import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import deepFreeze from "../../../../assets/myz/level-3-deep-freeze.jpg";
import latrines from "../../../../assets/myz/level-3-latrines.jpg";
import messHall from "../../../../assets/myz/level-3-mess-hall.jpg";
import recCenter from "../../../../assets/myz/level-3-rec-center.jpg";
import titanPowers from "../../../../assets/myz/book-cover-enclave-wars.jpg";
import elysiumHistory from "../../../../assets/myz/enclave-elysium-history.mp3";
import doorScan from "../../../../assets/myz/level-3-door-scan.jpg";
import greenManAsTree from "../../../../assets/myz/green-man-as-tree.jpg";
import chestburster from "../../../../assets/myz/chestburster.jpg";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Level 3 Command Center Exploration",
    text: (
      <p>
        The elevator arrives on level 3 after a several minute ride deep into
        the depths of the Zone. Almost immediately after stepping off the
        elevator the party is attacked by five guard robots and one drone
        soldier robot. The battle rages and the mutants, oilfield clones, and
        Dorothy are victorious.
        <br />
        <br />
        The party rests in the latrine area for three hours in order to heal,
        grab a nab, repair items and heal some critical injuries.
        <br />
        <br />
        Dorothy announces she has downloaded software and the party must choose
        her SECONDARY FUNCTION (i.e. talent) and the two MODULES (i.e.
        mutations) she will install. The secondary functions are detailed on
        Dorothy's management chest screen, but the MODULES have no details. The
        party must try to choose two modules based on names alone. After some
        debate, Bayazid successfully gets some hints about two modules to help
        the decision.
        <br />
        <br />
        Dorothy is outfitted with the INTERPRETER secondary function. For her
        modules, she gets GRENADE LAUNCHER and RIOT CONTROL.
        <br />
        <br />
        To the north of the lantries are showers. The party finds some scrap,
        some water, and are able to wash off rot.
      </p>
    ),
    image: {
      imagePath: doorScan,
      altText: `Touchpad readout for elevator to level 4 after card scan.`,
      className: "imageLeft",
      imageCaption: "Door Scan Message",
    },

    image2: {
      imagePath: latrines,
      altText: `Turns out latrines offer good cover when you have a close-quarter battle with mechanized guardians.`,
      className: "imageRight",
      imageCaption: "Latrines",
    },
  },
  {
    title: "Morlocks!!",
    text: (
      <p>
        The gang explores the large sleeping quarters. Dozens of small rooms
        with four bunks each provide a lot of nooks and crannies for hiding, but
        also for finding interesting stuff. As SmokeRaven plays with a small
        device with a screen and round plastic tray that might be a holotape,
        echoing howls and growls signal the advance of morlocks: depraved, lost
        mutants that have degenerated into beasts much like Zone Ghouls are
        toxic humans that have lost their humanity.
        <br />
        <br />
        The party flees back toward the elevators as two groups of Morlocks
        converge to pursue the mutants and clones. Dorothy turns around as the
        morlocks bottleneck between the barracks and latrine and fires an
        explosive grenade. Fourteen of the sixteen morlocks are immediately
        killed, blown into pieces, a bloody armageddon for the morlocks. One of
        the two survivors escapes as the last one is caputred. Doc Gladhand
        suggests Dorothy use her interpreter skill to try and communicate with
        the beast. Dorothy in fact can communicate with the beast.
        <br />
        <br />
        The morlock tells that they have lived in the command center for
        generations and that many more of them live down on level 4 and in the
        tunnels of the tram. The only description of the level 3 layout that
        makes sense is directions through the mess hall to the pantry and deep
        freezer. As the group decides to investigate the pantry and freezer
        before finishing the barracks afterward, the morlock successfully makes
        a break for it and escapes.
      </p>
    ),
    image: {
      imagePath: messHall,
      altText: `Military mess hall in Command Center Charlie`,
      className: "imageRight",
      imageCaption: "Mess Hall",
    },
    image2: {
      imagePath: greenManAsTree,
      altText: `The main screen of the rec room shows a live external camera view showing the Green Man has turned into a tree hundreds of feet tall with some grass growing around the base of the trunk.`,
      className: "imageLeft",
      imageCaption: "Green Man Becomes Giant Tree",
    },
  },
  {
    title: "Deep Freeze, Suprise Visitor, and Rec Center",
    text: (
      <p>
        To the east of the Mess Hall is a pantry. A fair amount of grub and
        water is found. The deep freeze is quite cold and frosted. Hulagu, Smoke
        Raven, and Chromicus put on their cold suits to search the freezer. In a
        corner of the freezer they find a corpse on a food service cart. The
        body is wearing a COLD SUIT. There is a HUNTIING RIFLE on the ground by
        the cart and a bloody icicle from the head of the corpse down to the
        floor of the freezer. There is a folded note in the pocket of the
        corpse.
        <br />
        <br />
        Chromicus is pushing the cart into the warm kitchen area as Hulagu
        inspects the hunting rifle. Suddenly there is a high pitched shriek and
        the chest of the corpse blasts open with a rain of ice shards. A bloody
        xenophage scurries out of the chest cavity, along a wall, and into a
        small duct before anyone can react. Hulagu, Chromicus, and Smoke Raven
        suffer an 8D6 horror attack (i.e. WITS damage) from the unexpected
        horror and the alien shriek of the beast.
        <br />
        <br />
        Next the party investigates the Rec Center. There are rows of
        still-working exercise bikes, each with its own screen. And on the wall,
        there is a main screen. The main screen rotates between:
        <ul>
          <li>
            Video extolling the importance of viewng the Chronicles of the Titan
            Powers video in order to make the mind as strong as the body.
          </li>
          <li>
            Game show hosted by Clark Stanley called "Clark Stanley's Less Your
            Pluck" where contestants suffer an endurance contest based on
            drinking different unknown snake oils. Sickened contestants are
            hauled off-stage as the remaining players try to manage their
            fortitude and try new snake oils.
          </li>
          <li>
            External camera view of Command Center Charlie above ground view.
            This is where we learn that The Green Man has sprouted into a tree
            hundreds of feet tall right where he stood to distract the bunker
            guns so the IL Gang could gain entry to the command center. At the
            base of the tree trunk is fresh, green grass growing.
          </li>
        </ul>
        Bayazid discovers they can play the Chonicle of the Titan Powers by
        riding a bike. Smoke Raven obliges and the history is played on the big
        screen. Afterward, a tray on the bicycle opens and the gang gets the
        holotape.
        <br />
        <br />
        The gang doubles back to the barracks and discovers the holotape for
        Enclave Elysium. They also find a Technology level 80 Mechatron
        artifact. Using the homebrew rules, they are able to attempt a
        comprehend at -3. They succeed and realize they have found a LOCK BOLT
        which can be used to partially "paralyze" a robot, making it difficult
        for the robot to take actions. Chromicus volunteers to carry the lock
        bolt and use it in melee if needed.
        <br />
        <br />
        The party opens the next door which leads to the Game Room. But as soon
        as the door is opened, a Digimax Hoverdrone flys out at top speed,
        zigging and zagging it's way out of combat. Dorothy announces, "I think
        we are going to have visitors soon." The gang decides to flip over some
        of the game and pool tables in the rec centor for cover and await the
        robot patrol sure to be summoned with an eye toward Dorothy launching a
        grenade in the hopes it works as well as it did with the Morlocks.
      </p>
    ),
    image: {
      imagePath: titanPowers,
      altText: `Holotape titled "Chronicle of the Titan Powers".`,
      className: "imageRight",
      imageCaption: "Chronicle of the Titan Powers",
    },
    image2: {
      imagePath: chestburster,
      altText: `A xenophage bursts from the chest of a body in the deep freeze.`,
      className: "imageLeft",
      imageCaption: "Xenophage",
    },
    audio2: {
      audioPath: elysiumHistory,
      altText: `History of Elysium enclave.`,
      className: "imageLeft",
      audioCaption: "Enclave Elysium Recording (SPOILER ALERT)",
    },
  },
  {
    title: "Wrapup",
    text: (
      <p>
        <h3>Bookkeeping</h3>
        <ul>
          <li>Bitterbeasts: food harvested.</li>
          <li>
            Pure Plant Patrol: Things go like clockwork (6 water, 9 grub, 11
            booze).
          </li>
          <li>Ark Reputation: 2 mutants arrive wanting citizenship.</li>
          <li>Wanderers Arrive: 5 mutants arrive wanting citizenship.</li>
        </ul>
        NOTE: Altair is housed at the Rust Castle along with Summer of Love bus.
        Chi Tzu is on the Steelcrest anchored off the island of Command Center
        Charlie.
        <br />
        <br />
        Next session starts in-game 06-23-0001, approximately dawn inside
        Command Center Charlie. The party is just getting off the elevator on
        floor 3.
        <br />
        <br />
        Base XP is 3 per character. Then, we discussed cool stuff that happened.
        Based on discussion, GM awards a bonus xp to one of the characters for
        each player. Some highlights discussed were:
        <ul>
          <li>
            Gladhand suggested using Dorothy's grenade in bottleneck corridor.
          </li>
          <li>
            Bayazid figured out how to play the Chronicle of the Titan Powers on
            exercise bike.
          </li>
          <li>Smoke Raven did some quaility sneaking.</li>
        </ul>
        Bonus xp was player choice due to largely even session. Points went to
        Hulagu, Ghost Bird, and Gladhand.
        <h6>Critical Injury Roundup</h6>
        <ul>
          <li>
            Chromicus: GROIN HIT: -1 FIGHT, MOVE, FORCE; heal time 4 days; ends
            morning of 26th.
          </li>
          <li>
            Ghost Bird: SPRAINED ANKLE: -1 MOVE, SNEAK; heal time 3 days; ends
            morning of 25th.
          </li>
          <li>
            Ghost Bird: THIGH WOUND: -2 MOVE and SNEAK; heal time 3 days; ends
            morning of 25th.
          </li>
          <li>
            Smoke Raven: DAMAGED SHIN, -1 MOVE and SNEAK, heal time 4 days; ends
            morning of 26th.
          </li>
        </ul>
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        .
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: deepFreeze,
      altText: `In the deep freezer, a corpse is discovered.`,
      className: "imageRight",
      imageCaption: "Deep Freeze",
    },
    image2: {
      imagePath: recCenter,
      altText: `Recreation Center has pool tables, game tables, a rack of books and a small refrigerator.`,
      className: "imageLeft",
      imageCaption: "Rec Center",
    },
  },
];

const MYZAnchortownSession50 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 50: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  Exploration of level 3 in Command Center Charlie reveals the
                  Chronicle of the Titan Powers and morlocks!
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession50;
