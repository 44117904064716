import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import NavBarMB from "../../../assets/mork-borg/navbar-icon-morkborg.webp";
import NavBarMalumMortis from "../../../assets/mork-borg/malum-mortis-icon-85x85.webp";

const MorkBorgNavBar = () => {
  return (
    <NavDropdown title="Mork Borg" id="basic-nav-dropdown">
      <Navbar bg="dark" variant="dark" expand="md" sticky="top">
        <Navbar.Brand href="#">
        <div style={{ textAlign: 'center', padding: "0px 0px 0px 8px" }}>
          Malum Mortis
          <br />
          Campaign
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <a href=".">
              <img
                src={NavBarMalumMortis}
                alt="SmokeRaven"
                style={{ maxHeight: 85 }}
              />
            </a>
          </Nav>
          <NavDropdown title="Sessions" id="basic-nav-dropdown">
            {/* <Navbar bg="light"> */}
            <NavDropdown.ItemText></NavDropdown.ItemText>
            {/* <NavDropdown
                bg="dark"
                title="Sessions 01-10"
                id="basic-nav-dropdown"
              > */}
            <NavDropdown.Item href="/mork-borg-session-11-malum-mortis">
              Malum Mortis: Session 11: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;12-22-2023
            </NavDropdown.Item>
            <NavDropdown.Item href="/mork-borg-session-10-malum-mortis">
              Malum Mortis: Session 10: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;12-08-2023
            </NavDropdown.Item>
            <NavDropdown.Item href="/mork-borg-session-09-malum-mortis">
              Malum Mortis: Session 09: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;11-11-2023
            </NavDropdown.Item>
            <NavDropdown.Item href="/mork-borg-session-08-malum-mortis">
              Malum Mortis: Session 08: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10-06-2023
            </NavDropdown.Item>
            <NavDropdown.Item href="/mork-borg-session-07-malum-mortis">
              Malum Mortis: Session 07: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;09-29-2023
            </NavDropdown.Item>
            <NavDropdown.Item href="/mork-borg-session-06-malum-mortis">
              Malum Mortis: Session 06: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;08-25-2023
            </NavDropdown.Item>
            <NavDropdown.Item href="/mork-borg-session-05-malum-mortis">
              Malum Mortis: Session 05: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;03-26-2023
            </NavDropdown.Item>
            <NavDropdown.Item href="/mork-borg-session-04-malum-mortis">
              Malum Mortis: Session 04: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;03-26-2023
            </NavDropdown.Item>
            <NavDropdown.Item href="/mork-borg-session-03-malum-mortis">
              Malum Mortis: Session 03: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;01-13-2023
            </NavDropdown.Item>
            <NavDropdown.Item href="/mork-borg-session-02-malum-mortis">
              Malum Mortis: Session 02: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;01-06-2023
            </NavDropdown.Item>

            <NavDropdown.Item href="/mork-borg-session-01-malum-mortis">
              Malum Mortis: Session 01: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;12-30-2022
            </NavDropdown.Item>
            {/* </NavDropdown> */}
            {/* </Navbar> */}
          </NavDropdown>
          <NavDropdown title="Maps, Graveyard, Misc." id="basic-nav-dropdown">
            <NavDropdown.ItemText></NavDropdown.ItemText>

            <NavDropdown.Item href="/mork-borg-malum-mortis-graveyard">
              Graveyard
            </NavDropdown.Item>
          </NavDropdown>
        </Navbar.Collapse>
        <Navbar.Brand href=".">
          Mork Borg
          <br />
          Game Aids
        </Navbar.Brand>
        <Nav className="mr-auto">
          <a href=".">
            <img src={NavBarMB} alt="SmokeRaven" style={{ maxHeight: 85 }} />
          </a>
        </Nav>
        <NavDropdown title="Rules and Game Aids" id="basic-nav-dropdown">
          <NavDropdown.ItemText></NavDropdown.ItemText>
          <NavDropdown.Item href="/mork-borg-classes-gallery">
            Class Gallery
          </NavDropdown.Item>
          <NavDropdown.Item href="/mork-borg-classes-core">
            Classes: Core
          </NavDropdown.Item>
          <NavDropdown.Item href="/mork-borg-classes-third-party">
            Classes: Third Party
          </NavDropdown.Item>
        </NavDropdown>
      </Navbar>
    </NavDropdown>
  );
};

export default MorkBorgNavBar;
