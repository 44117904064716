import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import casualties from "../../../../assets/myz/zone-war-casualties.webp";
import harleyHit from "../../../../assets/myz/smoke-raven-smokes-harley.webp";
import harleyBanner from "../../../../assets/myz/threat-helldrivers-harley-banner.jpg";
import warOver from "../../../../assets/myz/zone-war-won.jpg";
import zoneWarriors from "../../../../assets/myz/zone-warrior-roll20.jpg";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Session 58: Cleaning Up the Cult",
    text: (
      <p>
        With Chromicus the clear new leader of the Silver Egg cult, and an
        obsequious and sly Quiet Bob as his veteran lieutenant, the clean up of
        the space capsule begins.
        <br />
        <br />
        They find food, grub, and cannisters of the gases Zakarya used to take
        out unwary pilgrims.
        <br />
        <br />
        Chromicus learns that MULTI, the small, friendly spider bot assistant,
        can assign a temporary "friend" that MULTI must serve when ordered by
        Chromicus. Chromicus sets up Bayazid as his "friend" knowing that MULTI
        could boost the gearhead's efforts for sure.
        <br />
        <br />
        Some highlights:
        <ul>
          <li>
            The Zone War continues with Gladhand fighting Berke and Cage Island
            goons to free the saurians.
          </li>
          <li>
            Bayazid uses pathokinesis to make Dr. Theopolis like him. There is
            an immediate energy backlash as Dr. Theopolis psychically resists.
            Bayazid boots his power with more mutation points and gets a lucky
            roll and succeeds in giving Dr. Theo the warm fuzzies. Unfortunately
            it causes a mutation misfire that results in a permanent stat drop
            for Bayazid of STR 4 down to STR 3. Since Bayazid already has four
            mutations we roll randomly that he receives Pyrokinesis and player
            lets Luminescence drop.
          </li>
          <li>
            <span style={{ fontWeight: 600, color: "yellowgreen" }}>
              Nova Cult envoys arrive
            </span>
            . They want Abbetina to come back to the cult Ark with them. Their
            leader Grigor speaks with Smoke Raven. Smoke Raven reads Grigor well
            with the help of two successes in SENSE EMOTION roll. It is a
            friendly talk but Smoke Raven makes it clear they will not let the
            cultists take Abbetina, but that they will promise to protect her.
            The cultists are invited to wash off any rot and stay overnight.
          </li>
          <li>Hulagu recruits the Pure Plant humans.</li>

          <li>
            Hulagu goes to The Homestead and recruits the cannibal family as
            allies in the war.
          </li>
          <li>
            <span style={{ fontWeight: 600, color: "yellowgreen" }}>
              Berke is broken and decapitated by Gladhand
            </span>
            . Gladhand does a dramatic show of deriding Berke and surrounding
            him with a SPORE CLOUD at 3MP spent. And then, after rendering his
            verbal judgment, Glandhand uses SPRINTER to run in a lightning
            fastidious circle that ends with Gladhand decapitating Berke.
          </li>
          <li>
            Ghost Bird, Chromicus, and Gladhand wish the freed saurians allies
            well and leave the sector in their moon rover vehicle . . . with
            Berke head strapped on an ornament.
          </li>
          <li>
            The Nova Cultists leave without Abbetina to return to their home.
          </li>
          <li>
            <span style={{ fontWeight: 600, color: "red" }}>
              Smoke Raven smokes Harley
            </span>
            . With a 9-success war roll, Smoke Raven topples the Helldriver
            queen. In the tradition of Gladhand, as established with Berke,
            Harley is decapitaed by Smoke Raven. Harley's head is sported on a
            jagged spear on the scrap walls surrounding The Sunshine Plaza.
          </li>
          <li>Smoke Raven rips of Luke's head with a huge MANBEAST attack.</li>
        </ul>
        The session ends with a messenger arriving. The Patrick leading the
        Helldrivers headed toward the Ark is not Patrick. It's a stand-in to
        fool them and buy time for a strike force of Helldrivers led by Patrick
        making a bee-line to the Nova Cult Ark where it is rumored they hope to
        turn the cultists nuclear warhead into a weapon against the Sunshine
        Plaza.
      </p>
    ),

    image2: {
      imagePath: harleyHit,
      altText: `Smoke Raven smokes Harley with an amazing nine successes on Zone War roll.`,
      className: "imageRight",
      imageCaption: "Hard Hit to Harley",
    },

    image: {
      imagePath: harleyBanner,
      altText: `Harley Helldriver. Good girl, murderess, and All That.`,
      className: "imageLeft",
      imageCaption: "Death of Harley",
    },
  },
  {
    title: "Session 59: Winning the War",
    text: (
      <p>
        The war is turning against the Helldrivers. Even with the PCs leaving
        deputies in charge as they race to catch Patrick and his Helldrivers.
        <br />
        <br />
        Its decided that Gladhand, Chromicus, Hetu Jr, and a scrap pirate will
        ride bitterbeasts. Meanwhile, the others will take the{" "}
        <a
          href="https://www.drivethrurpg.com/product/303603/Mutant-Mythic-Artifacts"
          rel="noreferrer"
          target="_blank"
        >
          {" "}
          HDW-700 Karzar Battlewalker
        </a>{" "}
        (be sure to visit and check out all of stefouch's stuff; best
        supplements for MYZ out there). Smoke Raven is the driver, Hulagu is the
        top gunner, and Ghost Bird and Bayazid (with MULI on shoulder) are the
        auxiliary gunners.
        <br />
        <br />
        The Zone War rolls get kind of tedious even with simplified rules. The
        changing of rules between games with various one out of three players
        abstent makes it clear, at least by session's end, that we need to
        simply declare the Plaza winners of the war and concentrate on the
        pursuit of Patrick. After all, if decapitating Luke, Berke, and Harley
        doesn't spell winning, what does?
        <br />
        <br />
        <span style={{ fontWeight: 600, color: "yellowgreen" }}>
          The players jump into vehicles to chase Patrick
        </span>
        . It is decided that Abbetina, her donor Verter and Dr. Theopolis will
        travel with the pregnant abbess in the back of the moon rover vehicle.
        They will stay a sector or two behind the attack forces.
        <br />
        <br />
        Once the players catch up to Patrick's forces, a road warrior battle
        starts. The Battlewalker and four mutants riding bitterbeasts are trying
        to keep up, get past, or destroy about 10 motorcycles, two trucks, a
        dune buggy and the infamous Helldriver Juggeraut. In the Juggernaut is
        Patrick, the two-headed Illona, and two minions.
      </p>
    ),
    image: {
      imagePath: warOver,
      altText: `And so the Zone War ends with The Sunshine Plaza victorious!`,
      className: "imageLeft",
      imageCaption: "Zone War Won!",
    },
  },
  {
    title: "Session 60: Zone Warriors",
    text: (
      <p>
        As we head into the meat of the battle, Smoke Raven, Gladhand, and
        Bayazid have mental projection of{" "}
        <span style={{ fontWeight: 600, color: "red" }}>
          Scrap Oracle Cassandra appearing to them
        </span>
        .
        <br />
        <br />
        "The War is won", she croons as each of the PCs has a slideshow of
        visions catered to their secret desires, fears, and hopes as Cassandra
        speaks to them. "The time may come when you need me. In your greatest
        moment of need, call on me. I may be able to help from afar." And she
        fades just as the waking dreamers wonder what exactly the powerful
        mutant of mutants might be capable of doing. Then, it's back to the task
        at heads; a wild, battling convoy of bikes, bitterbeasts, big trucks and
        battle walkers. That's a lotta Bs y'all.
        <br />
        <br />
        Zone Warrior highlights:
        <ul>
          <li>
            Smoke Raven fires off the sonic blaster as he pilots the Karzar
            Battlewalker on the chase. It takes out five helldrivers, leaving
            them gaping mouthed and bleeding from their ears wherever they fell
            after crashing their motorcycles.
          </li>
          <li>
            Gladhand is shotgunned by a bitch rider on one of the Helldrivers
            cycles. He is broken but has NEVER SURRENDER and stays in the saddle
            with a successful MOVE roll (note: we forgot you still have critical
            injury before talent gets you back up on feet; remembered to late to
            worry about it).
          </li>
          <li>
            Bayazid starts auto-firing rockets at the Juggernaut. Ghost Bird is
            peppering the Juggernaut with machinegun fire.
          </li>
          <li>
            Bayazid breaks the rockets from continuous auto-fire. MULTI starts
            making a repair and later Hulagu breaks the top Gauss Machinegun
            which MULTI also repairs as the battle rages.
          </li>
          <li>
            As the big trucks battle it out, the bitterbeasts and bikers
            squabble is definitely going the players' way. The bitterbeasts tend
            to acid spit the driver of a motorcycle and then the mutant in the
            saddle urges the bitterbeast to speed ahead to keep up with the main
            battle. It's an effective manuever we call "spit n' git".
          </li>
          <li>
            Bayazid realizes he can use TELEKINESIS via his auxillary gunner
            headset. He hurls at stone at the Juggernaut with a full EIGHT
            MUTATION POINTS pumped into the makeshift missle.
          </li>
          <li>
            Gladhand is shotgunned again by the same little bitch. This time we
            remember the critical injury roll. Gladhand once again stays in the
            saddle and suffers only a SPRAINED WRIST (non-lethal, -1
            Fight/Shoot, 4 days to heal [heal to reduce time not yet
            performed]).
          </li>
          <li>
            Just as a few buildings including a barn appear on the horizon, the
            Juggernaut grinds to a halt spewing smoke and front axle broken.
            Smoke Raven jumps out of the battlewalker, rifle in hand. Bayazid
            keeps peppering the stopped Juggernaut with the machine gun battery
            until the machine gun battery is broken. The doors of the juggernaut
            screech open. Two bodies fall out and Ilona's massive two-headed
            body can be seen leaning at a steep angle, dead from many gunshot
            wounds. Patrick comes crawling out of the Juggernaut. He is alive
            but broken.
          </li>
        </ul>
        Patrick is staring up at Smoke Raven with a bloodly snarl, then all eyes
        drift to the horizon where a line of black-robbed, pale-faced members of
        the Nova Cult are approaching. To be continued. . .
      </p>
    ),
    image: {
      imagePath: zoneWarriors,
      altText: `Bitterbeasts and battlewalker versus the Helldrivers`,
      className: "imageLeft",
      imageCaption: "Zone Warriors Roll20",
    },
  },
  {
    title: "Wrapup",
    text: (
      <p>
        Ark rolls and project rolls were on hold as the Plaza cleaned up bodies,
        reinforced damaged structures, and tended to the wounded.
        <br />
        <br />
        We pick up with the battlewalker and Juggernaut: Patriick broken on the
        ground and Nova Cult approaching. Meanwhile, bitterbeasts and
        motorcycles are on approach as well; and somewhere a sector or two
        behind in the moon rover with bearing the mother of the Luminescent
        Child.
        <br />
        <br />
        We did XP spend at top of session. MOVE assigned to PCs for player
        absent as desired.
        <br />
        <br />
        <h4>CRITICAL INJURY ROUNDUP</h4>
        <ul>
          <li>
            Chromicus: SPRAINED WRIST, non-lethal, healing time (untreated) 4
            days. -1 SHOOT and FIGHT.
          </li>
        </ul>
        Note that total population shifts are:
        <br />
        <br />
        Casualties (18): Rel, Fang the Mountain, Grandma Yoya, Vanja the clone,
        Sarge & Skype (met on the Dead Blue Sea), Drea the Irritable, an
        ex-kurtzer mutant, two humans, and eight People of the Plaza.
        <br />
        <br />
        New Citizens (52): Quiet Bob, 15 Guardians of the Egg mutants, 12
        ex-Helldrivers, and 24 Mechies.
        <br />
        <br />
        Going forward humans and mutants will not be tracked by sub-faction as
        the Plaza is now a totally united community.
        <br />
        <br />
        The bonus XP went to Gladhand, Smoke Raven, and Bayazid. Bayazid's epic
        autofire climax was cool as hell. Gladhand staying in the saddle after
        being shotgunned twice proved you don't have to be an Enforcer to be a
        badass. And finally, Smoke Raven did a great job driving and the sonic
        blast was an effective move. Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        .
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: casualties,
      altText: `Zone War Casualties include Chromicus' sweetheart Vanja and Rel.`,
      className: "imageRight",
      imageCaption: "",
    },
  },
];

const MYZAnchortownSession58_60 = () => {
  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">Session 58-60: Zone Warriors</h1>
                <p className="lead leadExtra">
                  The Zone War finishes with the Sunshine Plaza victorious.
                  Meanwhile, the IL Gang is chasing Patrick and his Helldrivers
                  toward the Nova Cult.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession58_60;
