import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import theHomestead from "../../../../assets/myz/the-homestead-session33.jpg";
import welcomeBackKotterShow from "../../../../assets/myz/welcome-back-kotter-show.gif";
import boardGame from "../../../../assets/myz/welcome-back-board-game-in-progress.jpg";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Bookkeeping",
    text: (
      <p>
        05-25-0001:
        <br />
        <br />
        <ul>
          <li>
            PARTY ROLL CALL: Hulagu, Bayazid, Gladhand, Chromicus, Smoke Raven,
            Double Bird.
          </li>
          <li>
            BITTERBEASTS: several eggs hatched and at least three beasts became
            trained.
          </li>
          <li>
            PROJECT ROLLS: finished Workshop and started a Zone Expedition
            heading east toward Rust Castle. It is looking for CULTURE, starting
            in zone C11, and target sector is 6 sectors away. The side quest is
            that the IL Gang is interested in whether thare are any zone oasis
            spots for building a new settlement.
          </li>
          <li>PURE PLANT PATROL: guards attacked by creatures. ONE DEATH.</li>
          <li>ARK REPUTATION EFFECT: Sickness. FIVE DEAD.</li>
          <li>
            MISC: with improved DEV tech level and a lucky roll, the party
            figures out the "shelf" they found is actually a HOVERBOARD mythic
            artifact.
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: ``,
    },
  },
  {
    title: "The Homestead",
    text: (
      <p>
        The IL Gang parks the Summer of Love battle bus outside the gates to The
        Homestead compound. Daddy Ragnar, Big Barbro, and Hobbled Harry are
        there with the Sweathogs (i.e. stink hounds) to greet the mutants. Harry
        mistakes them for Helldrivers until Big Barbro points out they are not
        sporting flaming skulls and Helldrivers would not be the type to
        disguise themselves.
        <br />
        <br />
        Boss Hulagu accepts Daddy Ragnar's invitation to approach the gate and
        let stink hound Barbarino check him out to see "if yer a good sort."
        Hulagu fails roll and vomits from the hound's stench. Luckily, Barbarino
        takes a shine to Hulagu and laps up the vomit from behind the gate. The
        party finds out that Daddy Ragnar's operating philosophy is that "Eden
        is some fairly tale mutants tell themselves to avoid gettin' on with
        livin'" and the world if made up of "assholes and family."
        <br />
        <br />
        <h4>The Family at The Homestead -- INTRODUCTIONS</h4>
        <ul>
          <li>
            <b>King Lotto</b>: grandfather "paw paw" of the family. Hagga’s
            Husband (aka Jezebel, Sylvia). He is a gambler.
          </li>
          <li>
            <b>Sylvia</b> (aka Jezebel, aka Hagga): wife of King Lotto that IL
            Gang rescued in{" "}
            <a
              href="./mutant-year-zero-session-21-anchortown"
              rel="noreferrer"
              target="_blank"
            >
              Session 21
            </a>
            . Mother of Lutrell (from the Pure Plant), Daddy Ragnar, and Hobled
            Harry.
          </li>
          <li>
            <b>Daddy Ragnar</b>: patriarch of The Homestead. Son of King Lotto
            and Sylvia. Father to Big Barbro and Lutrell (with wife Sveah), and
            Little Brother and Lillemor (with Sweet Lena). Ragnar is the tallest
            of a tall family. He laughs loud at own jokes and loves talking
            about marinades. He is an easy-going joker, but also quick to anger.
            He smells of flowery perfume that he uses to MANIUPLATE more
            effectively.
          </li>
          <li>
            <b>Sveah “Mommy Dearest”</b>: matriarch of The Homestead and wife of
            Daddy Ragnar.
          </li>
          <li>
            <b>Sweet Lena</b>: sister of Sveah and mother of Little Brother and
            Lillemor with Ragnar.
          </li>
          <li>
            <b>Olle the Hat</b>: "uncle" Olle is not blood relation and he's
            family which is thicker than blood for The Homestead.
          </li>
          <li>
            <b>Hobbled Harry</b>: Ragnar’s brother and son of King Lotto and
            Sylvia. Harry is a tall, thin loner. He has a permanent limp from a
            run-in with Zone Rats. He collects scrap, he's gullible, and he
            loves to collect scrap.
          </li>
          <li>
            <b>Lutrell</b>: son of Olle and Sveah. Ragnar claims to not resent
            Lutrell, but he's also quick to say the boy was "too big for his
            britches" and "it's no surprise he thinks he's better than family".
            Lutrell ran away from home and became the leader of the Pure Plant
            where he has been unable to get away from his addictive tendencies
            as a cannibal, but has been able to keep them a secret.
          </li>
          <li>
            <b>Big Barbro</b>: daughter of Ragnar and Sveah. She's all and
            always wears a baseball cap. Her favorite color is pink and she
            stutters. She is inquisitive and laughs loud at nothing with a
            squall that would make a wounded, screaming donkey sound like a
            lullaby. She hates her parents, but she loves Little Mor and Little
            Brother with her life. She also hates Lutrell for leaving, but is
            also a little jealous.
          </li>
          <li>
            <b>Lillemor</b>: daughter of Ragnor and Lena. Early 20s. Had baby
            with Lutrell. He left the family after she was pregnant. After baby
            was born, Sylvia took the baby and ran into the zone "because the
            family was going to it." She is addled and while the family admits
            being cannibals, no one admits to wanting to eat one of their own
            kin.
          </li>
          <li>
            <b>Little Brother</b>: son of Ragnar and Lena. The baby of the
            family at age 19.
          </li>
        </ul>
        Smoke Raven escorts Sylvia to the gate to show the family that they do
        have a human with them and she claims this is her home. Sylvia, formerly
        Jezebel, was found back in{" "}
        <a
          href="./mutant-year-zero-session-21-anchortown"
          rel="noreferrer"
          target="_blank"
        >
          Session 21
        </a>
        . Upon seeing her, Daddy Ragnar says, "Momma? Is that you?"
        <br />
        <br />
      </p>
    ),
    image: {
      imagePath: welcomeBackKotterShow,
      altText: `The family has you watch moving pictures using an artifact that call "bay tah max". The people in the show are incomprehensibly different and in clearly living in the Old Age. Seeing how the world was before is hypnotizing. It seems like the show is meant to be funny, but it to you it seems like a mesmerizing time capsule into a different world filled with zone ghouls, uh, humans that are even dumber than the ones in the Zone.`,
      className: "imageLeft",
      imageCaption: "Welcome Back Kotter",
    },
  },
  {
    title: "Hanging Out at the Homestead",
    text: (
      <p>
        05-25-0001: The family learns to call Hagga by her new name Sylvia. They
        are grateful to the IL Gang and she explains how the Sunshine Plaza has
        made her feel at home. After watching an episode of the Welcome Back
        Kotter show and learning about the family's cannibal tendencies; talk of
        an alliance is in the air. To seal the deal, Daddy Ragnar wants the IL
        Gang to play their Welcome Back Kotter board game, complete with
        doctored Woodman and Kotter cards that have potentially deadly effects.
        <br />
        <br />
        Smoke Raven wins the game after using EXTREME REFLEXES to avoid a
        gunshot from Sweet Lena (due to card effect). The family freaks out and
        a brawl is nearly ready to break out. However, some great dialog and
        decent MANIPULATION rolls calm the atmosphere.
        <br />
        <br />
        The IL Gang agrees to give the family the next two bitterbeasts that are
        born and the family agrees to help out in getting the IL Gang to the
        Rust Castle. Sylvia will live with her family at The Homestead. The
        Family has been there and explains it is a real trading post with guard
        robots. It is a neutral trading ground run by little misshapen humans
        called "Mechies".
      </p>
    ),
    image: {
      imagePath: theHomestead,
      altText: `The Homestead`,
      className: "imageLeft",
      imageCaption: `The Homestead`,
    },
  },

  {
    title: "Wrapup",
    text: (
      <p>
        Next session will start on in-game morning of 05-26-2021 with party
        outside the gate of THE HOMESTEAD. We'll assume the gang camped out in
        their bus after a night of drinking and watching episodes of Welcome
        Back Kotter.
        <br />
        <br />
        Rot and food taken care of so no need at top of session. 4 XP awarded to
        each PC.
        <br />
        <br />
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        .
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: boardGame,
      altText: `The IL Gang plays a modified game of the Welcome Back Kotter board game where death is possible`,
      className: "imageLeft",
      imageCaption: "Welcome Back Kotter Board Game",
    },
  },
];

const MYZAnchortownSession33 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 33: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  The IL gang straggles back to the Sunshine Plaza. Based on
                  some information from Sylvia (aka Jezebel), they load up the
                  battle bus Summer of Love and straddle a few bitterbeasts to
                  ride into the zone and find Sylvia's family.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                {/*<Card.Title style={cardTitle}>Shootout at Burger Jim's, 03-27-0001 - 03-27-0001</Card.Title>*/}
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession33;
