import React from "react";
import "../../index.css";

class Content extends React.Component {
  render() {
    let titleSection;
    let sectionTitleCssName = "sectionTitle";

    if (this.props.content.sectionTitleCssName) {
      sectionTitleCssName = this.props.content.sectionTitleCssName;
    }
    console.log(this.props);
    if (this.props.content.title) {
      titleSection = (
        <div className={sectionTitleCssName}>
          <h5>{this.props.content.title}</h5>
        </div>
      );
    }
    let sectionImage;
    if (this.props.content.image) {
      sectionImage = (
        <a href={this.props.imagePath}>
          <div className={`hover01 ${this.props.content.image.className}`}>
            <figure>
              <img
                src={this.props.content.image.imagePath}
                alt={this.props.content.image.altText}
              />
              <br />
              <div style={{ textAlign: "center" }}>
                {this.props.content.image.imageCaption}
              </div>
            </figure>
          </div>
        </a>
      );
    }
    let sectionImage2;
    if (this.props.content.image2) {
      sectionImage2 = (
        <a href={this.props.imagePath}>
          <div className={`hover01 ${this.props.content.image2.className}`}>
            <figure>
              <img
                src={this.props.content.image2.imagePath}
                alt={this.props.content.image2.altText}
              />
              <br />
              <div style={{ textAlign: "center" }}>
                {this.props.content.image2.imageCaption}
              </div>
            </figure>
          </div>
        </a>
      );
    }
    let audio;
    if (this.props.content.audio) {
      audio = (
        <div style={{ margin: "0 auto", display: "table", float: "left" }}>
          <figcaption style={{ textAlign: "left", fontSize: "12px" }}>
            {this.props.content.audio.audioCaption}
          </figcaption>
          <audio
            src={this.props.content.audio.audioPath}
            controls
            type="mp3"
          ></audio>
        </div>
      );
    }
    let audio2;
    if (this.props.content.audio2) {
      audio2 = (
        <div style={{ margin: "0 auto", display: "table", float: "left" }}>
          <figcaption style={{ fontSize: "12px" }}>
            {this.props.content.audio2.audioCaption}
          </figcaption>
          <audio
            src={this.props.content.audio2.audioPath}
            controls
            type="mp3"
          ></audio>
        </div>
      );
    }

    return (
      <div>
        {/* <a class="anchor" href="javascript:void(0)" name={this.props.content.anchor}>
          &nbsp;
        </a> */}
        <button class="anchor-button" onclick="window.location.href=''">
    &nbsp;
</button>
        {titleSection}
        <div>
          {sectionImage}
          {sectionImage2}
          {audio}
          {audio2}

          {this.props.content.text}
        </div>
      </div>
    );
  }
}

class ContentContainer extends React.Component {
  render() {
    let arr = this.props.data;
    let elements = [];
    for (let i = 0; i < arr.length; i++) {
      elements.push(<Content key={i} content={arr[i]} />);
    }
    return (
      <div>
        <div style={{ display: "flex" }}>
          <div>{elements}</div>
        </div>
      </div>
    );
  }
}

export { ContentContainer };
