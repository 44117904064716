import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import mbLogo from "../../../../src/assets/mork-borg/mork-borg-logo.webp";
import sectionDivider from "../../../../src/assets/mork-borg/mork-borg-section-divider.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";

import akuhn from "../../../../src/assets/mork-borg/session11/akuhn.webp";
import grace from "../../../../src/assets/mork-borg/session11/grace-of-a-dead-saint.webp";
import race from "../../../../src/assets/mork-borg/session11/racing-for-the-heart.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const MBMalumMortisSession11 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  const section1Data = [
    {
      title: "Akuhn the Demon Princess",
      text: (
        <div className="morkBorgFont morkBorgTitleText">
          <ImageComponent
            image={akuhn}
            caption="Akuhn the Demon Princess"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Akuhn the Demon Princess, ruling the Death Ziggurat."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          Our party consists of:
          <ul>
            <li>
              <span style={{ color: "#00FFFF" }}>HERMAN</span>:{" "}
              <span style={{ color: "#ADFF2F" }}>gutterborn scvm</span>.
              worried, naive, hands sore caked, pyromaniac.
            </li>
            <li>
              <span style={{ color: "#00FFFF" }}>HILDEBRAND</span>:{" "}
              <span style={{ color: "#ADFF2F" }}>heretical priest</span>, washes
              hands & obsessively clean, fire baptized!
            </li>

            <li>
              <span style={{ color: "#00FFFF" }}>KLATUUBARADA</span>:{" "}
              <span style={{ color: "#ADFF2F" }}>fugitive knight</span>, an
              argumentative, pyromaniacal.
            </li>
            <li>
              <span style={{ color: "#00FFFF" }}>SIR FAT HEAD</span>:{" "}
              <span style={{ color: "#ADFF2F" }}>mushroom eater</span>, wacked
              out prophet, scrambled like eggs.
            </li>
            <li>
              <span style={{ color: "#00FFFF" }}>SPUG</span>:{" "}
              <span style={{ color: "#ADFF2F" }}>wretched royalty</span>{" "}
              afflicted with kleptomania and afraid of pain. Pain is such a
              bother!
            </li>
            <li>
              <span style={{ color: "#00FFFF" }}>WINSTON DUNGHILL</span>:{" "}
              <span style={{ color: "#ADFF2F" }}>soul-damned drunkard</span>, a
              nervous drunk with resiliancy.
            </li>
          </ul>
          Outside the Death Ziggurat, Sir Fat Head tries on the Spiral Crown.
          This immediately summons Akuhn to the steps of the icy edifice. Upon
          sight of the demon princess, the cultists turn against the scvm, their
          cultist minds not able to resist the culmination of their nightmares
          in the flesh.
          <br />
          <br />
          Akuhn is defeated and disappears in a swirl of colored lights
          reminiscent of the glimpses of the stars above the snow tornado and in
          the way the gems hover above the spiral crown. The surviving cultists
          capitulate in shame but are unrepentant of their switched loyalty.
          Klaatu mumbles, "can we set them on fire?" And so it is done. Then,
          our party enters the Death Ziggurat.
          <br />
          <br />
          <ImageComponent
            image={race}
            caption="Racing for the Heart"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="The race for Akuhns Heart."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          The cascading torrent of ice, snow, and debris forming a tornado-like
          funnel is being drawn down through a torn roof in the ziggurat from
          the terrible night sky and disappearing into a huge, circular maw in
          the floor. Heat from the maw is melting the snow in the massive
          chamber and filling it with the fetid reek of death and moist
          oblivion. There are four rot priests, two horn beats, and a newly
          remergent Akuhn in the room.
          <br />
          <br />
          As the epic battle rages, the Akuhn's beating heart can be heard
          somewhere down below, beyond the maw in the floor.
          <br />
          <br />
          Akuhn grabs Jacque Manaque and devours the silly squire boy in her
          vertical chest mouth of doom. Others are diving into the maw and
          racing for Akuhn's heart. Sarku's words "stab her heart to kill her"
          sets off a foot race for the heart through dripping, cold, and
          decaying tunnels.
          <br />
          <br />
          Herman is making the most of his invisibility on the battlefield.
          <br />
          <br />
          <ImageComponent
            image={grace}
            caption="Grace of A Dead Saint"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Grace of A Dead Saint with Eldritch Elevation for double damage."
          ></ImageComponent>
          <ModalComponent
            key={2}
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          There is a nice moment when Klaatubarada wierds a power getting double
          damage with Grace of A Saint.
          <br />
          <br />
          The boy from Galgenbeck are victorious: they pop Akuhn's heart like a
          blood-filled balloon, destroy the minions of darkness, and find the
          Blood Relic along with some other modest loot.
          <br />
          <br />
          We finished the session with Getting Better rolls and a promise that
          we will not foget that almost everyone in the party is still suffering
          from the Emerald Venom of the Sepulchre of the Swamp Witch module and
          therefore fumble on a 1-3 roll.
          <br />
          <br />
          <h4>
            Killing Akuhn sets off a misery: Psalm 2, Verse 5: "And glass shall
            become quartz."
          </h4>
          <h3>The Wrapup</h3>
          <div
            style={{ border: "3px solid red", padding: "5px", margin: "5px" }}
          >
            Misery Countdown:
            <ul>
              <li>
                Psalm 6, Verse 1: You shall know the last day is come. The sun
                shall set and never rise.
                <ul>
                  <li>
                    EFFECT: sun never shines. World is dark always. No more
                    daytime.
                  </li>
                </ul>
              </li>
              <li>
                Psalm 2, Verse 5: And glass shall become quartz.
                <ul>
                  <li>
                    EFFECT: sun never shines. World is dark always. No more
                    daytime.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          This session saw KLATU and SPUG sitting out.
          <br />
          <br />
          This episode of Malum Mortis brought to you in part by:
          <br />
          Module:{" "}
          <a
            href="https://www.drivethrurpg.com/product/328612/MORK-BORG-CULT-FERETORY"
            rel="noreferrer"
          >
            FERETORY.
          </a>
          <br />
          Game:{" "}
          <a href="https://morkborg.com/content/" rel="noreferrer">
            Mork Borg
          </a>
          <br />
          Module (METAPLOT):{" "}
          <a
            href="https://www.drivethrurpg.com/product/423719/Malum-Mortis-A-Mork-Borg-Metaplot"
            rel="noreferrer"
          >
            Malum Mortis: A Mork Borg Metaplot.
          </a>
          <br />
        </div>
      ),
    },
  ];

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/mork-borg/bkgnd-01.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <Container>
      {/* <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin" 
                    style={{position: 'float', float: 'right',width: '120px', height: '240px'}} marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=smokeraven-20&language=en_US&marketplace=amazon&region=US&placement=9189143701&asins=9189143701&linkId=d1384e0e6b9ef083fee35d7272a71f88&show_border=true&link_opens_in_new_window=true"></iframe> */}
      <Row>
        <Col>
          <div
            style={{ backgroundColor: "transparent", fontFamily: "Orbitron" }}
          >
            <main role="main" className="inner cover text-center">
              <img
                src={mbLogo}
                style={{ maxWidth: "200px" }}
                alt="Malum Mortis Mork Borg campaign"
              />
            </main>
            <div style={pageHeader}>
              <h1 className="cover-heading">Session 11: Malum Mortis</h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title className="cardTitle-mork-borg">
                Akuhn is defeated, the Blood Relic of Anuk Schleger is found,
                and Jacque Manaque lies dead; or does he?
              </Card.Title>
              <div>
                <ContentContainer data={section1Data} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MBMalumMortisSession11;
