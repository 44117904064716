import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import logo from "../../../../src/assets/forbidden-lands/forbidden-lands-logo.webp";
import sectionDivider from "../../../../src/assets/forbidden-lands/session02/bitter-reach-page-banner-session-02.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";
import "./forbidden-lands-styles.scss";

import iona from "../../../../src/assets/forbidden-lands/session02/iona-northfall.webp";
import convel from "../../../../src/assets/forbidden-lands/session02/convel-wolfkin.webp";
import velvel from "../../../../src/assets/forbidden-lands/session02/velvel-wolfkin.webp";
import resza from "../../../../src/assets/forbidden-lands/session02/resza-wolfkin-portrait.webp";
import hillis from "../../../../src/assets/forbidden-lands/session02/hillis-the-trader-portrait.webp";
import lighthouse from "../../../../src/assets/forbidden-lands/session02/lighthouse-portrait.webp";
import howlscorn from "../../../../src/assets/forbidden-lands/campaign/images/howlscorn.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "1",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Carter One",
  backgroundColor: "rgba(0, 0, 0, 1) ",
  color: "white",
  marginBottom: "15px",
};

const FBLSession02 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  const section1Data = [
    {
      title: "Meeting Hillis the Trader",
      anchor: "freedom-day",
      sectionTitleCssName: "sectionTitleFBL",
      text: (
        <div className="fblBodyText">
          <ImageComponent
            image={hillis}
            caption="Hillis the Trader"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Hillis is an Alderlandan trader in Northfall. Her father and mother came to the Bitter Reach before she was born. She inherited the shop. She has never travelled anywhere but visits the world in her mind by way of her curiosity and impressive library."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <span style={{ color: "burlywood" }}>Blisster and Lobo</span> are putting out the fire in the elven ruins they call home for now. <span style={{ color: "lightgreen" }}>"Ah, you boys are up. My saviors!"</span> cries <span style={{ color: "burlywood" }}>Bata</span> stopping by to visit.
          <span style={{ color: "lightgreen" }}>"I am staying in tiny closet in shop of Hillis the trader. She is very nice lady. She appreciate me reading and writing. 
          I tell about you; all the fur and hair and she is ok. You should see her. She is at top of the Stone Staircase. Come on, I will show you."</span>
          <br/><br/>
          Hillis' store has piles of junk mixed with useful gear outside, and the inside is an even more chaotic mess. <span style={{ color: "burlywood" }}>Hillis is a tall, middle-aged Alderlander</span> lady with a big 
          smile and a big heart. She is a trader and a collector of all things. 
          Her face has deep lines, all of which seem related to smiling or laughing. She treats the goblin and wolfkin kindly. She asks about Gomsten Island without directly referencing their 
          history as criminals. Lobo is a bit quiet, but Blisster tells her the island is the only place he has ever known. Hillis scribbles notes in her journal as the prisoners from Gomsten 
          tell her about their lives.
          <br/><br/>
          As she makes tea, Blisster and Lobo make some purchases, eager to get all the winter clothing needed to survive in the Bitter Reach. <span style={{ color: "red" }}>Blisster cannot help himself and eyes a pair of 
          gloves to steal</span>. Lobo sees that look in Blisster's eyes and chatters with Hillis to distract her. Blisster rolls and pushes; his failure causes three points of AGILITY damage. The player says 
          this takes the form of feeling shaken and unsteady hands due to his compulsion to steal and the resulting guilt he has, especially since Hillis catches him. Hillis mildly chastises Blisster, then offers the gloves at a discount. 
          Blisster tears up at her restraint and kindness, feeling guilty about trying to rob such a nice person. He then wants to buy a pair of mukluks and she gives them to him.
          <br/><br/>
          Hillis tells the <span style={{ color: "orange" }}>LEGEND: WURDA</span>.
          <br/><br/>
          The visit concludes with Hillis showing everyone ber notes on ancient Winter Elf architecture and their sigil writing. She is very proud of her status as one of the only people 
          in the Bitter Reach who knows anything about the Winter Elves.
          <br/><br/>
          The boys next visit <span style={{ color: "burlywood" }}>Ogrin the Tailor</span>. While not unkind, Ogrin is all business. A negotiation for a lower price on a fancy parka fails and players note a -1 on the roll due to mild 
          prejudice against wolfkin and goblin alike. However, the prejudice does not show through Ogrin's professional demeanor. Blisster cannot help himself and buys a fancy parka. The fancy 
          parka goes beyond the basic COAT requirement of an anorak for adequate protection in the Bitter Reach. It costs a solid gold, but gives bonuses on ENDURANCE versus the COLD and 
          also a +1 to PERFORMANCE rolls.
          <br/><br/>
          <ImageComponent
            image={iona}
            caption="Iona"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Iona is a famously beautiful young lady of the whaler camp in Northfall. "
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          Lobo goes back to work for Khurkan butchering pikebeasts. Blisster works as a deputy for Marshall Volle, especially proud to show off his fancy park while on patrol. Khurkan is 
          still teasing about Lobo's butchering skills but notices a small improvement. At one point a famously beautiful girl from the whaler camp named <span style={{ color: "burlywood" }}>Iona</span> walks by. Khurkan leers as she 
          passes and rudely asks Lobo, <span style={{ color: "lightgreen" }}>"do you dog boys go for such a thing?"</span> Lobo shrugs as he wields his cleaver, <span style={{ color: "lightgreen" }}>"I can see the appeal."</span>
          <ImageComponent
            image={convel}
            caption="Convel, Wolfkin Stalker"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="Convel is a wolfkin with tan fur and brown eyes and the traditional braid of the Stalker class."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <br/><br/>Meanwhile, at the top of the Stone Staircase, Blisster is approached by a wolfkin with tan fur, brown eyes, and the traditional braid of a Stalker. <span style={{ color: "lightgreen" }}>"Hello friend, it is a fine day to look out over the bay. That's a fine parka you have. Are you on watch?"</span> asks the wolfkin.  
           They introduce themselves and the wolfkin says his name is <span style={{ color: "burlywood" }}>Convel</span>. 
           Blisster 
           says he is aiding the city watch while his friend who is also a wolfkin is working for Khurkan.
           <br/><br/><span style={{ color: "lightgreen" }}>"Oh really?" muses Convel, "And where is your friend now?"</span><br/><br/>Blisster explains where Lobo is working. Convel thanks the goblin then goes on all fours to nimbly descend the
           treacherous Stone Staircase to the black beach bay of Northfall below. <span style={{ color: "lightgreen" }}>"Hmm, that was a bit strange" Blisster thinks to out loud.</span>
          <br/><a href="#pageTop">Back to Top</a>
        </div>
      ),
    },
    {
      title: "Lupiforgis & the Winter Elf Lighthouse",
      anchor: "freedom-day",
      sectionTitleCssName: "sectionTitleFBL",
      text: (
        <div className="fblBodyText">
          <ImageComponent
            image={velvel}
            caption="Velvel, Wolfkin Gamma"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Velvel is a wolfkin Gamma with dark grey fur and black eyes. He is Convel's elder brother and leads the scouting party in Northfall looking for Lobo."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          In the wee hours of that evening, Lobo is awakening by three shapes holding him down. They are shadows backlit by the firelight as Lobo struggles to awaken. 
          <span style={{ color: "lightgreen" }}>"We found you, Lobo,"</span>" says a growling voice. <span style={{ color: "lightgreen" }}>"Who are you?"</span> blurts Lobo as the figures allow him to sit up. 
          The three visitors are the wolfkin <span style={{ color: "burlywood" }}>Velvel, Resza, and Convel</span>. <span style={{ color: "lightgreen" }}>"We heard you were back. Why are you here?"</span> barks Velvel, a Gamma wolfkin with dark grey fur and 
          black eyes; the leader of the small scouting party. [Note: take a look at <a target="_blank" href="./forbidden-lands-wolfkin">Wolfkin</a> for more information on the wolfkin rituals and culture].
          <br />
          <br />
          Lobo explains he has his freedom back and wanted to return home. <span style={{ color: "lightgreen" }}>"Do you wish to re-join the pack Lobo? Will you undergo the trial of Lupifurgis?"</span> asks Velvel. 
          <span style={{ color: "lightgreen" }}>"He doesn't deserve it!"</span> howls Resza, a female wolkfin with white fur and blue eyes; she was the lover of Lobo's brother who is (was?) a Hunter for the pack.<br/> 
          <span style={{ color: "lightgreen" }}>"I will undergo Lupiforgis"</span> Lobo 
          answers stocially. Blisster is sitting up at this point and Convel kneels by the goblin and explains, <span style={{ color: "lightgreen" }}>"Listen friend, Lobo is going to be hurt. He may die, he may not ok? 
          But you . . . if you don't stay still and and stay out of it, you will die, understand?"</span> Blisster protests that he will backup Lobo in any situation. 
          <span style={{ color: "lightgreen" }}>"Stand down Blisster. This is something 
          I have to do"</span> growls Lobo.
          <br/><br/>
          <ImageComponent
            image={resza}
            caption="Resza, Wolfkin Tracker"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="Resza is a wolfkin Tracker with white fur and blue eyes. She was in lover (or lover?) of Lobo's brother who is (was?) a Stalker."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          What happens then is a brief but vicious beatdown as the three wolfkin take turns striking Lobo. Resza is about to deliver the last, breaking blow. It is a 50/50 chance that she 
          will attack with BLUNT kick or SHARP claws. Lobo decides not to provote or assuage Resza and stares into her eyes as she strikes. Resza strikes with the slightly more
          merciful BLUNT strike and Lobo has a GROIN HIT critical injury.
          <br/><br/>
          As Lobo lays unconscious near the campfire, Convel shows Blisster a piece of wood and scratches a spiral shape into the wood with his claws. 
          <span style={{ color: "lightgreen" }}>"This is a howlscorn"</span>, Convel tells the goblin. <span style={{ color: "lightgreen" }}>"It is given to show one is banished from the pack."</span> As he speaks he also pulls out a small pouch. He opens the pouch and pulls out 
          pinches of crushed flowers and starts packing them into the spiral scratch. <span style={{ color: "lightgreen" }}>"But if the howlscorn is filled with flowers"</span>, Convel says with a toothy grin at Blisster, <span style={{ color: "lightgreen" }}>"then it means the 
          one who holds it may approach pack territory and demand to speak with the Alpha about re-joining the pack. Whether or not that works is another matter; but it is a chance."</span> Convel finishes the 
          Howlscorn with a flourish. <span style={{ color: "lightgreen" }}>"Make sure your friend gets this hey? Take care of him. Your a good friend to him Blisster."</span> And then, the wolves are gone. Blisster tends to Lobo through the night, healing him and 
          they both sleep.
          <ImageComponent
            image={howlscorn}
            caption="Howlscorn"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="The howlscorn is an important wolfkin symbols. See <a href='./forbidden-lands/wolfkin' target='_blank'>Wolfkin</a> for more details."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <br/><br/>
          The next day the boys work again. That night, itching for adventure, Blisster tells Lobo about the glowing lights at the top of the Elven lighthouse in the night. The two decide to investigate.
          <br/><br/>They approach the lighthouse at midnight. The whaler's camp is quiet; the cauldrons cool and dark; and the fisherman's camp is empty. As said before, no one is near the lighthouse as the 
          locals are superstitious and never approach the lighthouse and only speak of it as haunted.
          <ImageComponent
            image={lighthouse}
            caption="Winter Elf Lighthouse"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="The Winter Elf lighthouse is long abandoned and avoided by the locals of Northfall who claim it is haunted. Perhaps the swirling colors that float around the top of the lighthouse in the darkest hours of the night have something to do with their superstitions."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <br/><br/>As Blisster and Lobo approach they see the dim shadow of a humanoid shape behind the glowing aurora borealis of unnatural glowing lights swirling around the top of the lighthouse.
          They enter the lighthouse and find it is colder than the outside. There is nothing  but a staircase leading up to the top of the massive 100-foot structure. At the top of the structure they see 
          a human form by the lighthouse lamp. It is a person with sharply pointed ears, long white hair, and a very narrow face. Blisster recognizes the features as those of a Winter Elf from 
          the descriptions and sketches Hillis showed them. The swirling colors shine through the body of the figure and it becomes clear the elf is a spectre or ghost of some sort. It floats toward the 
          wolfkin and goblin with a smooth, ghostly pantomime of walking.
          <br/><br/>Blisster attacks the ghost and his blade slices through the translucent figure. The ghost suffers no effect but Blisster's blade frosts with chill and Blisster feels the 
          freeze of becoming COLD. Then, <span style={{ color: "red" }}>the ghost looms at both of the intruders with a face that enlarges and contorts horribly as it screetches,</span><span style={{ color: "lightgreen" }}>"Oil!!! I need oil for the laaaamp!!!"</span>
          <br/><br/>
          Blisster takes FEAR damage and Lobo is shaken but unharmed. Blisster blusters, <span style={{ color: "lightgreen" }}>"We can get you oil. I don't have oil here but we can get it. We will get you oil!"</span> And with that, the 
          ghost calms down and walks back to the lighthouse lamp as though waiting. Lobo and Blisster look at each other thinking, <span style={{ color: "lightgreen" }}>"where can we get oil at midnight?"</span>
          <br/><br/>
          Tune in next time for more chilling developments in the Bitter Reach. Until then, stay warm friends.
      <br/>
          <a href="#pageTop">Back to Top</a>
          <br/><br/>
          <small>Attendees: Blisster, Lobo, (Rog-Golar)</small><br/>
          <small>Ending Datetime: 12am, Winterwane 36, 1165</small>
          <h5>XP</h5>
          <ul>
            <li>Session 1.0 (Blisster, Lobo)</li>
            <li>Legend: 1.0 (Wurda by Hillis)</li>
            <li>Lore: 0.2 (Adequate Protection by Hillis)</li>
            <li>Hex: 1.0</li>
            <li>Pride: 0.0</li>
            <li>Treasure: 0.0</li>
            <li>Monster: 0.0</li>
            <li>Dark Secret: 1.0 (Blisster, Lobo)</li>
          </ul>
        </div>
      ),
    }
  ];

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/forbidden-lands/fbl-page-background-blizzard.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.backgroundWidth = "100vw";
    document.body.backgroundHeight = "100vh";
    document.body.style.backgroundSize = "cover";
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <div
            style={{
              backgroundColor: "transparent",
              fontFamily: "IM Fell Great Primer",
            }}
          >
            <div style={pageHeader}>
              <main role="main" className="inner cover text-center">
                <a href="#freedom-day" name="pageTop">
                  <img
                    src={logo}
                    style={{ maxWidth: "200px" }}
                    alt="Forbidden Lands Campaign"
                  />
                </a>
              </main>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title id="cardTitle-forbidden-lands" className="cardTitle-forbidden-lands  ">
              Our heroes break the monotony of their day jobs to investigate the supernartural glowing of the Winter Elf Lighthouse in Northfall.
                <br /><br/>
                <small>
                  ⇐ Session 01:{" "}
                  <a href="./forbidden-lands-session-01">
                    Freedom Day in Gomsten
                  </a>{" "}
                  ☠{" "}
                  Session 03:{" "}
                  <a href="./forbidden-lands-session-03">
                    Winter Spirits & Tundra Walk
                  </a>{" "}
                  ⇒{" "}
                </small>
                
       


              </Card.Title>
              <div>
                <ContentContainer data={section1Data} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default FBLSession02;
