import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import mbLogo from "../../../../src/assets/mork-borg/mork-borg-logo.webp";
import sectionDivider from "../../../../src/assets/mork-borg/mork-borg-section-divider.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";

import frogs from "../../../../src/assets/mork-borg/session10/scrolki-and-yaoxl.webp";
import cultist from "../../../../src/assets/mork-borg/session10/cultist.webp";
import rotPriest from "../../../../src/assets/mork-borg/session10/rot-priest-portrait.webp";
import lake from "../../../../src/assets/mork-borg/session10/mood-frozen-lake.webp";
import mural from "../../../../src/assets/mork-borg/session10/mural.webp";
import sarku from "../../../../src/assets/mork-borg/session10/sarku-portrait.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const MBMalumMortisSession10 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  const section1Data = [
    {
      title: "Space Frogs Went A-Courtin'",
      text: (
        <div className="morkBorgFont morkBorgTitleText">
          <ImageComponent
            image={frogs}
            caption="Space Frogs"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Scrolki and Yaoxl are cosmic space frogs and they want the Croaking Trident."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          Our party consists of:
          <ul>
            <li>
              <span style={{ color: "#00FFFF" }}>HERMAN</span>:{" "}
              <span style={{ color: "#ADFF2F" }}>gutterborn scvm</span>.
              worried, naive, hands sore caked, pyromaniac.
            </li>
            <li>
              <span style={{ color: "#00FFFF" }}>HILDEBRAND</span>:{" "}
              <span style={{ color: "#ADFF2F" }}>heretical priest</span>, washes
              hands & obsessively clean, fire baptized!
            </li>
            <li>
              <span style={{ color: "#00FFFF" }}>SIR FAT HEAD</span>:{" "}
              <span style={{ color: "#ADFF2F" }}>mushroom eater</span>, wacked
              out prophet, scrambled like eggs.
            </li>

            <li>
              <span style={{ color: "#00FFFF" }}>WINSTON DUNGHILL</span>:{" "}
              <span style={{ color: "#ADFF2F" }}>soul-damned drunkard</span>, a
              nervous drunk with resiliancy.
            </li>
          </ul>
          On the dizzying steps of the downward spiral at the edge of ethereal
          space,{" "}
          <span style={{ color: "#FF7F50" }}>
            our party speaks with the psychedelic space frogs Scrolki & Yaoxl
          </span>
          . The frogs speak in a shattered harmony as one voice. They want the
          Croaking Trident carried by Winston Dunghill. The frogs promise to
          transport the party via ethereal tunnels to the edge of a chasm in
          Sarkash forest where they may find the{" "}
          <span style={{ color: "#FF7F50" }}>Death Ziggurat</span>. They also
          sing the story of the{" "}
          <span style={{ color: "#FF7F50" }}>Demon Child Akunh</span> and her
          story of banishment by SHE for being a disruptive, half-demon child.
          When asked of their purpose, the frogs say they want to leave their
          ethereal realm of punishment and come to the Dying Lands. When asked
          why they are at the downard spiral below the Sepulcher of the Swamp
          Witch, they simply reply "Why is a fish at the edge of a pond before
          it grows legs to walk upon the land?"
          <br />
          <br />
          <ImageComponent
            image={cultist}
            caption="Cultists"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="Cultists camped near the Death Ziggurat await a holy sign."
          ></ImageComponent>
          <ModalComponent
            key={2}
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          The party convinces the frogs that they will give over the trident
          AFTER the death ziggurat so they are assured of a round-trip journey
          back to the shores of Lake Onda; an important point since they are
          racing against the Calendar of Necrubel to save the Dying Lands. The
          &nbsp;
          <span style={{ color: "#FF7F50" }}>
            frogs warn Winston that the more he uses the trident, the more like
            the space frogs he will become and the less of his own soul will he
            retain
          </span>
          . "Be careful", the twin cosmic toads warn, "your human souls are so
          fragile; but delicious!"
          <br />
          <br />
          Accepting the risk, our anti-heroes follow the frogs through the
          labyrinthe spiral tunnels. Squire-in-training Jacque Manaque is
          perplexed as he is the only party member not under the influence of
          the emerald serpent venom and therefore apprehends absolutely nothing
          of the cosmic space frogs or the many psychedelic visions floating in
          the air.
          <br />
          <br />
          As they walk the ethereal tunnels, the venom begins to wear off and
          the frogs fade. With the blink of an eye{" "}
          <span style={{ color: "#FF7F50" }}>
            they find themselves standing in the dark, ominous forest of Sarkash
          </span>
          ; snow is falling, the wind is blowing like the devil's horn, and they
          hear a chanting of voices to the north in the forest.
        </div>
      ),
    },
    {
      title: "Cultists of the Death Ziggurat",
      text: (
        <div className="morkBorgFont morkBorgTitleText">
          <ImageComponent
            image={lake}
            caption="The Frozen Lake"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Near the Death Zigurrat is a frozen lake trapping dozens of moaning undead with black water lilies sparkling in the light of the comet in the night sky."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          The party approaches a{" "}
          <span style={{ color: "#FF7F50" }}>camp of cultists</span>. They are
          mostly sitting and chanting, a form of cross-harmonic throat singing
          like Mongolian throat signing with a distorted tone. They all wear
          headresses and fur robes decorated with bones. A tall human with the
          most elaborate headress approaches the scvm; he headdress is
          buttressed with bone struts connected to his sholders to keep the
          thing in place.{" "}
          <span style={{ color: "#FF7F50" }}>
            "I am Festus, welcome seekers.
          </span>
          "
          <br />
          <br />
          <ImageComponent
            image={rotPriest}
            caption="Rot Priest"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="Rot Priests of Akuhn, the demon child of the Death Ziggurat."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          As the party sits and speaks with Festus, he explains{" "}
          <span style={{ color: "#FF7F50" }}>
            they are the exploratory arm of a religious order in Schleswig that
            are investigating the newly uncovered, and long-lost story of the
            Death Ziggurat and the Demon Child Akunh
          </span>
          . They wonder if there is a connection to the meteor that fill the
          forever-night sky, or if the story is even true. They have camped at
          the edges of the cliff that define the fist-shaped crater of the Death
          Ziggurat area waiting for a sign. The party is the sign. With a few
          deft rolls,{" "}
          <span style={{ color: "#FF7F50" }}>
            Hildebrand convinces ten of the cultists to accompany the party to
            the death ziggurat
          </span>
        </div>
      ),
    },
    {
      title: "The Spiral Chapel",
      text: (
        <div className="morkBorgFont morkBorgTitleText">
          The <span style={{ color: "#FF7F50" }}>mini-hex crawl</span> begins.
          Each hex takes up 15 minutes to travel and 15 minutes for searching to
          find goodies.
          <br />
          <br />
          They encounter priests that are completely unlike the cultists. A
          group of four sits at a campfire. They wear cloaks and rounded hats
          with circular brims. They explain that they are philosophers also
          seeking truth to the uncovered rumor of Akuhn. They are friendly and
          say they will help the party, but{" "}
          <span style={{ color: "#FF7F50" }}>
            they must seal the deal with a hug
          </span>
          . Sir Fat Head questions this, but the rot priests explain how it
          engenders trust and some other major bullship. Sir Fat Head goes in
          for the hug. All four priests hug our mushroom hunter and the leader
          whispers in his ear, "you should have worried less about what was up
          my sleeve and more about what was under here";{" "}
          <span style={{ color: "#FF7F50" }}>
            the rot priest peels back his robe to reveal a vertical mouth in his
            torso rimmed with sharp, supernatural teeth
          </span>
          .
          <br />
          <br />
          In the ensuing battle, Jacque breaks his knife, but the Croaking
          Trident and Hildebrand's scythe prove extremely deadly. The cultists
          try to help but they are moderate to poorly effective fighters.
          <br />
          <br />
          As the party continues they find a few silver and some knickknacks,
          but they also find more small gangs of Rot Priests. A group of four,
          then a group of three and so on. Even though the rots priests are
          defeated each time, they are making 100% of their morale checks, thus
          proving their fanatical and complete devotion to Akuhn. Sir Fat Head
          cleaves one priest, opening the torso mouth from chin to crotch with a
          great hit. As usual the trident continues to serve, but after FIVE
          kills, Sir Fat Head notes that Winston has increasinggly bulging eyes,
          slimy sweat and discolored skin. It would seem the trident does enact
          a transformation as the cosmic frogs promised. Sir Fat Head suggests
          Winston let him use the trident for a bit; but Winston gives a
          Clockwork Orange grin and says, "no . . . it is mine".{" "}
          <span style={{ color: "#FF7F50" }}>
            Winston can choose to use another weapon, but at this point he will
            never willingly hand over the trident to another person
          </span>
          .
          <br />
          <br />
          <ImageComponent
            image={mural}
            caption="Mural"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="A mural in the Spiral Chapel."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          The party approaches a{" "}
          <span style={{ color: "#FF7F50" }}>
            chapel with a circular construction and a domed roof
          </span>{" "}
          decorated to look like a spiral toward the sky. Inside the chapel the
          party finds a depression in the floor with a concave pattern in the
          middle with a mural overhead, the dome painted to look like a night
          sky that is reminiscent of the medallions the party has taken off the
          bodies of the rots priests.
          <br />
          <br />
          There is also a{" "}
          <span style={{ color: "#FF7F50" }}>
            large tile mosaic on the wall
          </span>
          . The mosiac forms a mural of a priest gesturing and gazing up at the
          night sky. As the party examines the mural, Hildebrand hears a tiny
          scuttle sound and catches a glimpse of a humanoid darting back thru
          the front door of the chapel.
          <br />
          <br /> The party gives chase to the lanky figure loping through the
          snowy forest. At last, the figure stops running at the edge of a
          frozen lake and turns to face his chasers. Using ENOCHIAN SYNTAX,
          Hildebrand is able to calm the figure and learn its story. The person
          is a supernatural, ethereal demon ghost named Sarku.
          <ImageComponent
            image={sarku}
            caption="Sarku"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="Sarku, former advisor to Akunh before her corruption at the hands of demonic powers."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <span style={{ color: "#FF7F50" }}>
            Sarku was the advisor to Akuhn
          </span>{" "}
          but left her as the demonic blood in her half-breed parentage began to
          take over. Sarku was cursed to become a demonic ghost trapped in the
          Dying Lands as an ethereal spectre.{" "}
          <span style={{ color: "#FF7F50" }}>
            He promises to reveal all he knows of the Death Ziggurat if he is
            allowed to drink the blood of a willing sacrifice so he may finally
            leave the Dying Lands and move on
          </span>
          .
          <br />
          <br />
          At this point the entire party is back inside the Spiral Chapel and
          out of the weather. At first Herman steps forward to offer himself up,
          but Sarku is honest and sensing Herman's excitement, Sarku makes it
          clear there is no becoming a vampire or anything cool . . . he will
          drink his sacrifice until they get to 0 hp, and then they will either
          die or recover. In light of this knowledge, Herman says, "well, Jacque
          Manque has the best blood".
          <br />
          <br />
          With the help of the cultist leader Festus,{" "}
          <span style={{ color: "#FF7F50" }}>
            the party convices one of the other nine cultists to give up the
            ghost
          </span>{" "}
          happily. Sarku feeds and explains as he fades how to touch the eyes of
          the mosiac to open a comparment with a metal spiral which can then be
          placed in the concave pattern in the floor of the Spiral Chapel. And
          then, there is a silver crown adorned with a hallow of many jewels in
          a spiral pattern. Sarku knows that the artifact hurts Akuhn but will
          also draw her wrath. He explains that the medallions will be needed to
          enter the semi-ethereal Death Ziggurat proper, and finally that Akuhn
          can be killed by finding her living heart at the end of a tunnel in
          the Death Ziggurat.{" "}
          <span style={{ color: "#FF7F50" }}>
            "Follow the sounds of a beating heart and stab the black heart to
            kill her"
          </span>
          .
          <br />
          <br />
          Everyone rolls phenomenal Get Better (except Sir Fat Head who gets a
          minus 1 on all four stats, such is the whim of HE and of SHE and
          Necrubel). Next time, the party will noodle over the crown and the
          very near Death Ziggurat itself!
          <br />
          <br />
          <h3>The Wrapup</h3>
          <div
            style={{ border: "3px solid red", padding: "5px", margin: "5px" }}
          >
            Misery Countdown:
            <ul>
              <li>
                Psalm 6, Verse 1: You shall know the last day is come. The sun
                shall set and never rise.
                <ul>
                  <li>
                    EFFECT: sun never shines. World is dark always. No more
                    daytime.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          This session saw KLATU and SPUG sitting out.
          <br />
          <br />
          This episode of Malum Mortis brought to you in part by:
          <br />
          Module:{" "}
          <a
            href="https://www.drivethrurpg.com/product/328612/MORK-BORG-CULT-FERETORY"
            rel="noreferrer"
          >
            FERETORY.
          </a>
          <br />
          Game:{" "}
          <a
            href="https://morkborg.com/content/"
            rel="noreferrer"
          >
            Mork Borg
          </a>
          <br />
          Module (METAPLOT):{" "}
          <a
            href="https://www.drivethrurpg.com/product/423719/Malum-Mortis-A-Mork-Borg-Metaplot"
            rel="noreferrer"
          >
            Malum Mortis: A Mork Borg Metaplot.
          </a>
          <br />
        </div>
      ),
      image: {
        imagePath: mural,
        altText: `Mural in the spiral chapel.`,
        className: "imageLeft",
        imageCaption: "Mural in the Spiral Chapel",
      },
      image2: {
        imagePath: sarku,
        altText: `Sarku, former advisor to Akuhn and current demon ghost trapped in the Dying Lands.`,
        className: "imageRight",
        imageCaption: "Sarku",
      },
    },
  ];

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/mork-borg/bkgnd-01.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <Container>
      {/* <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin" 
                    style={{position: 'float', float: 'right',width: '120px', height: '240px'}} marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=smokeraven-20&language=en_US&marketplace=amazon&region=US&placement=9189143701&asins=9189143701&linkId=d1384e0e6b9ef083fee35d7272a71f88&show_border=true&link_opens_in_new_window=true"></iframe> */}
      <Row>
        <Col>
          <div
            style={{ backgroundColor: "transparent", fontFamily: "Orbitron" }}
          >
            <main role="main" className="inner cover text-center">
              <img
                src={mbLogo}
                style={{ maxWidth: "200px" }}
                alt="Malum Mortis Mork Borg campaign"
              />
            </main>
            <div style={pageHeader}>
              <h1 className="cover-heading">Session 10: Malum Mortis</h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title className="cardTitle-mork-borg">
                Cosmic space frogs transport the scvm to the edges of crater in
                Sarkash forest near the Death Ziggurat.
              </Card.Title>
              <div>
                <ContentContainer data={section1Data} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MBMalumMortisSession10;
