import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import kyle from "../../../../assets/myz/ark-kyle-mutant.jpg";
import hulagu_red from "../../../../assets/myz/ark-mutant-hulagu-token-red.png";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Bookkeeping",
    text: (
      <p>
        05-17-0001: We took care of non-party business first which included:
        <br />
        <br />
        <ul>
          <li>
            BITTERBEASTS: hatchling and training rolls. One new hatchling.
          </li>
          <li>&nbsp;</li>
          <li>
            PROJECT ROLLS: Defenses takes 6 points damage from Zone quakes. +3
            points to Workshop and +3 points to Militia.
          </li>
          <li>&nbsp;</li>
          <li>
            PURE PLANT PATROL: the fifth Pure Plant patrol goes really well. +12
            water to Ark general storage.
          </li>
        </ul>
        <br />
        <br />
      </p>
    ),
    image: {
      imagePath: hulagu_red,
      altText: `Boss Hulagu gets a cosmetic mutation misfire and his skin turns red`,
      className: "imageLeft",
      imageCaption: `Hulagu Red`,
    },
  },
  {
    title: "Deck the Halls With Sounds of Gunfire",
    text: (
      <p>
        The IL gang has recued six Givers and a seventh giver named Freddy that
        Smoke Raven had promised to rescue. As the mutants and their Giver
        allies come up from the basement, they are met with resistance in the
        hallway as human Giver Guards and their female mutant overlords are
        behind tables, around doorways, and determined to stop the interlopers.
        <br />
        <br />
        Some highlights of the hall fight include:
        <br />
        <br />
        <ul>
          <li>
            Kyle the ex-skag mutant was shot while pulling on the chainsaw. He
            gets BROKEN TOES as critical injury.
          </li>
          <li>
            Boss Hulagu gets a mutation misfire leading to a cosmetic change and
            his skin becomes red. Sort of like when Mt. Dew suddenly had Code
            Red on the shelves.
          </li>
          <li>
            Chromicus uses INTIMIDATE to give some of the human guards pause as
            the mutants furiously try to keep them motivated.
          </li>
          <li>
            Gladhand uses his SPRINTER mutation to run up to enemy human guards
            and cajole them to join the revolution whilst sprinting back on the
            same turn. Psychological warfare.
          </li>
          <li>
            Kyle is shot dead while broken before he can get back on his feet.
            RIP
          </li>
          <li>
            Gladhand does the sprint into enemy lines then back again, but this
            time he slaps one of the mutant guards and tells the humans, "Join
            us! They're nothing!"
          </li>
          <li>
            Double Bird uses Telepathy to target a human guard and convert them
            into an ally in addition to Gladhand and Chromicus' psychological
            tricks.
          </li>
          <li>
            Double Bird gets a cosmetic mutation misfire and gains a third eye
            in the middle of his forehead in addition to his thorn hair.
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: kyle,
      altText: `Kyle, an ex-skag mutant turned ally after being found in the Zone, died in hallway battle at the Diamond Center.`,
      className: "imageLeft",
      imageCaption: "Kyle the Unmotivated",
    },
  },

  {
    title: "Wrapup",
    text: (
      <p>
        Two weeks passed before GM realized no session body count was rolled, so
        its a freebie. All the action is still taking place on day 05-17-0001
        with no need to worry about rot, food or water. Next session the action
        picks up in the hallway where the IL gang has just defeated the mutants
        and whatever givers they could not convince to join their revolution.
        <br />
        <br />
        Next session will begin late afternoon/evening of 05-17-0001. We awarded
        four XP at end of session, There is no prep at all since food, water and
        rot of no concern in ark. We will do XP spend, award session mutation
        points, and get rolling.
        <br />
        <br />
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageRight",
      imageCaption: "",
    },
  },
];

const MYZAnchortownSession27 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 27: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  The IL gang frees the givers in the Diamond Center and fights
                  their way down the hallways back toward the lobby.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession27;
