import React from 'react';
import {encounters as encounters_ravenland, terrainList as terrain_ravenland} from '../../../src/json/encounter_data_gamemasters_guide';
import {encounters as encounters_bitterreach, terrainList as terrain_bitterreach} from '../../../src/json/encounter_data_bitter_reach';
import forbiddenLands from "../../../src/assets/Forbidden Lands Map Complete Basic 6000w.jpg"
import forbiddenLandsKin from "../../../src/assets/Forbidden Lands Map Complete Basic 6000w - kin map.jpg"
import bitterReach from "../../../src/assets/bitter-reach-map.jpg"
import bitterReachHexOverlay from "../../../src/assets/bitter-reach-map-hex-overlays.jpg"
import background2 from "../../../src/assets/forbidden-lands-map-hex-overly-no-text-1920-background.jpg"
import {hexData} from "../../../src/js/hexData";

// let backgroundImages = [{background1},{background2},{background3},{background4}]
const hexLetters = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','AA','AB','AC','AD','AE','AF','AG','AH','AI','AJ','AK','AL','AM','AN','AO'];
let hexNumbers = [...Array(52).keys()];


// function getRandomIndexFromList(inputList) {
//   return Math.floor(Math.random() * inputList.length);
// }

function getTerrainNew(name, terrain) {
  let z = terrain.filter(x => x.name === name)
  return (z[0]) ? z[0].freq : 0;
}

class HexTraveller extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      encountersData: null,
      selectedTerrain: '',
      terrain: '',
      encounterName: null,
      encounter: null,
      backgroundImage: null,
      ignoreNoEncounterResults: false,
      selectedCampaign: 'ravenland',
      hexLabels: null,
      input: 'g18',
      hexData: null,
      x: 0,
      y: 0
    };
  }
  buildHexLabels = () => {
    hexNumbers.shift();
    let temp = [];
    for (const x of hexLetters) {
      for (const y of hexNumbers) {
         temp.push(x+y);
      }
    }
    this.setState({hexLabels: temp});
  }
  randomEncounterMonster = (encounterListNew) => {
    let r = Math.floor(Math.random() * encounterListNew.length);
    return (encounterListNew[r])?encounterListNew[r].name:null;
}
  buildTerrainList = () => {
    //NOTE: in future we may combine all terrain lists into master of unique terrains
    if(this.state.selectedCampaign === 'bitter reach') {
      return terrain_bitterreach;
    }
    return terrain_ravenland;
  }
  createSelectItems = () => {
    let items = [];
    let terrainList = this.buildTerrainList();
    items.push(<option key={-1} value={-1}></option>);
    terrainList.forEach(function (item, index) {
      items.push(<option key={index} value={item}>{item.toUpperCase()}</option>);
    });
    return items;
  }

  componentDidMount() {
    this.buildHexLabels();

    this.setState({backgroundImage: background2})
    this.setState({encountersData: this.getCampaignData()})
  }
  handleChangeNoEncounter = (e) => {
    this.setState({ignoreNoEncounterResults: e.target.checked});
  }
  getCampaignData = (name) => {
    switch(name) {
      case 'bitter reach':
        return encounters_bitterreach
      default:
        return encounters_ravenland
    }
  }
  handleChangeCampaign = (e) => {
    let selectedCampaign = e.target.value;
    this.setState({selectedCampaign: e.target.value}, this.createSelectItems);
    this.setState({encountersData: this.getCampaignData(selectedCampaign)})
  }
  /*
Create list of objects that have encounter names. There are as many rows with same encounter name
as their designated frequency.
NOTE: convert to binary tree search - https://stackoverflow.com/questions/16927299/coding-random-encounter
[
  {name: "bloodmist"}
  {name: "bloodmist"}
  {name: "death from above"}
  {name: "death from above"}
  {name: "the harpies' feast"}
  {name: "the harpies' feast"}
]
 */
  buildEncounterList = (terrain, ignoreNoEncounters) => {
  if (!terrain) {
    return
  }
  let encounters = this.state.encountersData
  let encounterList = []
  for (let name in encounters) {
    if (ignoreNoEncounters && name === 'no encounter') { continue; }
    let frequency = getTerrainNew(terrain, encounters[name].terrain)
    for (let i = 0; i < frequency; i++) {
      encounterList.push({'name': name})
    }
  }
  return encounterList;
}

 changeBackground = (e) =>{
    e.target.style.background = 'red';
  }
   mouseLeave = (e) =>{
    e.target.style.background = 'green';
  }


handleChange = (e) => {
    this.setState({ input: e.target.value });
  }
  handleClick = (e) => {
    // let newImageObj = backgroundImages[getRandomIndexFromList(backgroundImages)];
    // let newImage = Object.values(newImageObj)[0];
    let input = this.state.input;
    if (input) {
    const d = hexData.filter(x=>x['name'] === input.toUpperCase())
    this.setState({hexData: d[0]})
    }
  }
selectRegion = (x,y) => {
  console.log('SELECT REGION')
}
  render() {

let backgroundStyle = {
  backgroundImage: `url(${this.state.backgroundImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed',
  backgroundSize: 'cover',
  width:'100vw',
  height:'2000px'
}

    return (
      <div style={backgroundStyle}>
        <div style={{backgroundColor: '#ffffff',padding: 20,opacity:0.7,width:'50vw',margin:'0 auto'}}>
          <center><h2>Forbidden Lands Campaign Maps with Hex Overlay</h2>
            <br/>
            <a href={forbiddenLands} target='_blank' rel="noopener noreferrer">Forbidden Lands Map Hex Overlax (6000 x 4346)</a>
            <br/><br/>
            <a href={forbiddenLandsKin} target='_blank' rel="noopener noreferrer">Forbidden Lands Kin Map Hex Overlax (6000 x 4346)</a>
            <br/><br/>
            <a href={bitterReachHexOverlay} target='_blank' rel="noopener noreferrer">Bitter Reach Map Hex Overlay (6236 x 4503)</a>
            <br/><br/>
            <a href={bitterReach} target='_blank' rel="noopener noreferrer">Bitter Reach Map Basic (6236 x 4503)</a>
            <br/><br/>
          </center>
        </div>
      </div>
    );
  }
}

export default HexTraveller;