import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import bladesLogo from "../../../../src/assets/blades-in-the-dark/logo-blades-in-the-dark.png";
import sectionDivider from "../../../../src/assets/blades-in-the-dark/banner-london-sky.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import { useEffect } from "react";

import scoundrels from "../../../../src/assets/blades-in-the-dark/mood-scoundrels.webp";
import dividerKnife from "../../../../src/assets/blades-in-the-dark/divider-knife-white-right-point.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "",
    text: (
      <div class="bitdFont morkBorgTitleText">
        <p class="bitdFontItalic">
          <img
            style={{
              maxWidth: "450px",
              verticalAlign: "top",
              margin: "0px 20px 20px 0px",
            }}
            align="left"
            src={scoundrels}
            alt="Scoundrels in the foggy city of Doskvol"
          />
          You're scoundrels in the foggy city of Doskvol, a haunted
          Victorian-era city surrounded by a wall of lightning powered by demon
          blood.
        </p>
        <div class="container">
          <img
            style={{
              maxHeight: "50px",
              maxWidth: "600px",
              minWidth: "200px",
              width: "200px",
              margin: "0px auto",
              display: "block",
            }}
            align="center"
            src={dividerKnife}
            alt="knife divider"
          />
        </div>
        <p class="bitdFontItalic">
          You and your crew try to make scores, take over turf, and gain
          reuptation.
        </p>
        <div class="container">
          <img
            style={{
              maxHeight: "50px",
              maxWidth: "600px",
              minWidth: "200px",
              width: "200px",
              margin: "0px auto",
              display: "block",
            }}
            align="center"
            src={dividerKnife}
            alt="knife divider"
          />
        </div>
        <p>
          The game takes place in the cold, foggy city of <b>Doskvol</b> (aka
          Duskwall or “the Dusk”). It's industrial in its development. Imagine a
          world like ours during the second industrial revolution of the
          1870s—there are trains, steam-boats, printing presses, simple
          electrical technology, carriages, and the black smog of chimney smoke
          everywhere. Doskvol is something like a mashup of Venice, London, and
          Prague. It's crowded with row-houses, twisting streets, and
          criss-crossed with hundreds of little waterways and bridges.
          <br />
          <br />
          The city is also a <b>fantasy</b>. The world is in perpetual darkness
          and haunted by ghosts—a result of the cataclysm that shattered the sun
          and broke the Gates of Death a thousand years ago. The cities of the
          empire are each encircled by crackling lightning towers to keep out
          the vengeful spirits and twisted horrors of the deathlands. To power
          these massive barriers, the titanic metal ships of the leviathan
          hunters are sent out from Doskvol to extract electroplasmic blood from
          massive demonic terrors upon the ink-dark Void Sea.
          <br />
          <br />
        </p>
      </div>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
];

const BITDClassesOverview = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/blades-in-the-dark/mood-doskvol_street-scene-01.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={bladesLogo}
                  style={{ maxWidth: "200px" }}
                  alt="Blades in the Dark campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">What is Blades in the Dark?</h1>
                <p className="lead leadExtra">
                  <div
                    class="bitdFont morkBorgText"
                    style={{ textAlign: "center" }}
                  >
                    💀{" "}
                    <a
                      style={{ color: "yellow" }}
                      href="./blades-in-the-dark-classes-core"
                    >
                      Classes
                    </a>{" "}
                    💀
                  </div>
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img
                src={sectionDivider}
                style={{ maxHeight: "169px" }}
                alt="section divider"
              />
              <Card.Body>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BITDClassesOverview;
