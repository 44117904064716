export const hexData = [
  {
    "name": "A1",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B1",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C1",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D1",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E1",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F1",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "G1",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "H1",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "I1",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "J1",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "K1",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "L1",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "M1",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "N1",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "O1",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "P1",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Q1",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "R1",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "S1",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "T1",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "U1",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "V1",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "W1",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "X1",
    "terrain": "stillmist",
    "includes": [],
    "special": [
      "stillmist"
    ],
    "adventure": null
  },
  {
    "name": "Y1",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Z1",
    "terrain": "stillmist",
    "includes": [],
    "special": [
      "stillmist"
    ],
    "adventure": null
  },
  {
    "name": "AA1",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AB1",
    "terrain": "high mountains",
    "includes": [],
    "special": [
      "stillmist"
    ],
    "adventure": null
  },
  {
    "name": "AC1",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AD1",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AE1",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AF1",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AG1",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AH1",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI1",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AJ1",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK1",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL1",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM1",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN1",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO1",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A2",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B2",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C2",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D2",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E2",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F2",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "G2",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "H2",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "I2",
    "terrain": "mountains",
    "includes": [],
    "region": "canide",
    "kin": "canide dwarves",
    "special": [],
    "adventure": null
  },
  {
    "name": "J2",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "K2",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "L2",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "M2",
    "terrain": "mountains",
    "includes": [
      "plains"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "N2",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "O2",
    "terrain": "mountains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "P2",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Q2",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "R2",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "S2",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "T2",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "U2",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "V2",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "W2",
    "terrain": "high mountains",
    "includes": [],
    "special": [
      "stillmist"
    ],
    "adventure": null
  },
  {
    "name": "X2",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Y2",
    "terrain": "forest",
    "includes": [],
    "special": [
      "stillmist"
    ],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "Z2",
    "terrain": "stillmist",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AA2",
    "terrain": "stillmist",
    "includes": [
      "high mountains"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "AB2",
    "terrain": "stillmist",
    "includes": [
      "high mountains"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "AC2",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AD2",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AE2",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AF2",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AG2",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AH2",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI2",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AJ2",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK2",
    "terrain": "mountains",
    "includes": [
      "forest",
      "valley"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL2",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM2",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN2",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO2",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "far vivend",
    "kin": "elvenspring"
  },
  {
    "name": "A3",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B3",
    "terrain": "mountains",
    "includes": [],
    "special": [
      "belderand"
    ],
    "adventure": null
  },
  {
    "name": "C3",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D3",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E3",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F3",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "G3",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "H3",
    "terrain": "high mountains",
    "includes": [],
    "region": "canide",
    "kin": "canide dwarves",
    "special": [],
    "adventure": null
  },
  {
    "name": "I3",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "J3",
    "terrain": "mountains",
    "includes": [],
    "region": "canide",
    "kin": "canide dwarves",
    "special": [],
    "adventure": null
  },
  {
    "name": "K3",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "L3",
    "terrain": "mountains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "M3",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "N3",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "O3",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "P3",
    "terrain": "forest",
    "includes": [
      "river",
      "mountains"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "Q3",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "R3",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "S3",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "T3",
    "terrain": "mountains",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "U3",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "V3",
    "terrain": "mountains",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": "dungeon"
  },
  {
    "name": "W3",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "X3",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "Y3",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Z3",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "AA3",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AB3",
    "terrain": "forest",
    "includes": [
      "mountains"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "AC3",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AD3",
    "terrain": "mountains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": "village"
  },
  {
    "name": "AE3",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AF3",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": "dungeon"
  },
  {
    "name": "AG3",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AH3",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI3",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AJ3",
    "terrain": "mountains",
    "includes": [
      "plains"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK3",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL3",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "far vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AM3",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN3",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "far vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AO3",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A4",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B4",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C4",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D4",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E4",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": "dungeon"
  },
  {
    "name": "F4",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "G4",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "H4",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "I4",
    "terrain": "mountains",
    "includes": [],
    "region": "canide",
    "kin": "canide dwarves",
    "special": [
      "fog"
    ],
    "adventure": null
  },
  {
    "name": "J4",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "K4",
    "terrain": "mountains",
    "includes": [],
    "region": "canide",
    "kin": "canide dwarves",
    "special": [],
    "adventure": "castle"
  },
  {
    "name": "L4",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "M4",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "N4",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "O4",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "P4",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Q4",
    "terrain": "forest",
    "includes": [
      "river",
      "mountains"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "R4",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "S4",
    "terrain": "forest",
    "includes": [
      "mountains"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "T4",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "U4",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "V4",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "W4",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "X4",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Y4",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "Z4",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AA4",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "AB4",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AC4",
    "terrain": "plains",
    "includes": [
      "river",
      "forest"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "AD4",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AE4",
    "terrain": "plains",
    "includes": [
      "mountains",
      "river",
      "canyon"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "AF4",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AG4",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AH4",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI4",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": "castle",
    "region": "far vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AJ4",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK4",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "far vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AL4",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM4",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "far vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AN4",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO4",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A5",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B5",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C5",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D5",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E5",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F5",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": "dungeon"
  },
  {
    "name": "G5",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "H5",
    "terrain": "high mountains",
    "includes": [
      "fog"
    ],
    "region": "canide",
    "kin": "canide dwarves",
    "special": [],
    "adventure": null
  },
  {
    "name": "I5",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "J5",
    "terrain": "mountains",
    "includes": [
      "fog"
    ],
    "region": "canide",
    "kin": "canide dwarves",
    "special": [],
    "adventure": null
  },
  {
    "name": "K5",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "L5",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": "dungeon"
  },
  {
    "name": "M5",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "N5",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "O5",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "P5",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "Q5",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "R5",
    "terrain": "mountains",
    "includes": [
      "forest",
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "S5",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "T5",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": "village",
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "U5",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "V5",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "W5",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "X5",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "Y5",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Z5",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "AA5",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AB5",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "AC5",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AD5",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "AE5",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AF5",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AG5",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AH5",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI5",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AJ5",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "far vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AK5",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL5",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "far vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AM5",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN5",
    "terrain": "hills",
    "includes": [],
    "special": [],
    "adventure": "dungeon",
    "region": "far vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AO5",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A6",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B6",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C6",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D6",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E6",
    "terrain": "mountains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "F6",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "G6",
    "terrain": "mountains",
    "includes": [],
    "region": "canide",
    "kin": "canide dwarves",
    "special": [],
    "adventure": null
  },
  {
    "name": "H6",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "I6",
    "terrain": "plains",
    "includes": [
      "fog"
    ],
    "region": "canide",
    "kin": "canide dwarves",
    "special": [],
    "adventure": null
  },
  {
    "name": "J6",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "K6",
    "terrain": "quagmire",
    "includes": [
      "river"
    ],
    "region": "canide",
    "kin": "canide dwarves",
    "special": [],
    "adventure": null
  },
  {
    "name": "L6",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "M6",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "N6",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "O6",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "P6",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Q6",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "R6",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "S6",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "T6",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "U6",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "V6",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "W6",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "X6",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Y6",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "Z6",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AA6",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "AB6",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AC6",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "AD6",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AE6",
    "terrain": "mountains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "AF6",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AG6",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": "dungeon"
  },
  {
    "name": "AH6",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI6",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "far vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AJ6",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK6",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "far vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AL6",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM6",
    "terrain": "plains",
    "includes": [
      "hills"
    ],
    "special": [],
    "adventure": null,
    "region": "far vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AN6",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO6",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A7",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B7",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C7",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D7",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E7",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F7",
    "terrain": "mountains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "G7",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "H7",
    "terrain": "mountains",
    "includes": [
      "river"
    ],
    "region": "canide",
    "kin": "canide dwarves",
    "special": [],
    "adventure": null
  },
  {
    "name": "I7",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "J7",
    "terrain": "quagmire",
    "includes": [
      "plains"
    ],
    "region": "canide",
    "kin": "canide dwarves",
    "special": [],
    "adventure": null
  },
  {
    "name": "K7",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "L7",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "M7",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "N7",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "O7",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "P7",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "Q7",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "R7",
    "terrain": "mountains",
    "includes": [
      "forest",
      "river"
    ],
    "special": [],
    "adventure": "dungeon"
  },
  {
    "name": "S7",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "T7",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "U7",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "V7",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "W7",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "X7",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "Y7",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Z7",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "AA7",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AB7",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "AC7",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AD7",
    "terrain": "plains",
    "includes": [
      "river",
      "forest"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "AE7",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AF7",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AG7",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AH7",
    "terrain": "plains",
    "includes": [
      "quagmire"
    ],
    "special": [],
    "adventure": null,
    "region": "far vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AI7",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AJ7",
    "terrain": "plains",
    "includes": [
      "lake"
    ],
    "special": [],
    "adventure": null,
    "region": "far vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AK7",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL7",
    "terrain": "hills",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "far vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AM7",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN7",
    "terrain": "lake",
    "includes": [
      "plains"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO7",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A8",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B8",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C8",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D8",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E8",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": "castle"
  },
  {
    "name": "F8",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "G8",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "H8",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "I8",
    "terrain": "quagmire",
    "includes": [
      "river"
    ],
    "region": "canide",
    "kin": "canide dwarves",
    "special": [],
    "adventure": null
  },
  {
    "name": "J8",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "K8",
    "terrain": "quagmire",
    "includes": [
      "river"
    ],
    "region": "canide",
    "kin": "canide dwarves",
    "special": [],
    "adventure": null
  },
  {
    "name": "L8",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "M8",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "N8",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "O8",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": "castle",
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "P8",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Q8",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "R8",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "S8",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "T8",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "U8",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "V8",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "W8",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "X8",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Y8",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": "castle",
    "region": "dankwood",
    "kin": "ents"
  },
  {
    "name": "Z8",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AA8",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "AB8",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AC8",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "AD8",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AE8",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AF8",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AG8",
    "terrain": "quagmire",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AH8",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI8",
    "terrain": "plains",
    "includes": [
      "lake",
      "river",
      "forest"
    ],
    "special": [],
    "adventure": "village",
    "region": "far vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AJ8",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK8",
    "terrain": "lake",
    "includes": [
      "plains"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL8",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM8",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN8",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO8",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A9",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B9",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C9",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D9",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E9",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F9",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "G9",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "H9",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "I9",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "J9",
    "terrain": "quagmire",
    "includes": [
      "river"
    ],
    "region": "canide",
    "kin": "canide dwarves",
    "special": [],
    "adventure": null
  },
  {
    "name": "K9",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "L9",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "M9",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "N9",
    "terrain": "forest",
    "includes": [
      "plains"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "O9",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "P9",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "Q9",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "R9",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "S9",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "T9",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "U9",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "V9",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "W9",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "X9",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "ents"
  },
  {
    "name": "Y9",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Z9",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "AA9",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AB9",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "AC9",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AD9",
    "terrain": "mountains",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": "castle"
  },
  {
    "name": "AE9",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AF9",
    "terrain": "quagmire",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AG9",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AH9",
    "terrain": "forest",
    "includes": [
      "quagmire"
    ],
    "special": [],
    "adventure": null,
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AI9",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AJ9",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK9",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL9",
    "terrain": "lake",
    "includes": [
      "plains"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM9",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN9",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO9",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A10",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B10",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C10",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D10",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E10",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F10",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "G10",
    "terrain": "plains",
    "includes": [],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "H10",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "I10",
    "terrain": "hills",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "J10",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "K10",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "L10",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "M10",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "N10",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "O10",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "P10",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Q10",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "R10",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "S10",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "T10",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "U10",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "V10",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "W10",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "X10",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Y10",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "ents"
  },
  {
    "name": "Z10",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AA10",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "AB10",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AC10",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "AD10",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AE10",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AF10",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AG10",
    "terrain": "quagmire",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AH10",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI10",
    "terrain": "plains",
    "includes": [
      "river",
      "lake"
    ],
    "special": [],
    "adventure": null,
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AJ10",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK10",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL10",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM10",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN10",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO10",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A11",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B11",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C11",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D11",
    "terrain": "mountains",
    "includes": [],
    "special": [
      "stonegarden"
    ],
    "adventure": "dungeon"
  },
  {
    "name": "E11",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F11",
    "terrain": "hills",
    "includes": [],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "G11",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "H11",
    "terrain": "hills",
    "includes": [],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "I11",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "J11",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "K11",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "L11",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "M11",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "N11",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": "village"
  },
  {
    "name": "O11",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "P11",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "Q11",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "R11",
    "terrain": "forest",
    "includes": [
      "river",
      "lake"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "S11",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "T11",
    "terrain": "forest",
    "includes": [
      "river",
      "lake"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "U11",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "V11",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "W11",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "X11",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "ents"
  },
  {
    "name": "Y11",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Z11",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "AA11",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AB11",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "AC11",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AD11",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "AE11",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AF11",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AG11",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AH11",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AI11",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AJ11",
    "terrain": "lake",
    "includes": [
      "plains"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK11",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL11",
    "terrain": "forest",
    "includes": [
      "plains"
    ],
    "special": [
      "maidenholm"
    ],
    "adventure": null
  },
  {
    "name": "AM11",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN11",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO11",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A12",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B12",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C12",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D12",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E12",
    "terrain": "mountains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "F12",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "G12",
    "terrain": "plains",
    "includes": [],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "H12",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "I12",
    "terrain": "mountains",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "J12",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "K12",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "L12",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "M12",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "N12",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "O12",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "P12",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Q12",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "R12",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "S12",
    "terrain": "forest",
    "includes": [
      "lake"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "T12",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "U12",
    "terrain": "forest",
    "includes": [
      "river",
      "lake"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "V12",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "W12",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "X12",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Y12",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "Z12",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AA12",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "AB12",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AC12",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": "village",
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AD12",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AE12",
    "terrain": "forest",
    "includes": [
      "river",
      "plains"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "AF12",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AG12",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AH12",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI12",
    "terrain": "plains",
    "includes": [
      "lake"
    ],
    "special": [],
    "adventure": null,
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AJ12",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK12",
    "terrain": "forest",
    "includes": [
      "plains",
      "island"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL12",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM12",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN12",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO12",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A13",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B13",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C13",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D13",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "E13",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F13",
    "terrain": "hills",
    "includes": [],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "G13",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "H13",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "feulenmark",
    "kin": "urhur orcs"
  },
  {
    "name": "I13",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "J13",
    "terrain": "mountains",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": "dungeon"
  },
  {
    "name": "K13",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "L13",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "M13",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "N13",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "O13",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "P13",
    "terrain": "plains",
    "includes": [
      "river",
      "mountains"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "Q13",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "R13",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "S13",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "T13",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "U13",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "V13",
    "terrain": "lake",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "W13",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "X13",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "Y13",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Z13",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "AA13",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AB13",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AC13",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AD13",
    "terrain": "plains",
    "includes": [
      "river",
      "forest"
    ],
    "special": [],
    "adventure": null,
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AE13",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AF13",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AG13",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AH13",
    "terrain": "plains",
    "includes": [
      "river",
      "lake"
    ],
    "special": [],
    "adventure": null,
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AI13",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AJ13",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK13",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL13",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM13",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN13",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO13",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A14",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B14",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C14",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D14",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E14",
    "terrain": "mountains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "F14",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "G14",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "H14",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "I14",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "feulenmark",
    "kin": "urhur orcs"
  },
  {
    "name": "J14",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "K14",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "feulenmark",
    "kin": "urhur orcs"
  },
  {
    "name": "L14",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "M14",
    "terrain": "mountains",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": "dungeon"
  },
  {
    "name": "N14",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "O14",
    "terrain": "mountains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "P14",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Q14",
    "terrain": "plains",
    "includes": [
      "river",
      "lake"
    ],
    "special": [],
    "adventure": "village"
  },
  {
    "name": "R14",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "S14",
    "terrain": "lake",
    "includes": [],
    "special": [
      "the spire of quetzel"
    ],
    "adventure": null
  },
  {
    "name": "T14",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "U14",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "V14",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "W14",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "X14",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Y14",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "Z14",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AA14",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AB14",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AC14",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AD14",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AE14",
    "terrain": "forest",
    "includes": [
      "plains"
    ],
    "special": [],
    "adventure": null,
    "region": "dankwood",
    "kin": "elves"
  },
  {
    "name": "AF14",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AG14",
    "terrain": "plains",
    "includes": [
      "marshland"
    ],
    "special": [],
    "adventure": "village",
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AH14",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI14",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AJ14",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK14",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL14",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM14",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN14",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO14",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A15",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B15",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C15",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D15",
    "terrain": "mountains",
    "includes": [
      "river",
      "lake"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "E15",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F15",
    "terrain": "quagmire",
    "includes": [
      "river"
    ],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [
      "ravenhole"
    ],
    "adventure": "village"
  },
  {
    "name": "G15",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "H15",
    "terrain": "forest",
    "includes": [
      "plains"
    ],
    "special": [],
    "adventure": null,
    "region": "feulenmark",
    "kin": "urhur orcs"
  },
  {
    "name": "I15",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "J15",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "feulenmark",
    "kin": "urhur orcs"
  },
  {
    "name": "K15",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "L15",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "feulenmark",
    "kin": "urhur orcs"
  },
  {
    "name": "M15",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "N15",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "O15",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "P15",
    "terrain": "mountains",
    "includes": [
      "river",
      "lake"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "Q15",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "R15",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "S15",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "T15",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "U15",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "V15",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "W15",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "X15",
    "terrain": "plains",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": "castle",
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "Y15",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Z15",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AA15",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AB15",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AC15",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AD15",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AE15",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AF15",
    "terrain": "plains",
    "includes": [
      "river",
      "lake"
    ],
    "special": [],
    "adventure": null,
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AG15",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AH15",
    "terrain": "marshland",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI15",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AJ15",
    "terrain": "ocean",
    "includes": [
      "plains",
      "forest",
      "island"
    ],
    "special": [
      "graveyard of thunder"
    ],
    "adventure": "dungeon"
  },
  {
    "name": "AK15",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL15",
    "terrain": "lake",
    "includes": [
      "plains",
      "forest",
      "island"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM15",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN15",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO15",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A16",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B16",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C16",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D16",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E16",
    "terrain": "quagmire",
    "includes": [
      "river"
    ],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "F16",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "G16",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "H16",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "I16",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "feulenmark",
    "kin": "urhur orcs"
  },
  {
    "name": "J16",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "K16",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "feulenmark",
    "kin": "urhur orcs"
  },
  {
    "name": "L16",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "M16",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "feulenmark",
    "kin": "urhur orcs"
  },
  {
    "name": "N16",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "O16",
    "terrain": "mountains",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "P16",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Q16",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "R16",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "S16",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "T16",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "U16",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "V16",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "W16",
    "terrain": "marshland",
    "includes": [
      "lake"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "X16",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Y16",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "Z16",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AA16",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AB16",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AC16",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AD16",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AE16",
    "terrain": "plains",
    "includes": [
      "river",
      "lake"
    ],
    "special": [],
    "adventure": null,
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AF16",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AG16",
    "terrain": "marshland",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AH16",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI16",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AJ16",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK16",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL16",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM16",
    "terrain": "lake",
    "includes": [
      "plains"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN16",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO16",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A17",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B17",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C17",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D17",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E17",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F17",
    "terrain": "quagmire",
    "includes": [
      "river"
    ],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "G17",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "H17",
    "terrain": "plains",
    "includes": [
      "forest"
    ],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "I17",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "J17",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "feulenmark",
    "kin": "urhur orcs"
  },
  {
    "name": "K17",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "L17",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "feulenmark",
    "kin": "urhur orcs"
  },
  {
    "name": "M17",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "N17",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "feulenmark",
    "kin": "urhur orcs"
  },
  {
    "name": "O17",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "P17",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Q17",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "R17",
    "terrain": "marshland",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "S17",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "T17",
    "terrain": "marshland",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "U17",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "V17",
    "terrain": "marshland",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "W17",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "X17",
    "terrain": "plains",
    "includes": [
      "river",
      "marshland"
    ],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "Y17",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Z17",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": "village",
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AA17",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AB17",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AC17",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AD17",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AE17",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AF17",
    "terrain": "marshland",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AG17",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AH17",
    "terrain": "marshland",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI17",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AJ17",
    "terrain": "lake",
    "includes": [
      "plains"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK17",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL17",
    "terrain": "plains",
    "includes": [
      "lake",
      "island"
    ],
    "special": [],
    "adventure": "village",
    "region": "margelda",
    "kin": "ailanders"
  },
  {
    "name": "AM17",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN17",
    "terrain": "plains",
    "includes": [
      "ocean"
    ],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "ailanders"
  },
  {
    "name": "AO17",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A18",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B18",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C18",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D18",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E18",
    "terrain": "plains",
    "includes": [],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "F18",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "G18",
    "terrain": "plains",
    "includes": [
      "river",
      "quagmire"
    ],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "H18",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "I18",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "feulenmark",
    "kin": "urhur orcs"
  },
  {
    "name": "J18",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "K18",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": "dungeon",
    "region": "feulenmark",
    "kin": "urhur orcs"
  },
  {
    "name": "L18",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "M18",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "feulenmark",
    "kin": "urhur orcs"
  },
  {
    "name": "N18",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "O18",
    "terrain": "forest",
    "includes": [
      "lake"
    ],
    "special": [],
    "adventure": null,
    "region": "feulenmark",
    "kin": "urhur orcs"
  },
  {
    "name": "P18",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Q18",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": "village",
    "region": "arina forest",
    "kin": "roka orcs"
  },
  {
    "name": "R18",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "S18",
    "terrain": "marshland",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "T18",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "U18",
    "terrain": "marshland",
    "includes": [],
    "special": [],
    "adventure": "village"
  },
  {
    "name": "V18",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "W18",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "X18",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Y18",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "Z18",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AA18",
    "terrain": "lake",
    "includes": [
      "plains"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "AB18",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AC18",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AD18",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AE18",
    "terrain": "plains",
    "includes": [
      "river",
      "lake",
      "island"
    ],
    "special": [],
    "adventure": null,
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AF18",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AG18",
    "terrain": "plains",
    "includes": [
      "lake",
      "river",
      "island"
    ],
    "special": [],
    "adventure": null,
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AH18",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI18",
    "terrain": "plains",
    "includes": [
      "lake",
      "island"
    ],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "ailanders"
  },
  {
    "name": "AJ18",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK18",
    "terrain": "plains",
    "includes": [
      "lake",
      "island"
    ],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "ailanders"
  },
  {
    "name": "AL18",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM18",
    "terrain": "plains",
    "includes": [
      "lake"
    ],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "ailanders"
  },
  {
    "name": "AN18",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO18",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A19",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B19",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C19",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D19",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E19",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F19",
    "terrain": "quagmire",
    "includes": [
      "river"
    ],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "G19",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "H19",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "I19",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "J19",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "feulenmark",
    "kin": "urhur orcs"
  },
  {
    "name": "K19",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "L19",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "feulenmark",
    "kin": "urhur orcs"
  },
  {
    "name": "M19",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "N19",
    "terrain": "forest",
    "includes": [],
    "special": [
      "varassa"
    ],
    "adventure": null,
    "region": "feulenmark",
    "kin": "urhur orcs"
  },
  {
    "name": "O19",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "P19",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "feulenmark",
    "kin": "urhur orcs"
  },
  {
    "name": "Q19",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "R19",
    "terrain": "forest",
    "includes": [
      "plains"
    ],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "roka orcs"
  },
  {
    "name": "S19",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "T19",
    "terrain": "plains",
    "includes": [
      "lake",
      "marshland"
    ],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "U19",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "V19",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "W19",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "X19",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "Y19",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Z19",
    "terrain": "hills",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "AA19",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AB19",
    "terrain": "hills",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "AC19",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AD19",
    "terrain": "plains",
    "includes": [
      "river",
      "lake"
    ],
    "special": [],
    "adventure": null,
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AE19",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AF19",
    "terrain": "plains",
    "includes": [
      "lake"
    ],
    "special": [],
    "adventure": null,
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AG19",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AH19",
    "terrain": "plains",
    "includes": [
      "marshlands"
    ],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "ailanders"
  },
  {
    "name": "AI19",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AJ19",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "ailanders"
  },
  {
    "name": "AK19",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL19",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "ailanders"
  },
  {
    "name": "AM19",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN19",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO19",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A20",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B20",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C20",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D20",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E20",
    "terrain": "hills",
    "includes": [],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "F20",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "G20",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": "castle"
  },
  {
    "name": "H20",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "I20",
    "terrain": "forest",
    "includes": [
      "plains",
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "feulenmark",
    "kin": "urhur orcs"
  },
  {
    "name": "J20",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "K20",
    "terrain": "forest",
    "includes": [
      "plains"
    ],
    "special": [],
    "adventure": null,
    "region": "feulenmark",
    "kin": "urhur orcs"
  },
  {
    "name": "L20",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "M20",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "feulenmark",
    "kin": "urhur orcs"
  },
  {
    "name": "N20",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "O20",
    "terrain": "forest",
    "includes": [
      "plains",
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "feulenmark",
    "kin": "urhur orcs"
  },
  {
    "name": "P20",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Q20",
    "terrain": "forest",
    "includes": [
      "plains",
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "urhur orcs"
  },
  {
    "name": "R20",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "S20",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "T20",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "U20",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "V20",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "W20",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "X20",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Y20",
    "terrain": "plains",
    "includes": [
      "hills",
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "Z20",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AA20",
    "terrain": "hills",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "AB20",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AC20",
    "terrain": "plains",
    "includes": [
      "lake"
    ],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "AD20",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AE20",
    "terrain": "plains",
    "includes": [
      "lake"
    ],
    "special": [],
    "adventure": null,
    "region": "vivend",
    "kin": "elvenspring"
  },
  {
    "name": "AF20",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AG20",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "quard aslenes"
  },
  {
    "name": "AH20",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI20",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "ailanders"
  },
  {
    "name": "AJ20",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK20",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "ailanders"
  },
  {
    "name": "AL20",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM20",
    "terrain": "plains",
    "includes": [
      "ocean"
    ],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "quard aslenes"
  },
  {
    "name": "AN20",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO20",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A21",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B21",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C21",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D21",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": "castle"
  },
  {
    "name": "E21",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F21",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "G21",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "H21",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "I21",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "J21",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "K21",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "L21",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "feulenmark",
    "kin": "urhur orcs"
  },
  {
    "name": "M21",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "N21",
    "terrain": "forest",
    "includes": [
      "plains",
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "feulenmark",
    "kin": "urhur orcs"
  },
  {
    "name": "O21",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "P21",
    "terrain": "forest",
    "includes": [
      "plains",
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "urhur orcs"
  },
  {
    "name": "Q21",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "R21",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "roka orcs"
  },
  {
    "name": "S21",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "T21",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "U21",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "V21",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "W21",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "X21",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "Y21",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Z21",
    "terrain": "hills",
    "includes": [
      "plains"
    ],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "AA21",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AB21",
    "terrain": "plains",
    "includes": [
      "quagmire",
      "lake"
    ],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "AC21",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AD21",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AE21",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AF21",
    "terrain": "plains",
    "includes": [
      "lake",
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "quard aslenes"
  },
  {
    "name": "AG21",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AH21",
    "terrain": "plains",
    "includes": [],
    "special": [
      "amber's peak"
    ],
    "adventure": "castle",
    "region": "margelda",
    "kin": "quard aslenes"
  },
  {
    "name": "AI21",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AJ21",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "ailanders"
  },
  {
    "name": "AK21",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL21",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "quard aslenes"
  },
  {
    "name": "AM21",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN21",
    "terrain": "ocean",
    "includes": [],
    "special": [
      "island"
    ],
    "adventure": null
  },
  {
    "name": "AO21",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A22",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B22",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C22",
    "terrain": "mountains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "D22",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E22",
    "terrain": "hills",
    "includes": [],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "F22",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "G22",
    "terrain": "plains",
    "includes": [],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "H22",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "I22",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "J22",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "K22",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "L22",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "M22",
    "terrain": "forest",
    "includes": [
      "plains",
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "feulenmark",
    "kin": "urhur orcs"
  },
  {
    "name": "N22",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "O22",
    "terrain": "forest",
    "includes": [
      "plains",
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "urhur orcs"
  },
  {
    "name": "P22",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Q22",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "urhur orcs"
  },
  {
    "name": "R22",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "S22",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "roka orcs"
  },
  {
    "name": "T22",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "U22",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "V22",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "W22",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "X22",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Y22",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "Z22",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AA22",
    "terrain": "plains",
    "includes": [
      "hills",
      "quagmire",
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "AB22",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AC22",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AD22",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AE22",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AF22",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AG22",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "quard aslenes"
  },
  {
    "name": "AH22",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI22",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "quard aslenes"
  },
  {
    "name": "AJ22",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK22",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "quard aslenes"
  },
  {
    "name": "AL22",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM22",
    "terrain": "hills",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "quard aslenes"
  },
  {
    "name": "AN22",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO22",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "quard aslenes"
  },
  {
    "name": "A23",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B23",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "C23",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D23",
    "terrain": "plains",
    "includes": [
      "mountains",
      "river"
    ],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "E23",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F23",
    "terrain": "hills",
    "includes": [],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "G23",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "H23",
    "terrain": "plains",
    "includes": [],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "I23",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "J23",
    "terrain": "plains",
    "includes": [],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "K23",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "L23",
    "terrain": "forest",
    "includes": [
      "plains",
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "feulenmark",
    "kin": "urhur orcs"
  },
  {
    "name": "M23",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "N23",
    "terrain": "forest",
    "includes": [
      "plains",
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "urhur orcs"
  },
  {
    "name": "O23",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "P23",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "urhur orcs"
  },
  {
    "name": "Q23",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "R23",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "roka orcs"
  },
  {
    "name": "S23",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "T23",
    "terrain": "forest",
    "includes": [
      "plains"
    ],
    "special": [],
    "adventure": "dungeon",
    "region": "arina forest",
    "kin": "roka orcs"
  },
  {
    "name": "U23",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "V23",
    "terrain": "plains",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "W23",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "X23",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "Y23",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Z23",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "AA23",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AB23",
    "terrain": "quagmire",
    "includes": [
      "river",
      "lake"
    ],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "AC23",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AD23",
    "terrain": "plains",
    "includes": [],
    "special": [
      "falender"
    ],
    "adventure": "village",
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "AE23",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AF23",
    "terrain": "plains",
    "includes": [
      "lake",
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "quard aslenes"
  },
  {
    "name": "AG23",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AH23",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "quard aslenes"
  },
  {
    "name": "AI23",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AJ23",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "quard aslenes"
  },
  {
    "name": "AK23",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL23",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "quard aslenes"
  },
  {
    "name": "AM23",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN23",
    "terrain": "hills",
    "includes": [],
    "special": [],
    "adventure": "dungeon",
    "region": "margelda",
    "kin": "quard aslenes"
  },
  {
    "name": "AO23",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A24",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B24",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C24",
    "terrain": "mountains",
    "includes": [
      "plains",
      "river"
    ],
    "special": [
      "grindbone"
    ],
    "adventure": "village"
  },
  {
    "name": "D24",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E24",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "F24",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "G24",
    "terrain": "hills",
    "includes": [
      "plains"
    ],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "H24",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "I24",
    "terrain": "plains",
    "includes": [],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "J24",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "K24",
    "terrain": "plains",
    "includes": [
      "river",
      "forest"
    ],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "L24",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "M24",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "urhur orcs"
  },
  {
    "name": "N24",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "O24",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "urhur orcs"
  },
  {
    "name": "P24",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Q24",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "roka orcs"
  },
  {
    "name": "R24",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "S24",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "roka orcs"
  },
  {
    "name": "T24",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "U24",
    "terrain": "forest",
    "includes": [
      "plains"
    ],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "roka orcs"
  },
  {
    "name": "V24",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "W24",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "X24",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Y24",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "Z24",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AA24",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "AB24",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AC24",
    "terrain": "quagmire",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "AD24",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AE24",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "AF24",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AG24",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "quard aslenes"
  },
  {
    "name": "AH24",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI24",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "quard aslenes"
  },
  {
    "name": "AJ24",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK24",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "quard aslenes"
  },
  {
    "name": "AL24",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM24",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "quard aslenes"
  },
  {
    "name": "AN24",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO24",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A25",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B25",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C25",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D25",
    "terrain": "mountains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "E25",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F25",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "G25",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "H25",
    "terrain": "plains",
    "includes": [],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "I25",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "J25",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "K25",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "L25",
    "terrain": "forest",
    "includes": [
      "plains",
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "urhur orcs"
  },
  {
    "name": "M25",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "N25",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "urhur orcs"
  },
  {
    "name": "O25",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "P25",
    "terrain": "mountains",
    "includes": [
      "forest"
    ],
    "special": [
      "eye of the rose"
    ],
    "adventure": "castle",
    "region": "arina forest",
    "kin": "roka orcs"
  },
  {
    "name": "Q25",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "R25",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "roka orcs"
  },
  {
    "name": "S25",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "T25",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "roka orcs"
  },
  {
    "name": "U25",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "V25",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "W25",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "X25",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "Y25",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Z25",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "AA25",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AB25",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "AC25",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AD25",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "AE25",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AF25",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "AG25",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AH25",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "quard aslenes"
  },
  {
    "name": "AI25",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AJ25",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "quard aslenes"
  },
  {
    "name": "AK25",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL25",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "quard aslenes"
  },
  {
    "name": "AM25",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN25",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO25",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A26",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B26",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C26",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D26",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E26",
    "terrain": "plains",
    "includes": [
      "mountains",
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "F26",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "G26",
    "terrain": "mountains",
    "includes": [
      "plains",
      "river"
    ],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "H26",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "I26",
    "terrain": "plains",
    "includes": [
      "forest",
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "J26",
    "terrain": "--",
    "includes": [],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "K26",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "L26",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "M26",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "urhur orcs"
  },
  {
    "name": "N26",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "O26",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "P26",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Q26",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "roka orcs"
  },
  {
    "name": "R26",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "S26",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "roka orcs"
  },
  {
    "name": "T26",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "U26",
    "terrain": "plains",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "roka orcs"
  },
  {
    "name": "V26",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "W26",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "X26",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Y26",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "Z26",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AA26",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "AB26",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AC26",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "AD26",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AE26",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "AF26",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AG26",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "quard aslenes"
  },
  {
    "name": "AH26",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI26",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "quard aslenes"
  },
  {
    "name": "AJ26",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK26",
    "terrain": "plains",
    "includes": [
      "marshland"
    ],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "quard aslenes"
  },
  {
    "name": "AL26",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM26",
    "terrain": "marshland",
    "includes": [],
    "special": [],
    "adventure": "village"
  },
  {
    "name": "AN26",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO26",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A27",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B27",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C27",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D27",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": "dungeon"
  },
  {
    "name": "E27",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F27",
    "terrain": "mountains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "G27",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "H27",
    "terrain": "plains",
    "includes": [
      "river",
      "marshland"
    ],
    "region": "harmsmoor",
    "kin": "ogres",
    "special": [],
    "adventure": null
  },
  {
    "name": "I27",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "J27",
    "terrain": "forest",
    "includes": [
      "plains",
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "isir orcs"
  },
  {
    "name": "K27",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "L27",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "isir orcs"
  },
  {
    "name": "M27",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "N27",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "O27",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "P27",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Q27",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "R27",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "roka orcs"
  },
  {
    "name": "S27",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "T27",
    "terrain": "forest",
    "includes": [
      "lake"
    ],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "roka orcs"
  },
  {
    "name": "U27",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "V27",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": "castle",
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "W27",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "X27",
    "terrain": "plains",
    "includes": [
      "river",
      "hills"
    ],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "Y27",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Z27",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AA27",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AB27",
    "terrain": "plains",
    "includes": [
      "river",
      "mountains"
    ],
    "special": [
      "the hollows"
    ],
    "adventure": "village",
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "AC27",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AD27",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "AE27",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AF27",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "yendra",
    "kin": "galdane aslenes"
  },
  {
    "name": "AG27",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AH27",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "quard aslenes"
  },
  {
    "name": "AI27",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AJ27",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "quard aslenes"
  },
  {
    "name": "AK27",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL27",
    "terrain": "marshland",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM27",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN27",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO27",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A28",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B28",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C28",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D28",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E28",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F28",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "G28",
    "terrain": "mountains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "H28",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "I28",
    "terrain": "plains",
    "includes": [
      "mountains",
      "forest",
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "isir orcs"
  },
  {
    "name": "J28",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "K28",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "isir orcs"
  },
  {
    "name": "L28",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "M28",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "isir orcs"
  },
  {
    "name": "N28",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "O28",
    "terrain": "mountains",
    "includes": [
      "forest"
    ],
    "special": [
      "stoneloom mines"
    ],
    "adventure": "dungeon",
    "region": "arina forest",
    "kin": "isir orcs"
  },
  {
    "name": "P28",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Q28",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "roka orcs"
  },
  {
    "name": "R28",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "S28",
    "terrain": "forest",
    "includes": [
      "plains",
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "roka orcs"
  },
  {
    "name": "T28",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "U28",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "V28",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "W28",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "X28",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Y28",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Z28",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AA28",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AB28",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AC28",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "yendra",
    "kin": "galdane aslenes"
  },
  {
    "name": "AD28",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AE28",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "yendra",
    "kin": "galdane aslenes"
  },
  {
    "name": "AF28",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AG28",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "quard aslenes"
  },
  {
    "name": "AH28",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI28",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "margelda",
    "kin": "quard aslenes"
  },
  {
    "name": "AJ28",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK28",
    "terrain": "plains",
    "includes": [
      "marshland"
    ],
    "special": [],
    "adventure": null,
    "region": "yendra",
    "kin": "galdane aslenes"
  },
  {
    "name": "AL28",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM28",
    "terrain": "marshland",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN28",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO28",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A29",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B29",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C29",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D29",
    "terrain": "forest",
    "includes": [
      "mountains",
      "valley",
      "fog"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "E29",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F29",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": "dungeon"
  },
  {
    "name": "G29",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "H29",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "I29",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "J29",
    "terrain": "forest",
    "includes": [
      "plains"
    ],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "isir orcs"
  },
  {
    "name": "K29",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "L29",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "isir orcs"
  },
  {
    "name": "M29",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "N29",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "isir orcs"
  },
  {
    "name": "O29",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "P29",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "isir orcs"
  },
  {
    "name": "Q29",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "R29",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "roka orcs"
  },
  {
    "name": "S29",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "T29",
    "terrain": "quagmire",
    "includes": [
      "river"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "U29",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "V29",
    "terrain": "plains",
    "includes": [
      "river",
      "lake"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "W29",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "X29",
    "terrain": "hills",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "Y29",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Z29",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AA29",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AB29",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "yendra",
    "kin": "galdane aslenes"
  },
  {
    "name": "AC29",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AD29",
    "terrain": "plains",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "AE29",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AF29",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "yendra",
    "kin": "galdane aslenes"
  },
  {
    "name": "AG29",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AH29",
    "terrain": "plains",
    "includes": [
      "river",
      "forest"
    ],
    "special": [],
    "adventure": null,
    "region": "yendra",
    "kin": "galdane aslenes"
  },
  {
    "name": "AI29",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AJ29",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "yendra",
    "kin": "galdane aslenes"
  },
  {
    "name": "AK29",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL29",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "yendra",
    "kin": "galdane aslenes"
  },
  {
    "name": "AM29",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN29",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO29",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A30",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B30",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C30",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D30",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E30",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F30",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "G30",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "H30",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "I30",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "J30",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "K30",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "isir orcs"
  },
  {
    "name": "L30",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "M30",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "isir orcs"
  },
  {
    "name": "N30",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "O30",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "isir orcs"
  },
  {
    "name": "P30",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Q30",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "roka orcs"
  },
  {
    "name": "R30",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "S30",
    "terrain": "forest",
    "includes": [
      "plains",
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "roka orcs"
  },
  {
    "name": "T30",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "U30",
    "terrain": "quagmire",
    "includes": [
      "plains",
      "river"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "V30",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "W30",
    "terrain": "plains",
    "includes": [],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "X30",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Y30",
    "terrain": "ruins",
    "includes": [],
    "special": [
      "wailer's hold"
    ],
    "adventure": null,
    "region": "maldena",
    "kin": "ailanders"
  },
  {
    "name": "Z30",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AA30",
    "terrain": "mountains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "AB30",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AC30",
    "terrain": "plains",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": null,
    "region": "yendra",
    "kin": "galdane aslenes"
  },
  {
    "name": "AD30",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AE30",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "the shroud",
    "kin": "goblins"
  },
  {
    "name": "AF30",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AG30",
    "terrain": "plains",
    "includes": [
      "river",
      "forest"
    ],
    "special": [],
    "adventure": "dungeon",
    "region": "yendra",
    "kin": "galdane aslenes"
  },
  {
    "name": "AH30",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI30",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "AJ30",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK30",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "yendra",
    "kin": "galdane aslenes"
  },
  {
    "name": "AL30",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM30",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": "village",
    "region": "yendra",
    "kin": "galdane aslenes"
  },
  {
    "name": "AN30",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO30",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A31",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B31",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C31",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D31",
    "terrain": "mountains",
    "includes": [
      "valley",
      "fog"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "E31",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F31",
    "terrain": "mountains",
    "includes": [
      "valley",
      "fog"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "G31",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "H31",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "I31",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "J31",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "K31",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "L31",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "isir orcs"
  },
  {
    "name": "M31",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "N31",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "isir orcs"
  },
  {
    "name": "O31",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "P31",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "isir orcs"
  },
  {
    "name": "Q31",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "R31",
    "terrain": "forest",
    "includes": [
      "plains"
    ],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "roka orcs"
  },
  {
    "name": "S31",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "T31",
    "terrain": "quagmire",
    "includes": [
      "river"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "U31",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "V31",
    "terrain": "plains",
    "includes": [],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "W31",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "X31",
    "terrain": "hills",
    "includes": [
      "ruins"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [
      "wailer's hold"
    ],
    "adventure": null
  },
  {
    "name": "Y31",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Z31",
    "terrain": "mountains",
    "includes": [
      "forest",
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "AA31",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AB31",
    "terrain": "forest",
    "includes": [
      "plains"
    ],
    "special": [],
    "adventure": null,
    "region": "the shroud",
    "kin": "goblins"
  },
  {
    "name": "AC31",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AD31",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "the shroud",
    "kin": "goblins"
  },
  {
    "name": "AE31",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AF31",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "the shroud",
    "kin": "goblins"
  },
  {
    "name": "AG31",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AH31",
    "terrain": "plains",
    "includes": [
      "forest",
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "yendra",
    "kin": "galdane aslenes"
  },
  {
    "name": "AI31",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AJ31",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK31",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL31",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "yendra",
    "kin": "galdane aslenes"
  },
  {
    "name": "AM31",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN31",
    "terrain": "ocean",
    "includes": [
      "forest",
      "island"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO31",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A32",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B32",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C32",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D32",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E32",
    "terrain": "mountains",
    "includes": [
      "valley",
      "fog"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "F32",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "G32",
    "terrain": "mountains",
    "includes": [
      "valley",
      "fog"
    ],
    "special": [
      "vale of the dead"
    ],
    "adventure": "dungeon"
  },
  {
    "name": "H32",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "I32",
    "terrain": "mountains",
    "includes": [],
    "special": [
      "hagglers house"
    ],
    "adventure": "castle"
  },
  {
    "name": "J32",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "K32",
    "terrain": "forest",
    "includes": [
      "plains"
    ],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "isir orcs"
  },
  {
    "name": "L32",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "M32",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "isir orcs"
  },
  {
    "name": "N32",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "O32",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "isir orcs"
  },
  {
    "name": "P32",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Q32",
    "terrain": "forest",
    "includes": [
      "plains"
    ],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "roka orcs"
  },
  {
    "name": "R32",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "S32",
    "terrain": "plains",
    "includes": [],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "T32",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "U32",
    "terrain": "quagmire",
    "includes": [
      "plains",
      "river"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "V32",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "W32",
    "terrain": "plains",
    "includes": [],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "X32",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Y32",
    "terrain": "ruins",
    "includes": [],
    "region": "harga",
    "kin": "alderlanders",
    "special": [
      "wailer's hold"
    ],
    "adventure": null
  },
  {
    "name": "Z32",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AA32",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "goblins"
  },
  {
    "name": "AB32",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AC32",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "the shroud",
    "kin": "goblins"
  },
  {
    "name": "AD32",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AE32",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "the shroud",
    "kin": "goblins"
  },
  {
    "name": "AF32",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AG32",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "the shroud",
    "kin": "goblins"
  },
  {
    "name": "AH32",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI32",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "yendra",
    "kin": "galdane aslenes"
  },
  {
    "name": "AJ32",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK32",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "yendra",
    "kin": "galdane aslenes"
  },
  {
    "name": "AL32",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM32",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN32",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO32",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A33",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B33",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C33",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D33",
    "terrain": "mountains",
    "includes": [
      "valley",
      "fog"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "E33",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F33",
    "terrain": "mountains",
    "includes": [
      "valley",
      "fog"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "G33",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "H33",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "I33",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "J33",
    "terrain": "mountains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "K33",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "L33",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "M33",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "N33",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "isir orcs"
  },
  {
    "name": "O33",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "P33",
    "terrain": "forest",
    "includes": [
      "plains"
    ],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "isir orcs"
  },
  {
    "name": "Q33",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "R33",
    "terrain": "plains",
    "includes": [],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "S33",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "T33",
    "terrain": "plains",
    "includes": [
      "forest",
      "river"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": "village"
  },
  {
    "name": "U33",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "V33",
    "terrain": "forest",
    "includes": [
      "plains"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "W33",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "X33",
    "terrain": "forest",
    "includes": [
      "plains"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "Y33",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Z33",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "goblins"
  },
  {
    "name": "AA33",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AB33",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "the shroud",
    "kin": "goblins"
  },
  {
    "name": "AC33",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AD33",
    "terrain": "mountains",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": null,
    "region": "the shroud",
    "kin": "goblins"
  },
  {
    "name": "AE33",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AF33",
    "terrain": "forest",
    "includes": [
      "mountains"
    ],
    "special": [],
    "adventure": null,
    "region": "the shroud",
    "kin": "goblins"
  },
  {
    "name": "AG33",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AH33",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "the shroud",
    "kin": "goblins"
  },
  {
    "name": "AI33",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AJ33",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "yendra",
    "kin": "galdane aslenes"
  },
  {
    "name": "AK33",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL33",
    "terrain": "ocean",
    "includes": [
      "island"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM33",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN33",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO33",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A34",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B34",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C34",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D34",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E34",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F34",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "G34",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "H34",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "I34",
    "terrain": "mountains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "J34",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "K34",
    "terrain": "mountains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "L34",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "M34",
    "terrain": "mountains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "isir orcs"
  },
  {
    "name": "N34",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "O34",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "isir orcs"
  },
  {
    "name": "P34",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Q34",
    "terrain": "plains",
    "includes": [
      "forest"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "R34",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "S34",
    "terrain": "plains",
    "includes": [
      "forest"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "T34",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "U34",
    "terrain": "plains",
    "includes": [
      "quagmire",
      "river"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "V34",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "W34",
    "terrain": "plains",
    "includes": [],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "X34",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Y34",
    "terrain": "mountains",
    "includes": [
      "forest",
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "groveland woods",
    "kin": "isir orcs"
  },
  {
    "name": "Z34",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AA34",
    "terrain": "forest",
    "includes": [
      "plains",
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "goblins"
  },
  {
    "name": "AB34",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AC34",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "the shroud",
    "kin": "goblins"
  },
  {
    "name": "AD34",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AE34",
    "terrain": "mountains",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": "dungeon",
    "region": "the shroud",
    "kin": "goblins"
  },
  {
    "name": "AF34",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AG34",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "the shroud",
    "kin": "goblins"
  },
  {
    "name": "AH34",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI34",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "the shroud",
    "kin": "goblins"
  },
  {
    "name": "AJ34",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK34",
    "terrain": "plains",
    "includes": [
      "lake"
    ],
    "special": [],
    "adventure": "village",
    "region": "yendra",
    "kin": "galdane aslenes"
  },
  {
    "name": "AL34",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM34",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN34",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO34",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A35",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B35",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C35",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D35",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E35",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F35",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "G35",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "H35",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "I35",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "J35",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "K35",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "L35",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "M35",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "N35",
    "terrain": "mountains",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": null,
    "region": "arina forest",
    "kin": "isir orcs"
  },
  {
    "name": "O35",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "P35",
    "terrain": "plains",
    "includes": [],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "Q35",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "R35",
    "terrain": "plains",
    "includes": [
      "forest"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "S35",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "T35",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "U35",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "V35",
    "terrain": "plains",
    "includes": [
      "forest"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "W35",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "X35",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "groveland woods",
    "kin": "isir orcs"
  },
  {
    "name": "Y35",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Z35",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "goblins"
  },
  {
    "name": "AA35",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AB35",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "the shroud",
    "kin": "goblins"
  },
  {
    "name": "AC35",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AD35",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "the shroud",
    "kin": "goblins"
  },
  {
    "name": "AE35",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AF35",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "the shroud",
    "kin": "goblins"
  },
  {
    "name": "AG35",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AH35",
    "terrain": "forest",
    "includes": [
      "plains"
    ],
    "special": [],
    "adventure": null,
    "region": "yendra",
    "kin": "galdane aslenes"
  },
  {
    "name": "AI35",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AJ35",
    "terrain": "plains",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": null,
    "region": "yendra",
    "kin": "galdane aslenes"
  },
  {
    "name": "AK35",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL35",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM35",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN35",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO35",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A36",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B36",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C36",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D36",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E36",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F36",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "G36",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "H36",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "I36",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "J36",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "K36",
    "terrain": "mountains",
    "includes": [],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "L36",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "M36",
    "terrain": "mountains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "N36",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "O36",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "P36",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Q36",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": "village"
  },
  {
    "name": "R36",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "S36",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "T36",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "U36",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "groveland woods",
    "kin": "isir orcs"
  },
  {
    "name": "V36",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "W36",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "groveland woods",
    "kin": "isir orcs"
  },
  {
    "name": "X36",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Y36",
    "terrain": "mountains",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "Z36",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AA36",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [
      "hexenwald"
    ],
    "adventure": "village",
    "region": "fangwood",
    "kin": "goblins"
  },
  {
    "name": "AB36",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AC36",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "goblins"
  },
  {
    "name": "AD36",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AE36",
    "terrain": "forest",
    "includes": [
      "lake"
    ],
    "special": [],
    "adventure": null,
    "region": "the shroud",
    "kin": "goblins"
  },
  {
    "name": "AF36",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AG36",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "the shroud",
    "kin": "goblins"
  },
  {
    "name": "AH36",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI36",
    "terrain": "plains",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": null,
    "region": "yendra",
    "kin": "galdane aslenes"
  },
  {
    "name": "AJ36",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK36",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL36",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM36",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN36",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO36",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A37",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B37",
    "terrain": "high mountains",
    "includes": [],
    "special": [
      "shadowgate pass"
    ],
    "adventure": null
  },
  {
    "name": "C37",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D37",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E37",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F37",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "G37",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "H37",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "I37",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "J37",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": "dungeon"
  },
  {
    "name": "K37",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "L37",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": "village"
  },
  {
    "name": "M37",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "N37",
    "terrain": "lake",
    "includes": [],
    "region": "blaudwater",
    "special": [],
    "adventure": null
  },
  {
    "name": "O37",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "P37",
    "terrain": "plains",
    "includes": [
      "lake"
    ],
    "special": [],
    "adventure": "castle"
  },
  {
    "name": "Q37",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "R37",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "groveland woods",
    "kin": "isir orcs"
  },
  {
    "name": "S37",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "T37",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "groveland woods",
    "kin": "isir orcs"
  },
  {
    "name": "U37",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "V37",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "groveland woods",
    "kin": "isir orcs"
  },
  {
    "name": "W37",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "X37",
    "terrain": "mountains",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "Y37",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Z37",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "goblins"
  },
  {
    "name": "AA37",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AB37",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "goblins"
  },
  {
    "name": "AC37",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AD37",
    "terrain": "lake",
    "includes": [],
    "region": "lake mill",
    "special": [],
    "adventure": null
  },
  {
    "name": "AE37",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AF37",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "belifar",
    "kin": "halflings"
  },
  {
    "name": "AG37",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AH37",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI37",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AJ37",
    "terrain": "ocean",
    "includes": [
      "island"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK37",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL37",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM37",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN37",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO37",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A38",
    "terrain": "shadowgate pass",
    "includes": [
      "high mountains"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "B38",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C38",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D38",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E38",
    "terrain": "mountains",
    "includes": [],
    "special": [
      "vond"
    ],
    "adventure": "castle"
  },
  {
    "name": "F38",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "G38",
    "terrain": "mountains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "H38",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "I38",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "J38",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "K38",
    "terrain": "plains",
    "includes": [],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "L38",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "M38",
    "terrain": "lake",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "N38",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "O38",
    "terrain": "lake",
    "includes": [],
    "region": "blaudwater",
    "special": [],
    "adventure": null
  },
  {
    "name": "P38",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Q38",
    "terrain": "plains",
    "includes": [
      "river",
      "forest"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "R38",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "S38",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "groveland woods",
    "kin": "isir orcs"
  },
  {
    "name": "T38",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "U38",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "groveland woods",
    "kin": "isir orcs"
  },
  {
    "name": "V38",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "W38",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "groveland woods",
    "kin": "wolfkin"
  },
  {
    "name": "X38",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Y38",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "wolfkin"
  },
  {
    "name": "Z38",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AA38",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "goblins"
  },
  {
    "name": "AB38",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AC38",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "goblins"
  },
  {
    "name": "AD38",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AE38",
    "terrain": "lake",
    "includes": [
      "plains"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "AF38",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AG38",
    "terrain": "plains",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": "village",
    "region": "belifar",
    "kin": "halflings"
  },
  {
    "name": "AH38",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI38",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AJ38",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK38",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL38",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM38",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN38",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO38",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A39",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B39",
    "terrain": "shadowgate pass",
    "includes": [
      "high mountains"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "C39",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D39",
    "terrain": "shadowgate pass",
    "includes": [
      "high mountains"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "E39",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F39",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "G39",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "H39",
    "terrain": "ruins",
    "includes": [
      "mountains",
      "river"
    ],
    "special": [
      "alderstone"
    ],
    "adventure": null
  },
  {
    "name": "I39",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "J39",
    "terrain": "ruins",
    "includes": [
      "mountains",
      "river"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [
      "alderstone"
    ],
    "adventure": null
  },
  {
    "name": "K39",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "L39",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "M39",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "N39",
    "terrain": "plains",
    "includes": [],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "O39",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "P39",
    "terrain": "plains",
    "includes": [],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "Q39",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "R39",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "groveland woods",
    "kin": "isir orcs"
  },
  {
    "name": "S39",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "T39",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": "dungeon",
    "region": "groveland woods",
    "kin": "isir orcs"
  },
  {
    "name": "U39",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "V39",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "groveland woods",
    "kin": "wolfkin"
  },
  {
    "name": "W39",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "X39",
    "terrain": "mountains",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "Y39",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Z39",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "wolfkin"
  },
  {
    "name": "AA39",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AB39",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "goblins"
  },
  {
    "name": "AC39",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AD39",
    "terrain": "forest",
    "includes": [
      "lake"
    ],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "goblins"
  },
  {
    "name": "AE39",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AF39",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "belifar",
    "kin": "halflings"
  },
  {
    "name": "AG39",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AH39",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI39",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AJ39",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK39",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL39",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM39",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN39",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO39",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A40",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B40",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C40",
    "terrain": "shadowgate pass",
    "includes": [
      "high mountains"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "D40",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E40",
    "terrain": "mountains",
    "includes": [
      "shadowgate pass"
    ],
    "region": "shadowgate pass",
    "kin": "demons",
    "special": [],
    "adventure": null
  },
  {
    "name": "F40",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "G40",
    "terrain": "ruins",
    "includes": [
      "mountains"
    ],
    "region": "shadowgate pass",
    "kin": "demons",
    "special": [
      "alderstone"
    ],
    "adventure": null
  },
  {
    "name": "H40",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "I40",
    "terrain": "ruins",
    "includes": [
      "river"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [
      "alderstone"
    ],
    "adventure": null
  },
  {
    "name": "J40",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "K40",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "L40",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "M40",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "N40",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "O40",
    "terrain": "plains",
    "includes": [],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "P40",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Q40",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "groveland woods",
    "kin": "isir orcs"
  },
  {
    "name": "R40",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "S40",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "groveland woods",
    "kin": "isir orcs"
  },
  {
    "name": "T40",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "U40",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "groveland woods",
    "kin": "wolfkin"
  },
  {
    "name": "V40",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "W40",
    "terrain": "mountains",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "X40",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Y40",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "wolfkin"
  },
  {
    "name": "Z40",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AA40",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "wolfkin"
  },
  {
    "name": "AB40",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AC40",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "goblins"
  },
  {
    "name": "AD40",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AE40",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "goblins"
  },
  {
    "name": "AF40",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AG40",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "belifar",
    "kin": "halflings"
  },
  {
    "name": "AH40",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI40",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AJ40",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK40",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL40",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM40",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN40",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO40",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A41",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B41",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C41",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D41",
    "terrain": "shadowgate pass",
    "includes": [],
    "region": "shadowgate pass",
    "kin": "demons",
    "special": [],
    "adventure": null
  },
  {
    "name": "E41",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F41",
    "terrain": "plains",
    "includes": [
      "mountains"
    ],
    "region": "shadowgate pass",
    "kin": "demons",
    "special": [],
    "adventure": null
  },
  {
    "name": "G41",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "H41",
    "terrain": "ruins",
    "includes": [
      "mountains",
      "river"
    ],
    "region": "",
    "kin": "misgrown",
    "special": [
      "alderstone"
    ],
    "adventure": null
  },
  {
    "name": "I41",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "J41",
    "terrain": "mountains",
    "includes": [
      "river"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "K41",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "L41",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "M41",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "N41",
    "terrain": "plains",
    "includes": [],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "O41",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "P41",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "groveland woods",
    "kin": "isir orcs"
  },
  {
    "name": "Q41",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "R41",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "groveland woods",
    "kin": "isir orcs"
  },
  {
    "name": "S41",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "T41",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "U41",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "V41",
    "terrain": "mountains",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "W41",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "groveland woods",
    "kin": "wolfkin"
  },
  {
    "name": "X41",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "wolfkin"
  },
  {
    "name": "Y41",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Z41",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "wolfkin"
  },
  {
    "name": "AA41",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AB41",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "goblins"
  },
  {
    "name": "AC41",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AD41",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "goblins"
  },
  {
    "name": "AE41",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AF41",
    "terrain": "forest",
    "includes": [
      "plains"
    ],
    "special": [],
    "adventure": null,
    "region": "belifar",
    "kin": "halflings"
  },
  {
    "name": "AG41",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AH41",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "belifar",
    "kin": "halflings"
  },
  {
    "name": "AI41",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AJ41",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK41",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL41",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM41",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN41",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO41",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A42",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B42",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C42",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D42",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E42",
    "terrain": "plains",
    "includes": [],
    "region": "shadowgate pass",
    "kin": "demons",
    "special": [],
    "adventure": null
  },
  {
    "name": "F42",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "G42",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "shadowgate pass",
    "kin": "demons",
    "special": [],
    "adventure": null
  },
  {
    "name": "H42",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "I42",
    "terrain": "mountains",
    "includes": [],
    "region": "",
    "kin": "misgrown",
    "special": [],
    "adventure": null
  },
  {
    "name": "J42",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "K42",
    "terrain": "plains",
    "includes": [
      "mountains",
      "forest"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "L42",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "M42",
    "terrain": "plains",
    "includes": [],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "N42",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "O42",
    "terrain": "plains",
    "includes": [
      "forest"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "P42",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Q42",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "groveland woods",
    "kin": "isir orcs"
  },
  {
    "name": "R42",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "S42",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "groveland woods",
    "kin": "wolfkin"
  },
  {
    "name": "T42",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "U42",
    "terrain": "mountains",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "V42",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "W42",
    "terrain": "plains",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": "dungeon"
  },
  {
    "name": "X42",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Y42",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "wolfkin"
  },
  {
    "name": "Z42",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AA42",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "wolfkin"
  },
  {
    "name": "AB42",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AC42",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "goblins"
  },
  {
    "name": "AD42",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AE42",
    "terrain": "hills",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": null,
    "region": "belifar",
    "kin": "halflings"
  },
  {
    "name": "AF42",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AG42",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "belifar",
    "kin": "halflings"
  },
  {
    "name": "AH42",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI42",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "belifar",
    "kin": "halflings"
  },
  {
    "name": "AJ42",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK42",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": "castle",
    "region": "belifar",
    "kin": "halflings"
  },
  {
    "name": "AL42",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM42",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN42",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO42",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A43",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B43",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C43",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D43",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E43",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F43",
    "terrain": "mountains",
    "includes": [],
    "region": "shadowgate pass",
    "kin": "demons",
    "special": [],
    "adventure": null
  },
  {
    "name": "G43",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "H43",
    "terrain": "plains",
    "includes": [
      "mountains",
      "river"
    ],
    "region": "",
    "kin": "misgrown",
    "special": [],
    "adventure": null
  },
  {
    "name": "I43",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "J43",
    "terrain": "forest",
    "includes": [
      "mountains",
      "valley"
    ],
    "region": "",
    "kin": "misgrown",
    "special": [],
    "adventure": null
  },
  {
    "name": "K43",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "L43",
    "terrain": "plains",
    "includes": [
      "mountains"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "M43",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "N43",
    "terrain": "plains",
    "includes": [],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "O43",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "P43",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "groveland woods",
    "kin": "isir orcs"
  },
  {
    "name": "Q43",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "R43",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "groveland woods",
    "kin": "wolfkin"
  },
  {
    "name": "S43",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "T43",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "groveland woods",
    "kin": "wolfkin"
  },
  {
    "name": "U43",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "V43",
    "terrain": "mountains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "W43",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "X43",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "wolfkin"
  },
  {
    "name": "Y43",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Z43",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": "dungeon",
    "region": "fangwood",
    "kin": "wolfkin"
  },
  {
    "name": "AA43",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AB43",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "goblins"
  },
  {
    "name": "AC43",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AD43",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "belifar",
    "kin": "halflings"
  },
  {
    "name": "AE43",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AF43",
    "terrain": "hills",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "belifar",
    "kin": "halflings"
  },
  {
    "name": "AG43",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AH43",
    "terrain": "plains",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": null,
    "region": "belifar",
    "kin": "halflings"
  },
  {
    "name": "AI43",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AJ43",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "belifar",
    "kin": "halflings"
  },
  {
    "name": "AK43",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL43",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM43",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN43",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO43",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A44",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B44",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C44",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D44",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E44",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F44",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "G44",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "shadowgate pass",
    "kin": "demons",
    "special": [],
    "adventure": null
  },
  {
    "name": "H44",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "I44",
    "terrain": "plains",
    "includes": [
      "mountains"
    ],
    "region": "",
    "kin": "misgrown",
    "special": [],
    "adventure": null
  },
  {
    "name": "J44",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "K44",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "L44",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "M44",
    "terrain": "plains",
    "includes": [],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "N44",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "O44",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "groveland woods",
    "kin": "isir orcs"
  },
  {
    "name": "P44",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Q44",
    "terrain": "forest",
    "includes": [
      "plains",
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "groveland woods",
    "kin": "wolfkin"
  },
  {
    "name": "R44",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "S44",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "groveland woods",
    "kin": "wolfkin"
  },
  {
    "name": "T44",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "U44",
    "terrain": "mountains",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": "castle"
  },
  {
    "name": "V44",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "W44",
    "terrain": "mountains",
    "includes": [
      "plains",
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "X44",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Y44",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "wolfkin"
  },
  {
    "name": "Z44",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AA44",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "wolfkin"
  },
  {
    "name": "AB44",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AC44",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": "dungeon",
    "region": "belifar",
    "kin": "halflings"
  },
  {
    "name": "AD44",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AE44",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": "village",
    "region": "belifar",
    "kin": "halflings"
  },
  {
    "name": "AF44",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AG44",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "belifar",
    "kin": "halflings"
  },
  {
    "name": "AH44",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI44",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "belifar",
    "kin": "halflings"
  },
  {
    "name": "AJ44",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK44",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "belifar",
    "kin": "halflings"
  },
  {
    "name": "AL44",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM44",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN44",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO44",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A45",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B45",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C45",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D45",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E45",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F45",
    "terrain": "mountains",
    "includes": [],
    "region": "shadowgate pass",
    "kin": "demons",
    "special": [],
    "adventure": "dungeon"
  },
  {
    "name": "G45",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "H45",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "",
    "kin": "misgrown",
    "special": [],
    "adventure": null
  },
  {
    "name": "I45",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "J45",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "K45",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "L45",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "M45",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "N45",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": "dungeon"
  },
  {
    "name": "O45",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "P45",
    "terrain": "mountains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "Q45",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "R45",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "groveland woods",
    "kin": "wolfkin"
  },
  {
    "name": "S45",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "T45",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "groveland woods",
    "kin": "wolfkin"
  },
  {
    "name": "U45",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "V45",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "W45",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "X45",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "goblins"
  },
  {
    "name": "Y45",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Z45",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "wolfkin"
  },
  {
    "name": "AA45",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AB45",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "goblins"
  },
  {
    "name": "AC45",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AD45",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "belifar",
    "kin": "halflings"
  },
  {
    "name": "AE45",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AF45",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "belifar",
    "kin": "halflings"
  },
  {
    "name": "AG45",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AH45",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "belifar",
    "kin": "halflings"
  },
  {
    "name": "AI45",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AJ45",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "belifar",
    "kin": "halflings"
  },
  {
    "name": "AK45",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL45",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM45",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN45",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO45",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A46",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B46",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C46",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D46",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E46",
    "terrain": "mountains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "F46",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "G46",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "",
    "kin": "misgrown",
    "special": [],
    "adventure": null
  },
  {
    "name": "H46",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "I46",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "",
    "kin": "misgrown",
    "special": [],
    "adventure": null
  },
  {
    "name": "J46",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "K46",
    "terrain": "mountains",
    "includes": [],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": "dungeon"
  },
  {
    "name": "L46",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "M46",
    "terrain": "plains",
    "includes": [],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "N46",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "O46",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "P46",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Q46",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "R46",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "S46",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "groveland woods",
    "kin": "wolfkin"
  },
  {
    "name": "T46",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "U46",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "V46",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "W46",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "X46",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Y46",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "goblins"
  },
  {
    "name": "Z46",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AA46",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "goblins"
  },
  {
    "name": "AB46",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AC46",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "belifar",
    "kin": "halflings"
  },
  {
    "name": "AD46",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AE46",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "belifar",
    "kin": "halflings"
  },
  {
    "name": "AF46",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AG46",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "belifar",
    "kin": "halflings"
  },
  {
    "name": "AH46",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI46",
    "terrain": "plains",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": null,
    "region": "belifar",
    "kin": "halflings"
  },
  {
    "name": "AJ46",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK46",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL46",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM46",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN46",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO46",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A47",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B47",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C47",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D47",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E47",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F47",
    "terrain": "mountains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "G47",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "H47",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "",
    "kin": "misgrown",
    "special": [],
    "adventure": null
  },
  {
    "name": "I47",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "J47",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "",
    "kin": "misgrown",
    "special": [],
    "adventure": null
  },
  {
    "name": "K47",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "L47",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "M47",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "N47",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "O47",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "P47",
    "terrain": "mountains",
    "includes": [],
    "special": [
      "weatherstone"
    ],
    "adventure": "castle"
  },
  {
    "name": "Q47",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "R47",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "S47",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "T47",
    "terrain": "mountains",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "U47",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "V47",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "W47",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "X47",
    "terrain": "forest",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "goblins"
  },
  {
    "name": "Y47",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Z47",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "goblins"
  },
  {
    "name": "AA47",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AB47",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "goblins"
  },
  {
    "name": "AC47",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AD47",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "goblins"
  },
  {
    "name": "AE47",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AF47",
    "terrain": "plains",
    "includes": [
      "forest",
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "belifar",
    "kin": "halflings"
  },
  {
    "name": "AG47",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AH47",
    "terrain": "plains",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": null,
    "region": "belifar",
    "kin": "halflings"
  },
  {
    "name": "AI47",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AJ47",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "belifar",
    "kin": "halflings"
  },
  {
    "name": "AK47",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL47",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM47",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN47",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO47",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A48",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B48",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C48",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D48",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E48",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F48",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "G48",
    "terrain": "plains",
    "includes": [],
    "region": "",
    "kin": "misgrown",
    "special": [],
    "adventure": null
  },
  {
    "name": "H48",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "I48",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "J48",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "K48",
    "terrain": "plains",
    "includes": [],
    "region": "",
    "kin": "misgrown",
    "special": [],
    "adventure": null
  },
  {
    "name": "L48",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "M48",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": "village"
  },
  {
    "name": "N48",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "O48",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "P48",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Q48",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "R48",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "S48",
    "terrain": "mountains",
    "includes": [],
    "special": [
      "the bright vault"
    ],
    "adventure": "dungeon"
  },
  {
    "name": "T48",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "U48",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "V48",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "W48",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "X48",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Y48",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "goblins"
  },
  {
    "name": "Z48",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AA48",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "goblins"
  },
  {
    "name": "AB48",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AC48",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "goblins"
  },
  {
    "name": "AD48",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AE48",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "goblins"
  },
  {
    "name": "AF48",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AG48",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": "castle",
    "region": "belifar",
    "kin": "halflings"
  },
  {
    "name": "AH48",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI48",
    "terrain": "plains",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "belifar",
    "kin": "halflings"
  },
  {
    "name": "AJ48",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK48",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL48",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM48",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN48",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO48",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A49",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B49",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C49",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D49",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E49",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F49",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "G49",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "H49",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "I49",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "J49",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "K49",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "L49",
    "terrain": "plains",
    "includes": [],
    "region": "",
    "kin": "misgrown",
    "special": [],
    "adventure": null
  },
  {
    "name": "M49",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "N49",
    "terrain": "mountains",
    "includes": [
      "river"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "O49",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "P49",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "Q49",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "R49",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "S49",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "T49",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "U49",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "V49",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "W49",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "X49",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "goblins"
  },
  {
    "name": "Y49",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Z49",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": "castle"
  },
  {
    "name": "AA49",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AB49",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "goblins"
  },
  {
    "name": "AC49",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AD49",
    "terrain": "plains",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "AE49",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AF49",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "special": [],
    "adventure": null,
    "region": "belifar",
    "kin": "halflings"
  },
  {
    "name": "AG49",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AH49",
    "terrain": "plains",
    "includes": [
      "ocean"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI49",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AJ49",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK49",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL49",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM49",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN49",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO49",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A50",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B50",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C50",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D50",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E50",
    "terrain": "mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F50",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "G50",
    "terrain": "plains",
    "includes": [],
    "special": [
      "iron lock"
    ],
    "adventure": null
  },
  {
    "name": "H50",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "I50",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "J50",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "K50",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "L50",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "M50",
    "terrain": "mountains",
    "includes": [
      "river"
    ],
    "region": "",
    "kin": "misgrown",
    "special": [],
    "adventure": null
  },
  {
    "name": "N50",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "O50",
    "terrain": "mountains",
    "includes": [],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "P50",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Q50",
    "terrain": "plains",
    "includes": [
      "river"
    ],
    "region": "harga",
    "kin": "alderlanders",
    "special": [],
    "adventure": null
  },
  {
    "name": "R50",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "S50",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "T50",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "U50",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "V50",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "W50",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "X50",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Y50",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Z50",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AA50",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AB50",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AC50",
    "terrain": "forest",
    "includes": [],
    "special": [],
    "adventure": null,
    "region": "fangwood",
    "kin": "goblins"
  },
  {
    "name": "AD50",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AE50",
    "terrain": "plains",
    "includes": [
      "forest"
    ],
    "special": [],
    "adventure": null
  },
  {
    "name": "AF50",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AG50",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AH50",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI50",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AJ50",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK50",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL50",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM50",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN50",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO50",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "A51",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "B51",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "C51",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "D51",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "E51",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "F51",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "G51",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "H51",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "I51",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "J51",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "K51",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "L51",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "M51",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "N51",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "O51",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "P51",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Q51",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "R51",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "S51",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "T51",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "U51",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "V51",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "W51",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "X51",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Y51",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "Z51",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AA51",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AB51",
    "terrain": "high mountains",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AC51",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AD51",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AE51",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AF51",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AG51",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AH51",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AI51",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AJ51",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AK51",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AL51",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AM51",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AN51",
    "terrain": "ocean",
    "includes": [],
    "special": [],
    "adventure": null
  },
  {
    "name": "AO51",
    "terrain": "--",
    "includes": [],
    "special": [],
    "adventure": null
  }
]