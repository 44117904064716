import React from "react";
import { Navbar, NavDropdown } from "react-bootstrap";

const ResourcesNavBar = () => {
  return (
    <NavDropdown title="RPG Resources" id="basic-nav-dropdown">
      <Navbar bg="dark" variant="dark" expand="md" sticky="top">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <NavDropdown
            title="Role Playing Games"
            id="basic-nav-dropdown"
            href="./game-rundown"
          >
            <NavDropdown.ItemText></NavDropdown.ItemText>

            <NavDropdown.Item href="./game-rundown">
              Game Rundown
            </NavDropdown.Item>
          </NavDropdown>
        </Navbar.Collapse>
      </Navbar>
    </NavDropdown>
  );
};

export default ResourcesNavBar;
