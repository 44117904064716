import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import mbLogo from "../../../../src/assets/mork-borg/mork-borg-logo.webp";
import sectionDivider from "../../../../src/assets/mork-borg/mork-borg-section-divider.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";

import devotee from "../../../../src/assets/mork-borg/session09/forked-tongue-cultist.webp";
import cultist from "../../../../src/assets/mork-borg/session09/serpent-drug-cultist.webp";
import ueth from "../../../../src/assets/mork-borg/session09/ueth-the-scalehunter-portrait.webp";
import swampWitch from "../../../../src/assets/mork-borg/session09/the-swamp-witch-token.webp";
import dinah from "../../../../src/assets/mork-borg/session09/herman-and-dinah.webp";
import worshipHall from "../../../../src/assets/mork-borg/session09/worship-hall.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const MBMalumMortisSession09 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  const section1Data = [
    {
      title: "Bring Me My Daughter",
      text: (
        <div class="morkBorgFont morkBorgTitleText">
          <ImageComponent
            image={devotee}
            caption="Serpent Devotee"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Muscled priests of the serpent cult."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <p>
            Our party consists of:
            <ul>
              <li>
                <span style={{ color: "#00FFFF" }}>HERMAN</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>gutterborn scvm</span>.
                worried, naive, hands sore caked, pyromaniac.
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>HILDEBRAND</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>heretical priest</span>,
                washes hands & obsessively clean, fire baptized!
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>SIR FAT HEAD</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>mushroom eater</span>, wacked
                out prophet, scrambled like eggs.
              </li>

              <li>
                <span style={{ color: "#00FFFF" }}>WINSTON DUNGHILL</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>soul-damned drunkard</span>,
                a nervous drunk with resiliancy.
              </li>
              <li style={{ fontSize: "0.8em" }}>
                <span style={{ color: "#00FFFF" }}>KLATUUBARADA (absent)</span>:{" "}
                <br />
                <span style={{ color: "#ADFF2F" }}>fugitive knight</span>,
                argumentative, pyromaniacal.
              </li>
              <li style={{ fontSize: "0.8em" }}>
                <span style={{ color: "#00FFFF" }}>SPUG (absent)</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>wretched royalty</span>{" "}
                kleptomaniac, afraid of pain.
              </li>

              <li style={{ fontSize: "0.8em" }}>
                <span style={{ color: "#00FFFF" }}>Donkey Hoatee</span>: donkey
              </li>
              <li style={{ fontSize: "0.8em" }}>
                <span style={{ color: "#00FFFF" }}>
                  Barbarister the Incredible Horse
                </span>
                : talking horse minion (master?) to Spug
              </li>
              <li style={{ fontSize: "0.8em" }}>
                <span style={{ color: "#00FFFF" }}>Jaque Manaque</span>: wannabe
                knight squire-lad trying to earn his keep
              </li>
            </ul>
            <div
              style={{ border: "3px solid red", padding: "5px", margin: "5px" }}
            >
              Misery Countdown:
              <ul>
                <li>
                  Psalm 6, Verse 1: You shall know the last day is come. The sun
                  shall set and never rise.
                  <ul>
                    <li>
                      EFFECT: sun never shines. World is dark always. No more
                      daytime.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            This session saw KLATU and SPUG sitting out.
            <br />
            <br />
            Our anti-heroes{" "}
            <span style={{ color: "#FF7F50" }}>
              returned to the western shore of Lake Onda
            </span>{" "}
            to the hut of Haguzza the Blood Witch after dispatching the goblins
            at the WAILING WELL.
            <br />
            Rolf, the zombie: now that he is not strung up to be forever
            disemboweled like a pathetic demi-god to make goblin stew, he finds
            himself without direction. He decides to tag along with the party.
            <br />
            <br />
            Back at the hut, Haguzza is pleased with the news and claims the
            pallor of her changeling infant is already better. "And now," she
            cackles, "I know you're worthy. So, I really only need one more
            simple thing before I tell you what I know of the BLOOD RELIC OF
            ANUK SCHLEGER."
            <br />
            <ImageComponent
              image={cultist}
              caption="Serpent Cultist"
              cssClass="imageRight"
              handleClick={handleClick}
              modalBody="Serpent Cultists at the Sepulchre of the Swamp Witch."
            ></ImageComponent>
            <ModalComponent
              show={showModal}
              onHide={handleCloseModal}
              modalData={modalData}
            ></ModalComponent>
            <span style={{ color: "#FF7F50" }}>
              Haguzza needs her adult daughter Tippi to return home
            </span>{" "}
            and help raise the changeling into a proper blood witch. "She ran
            off about a year ago to marry a blacksmith in Crystal Bay. Can you
            imagine? A blacksmith! Anyhow, just bring her home is all I ask. If
            she resists knock her out and toss her in a wagon needs be. She is
            in the village of Crystal Bay on the western shore of the southwast
            peninsula of Wastland just a few days from here."
            <br />
            <br />
            Sir Fat Head explains that they need some silver up front. Haguzza
            seems surprised to hear about the rising cost of oil due to the
            permanent darkness from the first misery. She seems unaffected since
            her hut always has a continual fire with supernatural shapes dancing
            in the flames.
            <br />
            <br />
            <span style={{ color: "#FF7F50" }}>
              Haguzza is convinced to give up some silver for "expenses".
            </span>{" "}
            However, our gang takes a detour a quarter day to the East to
            <span style={{ color: "#FF7F50" }}>
              &nbsp; investigate tales of a serpent cult in the SEPULCHRE OF THE
              SWAMP WITCH
            </span>
            . Along the way the weather is LIFELESS GREY and Sir Fat Head and
            Winston become DEPRESSED. Herman is saved from that condition by
            Jacque Manaque's critial save which took the form of a boundless,
            irresistable (if not irritating) squire optimism and a few
            knock-knock jokes that made the gutterborn scum giggle. .
          </p>
        </div>
      ),
    },
    {
      title: "Sepulchre of the Swamp Witch",
      text: (
        <div class="morkBorgFont morkBorgTitleText">
          <ImageComponent
            image={ueth}
            caption="Ueth the Scalehunter"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Ueth the Scalehunter; leader of the ancient serpent cult."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>

          <p>
            <br />
            The party arrives at a partially sunken doorway to a wet, swampy
            lair where the serpent cultists live. A{" "}
            <span style={{ color: "#FF7F50" }}>giant emerald snake </span> rises
            from the water and says the door will only open to those affected by
            the snakes venum. The party is suprisingly ready to acquiese.
            Everyone makes their TOU saves and so only Hildebrand is affected.
            <br />
            Hildrebrand starts to see everything as a wild, kaleidoscopic
            drug-enhanced phantasmagoric landscape.{" "}
            <span style={{ color: "#FF7F50" }}>
              Hildebrand is basically trippin' balls!&nbsp;
            </span>
            The module mentions seeing new things such as the colors jale, dolm,
            and ulfire. The back story on those colors is quite cool, so here it
            is:
            <br />
            <br />
            <i>
              Jale, Ulfire and Dolm - Two new primary colors (Jale and Ulfire)
              and a compound color of ulfire and blue (dolm) from{" "}
              <span style={{ color: "#FF7F50" }}>A Voyage to Arcturus </span>
              (1920) by David Lindsay. Jale and Ulfire are described as "Just as
              blue is delicate and mysterious, yellow clear and unsubtle, and
              red sanguine and passionate, so he felt ulfire to be wild and
              painful, and jale dreamlike, feverish, and voluptuous."
            </i>
            <br />
            <br />
            It turns out that{" "}
            <span style={{ color: "#FF7F50" }}>
              Hildebrand can see passages through seeming solid stone as
              patterns of glittering gold specks
            </span>
            <ImageComponent
              image={worshipHall}
              caption="Worship Hall"
              cssClass="imageRight"
              handleClick={handleClick}
              modalBody="In the Worship Hall, the party goes along with the service, creates a distraction, and makes a break for it."
            ></ImageComponent>
            <ModalComponent
              show={showModal}
              onHide={handleCloseModal}
              modalData={modalData}
            ></ModalComponent>
            ; hidden doors that appear as solid walls to anyone that does not
            have the venom in their system. The venom bestows Emerald Vision for
            7 days an addition to the euphoric and hallucinatory effects (which
            impose a -1 initiative and cause fumbles on 1-3 instead of just a
            1).
            <br />
            <br />
            The common room is empty and the party follows Hildebrand through
            the emerald vision door into a bedchamber. The ceiling is adorned
            with alligator skins and cloth and reeds are strewn about the floor
            around a reed mattress. A{" "}
            <span style={{ color: "#FF7F50" }}>
              scoll of SLITHERING STRANGULATION{" "}
            </span>{" "}
            is found.
            <br />
            <br /> The ensuing trek through the sepulchre includes the
            following, in order:
            <ul>
              <li>
                In the Worship Hall,{" "}
                <span style={{ color: "#FF7F50" }}>
                  FORKED-TONGUE DEVOTEES are praying and harrassing five
                  imprisoned villagers
                </span>{" "}
                in metal cages. They are suspicious of the characters. However,
                with Hildebrand obviously "blessed" with Emerald Vision and some
                good roleplay, the guards are willing to let the "doubters"
                wander about and learn about the cult as long as
                <span style={{ color: "#FF7F50" }}>
                  &nbsp;Hildebrand stays locked in one of the cages
                </span>
                &nbsp; and the zombie ROLF leaves. The leader, UETH THE
                SCALEHUNTER, accompanies Rolf outside. It's at this time
                everyone recalls that the hired servant to guard the wagons and
                tend livestock was sacrificed to Haguzza so maybe Rolf can take
                over guard duty. .
              </li>
              <li>
                The party goes west into the SNAKE PIT room. Herman and Sir Fat
                Head supplicate to an Emerald Serpent in that room to try and
                get bitten again while Winston talks to a devotee dropping live
                mice into a snake pit in the middle of the room. Eventually,{" "}
                <span style={{ color: "#FF7F50" }}>
                  all PCs are bitten and are afflicted with the glorious emerald
                  vision
                </span>
                .
              </li>
              <li>
                A{" "}
                <span style={{ color: "#FF7F50" }}>
                  prisoner in the cage next to Hildebrand
                </span>{" "}
                is able to hiss that he knows the Klopstocks of Schleswig and
                will introduce the party if they can rescue him. As the
                desperate soul blatthers Hildebrand notices a RUBY rising hidden
                under a scrunched blanket on the floor of the WORSHIP HALL.
              </li>
              <li>
                Meanwhile, back in the SNAKE PIT room. A mention of seaching for
                an artifact makes the devotee suspcious. Some lucky rolls and
                quick talk calms the guard and convinces him the party is more
                about becoming disciples than being treasure hunters. However,
                the
                <span style={{ color: "#FF7F50" }}>
                  devotee keeps a big arm draped around Jacque
                </span>{" "}
                as his pet snake curls around the squire's neck. It's clear that
                Jacque is being help captive similarly to Hildebrand in the
                Worship Hall.
              </li>
              <li>
                Sir Fat Head and Herman go south into the bedrooms and meet the
                cultists. In my mind, they are basically a tongue-in-cheek riff
                on the "dirty hippie" stereotype (note: let it be know this
                author was a dirty neo-hippie, so . . . there's that).{" "}
                <span style={{ color: "#FF7F50" }}>
                  Sir Fat Head gets into a deep conversation with the addled
                  cultists
                </span>{" "}
                and realizes their faith is less about solid principles than it
                is about getting jacked on emerald venom and sitting around
                having sex and partying. That said,{" "}
                <span style={{ color: "#FF7F50" }}>
                  Herman makes out with a cultist named Dinah
                </span>{" "}
                who follows Herman afterward as an enamored paramour (at least
                for today).
              </li>
              <li>
                Sir Fat Head, Winston Dunghill, and Herman return to the Worship
                Room. The devotees can see that everyone is "blessed" now, and
                that Dinah has taken a shine to Herman. Relieved, the devotees
                are excited to have the party pray with them. The party kneels,
                rolls their eyes, and makes hissing sounds along with the
                priests of the cult. Herman whispers to one of the six priests
                who takes it in stride. This opens the door for a power use.{" "}
                <span style={{ color: "#FF7F50" }}>
                  Herman uses ENOCHIAN SYNTAX
                </span>{" "}
                to make the priest start shouting blasphemies and run out of the
                hall to the south.
              </li>
              <li>
                "Oh my god, what's wrong with Roger this time?" the other
                priests wonder. Several pursue their confused bretheren as the
                other two pound on the cages and tell the human prisoners to
                shut the hell up. This is the cue for party to quick step into
                the Snake Pit room. When they enter, the devotee menacing Jacque
                asks, "What the hell is going on?" as he heard the shouting in
                the Worship Hall. The party explains one of the priests lost his
                faith and started having doubts. "Was it Roger? Damn it.{" "}
                <span style={{ color: "#FF7F50" }}>
                  Why does Roger always question shit??
                </span>
                "
              </li>
              <li>
                The Snake Pit guard is convinced to let Jacque go in the care of
                the "blessed" party. Knowing that the northernmost hidden
                emerald vision door forbids cultists to go past it, the{" "}
                <span style={{ color: "#FF7F50" }}>
                  party runs through the door
                </span>
                . Winston is triple-attacked by the emerald serpent but his
                armor absorbs most of the damage.{" "}
                <span style={{ color: "#FF7F50" }}>
                  Herman tries to drag Dinah along but fails a PRE roll
                </span>
                . So, Dinah not only wrenches away from the fordibben portal but
                she runs screaming back into the cult lair to alert everyone.
                Winston then uses SLITHERING STRANGULATION in the emerald
                doorway to the garden room to twart potential pursuers.
              </li>
              <li>
                <ul>
                  <li>
                    <b>Herman's Hippy Heartbreak in Two Panels</b>
                    <br />
                    <img
                      src={dinah}
                      style={{ maxWidth: "400px" }}
                      alt="Herman and Dinah"
                    ></img>
                  </li>
                </ul>
              </li>
              <li>
                Once through the door, the party enters into the gardens. There
                they find a{" "}
                <span style={{ color: "#FF7F50" }}>
                  giant flower that opens as a doorway
                </span>{" "}
                into a fern-choked secret room where{" "}
                <span style={{ color: "#FF7F50" }}>
                  they find the CROAKING TRIDENT{" "}
                </span>{" "}
                which Winston takes as his new super weapon.
              </li>
              <li>
                Then, the party battles the boss and the reason no cultists come
                to the north part of the lair. Yes, they{" "}
                <span style={{ color: "#FF7F50" }}>
                  battle the SWAMP WITCH{" "}
                </span>
                with her LUNAR ZWEIHANDER sword! They beat the witch down with
                some insane rolls and excellent use of omens to maximize damage.
                <br />
                <img
                  src={swampWitch}
                  style={{ maxWidth: "400px" }}
                  alt="Swamp Witch"
                ></img>
              </li>
              <li>
                The witch is defeated, the party hits an invisible barrier in
                the winding labyrinth stairs, and when the turn back to the
                witch's tomb, she back!!
              </li>
              <li>
                They lay the witch down again and then hack apart a gnarled
                clump of roots shaped like an altar that pulses each time she
                dies and remove her secret method to keep bringing her body
                back.
              </li>
              <li>
                Free to flee the lair at last, our heroes descend the
                labyrinthian stairs until{" "}
                <span style={{ color: "#FF7F50" }}>
                  they meet TWO GIANT COSMIC SPACE FROGS{" "}
                </span>{" "}
                who croak, "What do you seek?"
              </li>
            </ul>
            And that's here the session ended. All of the GETTING BETTER rolls
            were solid to outstanding. Next session, we see what the freaky
            space frogs have to say. Do they know aught about the BLOOD RELIC?
            Is the labyrinthe a way out? Stay tuned.
            <br />
            <br />
            This episode of Malum Mortis brought to you in part by:
            <br />
            Module:{" "}
            <a
              href="https://www.drivethrurpg.com/product/380973/MORK-BORG-CULT-HERETIC"
              rel="noreferrer"
              target="_blank"
            >
              HERETIC.
            </a>
            <br />
            Game:{" "}
            <a
              href="https://morkborg.com/content/"
              rel="noreferrer"
              target="_blank"
            >
              Mork Borg
            </a>
            <br />
            Module (METAPLOT):{" "}
            <a
              href="https://www.drivethrurpg.com/product/423719/Malum-Mortis-A-Mork-Borg-Metaplot"
              rel="noreferrer"
              target="_blank"
            >
              Malum Mortis: A Mork Borg Metaplot.
            </a>
            <br />
          </p>
        </div>
      ),
    },
  ];

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/mork-borg/bkgnd-01.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <Container>
      {/* <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin" 
                    style={{position: 'float', float: 'right',width: '120px', height: '240px'}} marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=smokeraven-20&language=en_US&marketplace=amazon&region=US&placement=9189143701&asins=9189143701&linkId=d1384e0e6b9ef083fee35d7272a71f88&show_border=true&link_opens_in_new_window=true"></iframe> */}
      <Row>
        <Col>
          <div
            style={{ backgroundColor: "transparent", fontFamily: "Orbitron" }}
          >
            <main role="main" className="inner cover text-center">
              <img
                src={mbLogo}
                style={{ maxWidth: "200px" }}
                alt="Malum Mortis Mork Borg campaign"
              />
            </main>
            <div style={pageHeader}>
              <h1 className="cover-heading">Session 09: Malum Mortis</h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title className="cardTitle-mork-borg">
                Our bad boys of the Dying Lands detour on 2nd mission for
                Haguzza the blood witch to party with the cultists in the
                Sepulchre of the Swamp Witch.
              </Card.Title>
              <Card.Text>
                <ContentContainer data={section1Data} />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MBMalumMortisSession09;
