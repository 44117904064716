import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import deadBlueSea from "../../../../assets/myz/dead-blue-sea.jpg";
import steelCrest from "../../../../assets/myz/steelcrest-battle-map.jpg";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Bookkeeping",
    text: (
      <p>
        06-05-0001:
        <br />
        <br />
        Points are spend and mutation points allocated. Three more bitterbeast
        eggs hatch, pushing the Sunshine Plaza toward their 30 bitterbeast
        limit. The ARK REPUTATION roll shows that the Helldrivers launched a
        parallel attack on the Pure Plant that coincided with their Ark attack
        on the Plaza last session. Lutrell lost two people.
        <br />
        <br />
        The IL Gang buys Spear Rifles and arrows to be prepared for battle at
        sea. Among the merchants aboard the Rust Castle are:
        <ul>
          <li>
            Gregoria and her son Ibor from <b>Cage Island</b>.
          </li>
          <li>
            Evgenia of the <b>Fardrifters</b>.
          </li>
          <li>
            Xerxes and his companions from the <b>Oilfields of the Ancients</b>.
            This includes Vanja who is the only one who seems different than the
            others.
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "Rust Castle Shifts",
    text: (
      <p>
        Smoke Raven and Hulagu pay to go into the lounge of the Rust Castle and
        speak with Berke. Smoke Raven plays down their sense of anger at Berke's
        betrayal during the Civil War at the Sunshine Plaza. Hulagu keeps fairly
        quiet, barely able to contain his rage and desire to simply gut the
        traitor with a katana.
        <br />
        <br />
        Berke seems magnanimous and indicates that being separate forces and
        getting down to business may wind up being beneficial over the long
        term. Smoke Raven gives him some information about the Sunshine Plaza
        population and learns that Berke's new gang lives aboard a ship called
        The Wrecker. His influence as a boss is now concentrated on the watery
        area known as the Dead Blue Sea.
        <br />
        <br />
        After the interview with Berke, Smoke Raven assures Hulagu that with
        Berke you have to play a long game and revenge will come at the right
        time. Gladhand hears of Berke's attitude and grabs a ride to the Castle
        from Whopper guard Cad. However, Berke is retired for the evening. He
        sends a note inviting Gladhand to stay in a complimentary suite on
        Berke's dime and they will meet for breakfast in the morning.
        <br />
        <br />
        That evening, everyone wakes up to the mettalic screeching and low, deep
        groaning sound of the Rust Castle shifting and settling. The IL Gang
        watches from the shore along with other zone citizens. Meanwhile,
        Gladhand is rudely dumped out of bed by the earthquake-like shift.
        Mechie Adalia runs into his room asking for help. The comatose
        Double-Bird has fallen on the floor of the medical bay and her assistant
        robot is shorting out from moisture. Gladhand gives a helping hand
        gladly.
      </p>
    ),
    image: {
      imagePath: deadBlueSea,
      altText: `IL Gang launches into the Dead Blue Sea as their reputation in the Zone blossoms`,
      className: "imageLeft",
      imageCaption: "Dead Blue Sea",
    },
  },
  {
    title: "Aboard the Steelcrest",
    text: (
      <p>
        Most of the guests at the Rust Castle flee as the castle settles and
        shifts.
        <br />
        <br />
        Mechie Boss Nikkil summons the entire IL Gang to the lounge for a
        meeting. Berke has put in a word for the IL Gang and Nikkil has decided
        to offer them a mission.
        <br />
        <br />
        The Rust Castle is disintegrating slowly from the ground up; the Mechies
        are living on borrowed time unless they can repair the struts and
        structures proping up the shattered half of the ancient ship that
        comprises their home.
        <br />
        <br />
        One thing they need is an artifact allowing extended breathing
        underwater. They know it can be found in a sector near the Sunshine
        Plaza on a small island where stalkers have heard stories about a gient
        metal tube from the old days. But there are also stories of strong,
        dangerous humanoids on that island.
        <br />
        <br />
        The gang is offered a free escort with daily water and grub aboard the
        Steelcrest with Elwith as captain. Roggi and Elli, the whopper sisters,
        will accompany, but only to protect Elwith as their one and only
        priority. In exchange for bringing back the artifact and letting Nikkil
        study the battle bus Summer of Love and the bitterbeasts, he offers 3D6
        grub, 6D6 scrap and 10D6 bullets upon delivery of the artifacts.
        Gladhand figures since bitterbeasts are the unique value of the Plaza;
        if they can team-up with Rust Castle and open a bitterbeast stall at the
        marketplace, it shall be known as Doc Gladhand's Sunshine Bitterbeast
        Babies Inc. (a subsidiary of the IL Gang).
        <br />
        <br />
        And so the gang heads out upon the waters of the Dead Blue Sea. In the
        first few sectors they encounter acid raid, a glass storm, and giant
        lobsters. The glass storm is a deadly malestrom of sharp, airborne
        shards and it tears up the captain Elwith. She is broken and stricken
        with paralysis. Her healing time is over 10 days and everyone is
        decidedly glad Smoke Raven took a point in NAVIGATOR so he can pilot the
        Steelcrest under Elwith's watchful, but paralyzed, eyes.
        <br />
        <br />
        During battle with giant lobsters, Doc Gladhand is broken. He suffers a
        DAMAGED KIDNEY. Luckily, Chromicus heals him. When all is said and done,
        Doc Gladhand will not die but he suffers the condition until the morning
        of the 13th. On the plus side, giant lobsters go good with butter and
        the gang butchers over 30 units of grub which they can zone cook
        leisurely aboard the Steelcrest without losing travel time.
      </p>
    ),
    image: {
      imagePath: steelCrest,
      altText: `Shortly after Elwith is broken and paralyzed by a glass storm, Doc Gladhand gets a damaged kidney during battle with giant lobters`,
      className: "imageLeft",
      imageCaption: "Steelcrest battle map",
    },
  },

  {
    title: "Wrapup",
    text: (
      <p>
        <br />
        <br />
        Next session will start on in-game morning of 06-06-2021 with party
        aboard the Steelcrest on the Dead Blue Sea.
        <br />
        <br />
        We reviewed XP system. Gang decided just assigning XP is not
        satisfactory, but that the two XP questions about NPC you hate and
        protect are limiting. We decided that each session will have a base of
        three XP (conflating the NPC questions with participation in session,
        working on projects, and discovering a new zone). And then, in addtion
        to the three XP, we will discuss each character in turn to see if they
        quality for extra XP point based on:
        <ul>
          <li>Did you sacrifice or risk something for your buddy?</li>
          <li>
            Did you sacrifice or risk something or vigorously pursue your big
            dream?
          </li>
        </ul>
        XP for this session was: Gladhand (4), Chromicus (3), Bayazid (3),
        Hulagu (4), Double-Bird (3), and Smoke Raven (4).
        <br />
        <br />
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        .
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
];

const MYZAnchortownSession37 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 37: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  The IL gang heads out into the Dead Blue Sea aboard the
                  Steelcrest on a mission for the Rust Castle.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                {/*<Card.Title style={cardTitle}>Shootout at Burger Jim's, 03-27-0001 - 03-27-0001</Card.Title>*/}
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession37;
