import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import NavBarMalumMortisX from "../../../assets/mork-borg/navbar-icon-malum-mortis.webp";

const ExquisiteCorpsesNavBar = () => {
  return (
    <NavDropdown title="Exquisite Corpses" id="basic-nav-dropdown">
      <Navbar bg="dark" variant="dark" expand="md" sticky="top">
        <Navbar.Brand href="#">
        <div style={{ textAlign: 'center', padding: "0px 0px 0px 8px" }}>
          Exquisite Corpses
          <br />
          <small>Mork Borg Solo</small>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <a href=".">
              <img
                src={NavBarMalumMortisX}
                alt="SmokeRaven"
                style={{ maxHeight: 85 }}
              />
            </a>
          </Nav>
          <NavDropdown title="Sessions" id="basic-nav-dropdown">
            <Navbar bg="dark">
              <NavDropdown
                bg="dark"
                title="Sessions 01-10"
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item href="/mork-borg-session-01-exquisite-corpses">
                  Solitary Defilement: Session 01
                </NavDropdown.Item>
                <NavDropdown.Item href="/mork-borg-session-00-exquisite-corpses">
                  Solitary Defilement: Session 00
                </NavDropdown.Item>
              </NavDropdown>
            </Navbar>
          </NavDropdown>
        </Navbar.Collapse>
      </Navbar>
    </NavDropdown>
  );
};

export default ExquisiteCorpsesNavBar;
