import React from "react";
import "../../../index.css";

import { useEffect } from "react";

import hulagu from "../../../assets/myz/hulagu-pc.jpg";
import chromicus from "../../../assets/myz/chromicus-pc.jpg";
import ghostBird from "../../../assets/myz/ghost-bird-pc.jpg";
import smokeRaven from "../../../assets/myz/smoke-raven-pc.jpg";
import bayazid from "../../../assets/myz/bayazid-raven-pc.jpg";
import gladhand from "../../../assets/myz/doc-gladhand-pc.jpg";
import kohen from "../../../assets/myz/kohen-pc.jpg";
import jebe from "../../../assets/myz/jebe-pc.jpg";
import doubleBird1 from "../../../assets/myz/double-bird-1-pc.jpg";
import doubleBird2 from "../../../assets/myz/double-bird-2-pc.jpg";
import rowbertSkut from "../../../assets/myz/rowbert-skut-pc.jpg";

import cover1 from "../../../assets/myz/session-42-banner.jpg";
import cover2 from "../../../assets/myz/session-43_44-banner-the-big-picture.jpg";
import cover3 from "../../../assets/myz/session-45-banner-cage-island.jpg";
import cover4 from "../../../assets/myz/session-46-banner-ghost-ship.jpg";
import cover5 from "../../../assets/myz/session-47-banner-green-man-free.jpg";
import cover5a from "../../../assets/myz/session-48-command-center-charlie.jpg";
import cover5b from "../../../assets/myz/session-49-rad-protocol.webp";
import cover5c from "../../../assets/myz/session-50-chronicle-of-the-titan-powers.webp";
import cover5d from "../../../assets/myz/session-53-back-to-the-surface.jpg";
import cover5e from "../../../assets/myz/session-54-rust-castle-revenge.webp";
import cover6 from "../../../assets/myz/session-55-im-your-captain-half-page.webp";
import cover7 from "../../../assets/myz/session-56-57-cracking-the-silver-egg-half-page.webp";
import cover8 from "../../../assets/myz/session-61-62-requiem-for-doc-gladhand.webp";
import cover9 from "../../../assets/myz/session-63-eden-found-half-page.webp";


import thing1 from "../../../assets/myz/childrens-game.jpg";
import thing2 from "../../../assets/myz/cereal.jpg";



const MYZCoverGallery = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <img
        style={{ float: "left", maxHeight: "300px" }}
        src={hulagu}
        alt="Childrens Board Game"
      />
      <img
        style={{ float: "left", maxHeight: "300px" }}
        src={chromicus}
        alt="Childrens Board Game"
      />
      <img
        style={{ float: "left", maxHeight: "300px" }}
        src={smokeRaven}
        alt="Childrens Board Game"
      />
      <img
        style={{ float: "left", maxHeight: "300px" }}
        src={bayazid}
        alt="Childrens Board Game"
      />
      <img
        style={{ float: "left", maxHeight: "300px" }}
        src={ghostBird}
        alt="Childrens Board Game"
      />
      <img
        style={{ float: "left", maxHeight: "300px" }}
        src={kohen}
        alt="Childrens Board Game"
      />
      <img
        style={{ float: "left", maxHeight: "300px" }}
        src={gladhand}
        alt="Childrens Board Game"
      />
      <img
        style={{ float: "left", maxHeight: "300px" }}
        src={doubleBird2}
        alt="Childrens Board Game"
      />
      <img
        style={{ float: "left", maxHeight: "300px" }}
        src={jebe}
        alt="Childrens Board Game"
      />
      <img
        style={{ float: "left", maxHeight: "300px" }}
        src={doubleBird1}
        alt="Childrens Board Game"
      />
      <img
        style={{ float: "left", maxHeight: "300px" }}
        src={rowbertSkut}
        alt="Childrens Board Game"
      />

      {/* COVERS */}
      <img
        style={{ float: "left", maxHeight: "300px" }}
        src={cover1}
        alt="Childrens Board Game"
      />
      <img
        style={{ float: "left", maxHeight: "300px" }}
        src={cover2}
        alt="Childrens Board Game"
      />
      <img
        style={{ float: "left", maxHeight: "300px" }}
        src={cover3}
        alt="Childrens Board Game"
      />
      <img
        style={{ float: "left", maxHeight: "300px" }}
        src={cover4}
        alt="Childrens Board Game"
      />
      <img
        style={{ float: "left", maxHeight: "300px" }}
        src={cover5}
        alt="Childrens Board Game"
      />
      <img
        style={{ float: "left", maxHeight: "300px" }}
        src={cover5a}
        alt="Childrens Board Game"
      />
      <img
        style={{ float: "left", maxHeight: "300px" }}
        src={cover5b}
        alt="Childrens Board Game"
      />
      <img
        style={{ float: "left", maxHeight: "300px" }}
        src={cover5c}
        alt="Childrens Board Game"
      />
      <img
        style={{ float: "left", maxHeight: "300px" }}
        src={cover5d}
        alt="Childrens Board Game"
      />
      <img
        style={{ float: "left", maxHeight: "300px" }}
        src={cover5e}
        alt="Childrens Board Game"
      />
      <img
        style={{ float: "left", maxHeight: "300px" }}
        src={cover6}
        alt="Childrens Board Game"
      />
      <img
        style={{ float: "left", maxHeight: "300px" }}
        src={cover7}
        alt="Childrens Board Game"
      />
      <img
        style={{ float: "left", maxHeight: "300px" }}
        src={cover8}
        alt="Childrens Board Game"
      />
      <img
        style={{ float: "left", maxHeight: "300px" }}
        src={cover9}
        alt="Childrens Board Game"
      />

      <img
        style={{ float: "left", maxHeight: "300px" }}
        src={thing1}
        alt="Childrens Board Game"
      />
      <img
        style={{ float: "left", maxHeight: "300px" }}
        src={thing2}
        alt="Cereal"
      />
    </div>
  );
};

export default MYZCoverGallery;
