import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import logo from "../../../../src/assets/kids-on-bikes/logo-kids-on-bikes.webp";
import sectionDivider from "../../../../src/assets/kids-on-bikes/kids-on-bikes-page-banner.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";
import "./kids-on-bikes-styles.scss";

import aspect from "../../../../src/assets/kids-on-bikes/session04/aspect.webp";
import balanceEngine from "../../../../src/assets/kids-on-bikes/session04/balance-engine.webp";
import darknessOfTheRock from "../../../../src/assets/kids-on-bikes/session04/darkness-of-the-rock.webp";
import heartOfTheRock from "../../../../src/assets/kids-on-bikes/session04/heart-of-the-rock.webp";
import maria from "../../../../src/assets/kids-on-bikes/session04/maria-garcia.webp";
import michaeal from "../../../../src/assets/kids-on-bikes/session04/michael-garcia.webp";
import otto from "../../../../src/assets/kids-on-bikes/session04/otto-fairfield.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "1",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Carter One",
  backgroundColor: "rgba(0, 0, 0, 1) ",
  color: "white",
  marginBottom: "15px",
};

const KOBTurnaroundRockSession04 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  const section1Data = [
    {
      title: "SIGNS AND PORTENTS",
      sectionTitleCssName: "sectionTitleKOB",
      text: (
        <div className="kidsOnBikesBodyText">
          <ImageComponent
            image={aspect}
            caption="The Aspect"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="The Aspect is the powered character of the game, not Chrisper Liu's as we had thought."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          Welcome back to Turnaround Rock, a sleepy little town on the Oregon
          coast, about 80 miles north of the California border. Here generations
          of families work the sea, a hard life made more difficult by The Rock,
          a large rocky island which keeps larger boats and ships from coming
          into the harbor. It is late dusk on Wednesday, April 12, 1989, and
          Ajax, Ariadne, and Sheldon have just left the hospital where they
          learned that the strange aspect to which they have attuned is not
          Christopher Liu after all. They see Felix Waites also leaving the
          hospital. When they look out towards the water, the kids note that the
          deep dusk of early night is broken by the unmistakable deeper darkness
          of the unnatural cloud that has settled over The Rock in the bay. It
          looks almost like a tumor, and electricity crackles over it.
          <br />
          <br />
          The kids can still see the spirit, or aspect, hovering nearby. It is
          dimmed now, almost as if exhausted by all the recent events, and they
          can only see it out of the corner of their eyes. The kids realize they
          also are spent and in need of rest.
          <br />
          <br />
          Thinking back to seeing Felix Waites wander confused and bloody out of
          the woods by the Marina, Ariadne forgoes unlocking her bike from the
          rack and jogs over to Felix instead, not wanting to miss him. “Mr.
          Waites, Mr. Waites!” she yells out as he opens his car door. The
          others run along with her.
          <br />
          <br />
          Felix seems reticent about talking to the kids. Despite Ariadne being
          unsuccessful in charming Waites into conversation, Ajax grits his
          teeth and questions the man. Felix finally confesses that when he came
          out of the woods, it was his blood all over him, but that he wasn't
          hurt. He insists he must go, but just then Deputy Lily Waters pulls
          into the parking lot in a police cruiser. She rolls up next to them
          and gets out of the car.
          <br />
          <br />
          “I see you've met these young charges,” she says, implying some
          responsibility for them or perhaps making a sly electrical joke?
          <br />
          <br />
          Waites and Waters exchange a knowing glance, and Waites admits that he
          can sense an energy around them that feels like what he felt in the
          woods. When pressed, he shares that he was healed in the woods by what
          looked like the spirit of the previously missing boy scout,
          Christopher Liu.
          <br />
          <br />
          “Here we go,” mutters Lily, protectively.
          <br />
          <br />
          Sensing that Waites knows more about the larger picture, Sheldon says,
          “The more we know, the safer we are. So, tell us or not, but if you
          don't tell us, you're putting us in danger.”
          <br />
          <br />
          Felix says to Lily, “If these are the kids you mentioned, then…” But
          Lily doesn't confirm anything. Felix turns back to the three of them,
          “We're having a meeting at the cannery tomorrow and you should
          probably be there.”
          <br />
          <br />
          Sheldon is still not satisfied, and he still has psychic energy to
          fuel his powers, so he quickly grabs onto Felix and goes into the
          Dreamscape.
          <br />
          <br />
          In the Dreamscape, Sheldon and Felix are in a misty wood near the edge
          of the water. Felix has a small boat that he's prepared to row out in
          the direction of The Rock. The howl of the Wendigo pierces the
          Dreamscape. The monster lopes out of the woods and slashes open
          Felix's chest. Lily Waters comes running out of the woods. Perhaps she
          had been pursuing the beast? She begins chanting, and the Wendigo runs
          off, smoky wisps beginning to reveal its human form.
          <br />
          <br />
          Sheldon sees that here, in the Dreamscape, the translucent form of
          Christopher shimmers like heat on a hot day, shifting subtly back and
          forth between the image of Christopher and the appearance of the
          aspect.
          <br />
          <br />
          There is a blue flash of light and Felix's wounds knit closed. Sheldon
          and Felix emerge from the Dreamscape. To the others, it seems as if
          they were still for but a moment. “What the hell was that?” Felix
          asks, shaken.
          <br />
          <br />
          When he settles down, he tells them that when he emerged from the
          woods that day, he was confused and didn't know how he'd gotten there.
          “It took me a while to remember what happened, but even then, it
          wasn't as clear as what you just made me relive. That you can do that
          reinforces that you should join us tomorrow. The Fishermen are meeting
          at noon at the processing plant. If you want to know more, you should
          be there.” They hear the capital F in “Fishermen” and know it's not
          just a gathering of people who fish, but a meeting of the group that
          formed following the disasters of 1889.
          <br />
          <br />
          Ariadne, whose home is the farthest away, tries to convince Deputy
          Waters to give her a ride in the police cruiser. Lily scoffs and
          advises them all to just go home, but Ariadne finally charms her to
          get a ride to the Coastal Nose neighborhood. Lily supervises Ariadne
          in putting the bike in the trunk, so that Ariadne doesn't mess with
          the shotgun or tactical gear already stored back there. Since Lily is
          not in her police SUV, there's no room for the other bikes in the
          back, so Sheldon and Ajax decide to bike together. In the cruiser,
          Lily isn't in the mood for conversation, which is perfect since
          Ariadne nods off, lulled by the safety of the diminutive deputy's
          presence. After Ariadne and Deputy Waters pull away, the other two
          bike towards downtown. Though Sheldon's house is closer, he chooses to
          ride with his friend.
          <br />
          <br />
        </div>
      ),
    },
    {
      title: "FATHERS AND SONS",
      sectionTitleCssName: "sectionTitleKOB",
      text: (
        <div className="kidsOnBikesBodyText">
          Sheldon notes that “Ariadne's parents are at the hospital, too, so
          maybe I'll just go sleep at her house. My parents won't care.”
          <br />
          <br />
          Ajax thinks that's a great idea, but he wants to ask his mom first, so
          the two of them bike to the Geoduck Inn. As they walk into the bar,
          Sheldon looks around, knowing that there's always a chance his father,
          if not drunk at home, will be drinking here. He doesn't see him, but
          Alice Fairfield, Ajax's mom, beelines for Ajax and gives him a big
          hug, holding him tighter and longer than normal. She says she was
          worried about him because of the dark cloud over The Rock. The kids
          notice that everyone in the bar is talking about it. It's a bad omen.
          <br />
          <br />
          Ajax notices that his mom has something in her hand that she's trying
          to hide from him. “Is that a picture of my dad?” he asks.
          <br />
          <br />
          <ImageComponent
            image={otto}
            caption="Otto Fairfield"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Otto is Ajax's father. Otto has been away most of Ajax's life. Ajax resents his father for this, not knowing that his father was travelling the world to find a solution for the imbalance in Turaround Rock."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          It is indeed a picture of Otto Farfield. “Why do you have that out,
          Mom?” She explains that with the strange happenings in Turnaround
          Rock, especially the dark cloud out in the bay, she is scared for the
          safety of Otto and Ajax.
          <br />
          <br />
          “How can Dad get hurt here, Mom? He's in Mexico.”
          <br />
          <br />
          Alice reveals that Ajax's father is in town. This upsets Ajax, since
          Otto has contacted him while in town. Alice explains that for years
          she thought that Otto was crazy, obsessed, and that's why she couldn't
          be with him any longer. But now, with the very air in Turnaround Rock
          feeling strange, she was considering that she might have been wrong.
          She says that he and those other two, Michael Garcia and Felix Waites,
          were always talking about the “balance” and about strange phenomena,
          such as the one now swirling as a dark miasma over The Rock. They were
          always conspiring about fixing things.
          <br />
          <br />
          At the mention of Michael Garcia, Sheldon's father, Sheldon pays even
          closer attention than he has. He has heard everything, using his
          Unassuming skill to be barely noticed by Alice Farfield.
          <br />
          <br />
          Then Ajax's mother, with trepidation, blurts out, “Your father is in
          the conference room, Ajax.”
          <br />
          <br />
          “What? He's here!?”
          <br />
          <br />
          <ImageComponent
            image={maria}
            caption="Maria Garcia"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="Maria is Sheldon's mom. She works as a nurse at Talbot Memorial Hospital."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <ImageComponent
            image={michaeal}
            caption="Michael Garcia"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Michael Garcia is Sheldon's father. He turned to alcohol to cope with the traumas of his encounters with the bad energy of Turnaround Rock when he was a young man."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          Ajax, followed by Sheldon, bursts into the conference room, which is
          little more than a small room used for employee breaks and sometimes
          rented for small get-togethers. Ajax is filled with resentment and
          pain as he sees his father there. As for Sheldon, he wasn't expecting
          to see his own father, Michael Garcia, meeting with Otto Farfield. The
          two boys are dumbfounded at seeing the two dads who have been absent,
          each in their own way, from their lives.
          <br />
          <br />
          Ajax asks his dad what he's doing there, and Otto tells his son that
          he had to return because of the troubles going on in Turnaround Rock.
          He says that Felix Waites called him and said he had to come. “Oh
          sure,” says Ajax, “you don't come back for me, but for some guy and a
          cloud over The Rock you come running.”
          <br />
          <br />
          Sheldon, meanwhile, is perplexed to see that his father is sober.
          Michael tells Sheldon, “I'm sorry, Sheldon. I wanted to be a good
          father, but I didn't know how to live in the present with what I had.
          I was too scared about losing you to be with you the way I should
          have. And now it's too late.”
          <br />
          <br />
          “What do you mean it's too late?” Sheldon demands.
          <br />
          <br />
          Ajax, highly emotional and angry, yells at his father, “You need to
          tell us what's going on! We've been through a lot! Our friend was
          almost killed, and we had to save him! There are strange things
          happening!”
          <br />
          <br />
          “Wait,” says Otto Farfield, “you're the kids who saved Christopher
          Liu? Lily didn't tell us that.”
          <br />
          <br />
          The two fathers exchange knowing looks, and the boys finally learn
          that Otto, Michael, and Felix were at a strange confluence of
          happenings when they were kids. It changed their lives.
          <br />
          <br />
          Ajax learns that his father has been searching around the world for
          answers on how to deal with recurring events in Turnaround Rock that
          go back generations. Otto says that he found himself following false
          rumors and true stories regarding ancient sites, ley lines, cryptids,
          and other unexplainable phenomena.
          <br />
          <br />
          Sheldon learns that his father couldn't deal with all that he and his
          friends had seen and done, whatever that was, and the responsibility
          that came with it. He tried to have a normal life but eventually
          turned to drinking to stop thinking about it, to make it feel like it
          wasn't real.
          <br />
          <br />
          In the meantime, Felix Waites tried to maintain an infrastructure that
          could be in place to help when things inevitably went south again.
          <br />
          <br />
          Otto says, “I didn't find answers in other places, but I did find
          secret technologies and artifacts, perhaps of alien origin. Felix
          poured money into researching things I sent him, trying to construct
          something that would help.”
          <br />
          <br />
          Ajax can't let go of his anger easily. “It seems like you ran away,”
          he says.
          <br />
          <br />
          Sheldon asks about his grandpa, who died the year before. “Did Grandpa
          know about this?”
          <br />
          <br />
          Michael says that he only knew in the way that everyone in town had an
          inkling of something not right. “But he didn't understand. What
          happened to me, to Otto, to Felix…we were a triumvirate.”
          <br />
          <br />
          Ajax snarls, “But what did you learn?”
          <br />
          <br />
          Otto looks at his son carefully. “There are two forces at play,
          fighting each other,” he says. “If we're not careful, the town will
          fall to the darker forces.”
          <br />
          <br />
          Michael tells them that to maintain the balance, someone needs to
          sacrifice, just like Elias Fielder did back in 1889. “There's an
          energy that runs through different families in town. Grandpa didn't
          have it. It can skip a generation of a family. It's not just in three
          families, but it manifests in three people each time.”
          <br />
          <br />
          Otto says, “I think it has to be all three of us.”
          <br />
          <br />
          Ajax wants clear answers. He wants it all to make sense. “What is the
          force of good, Dad? Is that us?”
          <br />
          <br />
          Otto doesn't answer, and Ajax, in frustration and anger, feels the
          need to show just a small inkling of the power he holds. He uses his
          electrical ability to stand his own hair on end. His hair shoots up
          and crackles with electricity. There's also the smell of burning and
          his hair singes at the ends.
          <br />
          <br />
          Ajax sobs, “Dad, I feel like I'm going crazy.”
          <br />
          <br />
          Then his dad is there, pulling him into a hug that is so strong that
          Ajax can't shake free of it. Otto tells Ajax that it's going to be ok.
          <br />
          <br />
          Ajax says that he doesn't believe it.
          <br />
          <br />
          Otto, still holding his son, responds that he doesn't believe it,
          either, but he has to say it. “I love you, son.”
          <br />
          <br />
          Sheldon and his father look at each other, and Sheldon sees that his
          dad is weeping. Sheldon hugs his father, forgiving him.
          <br />
          <br />
          Ajax looks up at Otto, who still won't let him go. “What are you going
          to do about the storm, Dad?”
          <br />
          <br />
          “We're meeting tomorrow, and we have a plan to restore the balance.
          Felix has been pouring money into technology based on things I've sent
          him. He's had equipment trucked into the processing plant and built
          something there that might help us to balance the energies of the
          town.”
          <br />
          <br />
          Ajax says, “It sounds so crazy, but maybe crazy is normal.”
          <br />
          <br />
          Michael says that he, Otto, and Felix are going to try so that
          Sheldon, Ajax, and their friend don't have to.
          <br />
          <br />
          The door to the conference room opens and Alice Farfield looks in.
          Seeing what's going on in the room, she can't help but wrap her arms
          around Ajax and Michael and join their hug. The crying is contagious.
          <br />
          <br />
          Finally, they all break apart. Sheldon tells his dad that they want to
          sleep at Ariadne's.
          <br />
          <br />
          Michael Garcia tells him it sounds like a good idea. “The triumvirate
          should be together,” he says.
          <br />
          <br />
          Otto and Alice agree that Ajax can go as well. With their renewed
          familial bonds and as many questions as answers, Ajax and Sheldon
          return to their bikes and ride to Ariadne's house “on the Nose.”
          <br />
          <br />
          Ariadne awakens to the sound of intruders. She creeps downstairs and
          accosts the boys with a big knife from the kitchen. “What are you
          idiots doing?? You didn't say you were coming here. I thought you were
          the Wendigo!”
          <br />
          <br />
          They talk about what the boys have learned, and Ariadne gets excited.
          “See!? I TOLD you there was something going on at the cannery!”
          <br />
          <br />
          With Ariadne's parents both working late at the hospital, the
          triumvirate discusses all the revelations and whether they should
          attend the meeting at the processing plant or go straight to The Rock
          in the early morning. Ajax is scared that their fathers are going to
          sacrifice themselves and wants to go out to The Rock to see if they
          can do something about it first. He thinks that's where they need to
          go.
          <br />
          <br />
          “We're the ones with the power now,” he says. “We should settle this,
          whatever it is.”
          <br />
          <br />
          Sheldon is more inclined to go to the meeting at noon at the plant, to
          learn more, to see what happens. He thinks they might be able to make
          more of a difference there.
          <br />
          <br />
          Ariadne says, “I'm tired of adults telling me what I can and can't do.
          I think Ajax's sense of urgency about The Rock is important. Maybe we
          can get Harry Darkstar to take us out there in one of his boats. He
          seems to know a lot.” But she wavers as she listens to Sheldon.
          “Hmmm,” she ponders, “maybe going to the cannery is smart. We could
          probably learn more and maybe find a laser gun or something useful.”
          <br />
          <br />
          Ajax feels less certain about his desire to go to The Rock, but then
          he says, “I know how to solve this!” He takes his tarot deck from his
          pack, mixes the cards around, and he draws a card. It is the Page of
          Cups. A young person at the seashore holds a golden cup that has a
          fish in it.
          <br />
          <br />
          “That's it!” Ajax gets excited at the imagery, which he connects to
          their quest. He then explains the meaning, that the card has to with
          listening to intuition, with having a calling, and with embracing and
          believing in the “real you.” He is convinced this indicates they
          should go to The Rock in the morning. He also accepts this card as his
          talisman, knowing the photo of Christopher's family was not it.
          <br />
          <br />
          Ariadne hasn't stopped thinking about Harry Darkstar telling the kids
          they all need a talisman, or Lily Waters talking about her people's
          poetry being her artifact. To Ariadne, those two seemed to be the ones
          to trust. Ajax has his card, and Sheldon has his Wendigo figure that
          he found at the Qua-toh-mah site before their adventure together
          began. What did Ariadne have? She asks Ajax if she can draw a card too
          and use it as her talisman.
          <br />
          <br />
          She does so, drawing the Death card. She goes white from fear, but
          Ajax rushes to explain that the Death card is more about
          transformation and new beginnings. She accepts this and sets the card
          down.
          <br />
          <br />
          Without realizing it, she sets it down on a small hand-held mirror,
          the same mirror she has used many times to check her appearance. She
          notices a crack in the mirror's surface that she never noticed before.
          She thinks of the change of self-awareness she recently experienced
          after saving Christopher. Perhaps she never saw the crack before
          because of her attention on her own reflection, but perhaps the crack
          was new like her perspective. She thinks of how she has been shaken
          and filled with doubt and how her obsession with her own beauty is
          shifting. She pockets the tarot card, feeling inspired but with an
          ache in her heart, unsure what the future would hold.
          <br />
          <br />
          Ajax and Ariadne try to convince Sheldon to draw a card. Their pleas
          fall on stubborn ears. Finally, though we want to avoid PvP, Sheldon's
          player agreed to a Charm role from a role-play perspective. The roll
          is made, and he is unconvinced.
          <br />
          <br />
          <ImageComponent
            image={balanceEngine}
            caption="Balance Engine"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="The Balance Engine is technology assembled to help restore balance to Turnaround Rock."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          Sheldon feels guilty for not respecting his dad before today. When
          asked, the GM assures him that all the players need to regain their
          Psychic Energy Tokens to use their powers is sleep. Having one token
          left that will be replenished anyway, Sheldon uses it to go into the
          Dreamscape, thinking strongly of his father. The Dreamscape offers him
          a glimpse into the Fishermen's plans for the next day. He sees his dad
          gathered with a large group of townspeople. Deputy Waters it there,
          along with many other members of the community from all walks of life.
          They are gathered in a room in the basement of the fish processing
          plant. The room has concrete walls, and the far end houses a big
          machine. In the Dreamscape, Sheldon knows the machine is something
          called a Balance Engine. This is what was built from pieces trucked
          into the cannery in secret.
          <br />
          <br />
          The Balance Engine has spaces in it to house three people, as if they
          would enter and place themselves within, hands raised above their
          heads to rest on a bar above, or maybe be clipped in, feet slipped
          into metal sleeves on the floor of the machine. Sheldon looks to his
          dad and sees a sadness on his face. While the rest of the room
          watches, Michael Garcia, Otto Farfield, and Felix Waites all head
          toward the machine, and before Sheldon can see more, his journey into
          the Dreamscape ends.
          <br />
          <br />
          Sheldon realizes that the necessary “sacrifice” does, in fact, look
          like it includes his dad. The math whiz didn't go for the tarot cards,
          but he comes to the same conclusion as the others. “Yeah, guys,”
          Sheldon says, “let's go for The Rock.”
          <br />
          <br />
          The three agree that their best bet is Harry Darkstar. They make sure
          they all have flashlights for the next day. Ariadne puts a kitchen
          knife in her pack. Then they all collapse into the darkness of sleep,
          mercifully free from dreams of the greater darkness hanging over The
          Rock.
        </div>
      ),
    },
    {
      title: "BETWEEN THE ROCK AND A HARD PLACE",
      sectionTitleCssName: "sectionTitleKOB",
      text: (
        <div className="kidsOnBikesBodyText">
          THURSDAY, APRIL 13th
          <br />
          <br />
          The triumvirate awakens with their Psychic Energy Tokens replenished
          and strengthened (instead of 3/3, their continued attunement gives
          them 5/5). It is 6:26am, a minute before sunrise, but even as the sun
          rises and they leave the house, the early morning is still dark from
          the gloomy pall infecting the atmosphere of the town.
          <br />
          <br />
          The kids ride down to the upper marina to see if Harry Darkstar will
          help them. They have a good feeling about it. When they see him, he is
          puttering around outside his shop, getting some supplies together. He
          is happy to see them. “Hey, kids, was that kid ok that I cut out of
          that box?” he asks, adding, “And shouldn't you be on your way to
          school?”
          <br />
          <br />
          Ariadne begins to tell a semi-elaborate lie about school, but the
          radio Harry has playing in his shop is broadcasting the local morning
          news. They hear the newscaster say that, due to the strange weather
          and other recent events, the school is closed for the day. The voice
          on the radio urges everyone to “be safe out there.”
          <br />
          <br />
          The kids begin to try to convince Harry to take them to The Rock. The
          difficulty of the roll to convince him is exceedingly low, only a two,
          which is pretty much instant success. The three of them rejoice at how
          easy it was. Then Harry tells them that Sheriff Roberts and he were
          already planning to go to The Rock this morning.
          <br />
          <br />
          Ajax asks, “Won't the sheriff stop us from going out to the island?”
          <br />
          <br />
          Harry seems to think he can easily convince him. “We're good friends.
          He'll listen to me.”
          <br />
          <br />
          Just then, Sheriff Roberts pulls up in his cruiser. He looks doubtful
          when Harry mentions that they are coming out to The Rock with them.
          Harry assures him that it will be ok. “These kids need to go out
          there, Vic.” Roberts agrees. Again, it's not that difficult to get him
          onboard with the idea.
          <br />
          <br />
          Jovial and helpful, Harry grabs some sandwiches from his fridge and
          even grabs some fishing poles. “I doubt we'll have any time to fish,”
          he laughs, “but you never know.” It feels almost like a moment of
          normalcy, like it was any other day.
          <br />
          <br />
          The kids clamber into a small deck boat with Harry and Sheriff
          Roberts. Soon, the boat is out at the halfway point between the marina
          and The Rock. The dark clouds loom large. They are dense and rolling.
          Lighting and energy dances around and through it, not necessarily a
          part of it, like another force fighting with it. The dark cloud moves
          almost like a creature through the air around the lighthouse.
          <br />
          <br />
          And, past the halfway point, Harry and the Sheriff both start to
          laugh. All their goodwill falls away, and Harry begins to contort.
          Strange, guttural noises come from him as he begins to transform into
          the Wendigo. The kids are paralyzed with fear. Harry's transformation
          halts and reverses. He is toying with them, grinning sickly as he
          returns to full human form.
          <br />
          <br />
          Sheriff Roberts leisurely opens a tote on the deck and pulls a rope
          from it. He turns toward Ariadne and casually walks toward her,
          smiling. “I'm going to tie you up,” he says.
          <br />
          <br />
          Ariadne uses the blue flash of power for Rapid Reaction to draw the
          kitchen knife from her backpack and kick the Sheriff in his family
          jewels. She successfully connects, and Sheriff Roberts bends forward
          in pain. At that moment, Ajax leaps up and with a high brawn roll
          knocks the already unsteadied Sheriff over the gunwale and into the
          water.
          <br />
          <br />
          Harry Darkstar looms, marching forward to deal with the kids. They are
          terrified after learning that he is the Wendigo. They won't get as
          lucky with him as they did with Sheriff Roberts.
          <br />
          <br />
          Sheldon looks to The Rock, to the shore there. He holds the shore in
          his mind, grabbing Ajax and Ariadne by the hand. In a green flash of
          power, Sheldon translocates them to the shore.
          <br />
          <br />
          Harry circles the boat to pick up the Sheriff, and the kids run to the
          metal staircase that runs up The Rock to the lighthouse. They must
          overcome four obstacles in four rounds to keep ahead of the bad guys.
          The obstacles are created through collaboration with the players. They
          are asked to look at their character fears, motivations, and best
          ability dice. From that, the GM and the characters build the
          obstacles.
          <br />
          <br />
          The first obstacle is a locked gate at the base of the stairs. Sheldon
          has had past success with locks, using his exceptional brain power to
          learn to pick them. He's not always successful, but this time, as Ajax
          and Ariadne groan in despair, he manages to quickly take care of the
          problem. 1/4
          <br />
          <br />
          As the lock comes off the gate, a tendril of the black cloud travels
          down the face of The Rock. They continue to run forward, and it soon
          meets them along the way, coalescing on the stairs and blocking their
          path. This is the second obstacle.
          <br />
          <br />
          Ajax is certain that he can draw lightning from the cloud and harness
          it to open a pathway through. He moves forward and meets the cloud
          with a white flash of energy, but too late he realizes there is no
          lightning in the cloud. He is trying to harness from the wrong source.
          It's not just that he rolled a 3 on Grit, but a feeling that the
          lightning is an opposing force to the darkness. Ajax is engulfed in
          the darkness.
          <br />
          <br />
          Ariadne's fear of being alone stabs through her as she sees Ajax
          disappear. She could lose her friends today. Without thinking, she
          uses her Rapid Reaction to grab Sheldon's hand and dive into the
          cloud, finding Ajax's hand, and passing so swiftly that the three of
          them arrive on the other side. They barely have time to register
          what's happened before they are running again. 2/4
          <br />
          <br />
          But they don't run for long. One look back and they all stop. Behind
          them, they see that the ghostly and skeletal form of the aspect is
          being held back by the swirling darkness of this growing tendril of
          cloud. The crackling yellow light coming off the aspect begins to dim
          as does the aspect itself.
          <br />
          <br />
          <ImageComponent
            image={heartOfTheRock}
            caption="Heart of The Rock"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="The Heart of the Rock is how the Aspect appears in the Dreamlands. The heart is a force of good, lending its power to the kids."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          It is Sheldon's turn to grab everyone's hands. He rolls high and takes
          them all into the Dreamscape. In the Dreamscape are revealed the true
          forms of the aspect and the cloud. They now see the aspect as an
          entity that the Dreamscape titles The Heart of the Rock. This is the
          true form which the aspect represents, and it looks more alien to them
          than skeletal. Another entity, just as strange looking, but more
          menacing, a dark creature of visible solidity yet of a malleable smoky
          form, has wrapped itself around The Heart of the Rock. This is The
          Darkness of the Rock, powerful, driven, and strangling the life out of
          The Heart.
          <br />
          <br />
          Sheldon feels the eyes of The Heart of The Rock locking on his own.
          Beside him, Ajax flashes again with white energy. Ajax understands the
          balance more now, the two forces fighting in front of him. This time,
          in the Dreamscape, he somewhat effectively employs lightning to lend
          power to The Heart. Sheldon aids the effort by triggering a blue flash
          of healing power. The two powers work together. Bolstering bolts of
          white lightning shoot from Ajax's hand and strengthen The Heart. At
          the same time, blue healing energy shoots from Sheldon's eyes and
          settles into the Heart through its eyes.
          <br />
          <br />
          <ImageComponent
            image={darknessOfTheRock}
            caption="Darkness of The Rock"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="The Darkness of The Rock is a malevolent force terrorizing Turaround Rock."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          The Heart breaks free from The Darkness in the Dreamscape, and as the
          kids all exit back into the real world, they see that the aspect has
          broken free from the tendrils of cloud here, dispersing it back into
          the air around them. 3/4
          <br />
          <br />
          They begin to run again.
          <br />
          <br />
          Above, the larger mass of darkness rumbles and swells with anger.
          <br />
          <br />
          Below, Harry Darkstar and Sheriff Roberts arrive at the shore. Our
          young heroes have put a lot of distance between them and their
          pursuers. Out of the four obstacles they need to complete (in four
          rounds) to make it to the top before being overtaken by Harry and the
          Sheriff, the kids have completed three (in two rounds). And here we
          leave them, still fighting their way up the long, metal staircase to
          get to the lighthouse at the top of The Rock.
          <br />
          <br />
          END OF SESSION
          <br />
          <br />
          Kids on Bikes was GMed and written by{" "}
          <a
            href="https://www.roblecrone.com/"
            rel="noreferrer"
            target="_blank"
          >
            CobraRex
          </a>
        </div>
      ),
    },
  ];

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/kids-on-bikes/kids-on-bikes-page-background.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <Container>
      {/* <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin"
                    style={{position: 'float', float: 'right',width: '120px', height: '240px'}} marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=smokeraven-20&language=en_US&marketplace=amazon&region=US&placement=9189143701&asins=9189143701&linkId=d1384e0e6b9ef083fee35d7272a71f88&show_border=true&link_opens_in_new_window=true"></iframe> */}
      <Row>
        <Col>
          <div
            style={{
              backgroundColor: "transparent",
              fontFamily: "Carter One",
            }}
          >
            <main role="main" className="inner cover text-center">
              <img
                src={logo}
                style={{ maxWidth: "200px" }}
                alt="Kids on Bikes Turnaround Rock Campaign"
              />
            </main>
            <div style={pageHeader}>
              <h1 className="cover-heading-kids-on-bikes">
                Session 04: Turnaround Rock
              </h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title className="cardTitle-kids-on-bikes">
                The kids learn more about the secrets of The Rock, reconcile
                with family, and discover evil hidden in plain sight.
              </Card.Title>
              <div>
                <ContentContainer data={section1Data} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default KOBTurnaroundRockSession04;
