import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import ghostBird from "../../../../assets/myz/ark-ghost-bird.png";
import theBat from "../../../../assets/myz/doublebirds-bat-in-temple.jpg";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Bookkeeping",
    text: (
      <p>
        06-10-0001, 6am:
        <br />
        <br />
        <ul>
          <li>Mutation points and XP spend</li>
          <li>Pure Patrol: mutants and humans fight, only d6 water.</li>
          <li>
            Ark Reputation: sickness due to influx of new citizens. TWO DEATHS.
          </li>
          <li>Ark Threat: secret GM roll</li>
          <li>SmokeRaven's GROIN HIT is cured.</li>
        </ul>
        NOTE: Altair and Chi Tzu are housed at the Rust Castle along with Summer
        of Love bus.
        <br />
        <br />
        Critical Injury Roundup
        <ul>
          <li>
            GLADHAND has a DAMAGED KIDNEY. Ends morning of 06-13-0001. Point of
            damage for roll to Force, Move or Fight.
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: theBat,
      altText: `DoubleBird the Second's bat is mounted in the Temple of Endurance with the help of his cousin Ghost Bird.`,
      className: "imageLeft",
      imageCaption: "DoubleBird's Bat",
    },
  },
  {
    title: "The Redemption of Berke",
    text: (
      <p>
        At the submarine, a random roll is made with a 1 result, which is bad.
        <br />
        Elli, Chromicus, and Bayazid wake up with six scrap pirates pointing bow
        and arrow at them. The party decides to go with the pirates peacefully
        as hostages.
        <br />
        <br />
        The rest of the party is back at the Ark along with Berke, Ford the
        scrap pirate, and Rust Castle mechie Captain Elwith. Hulagu sits down to
        gang business and finds the last Pure Plant patrol came back with a
        letter from Luttrell (see below).
        <br />
        SmokeRaven sits down to have a drink with Fang the Mountain, a fellow
        stalker (see below).
        <br />
        <br />
        <h4>Council Meeting</h4>
        The People of the Sunshine Plaza gather in the Gathering Place to hear
        from their leaders.
        <ul>
          <li>
            Boss Krao introduces Ghost Bird and Ghost Bird speaks to The People
            of his vision that Double Bird died and Ghost Bird came to learn
            what happened and to chronicle the Bird clas. "I am not of this
            world. I am a spirit. Peel back the illusion of self."
          </li>
          <li>
            Hulagu talked of finding the RUST CASTLE. He spoke about the Dead
            Blue Sea. He introduces Elwith. He has Berke brought out. "In
            fitting with our new way of politicking, after his promised time to
            redeem himself, we will vote on his fate."
          </li>
          <li>
            Fatima gave an impassioned entreaty for Sufferage as a project (see
            below).
          </li>
        </ul>
        After the Council Meeting, Smoke Raven is interrogating Berke. Beta the
        Sour comes by to talk to Berke. She is mad Huluga will not let her speak
        to Berke alone and Berke tells Beta, "you must respect Hulagu."
        <br />
        We did the triple roll for projects and the IL Gang got Sufferage to 11
        of the 12 points needed. Smoke Raven escorted the shackled Berke around
        the Ark. Berke's charisma, even in chains as a traitor, gets the IL Gang
        the one point they need and they finish the Sufferage project and gain 5
        DEV points of Technology level.
        <br />
        Ghost Bird and Krao unveil the plaque mounted in the Temple of Endurance
        showcasing Double Bird the Second's spiked bat and words about no more
        slavery.
        <br />
        Berke gives information about the <b>Oilfield of the Ancients</b>.
        <ul>
          <li>
            Oilfield of the Ancients is located in sector H17 of Dead Blue Sea.
          </li>
          <li>
            There are four oil rigs. Three are operational. Jet skis and
            motorboats patrol area. Impossible to approach undecteced unless
            underwater.
          </li>
          <li>
            They extract oil. They are operatives of an enclave power called
            Noatun. I think they trade with Scrap Oracle Cassandra, but their
            Noatun is a different settlement than the Noatun Estates..
          </li>
          <li>Xerxes pushes them to produce oil with a maniacal dedication.</li>
          <li>
            Communications with Noatun home base have been interupted so Berke
            was sent out with Ossian to get information.
          </li>
          <li>
            He heard them talk as if Noatun is a settlement underwater. He heard
            their motto a lot: “Strength through Unity”.
          </li>
          <li>
            They took me on as a kind of consultant. I was initially captured by
            Capt. Ossian but made myself useful when there was a run-in with
            Xerses patrol.
          </li>
          <li>
            I was director of outside communications. They are about 15 clones
            and 15 medical, technician and diver staff that are human. They are
            timid and do not like boats; consider mutants savages.
          </li>
        </ul>
        <br />
        <br />
        Finally, the gang heads back to the submarine to meet Captain Ossian.
        They discover Elli, Chromicus, and Bayazid are missing. Ford the scrap
        pirate points out a note stuck next to the wheel on the Steelcrest. It
        describes that the pirates are taking their hostages to Cage Island.
        <br />
        Elwith fires up the Steelcrest and the IL Gang gives chase. The
        Steelcrest is very fast and The Wrecker is ponderous. With some luck and
        a terrible storm, the Steelcrest finds The Wrecker and the IL Gang
        boards The car ferry. A terrible battle ensues with these highlights:
        <ul>
          <li>
            The scrap pirates initally have the benefit of cover from cars and
            Ossian fires on Hulagu with a scrap cannon several times. He uses
            barkskin which locks down. And during the combat, he has a mutation
            misfire and gains Extreme Reflexes and replaces acid spit with it.
            Due to all the new mutations,
          </li>
          <li>
            The scrap pirates are numerous and take out several IL Gang minions
            quickly.
          </li>
          <li>
            Chromicus and Gladhand wake up in separate rooms bound to chairs.
            They begin FORCE rolls to try and get free.
          </li>
          <li>Ghost Bird breaks with a CONCUSSION.</li>
          <li>Elli the Whopper from RUST CASTLE breaks with SPRAINED WRIST.</li>
          <li>
            Capt Ossian is broken. One of his pirates drags him down the stairs
            toward the back of the boat and then goes toward a hatch leading
            below deck.
          </li>
          <li>Bayazid and Chromicus break out</li>
          <li>
            Smoke Raven is broked with a CRUSHED FACE with one minute to live!!
          </li>
          <li>
            Hulagu has frog-lept and grabbed Ossian who is broken with ARTERIAL
            BLEEDING and dying too.
          </li>
          <li>
            Hulagu shouts out that he has Ossian and Ossian also shouts out for
            the pirates to stand down.
          </li>
          <li>
            The below deck pirate pops back up on deck and shouts, "I did plan B
            captain, it's gonna blow" and starts running. The scuttle bomb below
            deck blows up and miraculously does no damage to people but The
            Wrecker starts to sink.
          </li>
          <li>
            Theres a flurry of activity as Elwith is gunning the Steelcrest and
            shouting through the storm that The Wrecker is going down and they
            have to leave.
          </li>
          <li>
            Chromicus flys over and heals the Lethality on SmokeRaven with an
            amazing roll considering his skill level and no gear to help.
          </li>
          <li>
            Everyone, including Elli is healed enough to move and get on board
            the Steel crest.
          </li>
          <li>
            The scrap pirates were adding their bonus to HEAL checks once the
            Captain commanded them to stand down.
          </li>
          <li>SmokeRaven and Gladhand very nearly died; it was a close one.</li>
        </ul>
        <h4>Critical Injury Roundup</h4>
        <ul>
          <li>
            SMOKE RAVEN has INTERNAL BLEEDING. He had 1 minute to live but made
            it.
            <br /> Effect: You suffer one point of damage for every roll you
            make to Force, Move or Fight.
            <br />
            <u>Healing Time</u>: 6 days (heal not rolled yet)
          </li>
          <li>
            GHOST BIRD has CONCUSSION. Ends morning of 06-14-0001.
            <br />
            Effect: −1 to Scout and Comprehend.
          </li>
          <li>
            ELLI has SPRAINED WRIST. Ends morning of 06-14-0001.
            <br />
            Effect: −1 to Shoot and Fight.
          </li>

          <li>
            GLADHAND has a CRUSHED FACE. Ends ???.
            <br />
            Effect: -2 to Manipulate.
            <br />
            <u>Healing Time</u>: 12 days.
            <br />
            GLADHAND has a DAMAGED KIDNEY. Ends morning of 06-13-0001.
            <br />
            Effect: Point of damage for roll to Force, Move or Fight.
          </li>
        </ul>
        <h5>Letter from Lutrell</h5>
        <small>
          To Hulagu and the IL Gang,
          <br />
          Well, I guess you probably heard by now, but with some problems we've
          had on the patrol and various obstructions to clarity given to the
          minds of my people by the events of fortune, perception, and whatnot;
          well, it's been said we should have only humans on the Pure Plant
          patrols from the Plaza.
          <br />
          The very word of the day suggests y'all are doing quite well and
          attracting settlers, mutant and human alike. My people are kicking up
          a fuss and I have to make motions toward appeasement.
          <br />
          I feel you may owe me one as my sister Big Barbro stopped by. I reckon
          you know her if she hasn't lied, and while she may have an
          imagination, I have not know her to traffic in untruth. I guess you
          have my mother Hagga with you at the plaza under the moniker Sylvia.
          <br />
          I don't blame her for wanting a new name, but I do blame her for
          bringing that baby up here knowing it could ruin me. The people of the
          pure plant, true to their name, do not tolerate more than they have to
          from this harsh world we live in. I guess you know about my family's
          special relationship with survival and that we will do, and eat, what
          we must to survive. It's a family trait I've had to hide from the good
          folk at the plant as you might have guessed. Cannibalism is something
          they expect from mutants but not from their human kin. It's not right
          I know, but there we are.
          <br />
          Well, the strain of having a baby with my sister Lillemor brought out
          . . . well, you can see it's just more noise without a profit. And so
          I made my mother leave and the baby with her and branded her Jezebel.
          It was theater for my people and a chastisement for her stepping
          outside her bounds. After casting her out I did not know, or care to
          know, her face. I figured she would wander back to the Homestead. But
          by some circuitousness born of the absurd she now resides with you
          all.
          <br />
          That's fine, but I hold your revelation of my situation to my family
          as something that balances any dislike you might have of a human-only
          patrol, and that from this understanding we can continue forward with
          our arrangement in peace.
          <br />
          Yours truly, Lutrell . . . please burn this letter after reading it
          Hulagu. You are a mutant of your word as I have seen.
        </small>
        <hr />
        <h5>Fang the Mountain Speaks</h5>
        <small>
          Can we talk Smoke Raven? Stalker to stalker? Here, let me fill your
          cup. Like the time you stole my knife. HAHAHA! I know. It was a fair
          deal. A mutant that drinks must drink to the bottom of the cup!
          <br />
          But we have a problem my friend. Just as we are trained to know danger
          and reward in the Zone, I have seen the signs here at home. Right here
          in the Sunrise Plaza; dark clouds. I understand the Wanderers are good
          people. They have built projects and they have bled here. But they are
          people . . . not mutants . . . not of The People!
          <br />
          I fear because there are almost as many humans as us now. There are
          rumors too that the Pure Plant will not let mutants be part of the
          patrol any longer. Maybe they are forming a partnership with the
          humans among us to take over? Maybe they simply hate us.
          <br />
          After our Civil War we still struggle to heal and grow strong and
          numerous. I do not think filling the loss of our People with humans is
          the answer. I bear them no ill will, but I fear some of The People
          feel threatened. They wonder if this new future means less for us and
          more for them. Ahh, let's drink more and talk less.
        </small>
        <hr />
        <h5>Fatima's Entreaty</h5>
        <small>
          I am honored to speak to The People of the Plaza. As the newest boss,
          I want you to know, everything I do and say is for you, The People.
          The People that now includes me and my people, as one people. We are
          grateful.
          <br />
          But, I will not let gratitude be an excuse to settle into mere
          thankfulness. We have all bled and died for the Plaza now. We have
          built projects and we share a common dream.
          <br />
          But there is grumbling about human versus mutant. The Pure Plant wants
          only human patrols now. I disagree with Lutrell, and I disagree that
          human and mutant are incompatible.
          <br />
          I carry the child of Gladhand. A child that will mix human and mutant
          blood. A child that represents the future of The People.
          <br />
          We must show Lutrell, and show ourselves, that we can live together in
          harmony; that no mutant is beholden to humans and no humans are under
          the coercion of mutants. We need stability for ourselves, and for the
          new citizens coming to the Plaza as part of the dream! We need one
          voice!
          <br />
          There are those who would say I prefer humans over mutants; this is
          not true. I remind us all of the sacrifice and blood spilled, but also
          of the new life I bear.
          <br />
          There are those who say Boss Krao is lost, haunted by the loss of The
          Elder and pining only for safety. But her actions show that she knows
          the path The People must take, and she works tirelessly to build our
          story for generations to come.
          <br />
          There are those who would say Boss Hulagu is never here and cannot
          lead the Ark. But I say, much of what we have is due to his tireless
          efforts and willingness to risk his life in the Zone.
          <br />
          How can we have one voice? A voice to gather up our collective
          concerns, our dream, and articulate the path forward like a stalker
          guiding us safely to the next zone?
          <br />I propose Sufferage as an Ark project. With sufferage we, and
          all who know of us, will know that The People and their Bosses are
          never the product of intimidation, politics, or chance. It is always
          the will of The People. Sufferage for the Plaza!!
        </small>
      </p>
    ),
    image: {
      imagePath: ghostBird,
      altText: `Origin:  Believes he is a spirit of from another world. Originates from the Fire (Elta) Cult, but has wandered the Zone for years. He chronicles the manifestations of the spiritual world and inspires others to abandon self to embrace the void. He is cousin to the Double Bird twins, and seeks to chronicle the Bird tribe.`,
      className: "imageLeft",
      imageCaption: "Ghost Bird",
    },
  },

  {
    title: "Wrapup",
    text: (
      <p>
        Using our modified XP rules, Hulagu won the nomination for extra xp
        thanks to his pushing HEAL roll on Ossian then shouting out to the scrap
        pirates and changing the course of the battle. Honerable mention to
        Chromicus healing Smoke Raven who was bleeding out on the deck of The
        Wrecker. So, 4 XP for Hulagu and everyone else got 3 XP.
        <br />
        <br />
        Next session will start on in-game in the early night hours of
        06-12-2021 with everyone on the Steelcrest.
        <br />
        <br />
        NEXT SESSION WILL START WITH:
        <ul>
          <li>Mutation points and XP spend</li>
          <li>What about the Scrap Pirates on the Steelcrest and wounds.</li>
          <li>Healing Time rolls for Gladhand and Smoke Raven.</li>
        </ul>
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        .
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
];

const MYZAnchortownSession42 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 42: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">The Redemption of Berke</p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                {/*<Card.Title style={cardTitle}>Shootout at Burger Jim's, 03-27-0001 - 03-27-0001</Card.Title>*/}
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession42;
