import React, { lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import NavBar from "./navbar/navbar";
import HomePage from "./HomePage";
import IFrameComponent from "./IFrameComponent";
import GameRundown from "./GameRundown";
import FLBestiaryTokens from "./FLBestiaryTokens";
import FLFindsPreciousCarried from "./FLFindsPreciousCarried";
import FLFindsPreciousLair from "./FLFindsPreciousLair";
import FLFindsSimpleCarried from "./FLFindsSimpleCarried";
import FLBestiaryTokensBitterReach from "./FLBestiaryTokensBitterReach";
import RandomEncounters from "./RandomEncounters";
import HexTraveller from "./HexTraveller";
import CampaignMaps from "./campaignMaps";
import BITDClassesCore from "./bitd/BITDClassesCore";
import BITDOverview from "./bitd/BITDOverview";
import BRClasses from "./blade-runner/BRClasses";
import BRRules from "./blade-runner/BRRules";
import BROverview from "./blade-runner/BROverview";

import FBLSession13 from "./forbidden-lands/FBLSession13";
import FBLSession12 from "./forbidden-lands/FBLSession12";
import FBLSession11 from "./forbidden-lands/FBLSession11";
import FBLSession10 from "./forbidden-lands/FBLSession10";
import FBLSession09 from "./forbidden-lands/FBLSession09";
import FBLSession08 from "./forbidden-lands/FBLSession08";
import FBLSession07 from "./forbidden-lands/FBLSession07";
import FBLSession06 from "./forbidden-lands/FBLSession06";
import FBLSession05 from "./forbidden-lands/FBLSession05";
import FBLSession04 from "./forbidden-lands/FBLSession04";
import FBLSession03 from "./forbidden-lands/FBLSession03";
import FBLSession02 from "./forbidden-lands/FBLSession02";
import FBLSession01 from "./forbidden-lands/FBLSession01";
import FBLSession00 from "./forbidden-lands/FBLSession00";
import FBLWolfkin from "./forbidden-lands/FBLWolfkin";
import FBLGoblin from "./forbidden-lands/FBLGoblin";
import FBLGambling from "./forbidden-lands/FBLGambling";
import FBLContests from "./forbidden-lands/FBLContests";

import MYZSession01 from "./myz/sessions/MYZAnchortownSession01";
import MYZSession02 from "./myz/sessions/MYZAnchortownSession02";
import MYZSession03 from "./myz/sessions/MYZAnchortownSession03";
import MYZSession04 from "./myz/sessions/MYZAnchortownSession04";
import MYZSession05 from "./myz/sessions/MYZAnchortownSession05";
import MYZSession06 from "./myz/sessions/MYZAnchortownSession06";
import MYZSession07 from "./myz/sessions/MYZAnchortownSession07";
import MYZSession08 from "./myz/sessions/MYZAnchortownSession08";
import MYZSession09 from "./myz/sessions/MYZAnchortownSession09";
import MYZSession10 from "./myz/sessions/MYZAnchortownSession10";
import MYZSession11 from "./myz/sessions/MYZAnchortownSession11";
import MYZSession12 from "./myz/sessions/MYZAnchortownSession12";
import MYZSession13 from "./myz/sessions/MYZAnchortownSession13";
import MYZSession14 from "./myz/sessions/MYZAnchortownSession14";
import MYZSession15 from "./myz/sessions/MYZAnchortownSession15";
import MYZSession16 from "./myz/sessions/MYZAnchortownSession16";
import MYZSession17 from "./myz/sessions/MYZAnchortownSession17";
import MYZAnchortownSession18And19And20 from "./myz/sessions/MYZAnchortownSession18And19And20";
import MYZAnchortownSession21 from "./myz/sessions/MYZAnchortownSession21";
import MYZAnchortownSession22 from "./myz/sessions/MYZAnchortownSession22";
import MYZAnchortownSession23 from "./myz/sessions/MYZAnchortownSession23";
import MYZAnchortownSession24 from "./myz/sessions/MYZAnchortownSession24";
import MYZAnchortownSession25 from "./myz/sessions/MYZAnchortownSession25";
import MYZAnchortownSession26 from "./myz/sessions/MYZAnchortownSession26";
import MYZAnchortownSession27 from "./myz/sessions/MYZAnchortownSession27";
import MYZAnchortownSession28 from "./myz/sessions/MYZAnchortownSession28";
import MYZAnchortownSession29 from "./myz/sessions/MYZAnchortownSession29";
import MYZAnchortownSession30 from "./myz/sessions/MYZAnchortownSession30";
import MYZAnchortownSession31 from "./myz/sessions/MYZAnchortownSession31";
import MYZAnchortownSession32 from "./myz/sessions/MYZAnchortownSession32";
import MYZAnchortownSession33 from "./myz/sessions/MYZAnchortownSession33";
import MYZAnchortownSession34 from "./myz/sessions/MYZAnchortownSession34";
import MYZAnchortownSession35 from "./myz/sessions/MYZAnchortownSession35";
import MYZAnchortownSession36 from "./myz/sessions/MYZAnchortownSession36";
import MYZAnchortownSession37 from "./myz/sessions/MYZAnchortownSession37";
import MYZAnchortownSession38 from "./myz/sessions/MYZAnchortownSession38";
import MYZAnchortownSession39 from "./myz/sessions/MYZAnchortownSession39";
import MYZAnchortownSession40 from "./myz/sessions/MYZAnchortownSession40";
import MYZAnchortownSession41 from "./myz/sessions/MYZAnchortownSession41";
import MYZAnchortownSession42 from "./myz/sessions/MYZAnchortownSession42";
import MYZAnchortownSession43And44 from "./myz/sessions/MYZAnchortownSession43And44";
import MYZAnchortownSession45 from "./myz/sessions/MYZAnchortownSession45";
import MYZAnchortownSession46 from "./myz/sessions/MYZAnchortownSession46";
import MYZAnchortownSession47 from "./myz/sessions/MYZAnchortownSession47";
import MYZAnchortownSession48 from "./myz/sessions/MYZAnchortownSession48";
import MYZAnchortownSession49 from "./myz/sessions/MYZAnchortownSession49";
import MYZAnchortownSession50 from "./myz/sessions/MYZAnchortownSession50";
import MYZAnchortownSession51 from "./myz/sessions/MYZAnchortownSession51";
import MYZAnchortownSession52 from "./myz/sessions/MYZAnchortownSession52";
import MYZAnchortownSession53 from "./myz/sessions/MYZAnchortownSession53";
import MYZAnchortownSession54 from "./myz/sessions/MYZAnchortownSession54";
import MYZAnchortownSession55 from "./myz/sessions/MYZAnchortownSession55";
import MYZAnchortownSession56And57 from "./myz/sessions/MYZAnchortownSession56And57";
import MYZAnchortownSession58And60 from "./myz/sessions/MYZAnchortownSession58And60";
import MYZAnchortownSession61And62 from "./myz/sessions/MYZAnchortownSession61And62";
import MYZAnchortownSession63 from "./myz/sessions/MYZAnchortownSession63";
import MYZAnchortownSession64And65 from "./myz/sessions/MYZAnchortownSession64And65";
import MYZMap from "./myz/MYZMap";
import MYZMapGridExploration from "./myz/MYZMapGridExploration";
import MYZCoverGallery from "./myz/MYZCoverGallery";
import MYZScenes from "./myz/MYZScenes";
import MYZHandwaveTravel from "./myz/MYZHandwaveTravel";
import MYZGraveyard from "./myz/MYZAnchortownGraveyard";
import MYZPopulationNotableEvents from "./myz/MYZAnchortownPopulationNotableEvents";
import MYZErrata from "./myz/MYZErrata";
import MYZSectorGenerator from "./myz/MYZSectorGenerator";

import MBMalumMortisSession01 from "./morkborg/MBMalumMortisSession01";
import MBMalumMortisSession02 from "./morkborg/MBMalumMortisSession02";
import MBMalumMortisSession03 from "./morkborg/MBMalumMortisSession03";
import MBMalumMortisSession04 from "./morkborg/MBMalumMortisSession04";
import MBMalumMortisSession05 from "./morkborg/MBMalumMortisSession05";
import MBMalumMortisSession06 from "./morkborg/MBMalumMortisSession06";
import MBMalumMortisSession07 from "./morkborg/MBMalumMortisSession07";
import MBMalumMortisSession08 from "./morkborg/MBMalumMortisSession08";
import MBMalumMortisSession09 from "./morkborg/MBMalumMortisSession09";
import MBMalumMortisSession10 from "./morkborg/MBMalumMortisSession10";
import MBMalumMortisSession11 from "./morkborg/MBMalumMortisSession11";
import MBGraveyard from "./morkborg/MBMalumMortisGraveyard";
import MBExquisiteCorpsesSession00 from "./morkborg/MBExquisiteCorpsesSession00";
import MBExquisiteCorpsesSession01 from "./morkborg/MBExquisiteCorpsesSession01";
import MBClassesCore from "./morkborg/MBClassesCore";
import MBClassesThirdParty from "./morkborg/MBClassesThirdParty";
import MBClassesGallery from "./morkborg/MBClassesGallery";

import KidsOnBikesRoutes from "./routing/kids-on-bikes-routes";
import ForbiddenLandsRoutes from "./routing/forbidden-lands-routes";

class SmokeRavenNavbar extends React.Component {
  render() {
    // const kidsOnBikesRoutes = KidsOnBikesRoutes();
    // const forbiddenLandsRoutes = ForbiddenLandsRoutes();

    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <NavBar />
            <Router>
              <br />
              <Routes>
                <Route path="/" element={<HomePage />} />
                {KidsOnBikesRoutes()}
                {ForbiddenLandsRoutes()}
                <Route
                  path="/scrap-generator"
                  element={
                    <IFrameComponent
                      targetUrl={
                        "https://www.smokeraven.com/static/html/myz-scrap.html"
                      }
                      creditName="jjpeabody"
                      creditUrl={"https://www.deviantart.com/jjpeabody/gallery"}
                    />
                  }
                />
                <Route
                  path="/mutant-generator"
                  element={
                    <IFrameComponent
                      targetUrl={
                        "https://www.smokeraven.com/static/html/myz-mutant.html"
                      }
                      creditName=""
                      creditUrl={
                        "https://www.deviantart.com/everlite/art/Ruincityscape-001-2k-541701995"
                      }
                    />
                  }
                />
                <Route
                  path="/mutant-year-zero-scrap-rules"
                  element={
                    <IFrameComponent targetUrl="https://www.smokeraven.com/static/html/mutant-year-zero-scrap-rules.html" />
                  }
                />
                <Route
                  path="/mutant-year-zero-civil-war-rules"
                  element={
                    <IFrameComponent targetUrl="https://www.smokeraven.com/static/html/mutant-year-zero-civil-war-rules.htm" />
                  }
                />
                <Route
                  path="/mutant-year-zero-beast-training"
                  element={
                    <IFrameComponent targetUrl="https://www.smokeraven.com/static/html/mutant-year-zero-beast-training.html" />
                  }
                />
                <Route
                  path="/mutant-year-zero-rot-critical-injuries"
                  element={
                    <IFrameComponent targetUrl="https://www.smokeraven.com/static/html/CriticalInjuriesRot.html" />
                  }
                />
                <Route
                  path="/mutant-year-zero-anchortown-population-notable-events"
                  element={<MYZPopulationNotableEvents />}
                />
                <Route
                  path="/mutant-year-zero-scenes"
                  element={<MYZScenes />}
                />
                <Route
                  path="/mutant-year-zero-handwave-travel-rules"
                  element={<MYZHandwaveTravel />}
                />
                <Route
                  path="/mutant-year-zero-errata"
                  element={<MYZErrata />}
                />
                <Route
                  path="/mutant-year-zero-sector-generator"
                  element={<MYZSectorGenerator />}
                />
                <Route
                  path="/mutant-year-zero-session-01-anchortown"
                  element={<MYZSession01 />}
                />
                <Route
                  path="/mutant-year-zero-session-02-anchortown"
                  element={<MYZSession02 />}
                />
                <Route
                  path="/mutant-year-zero-session-03-anchortown"
                  element={<MYZSession03 />}
                />
                <Route
                  path="/mutant-year-zero-session-04-anchortown"
                  element={<MYZSession04 />}
                />
                <Route
                  path="/mutant-year-zero-session-05-anchortown"
                  element={<MYZSession05 />}
                />
                <Route
                  path="/mutant-year-zero-session-06-anchortown"
                  element={<MYZSession06 />}
                />
                <Route
                  path="/mutant-year-zero-session-07-anchortown"
                  element={<MYZSession07 />}
                />
                <Route
                  path="/mutant-year-zero-session-08-anchortown"
                  element={<MYZSession08 />}
                />
                <Route
                  path="/mutant-year-zero-session-09-anchortown"
                  element={<MYZSession09 />}
                />
                <Route
                  path="/mutant-year-zero-session-10-anchortown"
                  element={<MYZSession10 />}
                />
                <Route
                  path="/mutant-year-zero-session-11-anchortown"
                  element={<MYZSession11 />}
                />
                <Route
                  path="/mutant-year-zero-session-12-anchortown"
                  element={<MYZSession12 />}
                />
                <Route
                  path="/mutant-year-zero-session-13-anchortown"
                  element={<MYZSession13 />}
                />
                <Route
                  path="/mutant-year-zero-session-14-anchortown"
                  element={<MYZSession14 />}
                />
                <Route
                  path="/mutant-year-zero-session-15-anchortown"
                  element={<MYZSession15 />}
                />
                <Route
                  path="/mutant-year-zero-session-16-anchortown"
                  element={<MYZSession16 />}
                />
                <Route
                  path="/mutant-year-zero-session-17-anchortown"
                  element={<MYZSession17 />}
                />
                <Route
                  path="/mutant-year-zero-session-18-19-20-anchortown"
                  element={<MYZAnchortownSession18And19And20 />}
                />
                <Route
                  path="/mutant-year-zero-session-21-anchortown"
                  element={<MYZAnchortownSession21 />}
                />
                <Route
                  path="/mutant-year-zero-session-22-anchortown"
                  element={<MYZAnchortownSession22 />}
                />
                <Route
                  path="/mutant-year-zero-session-23-anchortown"
                  element={<MYZAnchortownSession23 />}
                />
                <Route
                  path="/mutant-year-zero-session-24-anchortown"
                  element={<MYZAnchortownSession24 />}
                />
                <Route
                  path="/mutant-year-zero-session-25-anchortown"
                  element={<MYZAnchortownSession25 />}
                />
                <Route
                  path="/mutant-year-zero-session-26-anchortown"
                  element={<MYZAnchortownSession26 />}
                />
                <Route
                  path="/mutant-year-zero-session-27-anchortown"
                  element={<MYZAnchortownSession27 />}
                />
                <Route
                  path="/mutant-year-zero-session-28-anchortown"
                  element={<MYZAnchortownSession28 />}
                />
                <Route
                  path="/mutant-year-zero-session-29-anchortown"
                  element={<MYZAnchortownSession29 />}
                />
                <Route
                  path="/mutant-year-zero-session-30-anchortown"
                  element={<MYZAnchortownSession30 />}
                />
                <Route
                  path="/mutant-year-zero-session-31-anchortown"
                  element={<MYZAnchortownSession31 />}
                />
                <Route
                  path="/mutant-year-zero-session-32-anchortown"
                  element={<MYZAnchortownSession32 />}
                />
                <Route
                  path="/mutant-year-zero-session-33-anchortown"
                  element={<MYZAnchortownSession33 />}
                />
                <Route
                  path="/mutant-year-zero-session-34-anchortown"
                  element={<MYZAnchortownSession34 />}
                />
                <Route
                  path="/mutant-year-zero-session-35-anchortown"
                  element={<MYZAnchortownSession35 />}
                />
                <Route
                  path="/mutant-year-zero-session-36-anchortown"
                  element={<MYZAnchortownSession36 />}
                />
                <Route
                  path="/mutant-year-zero-session-37-anchortown"
                  element={<MYZAnchortownSession37 />}
                />
                <Route
                  path="/mutant-year-zero-session-38-anchortown"
                  element={<MYZAnchortownSession38 />}
                />
                <Route
                  path="/mutant-year-zero-session-39-anchortown"
                  element={<MYZAnchortownSession39 />}
                />
                <Route
                  path="/mutant-year-zero-session-40-anchortown"
                  element={<MYZAnchortownSession40 />}
                />
                <Route
                  path="/mutant-year-zero-session-41-anchortown"
                  element={<MYZAnchortownSession41 />}
                />
                <Route
                  path="/mutant-year-zero-session-42-anchortown"
                  element={<MYZAnchortownSession42 />}
                />
                <Route
                  path="/mutant-year-zero-session-43_44-anchortown"
                  element={<MYZAnchortownSession43And44 />}
                />
                <Route
                  path="/mutant-year-zero-session-45-anchortown"
                  element={<MYZAnchortownSession45 />}
                />
                <Route
                  path="/mutant-year-zero-session-46-anchortown"
                  element={<MYZAnchortownSession46 />}
                />
                <Route
                  path="/mutant-year-zero-session-47-anchortown"
                  element={<MYZAnchortownSession47 />}
                />
                <Route
                  path="/mutant-year-zero-session-48-anchortown"
                  element={<MYZAnchortownSession48 />}
                />
                <Route
                  path="/mutant-year-zero-session-49-anchortown"
                  element={<MYZAnchortownSession49 />}
                />
                <Route
                  path="/mutant-year-zero-session-50-anchortown"
                  element={<MYZAnchortownSession50 />}
                />
                <Route
                  path="/mutant-year-zero-session-51-anchortown"
                  element={<MYZAnchortownSession51 />}
                />
                <Route
                  path="/mutant-year-zero-session-52-anchortown"
                  element={<MYZAnchortownSession52 />}
                />
                <Route
                  path="/mutant-year-zero-session-53-anchortown"
                  element={<MYZAnchortownSession53 />}
                />
                <Route
                  path="/mutant-year-zero-session-54-anchortown"
                  element={<MYZAnchortownSession54 />}
                />
                <Route
                  path="/mutant-year-zero-session-55-anchortown"
                  element={<MYZAnchortownSession55 />}
                />
                <Route
                  path="/mutant-year-zero-session-56-57-anchortown"
                  element={<MYZAnchortownSession56And57 />}
                />
                <Route
                  path="/mutant-year-zero-session-58-60-anchortown"
                  element={<MYZAnchortownSession58And60 />}
                />
                <Route
                  path="/mutant-year-zero-session-61-62-anchortown"
                  element={<MYZAnchortownSession61And62 />}
                />
                <Route
                  path="/mutant-year-zero-session-63-anchortown"
                  element={<MYZAnchortownSession63 />}
                />
                <Route
                  path="/mutant-year-zero-session-64_65-anchortown"
                  element={<MYZAnchortownSession64And65 />}
                />
                <Route
                  path="/mutant-year-zero-anchortown-graveyard"
                  element={<MYZGraveyard />}
                />
                <Route
                  path="/mutant-year-zero-anchortown-map"
                  element={<MYZMap />}
                />
                <Route
                  path="/mutant-year-zero-anchortown-map-grid-exploration"
                  element={<MYZMapGridExploration />}
                />
                <Route
                  path="/mutant-year-zero-cover-gallery"
                  element={<MYZCoverGallery />}
                />
                <Route
                  path="/mork-borg-session-01-malum-mortis"
                  element={<MBMalumMortisSession01 />}
                />
                <Route
                  path="/mork-borg-session-02-malum-mortis"
                  element={<MBMalumMortisSession02 />}
                />
                <Route
                  path="/mork-borg-session-03-malum-mortis"
                  element={<MBMalumMortisSession03 />}
                />
                <Route
                  path="/mork-borg-session-04-malum-mortis"
                  element={<MBMalumMortisSession04 />}
                />
                <Route
                  path="/mork-borg-session-05-malum-mortis"
                  element={<MBMalumMortisSession05 />}
                />
                <Route
                  path="/mork-borg-session-06-malum-mortis"
                  element={<MBMalumMortisSession06 />}
                />
                <Route
                  path="/mork-borg-session-07-malum-mortis"
                  element={<MBMalumMortisSession07 />}
                />
                <Route
                  path="/mork-borg-session-08-malum-mortis"
                  element={<MBMalumMortisSession08 />}
                />
                <Route
                  path="/mork-borg-session-09-malum-mortis"
                  element={<MBMalumMortisSession09 />}
                />
                <Route
                  path="/mork-borg-session-10-malum-mortis"
                  element={<MBMalumMortisSession10 />}
                />
                <Route
                  path="/mork-borg-session-11-malum-mortis"
                  element={<MBMalumMortisSession11 />}
                />
                <Route
                  path="/mork-borg-malum-mortis-graveyard"
                  element={<MBGraveyard />}
                />
                <Route
                  path="/mork-borg-session-01-exquisite-corpses"
                  element={<MBExquisiteCorpsesSession01 />}
                />
                <Route
                  path="/mork-borg-session-00-exquisite-corpses"
                  element={<MBExquisiteCorpsesSession00 />}
                />
                <Route
                  path="/mork-borg-classes-core"
                  element={<MBClassesCore />}
                />
                <Route
                  path="/mork-borg-classes-third-party"
                  element={<MBClassesThirdParty />}
                />
                <Route
                  path="/mork-borg-classes-gallery"
                  element={<MBClassesGallery />}
                />
                <Route path="/game-rundown" element={<GameRundown />} />
                <Route
                  path="/blades-in-the-dark-classes-core"
                  element={<BITDClassesCore />}
                />
                <Route
                  path="/blades-in-the-dark-overview"
                  element={<BITDOverview />}
                />
                <Route
                  path="/forbidden-lands-bestiary-roll20-tokens"
                  element={<FLBestiaryTokens />}
                />
                <Route
                  path="/forbidden-lands-finds-precious-carried"
                  element={<FLFindsPreciousCarried />}
                />
                <Route
                  path="/forbidden-lands-finds-precious-lair"
                  element={<FLFindsPreciousLair />}
                />
                <Route
                  path="/forbidden-lands-finds-simple-carried"
                  element={<FLFindsSimpleCarried />}
                />
                <Route
                  path="/forbidden-lands-bitter-reach-bestiary-roll20-tokens"
                  element={<FLBestiaryTokensBitterReach />}
                />
                <Route
                  path="/forbidden-lands-legend-generator"
                  element={
                    <IFrameComponent
                      targetUrl={
                        "https://www.smokeraven.com/static/html/forbidden-lands-legend-generator.html"
                      }
                    />
                  }
                />
                <Route
                  path="/forbidden-lands-magic-mishaps"
                  element={
                    <IFrameComponent
                      targetUrl={
                        "https://www.smokeraven.com/static/html/forbidden-lands-magic-mishaps.html"
                      }
                    />
                  }
                />
                <Route
                  path="/forbidden-lands-lead-the-way-mishaps"
                  element={
                    <IFrameComponent
                      targetUrl={
                        "https://www.smokeraven.com/static/html/forbidden-lands-lead-the-way-mishaps.html"
                      }
                    />
                  }
                />
                <Route
                  path="/forbidden-lands-foraging-mishaps"
                  element={
                    <IFrameComponent
                      targetUrl={
                        "https://www.smokeraven.com/static/html/forbidden-lands-foraging-mishaps.html"
                      }
                    />
                  }
                />
                <Route
                  path="/forbidden-lands-hunting-mishaps"
                  element={
                    <IFrameComponent
                      targetUrl={
                        "https://www.smokeraven.com/static/html/forbidden-lands-hunting-mishaps.html"
                      }
                    />
                  }
                />
                <Route
                  path="/forbidden-lands-fishing-mishaps"
                  element={
                    <IFrameComponent
                      targetUrl={
                        "https://www.smokeraven.com/static/html/forbidden-lands-fishing-mishaps.html"
                      }
                    />
                  }
                />
                <Route
                  path="/forbidden-lands-making-camp-mishaps"
                  element={
                    <IFrameComponent
                      targetUrl={
                        "https://www.smokeraven.com/static/html/forbidden-lands-making-camp-mishaps.html"
                      }
                    />
                  }
                />
                <Route
                  path="/forbidden-lands-sea-travel-mishaps"
                  element={
                    <IFrameComponent
                      targetUrl={
                        "https://www.smokeraven.com/static/html/forbidden-lands-sea-travel-mishaps.html"
                      }
                    />
                  }
                />
                <Route
                  path="/forbidden-lands-stronghold-generator"
                  element={
                    <IFrameComponent
                      targetUrl={
                        "https://www.smokeraven.com/static/html/forbidden-lands-stronghold-generator.html"
                      }
                    />
                  }
                />
                <Route
                  path="/forbidden-lands-critical-injuries"
                  element={
                    <IFrameComponent
                      targetUrl={
                        "https://www.smokeraven.com/static/html/forbidden-lands-critical-injuries.html"
                      }
                    />
                  }
                />
                <Route
                  path="/forbidden-lands-stronghold-events"
                  element={
                    <IFrameComponent
                      targetUrl={
                        "https://www.smokeraven.com/static/html/forbidden-lands-stronghold-events.html"
                      }
                    />
                  }
                />
                <Route
                  path="/ark-projects-summary"
                  element={
                    <IFrameComponent
                      targetUrl={
                        "https://docs.google.com/spreadsheets/d/1I_XrbBH7LMGt7QinlHomINAIM2es-vochY3378yUYXE/"
                      }
                    />
                  }
                />
                <Route
                  path="/forbidden-lands-demon-generator"
                  element={
                    <IFrameComponent
                      targetUrl={
                        "https://www.smokeraven.com/static/html/forbidden-lands-generate-demon.html"
                      }
                    />
                  }
                />
                <Route
                  path="/forbidden-lands-adventurer-generator"
                  element={
                    <IFrameComponent
                      targetUrl={
                        "https://www.smokeraven.com/static/html/forbidden-lands-adventurer-generator.html"
                      }
                    />
                  }
                />
                {/* <Route path="/forbidden-lands-lead-the-way" element={<IFrameComponent targetUrl={"https://www.smokeraven.com/static/html/forbidden-lands-lead-the-way.html"}/>}/> */}
                <Route path="/blade-runner-overview" element={<BROverview />} />
                <Route path="/blade-runner-classes" element={<BRClasses />} />
                <Route path="/blade-runner-rules" element={<BRRules />} />
              </Routes>
            </Router>
          </div>
        </div>
      </div>
    );
  }
}

export default SmokeRavenNavbar;
