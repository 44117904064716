import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import free from "../../../../assets/myz/session-47-ccc-steelcrest.webp";
import sparky from "../../../../assets/myz/sparky-the-small-vicious-dog.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Wrapping Up the Ghost Ship",
    text: (
      <p>
        The IL Gang is on board the Ghost Ship in the lower car hold. There are
        a couple hundred containers with various amounts of scrap in them. Using
        shopping carts found, the party hauls all the scrap (over 400 units!) to
        the upper deck of the Ghost Ship. Bayazid and Hulagu are attacked by
        Zone Wasps, but the duo escapes and the wasps are not seen again.
        <br />
        <br />
        Skye, Bayazid, and Gladhand dive into the Dead Blue Sea to plant the
        scrap bomb to free the ship from being stuck on spar of land and junk.
        Skye uses Sonar mutation to help Bayazid find best placement for the
        bomb. Ghost Bird is on deck and the rest of the party is in the lower
        car deck waiting to fill any hole with floation materials.
        <br />
        <br />
        A hungry Steel Shark swims into the bomb party and takes Gladhand down
        to one hit point. Gladhand tries his new mutation from last session
        SPORES, hoping it works underwater. It works and he spends a mighty
        EIGHT mutation points to finish off the Steel Shark!
        <br />
        <br />
        Once out of range, the bomb goes off. Rolls are made and perfect result
        occurs which means the tip of the land/scrap reef stays in the hole it
        made, effectively plugging the hole while the ship is free from
        obstruction.
        <br />
        <br />
        NOTE: Bayazid gets a mutation misfire using luminescence to blind the
        Steel Shark and has resulting cosmetic change:{" "}
        <b>Bayazid's skin turns albino white</b>.
      </p>
    ),
    image: {
      imagePath: free,
      altText: `Landfall is made on the island of Command Center Charlie.`,
      className: "imageRight",
      imageCaption: "Landfall Near CCC",
    },
  },
  {
    title: "Cage Island Carousing",
    text: (
      <p>
        The IL Gang returs to Cage Island with the luxury liner Ghost Ship in
        tow. The goon guards on jet skis are pumping their fists in the air and
        guide the boat into the harbor giving the IL Gang precedence over
        traders and Cage vessels.
        <br />
        <br />
        Once on shore, the entire community is gathered. Curiosity and
        celebration fill the air. Nearly everyone in the party joins their
        well-wishers for drinks and a night out carousing. We adapt the Frontier
        Scum "Going On A Bender" rules to see what happens. Highlights of
        carouse are:
        <ul>
          <li>
            Chromicus intimidates Beto and the other clones to let Vanja come
            out and carouse.
          </li>
          <li>
            Gladhand, Hulagu, and Bayazid party hard with some cage island
            goons; riding jet skis drunkenly, singing sea shanties, and ending
            up with 1d10 bullets each as gifts from the goons.
          </li>
          <li>
            Ghost Bird passes out on his hoverboard which gently bumps up
            against a wall in some corner of the marketplace. Ghost Bird wakes
            up to find a mangy street dog that followed him all night has taken
            Ghost Bird as his master. We welcome Sparky the Small Vicous Dog to
            the party. He follows Ghost Bird everywhere, growls a lot, and will
            attack on command!
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: sparky,
      altText: `Sometimes you go carousing on Cage Island and wakeup with a new, small and vicious dog companion. Say hello to Spark!`,
      className: "imageLeft",
      imageCaption: "Sparky",
    },
  },
  {
    title: "Green Man Free",
    text: (
      <p>
        The next morning finds the First Secretary thanking the gang and handing
        over the key to the cell of the Green Man; he is free to go and join the
        IL Gang. Sarge, Cashel, and Skye decide to catch a ride to the Sunshine
        Plaza with some of Ibor's friends who are transporting some trade goods
        on behalf of his mom Gregoria and the First Secretary.
        <br />
        <br />
        At the jail island, the guards are mostly relieved to see the Green Man
        set free. The Green Man seems slow and hesitant. He looks at the party
        and motions with his branch-like hands toward the door to the outside,
        points to his eyes, points to them, and then points to the outside
        again. His moments of silence are often punctuated with non-verbal
        communication of this sort, possibly a part of his possible militiary
        background where hand signals may have been taught for abushes and
        infiltrations.
        <br />
        <br />
        Chromicus talks to the Green Man and says "I too was once in a cage that
        changed me. You can come with us to the Sunshine Plaze where you might
        stand outside and bask in the light everyday." The Green Man slowly
        moves his huge hands and envelops Chromicus' head in a cocoon of
        plant-like appendange and says, "Cage". His communication is always
        oblique yet related.
        <br />
        <br />
        Bayazid asks the Green Man, "do you want to live?" After a vague answer,
        Bayazid presses with "you didn't answer my question: do you want to
        live??" After the Green Man ponderously says "yes" Bayazid fires up
        luminescence mutation and leads the new ally to the outside.
        <br />
        <br />
        The party gets aboard the Steelcrest and travels to Command Center
        Charlie. Highlights are:
        <ul>
          <li>
            Heavy Rot Zone: Smoke Raven is broken by rot and suffers Nausea and
            Sickness critical injury for 6 days (later 3 after HEAL roll); it
            makes all STRENGTH abilities and skills -1.
          </li>
          <li>
            Trade with Whaler GRELDA and her purser Zor: MYZ core rules book
            (found in scrap on Ghost Ship) is traded for whale meat and extra
            supplies thanks to Gladhand's MAKE A DEAL.{" "}
          </li>
          <li>
            An abandoned but functional JET SKI is found moored at an empty
            lighthouse. SmokeRaven hops from the canoe onto the jet ski and
            brings it back to the Steelcrest (which also has a motorboat being
            towed behind that was lifted from the Ghost Ship).
          </li>
        </ul>
        On the beach at the island where Command Center Charlie can be found,
        the party rests and fixes stuff. Beto wants to go explore, but after a
        caution from the PCs and Green Man saying "perimeter dangerous" he stays
        on the beachfront.
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "Wrapup",
    text: (
      <p>
        <h3>Bookkeeping</h3>
        <ul>
          <li>Bitterbeasts: 21 food harvested.</li>
          <li>Pure Plant Patrol: 6 water, 5 booze, 3 grub.</li>
          <li>
            Ark Reputation: 3 mutants arrive seeking citizenship in the Sunshine
            Plaza.
          </li>
        </ul>
        NOTE: Altair is housed at the Rust Castle along with Summer of Love bus.
        Chi Tzu is with the party on the Steelcrest.
        <br />
        <br />
        Next session starts in-game 06-22-0001, 6am on the beach of CCC island.
        The party has healed, fixed stuff, and will be ready to roll after
        bookkeeping and mutation points.
        <br />
        <br />
        Base XP is 3 per character. Then, we discussed cool stuff that happened.
        Based on discussion, GM awards a bonus xp to one of the characters for
        each player. Some highlights discussed were:
        <ul>
          <li>
            Bayazid did cool interaction and luminescence with the Green Man and
            is fixing ons of stuff.
          </li>
          <li>
            Ghost Bird passed out on hoverboard and gaining a small vicious dog.
          </li>
          <li>
            Gladhand debuting spore mutation with underwater EIGHT point attack
            over two rounds on Steel Shark.
          </li>
          <li>SmokeRaven taking charge of the jet ski.</li>
          <li>
            Hulagu and Bayazid keeping party out of combat with zone wasps.
          </li>
        </ul>
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        .
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
];

const MYZAnchortownSession47 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 47: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  The Green Man is freed from Cage Island and landfall on the
                  island of Command Center Charlie.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession47;
