import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Bookkeeping",
    text: (
      <p>
        06-06-0001:
        <br />
        <br />
        Points are spend and mutation points allocated. Six more bitterbeast
        eggs hatch, pushing the Sunshine Plaza over their 30 bitterbeast limit.
        The extras are harvested as grub, adding 25 to the collective grub pile.
        The ARK REPUTATION roll shows that the Pure Plant is attacked and that
        two more roles should be made on the Pure Plant Patrol chart with
        reduced effects. The Sunshine Plaza finishes the SURGERY project which
        helps session body count by 1 and gives a +2 to heal critical injuries
        while in the Ark.
        <br />
        <br />
        We discussed that Altair and Chi Tzu are housed at the Rust Castle along
        with Summer of Love bus as the Gang travels the high seas.
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "Torpedo Fish Steak and More",
    text: (
      <p>
        The group continues travel through the Dead Blue Sea. They fight a
        torpedo fish which they butcher and find a shotgun in it's belly. There
        is another glass storm. They pull up some nets to find antidepressant
        pills.
        <br />
        <br />
        Elwith is still paralyzed and wounded and Smoke Raven finds his feed as
        interim navigator of the Steelcrest.
        <br />
        <br />
        Critical Injury Roundup
        <ul>
          <li>
            GLADHAND has a DAMAGED KIDNEY. Ends morning of 06-13-0001. Point of
            damage for roll to Force, Move or Fight.
          </li>
          <li>
            DOUBLE BIRD got rot critical of REDNESS & STENCH. -1 for Empathy
            rolls until morning of 6-8-0001.
          </li>
          <li>
            SMOKE RAVEN has a GROIN HIT. End morning of 06-10-0001. Point of
            damage for roll to Force, Move or Fight.
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "Modified XP Rules",
    text: (
      <p>
        Group had some awesome discussion of XP rules. We have done the RAW
        questions, and we have done the 3 or 4 per session. We have often found
        we run out of time and don't do the XP talk. We came up with some new
        questions and we talked more about how the questions should be all about
        highlighting roleplay and creative play. And so, we have:
        <ul>
          <li>
            Did you participate in the session? (You get one XP just for being
            there.)
          </li>
          <li>Did you perform a Day’s Work for a project in the Ark?</li>
          <li>Did you explore at least one new sector in the Zone?</li>
          <li>Did you pursue or risk something for your buddies?</li>
          <li>Did you pursue or risk something to reach your big dream?</li>
          <li>Nominate a PC for bonus XP point.</li>
        </ul>
        We decided the questions about NPC you hate and protect were just too
        confining in terms of whether they are present or not in the session.
        <br />
        <br />
        We opened up concept of buddy to not be an assigned buddy but rather
        buddies plural to include your entire party, and even significant NPCs
        or even a group (i.e. group of slaves being freed, etc.)
        <br />
        <br />
        Also, we took away the word sacrifice and replaced it with pursue to
        underline a more open interpretation that points more generally to did
        you do something unusual, creative, epic, cool, and so on.
        <br />
        <br />
        Also, the GM decided that every PC would get a minimum of 3xp per
        session to obviate the possibility of only earning 2xp. It should never
        take three sessions to get enough XP to level an attribute or buy a
        talent.
        <br />
        <br />
        The nomination bit we decided encourages remembered who did interesting
        or cool stuff during the session and promotes comaraderie while
        encouraging vigorous roleplay or creative playing. The rules are:
        <ul>
          <li>
            Each player can nominate one PC for a bonus XP. It cannot be one of
            their own PCs.
          </li>
          <li>
            Each player must make a nomination and discuss why in addition to
            who for.
          </li>
          <li>
            If all PCs have equal amounts of nominations, then we roll off to
            see who gets the point.
          </li>
        </ul>
        So, for this session, Kevin nominated Double Bird for the way he helped
        Chromicus in the canoe to reel in net and fight Grazer fish in the net.
        Rob nominated Boss Hulagu for the epic frog leg leap onto the back of
        the Torpedo Fish to nearly kill the beast in one blow with is katana.
        Tom nominated Chromicus for having the idea to use the canoe everyone
        had forgotten about to get to the artifact tangled in the netting.
        <br />
        <br />
        And so after discussion and nomination rolloff, session XP was: Smoke
        Raven (4xp), Double Bird (3xp), Bayazid (3xp), Hulagu (4xp), Chromicus
        (3xp), and Gladhand (3xp).
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },

  {
    title: "Wrapup",
    text: (
      <p>
        Next session will start on in-game morning of 06-07-2021 with party
        aboard the Steelcrest on the Dead Blue Sea with a STEEL JAWS surprising
        the group to ram the ship.
        <br />
        <br />
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        .
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
];

const MYZAnchortownSession38 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 38: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  The IL gang continues their journey across the Dead Blue Sea
                  aboard the Steelcrest on a mission for the Rust Castle.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                {/*<Card.Title style={cardTitle}>Shootout at Burger Jim's, 03-27-0001 - 03-27-0001</Card.Title>*/}
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession38;
