import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import astrina from "../../../../assets/myz/ark-astrina-the-resourceful.jpg";
import rook from "../../../../assets/myz/ark-rook-the-dog-handler.jpg";
import hulagu from "../../../../assets/myz/ark-hulagu-portrait.jpg";
import jebe from "../../../../assets/myz/ark-jebe-portrait.jpg";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "New Relationships",
    text: (
      <p>
        05-14-0001: In the wake of the Civil War, our mutants had to choose some
        new relationships due to the cosmic alterations to the population of The
        Sunshine Plaza.
        <br />
        <br />
        <ul>
          <li>Hulagu - buddy Gladhand, protect Kublai, hate Berke</li>
          <li>
            Jebe - buddy Double Bird (thanks for saving my life!), protect
            Grandma Yoya, hate Beta the Sour
          </li>
          <li>
            Gladhand - buddy Chromicus, protect Fatima, hate Beta the Sour
          </li>
          <li>Chromicus - buddy Gladhand, protect Jezebel, hate Berke</li>
          <li>Double Bird - buddy Jebe, protect Krao, hate Berke</li>
          <li>Smoke Raven - buddy Hulagu, protect Grit, hate Berke.</li>
        </ul>
        It seems we now have four mutants that hate Berke. Berke's side switch
        to the Skag Gang during the Civil War has earned him the title Berke the
        Betrayer.
        <br />
        <br />
        Hulagu has some of his gang go out on a side mission to find word about
        Berke's location in The Zone. The IL Gang wants revenge.
      </p>
    ),
    image: {
      imagePath: jebe,
      altText: `Jebe the Dog Handler (PC) is on his fourth hound and a critical asset in the IL gang.`,
      className: "imageLeft",
      imageCaption: `Jebe the Dog Handler`,
    },
  },
  {
    title: "Let's Make A Scene",
    text: (
      <p>
        <h5>
          Scene: Boss Hulagu Calls a Meeting and Beta the Sour is Targeted
        </h5>
        Boss Hulagu calls a meeting with Jebe, Gladhand, and Double Bird. Hulagu
        is very impressed with Gladhand. He tells Gladhand the IL Gang owes him
        one. Jebe will exclusively use his new bodyguard taalent for Double Bird
        and Jebe has chosen him as his new buddy thanks to Double Bird's heroic
        run into the lightning storm to save Jebe (see{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-session-21-anchortown"
        >
          Session 21
        </a>
        ).
        <br />
        <br />
        Hulagu employs Gladhand to find out more infomation about Vik the Fixer
        now that Gladhand has taken the vulnerable Vik under his wing. It turns
        out Vik had friends helping him setp the dog fights. Notabley, they find
        out that Beta the Sour is was experimenting on wounded dogs to try and
        enhance them. The surgery and other experiments often resulted in
        horrible deaths for the dogs. Beta the Sour is now hated and on the
        agenda for Jebe and Gladhand.
        <br />
        <br />
        <h5>Scene: Brown Chicken Brown Cow</h5>
        Gladhand builds on a moment he had with Fatima at the funeral for her
        son Elroy and all those who were lost in the Civil War. He spends time
        healing some of the Wanderers, and then approaches Fatima. His
        MANIPULATE has FOUR successes as he builds on the trust he has earned.
        With the ability to have children in a mutant-human pairing, it looks
        like Gladhand may be in the market for creating Elroy Jr. or Gladhand
        the Mini-Me?
        <br />
        <br />
        <h5>Scene: Chromicus and Jezebel Born Again</h5>
        Chromicus convinces Boss Krao to take in Jezebel. His concern that her
        back story about her and Luttrell at the Pure Plant might be true
        becomes fodder for him convincing Jezebel that in addition to working
        for Boss Krao, it is time to let the dead fetus go. Chromicus rolls FIVE
        successes and so Krao is game, and Jezebel lets them bury the baby and
        she takes on the new name Sylvia to symbolize her new rebirth at the
        Sunshine Plaza (but also hopefully to help hide her presence from
        Luttrell in case that would cause problems for the Plaza).
        <br />
        <br />
        The IL Gang has a saddle for bitterbeast made. We discuss and new rule
        is you can either just trade bullets for gear, or if you provide the
        scrap to a gearhead and they succeed, you can make some kind of skill
        roll to see if you get the gear without paying additional price.
        <br />
        <br />
        <h5>Project Rolls</h5>
        <ul>
          <li>Double-Bird (workshop): +1</li>
          <li>Smoke Raven (cropland): +1</li>
          <li>Hulagou (cropland): +0</li>
          <li>Jebe (cropland): 1</li>
          <li>Glandhand (cropland): 1</li>
          <li>Chromicus (cropland): 0</li>
        </ul>
        Note: this is the second of three sessions where players get +3 on
        project rolls as one of the rewards for winning the Civil War.
      </p>
    ),
    image: {
      imagePath: hulagu,
      altText: `Boss Hulagu of the IL Gang. Famous for his merciless, but fair justice and notably in possession of THREE mutations.`,
      className: "imageRight",
      imageCaption: "Boss Hulagu",
    },
  },
  {
    title: "Back Toward The Other Ark",
    text: (
      <p>
        The party travels throught F07 again, and then finds 10 scrap in a
        rotted husk of an airplane crash in Sector G08.
        <br />
        <br />
        They travel through H08 again (the Swimming Hall zone where they found
        Jezebel, now Sylvia).
        <br />
        <br />
        Sector I08 is full of muddy ground which they navigate successfully and
        also find a GRENADE.
        <br />
        <br />
        Sector J08 is filled with skeletons from a long ago war. They dot the
        marshy landscape which is filled with Zone Smog. The rot damage from the
        smog kicks in as Smoke Raven tells the party that this zone has at least
        four artifacts.
        <br />
        <br />
        They find a bottle of ANTIDEPRESSANT pills. And then, as night is
        getting closer, they see a bicycle propped against the remnants of a
        concrete wall. Zone Spiders get the drop on party and bite Chromicus. A
        fierce battle ensues with a total of five Zone Spiders eventually
        emerging from the Smog to fight. Their armor and poison is deadly but no
        match for the party. Once again Hulagu does his frog leg leap with a
        katana and he GETS A THIRD MUTATION!!! He takes the permanent trauma to
        empathy this time and rolls insectoid. So, now Boss Hulagu is a Human
        Plant with Frog Legs and Insectoid!
        <br />
        <br />
        It must be noted that when the bitterbeasts are ridden by a Zone Rider
        or under attack, their bite is nasty and they greatly increase party
        power (not to mention travel speed).
        <br />
        <br />
        After the battle, the players find a BICYCLE and a PAINTING. They know
        there is a fourth artifact in Sector J08 but with night falling and such
        dangerous smog hiding nasties, they decide to enter the edge of Sector
        K08 which is a GLASSIFIED FILED and camp.
      </p>
    ),
    image: {
      imagePath: rook,
      altText: `Rook the Dog Handler, hated by Rowbert dies from injuries sustained in Civil War`,
      className: "imageLeft",
      imageCaption: "Rook the Dog Handler (RIP)",
    },
  },
  {
    title: "Wrapup",
    text: (
      <p>
        Next session will begin morning of 05-15-0001. XP award was 3 for each
        PC. All healing, grub/water, and rot points were done. Sector K08
        Glassified Fields encounters will start first thing next session.
        <br />
        <br />
        Some things we noticed or decided this session:
        <ul>
          <li>
            Rot Suits get damaged on fails without being pushed. Armor does the
            same, but only if damage gets through the successes.
          </li>
          <li>
            We again had the weird confusion about Frog Legs and other mutations
            and realized the place where it's cover in rules is right there were
            it says Activating A Mutation is an Action (i.e. Slow Action) UNLESS
            IT IS ENHANCING OR REACTIVE.
          </li>
          <li>
            We made dismounting a motorcycle or bitterbeast a SLOW ACTION.
          </li>
          <li>
            Now that Hulagu is driving the motorcycle and Jebe is on a bike, we
            note that Hulagu leaping away from the motorbike without dismounting
            could damage the motorcycle. I'm thinking it's a straight 4D6 attack
            on the bike to dump it.
          </li>
          <li>
            We decided you cannot aim a shot while riding bitterbeast. Zone
            Rider talent allows you to shoot at all without penalty from mount
            but you cannot aim.
          </li>
        </ul>
        <br />
        <br />
        NOTE: Double Bird is very very near the end with his rot currently at 8
        with seven permanent. We discussed and reviewed the Rot Critical Injury
        chart and also how Double Bird could use that grenade in some kind of
        epic send off if situation warrants it. Player mentioned how odd it is
        to be playing a PC knowing his time is so very limited with a known
        death approaching. C'est la vie dans Le Zone!!
        <br />
        <br />
        The body count roll was 6. Minus one due to FOOD DEV level of 14 leaves
        us with five casualties. The dead include Rook the Dog Handler
        (Rowbert's hated), Astrina the Resourceful, two nameless mutants, and
        one nameless ex-Kurtzer mutant.
        <br />
        <br />
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: astrina,
      altText: `Astrina the Resourceful was finally committed suicide. Her tragic belief she was worthless was played upon by Calihan and others, but now the Raven god knows she is worthy in heaven.`,
      className: "imageLeft",
      imageCaption: "Astrina the Resourceful (RIP)",
    },
  },
];

const MYZAnchortownSession22 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 22: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  The IL Gang returns to The Zone, making a bee line for The
                  Other Ark; but along the way are some spiders that do not want
                  to give up their artifacts.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                {/*<Card.Title style={cardTitle}>Shootout at Burger Jim's, 03-27-0001 - 03-27-0001</Card.Title>*/}
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession22;
