import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import wagon from "../../../../assets/myz/fast-food-joint-0.jpg";
import moss from "../../../../assets/myz/killer-tree-moss-man.jpg";
import tree from "../../../../assets/myz/killer-tree.jpg";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Powering Up at the Sunshine Plaza",
    text: (
      <p>
        03-27-0001: After fracas with Zone Ghouls at the FAST FOOD JOINT Burger
        Jim's, the party gathers all the scrap and heads back to the Ark since
        it is so near.
        <br />
        <br />
        At the Plaza, Chromicus is approached by a fixer (soon to be a new
        character in the party). The PCs offload scrap into their dens, turn in
        the DRESS to Order of the New Dawn, and find grub (including Smoke Raven
        going into Zone Wrestling for his food/grub hunt). Rel does an amazing
        job making three durable protection suits for the party from the scrap
        haul. And with some rest while the suits are constructed, the party is
        ready to head back into the zone. They will be hunting a bitterbeast as
        they travel to find the scrap oracle Cassandra in hopes of getting a
        potent poison they can use to take out Boss Skag.
        <br />
        <br />
        Last session ended with Ark Threat roll of REVOLUTION which pretty much
        ties nicely into the existing events. As part of that event playing out,
        the IL Gang hears about the semi-captive Helldriver gearhead Raff being
        caught talking into a plastic device and a voice replying. He has been
        communicating with Helldrivers via an artifact.
        <br />
        <br />
        Deeply concerned, the IL gang does some looking and questioning. Word in
        the Ark is that Raff has gone to Boss Skag's camp and is under Skag's
        protection. The party views this development with alarm. After nixing an
        immediate desire to react, they go back into the Zone on the hunt for
        poison.
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageRight",
      imageCaption: "",
    },
  },
  {
    title: "Benny's Food Wagon",
    text: (
      <p>
        <b>Sector D06: Ash Desert</b>: a hot but uneventful sector. Chromicus
        stumbles over a corpse half-buried in Zone sand. The corpse is wearing a
        protection suit artifact. Chromicus puts it in the wheeled suitcase.
        <br />
        <br />
        <b>Sector C06: Fast Food Joint</b>: as they party enters this zone, our
        stalker Smoke Raven sense an artifact in the zone. The PCs emerge into a
        wooded area choked with large trees, branches, shrubs and the rusty
        remnants of an old playground. At the edge of the clearing is an intact
        food wagon (based on the infamous Bennys Food Wagon in Anchorage,
        Alaska. It's been there for over 40 years and is still going!!).
        <br />
        <br />
        Suddenly, six figures silently appear from the edges of the woods. They
        are humanoid but made entirely of moss and plant matter. Everyone rolls
        KNOW THE ZONE, but no one has a clue about these weird creatures. A
        large tree next to the food wagon suddenly moves it's branches and
        hanging vines like limbs. A gaping hole opens in the massive trunk of
        the tree. The hole is a maw filling with sharp wooden tooth dripping
        with sap and ichor. "Bring them to me" the tree says in a low imitation
        of human speech.
        <br />
        Battle with KILLER TREE and six SEED POD creatures begins!!
        <br />
        <br />
      </p>
    ),
    image: {
      imagePath: wagon,
      altText: `Intact food wagon based on Benny\\'s Food wagon in Anchorage, Alaska.`,
      className: "imageLeft",
      imageCaption: `Benny's Food Wagon`,
    },
  },
  {
    title: "Battle With Killer Tree and Seed Pods",
    text: (
      <p>
        Once the battle begins, Boss Hulagu immediately uses FROG LEGS to leap
        forward to the KILLER TREE and attack with the KATANA found at the
        Elysium Fields amusement park (way back in{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-session-08-anchortown"
        >
          Session 08
        </a>{" "}
        for the chroniclers).
        <br />
        <br />
        The rest of the battle highlights are:
        <br />
        <br />
        <ul>
          <li>
            Smoke Raven spends his first round getting booze into the
            flamethrower and lighting it up. But then, Smoke Raven is broken in
            one hit from Moss Man. He drops the flamethrower and suffers from
            CRUSHED KNEE: lethal in 3 days with healing time of 13 days (-2 to
            Sneak/Move).
          </li>
          <li>
            Hulagu uses BARKSKIN to avoid damage when grappled by the KILLER
            TREE. (Recall that Hulago has both FROG LEGS and BARKSKIN due to a
            fortunate mutation mishap).
          </li>
          <li>
            Chromicus is also grappled by the tree and every party member except
            Jebe's new hound Rocket are compelled by the tree to move closer to
            it. (Rocket is the dog party found in Valley of the Moon park in{" "}
            <a
              rel="noreferrer"
              target="_blank"
              href="./mutant-year-zero-session-02-anchortown"
            >
              Session 2
            </a>
            ).
          </li>
          <li>
            Smoke Raven uses the never give up option of MANBEAST mutation to
            get back to his feet. A mishap calls for cosmetic change. Player
            decides Smoke Raven's hair turns to feathers. Combined with the
            earlier mishap that gave him fangs, I think it's safe to say Smoke
            Raven has an epic Stalker look.
          </li>
          <li>
            Hulagu does the frog leap again and does six damage to chop a Moss
            Man in half, and then thanks to the combination of frog legs and
            quickdraw, he also takes a shot at another Moss Man with pistol in
            same round. Epic stuff.
          </li>
          <li>
            Smoke Raven moves and picks up the flamethrower. Then, he is hit by
            Moss Man and broken again!! This time he adds critical injury GROIN
            HIT: non-lethal with healing time of 7 days (1 damage if Move,
            Force, or Fight).
          </li>
          <li>
            Chromicus is broken (stunned which is nothing but a scratch), but
            then Rocket saves him before Moss Man can finish him.
          </li>
        </ul>
        Searching the food wagon uncovers a VIDEO CAMERA [METAPLOT]. Hulagu
        succeeds on comprehension roll with two stunts, so he understands that
        it is a video camera, it's critically important, and that a generater or
        battery is needed to view the video.
        <br />
        <br />
        GM reveals that the DIARY and VIDEO CAMERA are two of six metaplot
        artifacts for the Eden storyline. Finding the video camera is fairly
        epic because it's the one artifact that was placed 100% randomly and the
        sector with the food wagon where it was to be found was 100% randomly
        rolled from sector table. Basically they found the needle in the
        haystack; a metaplot artifact that was lost without any lore or insight
        into its location. Awesome!
      </p>
    ),
    image: {
      imagePath: tree,
      altText: `The Killer Tree is one of the weirdest and most deadly monsters in the zone.`,
      className: "imageRight",
      imageCaption: `KILLER TREE`,
    },
  },
  {
    title: "Wrapup",
    text: (
      <p>
        Projects were advanced on auto-pilot with random rolls for which project
        got points:
        <ul>
          <li>MUSEUM: +3 (COMPLETE)</li>
          <li>DEFENSES: +2 (COMPLETE)</li>
        </ul>
        Next session starts 4pm, 03-28-0001 in game. We have to:
        <ul>
          <li>XP discussion: likely a straight 3 each</li>
          <li>healing rolls for Smoke Raven's two critical injuries.</li>
          <li>look at DEV levels of Ark and discuss</li>
        </ul>
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects.
        </a>
        .
      </p>
    ),
    image: {
      imagePath: moss,
      altText: `The Seed Pods are humanoid plant zombies determined to make more like themselves in service to the killer tree`,
      className: "imageRight",
      imageCaption: "SEED POD",
    },
  },
];

const MYZAnchortownSession15 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 15: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  After a brief return to the Ark, the IL Gang (aka Hulagu's
                  Hooligans) heads back into the zone on a bitterbeast hunt as
                  they travel toward the scrap oracle Cassandra .
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                {/*<Card.Title style={cardTitle}>Shootout at Burger Jim's, 03-27-0001 - 03-27-0001</Card.Title>*/}
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession15;
