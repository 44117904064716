import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import tencendur from "../../../../assets/myz/token2_tencendur.png";
import marengo from "../../../../assets/myz/token2_marengo.png";
import fatima from "../../../../assets/myz/wanderer-fatima-bow.jpg";
import jezebel from "../../../../assets/myz/people-costume-party-jezebel.jpg";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Rebuilding",
    text: (
      <p>
        05-11-0001: This session starts weeks later in game time. We figured
        that in the month or so of down time that everyone healed. This includes
        Bianca who suffered a bicep wound when we finally rolled the 8D6 she had
        coming for being deployed in the plan to kill Skag.
        <br />
        <br />
        Jebe brought a scene to the table wherein he uses SIC THE DOG on Vik the
        Fixer to get him to stop staging dog fights. Vik caves in fear. Then,
        Gladhand swoops in while Vik is vulnerable and uses MAKE A DEAL to get
        some goods while making Vik feel like Gladhand can steer him toward
        another occupation.
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: ``,
    },
  },
  {
    title: "Burials and Speeches to Bond The People",
    text: (
      <p>
        Fatima brings up the necessity for burials and remembrances. She has
        lost a lot and wants the outcome of such tragedy to be meaningful; she
        demands a place at the table of how decisions are made in the Plaza. I
        walk the players through the population and graveyard page and they
        realize the Plaza went from 177 to 87. There is some hope, and lots of
        fear. And the speeches are given:
        <ul>
          <li>
            BOSS HULAGU (pc): "All our enemies are run off. We are all friends,
            I hope. We cut out a disease. The future of the ark depends on
            unity."
          </li>
          <li>
            FATIMA (npc human): "We have given our blood. I have given my son!
            My best friend and support Montana was taken. Half my people died.
            But I am here, and my people are here. Let us not have died in
            vain."
          </li>
          <li>
            DOUBLE BIRD (pc): "our vision is to protect the vulnerable and make
            them strong. We have lost some. But we can reach out to the others
            in the Zone so others may know our way."
          </li>
          <li>
            CHROMICUS (pc): <i>pushed forward by Gladhand</i>. You've all heard
            me speak before. I do not have the same clear voice. I have been
            broken in the Zone, and I have been broken in the Plaza. Our enemies
            has broken us and they have broken me. The only way forward; the
            only way i can heal and you with me, is if we all come together and
            be as one. I need you now, and I will be there for you."
          </li>
          <li>
            SMOKE RAVEN (pc): "Mystery within us. As a community, the mystery
            will be shared and the riches of the world will be ours together."
          </li>
          <li>
            KRAO (npc): <i>holding the shard of Hannah's mirror</i>. "The Elder
            is in a coma. Half of our brothers and sisters are hone. But still,
            wa are a new family. It is time to take the steps that must be
            taken, and for us all to move forward."
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: fatima,
      altText: `Fatima the human wanderer lost her son Elroy and her right-hand mand Montana in the Civil War of the Sunshine Plaza.`,
      className: "imageLeft",
      imageCaption: "Wanderer Fatima",
    },
  },
  {
    title: "Preparing for The Zone",
    text: (
      <p>
        While preparing to go back into the zone toward The Other Ark the IL
        Gang notices a few things and accomplishes a few things:
        <ul>
          <li>
            Chronicler Silas has pulled out of his suicidal funk. He was talked
            out of suicide by Smoke Raven when Silas thought the Helldriver
            attack was his fault for delivering poisonous water to them. The
            Civil War had him at the brink, but after the speeches and
            celebration of the new People he has decided he wants to live. Of
            all the Chroniclers, he is focused on the here and now; he is
            dedicated to chronicling the history of the people more than
            cataloging items from the past.
          </li>
          <li>
            The little human girl Pequena has been orphaned; he father Jesus
            died in the Civil War.
          </li>
          <li>
            Another bitterbeast egg hatches and the Plaza now has four trained
            bitterbeasts.
          </li>
          <li>
            The Plaza completes work on a WATCHTOWER and starts on CROPLAND as
            well as advancing their other projects.
          </li>
          <li>
            Hulagu, Krao, and Gladhand finalize a deal with RAVEN from the PURE
            PLANT. There will be rotaning shifts of three mutants that stay at
            the PURE PLANT for a week providing help and protection. When the
            new shift arrives, the old shift will return to the Sunshine Plaza
            with all the rot-free water they can carry.
          </li>
        </ul>
        As the gang prepares for their longest journey, they realize they need
        more carrying capacity. Exkurtzer Rel makes a saddlebag (carry capacity
        6 regular items). SMOKE RAVEN and DOUBLE BIRD both take ZONE RIDER
        talent so they can ride bitterbeasts.
        <br />
        <br />
        <h6>Project Roll Successes</h6>
        - Double-Bird (Watchtower): 3<br />
        - Jebe (Watchtower): 2<br />
        - Chromicus (Cropland): 1<br />
        - Gladhand (Cropland): 0<br />
        - Hulagu (Cropland, Know the Zone): 2<br />
        - Smoke Raven (Cropland, Know the Zone): 1
        <br />
        <br />
        The party figures out who will be riding or driving what and it suddenly
        dawns on us . . . the entire party has vehicles and are kinda bad-ass
        now.
        <br />
        <br />
        <h6>Zone Party Composition</h6>
        <ul>
          <li>Gladhand rides Bucephalus.</li>
          <li>Chromicus rides Tencendur.</li>
          <li>Smoke Raven rides Valegro who is outfitted with scrap saddle.</li>
          <li>Hulagu & Jebe ride the Motorcycle with Tiska 2 riding along.</li>
          <li>Double Bird rides Marengo.</li>
        </ul>
      </p>
    ),
    image: {
      imagePath: marengo,
      altText: `Marengo, the second trained bitterbeast of the Sunshine Plaza`,
      className: "imageRight",
      imageCaption: "Marengo",
    },
  },
  {
    title: "In The Zone Again",
    text: (
      <p>
        The party travels through some known sectors (E07, F07) picking up some
        new finds from FIND THE PATH along the way.
        <br />
        <br />
        <b style={{ color: "green" }}>Sector G07: Gas Station</b>: nothing much
        here except a dilapidated gas station, nothing found.
        <br />
        <br />
        <b style={{ color: "green" }}>Sector H08: Swimming Hall</b>: as party
        enters this sector they are heading toward a greatly overgrown swimming
        hall. Suddenly an electric storm fires out of the darkening sky. The
        party is two turns from the hall. Hulagu jumps with FROG LEGS from the
        motorcycle as Jebe motors on. CHROMICUS flys off Tencendur. Everyone
        else, including the beasts suffer lightening attacks. Mutants and
        bitterbeasts all take damage as they run or race through the muddy
        field.
        <h5 style={{ paddingTop: 10, color: "red" }}>The Near Death of Jebe</h5>
        Jebe is broken because the lightning is attracted to the metal
        motorcycle. He flys from the bike to the ground and the motorcycle
        crashes on top of him. Part of the engiene pierces Jebe's log (i.e.
        SEVERED LEG ARTERY on Critical Injury chart). Jebe has three minutes to
        live before he bleeds to death!!
        <br />
        <br />
        Double Bird races from the protection of the Swimming Hall to grab Jebe
        and run with him back to shelter. As lightening crashes, Double Bird
        sees a bipedal creature of some sort enter the Swimming Hall from the
        other end.
        <br />
        We have a hilariously tense 10-15 minutes as we have to have player
        re-roll twice because this injury has a -1 on the Save A Life roll and
        we get it confused with the bonuses from other mutants helping.
        <br />
        <br />
        Gladhand comes forth to make a HEAL roll. He gets no successes. As he
        readies to push it, the party hears a rattling sound. From a darkened
        doorway the party sees the bipedal creature. It is an "old" woman
        wearing layers of furs, a head dress and shaking a wooden staff with a
        round ball on the end of it. As they see her she turns and runs into the
        shadows. Gladhand's push is rolled and Jebe is saved.
        <h5 style={{ paddingTop: 10, color: "red" }}>Tea Party</h5>
        As the party searches the swimming hall, they find the woman with the
        furs having a "tea party". She has two teddy bears sitting at a table
        with four chairs. On the table is a tea set and a cloche. She is
        babbling and talking to the bears. She calls the little brown bear with
        a bowtie "Luttie" and seems to prefer him to the other bear Nathan who
        was maybe pink at one time but is so dirty it is hard to tell.
        <br />
        <br />
        When she sees the party she gestures toward the table and keeps about
        with her sing-song psychobabble. Gladhand and Hulagu sit at the table.
        They try to get information from her. She pours them each some "tea".
        Hulagu lifts his cup and says,{" "}
        <b style={{ color: "red" }}>"Let's not be rude."</b> She is pleased that
        they both accept her tea and drink it. Drinking her tea earns her
        goodwill, crazed as it is, but carries with it taking a rot point.
        <br />
        <br />
        Boss Hulagu spritzes some cologne to enhance his MANIPULATE roll and
        prys at Jezebel for information. Meanwhile, Double Bird uses his
        TELEPATHY mutation and realizes she is afaid and is hiding a secret
        under the cloche. Double Bird kneels next to Hulagu and says, "we need
        to find out what is under the cloche, but without upsetting her."
        <br />
        <br />
        Hulagu makes his MANIPULATE roll and slowly moves his hand toward the
        cloche. "We are here to help you he says" as Jezebel is shaking her head
        and mumbling "no no no". "We don't mean you any harm" Hulagu croons as
        he lifts the lid of the cloche. Under the cloche is a dead human infant
        curled up on the serving platter. Jezebel screams a piercing mourn as
        Hulagu suffers a 3D6 doubt attack from the horror of what he has seen.
        Hulagu jumps up from his chair and turns to vomit in the corner of the
        room, broken from doubt damage!
        <br />
        <br />
        After the commotion dies down, the best the party can tell from
        Jezebel's mad mutterings is that she claims to be an outcast from the
        PURE PLANT. She implies she was in-love with or the lover of Lutrell. It
        seems she is saying the dead baby was hers and is in some way related to
        her being an outcast but it is difficult to piece her story together.
        Gladhand speaks with her and covinces her ride back to the Plaza with
        them. GM allows a passanger on bitter beast as a one-off expedience with
        the cost of rider's Zone Rider is nullified by Jezebel as passenger and
        that passenger on bitterbeast is not an established precedent.
        <br />
        <br />
        Luckily the party rolls no threat as they return along the same sectors.
      </p>
    ),
    image: {
      imagePath: jezebel,
      altText: `Crazed human woman found having "tea party" with two stuffed bears. 
        From her babbling the party gleans that she claims to be outcast from the Pure Plant, an ex-lover of Luttrell, and there was a dead infant under a cloche. `,
      className: "imageRight",
      imageCaption: "",
    },
  },
  {
    title: "Wrapup",
    text: (
      <p>
        Next session will begin morning of 05-13-0001. XP award was 3 and 4 to
        each player. In other words, they can give three to one of their PCs and
        4 to the other.
        <br />
        <br />
        Rot washing was done. Body Count roll was 4 (but Ark DEV level for food
        is now good enough that Body Count is minus 2.
        <br />
        <br />
        The body count is a nameless mutant and Vik the Fixer (a bizarre
        coincidence since just this session Jebe stripped Vik of his roll
        staging dog fights . . . hmm)
        <br />
        <br />
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: tencendur,
      altText: `Tencendur, the third trained bitterbeast of the Sunshine Plaza.`,
      className: "imageLeft",
      imageCaption: "Tencendur",
    },
  },
];

const MYZAnchortownSession21 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 21: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  The IL Gang leads a burial service for those lost in the Civil
                  War followed by speeches about the future of The People. Then,
                  it's back to The Zone where Jebe very nearly dies and a crazy
                  lady with teddy bears is encountered.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                {/*<Card.Title style={cardTitle}>Shootout at Burger Jim's, 03-27-0001 - 03-27-0001</Card.Title>*/}
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession21;
