import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import flTitle from "../../../src/assets/forbidden-lands/forbidden-lands-logo.webp";
import sectionDivider from "../../../src/assets/fl-header-bar.jpg";
import "../../index.css";

import abyssWorm from "../../../src/assets/forbidden-lands-bestiary-tokens/abyss-worm-token.png";
import bloodling from "../../../src/assets/forbidden-lands-bestiary-tokens/bloodling-token.png";
import deathKnight from "../../../src/assets/forbidden-lands-bestiary-tokens/death-knight-token.png";
import demon1 from "../../../src/assets/forbidden-lands-bestiary-tokens/demon-1-token.png";
import demon2 from "../../../src/assets/forbidden-lands-bestiary-tokens/demon-token.png";
import dragon from "../../../src/assets/forbidden-lands-bestiary-tokens/dragon-token.png";
import drakewyrm from "../../../src/assets/forbidden-lands-bestiary-tokens/drakewyrm-token.png";
import ent from "../../../src/assets/forbidden-lands-bestiary-tokens/ent-token.png";
import ghost from "../../../src/assets/forbidden-lands-bestiary-tokens/ghost-token.png";
import giantSquid from "../../../src/assets/forbidden-lands-bestiary-tokens/giant-squid-token.png";
import giant from "../../../src/assets/forbidden-lands-bestiary-tokens/giant-token.png";
import grayBear from "../../../src/assets/forbidden-lands-bestiary-tokens/gray-bear-token.png";
import gryphon from "../../../src/assets/forbidden-lands-bestiary-tokens/gryphon-token.png";
import harpy from "../../../src/assets/forbidden-lands-bestiary-tokens/harpy-token.png";
import insectoid from "../../../src/assets/forbidden-lands-bestiary-tokens/insectoid-token.png";
import manticore from "../../../src/assets/forbidden-lands-bestiary-tokens/manticore-token.png";
import minotaur from "../../../src/assets/forbidden-lands-bestiary-tokens/minotaur-token.png";
import nightwarg from "../../../src/assets/forbidden-lands-bestiary-tokens/nightwarg-token.png";
import seaSerpent from "../../../src/assets/forbidden-lands-bestiary-tokens/sea-serpent-token.png";
import stranglingVine from "../../../src/assets/forbidden-lands-bestiary-tokens/strangling-vine-token.png";
import troll1 from "../../../src/assets/forbidden-lands-bestiary-tokens/troll-1-token.png";
import troll2 from "../../../src/assets/forbidden-lands-bestiary-tokens/troll-2-token.png";
import undead1 from "../../../src/assets/forbidden-lands-bestiary-tokens/undead-1-token.png";
import undead2 from "../../../src/assets/forbidden-lands-bestiary-tokens/undead-2-token.png";
import undead3 from "../../../src/assets/forbidden-lands-bestiary-tokens/undead-3-token.png";
import wyvern from "../../../src/assets/forbidden-lands-bestiary-tokens/wyvern-token.png";

const cardTitle = {
  paddingTop: 10,
  paddingLeft: 10,
  paddingBottom: 10,
  fontSize: 24,
  backgroundColor: "rgba(177, 83, 90, 1) ",
};
const pageHeader = {
  color: "black",
  backgroundColor: "white",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Aladin",
  fontSize: 20,
  backgroundColor: "rgba(118, 131, 87, 1)",
  color: "white",
  marginBottom: "15px",
};

class FLBestiaryTokens extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "BEGINNING NAME",
      text: "Click button for some fun.",
      testValue: "test value",
      selectedMutation: null,
    };
  }

  componentWillMount() {
    document.body.style.backgroundImage = `url(${require("../../../src/assets/fl-map-groveland-woods.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "round";
    document.body.style.backgroundAttachment = "fixed";
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = null;
  }

  render() {
    return (
      <div>
        <Container>
          <Row>
            <Col>
              <div
                style={{
                  backgroundColor: "transparent",
                  fontFamily: "Orbitron",
                }}
              >
                <main role="main" className="inner cover text-center">
                  <img
                    src={flTitle}
                    style={{ maxWidth: "400px" }}
                    alt="Forbidden Lands campaign"
                  />
                </main>
                <div className={"forbiddenLandsFont"} style={pageHeader}>
                  <h1 className="cover-heading">
                    Forbidden Lands Bestiary VTT Tokens
                  </h1>
                  <p className="lead leadExtra">
                    The core bestiary of Forbidden Lands in transparent token
                    form for your virtual tabletop enjoyment. Made with roll20
                    in mind.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card style={cardStyle}>
                <img src={sectionDivider} alt="section divider" />
                <Card.Body>
                  <Card.Title style={cardTitle}>Bestiary Tokens</Card.Title>

                  <Card.Text>
                    <div className={"tokenWrapper"}>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Abyss Worm</div>
                        <img src={abyssWorm} alt="Abyss Worm" />
                      </div>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Bloodling</div>
                        <img src={bloodling} alt="Bloodling" />
                      </div>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Death Knight</div>
                        <img src={deathKnight} alt="Death Knight" />
                      </div>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Demon 1</div>
                        <img src={demon2} alt="Demon 1" />
                      </div>
                    </div>
                    <div className={"tokenWrapper"}>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Demon 2</div>
                        <img src={demon1} alt="Demon 2" />
                      </div>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Dragon</div>
                        <img src={dragon} alt="Dragon" />
                      </div>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Drakewyrm</div>
                        <img src={drakewyrm} alt="Drakewyrm" />
                      </div>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Ent</div>
                        <img src={ent} alt="Ent" />
                      </div>
                    </div>
                    <div className={"tokenWrapper"}>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Ghost</div>
                        <img src={ghost} alt="Ghost" />
                      </div>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Giant Squid</div>
                        <img src={giantSquid} alt="Giant Squid" />
                      </div>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Giant</div>
                        <img src={giant} alt="Giant" />
                      </div>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Gray Bear</div>
                        <img src={grayBear} alt="Gray Bear" />
                      </div>
                    </div>
                    <div className={"tokenWrapper"}>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Gryphon</div>
                        <img src={gryphon} alt="Gryphon" />
                      </div>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Harpy</div>
                        <img src={harpy} alt="Harpy" />
                      </div>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Insetoid</div>
                        <img src={insectoid} alt="Insectoid" />
                      </div>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Manticore</div>
                        <img src={manticore} alt="Manticore" />
                      </div>
                    </div>
                    <div className={"tokenWrapper"}>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Minotaur</div>
                        <img src={minotaur} alt="Minotaur" />
                      </div>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Nightwarg</div>
                        <img src={nightwarg} alt="Nightwarg" />
                      </div>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Sea Serpent</div>
                        <img src={seaSerpent} alt="Sea Serpent" />
                      </div>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>
                          Strangling Vine
                        </div>
                        <img src={stranglingVine} alt="Strangling Vine" />
                      </div>
                    </div>
                    <div className={"tokenWrapper"}>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Troll 1</div>
                        <img src={troll1} alt="Troll 1" />
                      </div>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Troll 2</div>
                        <img src={troll2} alt="Troll 2" />
                      </div>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Undead 1</div>
                        <img src={undead1} alt="Undead 1" />
                      </div>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Undead 2</div>
                        <img src={undead2} alt="Undead 2" />
                      </div>
                    </div>
                    <div className={"tokenWrapper"}>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Undead 3</div>
                        <img src={undead3} alt="Undead 3" />
                      </div>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Wyvern</div>
                        <img src={wyvern} alt="Wyvern" />
                      </div>
                      <div className={"tokens"}></div>
                      <div className={"tokens"}></div>
                      <div className={"tokens"}></div>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default FLBestiaryTokens;
