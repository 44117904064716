import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import battleBus from "../../../../assets/myz/battle-bus.jpg";
import polarBear from "../../../../assets/myz/polar-bear.jpg";
import tunnels from "../../../../assets/myz/tunnels.jpg";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Bookkeeping",
    text: (
      <p>
        05-19-0001:
        <ul>
          <li>PARTY ROLL CALL: Timur, Hulagu, GladHand, and Chromicus.</li>
          <li>BITTERBEASTS: Neopolit born!</li>
          <li>
            PROJECT ROLLS: Workshop +4 (party wants to get workshop done and get
            cracking on distillery since they have three battle buses now).
          </li>
          <li>
            PURE PLANT PATROL: everything goes smoothly: 4 water, 6 grub, 3
            booze
          </li>
          <li>
            ARTIFACTS TURNED IN: Bicycle (+1 tech), Assault Rifle (+4 tech/+2
            war), Chainsaw (+4 tech)
          </li>
          <li>
            NEW GUY: Timur the Gearhead steps up as lieutenant of the IL gang
          </li>
          <li>
            ZONE QUAKE: 4 damage to Zone Wrestling which destroys Zone Wrestling
            and takes away 4 points from WARFARE dev level.
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: battleBus,
      altText: `One of the three battle buses stolen from the Diamond Center ark`,
      className: "imageRight",
      imageCaption: `Battle Bus`,
    },
  },
  {
    title: "Home Again Jiggity . . . Oh Shit",
    text: (
      <p>
        When the IL Gang pulls up to the Plaza in their three battle buses, the
        guards sound the alarm: "Helldrivers!!" But, before any misunderstanding
        arises, the People realize it is the IL Gang returned. Mutants and
        humans gather around in the night time glow of the Ark as the gate is
        opened and several rusty automotive carcasses are moved to make room for
        the battle buses to park inside the perimeter. Gawkers and admirers
        gather around.
        <br />
        <br />
        The IL Gang and over 20 new humans rescued from the Diamond Center are
        stepping off the buses to the the amazement of The People. Gladhand
        whistles to guard in watchtower to get the spotlight on the bus as Boss
        Hulagu steps off to give a speech.
        <br />
        <br />
        "Look at us! Look what we brought", Boss Hulagu intones to his people.
        "We learned something. It's dangerous in the Zone. Not just monsters;
        other mutants like us, but they are not friendly. They are trying to
        survive by taking what we have. Now we have weapons."
        <br />
        <br />
        The crowd is cheering and nodding in wonderment as Chromicus adds, "You
        know me, ex-kurtzers; we were taken in. When we are divided we have
        strife. When we are united we cannot be stopped." Hulagu and Gladhand
        notice that some of the humans in the crowd seem concerned when the
        language "we are the IL Gang" is spoken. When Fatima is asked to speak,
        she is curt, merely telling the People that a council has been convened
        for the following morning and much will be discussed.
        <br />
        <br />
        During the night, before sweet sleep overtakes them, Timur makes a
        spiked club for himself and Gladhand makes a deal to get some booze.
        Gladhand speaks with Fatima as they settle in Den to get intimate. She
        is friendly to him but explains there has been tension between some of
        the humans and the mutants, but not to worry as they can discuss at
        council meeting. We decide that Fatima and Gladhand consummate fully and
        that <b>Fatima will be pregnant as story goes forward</b>.
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "The Council",
    text: (
      <p>
        05-20-0001: Council meeting happens on the turf of the Order of the New
        Dawn. PCs notice that Fatima calls meeting to order instead of Boss
        Krao. Items include:
        <ul>
          <li>
            FATIMA: As you can already see, the earthquakes have been coming and
            going and damaging our Ark. In fact, we now have a huge crack in the
            basement of the plaza. Two days ago this vapor you smelled started
            to seep from the crack in the basement. And just yesterday Wanderer
            Pequena disappeared. I will let Wander Mareth and Burton speak to
            this”. (NOTE: gas causes one Wit damage each day unless ENDURE roll
            success).
          </li>
          <li>
            MARETH and BURTON: (human couple from Wanderers). Mareth looks
            stricken. “We have been surrogate parents to Pequena since Jesus
            died. It has been a blessing but she misses him. She has had
            nightmares since he died in the Civil War. Then, these last few days
            she has been more upset than usual. During the day she has been
            saying is looking for her daddy. We can’t get her to understand.
            Then, yesterday, when we woke up, she was not in our den. We have
            not been able to find her since, but Kublai said he saw her going
            into the basement yesterday morning.”
          </li>
          <li>
            FATIMA: "Derek was patrolling with some of the IL Gang and saw
            Helldrivers at the edge of the sector. Helldriver Harley was riding
            in The Juggernaut with Illona. She mooned the patrol and drove away
            before we could get a proper shot at her."
          </li>
          <li>
            BOSS KRAO: "The gas is making the Elder gasp in his coma, he can
            barely breathe. He is inside the Temple of Endurance but it still
            affects him. You have seen many mutants are in gathering place
            scattered over the ground. We cannot sleep and many are sick. (NOTE:
            Elder has been in a coma since{" "}
            <a
              href="https://www.smokeraven.com/mutant-year-zero-session-21-anchortown"
              rel="noreferrer"
              target="_blank"
            >
              Session 21
            </a>
            . Also, FLASHLIGHT and BATTERY artifacts are missing from the Dawn
            Vault and we suspect Pequena took them to go into the fissure below
            the Plaza to find her father.
          </li>
          <li>
            FATIMA: she explains that she wants to be the official third boss of
            the Ark. The IL Gang, including Boss Hulagu, are gone more often
            than not. She is the one who keeps the humans from turning on the
            mutants and reminds them we are all allies. She makes sure the Ark
            is secure and patrols are trained. She tells Boss Hulagu she trusts
            him since they met in Zone and she gifted him with gun artifact, but
            she is carrying the weight of running the Ark. Fatima says she
            continues to make sure the human allies at the PURE PLANT are happy
            by making sure one of the members of the PURE PLANT PATROL is always
            human.
          </li>
          <li>
            BOSS HULAGU: Hulagu says "it is done, you're a boss Fatima. But the
            IL Gang runs the Plaza, don't ever forget that."
          </li>
        </ul>
        While Hulagu is true to his big dream to be the boss of all bosses, we
        can see Fatima doesn't like Hulagu's claim of dominance while allowing a
        three-way tribunal with Hulagu, Krao, and Fatima. However, she respects
        him and the achievements of the IL Gang too much to make a stink about
        it; at least not at this time.
        <br />
        <br />
        <h4>Update from IL Gang Patrols </h4>
        <ul>
          <li>
            <b>Helldrivers</b> seen at edge of sector as Fatima said.
          </li>
          <li>
            Rumors of an <b>Oracle of the Silver Egg</b> somewhere near
            nine-block of sectors F-H/16-19. Some kind of mutant that can tell
            the future, cure all ills, point the way toward Eden and so forth.
            The rumors all vary except that "Oracle of the Silver Egg" as title
            remains consistent.
          </li>
          <li>
            <b>Ex-Skag Gang Seen Heading South</b>: Julian the Deep, Lystra the
            Wild Slave, Wanderer Belka, Raff (from Helldrivers) and 30 other
            mutants travelling south. Morena was seen heading south with Kyle
            and Luna separately from Julian (note: Boss Hulagu lets the gang
            know Morena, Kyle and Luna are dead).. The concern is they could
            team-up with Helldrivers and get detailed layout of Ark for attack
            plans.
          </li>
          <li>
            <b>Quiet Bob</b> and Calihan the Promiscuous with their 26 followers
            have no sighting or rumors.
          </li>
          <li>
            <b>Boss Berke</b> and his 16 followers are rumored to have headed
            west toward Scrap Oracle Cassandra. Fang the Mountain may know more
            if you can get him drunk enough.
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: tunnels,
      altText: `The Zone Quake opens fissure beneath the Sunshine Plaza into ancient tunnels.`,
      className: "imageLeft",
      imageCaption: `Beneath the Sunshine Plaza`,
    },
  },
  {
    title: "Beneath the Sunshine Plaza",
    text: (
      <p>
        05-21-0001: The IL Gang inspects the fissure under the Plaza. It is
        clearly the source of the gas affecting the Ark. It leads into a cold,
        dark tunnel that slopes sharply downward. The party prepares for the
        darkness and the gas by having Kublai and Rel, as well as the new PC
        Timur build a TINDERBOX, an OIL LANTERN, and some FACE FILTERS (layers
        of cloth held in front of face by scrap. It can filter out gases that GM
        allows; not rot . . . it is a makeshift gas mask that is marginally
        effective and requires a unit of water every hour as it must be soaked
        over and over to filter the gas.
        <br />
        <br />
        And then the gang heads into the tunnels. The tunnels are setup as an
        emergent, abstract "dungeon crawl" with token drug through tunnels and
        each cave encountered a "node". When a cave node is reached, the clock
        ticks forward an hour, consumables are marked off (aka water for face
        filter), and then there is a roll on an encounter chart and on a loot
        chart. The tunnels are continually sloping downward, and the air is very
        cold as the party travels deeper. The brutal chain of events goes like
        this:
        <ul>
          <li>
            CAVE 1: one desperate, starving ZONE GHOUL attacks. The PCs quickly
            blast the toxic punk into the ground. No loot.
          </li>
          <li>
            CAVE 2: a sudden spray of dirt and rocks erputs right in the middle
            of the party and the roar of a chainsaw fills the air. An AUTOMATON
            atttacks! It breaks Boss Hulagu who suffers from a CRUSHED ELBOW; a
            lethal injury in several days time. The party finds a BLANKET which
            gives +2 ENDURE vs Cold in the cave as they gather up Boss Hulagu
            and head back to the Ark.
          </li>
          <li>
            The automaton is killed and the party races back to the surface.
            GRANDMA YOYA makes the heal role to save Boss Hulagu's life. As he
            recovers and eats, the others take naps to recover Wits damage and
            prepare to enter the tunnels again.
          </li>
          <li>
            CAVE 1 (again): this time Cave 1 welcomes the IL Gang with a WORM
            SWARM (a monster not seen since the WORM SWARM in a jar at the Pure
            Plant way back in{" "}
            <a
              href="/mutant-year-zero-session-05-anchortown"
              rel="noreferrer"
              target="_blank"
            >
              Session 5.
            </a>
            The new guy TIMUR runs up to attack the WORM SWARM, pushes his roll
            and breaks himself! The PCs decide to return to the surface again
            while the swarm is occupied eating Timur. We keep Timur's character
            sheet and he becomes a new character named BAYAZID except with new
            random mutations. Then, the party heads back into tunnels.
          </li>
          <li>
            CAVE 2 (again): two ZONE SPIDERS lower from the dark ceiling to
            attack the party. Not an impossible fight but their venom and 5 STR
            prove to be non-trivial
          </li>
          <li>
            CAVE 3: the party fails to spot one of the Zone's worst ambush
            predators. Out of the blue a POLAR DEVOURER charges and is making a
            roll against Boss Hulagu. . . and we end the session there on a
            cliff hanger, mid-attack.
          </li>
        </ul>
        <br />
        <br />
      </p>
    ),
    image: {
      imagePath: polarBear,
      altText: `Polar Devourer: one of the nightmares of the Zone. Not only huge and deadly, but expert ambush predators despite their size.`,
      className: "imageLeft",
      imageCaption: `Polar Devourer`,
    },
  },

  {
    title: "Wrapup",
    text: (
      <p>
        Next session will start on in-game day 05-19-2021
        <br />
        <br />
        No need to worry about rot. We have to do XP. Either discussion or
        simply 3 each.
        <br />
        <br />
        <b>Homebrew Rule</b>: we introduced Height Categories, Vehicle Chase
        rules, DEV Requirements rule tweak, and Sickness in anticipation of GM
        having added{" "}
        <a
          href="https://www.drivethrurpg.com/product/303603/Mutant-Mythic-Artifacts"
          rel="noreferrer"
          target="_blank"
        >
          Mythic Artifacts
        </a>
        , threats from the{" "}
        <a
          href="https://www.drivethrurpg.com/product/305844/Zone-Bestiary"
          rel="noreferrer"
          target="_blank"
        >
          Zone Bestiary
        </a>
        , and{" "}
        <a
          href="https://www.drivethrurpg.com/product/300315/Zone-Humans"
          rel="noreferrer"
          target="_blank"
        >
          Zone Human
        </a>{" "}
        rules to the game.
        <br />
        <br />
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        .
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        , including the death of new PC Timur who came and went in same session!
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageRight",
      imageCaption: "",
    },
  },
];

const MYZAnchortownSession30 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 30: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  The IL gang returns to the Sunshine Plaza, has a council
                  meeting, and goes underground to investigate the mystery of
                  why the Zone Quakes.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                {/*<Card.Title style={cardTitle}>Shootout at Burger Jim's, 03-27-0001 - 03-27-0001</Card.Title>*/}
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession30;
