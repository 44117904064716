import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import logo from "../../../../src/assets/forbidden-lands/forbidden-lands-logo.webp";
import sectionDivider from "../../../../src/assets/forbidden-lands/session00/bitter-reach-page-banner-session-00.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";
import "./forbidden-lands-styles.scss";
import gomsten from "../../../../src/assets/forbidden-lands/session01/gomsten-island.webp";
import worldMap from "../../../../src/assets/forbidden-lands/maps/word-map-with-boundary-overlay.webp";
import blisster from "../../../../src/assets/forbidden-lands/pcs/blisster.webp";
import rog from "../../../../src/assets/forbidden-lands/pcs/rog-golar-portrait.webp";
import lobo from "../../../../src/assets/forbidden-lands/pcs/lobo-portrait.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "1",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Carter One",
  backgroundColor: "rgba(0, 0, 0, 1) ",
  color: "white",
  marginBottom: "15px",
};

const FBLSession00 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  const section1Data = [
    {
      title: "Prisoners In Gomsten",
      anchor: "prisoners",
      sectionTitleCssName: "sectionTitleFBL",
      text: (
        <div className="fblBodyText">
          <ImageComponent
            image={gomsten}
            caption="Gomsten Island"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Gomsten Island is a prison run by Alderland wherein criminals and their descendants are doomed to permanent incarceration.<br/><br/>As the red mist has begun to fade, Alderland gives the prisoners amnesty and passage to the Bitter Reach as the king has more important matters to handle now and the island prison is to be shut down."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <ImageComponent
            image={worldMap}
            caption="World Map"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="The Forbidden Lands world map. I like to call the world Corvus, unofficially.<br/><br/>The map is overlaid with the natural boundaries campaign areas as of 02/2024."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          The Forbidden Lands campaign will begin with the liberation of your
          characters from an isolated prison island. We will start in the{" "}
          <span style={{ color: "red" }}>Bitter Reach</span>. The isolated
          characters from the island prison will help explain their lack of
          roots in any area and deepen the exploration to learn the world and
          drive the desire to get riches to put your incarcerated past behind
          you.
          <br />
          <br />
          Each player will have two characters. One will be from Gomsten and we
          will do them step-by-step manually. The second character will be
          joining the party later after the prisoners get to the Bitter Reach.
          The second character can be created using CharGen in Foundry which
          gives that character access to the formative event and paths from the
          Legends and Adventurers book.
          <br />
          <br />
          While Ravenland has been locked down by the Blood Mist you read about
          in the player's handbook, the Bitter Reach has been the same frozen
          wasteland for centuries, locked in an arcane winter that has only four
          cold seasons: <span style={{ color: "lightblue" }}>Breakup</span>,
          &nbsp;<span style={{ color: "lightblue" }}>Bright Winter</span>,
          &nbsp;
          <span style={{ color: "lightblue" }}>Dark Winter</span>, and{" "}
          <span style={{ color: "lightblue" }}>True Winter</span>.
          <hr className="hrForbiddenLands" />
          <ImageComponent
            image={blisster}
            caption="Blisster"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Blisster is a YOUNG GOBLIN ROGUE.<br/><br/>Blisster's father stole from the wrong guys and ended up on Gomsten island. Blisster has lived his entire life  as a prisoner on the island due to the harsh dictates of Alderlandean justice."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <ImageComponent
            image={rog}
            caption="Rog-Golar"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="Rog-Golar is a recent arrival to Gomsten island prison. His crime is unknown to other inmates. But those he calls friend know that his pride is that he stands strong to defend those he cares about."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          Your characters may be descended from{" "}
          <span style={{ color: "burlywood" }}>Alderlanders</span> that settled
          in Ravenland: either the Ailanders who came across The Divide first
          due to religious persecution, or an Alderlander who came later to help
          tame and settle Ravenland. Note that the{" "}
          <span style={{ color: "burlywood" }}>Ailanders</span>, although
          originally from Alderland, consider themselves the native human kin of
          Ravenland.
          <br />
          <br />
          Perhaps your ancestors came from{" "}
          <span style={{ color: "burlywood" }}>Aslene</span>, the mysterious
          land to the west known for its deserts and it's horse riders. Or,
          perhaps you are descended from kin that settled in the Bitter Reach
          long ago before the Blood Mist enveloped Ravenland; in which case you
          may be Aslenean, Alderlandean, or Ailanderan but consider the Bitter
          Reach your home.
          <ImageComponent
            image={lobo}
            caption="Lobo"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Lobo is an ADULT WOLFKIN FIGHTER.<br/><br/>Lobo was picked up for petty street crimes and taken to Gomsten Island. His punishment may have been more severe than average due to Alderlander prejudice against wolfkin."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <br />
          <br />
          To begin, it is assumed your characters speak Raventongue. However,
          due to imprisonment, you speak with a heavy Wyrmtongue accent. We will
          discuss Kin Language handout in game. And finally, your kin (or you)
          may be from Alderland itself, having come to the Bitter Reach to do
          trade and never having set foot in Ravenland (aka the second character
          who does not necessarily start in prison).
          <br />
          <br />
          In any case, you have been a prisoner on the island called{" "}
          <span style={{ color: "red" }}>
            Gomsten (GOM-sten) Island, the “Hidden Rock”
          </span>
          ; possibly for your entire life. Your ancestral parents were
          imprisoned here many years, or even generations ago, by the laws of
          Alderland for some hideous crime. Per their sentence at the time,
          their offspring (i.e. you) were doomed to live the life of a slave. Or
          maybe your parents were prisoners of war doomed to a life of
          servitude, for them and their children. Or perhaps you are one of the
          very few newer arrivals that got here by your own actions. Either way,
          there hasn’t been a new arrival in Gomsten for over five years. You’re
          curious, but the guards are mostly quiet.
          <br />
          <br />
          The prison complex on Gomsten is on a coastal area closed in by the
          ocean on one side and impassable mountains ringing the rest of the
          island. The habitable areas between the mountains and ocean include
          miles of alluvial delta soil, lots of farmable area, and some forested
          clumps.
          <br />
          <br />
          Your life has been spent farming and mining. Sometimes the prison
          gangs are working the lands of the alluvial fans from rivers cascading
          down the high, impassable mountains. Other times the gangs are led
          into the mountains to work deep, dark, and dangerous mines for
          precious stones and rock. You have been able to learn combat skills
          from hunting and secret practice, sometimes disguised as dancing
          (think capoeira or whatever you like; even make it part of your back
          story).
          <br />
          <br />
          Escape attempts are almost unheard of. Swimming the rough ocean
          surrounding the island is impossible. Rebellion or fighting back is
          met with death; and even if the guards should be overthrown; without a
          complicit vessel and captain docking at Gomsten, there is no where to
          go.
          <br />
          <br />
          Your prison gang has been farming foodstuffs and plucking treasure
          from the mountains for generations. All your life there have been
          ships carrying away food and precious stone every few months. Some of
          the ships are going to Alderland and some to The Silver Coast, which
          you have also heard called The Bitter Reach.
          <br />
          <br />
          You know most of the ships are from Alderland with the rare free
          vessel among them. Your captors are Alderlanders but they are mostly a
          quiet lot and have not told you much about Alderland or Ravenland. You
          have heard them complain; and the complainant is always told, “you
          could have been sent to work The Iron Lock and become a Silent. You
          still could be, so why don’t you practice by shutting up?” (they are
          apparently referring to some odious job in Ravenland but details are
          scarce).
          <br />
          <br />
          And so, a dreary but humane balance is maintained on Gomsten. A
          hundred or so prisoners; children shackled for the sins of their
          parents except for the newest arrivals that earned their spot in
          Gomsten themselves. However, it has been over 5 years since a new
          arrival and things continue day in and day out, each day like the day
          before it. Gomsten has a small library which enables you to choose
          general talents and so forth for your character including languages.
          Consider how you came to be on Gomsten. Are you a descendant of kin
          for whose original crime you must pay? Are you a fairly recent arrival
          that got here by making your own mistakes? See if your character
          history can inform your Pride and your Dark Secret.
          <br />
          <br />
          <a href="#pageTop">Back to Top</a>
        </div>
      ),
    },
  ];

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/forbidden-lands/fbl-page-background-blizzard.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.backgroundWidth = "100vw";
    document.body.backgroundHeight = "100vh";
    document.body.style.backgroundSize = "cover";
  }, []);

  return (
    <Container>
      {/* <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin" 
                    style={{position: 'float', float: 'right',width: '120px', height: '240px'}} marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=smokeraven-20&language=en_US&marketplace=amazon&region=US&placement=9189143701&asins=9189143701&linkId=d1384e0e6b9ef083fee35d7272a71f88&show_border=true&link_opens_in_new_window=true"></iframe> */}
      <Row>
        <Col>
          <div
            style={{
              backgroundColor: "transparent",
              fontFamily: "IM Fell Great Primer",
            }}
          >
            <div style={pageHeader}>
              <main role="main" className="inner cover text-center">
                <a href="#freedom-day" name="pageTop">
                  <img
                    src={logo}
                    style={{ maxWidth: "200px" }}
                    alt="Forbidden Lands Campaign"
                  />
                </a>
              </main>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title className="cardTitle-forbidden-lands  ">
                Players start with characters that are prisoners on the island
                of Gomsten.
                <br/>
                <small>
                  <br />☠ Session 01:{" "}
                  <a href="./forbidden-lands-session-01">
                    Freedom Day in Gomsten
                  </a>{" "}
                  ⇒
                </small>
              </Card.Title>
              <div>
                <ContentContainer data={section1Data} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default FBLSession00;
