import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import zoneWar from "../../../../assets/myz/zone-war-session-57.webp";
import drTheo from "../../../../assets/myz/zone-nightmare.jpg";
import quietBob from "../../../../assets/myz/ark-boss-quiet-bob.jpg";
import chromicus from "../../../../assets/myz/chromicus4-mimir-scar.jpg";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Zone War Dispatch",
    text: (
      <p>
        The Zone War kicks off with Bayazid and Chromicus fighting the
        Helldrivers couple of the year, Tony and Luke, just south of the
        Sunshine Plaza. The Sunshine Plaza forces whup the Helldrivers, taking
        out a unit of motorcycles. Tony and Luke retreat to the Helldrivers
        parking garage Ark further south as Chromicus and Bayazid move back into
        the Sunshine Plaza.
        <br />
        <br />
        Meanwhile, Gladhand leaves Noatun Estates to travel to the Saurians,
        ready to battle the perfidious Berke. Patrick, leader of the Helldrivers
        moves to the Diamond Center after The Chairman goes north to the Oracle
        of the Silver Egg.
        <br />
        <br />
        There is a minor emergency when the donor from the Nova Cult, Verter, is
        found having sex with a human ex-slave from the Diamond Center near the
        birthing area where Abbetina, pregnant with Verter's child, is housed.
        The zone monstrousity Dr. Theopolis, a sentient floating brain with
        organic and metallic matter and tentacles takes exception. He is
        overzealous in his role of protector of the Luminescent Child, and
        therefore overly protective of Abbetina who is pregnant with the child
        prophetized by the Nova Cult leader Plutonia.
        <br />
        <br />
        Smoke Raven intervenes as Dr. Theopolis is set to execute Verter and the
        offending citizen of the Plaza. Confronted with Smoke Raven's strong but
        reasoned protest, Dr. Theopolis offers Smoke a Sopihe's Choice; Smoke
        Raven can choose who dies. Smoke Raven launches into a MANIPULATION
        check with the help of Ghost Bird using his clairvoyance and convinces
        Dr. Theopolis to merely jail the offending lovers without killing
        anyone.
        <br />
        <br />
        Worried about losing a potenial ally and seeing a chance to possibly
        isolate and decimate The Chairman, Smoke Raven, Ghost Bird, and
        Chromicus head toward the Oracle of the Silver Egg. A roster of forces
        is left at the Sunshine Plaza, but without a proper leader since we were
        a player short this week. Before leaving, Chromicus tries to visit
        Abbetina, but Dr. Theopolis will not allow it. The only Plaza residents
        he will allow in the room are Fatima (who is fascinated about Abbetina's
        pregnancy since she herself is faced with this unknown blessing),
        Gladhand, and possibly Smoke Raven (who Dr. Theopolis has categoriezed
        as "the somewhat reasonable flesh bag"). Chromicus asks Dr. Theopolis to
        pass on his message which is a promise to Abbetina and Verter that they
        will be proteced while in the Plaza despite the raging war.
        <br />
        <br />
        Ghost Bird and Chromicus make it to the Silver Egg while Smoke Raven is
        midway on the road. As the players approach the Oracle of the Silver
        Egg, they hear tales from pilgrims on the road about Zakarya. He can
        foretell the future, he knows things, and he can even transport chosen
        ones directly to Eden! The Oracle of the Silver Egg is a landed
        (crashed?) rocket capsule in the middle of a swamp. There is a
        ramshackle camping ground for pilgrims next to a wooden walkway on
        stilts over the marsh that leads to the capsule. Mixed among the
        pilgrims and Guardians are some of the female fighters from the Diamond
        Center. They eye the PCs, remembering the{" "}
        <a
          href="./mutant-year-zero-session-28-anchortown"
          rel="noreferrer"
          target="_blank"
        >
          battle at the Diamond Center
        </a>
        . However, everyone is playing nice since they are here to win an ally.
        To either side of the walkway are small wooden cabins where the
        Guardians of the Egg live. They try to manage the incoming pilgrims and
        keep the peace. At the end of the walkway is a wooden deck butting up
        against the Silver Egg. On the deck is where the seer of the Silver Egg
        can be found. He is a minor prophet and right-hand man in charge of
        taking donations, giving prophecies, and even determining who is fit for
        a personal audience with Zakarya.
        <br />
        <br />
        Chromicus speaks with Guardian named Triska about making a donation.
        Chromicus points to the symbol of Mimir on the top of the Silver Egg and
        then points to the symbol of Mimir on his jumpsuit. "I am a Mimirian".
        Several other Guardians, a few pilgrims, and Scrunchie from the Diamond
        Center gather around. Everyone seems excited and/or wary that{" "}
        <b>a newcomer has arrived with the holy symbol on his chest</b>. What
        could this mean? Triska tells the PCs to see the seer on the platform
        outside the Silver Egg and stares agog as Chromicus and Ghost Bird walk
        toward the egg.
        <br />
        <br />
        As the PCs approach the seer, they say "we would like to make a
        donation". The umbrella twirls and the fat, bald head nods. They hear a
        low chuckle. "Well hello. They say old friends bring fresh happiness you
        lily-livered son of a bitches". The seer turns and the IL Gang is
        looking at none other than Quiet Bob, ex-Plaza boss and outcast since
        losing the &nbsp;
        <a
          href="./mutant-year-zero-session-18-19-20-anchortown"
          rel="noreferrer"
          target="_blank"
        >
          Civil War at the Sunshine Plaza
        </a>
        .
      </p>
    ),
    image: {
      imagePath: zoneWar,
      altText: `Zone War Wave 3 Update`,
      className: "imageLeft",
      imageCaption: "Zone War Wave 3",
    },
    image2: {
      imagePath: drTheo,
      altText: `Dr. Theopolis: a zone nightmare working as protector of the Luminescent Child for the Nova Cult`,
      className: "imageRight",
      imageCaption: "Dr. Theopolis",
    },
  },
  {
    title: "Cracking the Silver Egg",
    text: (
      <p>
        Quiet Bob, the Boss who took over in the Plaza when Loud Sue died,
        carries on the tradition of wise sayings preceded or followed with a
        random, Tourette-like insult. Chromicus feigns near joy seeing Bob who
        also seems happy and wants to "wash away the past into the murky swamp
        waters; I'm onto something good here." They exchange pleasantries.
        Chromicus points to his Mimir symbol and Bob thoughtfully says Zakarya
        will want a private audience with Chromicus. Chromicus gives Bob a can
        of Soda, compares umbrellas, and offers a hug. Quiet Bob engulfs
        Chromicus, the ex-Enforcer turned Slave, in his embrace and whispers in
        his ear, "Being deeply loved by someone gives you strength, while loving
        someone deeply gives you courage. Did you known that you
        vulture-chomping dingbat?" Chromicus then introduces Ghost Bird.
        <br />
        <br />
        Ghost Bird chats with Quiet Bob about each of their beliefs and origins.
        Chromicus approaches the Egg. The hatch opens with a hiss and Chromicus
        enters. As Chromicus climbs toward the top of the Silver Egg, the voice
        of Zakarya beckons him and a sphincter portal in the ladder tube is
        opened to allow Chromicus passage. In the command chamber Chromicus is
        faced with the wild-haired Zakarya. On Zakarya's shoulder is a small
        metal spider robot. Zakarya has one large, wide round eye and the other
        is squinted to a slit as if some facial spasm froze his face in that
        position. Chromicus notes how he too has one wide open eye. Zakarya says
        there is only one way to Eden and offers a gas mask to Chromicus.
        Chromicus takes the gas mask, says{" "}
        <span style={{ fontWeight: 600, color: "yellow" }}>
          "this one is for my friend Double Bird"
        </span>
        , and then Chromicus huffs the gas and we cut to Ghost Bird outside.
        <br />
        <br />
        While conversing with Bob, Ghost Bird notices a huge fish monster
        patrolling the scummy waters of the swamp. Ghost Bird gains Quiet Bob's
        permission to at least approach the Silver Egg. Quiet Bob sits at his
        desk and enters data into the donation and pilgrim ledger as{" "}
        <span style={{ fontWeight: 600, color: "yellowgreen" }}>
          Ghost Bird fires up his clairvoyance and touches the Silver Egg
        </span>
        . There is a tunnel-like rush of images: Ghost Bird sees an empty swamp;
        the capsule appears; Quiet Bob approaches the capsule with his rag-tag
        followers from the Sunshine Plaza; Zakarya waving from the open hatch of
        the Silver Egg; unfamilar faces breathing deeply while wearing a gas
        mask; and finally, vision of a dead body floating in the swamp near the
        capsule. As Ghost Bird, reels back from the vision, he notices that
        there really is a body floating in the swamp water near the capsule. As
        Ghost Bird says, "hey Bob, what is this dead body?" The fish monster
        grabs the body and rolls with it to drag it underwater. Just as the body
        sinks into the water Ghost Bird clearly sees{" "}
        <span style={{ fontWeight: 600, color: "blue" }}>
          the corpse is The Chairman from the Diamond Center
        </span>
        ! The fish and the body are gone as Quiet Bob turns and says, "what
        body?"
        <br />
        <br />
        Meanwhile, back inside the Silver Egg, Chromicus makes an ENDURE roll.
        The robot MULTI has descended to the floor and is running around
        Chromicus as though distressed. Chromicus can feel that he will succumb
        to the numbing sleep gas but as he stumbles about with tunnel-vision he
        knows he can try one thing before the darkness takes him. Chromicus
        pumps 5 mutation points into his INSECT WINGS. He can only do one point
        of wits damage to Zakarya but maybe the wing blast in tight quarters
        will be heard or trip a switch or something . . . Chromicus lets loose
        with his mutation. He gets a mutation-misfire. Just as Chromicus' vision
        goes dark from the vibration of his own wings and the echo of Zakarya
        cursing drifts toward him like a faraway echo, the misfire is rolled. It
        is a cosmetic mutation. The player decides what happens is the existing
        scar tissue on his face morphs and raises into a red scarification
        pattern that clearly markes his forehead with the symbol of Mimir.
        <br />
        <br />
        Outside the capsule, the hatch blasts open with a loud hiss. Ghost Bird
        knows something is wrong and darts inside. As Quiet Bob shouts to summon
        his guardians, Ghost Bird is blasted with rot-cleansing mist which in
        turn spills out of the hatch as the pilgrim camp stirs with amazement.
        Playing realistically, the player decides Ghost Bird has no way of
        knowing if Chromicus went down or up the ladder and so rolls to see
        which way Ghost Bird goes. Ghost Bird climbs! He finds the command
        module easily, following the mutterings and shouts of Zakarya. Ghost
        Bird sees Zakarya standing over Chromicus' body and the robot MULTI is
        on the ground nudging Chromicus' body as if concerned and trying to wake
        him up. Ghost bird rushes to assist Chromicus. Without knowing what ails
        his friend, he makes HEAL roll and successfully revives Chromicus.
        Zakarya dives at Chromicus wielding a chain knife. As they battle, Multi
        climbs up Chromicus' leg and heals 1 point of STR. Clearly the robot
        cannot talk but has a personality. It has been concerned about Zakarya's
        behavior but cannot do anything about it directly due to his repair-bot
        programming.
        <br />
        <br />
        Chromicus makes a COMPREHEND roll and manages to close the hatch to the
        Silver Egg, but not before Quiet Bob, three Guardians, and Scrunchie and
        Dink from the Diamond Center enter the capsule.
        <br />
        <br />
        Ghost Bird is pegging Zakarya with his slingshot and shouting that
        Zakarya has killed the chairman and is lying about sending people to
        Eden, loudly so the mutants and humans in the capsule at the bottom of
        the ladder can hear him. Zakarya's wide eye squinches and he goes
        berserk at Ghost Bird saying the truth out loud. He attacks Ghost Bird
        with the chain knife and breaks him, slicing his torso and leaving{" "}
        <span style={{ fontWeight: 600, color: "pink" }}>
          Ghost Bird with BROKEN RIBS
        </span>
        .
        <br />
        <br />
        The three Guardians enter the command module and begin attacking as
        well. Next, Scrunchie enters and shouts at the Guardians to calm down
        and wait a moment. The Chairman went into the Egg and has not yet
        exited; perhaps the Plaza scum are telling the truth?
        <br />
        <br />
        Next, Dink enters the room . . . he his more predjudice toward the PCs.
        He has the same random roll to determine his reaction and Dink, being
        the asshole he is and has always been, attacks Chromicus.{" "}
        <span style={{ fontWeight: 600, color: "pink" }}>
          Chromicus falls, broken with critical injury of BROKEN FINGERS
        </span>
        .
        <br />
        <br />
        Finally, Quiet Bob enters the comman module. Multi injects Chromicus
        with his 2nd and last heal fluid. With one point of strength, Chromicus
        stands and appears to Quiet Bob's reason as Zakarya shouts "I'm your
        prophet, these are all lies". Quiet Bob tells two of the Guardians to
        escort Scrunchie and Dink to leave the Egg. Then, he tells Chromicus to
        pick up Ghost Bird as Bob and the remaining Guardian are placing them
        under arrest pending an investigation. Chromicus drinks a soda and Bob
        pointedly says, "we will get them out of here mighty Zakarya, they have
        sullied your throne room enough; we need to leave NOW". With a look, as
        his speaks, Chromicus can see that the story of Zakarya killing people
        who have private audiences is a surprise. We rolled to see if Quiet Bob
        was in on the scam or not, and it turns out he believed the stories
        Zakarya told about having the means to transport true believers to Eden.
        <br />
        <br />
        As Chromicus emerges from the Silver Egg "under arrest", he walks tall
        and proud and he carries his friend Ghost Bird proudly. Pilgrims and
        Guardians can see that Chromicus has the mark of Mimir on his forehead.
        Triska is telling anyone who will listen, "he did NOT have the mark of
        Mimir when he arrived except on his clothing!! Now the holy symbol is on
        his forehead; it's a miracle!! What does it mean?" We wind down the
        session with the loosely articulated agreement that Quiet Bob can see
        which way the ball is bouncing. He will clearly see how Chromicus can be
        spun as the new prophet of the Silver Egg.{" "}
        <span style={{ fontWeight: 600, color: "yellow" }}>
          Quiet Bob and the Guardians join the Zone War as allies
        </span>
        . But . . . What will happen to Zakarya? What mysteries remain inside
        the Silver egg? And what does the next wave of the Zone War has in store
        for the Il Gang?
      </p>
    ),
    image: {
      imagePath: quietBob,
      altText: `Quiet Bob, perfidious ex-Plaza boss ostrasized after the Civil War, has found his joy as the seer for the Oracle of the Silver Egg`,
      className: "imageRight",
      imageCaption: "Quiet Bob is Back!",
    },
    image2: {
      imagePath: chromicus,
      altText: `Chromicus gains the mark of Mimir during the battle of the Silver Egg`,
      className: "imageLeft",
      imageCaption: "Chromicus with the Mark of Mimir",
    },
  },
  {
    title: "Wrapup",
    text: (
      <p>
        Ark rolls and project rolls are on hold during the Zone War.
        <br />
        <br />
        We will pick up with the search of the Silver Egg and next wave of Zone
        War.
        <br />
        <br />
        We did NOT do XP spend.
        <br />
        <br />
        <h4>CRITICAL INJURY ROUNDUP</h4>
        <ul>
          <li>
            Ghost Bird: BROKEN RIBS, non-lethal, healing time (untreated) 7
            days. -2 MOVE and FIGHT.
          </li>
          <li>
            Chromicus: BROKEN FINGERS, non-lethal, healing time (untreated) 6
            days. -1 SHOOT and FIGHT.
          </li>
        </ul>
        <br />
        <br />
        The bonus XP went to Chromicus and Ghost Bird with nary a discussion.
        Chromicus facing down Zakarya and choosing the Mimir symbol as cosmetic
        change was epic, and Ghost Bird's use of clairvoyance is once again rich
        and useful. Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        .
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
];

const MYZAnchortownSession56_57 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 56-57: Cracking the Silver Egg
                </h1>
                <p className="lead leadExtra">
                  The Zone War heats up with some interesting developments at
                  the Oracle of the Silver Egg.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession56_57;
