import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import stableGeniusPills from "../../../../assets/myz/stable-genius-pills.jpg";
import stableGeniusHologram from "../../../../assets/myz/remains-hologram-2.gif";
import chainKnife from "../../../../assets/myz/artifact-compendium-1-chain-knife.jpg";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Bookkeeping",
    text: (
      <p>
        05-29-0001:
        <br />
        <br />
        We did rolls for this session and next coming session (except for
        Project rolls and Ark Battle) to speed things up. Highlights are:
        <ul>
          <li>
            PARTY ROLL CALL: Gladhand, Chromicus, Smoke Raven, Double Bird.
          </li>
          <li>
            BITTERBEASTS: eggs hatched, more trained, and now extra become grub
          </li>
          <li>PROJECT ROLLS: catch up rolls next session</li>
          <li>
            PURE PLANT PATROL: there was a fight between Plaza and Pure Plant
            this time and one Pure Plant human and one Plaza mutant died.
          </li>
          <li>
            ARK REPUTATION EFFECT: 3 mutants arrived wanted to join Ark, and
            Helldrivers arrived for Ark Battle with Battle Level D6 + 2 (yet to
            be determined).
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: stableGeniusPills,
      altText: `Stable Genius Pills required additional rules to cover what happens if you swallow more than one`,
      className: "imageRight",
      imageCaption: "Stable Genius Pills",
    },
  },
  {
    title: "The Rotten Adventures of Double Bird",
    text: (
      <p>
        The IL Gang continues westward toward the RUST CASTLE. They find Chi Tzu
        and Altair in the next sector where they fled from the acid rain. The IL
        gang encounters a Zone River for the first time. We have homebrew rules
        for whether river is crossable and if it happens to have a scrap bridge,
        what happens when the bridge is crossed.
        <br />
        <br />
        A couple of sectors and easy river crossing later, Smoke Bird warns that
        the rain coming down coupled with the current sector geography puts them
        in danger of a flash flood. They find an isolated, abandoned RADIO
        STATION and decide to batten down in the building.
        <br />
        <br />
        The bitterbeasts are lashed to the radio tower on the raised cement pad;
        the battle bus Summer of Love is parked close to the building and the
        parking brake is set. The party works hard in the rain and mud to load a
        shopping cart with half the provisions (booze, grub, water, and bullets)
        so they won't loose everything if they should leave it in the bus and
        the bus washes away.
        <br />
        <br />
        Inside the radio station they brush aside a desiccated skeleton at the
        reception area and find a metal square on the counter. Clicking the
        square they are treated to the Clark Stanley Stable Genius Pills
        advertisement hologram. And just like the hologram they encounter in the
        gas station near Noatun Estate, they find another strong box with a
        marketing poster and a bottle of the pills.
        <br />
        <br />
        At this point, Double Bird remembers that he already has one pill.
        Together with the new bottle, there are a total of seven stable genius
        pills. Double Bird is sitting with NINE PERMANENT ROT and facing getting
        another rot soon based on time in the zone. Before exploring the RADIO
        STATION, Double Bird takes three stable genius pills in hand, and he
        kneels on the floor of the radio station lobby. He holds his hands in
        prayer and sings a song from his childhood, before he was sold into
        slavery. It is a sad lullaby that was sung to him as a child by what he
        remembers as his mother. Was there a mother? Perhaps it was the Elder or
        Boss Krao? It is certain, however, that it strikes to the heart of this
        warrior as he decides to gamble his life rather than wait for the Zone
        to take him.
        <br />
        <br />
        So, it turns out that the stable genius homebrew rules hadn't taken into
        account multiple pills at the same time. We decided, especially since
        the dangers for Double Bird were huge, that the unstable nature of the
        pills means each time pill(s) are taken there is a 50/50 chance the rot
        damage they cause stacks or not. Additionally, we decided that if the
        pill taker sustains a Rot Critical Injury, that there is a separate
        50/50 chance roll that the roll on the critical injury chart suffers a
        dump up in effect row for each additional pill taken. And finally, if
        the pill taker survives to the outcome of the pills (which is dicey for
        Double Bird who is, again, sitting at nine permanent rot), then we
        decided that D6 per pill is rolled and if ANY of the dice is a success,
        then the mutant is cured of all rot and the failure dice have no effect.
        With the clarification in place, Double Bird downs the pills. It goes
        like this:
        <ol>
          <li>
            <u>How Many Pills Taken?</u> Double Bird swallows three pills.
          </li>
          <li>
            <u>Is Rot Damage Stackable?</u> Double Bird rolls 6; it is NOT
            stackable.
          </li>
          <li>
            <u>How Much Rot Damage?</u> Double Bird rolls 3. So he takes two rot
            damage (he in effect takes only one and then is broken), and he will
            suffer stable fever for three hours.
          </li>
          <li>
            <u>How Much Trauma From Rot Damage?</u> Since getting a point of rot
            triggers rolls for rot damage, Double Bird rolls 10D6 for damage (9
            permanent points plus the one he just got). He takes three points of
            damage to STR.
          </li>
          <li>
            <u>Do Extra Pills Worsen Rot Critical Injury Roll?</u> Double Bird
            rolls 5; the extra pills will not make injury roll worse.
          </li>
          <li>
            <u>Roll Rot Critical Injury</u> Double Bird will suffer 9 bumps on
            the injury chart due to permanent rot, but at least the extra two
            pills won't make it even worse. He rolls low, and even with +9 rows
            his injury is CONFUSED AND DISORIENTED. It's non-lethal, causes -1
            WIT rolls for 2D6 days.
          </li>
          <li>
            <u>Roll Outcome</u> Double Bird rolls three dice for outcome due to
            taking three pills. He rolls 2, 2, 1. Therefore, due to the 1, he
            immediately has effect as if rot washed off which means he must roll
            D6 and on a failure he gains another permanent rot, which is death
            for Double Bird.
          </li>
          <li>
            <u>Roll Rot "Wash Off" Effect</u> Double Bird rolls a 4. The one rot
            he gained from the pills go away as the fever breaks and Double Bird
            is still alive.
          </li>
          <li>
            <u>Roll Mutation Gained</u> Double Bird emerges from the stable
            fever with ACID SPIT as a new mutation.
          </li>
        </ol>
        <br />
        <br />
        Exploring the radio station, Smoke Raven is convinced the artifact in
        this sector is in the basement. Since the basement is flooded, Gladhand
        offers to swim into the dark waters to search. After several long
        minutes he emerges with a CHAIN KNIFE artifact and successfully
        comprehends it for use.
        <br />
        <br />
        As the night continues, the IL Gang talks about the days ahead. Based on
        having to stay in the RADIO STATION over night because of the flash
        flood, they realize that everyone will get a rot point in the morning.
        Fearing that rot point, and then another before reaching the RUST
        CASTLE, double bird decides to take the rest of the pills!!!!
        <ol>
          <li>
            <u>How Many Pills Taken?</u> Double Bird swallows four pills (i.e.
            all the remaining pills).
          </li>
          <li>
            <u>Is Rot Damage Stackable?</u> Double Bird rolls 5; it is NOT
            stackable.
          </li>
          <li>
            <u>How Much Rot Damage?</u> Double Bird rolls 5. So he takes five
            rot damage (he in effect takes only one and then is broken again),
            and he will suffer stable fever (again) for five hours.
          </li>
          <li>
            <u>How Much Trauma From Rot Damage?</u> Since getting a point of rot
            triggers rolls for rot damage, Double Bird rolls 10D6 for damage (9
            permanent points plus the one he just got). He takes two points of
            damage to STR. This would break him, so he uses HUMAN PLANT to
            absorb a point of damage.
          </li>
          <li>
            <u>Do Extra Pills Worsen Rot Critical Injury Roll?</u> Double Bird
            rolls low; the extra pills WILL make injury roll worse.
          </li>
          <li>
            <u>Roll Rot Critical Injury</u> Double Bird will suffer 12 bumps on
            the injury chart due to permanent rot and extra pills taken. He
            rolls 31, which bumps up 12 rows to become: COMA. Coma is lethal, -1
            to Heal rolls, and he will die in THREE HOURS!!
          </li>
          <li>
            <u>Roll Outcome</u> Double Bird rolls four dice for outcome due to
            taking four pills. He rolls no successes and one failure. Therefore,
            due to the 1, he immediately has effect as if rot washed off which
            means he must roll D6 and on a failure he gains another permanent
            rot, which is death for Double Bird.
          </li>
          <li>
            <u>Roll Rot "Wash Off" Effect</u> Double Bird rolls and it is not a
            failure. The one rot he gained from the pills go away as the fever
            breaks and Double Bird is still alive, but in a coma.
          </li>
          <li>
            <u>Roll Mutation Gained</u> Double Bird emerges from the stable
            fever with ROT EATER as a new mutation.
          </li>
        </ol>
        At this point, Double Bird must receive a successful HEAL roll or die
        after all the crazy pill rolls because of the COMA!! Gladhand borrows
        the Anatomy book (+1 to HEAL) and succeeds!. He doesn't half the
        recovery time though, and does not want to push the roll or the gear
        dice will break the book. He hands the book to Chromicus who gives it a
        try and succeeds.
        <br />
        <br />
        Additional heal rolls half the heal time for both the CONFUSED and the
        COMA critical effects and they are both five days. So, in summary,
        Double Bird will be in a coma for five days (waking up morning of
        04-05-0001) as they travel to the RUST CASTLE.
        <br />
        <br />
        The funny thing is this: now that Double Bird has ROT EATER, he can
        always ask someone to hurt him (if he is not already hurt) and then
        spend a mutation point to eat one rot to heal himself. Therefore, in
        theory, he will NEVER have to worry about washing off that one rot and
        having it become permanent! BUT . . . it does mean, every time Double
        Bird takes a single rot point, he will have to roll 10D6 for damage, he
        will be broken and roll on the Rot Critical Injury chart. And so, if he
        doesn't suffer death on the Critical Injury Roll (which will always have
        a +9 to determie the effect roll) . . well, he will live on with
        whatever effects the critical injury roll gives but always avoid ever
        getting permanent rot point!! Beauty, they name is Double Bird. and
        healing thy name is Gladhand.
      </p>
    ),
    image: {
      imagePath: stableGeniusHologram,
      altText: `Stable Genius marketing hologram`,
      className: "imageLeft",
      imageCaption: "Stable Genius Hologram",
    },
  },

  {
    title: "Wrapup",
    text: (
      <p>
        Next session will start on in-game morning of 05-29-2021 with party in
        Sector D22, ready to continue toward the Rust Castle.
        <br />
        <br />
        Everyone will take a point of rot in the morning and we have to make
        sure healing is handled correctly and eating grub/water. Also, we will
        resolve project rolls for two sessions and outstanding Ark Battle with
        Helldrivers.
        <br />
        <br />
        XP award was three.
        <br />
        <br />
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        .
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: chainKnife,
      altText: `Chain Knife. Ginsu muttahfakka!!`,
      className: "imageLeft",
      imageCaption: "Chain Knife",
    },
  },
];

const MYZAnchortownSession35 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 35: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  The IL gang rides out a flood in an abandoned Radio Station.
                  Double-Bird doubles down on Stable Genius pills with startling
                  results!
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                {/*<Card.Title style={cardTitle}>Shootout at Burger Jim's, 03-27-0001 - 03-27-0001</Card.Title>*/}
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession35;
