import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import logo from "../../../../src/assets/forbidden-lands/forbidden-lands-logo.webp";
import sectionDivider from "../../../../src/assets/forbidden-lands/session04/bitter-reach-page-banner-session-04.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";
import "./forbidden-lands-styles.scss";

import ojja from "../../../../src/assets/forbidden-lands/session04/ojja.webp";
import obald from "../../../../src/assets/forbidden-lands/session04/obald-the-frozen.webp";
import panola from "../../../../src/assets/forbidden-lands/session04/panola.webp";
import roar from "../../../../src/assets/forbidden-lands/session04/roar.webp";
import turold from "../../../../src/assets/forbidden-lands/session04/turold-blood-eye.webp";
import holbenda from "../../../../src/assets/forbidden-lands/session04/holbenda.webp";
import balt from "../../../../src/assets/forbidden-lands/session04/balt.webp";
import murksho from "../../../../src/assets/forbidden-lands/session04/murksho.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "1",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Carter One",
  backgroundColor: "rgba(0, 0, 0, 1) ",
  color: "white",
  marginBottom: "15px",
};

const FBLSession04 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  const section1Data = [
    {
      title: "Love Knows No Bounds",
      anchor: "freedom-day",
      sectionTitleCssName: "sectionTitleFBL",
      text: (
        <div className="fblBodyText">
          <ImageComponent
            image={ojja}
            caption="Ojja"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Ojja is an orc trader from Frostwind Hold. She is unusual in her curiosity about other kin but it's serves her well as infamous peddler for the Frostwind orcs."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <ImageComponent
            image={obald}
            caption="Obald the Frozen"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="Obald is a venerable old sourdough seal hunter. <br/>He spends a lot of time at Cuss & Dang's trading stories for booze. Some say he hunts the coin of his audience more than seals in his old age. "
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <span style={{ color: "burlywood" }}>Lobo and Rog-Golar are on guard duty and break up a fight beteen Murksho and Balt outside Ogrin's tailor shop.</span>
          &nbsp;The two teenage ailanders are put in jail. 
          <br/><br/>
          After questioning them it is discovered Murksho is best friends with Roar of the fisherman's camp. Murksho was 
          shopping at Ogrin's for a dress on Roar's behalf. Balt, Iona's jilted suitor from the whaler camp, confronted Murksho. Balt claims the dress is for an 
          elopement forbidden by Iona's father Batu. 
          <br/><br/>
          Murksho is roughed up by Volle's guards more than Balt signifying Batu's influence in Northfall despite the 
          traditionally atagonistic feelings the guards have toward the rowdy whalers.
          <br/><br/>
          <ImageComponent
            image={panola}
            caption="Panola"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Panola is one of Marshall Volle's guards in Northfall. She was first met by Rog-Golar on guard patrol duty. <br/>Panola is a half-elf frailer of Alderlander descent. She is sullen most of the time, possibly due to whatever trauma caused her scarred face. <br/>She openly worships 'the god of the deep'.."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
               <ImageComponent
            image={roar}
            caption="Roar"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="Roar is a 16 year old Alderlander that lives with the fishermen in Northfall.<br/>He is in love with Iona from the whaler camp; a forbidden love causing more friction between the camps."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          
          
          
          
          Some other events include:
          <br/><br/>
          
            -- Rog-Golar on patrol with female, half-elf frailer named Panola. Rog wants to say hello to Ojja and when Panola complains about staying on duty patrolling the bearch, Rog says, <span style={{ color: "lightgreen" }}>"My legs are longer than yours, so you start and I will catch up."</span>
            <br/>
            -- Blisster tries to learn PATH OF THE KILLER 01 talent but fails and must try again in a different quarter day.
            <br/>
            
            -- Lobo hears from Marshall Volle that a goblin named Cloget visits Northfall every year for the Awakening Day festival. Cloget can teach Lobo DEFENDER talent. However, the party realizes that Rog-Golar knows DEFENDER and can be a teacher once both PCs spend same quarter day training.
            <br/>
            <ImageComponent
            image={murksho}
            caption="Murksho"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Murksho is a 14-year old Ailander that lives with the fishermen in Northfall.<br/>He is Roar's best friend."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
               <ImageComponent
            image={balt}
            caption="Balt"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="Balt is a 17-year old Ailander that lives with the whalers in Northfall.<br/>He is Roar's enemy and a jilted suitor of Iona."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
            -- Rog-Golar's quick drop-in and shout-out to Ojja in Hillis' trade shop bemuse the female peddler and she is sure to wonder about who Rog is and what he is all about.
            <br/>
            
            -- Blisster gains lore from Obald the Frozen at Cuss & Dang's tavern.
            <br/>
            -- Blisster tries to rob homes in the evening again but has less luck this time.
          
          
          <br/><a href="#pageTop">Back to Top</a>
          <br/><br/>
        </div>
      ),
    },
    {
      title: "Murder in Northfall",
      anchor: "freedom-day",
      sectionTitleCssName: "sectionTitleFBL",
      text: (
        <div className="fblBodyText">
          <ImageComponent
            image={holbenda}
            caption="Holbenda"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="Holbenda the Short is one of the only blacksmiths in the Bitter Reach. He has been in Northfall for years now."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
                <ImageComponent
            image={turold}
            caption="Turold Bloodeye"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Turold Bloodeye is a known rogue and rough female human with many crimes rumored to be her doing."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          As evening falls, the Northfall guard are called to a street near Holbenda's Smythe. <span style={{ color: "burlywood" }}>A dead female Alderlander is face down in the snow</span>, an oval of 
          frozen blood around her head. Folks in the street are gathered around as the PCs arrive to find Volle kneeling and examining the body. There is a shirtless 
          dwarf standing nearby seemingly oblivious of the cold. As the PCs canvas the crowd, Holbenda says says, <span style={{ color: "lightgreen" }}>"Aye, I know who did it. Turold Bloodeye as sure
          as I'm standin' here. She cut that lady down and fled toward the north gates; I saw it all from the window at my forge."</span>
          <br/><br/>Lobo immediatley gets Turold's scent and the PCs announce they will give chase. Precious minutes pass as Marshall Volle gets a dogsleed and 
          three sets of skis for the party to use in their pursuit. Additionally, a council of Alderlanders fearing the murder as an act against their kin offers 
          10 silver per PC for Turold delivered alive and 5 sp each for her body.
          <br/><br/>          
          Next session we will begin with the chase to track down Turold. And meanwhile we wonder how things will go between the fishermen and whaler camps as the 
          Awakening Day festival approaches.
      <br/>
          <a href="#pageTop">Back to Top</a>
          <br/><br/>
          <small>Attendees: Blisster, Lobo, Rog-Golar</small><br/>
          <small>Ending Datetime: 6am, Winterwane 41, 1165</small>
          <h5>XP</h5>
          <ul>
            <li>Session 1.0 (Blisster, Lobo, Rog-Golar)</li>
            <li>Legend: 1.0 (Frostwind Hold by Ojjato Blisster at Hillis' shop)</li>
            <li>Lore: 0.4 (Lay of the Land and Map of the Bitter Reach both by Obald the Frozen to Blisster at Cuss & Dangs)</li>
            <li>Hex: 1.0</li>
            <li>Pride: 1.0 (Blisster, Lobo (<span style={{ color: "red" }}>failed, no pride next session</span>), Rog-Golar)</li>
            <li>Treasure: 0.0</li>
            <li>Monster: 0.0</li>
            <li>Dark Secret: 0.0</li>
          </ul>
        </div>
      ),
    }
  ];

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/forbidden-lands/fbl-page-background-blizzard.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.backgroundWidth = "100vw";
    document.body.backgroundHeight = "100vh";
    document.body.style.backgroundSize = "cover";
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <div
            style={{
              backgroundColor: "transparent",
              fontFamily: "IM Fell Great Primer",
            }}
          >
            <div style={pageHeader}>
              <main role="main" className="inner cover text-center">
                <a href="#freedom-day" name="pageTop">
                  <img
                    src={logo}
                    style={{ maxWidth: "200px" }}
                    alt="Forbidden Lands Campaign"
                  />
                </a>
              </main>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title id="cardTitle-forbidden-lands" className="cardTitle-forbidden-lands  ">
              Our ex-convicts gather lore then gear up to chase murderer Turold Blood-Eye who is fleeing into the Bitter Reach.
                <br /><br/>
                <small>
                  ⇐ Session 03:{" "}
                  <a href="./forbidden-lands-session-03">
                    Winter Spirits & Tundra Walk
                  </a>{" "}
                  ☠{" "}
                  Session 05:{" "}
                  <a href="./forbidden-lands-session-05">
                    When Stars Fall
                  </a>{" "}
                  ⇒{" "}
                </small>
                
              </Card.Title>
              <div>
                <ContentContainer data={section1Data} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default FBLSession04;
