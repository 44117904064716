import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import logo from "../../../../src/assets/kids-on-bikes/logo-kids-on-bikes.webp";
import sectionDivider from "../../../../src/assets/kids-on-bikes/kids-on-bikes-page-banner.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";
import "./kids-on-bikes-styles.scss";

import map from "../../../../src/assets/kids-on-bikes/map-turaround-rock.webp";
import ajax from "../../../../src/assets/kids-on-bikes/kid-ajax-fairfield.webp";
import ariadne from "../../../../src/assets/kids-on-bikes/kid-ariadne-vale.webp";
import sheldon from "../../../../src/assets/kids-on-bikes/kid-sheldon-garcia.webp";
import cover from "../../../../src/assets/kids-on-bikes/session-00-cover.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "1",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Carter One",
  backgroundColor: "rgba(0, 0, 0, 1) ",
  color: "white",
  marginBottom: "15px",
};

const KOBTurnaroundRockSession00 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  const section1Data = [
    {
      title: "The Cast",
      sectionTitleCssName: "sectionTitleKOB",
      text: (
        <div className="kidsOnBikesBodyText">
          <ImageComponent
            image={ajax}
            caption="Ajax Fairfield"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Ajax Fairfield, age 14, is a Loner Weirdo from Turaround Rock, Oregon."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <ImageComponent
            image={cover}
            caption="Session 00"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="The Town of Turaround Rock, OR is formed with our kids: Ajax, Ariadne, and Sheldon.."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          Ajax, age 14, is a Loner Weirdo who started reading science fiction at
          an early age. He has predictions about technology and culture. He
          thinks he is smart but has odd interests, moves from subject to
          subject. He is inconsistently medicated for ADHD. He is a child of a
          single mother with an absent father. He does not like ordinary kid
          stuff like sports or video games.
          <br />
          <b>Strengths</b>: Rebellious, Intuitive, Skilled at Brains. <br />
          <i>Why do you like fending for yourself?</i>
          <br />
          Makes me feel safer.
          <br />
          <i>What part of cool kid life do you wish you had a little bit?</i>
          <br />
          Access to "hot girls".
          <br />
          Starts the game feeling <b>positive toward Ariadne</b>.{" "}
          <i>What about Ariadne makes you happy?</i> Her charm is clever.
          <br />
          Starts the game feeling <b>positive toward Sheldon</b>.{" "}
          <i>What would losing this character mean to you?</i> It would be loss
          of one of few people Ajax trusts.
          <hr className="hrKidsOnBikes" />
          <ImageComponent
            image={ariadne}
            caption="Ariadne Vale"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Ariadne Vale, age 13, is a Plastic Beauty from Turaround Rock, Oregon."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          Ariadne (air-ree-ADD-knee), age 13, is a Plastic Beauty who is
          secretly very insecure. She worries about being perceived as stupid,
          but worries more about manipulating to get her way with beauty and
          charm. Her insecurity surfaces as demanding and conceited talk and
          behavior. She has both parents and lives a middle-class life, but she
          somehow manages to feel things are "unfair" for her.
          <br />
          <b>Strengths</b>: Rebellious, Loyal, Lucky
          <br />
          <i>
            How does it feel to have others think that you're beautiful but
            terrible?
          </i>
          <br />
          Deeply distressing and it makes me feel bad about myself, but then I
          pivot to blaming "them" or feeling angry.
          <br />
          <i>What do you sacrifice to remain beautiful?</i>
          <br />
          Being close to people; having a good friend; being understood for who
          I really am.
          <br />
          Starts the game feeling <b>positive toward Ajax</b>.{" "}
          <i>What is your private nickname for Ajax and why?</i>
          Positive toward Ajax: private nickname for Ajax and why? Ariadne calls
          Ajax "Borax" since it's another brand soap name, and she lets Ajax
          call her "Addy".
          <br />
          Starts the game feeling <b>negative toward Sheldon</b>.{" "}
          <i>
            What roll did this character play in the worst day of your life?
          </i>{" "}
          He found me crying alone after my pet died and just ignored me.
          <hr className="hrKidsOnBikes" />
          <ImageComponent
            image={sheldon}
            caption="Sheldon Garcia"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Sheldon Garcia, age 13, is a Brilliant Mathtlete from Turaround Rock, Oregon."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          Sheldon, age 13, is a Brilliant Mathlete who is small for his age.
          Thick glasses but handsome when paid attention to. Fear of being a
          loser/failure. Father is unemployed/alcoholic; Sheldon does not want
          to turn into his father. Satisfaction from need to impress others.
          Always has his nose in a book of some kind.
          <br />
          <b>Strengths</b>: Rebellious, Unassuming, Skilled at Charm
          <br />.
          <i>
            Why do you get satisfaction from being good at math (or your other
            area of expertise)?
          </i>{" "}
          Satisfies my need to impress others.
          <br />
          <i>How does your social isolation manifest itself?</i> Always have my
          nose in a book of some kind.
          <br />
          Starts the game feeling <b>negative toward Ariadne</b>. Sure she is
          hiding own insecurity.
          <br />
          Starts the game feeling <b>positive towards Ajax</b>. He saved me from
          a bully.
        </div>
      ),
    },
    {
      title: "Turnaround Rock, Oregon",
      sectionTitleCssName: "sectionTitleKOB",
      text: (
        <div className="kidsOnBikesBodyText">
          <ImageComponent
            image={map}
            caption="Turnaround Rock"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Turnaround Rock is a coastal town in Oregon.   "
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          Everyone was excited to play Kids On Bikes. Since the game focuses on
          kids, it was important we discussed lines. Our group came up with:
          <ul>
            <li>No Sexual Violence</li>
            <li>Careful with Bullying</li>
            <li>
              No gratuitous or graphic killing of kids (eaten alive by monster
              fine, no serial killer)
            </li>
            <li>No Animal Cruelty</li>
          </ul>
          The town of Turnaround Rock was created using the Kids on Bikes core
          rules. It's a series of questions answered by the players in a
          round-robin fashion. The players create the setting!
          <br />
          <br />
          It is really fun to have agency beyond your character and have a say
          in creating the game world which your characters will then get to
          adventure in. Without further ado, here is the town write up from our
          GM Cobra Rex based on our answers to the game questions:
          <br />
          <br />
          <div className="divTitle">The History of Turnaround Rock</div>
          <p>
            Tunaround Rock in <b>Curry County</b> claims to be one of the oldest
            town sites on the Oregon coast, dating to 1855. It was established
            four years after <b>Port Orford</b>, a more prosperous town twenty
            miles to the south. The{" "}
            <b>
              Qua-toh-mah band of Athabascan-speaking Tututni people were the
              original inhabitants of the area
            </b>
            . In 1853, <b>Captain Elias Fielder</b>, emboldened by the success
            of Port Orford's establishment, tried to sail a small party of
            settlers and supplies to the area. The ship never made it, as a
            large, rocky landmass offshore, coupled with permanent eddies around
            the rock's ocean-facing side, conspired to draw the ship to its
            doom. Thanks to the expertise of Captain Fielder, however, the ship,{" "}
            <b>Fielder's Dream</b>, was able to ride an eddy and get shunted
            back out towards the ocean. They eventually landed at Port Orford
            and tried to transport the supplies by land along the coast. Once
            more, they were turned back, this time by the Qua-toh-mah, who drove
            them away.
            <br />
            <br />
            Fielder would not be deterred, though many of the original party
            decided they were done with the endeavor. It took him two years to
            get back. He sold Fielder's Dream and gathered a larger party in a
            number of smaller boats. While a larger ship would be unable to
            avoid the eddies, the smaller boats could navigate the coastline in
            a way that would keep them away from the dangerous area. When they
            arrived, they found the Qua-toh-mah village destroyed. The survivors
            raved about some beast that had done it, and{" "}
            <b>rumor spread among the settlers of a Wendigo</b>. Through the
            efforts of <b>Delores Talbot</b>, a nurse who had persisted through
            all the attempts to settle the location, the few surviving tribe
            members were nursed back to health and befriended. Many of the
            settlers wanted to call the place <b>Fielder's Dream</b>, but
            Fielder himself pushed for the name Turnaround Rock.
            <br />
            <br />
            Early industries included commercial fishing, gold mining, and
            attempts at lumbering and shipping. The lumber and shipping
            businesses were doomed to failure due to the difficulty in
            transporting lumber and goods by larger vessels. <b>The Rock</b>,
            the rocky landmass blocking the harbor, was unforgiving in its
            treatment of any large ships that came too close. This didn't
            stop&nbsp;<b>Filbert Postlethwaite</b> from making what was deemed a
            fool-hardy attempt at spearheading both industries. Postlethwaite
            arrived from New York in 1857 with a fortune and the inability to
            listen to reason, and left in 1859 a broken man searching for his
            way.
            <br />
            <br />
            In contrast, the fishing industry flourished, as smaller boats had
            an easier time of it and the waters surrounding the settlement were
            lush with sealife. Occasionally, a smaller boat would fall victim to
            The Rock. In 1889, the town felt as if it were under a curse. An
            extraordinary number of smaller vessels, captained by experienced
            seamen who knew the waters well and had been fishing them all their
            life, were pulled into The Rock and destroyed. The town fell into
            economic despair and a collective societal malaise. Even before the
            numerous deaths and the copious destruction of fishing vessels,
            the&nbsp;<b>yield from the sea had fallen</b>.
            <br />
            <br />
            One saving grace was the beauty of the place. Spared the logging
            that occurred elsewhere along the coast, Turnaround Rock boasted
            some of{" "}
            <b>the most dense and beautiful forested areas of old-growth</b>, as
            well as being home to beautiful views of the ocean. The eddies
            around The Rock also helped produce a by-product of awe-inspiring
            waves within protected coves. In 1890, famous American artist,{" "}
            <b>Lenia Callas</b>, moved to Turnaround Rock and built a gorgeous,
            if somewhat architecturally confusing, home along the coast. Callas
            ran an unofficial artists' retreat on her property, inviting friends
            from all over to visit and spend time painting, drawing, playing
            music, and pursuing their other arts.
            <br />
            <br />
            Turnaround Rock became known as an artists' destination, and not
            just at the Lenia Callas House. Word had spread of the beauty of the
            place, drawing tourists as well. The{" "}
            <b>boardwalk down at Fielder Beach</b>, just past downtown, still
            houses many art studios and galleries, while the end of the
            boardwalk sports the world-famous <b>Donalds' Seafood Restaurant</b>
            , known for its seafood, but famous for its assortment of geoduck
            recipes. Donalds' was established in 1855 by <b>Jared Donalds</b> in
            a small shack by the sea. The return of sealife in 1892 and the
            calming of The Rock's appetite for small vessels saw a resurgence in
            fishing that allowed Jared's grandson, <b>Jeremiah Donalds</b>, to
            build a much larger building on the same spot in 1915, now featuring
            a sign that still says, "Established in 1855 in conjunction with the
            establishment of Turnaround Rock."
            <br />
            <br />
            While a few fishing families left following the{" "}
            <b>disasters of 1889</b>, those who stayed formed a{" "}
            <b>brotherhood known as The Fishermen</b>. From 1889 to 1891, they
            seemed to do more drinking than fishing, but they supported one
            another and helped each other survive the hard times. In 1891, their
            founder, the man who had given a rousing speech to keep more of them
            from leaving town, none other than Elias Fielder himself, now around
            80 years old, had the appearance of losing his mind. He strode north
            out of town to Wallowa Beach, gaining lots of attention and concern
            as he removed all his clothing during the long walk, wading into the
            calm waters near the shore and standing in the light of the full
            moon with his arms raised. He tried to go out farther where the
            waves were larger, but he was pulled to safety. He then ran off into
            the night. During the ensuing search for him, he was seen taking a
            boat out towards The Rock. Still naked, he once again raised his
            arms up to the sky, then{" "}
            <b>allowed himself to be pulled in to his death</b>. It was
            confirmed that no one had seen him drinking, and some considered his
            death to be a sacrifice that led to the return of a successful
            fishing season.
            <br />
            <br />
            In the 1960's, the artistic nature of the community, as well as the
            incredible waves coming into protected coves, drew hippie artists
            and surfers to Turnaround Rock. This turned into a{" "}
            <b>hippie nudist colony on Wallowa Beach</b> that continues to exist
            to present day. Children are warned to stay away from there. There
            is some irony that this same site is where Elias Fielder waded naked
            into the sea. Wallowa Beach now has the additional nickname of&nbsp;
            <b>Gawker Beach</b>.
            <br />
            <br />
            Presently, <b>fishing remains the chief industry</b> of the area
            while
            <b>tourism and the active arts</b> community remain important as
            well. In addition, some dairying and other farming supports
            Turnaround Rock's sense of self-sufficiency.
          </p>
        </div>
      ),
    },
  ];

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/kids-on-bikes/kids-on-bikes-page-background.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <Container>
      {/* <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin" 
                    style={{position: 'float', float: 'right',width: '120px', height: '240px'}} marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=smokeraven-20&language=en_US&marketplace=amazon&region=US&placement=9189143701&asins=9189143701&linkId=d1384e0e6b9ef083fee35d7272a71f88&show_border=true&link_opens_in_new_window=true"></iframe> */}
      <Row>
        <Col>
          <div
            style={{
              backgroundColor: "transparent",
              fontFamily: "Carter One",
            }}
          >
            <main role="main" className="inner cover text-center">
              <img
                src={logo}
                style={{ maxWidth: "200px" }}
                alt="Kids on Bikes Turnaround Rock Campaign"
              />
            </main>
            <div style={pageHeader}>
              <h1 className="cover-heading-kids-on-bikes">
                Session 00: Turnaround Rock
              </h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title className="cardTitle-kids-on-bikes">
                The town of Turnaround Rock is created and our kids are cast.
              </Card.Title>
              <div>
                <ContentContainer data={section1Data} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default KOBTurnaroundRockSession00;
