import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";

const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "",
    text: (
      <p>
        Anchortown is a Mutant Year Zero campaign set in area that was once
        Anchorage, Alaska.
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
    },
  },
];

const MYZMap = () => {
  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../assets/myz/zone-map-anchortown.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div style={{ "margin-top": "100px", width: "300px", height: "50px" }}>
      <Container>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <Card.Body>
                <Card.Text>
                  <ContentContainer
                    data={section1Data}
                    style={{ float: "left !important" }}
                  />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZMap;
