import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import mbLogo from "../../../../src/assets/mork-borg/mork-borg-logo-horizontal.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import { useEffect } from "react";
import gallery from "../../../../src/assets/mork-borg/mork-borg-class-gallery.webp";

const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <p>
          There 23 lucky classes you can play in the Malum Mortis campaign for
          Mork Borg!
          <br />
          Twenty-three flavorful ways you can stumble through the Dying Lands
          before you die; gotta try 'em all.
          <br />
          <br />
          <div style={{ textAlign: "center" }}>
            💀{" "}
            <a style={{ color: "yellow" }} href="./mork-borg-classes-core">
              Core Classes Overview
            </a>{" "}
            💀{" "}
            <a
              style={{ color: "yellow" }}
              href="./mork-borg-classes-third-party"
            >
              Third Party Classes Overview
            </a>{" "}
            💀
          </div>
          <br />
          <img
            src={gallery}
            alt="Mork Borg Class Gallery"
            style={{ maxWidth: 1070 }}
          />
        </p>
      </div>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
];

const MBClassesGallery = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/mork-borg/bkgnd-01.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={mbLogo}
                  style={{ maxWidth: "200px" }}
                  alt="Mork Borg Malum Mortis"
                />
              </main>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <Card.Body>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MBClassesGallery;
