import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import BRSubNav from "./BRSubNav";
import bladeRunnerLogo from "../../../../src/assets/blade-runner/logo-blade-runner.webp";
import sectionDivider from "../../../../src/assets/blade-runner/blade-runner-section-divider-01.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import { useEffect } from "react";
import "./blade-runner-styles.scss";

import rules01 from "../../../../src/assets/blade-runner/rules-chapter-01-to-04.webp";
import rules02 from "../../../../src/assets/blade-runner/rules-chapter-05.webp";
import rules03 from "../../../../src/assets/blade-runner/rules-chapter-06.webp";
import rules04 from "../../../../src/assets/blade-runner/rules-chapter-07.webp";
import rules05 from "../../../../src/assets/blade-runner/rules-chapter-08.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const BRRules = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  const section1Data = [
    {
      title: "Blade Runner Player Rules (Chapter 01-04)",
      text: (
        <div class="bladeRunnerFont bladeRunnerTitleText">
          <a
            href="https://www.smokeraven.com/static/html/assets/blade-runner/core-book/BR_Core_Rules-01-to-04-Players-Guide.pdf"
            alt="Blade Runner Player Rules"
            target="_blank"
            rel="noopener noreferrer"
          >
            Blade Runner Player Rules
          </a>{" "}
          is where you and rules for playing the game. <br />
          <a
            href="https://www.smokeraven.com/static/html/assets/blade-runner/core-book/BR_Core_Rules-01-to-04-Players-Guide.pdf"
            alt="Blade Runner Player Rules"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              style={{ maxHeight: "300px" }}
              src={rules01}
              alt="Player Rules Chapter 01 to 04"
            />
          </a>
        </div>
      ),
    },
    {
      title: "Tale of Two Cities (Chapter 05)",
      text: (
        <div class="bladeRunnerFont bladeRunnerTitleText">
          <a
            href="https://www.smokeraven.com/static/html/assets/blade-runner/core-book/BR_Core_Rules-05-Tale-of_Two-Cities.pdf"
            alt="Blade Runner Tale of Two Cities"
            target="_blank"
            rel="noopener noreferrer"
          >
            Tale of Two Cities
          </a>{" "}
          : city sector breakdown, social structure, and city life details.
          Corporation, and more!
          <br />
          <a
            href="https://www.smokeraven.com/static/html/assets/blade-runner/core-book/BR_Core_Rules-05-Tale-of_Two-Cities.pdf"
            alt="Blade Runner Tale of Two Cities"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              style={{ maxHeight: "300px" }}
              src={rules02}
              alt="Tale of Two Cities (Chapter 05)"
            />
          </a>
        </div>
      ),
    },
    {
      title: "Powers That Be (Chapter 06) ",
      text: (
        <div class="bladeRunnerFont bladeRunnerTitleText">
          <a
            href="https://www.smokeraven.com/static/html/assets/blade-runner/core-book/BR_Core_Rules-06-Powers-That-Be.pdf"
            alt="Blade Runner Powers That Be"
            target="_blank"
            rel="noopener noreferrer"
          >
            Powers That Be
          </a>{" "}
          : agencies, media, gangs, Wallace Corporation, and the life of a
          replicant.
          <br />
          <a
            href="https://www.smokeraven.com/static/html/assets/blade-runner/core-book/BR_Core_Rules-06-Powers-That-Be.pdf"
            alt="Blade Runner Powers That Be"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              style={{ maxHeight: "300px" }}
              src={rules03}
              alt="Powers That Be (Chapter 06)"
            />
          </a>
        </div>
      ),
    },
    {
      title: "Working the Case (Chapter 07)",
      text: (
        <div class="bladeRunnerFont bladeRunnerTitleText">
          <a
            href="https://www.smokeraven.com/static/html/assets/blade-runner/core-book/BR_Core_Rules-07-Working-The-Case.pdf"
            alt="Blade Runner Working the Case"
            target="_blank"
            rel="noopener noreferrer"
          >
            Working the Case
          </a>{" "}
          : RepDetect structure, assets, reputation, and working the crime
          scene.
          <br />
          <a
            href="https://www.smokeraven.com/static/html/assets/blade-runner/core-book/BR_Core_Rules-07-Working-The-Case.pdf"
            alt="Blade Runner Working the Case"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              style={{ maxHeight: "300px" }}
              src={rules04}
              alt="Working the Case (Chapter 07)"
            />
          </a>
        </div>
      ),
    },
    {
      title: "Tools of the Trade (Chapter 08)",
      text: (
        <div class="bladeRunnerFont bladeRunnerTitleText">
          <a
            href="https://www.smokeraven.com/static/html/assets/blade-runner/core-book/BR_Core_Rules-08-Tools-of-the-Trade.pdf"
            alt="Blade Runner Tools of the Trade"
            target="_blank"
            rel="noopener noreferrer"
          >
            Tools of the Trade
          </a>{" "}
          : gear, armory, spinners, and more.
          <br />
          <a
            href="https://www.smokeraven.com/static/html/assets/blade-runner/core-book/BR_Core_Rules-08-Tools-of-the-Trade.pdf"
            alt="Blade Runner Tools of the Trade"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              style={{ maxHeight: "300px" }}
              src={rules05}
              alt="Tools of the Trade (Chapter 08)"
            />
          </a>
        </div>
      ),
    },
  ];

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/blade-runner/background-blade-runner-01.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <div
            style={{
              backgroundColor: "transparent",
              fontFamily: "Orbitron",
            }}
          >
            <main role="main" className="inner cover text-center">
              <img
                src={bladeRunnerLogo}
                style={{
                  maxWidth: "800px",
                  backgroundColor: "white",
                  borderRadius: "10px",
                }}
                alt="Blade Runner campaign"
              />
            </main>
            <div style={pageHeader}>
              <h1 className="cover-heading">Blade Runner Rules</h1>
              <p className="lead leadExtra">
                <BRSubNav />
              </p>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img
              src={sectionDivider}
              style={{ maxHeight: "100px" }}
              alt="section divider"
            />
            <Card.Body>
              <Card.Text>
                <ContentContainer data={section1Data} />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default BRRules;
