import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import wormSwarm from "../../../../assets/myz/worm-swarm.jpg";
import grit from "../../../../assets/myz/grit-portrait.jpg";
import lambda from "../../../../assets/myz/lambda-portrait.jpg";

const cardTitle = {
  paddingTop: 10,
  paddingLeft: 10,
  paddingBottom: 10,
  backgroundColor: "rgba(221, 117, 28, .7) ",
};
const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "",
    text: (
      <div>
        <p>
          Lutrell of the Pure Plant zone ghouls walks into the foyer of the
          control building with 11 cronies to negotiate. After some time, he
          dismisses all ghouls except two trusted guards. He tells the party
          that they can help him. For many years the zone ghouls have
          "worshipped" a semi-sentient worm swarm.
        </p>
        <p>
          The nightmarish swarm of maggot-mouthed leeches lives in a huge glass
          tank with leather bands in an area between four water tanks in the
          maze of rooms and corridors under the water resevoirs.
        </p>
        <p>
          The zone ghouls worship (i.e. appease) the swarm and sacrifice one of
          their people approximately every few weeks to keep it fed.
        </p>
        <p>
          Lutrell feels worship is a distraction from their duties and loss of
          people every few weeks pisses him off. It would be handy if the
          "demi-god" were to die in a way that couldn't be traced back to
          Lutrell.
        </p>
        <p>
          In return for the this "favor", Lutrell will return Rowbert, hand over
          Double Bird's twin brother Double Bird, give them all the water they
          can carry, and he promises a future visit to the Ark to discuss
          diplomatic relations between the Pure Plant and the Ark.
        </p>{" "}
      </div>
    ),
    image: {
      imagePath: grit,
      altText: `
        Grit was Vorhan’s Slave; then she took orders from Nelma mostly just to have someone to follow. 
        
        Grit is skinny and weak, and dreams of leaving the misery in the Ark. 
        
        After the dust settles at the Pure Plant she has her eyes set on Smoke Raven.`,
      className: "imageRight",
    },
  },
];
const section2Data = [
  {
    title: "",
    text: (
      <div>
        <p>
          The mutants ask the perfect question. "What is this things weakness?"
        </p>
        <p>
          Lutrell explains the worm swarm cannot be harmed except by fire and
          explosives. Since the mutants are in an industrial ruin manned by a
          group of people, it is ruled that they can use Jury-Rig to make a
          flamethrower and/or flaming bats (not normally available and not
          available in future unless similarly ruled).
        </p>
        <p>
          What follows is an epic montage scene of Skut and the wounded Lambda
          working hard with the all the scrap the party has and an additional
          pile of scrap delivered by Lutrell's men. They build:
        </p>
        <p>
          - flamethrower with three stunts: durable and +2 damage!!
          <br />
          - explosive charge (Blast Power 6)
          <br />- flaming bat (like regular bat but 2 damage and does not last
          past combat scene)
        </p>
        <p>
          Smoke Raven, Double Bird, Hulugu, and Jebe sneak into the maze of
          tunnels beneath the water resevoir. Subu stays back with Rowbert and
          Skut to guard Lambda in the control tower (i.e. Marcus was not able to
          play this session).
        </p>
        <p>
          Everyone successfully sneaks into position except Hulagu. Hulagu is
          stopped by the hand of a small child reaching out to grab his leg.
          Hulagu hisses intimidatingly, but the kid keeps yapping and wakes up
          his mom and dad. Hulagu exchanges blows with the dad, then flees as
          zone ghouls all start waking up.
        </p>
        <p>
          Just as the zone ghouls are closing in, the boy from the Sunshine
          Plaza attack the worm swarm.
          <br />
          <br />
          - Smoke Raven fires flamethrower. Zero successes with ELEVEN dice: he
          pushes, takes two damage and dishes two damage.
          <br />
          - Hulagu throws explosive charge perfectly into worm's container,
          blasting it apart.
          <br />
          - Worm swarm attacks Jebe as zone ghouls start screaming "they let it
          out!!!" and flee. The worm swarm misses.
          <br />
          - Double Bird slaps the wormy pile with flaming bat. He hits it so
          hard the flames go out on his bat, but not before doing damage.
          <br />- Smoke Raven, up on a metal mesh walkway, fires the
          flamethrower again and blasts the worm swarm to hell.
        </p>
      </div>
    ),
    image: {
      imagePath: wormSwarm,
      altText: ``,
      className: "imageRight",
    },
  },
];
const section3Data = [
  {
    title: "",
    text: (
      <div>
        <p>
          The gang travels back to the Ark with all the water they can carry
          (which is a hell of a lot).
        </p>
        <p>
          They forgo Find the Path and possible benefits to skip random
          encounter checks to get back to the Ark as morning dawns.
        </p>
        <p>
          Everyone washes off the rot from over a day out in the zone. Alas,{" "}
          <b>Jebe is striken with a permanent point of rot</b>.
        </p>
        <p>
          The Diary from the dead human woman in the trash hawk nest is turned
          in to the Order of the New Dawn and also the Children's Game is turned
          in, both **increasing the culture of the Ark**.
        </p>
        <p>
          Whilst the mutants were out, the randomly determined auto-pilot of
          projects saw two points accumulated for pig sty and temple of
          endurance, and none for zone wrestling.
        </p>
        <p>
          Session body count was 2D6 due to virulent parasitic fungus, but lucky
          only three died
        </p>
      </div>
    ),
    image: {
      imagePath: lambda,
      altText: `Lambda is a gearhead from Loud Sue's gang. Unlike some of Loud Sue's followers, she is very 
        clear-headed and practical, dedicated to doing things that make sense rather than mind games or empty 
        spiritual gestures.`,
      className: "imageRight",
    },
  },
];
const section4Data = [
  {
    title: "",
    text: (
      <div>
        <p>
          Next session starts in the ark early morning of 03-10-0001 (day added
          for rest/sleep).
        </p>
        <br />
        Check out Session Body Count at:{" "}
        <a href="./mutant-year-zero-anchortown-population-notable-events">
          SESSION BODY COUNT AND POPULATION
        </a>
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a href="./mutant-year-zero-anchortown-graveyard">
          Anchortown Graveyard to pay your respects
        </a>
        .
      </div>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageRight",
    },
  },
];

const MYZAnchortownSession05 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 05: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  Our mutants negotiate with Lutrell at the Pure Plant.
                  <br />
                  He wants the worm swarm that his fellow ghouls worship gone,
                  but it cannot look as though it was something he planned.
                  <br />
                  He engineers Double Bird the Slave "escaping" to help the
                  party sneak into the reservoirs and take out the swarm.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Title style={cardTitle}>
                  You Wash My Hands, I'll Wash Yours, 03-08-0001{" "}
                </Card.Title>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
                <Card.Title style={cardTitle}>
                  Where There's Smoke Raven, There's Fire
                </Card.Title>
                <Card.Text>
                  <ContentContainer data={section2Data} />
                </Card.Text>
                <Card.Title style={cardTitle}>
                  All's Well That Ends Well, 03-09-0001
                </Card.Title>
                <Card.Text>
                  <ContentContainer data={section3Data} />
                </Card.Text>
                <Card.Title style={cardTitle}>Executive Summary</Card.Title>
                <Card.Text>
                  <ContentContainer data={section4Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession05;
