import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import logo from "../../../../src/assets/forbidden-lands/forbidden-lands-logo.webp";
import sectionDivider from "../../../../src/assets/forbidden-lands/session13/bitter-reach-page-banner-session-13.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";
import "./forbidden-lands-styles.scss";

import cover from "../../../../src/assets/forbidden-lands/session13/fbl-session-13-cover.webp";
import legendFiveSeals from "../../../../src/assets/forbidden-lands/legend-tiles/legend-the-five-seals.webp";
import legendSunkenCity from "../../../../src/assets/forbidden-lands/legend-tiles/legend-the-sunken-city.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "1",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Carter One",
  backgroundColor: "rgba(0, 0, 0, 1) ",
  color: "white",
  marginBottom: "15px",
};

const FBLSession13 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body, isLegend, caption) => {
    setModalData({
      body: body,
      image: img,
      isLegend: isLegend,
      caption: caption,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  const section1Data = [
    {
      title: "The Fishing Contest",
      anchor: "freedom-day",
      sectionTitleCssName: "sectionTitleFBL",
      text: (
        <div className="fblBodyText">
          <ImageComponent
            image={cover}
            caption="The Five Seals"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Ingmar the Plump is met and treasure sold. A deal is struck
                regarding Ruenlocke. Wurda tells the legend of The Five Seals."
            isLegend={false}
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          The party this session is: Ibax, Lobo, Rog-Golar, and Stilak.
          <br />
          <br />
          <h2>Fishing Contest</h2>
          The party meanders over to the sign-up table for the fishing contest
          outside Ingmarg's trading post. There they{" "}
          <span style={{ color: "burlywood" }}>
            meet an unassuming ailander whaler named Roebuck
          </span>
          . Roebuck tells them the{" "}
          <span style={{ color: "lightblue" }}>Legend of the Sunken City</span>.
          He is open to having party go with him on his boat to fish near the
          Sunken City.
          <br />
          <br />
          <ImageComponent
            image={legendSunkenCity}
            caption="The Sunken City"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="The stars shine upon an ice-cold ocean, a mirror image of the glittering sky. Deep below
its surface lie ships and sailors, foundering for eternity, lured by the siren’s call. There,
the dead dream of a beacon to lead them home, away from the sunken city beneath the
ice.<br/><br/>
Sorcery alone allows bold adventurers to descend to this silent grave to reclaim its
treasures, but all that glimmers isn’t gold. A fisherman who cuts a hole in the ice above
the Sunken City can catch the biggest fish in the entire ocean, or even a Pikebeast if they
have an arm strong enough to pull it up"
            isLegend={true}
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          Then, having signed up for the fishing contest, the party is admitted
          to see Ingmarg after allowing Mouse the moose and their sled to be
          stored and guarded by Ingmarg's Black Guard.
          <br />
          <br />
          The interior of the trading post is plush by Northfall standards. They
          are led to small, hip-high hallways that lead toward the inner part of
          the building. After crawling through the{" "}
          <span style={{ color: "burlywood" }}>
            small hallways they emerge in a circular room with a hole in the
            roof
          </span>
          ; a sort of indoor yurt with corridors that make things difficult for
          any would be assassins or rogues.
          <br />
          <br />
          Sitting on a fur cushion at a low table is{" "}
          <span style={{ color: "burlywood" }}>
            Ingmarg the Plump, the most famous, avaricious, and powerful trader
            in the Bitter Reach
          </span>
          . Ingmarg is a large man, always dressed in expensive fur clothes,
          fingers adorned with rings made of amber, whale bone, silver, and
          gold. He is jovial and always tries to make a good deal out of every
          meeting. He laughes often but it always appears as a sneer. He has a
          smooth, flawless skin except for ailander tribal scarification that he
          keeps greased; a rare display of vanity for an Ailander.
          <br />
          <br />
          The party parlays with Ingmarg with the following points of interest:
          <ul>
            <li>
              He knows about the dagger Blisster stole in the night before the
              Awakening Day Festival. "It belongs to a friend of mine. A
              powerful friend from Alderland. Any help getting his Winter Elf
              dagger back helps not only him, but helps me as well. That is good
              for business."
            </li>
            <li>
              The party returns the dagger to Ingmarg who in turn will return it
              to Siqhoy al Faddah of Alderland. How they manage to convince the
              passed-out, party-animal Blisster that the dagger turned into a
              Winter Elf Shortsword is a story for next session.
            </li>
            <li>
              Ingmarg is VERY interested in the arable, for now, parcel of land
              near Ruenlocke. He proposes to help the party and to set up an
              independent trading post near the ruins as the Brothers of the
              Bitter Reach turn it into a stronghold.
            </li>
            <li>
              Ingmarg sends{" "}
              <span style={{ color: "burlywood" }}>
                quartermaster Chansomps
              </span>{" "}
              with several guards to Ruenlocke to setup a small, weather-port
              trading post.
            </li>
            <li>
              Chansomps will escort a{" "}
              <span style={{ color: "burlywood" }}>
                farmer named Tordung hired by the Brothers
              </span>{" "}
              so farming can begin in earnest.
            </li>
          </ul>
          <br />
          <br />
          <ImageComponent
            image={legendFiveSeals}
            caption="The Five Seals"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="Three thousand years ago the elven king Ferenblaud ruled a verdant kingdom that stretched from the coast all the way to the White Peaks. The kingdom was called Rodenvale and all was well. But the enemies of Ferenblaud
and the elves were many, and a pact betwixt five Demon Princes sealed the
fate of the kingdom.<br/><br/>
One night, these Princes of Filth stole the light of the stars and tamed the
four elements. With the power thus bestowed unto them, they called down
a frozen curse over the Winter King and his subjects. All the strongholds
and fortresses of Rodenvale were buried in snow, and an eternal winter
descended over the land.<br/><br/>
The Demon Princes then forged five artifacts that sealed the curse – the
Seals of Fire, Water, Wind, Earth and Stars – and then set five bestial
Guardians to watch over them.<br/><br/>
Beneath stone and ice, fools whirl about in a dance of death in the light of
Gibil’s burning visage. In her sunken tower crowned by fire, Abzu sings a
mournful lament over the fall of Rodenvale.<br/><br/>
With fangs full of poison, Ninhursag and her thousand spawn wait in a
chamber where the footfalls of giants echo, while Imdugud dances on winds
borne by traitorous servants. In the tower of the farseers Mul awaits, ready to
slay those who would disturb the wandering stars.<br/><br/>
Yet all is not lost. If the Seals are destroyed the curse will be broken, and the
living soul who shatters a Seal shall be filled by its power, all the better to
fight against the five Demon Princes that seek to prevent this.<br/><br/>
Thus, the Bitter Reach awaits Ferenblaud’s heir. For the one who breaks the
grasp of eternal winter and wakes this frozen land from its slumber, the one
who returns spring to these lands, will win Ferenblaud’s crown and rightfully rule over the risen kingdom."
            isLegend={true}
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          The session finishes with the party{" "}
          <span style={{ color: "burlywood" }}>meeting with Wurda</span> in the
          elven ruins outside the North Gate of Northfall near the cliffside.
          Wurda <span style={{ color: "burlywood" }}>trains Ibax</span> and
          tells the{" "}
          <span style={{ color: "lightblue" }}>legend of The Five Seals</span>.
          <br />
          <br />
          <small>Attendees: Rog-golar, Ibax, Stillak, Lobo.</small>
          <br />
          <small>Ending Datetime: 6am, Springrise 07, 1165</small>
          <h5>XP</h5>
          <ul>
            <li>Session 1.0</li>
            <li>
              Legend: 2.0 (Legend of the Sunken City told by Roebuck, Legend of
              The Five Seals told by Wurda.)
            </li>
            <li>Lore: 0.0</li>
            <li>Hex: 0.0</li>
            <li>Pride: 0.0</li>
            <li>Treasure: 0.0</li>
            <li>Monster: 0.0</li>
            <li>Dark Secret: 0.0</li>
          </ul>
          <a href="#pageTop">Back to Top</a>
        </div>
      ),
    },
  ];

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/forbidden-lands/fbl-page-background-blizzard.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.backgroundWidth = "100vw";
    document.body.backgroundHeight = "100vh";
    document.body.style.backgroundSize = "cover";
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <div
            style={{
              backgroundColor: "transparent",
              fontFamily: "IM Fell Great Primer",
            }}
          >
            <div style={pageHeader}>
              <main role="main" className="inner cover text-center">
                <a href="#freedom-day" name="pageTop">
                  <img
                    src={logo}
                    style={{ maxWidth: "200px" }}
                    alt="Forbidden Lands Campaign"
                  />
                </a>
              </main>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title
                id="cardTitle-forbidden-lands"
                className="cardTitle-forbidden-lands  "
              >
                Ingmar the Plump is met and treasure sold. A deal is struck
                regarding Ruenlocke. Wurda tells the legend of The Five Seals.
                <br />
                <br />
                <small>
                  ⇐ Session 12:{" "}
                  <a href="./forbidden-lands-session-12">Awakening Day</a>
                </small>
              </Card.Title>
              <div>
                <ContentContainer data={section1Data} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default FBLSession13;
