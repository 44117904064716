import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import logo from "../../../../src/assets/kids-on-bikes/logo-kids-on-bikes.webp";
import sectionDivider from "../../../../src/assets/kids-on-bikes/kids-on-bikes-page-banner.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";
import "./kids-on-bikes-styles.scss";

import cover from "../../../../src/assets/kids-on-bikes/session03/session-03-cover.webp";
import sheriff from "../../../../src/assets/kids-on-bikes/session03/sheriff-vic-roberts.webp";
import lily from "../../../../src/assets/kids-on-bikes/session03/deputy-lily-waters.webp";
import jonathan from "../../../../src/assets/kids-on-bikes/session03/jonathan-liu.webp";
import chun from "../../../../src/assets/kids-on-bikes/session03/chun-liu.webp";
import nancy from "../../../../src/assets/kids-on-bikes/session03/nancy-liu.webp";
import wendigo from "../../../../src/assets/kids-on-bikes/session03/wendigo.webp";
import founder from "../../../../src/assets/kids-on-bikes/session03/founders-statue.webp";
import spirit from "../../../../src/assets/kids-on-bikes/session03/the-spirit.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "1",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Carter One",
  backgroundColor: "rgba(0, 0, 0, 1) ",
  color: "white",
  marginBottom: "15px",
};

const KOBTurnaroundRockSession03 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  const section1Data = [
    {
      title: "DREAMSCAPE OF THE QUAH TOH MA",
      sectionTitleCssName: "sectionTitleKOB",
      text: (
        <div className="kidsOnBikesBodyText">
          <ImageComponent
            image={cover}
            caption="Session 03"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Meeting a Qua-toh-mah chieftain in the Dreamscape."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          Welcome back to Turnaround Rock, a sleepy little town on the Oregon
          coast, about 80 miles north of the California border. Here generations
          of families work the sea, a hard life made more difficult by The Rock,
          a large rocky island which keeps larger boats and ships from coming
          into the harbor. But there seem to be other forces at play. Perhaps
          things are not all as they seem. It is the night of Tuesday, April 11,
          and a storm is growing more menacing. We join our young heroes in a
          sparse beach bungalow where they have just freed ATF Agent Declan
          Collier and gotten Collier's gun from the hippie cultist known as
          Foster. Through the door come the two hippie guards, both a little
          addled from psychedelics but dangerous, nonetheless. Agent Collier
          holds his gun to them and shouts out a quip to the one holding
          Ariadne's copy of The Fellowship of the Ring, “Put down the Lord of
          the Rings!” Outside, the comatose body of Christopher Liu is in a
          strange lightning rod contraption and needs to be freed.
          <br />
          <br />
          Our session begins with one of the players recapping the previous
          session, before going right into the action in progress.
          <br />
          <br />
          Faced with the threat of the hippie thugs, Ajax makes a successful
          Grit roll to quickly pull his knife out of his bag. While Foster is
          still keeled over from Ariadne's kick to the groin, Ariadne heads for
          the door to try to go save Christopher. She fails her Flight roll and
          suddenly time stands still. The bungalow, the hippies, and the ATF
          agent all wisp away. They see that the spirit of Christopher has drawn
          them into the Dreamscape, their first experience of this. It tries to
          speak to them, but it still does not have its voice. They hear the hum
          and whine of electricity come from its mouth instead.
          <br />
          <br />
          <ImageComponent
            image={founder}
            caption="Founder Statue"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Statue of Elias Fielder, the founder of Turnaround Rock."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          Each player is asked to describe something they see, feel, or
          experience coming into the Dreamscape. Sheldon sees ghostly figures of
          what appear to be members of the Qua-toh-mah tribe. The GM expands
          that it is not only the tribe he sees, but all the people that have
          lived in Turnaround Rock back to pre- history. They shimmer in and out
          of view. Ariadne feels herself leaving her dream body and circling
          around the Dreamscape as if she is a bird that is part of the storm.
          Ajax reaches out towards Christopher and possibilities begin to
          coalesce. The three of them see three glowing flashes of light before
          them: white, blue, and green. They recall that the blue flash of light
          from Christopher healed the boy scouts but also caused confusion in
          them. They remember the green flash accompanied translocation and are
          filled with the knowledge that the white flash allows communication
          with the dead.
          <br />
          <br />
          Ariadne flies towards Christopher, hoping to commune more, but when
          she hits his ghostly form, she is unable to go farther. Her head is
          filled with buzzing. Sheldon pulls out the Wendigo carving he'd found
          pre-game at the Qua-toh-mah site. Gripping it, he reaches out to
          Christopher. The three of them know that they can now choose access to
          one of the three flashes. The players are being given access to the
          Powered Character game mechanic. This mechanic allows the players to
          collectively control a powered character to accomplish tasks and move
          the story forward (ex: imagine Eleven from Stranger Things and you
          understand the archetype of a powered character). In this case, the
          power of Christopher's spirit is accessed and flows through the kids,
          so it's a slight expansion of how Powered Characters work.
          <br />
          <br />
          After some discussion and group agreement, the powers (#1 and #2 in
          each “flash,” activated by Psychic Energy Tokens) and the strengths
          (#3) are divided up among the kids in the following manner:
          <br />
          <br />
          WHITE FLASH - Controlled by: Ajax
          <br />
          <ol>
            <li>Commune with the Dead ability (what can go wrong?)</li>
            <li>
              Manipulate Electricity ability (nothing can go wrong, can it?)
            </li>
            <li>
              Treasure Hunter: May spend 1 Adversity Token to find a useful item
              in your surroundings.
            </li>
          </ol>
          BLUE FLASH - Controlled by: Ariadne <br />
          <ol>
            <li>Heal Others ability (can cause confusion and memory loss)</li>
            <li>Rapid Reaction ability (this must be safe to use, right?)</li>
            <li>
               Intuitive: May spend 1 Adversity Token to ask the GM about your
              surroundings, an NPC, or the like. The GM must answer honestly.
            </li>
          </ol>
           GREEN FLASH - Controlled by: Sheldon <br />
          <ol>
            <li>
              Translocation ability (can send you to the wrong place or not move
              you at all)
            </li>
            <li>Dreamscape ability (there's no danger here, right?)</li>
            <li>
              Protective: Add +3 to rolls when defending one of your friends.
            </li>
          </ol>
          The spirit tries to speak for a third time as the kids exit the
          Dreamscape. All that comes out is an electronic garble; a mix of
          electrical fuzzy buzzing sounds and an 80's dialup modem connecting.
          <br />
          <br />
          Time moves normally again, and the kids are back in the real world, in
          the bungalow on Wallowa (Gawker) Beach. The storm still rages outside
          the bungalow. There is still a roaring bonfire on the beach, and
          hippies still run amok in some sort of strange ceremony, with
          Christopher's comatose body inside a metal box rigged with a lightning
          rod. The dark clouds coalescing over the beach seem to be almost
          alive, more than just a force of nature. It is almost as if it is some
          force trying to corral the electrical energy of the lightning, which
          doesn't quite want to comply.
          <br />
          <br />
          Ariadne is now able to run out the door. She runs towards the bonfire
          with a fire extinguisher that Ajax found and gave to her. Sheldon ties
          up the hippie guards and Foster, getting an amazing roll that allows
          him to make the knots very secure. He even finds gags. They don't need
          to worry about leaving anyone to guard these three, so Ajax and
          Sheldon bolt out of the bungalow along with Agent Declan Collier. The
          three of them head for the metal box with Christopher in it. Declan is
          held up by some hippies, who he fights skillfully, while Sheldon and
          Ajax both make it to the main ritual and the box. The hippie cult
          leaders, Seth and Clarissa, along with a gaggle of their followers,
          including Steve and others.
          <br />
          <br />
          Ariadne shoots the extinguisher at the fire, causing smoke to rise and
          confusion to follow among the surrounding hippies. At the box, Ajax
          grits his teeth and decides to use the powers to focus the lightning.
          He feels that the lightning wants to cooperate with him, almost as if
          there is a kinship and it wants to help. It seems the dark clouds in
          the sky are at war with the electricity while also trying to exert
          control over it, and Seth almost seems to be channeling this dark
          energy. Ajax focuses and uses the spirit's powers to direct it towards
          the hippies, particularly Seth and Steve. The lightning blasts Seth
          and Steve to the ground, seriously injuring Steve as the lightning
          shoots through him. Seth is knocked to the ground, which disrupts his
          ceremony and his connection to the dark force. The swirling dark storm
          cloud above begins to move off the beach and out over the water.
          <br />
          <br />
          Sheldon tries to open the box, but fails his Brains roll, learning
          that the box is welded shut. From the distance, they can hear sirens.
          Ariadne tries to run from the fire to the box, falling flat on her
          face and scraping her chin. In the confusion of the smoke, a hippie
          falls on top of her. She yells out to Sheldon who grabs the lightning
          rod and manages to rip it off the box. There is a hole underneath
          where the rod screwed in, and Sheldon can see Christopher's immobile
          body inside. The police sirens get closer and louder.
          <br />
          <br />
          The storm has died down, and the night air is calmer, but as the kids
          look up, they see that the dark cloud has not dissipated. Instead, it
          has moved across the sky over the water, towards The Rock. The light
          of the lighthouse is shining once again, having been repaired by the
          crew that went out the day before, but the dark cloud finally settles
          over the rocky mass like a blanket, engulfing that light in darkness.
          They watch it snuff out.
          <br />
          <br />
          <ImageComponent
            image={sheriff}
            caption="Sheriff Roberts"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Sheriff Vic Roberts of Turnaround Rock."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          A police car and a police SUV arrive with Sheriff Vic Roberts and
          Deputy Lily Waters. The half- extinguished bonfire still spits out
          smoke. Declan identifies himself as an ATF agent and assists the
          Sheriff and Deputy. Seth and Clarissa are arrested. Deputy Waters
          radios for ambulances to transport the wounded. A medic cleans up
          Ariadne's scraped chin. Steve is put into an ambulance and the hippies
          tied up in the bungalow, including Foster, are dealt with. Some of the
          other hippies escape into the surrounding area, but most are collected
          in some way or another.
          <br />
          <br />
          Deputy Lily Waters stays around after Sheriff Roberts has left the
          area. Also known as Little Rock, Deputy Waters is of Qua-toh-mah
          descent. Despite the attitudes of some of the harsher residents, Lily
          maintains a pleasant demeanor, secure in the knowledge that her
          dwarfism doesn&#39;t get in the way of her job. Harry Darkstar arrives
          and uses an acetylene torch to free Christopher's body from the welded
          metal box. Harry leaves after completing the job. While they wait for
          another ambulance to arrive and transport Christopher's body, they
          hear a familiar keening, supernatural screaming howl. A sudden loping
          horror charges out of the woods onto the beach. It is the first time
          that the kids have seen the creature clearly. In full view, they see
          that it is a wild wolflike humanoid with antlers, the Wendigo legend
          of the Qua-toh-mah tribe made manifest. They can't quite tell how tall
          it is because it is running on four legs, but it could stand as tall
          as 7 feet or more. The monster is racing straight for Christopher's
          body.
          <br />
          <br />
          Ariadne activates the translocation power which Ajax controls (any
          player can choose to use a Psychic Energy Point to activate a power
          controlled by one of the others, but then that other person gets to
          decide how to use the power without additional input from the
          activating player). Ajax decides to translocate the Wendigo “deep into
          the woods.” His roll fails. If it had been successful, the GM would
          have told him that he thought of a specific place in the woods and the
          beast was transported there. Since it failed, instead Ajax realizes
          that he had been thinking of a nebulous idea of woods, and that the
          ability can only work, and still sometimes might not, with a specific
          destination in mind that can be visualized because Ajax has been there
          before.
          <br />
          <br />
          Lily Waters begins to chant as the creature charges while Declan
          Collier raises his gun. The beast suddenly stops and turns its
          attention to her, starting to rise to its full height. The form of the
          Wendigo takes on a translucence and tendrils of smoky energy drift off
          its body, dispelling the Wendigo form and slowly revealing a human
          form underneath. The beast turns and runs back to the woods before
          anyone can discern who it might be. Agent Collier is too shocked to
          fire his weapon after it.
          <br />
          <br />
          “What was that?” he asks Deputy Waters.
          <br />
          <br />
          She replies, “We'll talk about it later. Meet me at the station and
          mention this to no one.”
          <br />
          <br />
          The kids ride with Deputy Lily Waters, loading their bikes into her
          SUV. She is a cheerful woman who excels as a law officer despite her
          diminutive stature.
          <br />
          <br />
          During the drive, Lily asks, “Any questions?”
          <br />
          <br />
          Sheldon says, “What was that thing?”
          <br />
          <br />
          Deputy Waters tells them, “There are forces at work here in Turnaround
          Rock, and players at work in service to those forces. Right now, there
          is an imbalance, and your friend, Christopher, currently seems to be
          at the center of this imbalance. That's why the Wendigo tried to
          attack him. You kids, like myself, seem especially attuned to these
          forces and events. The creature we saw is not the greater evil force,
          but a tool of that greater evil.”
          <br />
          <br />
          Ariadne asks, “Is that thing a man underneath? Who is it?”
          <br />
          <br />
          Lily replies, “I don't know who it is yet, but now they know who I
          am.”
          <br />
          <br />
          <ImageComponent
            image={wendigo}
            caption="Wendigo"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="The source of the keening, otherworldly screams is discovered; a Wendigo! Is it the wendigo from the Qua-toh-ma legends?"
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          When asked about her chanting, Lily says, “Those attuned to the powers
          here can sometimes use abilities. With the proper talisman, there can
          be greater success. For me, it is the poetry of my people. The words
          are simply poetry, but because they serve as my talisman and because I
          am attuned to the buzzing electricity of this place, I can use the
          poetry as my artifact or talisman.”
          <br />
          <br />
          Ajax's face lights up: “I can also feel this electricity you're
          talking about. Can I do something like that?”
          <br />
          <br />
          Lily responds, “if you can find the right tool and you feel the
          buzzing, which is attunement to good, then you can focus your powers .
          . . otherwise you might fall prey to darkness.”
          <br />
          <br />
          Sheldon asks, “Are we in danger? Does that thing know us now?”
          <br />
          <br />
          “It has to be someone in Turnaround Rock, so it probably knows you to
          some degree, but it may not be aware of your attunement.”
          <br />
          <br />
          As the kids ask more questions about what their artifact might be,
          Lily fields their questions as best she can and then says, “Oh, you
          kids! I don't know you well enough to know what your talisman could
          be. You'll have to figure that out for yourself.”
          <br />
          <br />
          The Coastal Nose Neighborhood is the closest to Gawker Beach, so
          Ariadne is dropped off first. It takes her awhile to get out of the
          car. She is crying and questioning her values, but she does mention
          the familiar sound of the modem before she leaves, referencing the
          Computer Lab at school.
          <br />
          <br />
          <ImageComponent
            image={lily}
            caption="Lily Waters"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Deputy Lily Waters. She is attuned to the forces of Turnaround Rock and helps the kids begin to understand the mysterious events."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          As Lily drives towards the Talbot Lake Neighborhood to drop Sheldon at
          his house, he finds himself grasping the carving of the Wendigo that
          he had found at the Qua-toh-mah site. The question arises about
          whether it is a good artifact or an artifact of evil. As Sheldon
          considers it, he concludes that it was used as a talisman of good to
          ward off the Wendigo, not to celebrate it.
          <br />
          <br />
          Sheldon says, “The Computer Lab.” to Ajax with a solemn nod as he
          leaves the police SUV and grabs his bike.
          <br />
          <br />
          Lily continues driving towards the Downtown Neighborhood, and Ajax
          pulls his deck of tarot cards out of his backpack. He tells Lily that
          he has a feeling this might have something to do with his talisman.
          “Could that be it?”
          <br />
          <br />
          “Oh, you kids,” Lily says again, “I don't' know. You'll have to
          discover that for yourself.”
          <br />
          <br />
          Before Ajax gets out of the vehicle, Deputy Waters cautions him that
          the kids shouldn't talk about what happened. “No one will believe you,
          and some might even think you're crazy. Don't worry. We'll talk
          again.”
          <br />
          <br />
          Ajax, whose fear is insanity, takes her words to heart.
        </div>
      ),
    },
    {
      title: "WHAT HAS CHANGED ABOUT YOU?",
      sectionTitleCssName: "sectionTitleKOB",
      text: (
        <div className="kidsOnBikesBodyText">
          So much has gone on with only the passing of a few days in Turnaround
          Rock, enough to change a person. Ariadne's questioning of herself
          dovetails nicely with the GM's plan to ask how the characters have
          changed because of these startling, scary, and exciting events. The
          players are also given the opportunity to switch two of their
          attributes if they can explain how it relates to these changes.
          <br />
          <br />
          All the kids feel more bonded to one another, and negative perceptions
          are changed to positive ones. Sheldon saw Ariadne's bravery, and
          Ariadne saw how Sheldon listened to her and was also brave.Ariadne
          switches her Charm (originally d20) with her Flight (originally d12)
          to reflect her crises of identity and loss of focus on charming others
          as her primary technique. She is now more aware, scared, and ready to
          run or hide if needed.
          <br />
          <br />
          Sheldon swaps his Flight (originally d12) and Grit (originally d10),
          more ready than he's been before to confront things. Meanwhile, Ajax
          keeps his attributes as given. He is excited about the discoveries,
          almost as if the supernatural events are finally giving credibility to
          his many theories and beliefs.
        </div>
      ),
    },
    {
      title: "THE NEXT DAY: WEDNESDAY, APRIL  12TH",
      sectionTitleCssName: "sectionTitleKOB",
      text: (
        <div className="kidsOnBikesBodyText">
          <ImageComponent
            image={jonathan}
            caption="Jonathan Liu"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Christopher Liu's dad Jonathan."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <ImageComponent
            image={chun}
            caption="Chun Liu"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="Christopher Liu's mom Chun."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <br />
          <br />
          The next day, the buzz around the school and town is the news of the
          hippie cult drug bust, as well as the news that Christopher has been
          found. Ajax, Sheldon, and Ariadne listen throughout the day to hear if
          they are mentioned in this news, but their involvement has been left
          out of the official story. Another part of this news is that Lisa
          Fielder was found living among the hippies and is now back with her
          grandmother, Carla Aimes Fielder. The kids recall their encounter with
          the older Fielder and her story about her missing granddaughter; they
          realize that Lisa Fielder is Moonflower.
          <br />
          <br />
          People are also talking, and sometimes, out of fear and confusion, not
          talking, about the strange occurrence of the thick, dark cloud that
          hangs heavy over The Rock and covers the lighthouse. Fishermen are
          wary of the ill tidings and the danger, with word being that no boats
          are leaving either marina today.
          <br />
          <br />
          Sheldon's paranoia starts to get the better of him throughout the day
          as he considers that anyone could be the human side of the Wendigo. He
          is wary and observant for any strange attention coming from anyone at
          school, be it students or teachers, but particularly the two bullies,
          Jack Melvin and Bixby Shetfeld. Could they be attuned to the evil side
          of things in Turnaround Rock or are they just bullies? He also recalls
          that Rodney Green is the only other person they know who has been able
          to see Christopher's spirit. While Rodney does not seem the type to be
          a player in a great evil, could he have them all fooled?
          <br />
          <br />
          <ImageComponent
            image={nancy}
            caption="Nancy Liu"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Christopher Liu's sister Nancy. She is 16 or 17; just a few years older than our protagonists."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          One thing that Ariadne, Ajax, and Sheldon notice throughout the day is
          that they are aware of where the spirit is, specifically that it is
          equidistant from all of them when they are apart. This also means that
          they have a new awareness of each other's locations. During the final
          class of the day, the Computer Lab that they all attend, the three
          kids see that the spirit has joined them in the classroom. Of course,
          no one else notices. During their time in the Dreamscape, they'd
          learned that Christopher wants his voice back. He had spoken to them
          three times with sounds related to electricity. The third time he'd
          sounded like a modem connecting, so they begin to research on one of
          the school computers.
          <br />
          <br />
          Sheldon is at the keyboard of a computer while the others gather
          around. Unsure of what to do, he uses Christopher's powers to activate
          the Dreamscape, specifically with the intent to look for friendly
          spirits. He has the option to go alone or to pull Ariadne and Ajax in
          with him. He chooses the latter, and all three feel time slow down as
          Sheldon's dream self reaches out and takes the hands of the dream
          selves of Ariadne and Ajax.
          <br />
          <br />
          Sheldon tries to talk to the dream spirits, but since he had
          specifically been looking for spirits, he is unable to communicate
          with them. It's the Dreamscape and it has rules, some having to do
          with intent, though those rules are also as changeable as dreams.
          Ajax, with his access to the power to commune with the dead, activates
          this power and searches for a dream spirit with whom he can speak. He
          sees a campfire. Reluctant to go into the woods in the dream, he is
          comforted by the fire in the meadow and approaches it. There is an old
          Qua-toh-mah elder sitting next to the fire. He is an ancient chieftain
          of the people from long ago.
          <br />
          <br />
          Ajax asks the Qua-toh-mah spirit, “Our friend is here but not here; he
          needs to tell us something. Can you help us give him back his voice?”
          <br />
          <br />
          The elder spots Christopher right away, “The one who is floating? Are
          you sure that is a person? There is something more to him and
          something less to him.”
          <br />
          <br />
          Ajax confirms that this is who he means, and questions what the elder
          means about something more to him and something less. The kids hear
          again about the energies of this place, though the elder uses a name
          for it that they have never heard. He speaks about the warring forces.
          As he does, a dream within a dream starts to occur. They see a series
          of images come pouring out of his head, taking shape above him. There
          is a vision of the Wendigo, or one like it, running through the
          Qua-toh-mah site, ravaging and killing the people. A thick dark cloud
          forms over the site, perhaps following the Wendigo, perhaps leading
          it. There is a clash and a boom as the yellow light of electric energy
          and power bashes into the dark cloud. The kids see a warrior raise a
          weapon, swing it, and cleave the Wendigo in half. The two energies
          above the site swirl together like a tornado composed of the two of
          them. They coalesce and are sucked out into the bay and down into The
          Rock itself.
          <br />
          <br />
          As Ajax continues to speak with the man, he can tell that the elder
          really wants to help them. The problem is that he doesn't know
          anything about some of the more 1980's modern terms that Ajax is
          using. He says, “There is someone new here. Perhaps he can help you.”
          <br />
          <br />
          Another man comes out of the woods and approaches the campfire. He
          looks familiar, yet not too familiar. They realize he looks a bit like
          Mr. Jackson, their Computer Lab teacher. This must be the spirit of
          his recently killed cousin.
          <br />
          <br />
          He says, “Where am I? How did I get here? I was out searching.” He
          sees Christopher's spirit. “I was searching for him, and then…”
          <br />
          <br />
          Again, a vision forms, this time sprouting wisp-like out of the head
          of Mr. Jackson's dead cousin. They see the cousin in the woods near
          Wallowa Beach. As he is about to break out of the woods and onto the
          beach, so unknowingly close to where Christopher's body was at the
          time, he is attacked by the beast, the Wendigo. It pummels him about,
          killing him with its massive claws.
          <br />
          <br />
          Ajax is sorry for what happened to him but assures him that
          Christopher is safe now. The only thing is that Christopher's spirit
          needs to find its voice. Ajax asks the dream version of the spirit of
          Mr. Jackson's cousin if he knows how to help get Christopher's voice
          back, making sure to tell him about the electricity and the modem
          sound.
          <br />
          <br />
          The dream spirit of Mr. Jackson's cousin says, “I wish my cousin were
          here. He would know what to do. He might tell you that the spirit
          needs to go into one of his computers. That might help in some way.”
          <br />
          <br />
          Suddenly, Sheldon feels Christopher's spirit start to pull away and
          out of the Dreamscape. Sheldon feels that he can either accept this
          pulling, or he can fight it to try to stay in the Dreamscape longer.
          He decides to accept, and he is pulled out of the Dreamscape, taking
          the other two kids with him. No time has passed in the classroom where
          Christopher's spirit form swirls around above them and then dives into
          the computer in front of them. The lights of the school flicker before
          normalizing again.
          <br />
          <br />
          Sheldon opens a text program on the computer and types, “Help us
          Christopher”. Then, words appear in response: “I need voice, I need
          sound, must coalesce.”
          <br />
          <br />
          Sheldon finds an accessibility option on the computer, the SAM
          program, or Software Automatic Mouth, a text-to-speech program.
          Ariadne holds out her copy of The Fellowship of the Ring. Sheldon
          types passages into the computer. The more the SAM program speaks the
          words, the stronger the spirit's ability to use electricity to create
          its own voice becomes.
          <br />
          <br />
          They watch as the energy of the spirit comes back out of the computer.
          It can now speak to them. Just as others couldn't see it, others also
          can't hear its speech. It says, “I don't know who I am? Who am I? What
          am I?”
          <br />
          <br />
          While the kids can hear Christopher, they know they will look crazy if
          they are speaking to the air. They consider talking as if they are
          whispering among themselves or speaking in code, but they decide
          instead to wait until after school and go somewhere more private. They
          are determined to show the spirit that it is Christopher Liu, and then
          they want to visit Christopher at the hospital. They head to a small
          park on the way to the hospital. At the park, Ajax pulls out the
          wallet he'd found at the boy scout campsite. From the wallet he takes
          the picture of Christopher with his family. He shows this to the
          ghost, which recognizes that its own form looks like the form of
          Christopher.
          <br />
          <br />
          Sheldon, still on high alert, has a sensation of being watched. As he
          scans around the park, he sees that Jack and Bixby are hiding behind a
          tree nearby. Bixby looks like he is preparing to charge at them while
          Jack is gathering rocks to throw. The bullies are not the type who
          will easily let go of their hurt pride from their encounter the day
          before.
          <br />
          <br />
          Ariadne shouts, “I'm gonna run over there and slap those rocks outta
          your hands!” She uses Christopher's Rapid Reaction power to run over
          and do just that, knocking the rocks from Jack's hand and kicking him
          in the family jewels. This has apparently become a signature move for
          her. She is back standing next to Ajax and Sheldon before the bullies
          fully understand what has happened. Jack is kneeling on the ground in
          pain. As he rises, he looks confused and terrified. Bixby is also
          bewildered and fearful of the unknown. The two leave immediately as
          fast as they can.
          <br />
          <br />
          Ariadne says, “If they talk about this, who is going to believe those
          jerks, right?”
          <br />
          <br />
          At the front desk of the hospital, the kids meet Carol Green, Rodney's
          older sister. They charm their way past her and get the room number
          for Christopher. In a bit of good luck, Ariadne's mom, Kelly Vale, is
          on the elevator on her way to Christopher's room and invites the kids
          to follow her. Ariadne says, “You know Sheldon and Ajax, right, Mom?
          You've heard me talk about them?” Dr. Vale says a halting, “Sure,”
          before addressing Sheldon as Ajax, a mistake quickly corrected by the
          kids.
          <br />
          <br />
          Outside Christopher's room the kids see his family: his father
          Jonathan, mom Chun Liu, and older sister Nancy Liu. The family has
          left their son's side for the time being, heading home to have dinner
          and then return. They stop and speak with Ariadne's mom, who turns out
          to be the doctor in charge of Christopher's case. When they ask if he
          will wake up, she cannot assure them that he will. She tells them that
          there is a lot that the medical sciences know about comas and a lot
          they don't. She reassures them that they are doing the right thing by
          being by his side and speaking with him. The voices and stories of
          family and friends could help him wake. The family thanks her and says
          they will return shortly.
          <br />
          <br />
          As they continue to the room, the group passes by another nurse,
          Sheldon's mom. She asks them what they're doing there, then tells
          Sheldon that he is a good kid and she's glad he is visiting his
          friend. She says that she will see him at home later.
          <br />
          <br />
          Doctor Kelly Vale takes the kids to the room with Christopher,
          repeating again that speaking to him can't hurt. Left alone in the
          room with Christopher's body, the kids wait until Ariadne's mom is far
          down the hall and then they close all the blinds and close the door to
          the room. They notice that there appears to be an energetic tether
          between the spirit and Christopher's body. Ajax again shows the spirit
          the photograph of the family. Ariadne encourages the spirit to sink
          into Christopher's body the way it sank into the computer. It attempts
          to do so, but when it encounters the boy scout's form, it is repelled,
          still tethered, bouncing back up into the air like a balloon on a
          string.
          <br />
          <br />
          Ariadne activates the blue flash of healing light on Christopher.
          Christopher begins to stir. As he stirs, the kids hear a snapping
          sound. At first, they think it might be the sound of the spirit
          snapping into Christopher's body. Instead, they see that the tether
          has snapped, severing the connection.
          <br />
          <br />
          Christopher wakes up and takes them in, saying each of their names and
          asking, “What happened? Where am I?”
          <br />
          <br />
          As for the spirit, it loses the form of Christopher, becoming more
          nebulous and changing shape. The untethered spirit becomes thinner,
          taller, more skeletal in facial appearance, and it crackles with a
          mild electrical energy. The kids realize that the spirit, or the
          energy, or the aspect, or whatever you might call it, is not
          Christopher. Christopher Liu is not the powered character. This being
          of energy is. And Christopher can't see it.
          <br />
          <br />
          <ImageComponent
            image={spirit}
            caption="The Spirit"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="The Spirit that has been inside Christopher Liu."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          As the three try to digest this new information, Kelly and Kreeger
          Vale walk into the room. At first, they are concerned that the door to
          the room was closed, but then they see that Christopher is awake. They
          burst into action, hitting the call button for a nurse to come help
          them. Sheldon's mom returns to help.
          <br />
          <br />
          As the doctors and the nurse check Christopher, Kelly Vale makes a
          motion to the kids that they should leave for now, and they do.
          <br />
          <br />
          They take the elevator down in some degree of shock. The electrical
          form that they had thought was Christopher Liu rides down with them.
          They still feel their attunement and connection to it. They still feel
          the power.
          <br />
          <br />
          As they leave the hospital, they see Felix Waite also leaving the
          hospital. Could it have only been a couple of days since they saw him
          come stumbling out of the woods near the Lower Tooth Beach Marina
          covered in blood and filled with confusion? So much had happened since
          then. They recall their earlier visit to the fish processing plant on
          Cannery Row and all the other events of the last few days and wonder
          what part Felix Waites might play in the strange turn of events
          occurring in Turnaround Rock.
          <br />
          <br />
          END OF SESSION
          <br />
          <br />
          Kids on Bikes was GMed and written by{" "}
          <a
            href="https://www.roblecrone.com/"
            rel="noreferrer"
            target="_blank"
          >
            CobraRex
          </a>
        </div>
      ),
    },
  ];

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/kids-on-bikes/kids-on-bikes-page-background.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <Container>
      {/* <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin"
                    style={{position: 'float', float: 'right',width: '120px', height: '240px'}} marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=smokeraven-20&language=en_US&marketplace=amazon&region=US&placement=9189143701&asins=9189143701&linkId=d1384e0e6b9ef083fee35d7272a71f88&show_border=true&link_opens_in_new_window=true"></iframe> */}
      <Row>
        <Col>
          <div
            style={{
              backgroundColor: "transparent",
              fontFamily: "Carter One",
            }}
          >
            <main role="main" className="inner cover text-center">
              <img
                src={logo}
                style={{ maxWidth: "200px" }}
                alt="Kids on Bikes Turnaround Rock Campaign"
              />
            </main>
            <div style={pageHeader}>
              <h1 className="cover-heading-kids-on-bikes">
                Session 03: Turnaround Rock
              </h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title className="cardTitle-kids-on-bikes">
                The wendigo attacks, Christopher awakens, and the kids now have
                a powered character on the team.
              </Card.Title>
              <div>
                <ContentContainer data={section1Data} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default KOBTurnaroundRockSession03;
