import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import logo from "../../../../src/assets/forbidden-lands/forbidden-lands-logo.webp";
import sectionDivider from "../../../../src/assets/forbidden-lands/campaign/bitter-reach-page-banner-wolfkin.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";
import "./forbidden-lands-styles.scss";
import howlscorn from "../../../../src/assets/forbidden-lands/campaign/images/howlscorn.webp";


const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "1",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Carter One",
  backgroundColor: "rgba(0, 0, 0, 1) ",
  color: "white",
  marginBottom: "15px",
};

const FBLWolfkin = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  const section1Data = [
    {
      title: "Wolfkin Signs & Rituals",
      anchor: "prisoners",
      sectionTitleCssName: "sectionTitleFBL",
      text: (
        <div className="fblBodyText">
          <ImageComponent
            image={howlscorn}
            caption="Howlscorn"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="The howlscorn is a sprial scratched into wood. It symbolizes that the recipient has been ostrasized from the pack. If packed with crushed flowers, then it means redemption is possible if council is met and satisfied."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          The wolfkin have a complex system of natural markings to communicate. The markings can be urine, claw marks, arrangements of objects, and so forth. A few examples:
          <br/><br/>
<b>Howlscorn</b>: A Howlscorn is a spiral carved into wood by a wolfkin's claw. It symbolizes that by decree of an Alpha, Beta, or Gamma that the recipient has been ostrasized from the pack. 
If they ever approach or walk the pack lands they risk death.
<br/>
A howlscorn with the spiral filled in by packed clusters of flowers means redemption is possible if the recipient brings it to the pack lands and sees the leadership council to state their desire to be a part of the pack again.
<br/>
The flowered howlscorn can be earned most often by submitting to ritual beating known as Lupiforgis.

<br/><br/>
<b>Lupiforgis</b>: ritual beating as punishment or to earn a Howlscorn. The severity of the offense determines of Lupiforgis is likely to be fatal or not, but in no case is it ever safe even when administered to younglings because only the strong survive in wolfkin culture.
<br/>
          <a href="#pageTop">Back to Top</a>
        </div>
      ),
    },
    {
      title: "Wolfkin Social Structure",
      anchor: "prisoners",
      sectionTitleCssName: "sectionTitleFBL",
      text: (
        <div className="fblBodyText">

<h4>Alpha Class (Leadership):</h4>
<ul>
<li>Alpha: The leader of the pack, responsible for making decisions that benefit the entire wolfkin society. The alpha's authority is respected and unquestioned.</li>
<li>Beta: The second-in-command, assisting the alpha in leadership duties and often leading in the alpha's absence.</li>
<li>Gamma: Trusted advisors to the alpha and beta, providing counsel and support in matters of governance and diplomacy.</li>
</ul>
<h4>Hunter Class (Providers):</h4>
<ul>
  <li>Stalker: Skilled hunters responsible for tracking and pursuing prey to ensure the pack's survival.</li>

  <li>Prowler: Stealthy hunters adept at ambushing prey and securing resources for the pack.</li>
  <li>Tracker: Experts in locating food sources and assessing the viability of hunting grounds.</li>
</ul>
<h4>Guardian Class (Protectors):</h4>
<ul>
  <li>Sentinel: Guardians tasked with defending the pack territory and its members from external threats.</li>
  <li>Warder: Protectors of the pack's den and young, ensuring their safety and security.</li>
  <li>Vigil: Scouts and sentries responsible for monitoring the surrounding environment for potential dangers.</li>
</ul>

<h4>Gatherer Class (Providers):</h4>
<ul>
  <li>Forager: Gatherers who collect plants, herbs, and other resources essential for the pack's well-being.</li>
  <li>Harvester: Specialists in harvesting and storing food supplies, ensuring the pack's provisions for lean times.</li>
  <li>Herbalist: Experts in identifying medicinal plants and treating injuries or illnesses within the pack.</li>
</ul>

<h4>Shaman Class (Spiritual Guides):</h4>
<ul>
  <li>Spiritcaller: Shamans who communicate with the spirits of nature and ancestors, guiding the pack with wisdom and insight.</li>
  <li>Dreamweaver: Shamans skilled in interpreting dreams and visions, providing guidance and prophecy to the pack.</li>
  <li>Healer: Shamans who specialize in spiritual healing, restoring balance and harmony within the pack through rituals and ceremonies.</li>
</ul>

          <a href="#pageTop">Back to Top</a>
        </div>
      ),
    },
  ];

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/forbidden-lands/fbl-page-background-blizzard.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.backgroundWidth = "100vw";
    document.body.backgroundHeight = "100vh";
    document.body.style.backgroundSize = "cover";
  }, []);

  return (
    <Container>
      {/* <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin" 
                    style={{position: 'float', float: 'right',width: '120px', height: '240px'}} marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=smokeraven-20&language=en_US&marketplace=amazon&region=US&placement=9189143701&asins=9189143701&linkId=d1384e0e6b9ef083fee35d7272a71f88&show_border=true&link_opens_in_new_window=true"></iframe> */}
      <Row>
        <Col>
          <div
            style={{
              backgroundColor: "transparent",
              fontFamily: "IM Fell Great Primer",
            }}
          >
            <div style={pageHeader}>
              <main role="main" className="inner cover text-center">
                <a href="#freedom-day" name="pageTop">
                  <img
                    src={logo}
                    style={{ maxWidth: "200px" }}
                    alt="Forbidden Lands Campaign"
                  />
                </a>
              </main>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title className="cardTitle-forbidden-lands  ">
                Here being a treastise of the wheretofore mysterious workings of the wolfkin.
                {/* <small>
                  <br />☠ Session 01:{" "}
                  <a href="./forbidden-lands-session-01">
                    Freedom Day in Gomsten
                  </a>{" "}
                  ⇒
                </small> */}
              </Card.Title>
              <div>
                <ContentContainer data={section1Data} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default FBLWolfkin;
