import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import fenhorn from "../../../../assets/myz/fenhorn.jpg";
import deathworm2 from "../../../../assets/myz/deathworm-2.png";
import protectiveSuit from "../../../../assets/myz/protective-suit.jpg";
import ashDesert1 from "../../../../assets/myz/ash-desert-1.jpg";
import wrench from "../../../../assets/myz/wrench.jpg";
import sodaStimulants from "../../../../assets/myz/soda-stimulants.jpg";
import subu from "../../../../assets/myz/ark-subu-token-in-memorium.jpg";

const cardTitle = {
  paddingTop: 10,
  paddingLeft: 10,
  paddingBottom: 10,
  backgroundColor: "rgba(221, 117, 28, .7) ",
};
const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Rules Clarification",
    text: (
      <p>
        During the session we encountered a couple of questions, including the
        issue of Inspire skill again. <br />
        Check out the semi-official errata which answers most questions.
      </p>
    ),
    image: {
      imagePath: fenhorn,
      altText: `Mutant Year Zero rules errata, mostly thanks to fenhorn on Friga Ligan forums.`,
      className: "imageLeft",
    },
  },
  {
    title: "Rules Corrections",
    text: (
      <p>
        We slipped and were making weapon damage decrease from gear failures,
        but the rule is that <b>Gear Bonus</b>
        is decreased for each failure.
        <br />
        <br />
        The other clarification thanks to the players was confusion around
        aiming being required. To clarify the combat maneuvers related to
        weapons:
        <ul>
          <li>
            Weapon must be drawn (maneuver). Note: house rule is mutants are
            carrying their primary weapon drawn when exploring the zone unless
            otherwise occupied climbing, searching, etc.
          </li>
          <li>
            Bows and slings must be <b>prepared</b> for each shot. Preparation
            is a maneuver.
          </li>
          <li>
            Guns must be <b>reloaded</b>. Reloading in a maneuver. Note:
            multi-barreled scrap guns can fire until all barrels are empty; Old
            World guns can fire until their chamber(s)/clip is empty.
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
    },
  },
  {
    title: "Projects and Zone Cooking",
    text: (
      <p>
        Active projects changed to Stables and Museum. Since all PCs where out
        of the Ark, rolls were made to progress projects with baseline boss
        attitudes. Results were 5 points for Stables, and 1 point for Museum.
        <br />
        <br />
        Before leaving camp in Sector D07, the party spent a day butchering four
        deathworms and Zone Cooking the hell out of them to get a total of 33
        units of rot-free grub. Yum!
      </p>
    ),
    image: {
      imagePath: deathworm2,
      altText: `Deathworm steak is fine dining when you are in the Zone.`,
      className: "imageRight",
    },
  },
];
const section2Data = [
  {
    title: "Sector D08 - Grasslands and Sector D09 - Ash Desert",
    text: (
      <p>
        03-13-0001: After finishing up deathworm cook off and resting for the
        evening with no encounters, the party explores Sector D08 - Marshlands.
        Nothing much here except a few bullets, broken old pavement, and dark
        clouds heralding a storm.
        <br />
        <br />
        03-14-0001: The marshlands gave way to ash desert in Sector D09. The
        gritty air and bleak landscape, while depressing and nearly featureless,
        did yield a flash of yellow in the shifting sand and grit that turned
        out to be a Protective Suit (artifiact) which Rowbert donned, much like
        the earlier suit they found at Valley of the Moon (overgrown park) in
        Sector D05 (see{" "}
        <a
          href="./mutant-year-zero-session-02-anchortown"
          rel="noreferrer"
          target="_blank"
        >
          Session 02
        </a>{" "}
        notes).
      </p>
    ),
    image: {
      imagePath: protectiveSuit,
      altText: `Protective Suit is good for not, you know, dying of rot in the Zone.`,
      className: "imageLeft",
    },
  },
  {
    title: "When It Rains, It Hurts: Acid Rain in Sector C10 - Ash Desert",
    text: (
      <p>
        03-14-0001: The Ash Desert continues. Midway through the Zone, acid rain
        begins to fall. The party makes the Move rolls needed to find a
        featureless, abandoned cement bunker. Nothing fancy but it keeps death
        away, so in the survival sense it's a palace. The party rests and heals
        overnight.
        <br />
        <br />
        As they are about to leave in the pale, sickly light of morning, Skut
        notices a hunk of metal that seems like it might be useful.
      </p>
    ),
    image: {
      imagePath: ashDesert1,
      altText: `Acid Rain in the ash desert is bad for your health.`,
      className: "imageRight",
    },
  },
  {
    title: "",
    text: (
      <p>
        With a successful Comprehend, Skut realizes he has found a Wrench
        (artifact). Gearhead score!
        <br />
        <br />
        Maxim mentions he would like the wrench as well. Skut tells Maxim, "I'll
        hold onto it for now and you can use it later; we'll share" . .. and
        with a successful Manipulation roll Maxim merely grumbles and hefts his
        gear from the ground with a grunt.Maxim mentions he would like the
        wrench as well. Skut tells Maxim, "I'll hold onto it for now and you can
        use it later; we'll share" . .. and with a successful Manipulation roll
        Maxim merely grumbles and hefts his gear from the ground with a grunt.
        <br />
        <br />
      </p>
    ),
    image: {
      imagePath: wrench,
      altText: `Make stuff and break heads with the wrench artifact.`,
      className: "imageLeft",
    },
  },
  {
    title: "Science!: Dilapidated Mansion in Sector C10 - Ash Desert",
    text: (
      <p>
        03-15-0001: This sector starts with icy cold rain, and then that rain
        becomes . . . you guessed it . . . more acid rain! The party knows to
        flee at the first drop and they do so. Everyone makes or shares a
        successful Move roll except Rowbert. With the helmet of his protective
        suit in hand and staring up into the rain, Rowbert looks like he's in
        trouble, but Smoke Raven the intrepid stalker pushes his roll for a
        stunt allowing him to double back and save our Chronicler from the rain,
        and himself.
        <br />
        <br />
        The party finds shelter in a dilapidated mansion. Smoke Raven uses his
        archaeology skill to identify the house as an informal science
        laboratory. He is explaining how these labs often had a basement where
        more equiptemant and/or fuel was kept when the floor suddenly collapses
        into a massive Sinkhole. With a +2 modification thanks to Smoke Raven's
        tour lecture, no one falls into the sinkhole. Jebe, Hulagu, and Skut
        climb down into the new hole carefully to explore and find a{" "}
        <b>Soda Can</b> (artifact) and a bottle of <b>Stimulants</b> (artifact).
        Apparently, scientists from the Old Age needed chemicals to stay alert.
      </p>
    ),
    image: {
      imagePath: sodaStimulants,
      altText: `Science requires a sharp mind and very little sleep.`,
      className: "imageRight",
    },
  },
  {
    title: "Tower of Ghouls: Skyscraper and Zone Ghouls in Sector B11",
    text: (
      <p>
        03-15-0001: Toward the afternoon, the party explores Sector B11 and they
        find a skyscraper that seems intact enough to allow someone to climb up
        and get a view of neighboring sectors. There is a ruined car, an
        overturned bus, and a third car in surprisingly good shape in the
        courtyard in front of the skyscraper
        <br />
        <br />
        Jebe sends Subu to investigate the car, then inside the building. Subu
        is attacked and suddenly the party is fighting Zone Ghouls;
        approximately 10 on the group and several sharpshooting from windows in
        the skyscraper.
        <br />
        <br />
        In the fight, Jebe goes down but gets back up with help with nothin
        worse than a Sprained Wrist.
        <br />
        <br />
        Bicycle chains are flying as the mutants battle the Zone Ghouls. Unlike
        the ghouls at the Pure Plant, these ghouls seem to be exactly the sort
        of mindless, slavering humanoids from the tales told by other stalkers.
        They are humans but the toxic Zone has fried any reason or humanity from
        their minds; they exist only to fight, kill and eat.
        <br />
        <br />
        Astrina goes down with a crushed face. She is mended and continues
        fighting as blood runs over her face.
        <br />
        Smoke Raven is roasting the upper floor with his flamethrower as Hulagu
        and Maxim return gunfire and the tide of the battle is turning. Then,
        one of the sharpshooting ghouls kills Jebe's hound Subu. Jebe let's a
        single tear roll as he steadfastly cleans up the remaining zone ghouls.
        <br />
        <br />
        One of the sharpshooters flees and the party besides to camp in the
        courtyard and first floor of the skyscraper with guards in case the
        ghoul or friends return.
      </p>
    ),
    image: {
      imagePath: subu,
      altText: `Jebe's faithful hound Subu goes down in the heat of battle. Doggie heaven has one more fighter now.`,
      className: "imageLeft",
    },
  },
];
const section3Data = [
  {
    title: "",
    text: (
      <p>
        We ended session having taken care of grub/water for healing and
        maintenance, rot points, and assigning XP points. <br />
        Maxim and Skut were protected from rot thanks to scrap suits, but the
        suits were not durable and are gone now. Rowbert's protective suit
        failed him and it took a point of damage (PR 5 now). Our guess is he
        forgot to put the helmet on again.
        <br />
        In true MYZ fashion,{" "}
        <b>
          Subu's life and sacrifice was honored by her transformation into four
          units of grub
        </b>
        .
        <br />
        <br />
        Next session starts morning of 03-16-0001.
        <br /> We will add mutation points, allow for XP spend, and then
        climbing the skyscraper is next on the agenda.
        <br />
        We will also review errata and clarifications.
        <br />
        Double Bird XP was at 6. I put it at 9 but let's verify that is
        accurate.
        <br />
        Finally: the summary of critical injuries is as follows (which includes
        Care rolls to half time):
        <br />
        <br />
        <ul>
          <li>Rowbert: finally healed from broken nose.</li>
          <li>
            Jebe: Sprained Wrist, -1 to Shoot and Fight. Will be healed Morning
            of 03-20-0001 (3 days).
          </li>
          <li>
            Maxim: Groin Hit (from session 06), 1 damage for every Force, Move,
            or Fight roll. Will be healed morning of 03-18-0001.
          </li>
          <li>
            Astrina: Crushed Face, -2 to Manipulate. Will be healed morning of
            03-21-0001 (4 days).
          </li>
        </ul>
        <br />
        Check out Session Body Count at:{" "}
        <a href="./mutant-year-zero-anchortown-population-notable-events">
          SESSION BODY COUNT AND POPULATION
        </a>
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a href="./mutant-year-zero-anchortown-graveyard">
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
    },
  },
];

const MYZAnchortownSession07 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 07: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  Our mutants continue to explore the Zone, trying to find the
                  amusement park Bianca said has medical supplies.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Title style={cardTitle}>
                  Housekeeping, 03-10-0001{" "}
                </Card.Title>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
                <Card.Title style={cardTitle}>
                  Exploring the Zone, 03-13-0001 to 03-15-0001
                </Card.Title>
                <Card.Text>
                  <ContentContainer data={section2Data} />
                </Card.Text>
                <Card.Title style={cardTitle}>Wrapup</Card.Title>
                <Card.Text>
                  <ContentContainer data={section3Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession07;
