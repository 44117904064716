import React from "react";
import "./image.styles.scss";
import { Filter } from "interweave";

const ImageComponent = ({
  image,
  cssClass,
  caption,
  handleClick,
  altText,
  modalBody,
  blurClass,
  isLegend,
}) => {
  if (!blurClass) {
  }
  if (!cssClass) {
    cssClass = "imageLeft";
  }
  if (!altText) {
    altText = caption;
  }
  const classNames = `hover01 ${cssClass}`;
  return (
    <div
      onClick={() => handleClick(image, modalBody, isLegend, caption)}
      className={classNames}
    >
      <figure>
        <img alt="altText" src={image} className={blurClass}></img>
        <div className="imageCaption">{caption}</div>
      </figure>
    </div>
  );
};
export default ImageComponent;
