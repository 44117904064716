import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import theBigPicture from "../../../../assets/myz/the-big-picture.jpg";
import conspiracy2 from "../../../../assets/myz/session-43-conspiracy-2.jpg";
import factions from "../../../../assets/myz/session-43-factions.jpg";
import conspiracy1 from "../../../../assets/myz/session-43-conspiracy.jpg";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Bookkeeping",
    text: (
      <p>
        06-12-0001, 6am:
        <br />
        <br />
        <ul>
          <li>Mutation points and XP spend</li>
          <li>Pure Patrol: mutants and humans fight, only d6 water.</li>
          <li>
            Ark Reputation: Ark Battle (we made it zone ghouls since that
            dovetailed with previous sessions ark threat roll). IL Gang
            destroyed Zone Ghouls.
          </li>
          <li>Ark Threat: Zone Ghouls</li>
        </ul>
        NOTE: Altair and Chi Tzu are housed at the Rust Castle along with Summer
        of Love bus.
        <br />
        <br />
        Critical Injury Roundup
        <ul>
          <li>
            SMOKE RAVEN has INTERNAL BLEEDING. Ends 06-16-0001. Effect: 1 damage
            for Force/Move/Fight.; Healing Time: 3 days
          </li>
          <li>
            GLADHAND has a CRUSHED FACE. Ends 06-25-0001. Effect: -2 to
            Manipulate; Healing Time: 12 days
          </li>
          <li>
            GHOST BIRD has CONCUSSION. Ends morning of 06-14-0001. Effect: -1 to
            Scout and Comprehend.
          </li>
          <li>
            ELLI has SPRAINED WRIST. Ends morning of 06-14-0001. Effect: -1 to
            Shoot and Fight.
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: conspiracy2,
      altText: ``,
      className: "imageRight",
      imageCaption: " ",
    },
  },
  {
    title: "Riding the Storm Out",
    text: (
      <p>
        On the Steelcrest, the IL Gang, Elli the Whopper, and Elwith the Mechie,
        ship captain, stand facing Captain Ossian, and seven scrap pirates. The
        pirates helped on heal rolls to escape the sinking Wrecker and everyone
        feels uncertain. FORD, the scrap pirate assigned to guard Berke, steps
        forward and tells Capt Ossian that in light of recent events Ford would
        like to join the Sunshine Plaza if they will have him. Boss Hulagu gives
        a thumbs up. With some MANIPULATION, Boss Hulagu gets THREE SCRAP
        PIRATES to join the Sunshine Plaza.
        <br />
        <br />
        Capt. Ossian asks to be put ashore with his remaining faithfuls on the
        scrap reef in this sector once the storm dies down. The IL Gang agrees.
        They set him ashore with 10 rot grub after finding out one of his
        pirates has ZONE COOK, and five water. Captain Ossian is grateful is if
        not an ally, is likely to be far less than an enemy going forward.
        <br />
        <br />
        The IL Gang explores the scrap reef and finds scrap. They have a fairly
        uneventful trip sailing back to the RUST CASTLE. A ROT STORM underlines
        the now deep conviction that travel in the Zone or on the Dead Blue Sea
        without protection is slow suicide as Double Bird showed us all.
      </p>
    ),
    image: {
      imagePath: factions,
      altText: `Nikkil of the RUST CASTLE breaks down the factions for the benefit of the IL Gang of the Sunshine Plaza`,
      className: "imageLeft",
      imageCaption: "Factions",
    },
  },
  {
    title: "The Big Picture",
    text: (
      <p>
        Cad the Whopper greets the IL Gang and the Steelcrest as they arrive.
        Sitting in the lounge, catching up, Gladhand and Smoke Raven wander over
        to a table with four acolytes of the Oracle of the Silver Egg. Hard
        information is scarce but they determine the central structure, the
        "egg", is made of an unusual metal and is/was like some kind of storage
        container or possible a repurposed vehicle. Gladhand convices the
        acolytes to consider visiting the Plaza. They surely hope to convert
        some Plaza mutants to the Silver Oracle, and the IL Gang hopes to
        bolster their population.
        <br />
        <br />
        Then, the IL Gang meets with Nikkil. They receive their reward for the
        SCUBA GEAR reclaimed from the Saurians in the West. More importantly
        than scrap and bullets, they get information. They get, THE BIG PICTURE:
        <ul>
          <li>
            <b>Factions</b>: all of the big or notable factions are broken down
            and discussed. At this time the IL Gang relationships are:
            <ul>
              <li>
                GOOD: Saurians, The Homestead, Noatun Estates, Pure Plant, and
                the Rust Castle.
              </li>
              <li>
                NEUTRAL (or unmet): Cage Island, Elta Cult, Oilfield of the
                Ancients, Oracle of the Silver Egg, Nova Cult, and the
                Fardrifters.
              </li>
              <li>BAD: Helldrivers, The Diamond Center</li>
            </ul>
          </li>
          <li>
            <b>Eden</b>: Nikkil lays out the six items needed to solve Eden
            mystery. The IL Gang has two of the six, two have unknown status and
            location, and the other two they know are likely at the Elta Cult
            and in Command Center Charlie.
          </li>
          <li>
            <b>The Enclaves</b>: Nikkil shares what little he knows about the
            Enclaves of the Old Age. Along with the events of this session, the
            gang begins to glean the people of the Old Age fucked up and that
            Noatun was one of the enclaves.
          </li>
          <li>
            <b>Command Center Charlie (aka CCC)</b>: CCC is a legendary military
            base on an island in the Dead Blue Sea. It is said to have
            impenetrable ground defenses that still operate since the Old Age
            and no one has ever entered the base.
            <ul>
              <li>
                <b>The Green Man</b>: Nikkil shares that there is a prisoner on
                Cage Island who is said to be unlike any mutant or human. He
                claims to be from the Old Age and to have lived all the years
                since. He is kept by the First Secretary as an oddity, but
                Nikkil is certain the Green Man would be a great help in
                approaching CCC.
              </li>
              <li>
                <b>Xerxes</b>: Nikkil also tells the gang about how the Oilfield
                of the Ancients has lost contact with the greater Noatun
                settlement (eg: not Noatun Estates but a settlement further
                afield and underwater or underground). Xerses might be convinced
                to help if he thinks CCC has the technology to help the clones
                re-establish contact with Noatun.
              </li>
            </ul>
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: theBigPicture,
      altText: `The Big Picture: Nikkil from the RUST CASTLE shares all he knows of the Zone, Factions, Eden, the Enclave Wars, and Command Center Charlie.`,
      className: "imageRight",
      imageCaption: "The Big Piture",
    },
  },
  {
    title: "Back Into the Dead Blue Sea",
    text: (
      <p>
        While preparing to voyage back into the Dead Blue Sea, the IL Gang talks
        with Gregoria, the trader from Cage Island. She thinks a trade
        relationships with the Sunshine Plaza would be compliment their
        relationship with the Rust Castle since the Plaza is further west.
        Gladhand and others discuss her sending a trade enjoy to the Plaza to
        start talks. Gladhand deftly explains the Plaza's sufferage and overall
        anti-slavery position. He positions the trade partnership as a way that
        older slaves could be given the chance to choose the Plaza. Since Cage
        Island does not murder or cannibalize their tired slaves like The
        Diamond Center, Gladhand proposes this as a win-win. She says the
        relationship would require discussion with Ephraim, the First Secretary
        of Cage Island. It is possible the bitterbeasts might be useful labor,
        or even simply cattle for food. The IL Gang is encouraged to bring a
        bitterbeast or at least an egg should they voyage to Cage Island.
        <br />
        <br />
        A deal is reached wherein the party will look for two suits of advanced
        scuba gear and an underwater welding torch once they breech Command
        Center Charlie (if they can). In return for their promise to find these
        items during their greater investigation of the mystery of Eden, Nikkil
        overrides Elwith's concerns and loans the party use of the Steelcrest as
        well as the services of bartender Theodore; not only an expert android
        drink pourer, but a battle robot once the proper appendages are
        attached.
        <br />
        <br />
        Major events at sea:
        <ul>
          <li>
            Encounter with a gorge whale stalking three castaways on a scrap
            reef. Once the whale reveals its abality to lumber above water,
            Smoke Raven orders the scrapflash cannon of Steelcrest loaded. He
            kills the whale with a single shot. Eager, and willing to join the
            gang, are three mutants: Sarge the Boss, Skye the Stalker, and
            Cashel the Enforcer.
          </li>
          <li>
            Oilfield of the Ancients: the party arrives in the middle of a
            terrible storm. They are given food and warmth on the oil derrick
            with the company of Xerxes' clones. Chromicus and Vanja find a
            connection and shower together. Perhaps the facial-scars and the
            feeling of being at the edge of the greater group is what they share
            in their hearts and sharing their bodies seems a natural adjunct. In
            the morning they travel to one of the other derricks and parlay with
            Xerxes. He tells them of the oil rig from Noatun that has not come
            for a long time and the lost communications in the past several
            weeks. he is clearly driven to "keep the oil flowing" with a
            fanatical devotion, almost to the exclusion of all else. He is
            convinced the party can use assistance as bayazid examines their
            radio and determines that Command Center Charlie is likely to have
            the technology that could help to reestablish communications with
            Noatun. Xerxes authorizes three clones to travel with the group.
            Ater some negociation, Vanja is assigned to the mission as well.
          </li>
          <li>
            Ghost Bird uses his clairvoyance with Xerxes' radio and has a vision
            of the long ago age of humans aboard the oil derrick, a vast
            explosion and a long time of empty loneliness, and then humans
            arriving at the derrick again to grow clones to man the platforms.
          </li>
          <li>
            Hulagu sees Gladhand's loquatious capabilities. The boss pulls him
            aside and says, "There once was a time I could talk a cat off a fish
            wagon. But now, I want you to be my mouthpiece." He gifts the
            sunglasses and the perfume to Gladhand. An elegant, but tactical
            gesture, as Hulagu's rampant mutation multiplication has left his
            attributes dangerously low (he currently has STR 3, AGI 2, WITS 2,
            EMP 2).
          </li>
          <li>
            During the neverending storm, SmokeRaven spots a clump of netting,
            debris and a boat engine in the violent waves. Hulagu helmsthe scrap
            davit for raising and lowering their canoe as Chromicus and Skype go
            down to retrieve the engiene. Hulagu accidentally knocks Skye into
            the ocean but she climbs back aboard without a hitch. Using a
            grappling hook, they manage to snag the engine and get it aboard the
            Steelcrest. With a small tweak by Bayazid, the engine is a working
            engine.
          </li>
          <li>
            Slavers with a broken boat hanging out on some floating containers
            are encountered. The have three companion slaves in chains. The IL
            Gang offers to trade for them. However, the Cage Island slaver goons
            say the slaves are earners and the gang couldn't afford them, and it
            would be the First Secretary's decision anyhow. In a smart bit of
            negociation, the gang offers the goons the engiene they found in
            return for an escort to Cage Island with an introduction to the
            First Secretary.
          </li>
          <li>
            The storm continues to rage as the Steelcrest follows the goons
            toward Cage Island. The goons unwittingly lead the Steelcrest into a
            huge patch of strangleweed writhing in the waves of the storm. The
            smell of the weed causes Gladhand, a couple of clones, several goons
            and two of the slaves to jump overboard. In the ensuing struggle
            Boss Hulagu does his signature frog leap and katana strike on the
            strangleweed. Then he quickly succumbs to the fatigue damage and
            sinks below the waves. The strangleweed is killed by those aboard
            the Steelcrest and Chromicus does a heroic flight with a fortuitous
            misfire that doubles his power, allowing him to fly and grab Boss
            Hulagu and get him back on the deck of the Steelcrest in one swift
            action. One of the goons and two of the slaves have died, but the
            convoy presses onward.
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: conspiracy1,
      altText: `As the Sunshine Plaza grows, with the spear tip of the IL Gang lancing the Zone and the Dead Blue Sea, the relationships betwixt the ark and the other factions grows in importance.`,
      className: "imageLeft",
      imageCaption: "Relationships",
    },
  },

  {
    title: "Wrapup",
    text: (
      <p>
        <br />
        <br />
        Next session will start on in-game in the early night hours of
        06-17-2021 with everyone on the Steelcrest, nearly at Cage Island.
        <br />
        <br />
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        .
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
];

const MYZAnchortownSession43_44 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 43-44: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  The Big Picture starts taking shape.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession43_44;
