import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import mbLogo from "../../../../src/assets/mork-borg/mork-borg-logo-horizontal.webp";
import sectionDivider from "../../../../src/assets/mork-borg/mork-borg-section-divider.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import { useEffect } from "react";

import betrayedPhantom from "../../../../src/assets/mork-borg/class-betrayed-phantom.webp";
import blightedMerman from "../../../../src/assets/mork-borg/class-blighted-merman.webp";
import catacombSaint from "../../../../src/assets/mork-borg/class-catacomb-saint.webp";
import cursedSkinwalker from "../../../../src/assets/mork-borg/class-cursed-skinwalker.webp";
import damnedBladeThrall from "../../../../src/assets/mork-borg/class-damned-blade-thrall.webp";
import deadGodsProphet from "../../../../src/assets/mork-borg/class-dead-gods-prophet.webp";
import divineHag from "../../../../src/assets/mork-borg/class-divine-hag.webp";
import exaltedGambler from "../../../../src/assets/mork-borg/class-exalted-gambler.webp";
import fanaticPhysician from "../../../../src/assets/mork-borg/class-fanatic-physician.webp";
import forlornPhilosopher from "../../../../src/assets/mork-borg/class-forlorn-philosopher.webp";
import fugitiveKnight from "../../../../src/assets/mork-borg/class-fugitive-knight.webp";
import paleOne from "../../../../src/assets/mork-borg/class-pale-one.webp";
import sacriligiousSongbird from "../../../../src/assets/mork-borg/class-sacriligious-songbird.webp";
import sinEater from "../../../../src/assets/mork-borg/class-sin-eater.webp";
import slappingBastard from "../../../../src/assets/mork-borg/class-slapping-bastard.webp";
import svampatare from "../../../../src/assets/mork-borg/class-mushroom-eater-svampatare.webp";
import theBellfounder from "../../../../src/assets/mork-borg/class-the-bellfounder.webp";


const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Betrayed Phantom",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <p>
          YOU DIED. Fallen on the battle field, your soul began to rot.
          <br />
          <br />
          Between the Earth and the beyond, SOMETHING REACHED OUT.
          <br />
          You took its bargain, and it took your body.
          <br />
          <br />
          NOW YOU ARE A SHADE, alive again but half-existent. Your second chance
          at life is denied you, and a wraith now wears your skin.
          <br />
          <br />
          <a
            href="https://theeldritchtomb.itch.io/betrayed-phantom"
            rel="noreferrer"
          >
            Available Here
          </a>
        </p>
      </div>
    ),
    image: {
      imagePath: betrayedPhantom,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "Blighted Merman",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <p>
          You are a creature of the ocean, no longer a blighted landlubber.
          <br />
          <br />
          Dragged into the Endless Sea by curse or witchery and spat out a
          twisted form, changed forever.
          <br />
          <br />
          What is left of your humanity is perverted by the tides and the
          salt-soaked waters that you now call your home.
          <br />
          <br />
          <a
            href="https://www.drivethrurpg.com/product/343223/Blighted-Merman--A-class-for-MORK-BORG"
            rel="noreferrer"
          >
            Available Here
          </a>
        </p>
        <br />
      </div>
    ),
    image: {
      imagePath: blightedMerman,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "Catacomb Saint",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <p>
          Anointed in ages past, these richly-adorned skeletons watch over the
          holy resting places and sacred relics of the One True Faith.
          <br />
          <br />
          Many have been branded apostates for irreconcilable differences in
          interpretation of scripture with current clergy, and more have been
          driven from their holy grounds, forced to wander the world.
          <br />
          <a
            href="https://imcclung.itch.io/catacomb-saint"
            rel="noreferrer"
          >
            Available Here
          </a>
        </p>
        <br />
        <br />
        <br />
      </div>
    ),
    image: {
      imagePath: catacombSaint,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "Cursed Skinwalker",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <p>
          You should have died, but something grabbed a hold of your soul as it
          seeped into the river of Death and sealed you once again in mortal
          flesh.
          <br />
          <br />
          Only this time the cage is not yours alone-in the currents of the
          afterlife your soul mingled with that of another dying creature,
          leaving you halved.
          <br />
          <br />
          Now you live a shared existence, locked in everlasting struggle
          between man and monster.
          <br />
          <br />
          You should have died.
          <br />
          <a
            href="https://www.drivethrurpg.com/product/305033/Cursed-Skinwalker--A-class-for-MORK-BORG"
            rel="noreferrer"
          >
            Available Here
          </a>
        </p>
      </div>
    ),
    image: {
      imagePath: cursedSkinwalker,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "Damned Blade Thrall",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <p>
          Taking it up was a folly you did not comprehend.
          <br />
          Your bones ache and your body is bent after so long shouldering this
          loathsome sword.
          <br />
          Despite your many attempts to smash or sunder it, the only thing left
          broken is your spirit.
          <br />
          Will even death free you from its bitter weight? No answer could bring
          comfort.
          <br />
          So still you heft the blade, or when too weary of its heavy fate, drag
          it yet unscathed across the cobbles and hard earth. &nbsp;
          <a
            href="https://nthdegree.itch.io/damned-blade-thrall-a-class-for-mork-borg"
            rel="noreferrer"
          >
            Available Here
          </a>
        </p>
      </div>
    ),
    image: {
      imagePath: damnedBladeThrall,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "Dead Gods Prophet",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <p>
          You hear voices in your head, telling you what to do.
          <br />
          Or at least you think you do.
          <br />
          <br />
          You are the prophet of a god; only your god is dead, slain by the
          Basilisk SHE within the wretched peaks of the Bergen Chrypt. <br />
          <br />
          Now no one wants to hear the words of your god. <br />
          <br />
          But you can still hear them. Day and night. Like hot wires through
          your brain.
          <br />
          <a
            href="https://morkborg.com/content/"
            rel="noreferrer"
          >
            Available Here
          </a>
        </p>
      </div>
    ),
    image: {
      imagePath: deadGodsProphet,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "Divine Hag (Cailleach)",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <p>
          The Cailleach is the Ancient Earth herself.
          <br />
          <br />
          She is the lichen-covered rocks and the mountain peaks. <br />
          <br />
          She is the bared earth covered with snow and frost. <br />
          <br />
          She is the Deep Ancestress, veiled by the passage of time. <br />
          <br />
          And you were born under her influences.
          <br />
          <a
            href="https://www.drivethrurpg.com/product/349184/An-Cailleach-An-Irish-Folklore-Class-for-Mork-Borg"
            rel="noreferrer"
          >
            Available Here
          </a>
        </p>
      </div>
    ),
    image: {
      imagePath: divineHag,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "Exalted Gambler",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <p>
          There are times where you could revel with the nobles. <br />
          <br />
          You were devious yet untroubled and cheerful - but then your
          remarkable luck had suddenly abandoned you and your world started to
          collapse. <br />
          <br />
          To pay off a debt, you signed an unholy pact with a BIZARRE BEING.
          It's ominous gaze is now persistent.
          <br />
          <a
            href="https://www.drivethrurpg.com/product/332162/Exalted-Gambler-A-class-for-MORK-BORG"
            rel="noreferrer"
          >
            Available Here
          </a>
        </p>
        <br />
      </div>
    ),
    image: {
      imagePath: exaltedGambler,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "Fanatic Physician",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <p>
          Scholar to some, sorcerer to others, there is no gray area in the
          reception to your essays. <br />
          <br />
          They are regarded as either monumental scientific progress, or
          heretical writings from a mad alchemist. <br />
          <br />
          You don't care, though. <br />
          <br />
          You just want to tinker with the human body and find its limits.
          <br />
        </p>
      </div>
    ),
    image: {
      imagePath: fanaticPhysician,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "Forlorn Philosopher",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <p>
          The road an unreasoning maze, every temple soaked in blood, each
          destination drenched in gloom; <br />
          you once thought that cold analysis might tame Fate itself, <br />
          now that dream of reason has decayed to shifting madness and
          <br />
          only the cold remains.
          <br />
          <a
            href="https://morkborg.com/content/"
            rel="noreferrer"
          >
            Available Here
          </a>
        </p>
        <br />
        <br />
      </div>
    ),
    image: {
      imagePath: forlornPhilosopher,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "Fugitive Knight",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <p>
          Bravery, nobility, justice & honor mean nothing to you. <br />
          <br />
          Through cowardice and treachery you slew a valiant knight to usurp his
          title, crest and name. <br />
          <br />
          Cloistered within dead man's enchanted plate, you need not fear being
          hunted like a dog again, as it shelters you from harm and hides your
          damned self from those seeking vengeance for those past crimes,
          unimaginable.
          <br />
          <a
            href="https://neonon.itch.io/mork-borg-fugitive-knight"
            rel="noreferrer"
          >
            Available Here
          </a>
        </p>
      </div>
    ),
    image: {
      imagePath: fugitiveKnight,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "Pale One",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <p>
          You lurk on the fringes of settlements, observing, perhaps judging,
          but rarely interacting. <br />
          <br />
          You are fundamentally other, and belong nowhere. <br />
          <br />
          You've had bouts of incoherent madness and self-destructive rages, but
          mostly you simply wait, in solitary resignation, for that which is
          inevitable.
          <br />
          <a
            href="https://morkborg.com/content/"
            rel="noreferrer"
          >
            Available Here
          </a>
        </p>
        <br />
        <br />
      </div>
    ),
    image: {
      imagePath: paleOne,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "Sacrilegious Songbird",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <p>
          Always the bringer of unwelcome sounds, your vocation as a bard left
          you destitute and disreputable...
          <br />
          until the rueful day when a being of impious character made you the
          deal of a lifetime.
          <br />
          <br />
          Your soul may be screaming, but your throat sings sweetly, and your
          purse ever sweeter. <br />
          <br />
          A small price to pay.
          <br />
          <a
            href="https://makedatanotlore.itch.io/sacrilegious-songbird"
            rel="noreferrer"
          >
            Available Here
          </a>
        </p>
        <br />
        <br />
      </div>
    ),
    image: {
      imagePath: sacriligiousSongbird,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "Sin Eater",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <p>
          <br />
          THE DEAD ARE CORRUPT. THE STOMACHS OF SIN EATERS PURIFY THEIR ROTTING
          FLESH.
          <br />
          The ghastly life of the poor sods who end up devouring the dead is
          never chosen, rather it is often a form of punishment handed down by
          fate. Theologians have debated about the qualities of these
          unfortunate souls who seem to be holiness garbed in disgusting
          wretchedness. Are they malign being warped by arcane chaos, do they
          belong to a cursed bloodline, or are they touched by some cruel god?
          <br />
          Many believe that their souls are eternally damned as the cost for
          forgiving those they eat. When a sin-eater dies, their bodies are
          salted and burned. Their remains are not allowed to be buried in the
          hallowed ground of most cemeteries. The two-headed basilisks of the
          one true faith abhor them. But from the lowest peasant to the grandest
          king, many call for them near the final end, so that they might find
          peace in the hereafter.
        </p>
      </div>
    ),
    image: {
      imagePath: sinEater,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "Slapping Bastard",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <p>
          A lifetime of hardship did this to you. <br />
          <br />
          Your mangled hands are rough, calloused mitts twisted and numbed to
          everything but bitter cold. <br />
          <br />
          You've only lived this long due to tenacity and luck.
          <br />
          <br />
          Though lumbering and unable to make a closed fist or grip anything
          smaller than a stout branch, you slap like a burlap sack full of
          rocks.
          <br />
          <a
            href="https://anadversary.itch.io/slapping-bastard"
            rel="noreferrer"
          >
            Available Here
          </a>
        </p>
      </div>
    ),
    image: {
      imagePath: slappingBastard,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "Svampatare (Mushroom Eater)",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <p>
          You once had a noble family, then you found the blood-red mushrooms in
          your garden. <br />
          <br />
          Now you wander the world, gathering and consuming the sacred fungi.{" "}
          <br />
          <br />
          Your mind is scrambled but your soul is...also scrambled.
          <br />
          <a
            href="https://www.drivethrurpg.com/product/343569/Svampatare-The-Mushroom-Eater--A-Mork-Borg-Class"
            rel="noreferrer"
          >
            Available Here
          </a>
        </p>
        <br />
        <br />
        <br />
      </div>
    ),
    image: {
      imagePath: svampatare,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "The Bellfounder",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <p>
          You have heard the death knell of the world. <br />
          The foundry was your escape, until you found the Bells. <br />
          Their resonance has the power to change everything. <br />
          You know it. <br />
          Even the power to change you...&nbsp;
          <a
            href="https://www.drivethrurpg.com/product/345462/The-Bellfounder-Class-ThirdParty-Mork-Borg-Publication"
            rel="noreferrer"
          >
            Available Here
          </a>
        </p>
        <br />
        <br />
      </div>
    ),
    image: {
      imagePath: theBellfounder,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
];

const MBClassesThirdParty = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/mork-borg/bkgnd-01.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={mbLogo}
                  style={{ maxWidth: "200px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">Mork Borg: Core Classes</h1>
                <p className="lead leadExtra">
                  <div
                    class="morkBorgFont morkBorgText"
                    style={{ textAlign: "center" }}
                  >
                    💀{" "}
                    <a
                      style={{ color: "yellow" }}
                      href="./mork-borg-classes-gallery"
                    >
                      Class Gallery
                    </a>{" "}
                    💀{" "}
                    <a
                      style={{ color: "yellow" }}
                      href="./mork-borg-classes-core"
                    >
                      Core Classes Overview
                    </a>{" "}
                    💀
                  </div>
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MBClassesThirdParty;
