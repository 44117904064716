import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import childrensGame from "../../../../assets/myz/childrens-game.jpg";
import lutrell from "../../../../assets/myz/lutrell-unmasked-portrait.jpg";
import trashHawk from "../../../../assets/myz/trash-hawk.jpg";
import diary from "../../../../assets/myz/diary-METAPLOT-blurry.jpg";
import tanner from "../../../../assets/myz/tanner-no-rabbit.jpg";

const cardTitle = {
  paddingTop: 10,
  paddingLeft: 10,
  paddingBottom: 10,
  backgroundColor: "rgba(221, 117, 28, .7) ",
};
const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "",
    text: (
      <p>
        Mutants are standing over three broken zone ghouls as trash hawk swoops
        in and tries to take one. At the same time, a dozen or more zone ghouls
        are approaching from the area of the resevoir tanks. The trash hawk
        randomly decides to haze the zone ghouls. Our mutants grab the
        opportunity to try and enter the control tower building.
      </p>
    ),
    image: {
      imagePath: trashHawk,
      altText: `
        Perched on the roofs of tall ruins, the Trash Hawk watches the Zone for prey. Yellow and brown feathers make 
        it blend in well in the wasteland. 
        
        This predatory bird has claws long and sharp as knives, and attack by 
        quickly swooping down at its target and simply ripping off a large piece of flesh. 
        
        If big enough, it even grabs its prey and flies off with it. 
        
        Stalkers claim to have seen Trash Hawks with a wingspan of 25 feet.`,
      className: "imageLeft",
    },
  },
  {
    title: "",
    text: (
      <p>
        The doors are blocked by debris, but Subu is barking at a window. It's
        because she smells the familiar scent of Grit the Slave from the
        Sunshine Plaza. Grit helps the party break rusty iron bars off window
        and get into the building. Upstairs in the control room is Nelma the
        Enforcer from Skag's gang and Lambda the Gearhead from Loud Sue's gang.
        They were led her by Stalker Vorhan as the secret expedition funded by
        Loud Sue and Boss Skag.
      </p>
    ),
  },
];
const section2Data = [
  {
    title: "",
    text: (
      <p>
        Smoke Raven and Jebe (with Subu hanging onto his back!) climb the
        smokestack to the nest of the trash hawk. The hawk returns just as Smoke
        Raven plucks a double-barreled scrap rifle of the corpse of Stalker
        Vorhan. With two rifles in play the trash hawk is killed quickly.
        <br />
        In addition to the double-barreled rifle, they find cans of grub,
        bottles of fresh water, a diary on the body of a female non-mutant
        human, and a Children's Board Game.
      </p>
    ),
    image: {
      imagePath: childrensGame,
      altText: `
        A box filled with plastic pieces. Most of them are square white pieces, but there is one red piece shaped 
        like a person. Then, there are two pieces shaped like skinny hammers. 
        
        Finally, there is a larger blue piece with legs. The white pieces seem to fit into the blue piece. There is 
        writing inside the lid of the box top the pieces came in, but it's almost impossible to read.
        
        Effect: None. Small item.
        DEV Requirement: —
        DEV Bonus: Culture +D6`,
      className: "imageRight",
    },
  },
];
const section3Data = [
  {
    title: "",
    text: (
      <p>
        Just as Smoke Raven and Jebe are ready to climb down, the Zone Ghouls
        attack. The party does not retreat into the control room building and
        stays outside to fight! With Smoke Raven and Jebe in sniper position,
        Rowbert, Scut, Hulagu and Grit rush in to battle the ghouls. Nelma
        disappears into the building to guard the window Scut fixed with scrap
        from a flanking force of ghouls. After eight zone ghouls at broken and
        Rowbert goes down, Hulagu calls a parlay with the lead zone ghoul who is
        named Lutrell.
      </p>
    ),
    image: {
      imagePath: lutrell,
      altText: `Lutrell -- zone ghoul boss of the Pure Plant zone.`,
      className: "imageLeft",
    },
  },
  {
    title: "",
    text: (
      <p>
        Lutrell, like the other zone ghouls, seems remarkably non-monsterous.
        Besides being pale and smaller, the zone ghouls do not seem at all like
        the monstrous apparitions the stories have made them out to be.
      </p>
    ),
    image: {
      imagePath: tanner,
      altText: `
        Tanner was a member of Skag's gang. He mocked Skut mercilessly for believing he was a rabbit.
        
        Tanner died of the parasitic plague.`,
      className: "imageRight",
    },
  },
  {
    title: "",
    text: (
      <p>
        Hulagu tries to call a bluff that "an army is on the way" and Lutrell
        tries a counter-manipulation but it's a stand-off. Session ended with
        both sides calling a four-hour truce.
      </p>
    ),
  },
];
const section4Data = [
  {
    title: "",
    text: (
      <p>
        <p>
          Next session starts in the control room building at the negotiating
          table at 03-08-0001 just after midnight.
        </p>
        <br />
        Check out Session Body Count at:{" "}
        <a href="./mutant-year-zero-anchortown-population-notable-events">
          SESSION BODY COUNT AND POPULATION
        </a>
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a href="./mutant-year-zero-anchortown-graveyard">
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: diary,
      altText: `
        A worn notebook with hand-written text on the waterlogged pages. 
        
        If you can read the Old Ones’ script, with a Comprehend (wits) -2, you can make out what it says`,
      className: "imageRight",
    },
  },
];

const MYZAnchortownSession04 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 04: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  Mutants battle a trash hawk at the Pure Plant.
                  <br />
                  Then, in a full frontal assault they battle a dozen zone
                  ghouls in the open with Jebe and Smoke Raven on sniper duty at
                  the top of the smokestack as Nelma and Grit provide backup and
                  Lambda lies wounded in the control tower.
                  <br />
                  After Rowbert goes down, Hulugu parlays with zone ghoul boss
                  Lutrell and a temporary truce is called.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Title style={cardTitle}>
                  Showdown at the Pure Plant, 03-07-0001
                </Card.Title>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
                <Card.Title style={cardTitle}>Into the Nest</Card.Title>
                <Card.Text>
                  <ContentContainer data={section2Data} />
                </Card.Text>
                <Card.Title style={cardTitle}>Full Frontal Assault</Card.Title>
                <Card.Text>
                  <ContentContainer data={section3Data} />
                </Card.Text>
                <Card.Title style={cardTitle}>Executive Summary</Card.Title>
                <Card.Text>
                  <ContentContainer data={section4Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession04;
