import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import logo from "../../../../src/assets/kids-on-bikes/logo-kids-on-bikes.webp";
import sectionDivider from "../../../../src/assets/kids-on-bikes/kids-on-bikes-page-banner.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";
import "./kids-on-bikes-styles.scss";

import aspect from "../../../../src/assets/kids-on-bikes/session04/aspect.webp";
import balanceEngine from "../../../../src/assets/kids-on-bikes/session04/balance-engine.webp";
import darknessOfTheRock from "../../../../src/assets/kids-on-bikes/session04/darkness-of-the-rock.webp";
import heartOfTheRock from "../../../../src/assets/kids-on-bikes/session04/heart-of-the-rock.webp";
import maria from "../../../../src/assets/kids-on-bikes/session04/maria-garcia.webp";
import michaeal from "../../../../src/assets/kids-on-bikes/session04/michael-garcia.webp";
import otto from "../../../../src/assets/kids-on-bikes/session04/otto-fairfield.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "1",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Carter One",
  backgroundColor: "rgba(0, 0, 0, 1) ",
  color: "white",
  marginBottom: "15px",
};

const KOBTurnaroundRockSession05 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  const section1Data = [
    {
      title: "POWERS AMOK",
      sectionTitleCssName: "sectionTitleKOB",
      text: (
        <div className="kidsOnBikesBodyText">
          {/* <ImageComponent
            image={aspect}
            caption="The Aspect"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="The Aspect is the powered character of the game, not Chrisper Liu's as we had thought."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent> */}
          <ImageComponent
            image={darknessOfTheRock}
            caption="Darkness of The Rock"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="The Darkness of The Rock is a malevolent force terrorizing Turaround Rock."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>{" "}
          Welcome back to Turnaround Rock, a sleepy little town on the Oregon
          coast, about 80 miles north of the California border. Here generations
          of families work the sea, a hard life made more difficult by The Rock,
          a large rocky island which keeps larger boats and ships from coming
          into the harbor. It is early morning on Thursday, April 13, 1989. Our
          triumvirate of teens, shaken from learning that the Wendigo is Harry
          Darkstar, a man they have considered an ally, are fleeing up the metal
          staircase along the face of The Rock on their way to the lighthouse
          above.
          <br />
          <br />
          Last time, the kids completed three of four obstacles to stay ahead of
          Harry Darkstar and Sheriff Roberts. Their fourth problem is presented
          as a combined obstacle that each of them must pass. They have two
          rounds left to succeed before Harry and the Sheriff catch up.
          <br />
          <br />
          The kids, panting and winded, continue their hasty trudge upward. The
          flowing darkness above churns and roils, sending out another cloudy
          tendril which zeroes in on the kids, hitting them with a surge of
          energy. Their powers are overcharged, activating involuntarily and
          with wild results. They find themselves struggling to get them back
          under control, each waging their own battle simultaneously. Each
          character loses one of their Psychic Energy Tokens from the
          uncontrolled use of power.
          <br />
          <br />
          Ajax is encircled in a flash of white power, his Commune with the Dead
          ability supercharging. He is suddenly beset upon from all sides by the
          spirits of those who have previously sacrificed themselves at The
          Rock. Their images and voices come at him, unrelenting, unyielding. He
          sees Qua-toh-mah warriors, both male and female; townspeople from
          Turnaround Rock, including Elias Fielder, who Ajax recognizes from the
          statue on the boardwalk. There are others, going even farther back,
          and he gets the sense that whatever is wrong in Turnaround Rock has
          been going on for longer than he could have imagined.
          <br />
          <br />
          Disparate voices either urge or dissuade him with different messages.
          “It didn't work,” a Qua-to-mah woman spirit tells him. “Go back,”
          another implores. “You must find a way to save everything,” says
          another. “There is no balance. The sacrifice is empty,” he hears as
          another voice and spirit comes at him.
          <br />
          <br />
          Ajax feels himself losing his grip on reality, so he focuses in on
          Elias Fielder. Fielder's eyes are wild. He is naked since that is how
          he boarded the boat so long ago to row out to The Rock. The spirit of
          the town founder croaks in a broken voice laced with an accent from
          earlier in the century, “My sacrifice was only a temporary panacea.
          The balance cannot be maintained.”
          <br />
          <br />
          Grimacing from the psychic anguish of it all, Ajax asks, “What can we
          do?”
          <br />
          <br />
          “The balance must be broken” Elias hums. “That which does not belong
          here must be expelled. We cannot balance it.”
          <br />
          <br />
          Ajax feels trapped as Elias fades into the background and other voices
          assault him. Just as he is feeling overwhelmed from the overcharged
          power inside him, Ajax spreads his arms in a welcoming motion, closes
          his eyes, and lets the voices simply flow over, around, and through
          him; he rolls Grit to regain control of his powers. He feels the
          spirits churn, recede briefly and then rush in like a wave. They
          settle into him. He feels a sense of calmness.
          <br />
          <br />
          Meanwhile, Ariadne shakes with a shuddering blue energy. She feels as
          if she is being torn apart, unable to control her Rapid Reaction
          power. She vibrates at an uncontrollable speed, her body shaking with
          unnaturally fast spasms. Her mind also, her thoughts and personality,
          are being pulled apart, splintering. She thinks, with what part of her
          mind she can still see as herself, of the broken mirror she had picked
          up back home when she drew the tarot card of Death. She feels all the
          parts of herself shaking and separating as if in a centrifuge, that
          part of herself that was a child on the playground, that part of
          herself that worshipped the beauty of her mother, Kelly Vale. She
          watches as that part of her that worshipped itself pulls apart,
          separates from the part of her that only knows its own insecurity.
          What can still be described as herself notes that she is all the
          aspects of self that now threaten to destroy her and that she is none
          of them.
          <br />
          <br />
          Ariadne falls to the ground, continuing her seizure in extraordinary
          spasms, laying there prone on the stairs. She envisions herself as the
          broken mirror, shattered beyond recognition, and she feels the pain of
          her shaking body. She begins to scream, part of her hoping that her
          sonic terror can somehow cause the mental mirror to coalesce into
          wholeness. She tries to control her shaking hand enough to grab the
          tarot card, which she still hopes is her talisman, which she still
          hopes can grant her extra focus. She feels herself, as if looking
          outside of herself, focus all her physical strength to pull herself
          together, but she fails her Brawn roll and lays on the ground in an
          uncontrollable seizure of physical and psychological pain.
          <br />
          <br />
          As these things are happening to Ajax and Ariadne, Sheldon is facing
          his own challenge. His body flashes again and again with green energy
          as his overcharged Translocation power sends him places without
          conscious control. One moment he is on the stairs, and the next he is
          in the hospital room where Christopher Liu is recovering and reading a
          Boys' Life magazine.
          <br />
          <br />
          Christopher looks up from his magazine. “Where did you come from,” he
          sputters.
          <br />
          <br />
          “What happened to you, Christopher,” Sheldon asks, grasping for
          answers to how this all began.
          <br />
          <br />
          Christopher starts to utter a reply, but then Sheldon flashes away
          again. He appears momentarily back on the stairs, somehow partially
          anchored there, before flashing immediately away again and arriving in
          the security office at the fish processing plant. He is alone in the
          room, but the handle of the door turns, and Terry, the security guard
          they'd interacted with at the gate of the cannery mere days before,
          yet seemingly much longer given all that has happened, enters the
          room.
          <br />
          <br />
          “Sheldon?” he says, confused. “How did you get in here? This room was
          locked. We…we weren't expecting you here until noon for the meeting.”
          <br />
          <br />
          Sheldon rushes towards Terry to grab him to see if he's real, unsure
          whether he's really traveling or experiencing some sort of out of body
          phenomena. He feels the solidity of Terry's arm.
          <br />
          <br />
          “The Wendigo,” Sheldon says, haltingly. “You know about it, Terry,
          right? You know?”
          <br />
          <br />
          Terry tries to be comforting, his tone as calm as he can make it.
          “Yes, we, the Fishermen, are going to try to fix all of that today.
          And…I'm sorry, Sheldon.”
          <br />
          <br />
          “What are you sorry about,” Sheldon asks.
          <br />
          <br />
          Terry looks concerned as he replies, “Felix was going to try and
          handle it by sacrificing himself the other night, but something
          stopped him from getting to The Rock. He said it would only be a
          temporary fix, anyway. We're resorting to this contraption they call a
          Balance Engine. It will take all three of them, your father, Ajax's
          father, and Mr. Waites. They hope this will finally end it all, but I
          don't think they're going to survive.”
          <br />
          <br />
          And then something else crosses over Terry's face. “And…I worry that
          Mr. Waites, in his need to fix things, is going to try to get you kids
          into the engine instead because of how powerful you are. I'm not sure
          you should go to that meeting today.”
          <br />
          <br />
          Sheldon removes his hand from Terry's arm, and as he does, he flashes
          again, arriving back on the stairs for a moment, only long enough to
          see a few seconds of Ajax and Ariadne dealing with their own torments.
          And then, he flashes again, three times in rapid succession, to the
          empty computer lab at school, then back to the stairs, and then, as he
          translocates once more, the GM asks him what location he is thinking
          of right then. Sheldon is thinking about the meeting at noon in the
          room below the fish processing plant, the room with the Balance
          Engine. But it is not yet noon, and translocation doesn't move through
          time, only space, so he arrives there well early of the meeting.
          <br />
          <br />
          He begins to yell, and the door opens. It is Felix Waites.
          <br />
          <br />
          “Sheldon!” he exclaims, looking the boy up and down. “You're early.
          We're not gathering for a few more hours. How did you get here?”
          <br />
          <br />
          “I don't know,” Sheldon cries, “I just showed up in this room. You
          must help me!”
          <br />
          <br />
          “Where are your friends?”
          <br />
          <br />
          “I don't know. We're in danger from the Wendigo out at The Rock.”
          <br />
          <br />
          “Wait, if you're in danger at The Rock, how are you here?”
          <br />
          <br />
          Sheldon grabs him, and the green blast of translocation energy carries
          Felix along with Sheldon as they are both transported onto the stairs
          on The Rock. Sheldon makes a Grit roll to get ahold of himself and his
          power, succeeding. He sees Ariadne shaking on the ground, and he sees
          Ajax opening his eyes and putting down his arms, the white energy
          around Ajax flowing back into him.
          <br />
          <br />
          Felix Waites crouches down by Ariadne to try to help her, but he
          doesn't know quite what to do.
          <br />
          <br />
          Ajax puts his arms around the vibrating Ariadne. He starts shaking,
          too, as he holds her. “We can do this together,” he says, his voice
          shuddering with the effort of trying to hold her still. He cannot stop
          her shaking, rolling low, and he is forced to let go.
          <br />
          <br />
          Sheldon tries to give Ariadne his talisman, the carved Wendigo
          statute, to help her pull herself together. He puts it in her hand,
          but he also rolls low. The carving crumbles into splinters and dust
          under the stress of Ariadne's shaking hand. If this was Sheldon's
          talisman, it is no more.
          <br />
          <br />
          Ariadne is still trying to pull herself together. The player explains
          that as a character with the plastic beauty trope, Ariadne might not
          normally use Brains here, but because of all her questioning of
          identity, Ariadne rolls Brains to try to bring her thoughts and body
          back into alignment. Unfortunately, she fails that roll as well. She
          uses the last of her adversity tokens to re-roll, using her Lucky
          feature, but she is anything but lucky, rolling a one. She continues
          to shake violently on the stairs, blurring almost beyond recognition.
          <br />
          <br />
          This is also the end of the two rounds the players had left to stay
          ahead of their pursuers. And so, Sheriff Roberts and Harry Darkstar
          are upon them. Harry transforms into the Wendigo as the two men
          surprise the distracted group.
          <br />
          <br />
          Felix Waites is taken aback, offended even, to see the sheriff.
          “You're part of this?”
          <br />
          <br />
          Roberts laughs at Felix and indicates he is going to tie their hands.
          “If you want the girl to live a little longer, you'll comply.”
          <br />
          <br />
          The Wendigo snarls menacingly.
          <br />
          <br />
          Felix puts his hand forward to be bound by the sheriff. Ajax and
          Sheldon are also bound by their hands. The sheriff hunkers down to
          bind Ariadne's hands, and as he does, the dark energy that was
          overcharging her powers is dispelled. Her hands come into focus as
          does the rest of her, slowing down and finally stabilizing. When
          Ariadne's body stops spasming, she feels empty and hollow, still not
          sure of her surroundings. Sheriff Roberts ties her, lifts her to her
          feet, and then loops a rope through all their bindings, securing them
          together.
          <br />
          <br />
          It takes Ariadne awhile to get her bearings as the sheriff and the
          Wendigo lead the prisoners up the stairs to the lighthouse.
        </div>
      ),
    },

    {
      title: "THE LIGHTHOUSE OF DARKNESS",
      sectionTitleCssName: "sectionTitleKOB",
      text: (
        <div className="kidsOnBikesBodyText">
          <ImageComponent
            image={heartOfTheRock}
            caption="Heart of The Rock"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="The Heart of the Rock is how the Aspect appears in the Dreamlands. The heart is a force of good, lending its power to the kids."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          When the group enters the lighthouse, they first see the steps that
          wind around the center and go up to the light itself. The sheriff does
          not lead them up those steps. Instead, in the center at the back,
          there is an access door. Inside that door is a small room, on the
          floor of which sits a hatch lock wheel that Harry the Wendigo turns
          and opens. The beast gestures that they follow Sheriff Roberts
          downward. They descend into The Rock in the order they are tied
          together: Felix, Sheldon, Ajax, and Ariadne.
          <br />
          <br />
          The room below is lit by unknown means. The circular wall of the room
          is composed of a material like rock and metal fused together, some
          strange alloy. The room could be some sort of pilot control room, but
          the technology is beyond anything the kids can understand, and it
          seems ancient. The Wendigo stands by the wall where a metal ladder
          descends further. At the center of the room there is a circular pool
          with a short, raised lip around it. There are control panels around
          this pool. At another point on the wall, there is a depression that
          looks like some sort of sliding doorway.
          <br />
          <br />
          Sheriff Roberts leads them closer to the pool and the control array.
          The liquid in the pool is dark with subtle, shifting colors drifting
          in unsettling incandescent patterns.
          <br />
          <br />
          The Sheriff speaks with a confident surety, “We've come to the end of
          your journey. You'll be dissolved here. Your consciousness, along with
          the powers that you hold, will feed The Darkness, and we will finally
          win this age-old battle.”
          <br />
          <br />
          Sheldon uses his Charm and says, “You might as well tell us everything
          since you have us.”
          <br />
          <br />
          Roberts laughs with surprise and superiority. “You have these
          abilities, and you don't know a damn thing?!” His laughter is
          humorless, the bark of it echoing in the alien chamber. “This is an
          ancient conflict. This goes way back, long before the Qua-to-mah.
          There are two beings here, which we call The Darkness and The Heart,
          two forces locked in an eternal struggle, but here's what you need to
          know. The Darkness will rule this world, remake it, and we will rule
          alongside it.”
          <br />
          <br />
          Ajax, while scared to death, has been in awe of all that he sees. This
          is obviously all about ancient aliens and thus falls right in the
          wheelhouse of his thoughts and interests. The sheriff's next words
          confirm this. “Their ship crashed here on earth a long time ago. The
          prisoner was freed, and the battle began again and again and again.”
          <br />
          <br />
          Roberts presses a button on a console near the pool. The sliding
          doorway opens on the wall, revealing another room. They can see that
          the room is filled with countless desiccated bodies hanging in energy
          fields inside tubes. These are the bodies of the ghosts that Ajax
          encountered, the ghosts that are still with him. One of the nearest
          bodies is a mummified Elias Fielder.
          <br />
          <br />
          As they stare wide-eyed at the sight, the sheriff growls, “Misguided
          fools, sacrificing to try to balance a broken equation.” He hits the
          button again and the door slides shut. He unties Felix Waites from the
          rope connecting them all together, leaving his hands still bound, and
          leads the man toward the pool. “We'll start slow, with the weakest of
          you.”
          <br />
          <br />
          Ariadne has regained her wits and is thinking that the kids tied
          together might be able to rush the sheriff and clothesline him with
          the rope into the pool. She tries to share her plan with Sheldon and
          Ajax by speaking as obliquely yet as clearly as possible.
          <br />
          <br />
          “Doesn't being tied with this rope remind you of that time our school
          had that tug-o-war with that other school? Everybody all tangled and
          falling into the mud.”
          <br />
          <br />
          The GM calls for a Charm to see how well Ariadne was able to convey
          her message to the other kids, distracted as they are by their
          imminent death. The roll explodes to a 22, beating the target amount
          by enough that not only did everyone hear and understand, but they are
          also ready to act together.
          <br />
          <br />
          The kids rush toward the sheriff, fanning out to create a clothesline
          to attempt to topple him. Ariadne is a bit surprised when Sheldon
          peels away and heads towards the Wendigo. Sheldon has managed to use
          his Brains to identify the Ashley Bend knot used to tie his hands to
          the lead rope and to manipulate one hand to untie it.
          <br />
          <br />
          The target to knock the Sheriff over the lip and into the pool is a
          combined 22 (an 11 doubled), meaning Ajax and Ariadne will add their
          rolls together to try to beat that number. Ajax rolls Grit and gets an
          8, while Ariadne gets an 18 on her Flight roll. With a combined 26,
          the kids rush forward, knock into the sheriff, and he stumbles forward
          and trips into the pool. Felix is elbowed aside and falls onto his
          rear on the floor.
          <br />
          <br />
          The Wendigo ignores Sheldon, unworried about the boy, and focuses on
          the screams of the sheriff. Sheriff Roberts thrashes in the viscous
          liquid in the pool, screaming and dissolving. The dark cloud seeps
          down from above, drawn to the pool and Sheriff Roberts, feeding off
          the sheriff's energy.
          <br />
          <br />
          With the Wendigo distracted, Sheldon reaches into his pack and grabs
          the fifth of gin he'd stolen from his father's liquor cabinet days
          ago. Still sprinting toward the Wendigo, he flings the bottle at him,
          hoping it will break and drench him. Instead, it is a case of the
          mathlete failing at being the athlete. The bottle shatters on the
          ground at the feet of the beast, who snarls and howls as it turns to
          charge at Sheldon.
          <br />
          <br />
          The Wendigo knocks Sheldon to the ground and towers over the boy.
          <br />
          <br />
          Ariadne shouts, “We have to get out of here!” She wonders how they
          will get Sheldon away from the monster and get up the ladder. Then,
          she stops to consider, using her Intuition to realize that fleeing the
          lighthouse will not save them. The dark cloud is growing stronger,
          siphoning off the lifeforce of the sheriff. She decides instead to hit
          the button on the console. The door to the room of bodies slides open
          again while the Wendigo howls over Sheldon.
          <br />
          <br />
          Ajax shouts, “Not my friend!” He rushes the Wendigo and unleashes a
          strong burst of electricity as he punches up under the ribcage of the
          beast. He envisions focusing the electricity into the Wendigo's chest
          cavity, and lightning shoots into the creature. The Wendigo shrieks an
          unearthly shriek and falls unconscious, transforming back into Harry
          Darkstar.
          <br />
          <br />
          Felix sees the open door. “That's where I was going the night I tried
          to row out to The Rock.”
          <br />
          <br />
          Sheldon gets everyone untied, while Felix continues, “I was going to
          sacrifice myself and feed the Heart of The Rock, but when I couldn't
          get here, we decided to try to end things once and for all. The
          balance never holds. We thought all three of us could use the Balance
          Engine to make things right.”
          <br />
          <br />
          Sheldon ignores the adult. He takes the ropes and ties up the
          now-human Harry Darkstar, another exploding roll that yields an
          impressive 21 on Brains, allowing Sheldon to securely tie the
          unconscious boat mechanic.
          <br />
          <br />
          Entering the other room, the group approach the closest bodies hanging
          in stasis in strange tubes. Ariadne sees that one of them looks very
          similar to her mother and grandmother, a relative of hers who
          sacrificed herself for the town of Turnaround Rock.
          <br />
          <br />
          There is another console in this strange room with three colored gems:
          green, white, and blue. Their noncorporeal ally, the Aspect, floats
          near the console, slightly more substantial than before, and waits.
          <br />
          <br />
          The kids decide that they should push the buttons in tandem, each
          pushing the gem that matches the color of their power's energy.
          Simultaneously, the young triumvirate push the buttons, then stand
          back as things begin to occur. First, the room brightens with the
          three colors, as well as the yellow color of the Aspect, the unseen
          light sources pulsing with increased power. Then, the gems float up
          and out of their sockets and form a triangle in the air. Finally, the
          Aspect floats to the center of the triangulation of gems and becomes
          even more substantial in appearance, power flowing in and around it.
          The Aspect speaks.
          <br />
          <br />
          “You're doing well against such odds,” it says to them.
          <br />
          <br />
          Ariadne wonders aloud, “You can talk again?” They haven't heard the
          Aspect speak since it briefly found its voice in the computer lab only
          a day before, on Wednesday. Things have all happened so fast in the
          last few days.
          <br />
          <br />
          The Aspect of The Heart replies, “Yes, I can communicate fully now. We
          are in my domain. and you have strengthened me. This is my ship. Never
          have the three come together before, so never could I be completely
          understood.”
          <br />
          <br />
          Felix says, “What about all these people? All the sacrifice.”
          <br />
          <br />
          “I am speaking to you now” says the Aspect, ignoring Felix and
          focusing on the kids. “Felix had his chance. His sacrifice would have
          kept the balance, but the struggle would have continued as it always
          has. There has always been a misunderstanding that could not be
          communicated about what sacrifice was truly necessary.”
          <br />
          <br />
          The Aspect looks to the kids, each in turn: “You must break it. You
          must break all of it.” The floating Aspect gestures to indicate the
          entire place.
          <br />
          <br />
          Ajax blurts, “The Tower, the worst tarot card of all.”
          <br />
          <br />
          The gems swirl and float into the Aspect's hand, which it holds out
          towards them. Each of the kids steps forward to take the gem matching
          their power's color. As they take the gems, their Psychic Energy
          Tokens are replenished to the maximum, and they feel a sense of
          greater control.
          <br />
          <br />
          Ariadne remembers the figurine of the Wendigo shattering. She thinks
          of her search for her talisman. Each of the kids realize that the gems
          are their true talismans.
          <br />
          <br />
          “I have lived a long time,” says the Aspect. “I hope to be gone soon.
          I have lost myself so many times. It's interminable. I thought I was a
          boy scout recently, and so many others throughout time. Keep these
          gifts. When I am gone, you will still be able to channel my
          technology.”
          <br />
          <br />
          Ariadne asks, “Were you the prisoner or the guard?”
          <br />
          <br />
          The Aspect looks saddened as if it had failed at something, “I was the
          guard.”
          <br />
          <br />
          Then the Aspect's attention moves to the other room. They all look
          through the door and see that the dark tendrils of cloud have drifted
          towards Harry's body and begin to dissolve his flesh. Then, those dark
          tendrils begin to move into the room of bodies, circling the Aspect
          threateningly. The Aspect, with its renewed strength, extends its hand
          and shoots the cloud with electrical energy. The cloud retreats from
          the room and slinks down the second ladder that extends deeper into
          The Rock.
          <br />
          <br />
          “Find us,” commands the Aspect as it chases after the cloud and floats
          in a rush down the ladder.
          <br />
          <br />
          The kids follow the Aspect, conjecturing on the way. The Rock is
          definitely a spaceship, isn't it? Maybe the two alien bodies are down
          there? Whatever lies ahead must be confronted.
          <br />
          <br />
          The chase ends in a room where the physical forms of the Heart of The
          Rock and the Darkness of the Rock are present, two alien bodies
          entwined in an eternal embrace of violence, suspended in the air in
          stasis. The dark cloud is entering into the body of the Darkness while
          the Aspect reinhabits the Heart.
          <br />
          <br />
          “Destroy us,” says the Heart of the Rock.
          <br />
          <br />
          Then, an unfamiliar voice speaks. It is a dark and seductive voice
          that growls, “No! You can rule and have all the power. You can own
          this world. Free me!”
          <br />
          <br />
          Felix, his eyes wild and bright, shouts, “I can go back upstairs and
          sacrifice myself.”
          <br />
          <br />
          Ariadne slaps the adult in the face and shouts, “We don't want your
          sacrifice! We don't want to feed anything or maintain any balance! We
          need to break the balance. We must end the cycle!”
          <br />
          <br />
          As the kids debate what to do about the alien bodies locked in stasis,
          Sheldon decides to enter the Dreamscape for answers. He sees a dark
          vessel and a vessel of light, then electrical energy coalescing and
          gathering into the light vessel, filling it, overflowing it like an
          overcharged battery filled with too much power.
          <br />
          <br />
          Sheldon comes out of his brief trance and shouts, “Ajax, overpower the
          Heart!!”
          <br />
          <br />
          Ajax gets it and lays hands on the Heart of the Rock. “The Heart is
          the sacrifice,” Ajax says as he pours all his focus into using his
          powers to their full extent. “It's the Heart. It always was!”
          <br />
          <br />
          As Ajax channels his power, even with successful Grit rolls, he
          realizes he is being affected along with the alien body. His fingers
          begin to blister and blacken and shrivel. His hair smokes. Perhaps he
          must also sacrifice himself to end this? He has never felt such pain.
          He feels himself being consumed by electricity as he overcharges the
          alien.
          <br />
          <br />
          Ajax resolves to continue to channel his power, even at the cost of
          his own life. But then, he feels a warmth, a healing surge that brings
          him hope. Ariadne stands at Ajax's side, her hands on him, sending her
          blue healing power into him. Ajax still feels pain, but it is
          lessened. He is wounded and healed, wounded and healed, in a cascade
          of energy that matches the strength of the cascade of current he sends
          into the Heart.
          <br />
          <br />
          Sheldon watches as the Heart begins to glow ever brighter, ever
          brighter, and he sees that everything is about to blow. He screams to
          Felix to “grab ahold of me” as he reaches out and touches both Ajax
          and Ariadne. He then realizes that it doesn't matter that Felix wasn't
          able to comply in time. He realizes that he doesn't need to touch any
          of them to use his power. If they are close enough and his focus is
          strong enough, he can move them all. He is aware of each of them,
          their proximity to him, and the way that his power encircles them all.
          He experiences the world around them becoming wavy and foggy, a moment
          of singularity, a black hole pulling all of them into its event
          horizon, into a single point. There is a rush of movement down a long
          tunnel of energy, lights and colors flashing by, and a feeling of
          being stretched thin almost into nothingness. At the other end, the
          pinprick of a single point opens into a view of the world beyond, and
          with a last rush of movement like a rubber band snapping back into
          shape, they are standing in the security office at the fish processing
          plant, facing a window that looks out towards the harbor and The Rock.
          <br />
          <br />
          The Rock, in its entirety, shimmers as our heroes watch, folding in on
          itself, imploding into absolutely nothing, a pinprick of matter and
          then it is gone. Following the implosion is a wave of blue energy that
          expands outward from where The Rock once was. The blue shimmering
          energy races over the entire town of Turnaround Rock, flowing over,
          around, and through everything.
          <br />
          <br />
          It is a larger massive blast of blue healing energy. It spreads its
          healing power over the town, improving everything in some small way
          for everybody, a last boon from the Heart of The Rock. And, like the
          blue healing light, the blue blast causes confusion and memory loss.
          Everyone in Turnaround Rock except the kids and Felix Waites is
          affected. The blue wave passes through the community, this small part
          of the world, and the gift of forgetting takes care of the anomaly of
          The Rock. There never was a Rock. The residual expansion of this wave
          moves across the world, travelling away until it returns as one last
          little bit of energy to where the kids stand. The Rock is gone, the
          memory of The Rock is gone. The bay is no longer a danger to ships or
          fishermen. There is no lighthouse. No rock.
          <br />
          <br />
          The kids are aware of what has happened, as is Felix, but they are
          unaffected by the memory loss, by the rewriting. They know they have
          always lived in Turnaround Rock and that now it is different.
          <br />
          <br />
          As for everyone else in the town, they are amazed by the prank that
          has been played on them. The townspeople wonder at who could have gone
          and changed all the signs in town to read “Turnaround Rock” when they
          all know that they live, and have always lived, in Fielder's Dream,
          the name the town would have had if The Rock had never existed. It is
          an elaborate, though silly prank, and only the three kids and Felix
          Waites know that it is no prank at all.
          <br />
          <br />
          As the days march forward, the richest man in town, Felix Waites,
          helps restore the signs to read “Fielder's Dream.” He sends men to
          collect brochures from the visitors' center and has them replaced with
          brochures that say, “Fielder's Dream” instead of “Turnaround Rock.”
          <br />
          <br />
          The healing energy from the implosion of The Rock makes family life
          better for Sheldon and Ajax.
          <br />
          <br />
          Sheldon's father has a job as a guard at the hospital. He no longer
          struggles with alcohol and doesnct even recall ever struggling with
          it. Having no memory of the strangeness of Turnaround Rock and his
          part in it means that he never turned to drink. Sheldon greets his
          father at the hospital and hugs him, the closeness to his father a new
          thing to Sheldon and yet seeming to have always been this wonderful
          for Michael Garcia.
          <br />
          <br />
          As for Otto Fairfield, he was never obsessed with the energies
          plaguing Turnaround Rock, and he never had to run away to try and fix
          things. “Do I have a rad dad now?” Ajax wonders. And as the days pass,
          Ajax thinks, “I have a rad dad!”
          <br />
          <br />
          Ariadne sees her mother in the morning putting on makeup, getting
          ready for the day. Her father smiles at breakfast, friendly but
          distracted by his workload for the day looming in his mind. For
          Ariadne, her family life seems relatively unchanged. The rewriting of
          history has not rewritten much for her privileged family. But Ariadne
          has changed. She realizes now that her parents are the plastic
          beauties; not her. She is something new and different; she is complex
          and confident.
          <br />
          <br />
          There are many questions at the end. It doesn't seem fully possible
          that such a rewriting could come with no consequences. Can all the
          evidence of the town's former history be so easily discarded? What
          happens when the inconsistencies are noticed? Does anyone else
          remember? But those are worries for another day.
          <br />
          <br />
          For now, the kids choose to enjoy their town and their freedom. They
          are friends, and they are in the wild and free days of their youth.
          And they have hidden powers, known only to them and to Felix Waites.
          As they bike around together, they pass by the Callas House. Ajax,
          fully attuned to his powers now thanks to that day on The Rock,
          suddenly screeches his bike to a halt, feeling something powerful
          coming from the house. “There's a ghost in there,” he says to the
          others with absolute certainty.
          <br />
          <br />
          Welcome to Fielder's Dream, a sleepy little town on the Oregon coast,
          about 80 miles north of the California border. Here generations of
          families work the sea. Life is good.
          <br />
          <br />
          THE END . . .
          <br />
          <br />
          Kids on Bikes was GMed and written by{" "}
          <a
            href="https://www.roblecrone.com/"
            rel="noreferrer"
            target="_blank"
          >
            CobraRex
          </a>
        </div>
      ),
    },
  ];

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/kids-on-bikes/kids-on-bikes-page-background.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <Container>
      {/* <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin"
                    style={{position: 'float', float: 'right',width: '120px', height: '240px'}} marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=smokeraven-20&language=en_US&marketplace=amazon&region=US&placement=9189143701&asins=9189143701&linkId=d1384e0e6b9ef083fee35d7272a71f88&show_border=true&link_opens_in_new_window=true"></iframe> */}
      <Row>
        <Col>
          <div
            style={{
              backgroundColor: "transparent",
              fontFamily: "Carter One",
            }}
          >
            <main role="main" className="inner cover text-center">
              <img
                src={logo}
                style={{ maxWidth: "200px" }}
                alt="Kids on Bikes Turnaround Rock Campaign"
              />
            </main>
            <div style={pageHeader}>
              <h1 className="cover-heading-kids-on-bikes">
                Session 05: Turnaround Rock
              </h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title className="cardTitle-kids-on-bikes">
                SEASON FINALE of Kids on Bikes: Turnaround Rock. <br />
                The sleepy little town on the Oregon coast will never be the
                same.
              </Card.Title>
              <div>
                <ContentContainer data={section1Data} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default KOBTurnaroundRockSession05;
