import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import flTitle from "../../../src/assets/forbidden-lands/forbidden-lands-logo.webp";
import "../../index.css";

import artifact from "../../../src/assets/finds/precious carried finds/artifact.webp";
import beautifulDesk1 from "../../../src/assets/finds/precious lair finds/beautiful-desk-1.webp";
import beautifulDesk2 from "../../../src/assets/finds/precious lair finds/beautiful-desk-2.webp";
import beautifulDesk3 from "../../../src/assets/finds/precious lair finds/beautiful-desk-3.webp";
import beautifulTapestry1 from "../../../src/assets/finds/precious lair finds/beautiful-tapestry-1.webp";
import beautifulTapestry2 from "../../../src/assets/finds/precious lair finds/beautiful-tapestry-2.webp";
import beautifulTapestry3 from "../../../src/assets/finds/precious lair finds/beautiful-tapestry-3.webp";
import ceilingCandelabrum from "../../../src/assets/finds/precious lair finds/ceiling-candelabrum.webp";
import coins from "../../../src/assets/finds/precious lair finds/coins-bronze-silver-gold.webp";
import crownWithGemstones1 from "../../../src/assets/finds/precious lair finds/crown-with-gemstones-1.webp";
import crownWithGemstones2 from "../../../src/assets/finds/precious lair finds/crown-with-gemstones-2.webp";
import crownWithGemstones3 from "../../../src/assets/finds/precious lair finds/crown-with-gemstones-3.webp";
import embroideredCarpet1 from "../../../src/assets/finds/precious lair finds/embroidered-carpet-1.webp";
import embroideredCarpet2 from "../../../src/assets/finds/precious lair finds/embroidered-carpet-2.webp";
import embroideredCarpet3 from "../../../src/assets/finds/precious lair finds/embroidered-carpet-3.webp";
import goldenArmchair from "../../../src/assets/finds/precious lair finds/golden-armchair.webp";
import goldenCradle from "../../../src/assets/finds/precious lair finds/golden-cradle.webp";
import goldenDiadem from "../../../src/assets/finds/precious lair finds/golden-diadem.webp";
import goldenMirror from "../../../src/assets/finds/precious lair finds/golden-mirror.webp";
import goldenNecklace1 from "../../../src/assets/finds/precious lair finds/golden-necklace-1.webp";
import goldenNecklace2 from "../../../src/assets/finds/precious lair finds/golden-necklace-2.webp";
import goldenNecklace3 from "../../../src/assets/finds/precious lair finds/golden-necklace-3.webp";
import goldenPalanquin from "../../../src/assets/finds/precious lair finds/golden-palanquin.webp";
import goldenSarcophagus from "../../../src/assets/finds/precious lair finds/golden-sarcophagus.webp";
import goldenStatue from "../../../src/assets/finds/precious lair finds/golden-statue.webp";
import goldenTable from "../../../src/assets/finds/precious lair finds/golden-table.webp";
import goldenGoblet1 from "../../../src/assets/finds/precious lair finds/gold-goblet-1.webp";
import goldenGoblet2 from "../../../src/assets/finds/precious lair finds/gold-goblet-2.webp";
import goldenGoblet3 from "../../../src/assets/finds/precious lair finds/gold-goblet-3.webp";
import goldStatueOfAChild from "../../../src/assets/finds/precious lair finds/gold-statue-of-a-child.webp";
import goldTray from "../../../src/assets/finds/precious lair finds/gold-tray.webp";
import largeRareGemstone from "../../../src/assets/finds/precious lair finds/large-rare-gemstone.webp";
import necklaceWithGemstones1 from "../../../src/assets/finds/precious lair finds/necklace-with-gemstones-1.webp";
import necklaceWithGemstones2 from "../../../src/assets/finds/precious lair finds/necklace-with-gemstones-2.webp";
import necklaceWithGemstones3 from "../../../src/assets/finds/precious lair finds/necklace-with-gemstones-3.webp";
import paintingWithGoldFrame1 from "../../../src/assets/finds/precious lair finds/painting-with-gold-frame-1.webp";
import paintingWithGoldFrame2 from "../../../src/assets/finds/precious lair finds/painting-with-gold-frame-2.webp";
import paintingWithGoldFrame3 from "../../../src/assets/finds/precious lair finds/painting-with-gold-frame-3.webp";
import pulpit from "../../../src/assets/finds/precious lair finds/pulpit.webp";
import scepterWithGemstones1 from "../../../src/assets/finds/precious lair finds/scepter-with-gemstones-1.webp";
import scepterWithGemstones2 from "../../../src/assets/finds/precious lair finds/scepter-with-gemstones-2.webp";
import scepterWithGemstones3 from "../../../src/assets/finds/precious lair finds/scepter-with-gemstones-3.webp";

const cardTitle = {
  paddingTop: 10,
  paddingLeft: 10,
  paddingBottom: 10,
  fontSize: 24,
  backgroundColor: "rgba(177, 83, 90, 1) ",
};
const pageHeader = {
  color: "black",
  backgroundColor: "white",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Aladin",
  fontSize: 20,
  backgroundColor: "rgba(118, 131, 87, 1)",
  color: "white",
  marginBottom: "15px",
};

class FLFindsPreciousLair extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "BEGINNING NAME",
      text: "Click button for some fun.",
      testValue: "test value",
      selectedMutation: null,
    };
  }

  componentWillMount() {
    document.body.style.backgroundImage = `url(${require("../../../src/assets/fl-map-groveland-woods.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "round";
    document.body.style.backgroundAttachment = "fixed";
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = null;
  }

  render() {
    return (
      <div>
        <Container>
          <Row>
            <Col>
              <div
                style={{
                  backgroundColor: "transparent",
                  fontFamily: "Orbitron",
                }}
              >
                <main role="main" className="inner cover text-center">
                  <img
                    src={flTitle}
                    style={{ maxWidth: "400px" }}
                    alt="Forbidden Lands campaign"
                  />
                </main>
                <div className={"forbiddenLandsFont"} style={pageHeader}>
                  <h1 className="cover-heading">
                    Forbidden Lands Finds - Precious Lair [VTT Tokens]
                  </h1>
                  <p className="lead leadExtra">
                    The precious lair finds from core Forbidden Lands GM guide
                    as transparent webp files in 512x512 dimension, perfect for
                    Foundry VTT.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card style={cardStyle}>
                <Card.Body>
                  <Card.Title style={cardTitle}>
                    Precious Carried Finds
                  </Card.Title>

                  <Card.Text>
                    <div className={"tokenWrapper"}>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Artifact</div>
                        <img src={artifact} alt="Artifact" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Beautiful Desk 1
                        </div>
                        <img src={beautifulDesk1} alt="Beautiful Desk 1" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Beautiful Desk 2
                        </div>
                        <img src={beautifulDesk2} alt="Beautiful Desk 2" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Beautiful Desk 3
                        </div>
                        <img src={beautifulDesk3} alt="Beautiful Desk 3" />
                      </div>
                    </div>
                    <div className={"tokenWrapper"}>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Beautiful Tapestry 1
                        </div>
                        <img
                          src={beautifulTapestry1}
                          alt="Beautiful Tapestry 1"
                        />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Beautiful Tapestry 2
                        </div>
                        <img
                          src={beautifulTapestry2}
                          alt="Beautiful Tapestry 2"
                        />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Beautiful Tapestry 3
                        </div>
                        <img
                          src={beautifulTapestry3}
                          alt="Beautiful Tapestry 3"
                        />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Ceiling Candelabrum
                        </div>
                        <img
                          src={ceilingCandelabrum}
                          alt="Ceiling Candelabrum"
                        />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Coins, bronze/silver/gold
                        </div>
                        <img src={coins} alt="Coins, bronze/silver/gold" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Crown with Gemstones 1
                        </div>
                        <img
                          src={crownWithGemstones1}
                          alt="Crown with Gemstones 1"
                        />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Crown with Gemstones 2
                        </div>
                        <img
                          src={crownWithGemstones2}
                          alt="Crown with Gemstones 2"
                        />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Crown with Gemstones 3
                        </div>
                        <img
                          src={crownWithGemstones3}
                          alt="Crown with Gemstones 3"
                        />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Embroidered Carpet 1
                        </div>
                        <img
                          src={embroideredCarpet1}
                          alt="Embroidered Carpet "
                        />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Embroidered Carpet 2
                        </div>
                        <img
                          src={embroideredCarpet2}
                          alt="Embroidered Carpet 2"
                        />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Embroidered Carpet 3
                        </div>
                        <img
                          src={embroideredCarpet3}
                          alt="Embroidered Carpet 3"
                        />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Golden Armchair
                        </div>
                        <img src={goldenArmchair} alt="Golden Armchair" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Golden Cradle</div>
                        <img src={goldenCradle} alt="Golden Cradle" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Golden Diadem</div>
                        <img src={goldenDiadem} alt="Golden Diadem" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Golden Mirror</div>
                        <img src={goldenMirror} alt="Golden Mirror" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Golden Necklace 1
                        </div>
                        <img src={goldenNecklace1} alt="Golden Necklace 1" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Golden Necklace 2
                        </div>
                        <img src={goldenNecklace2} alt="Golden Necklace 2" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Golden Necklace 3
                        </div>
                        <img src={goldenNecklace3} alt="Golden Necklace 3" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Golden Palanquin
                        </div>
                        <img src={goldenPalanquin} alt="Golden Palanquin" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Golden Sarcophagus
                        </div>
                        <img src={goldenSarcophagus} alt="Golden Sarcophagus" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Golden Statue</div>
                        <img src={goldenStatue} alt="Golden Statue" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Golden Table</div>
                        <img src={goldenTable} alt="Golden Table" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Gold Goblet 1</div>
                        <img src={goldenGoblet1} alt="Gold Goblet 1" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Gold Goblet 2</div>
                        <img src={goldenGoblet2} alt="Gold Goblet 2" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Gold Goblet 3</div>
                        <img src={goldenGoblet3} alt="Gold Goblet 3" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Gold Statue of Child
                        </div>
                        <img
                          src={goldStatueOfAChild}
                          alt="Gold Statue of Child"
                        />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Gold Tray</div>
                        <img src={goldTray} alt="Gold Tray" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Large Rare Gemstone
                        </div>
                        <img
                          src={largeRareGemstone}
                          alt="Large Rare Gemstone"
                        />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Necklace with Gemstones 1
                        </div>
                        <img
                          src={necklaceWithGemstones1}
                          alt="Necklace with Gemstones 1"
                        />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Necklace with Gemstones 2
                        </div>
                        <img
                          src={necklaceWithGemstones2}
                          alt="Necklace with Gemstones 2"
                        />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Necklace with Gemstones 3
                        </div>
                        <img
                          src={necklaceWithGemstones3}
                          alt="Necklace with Gemstones 3"
                        />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Painting with Gold Frame 1
                        </div>
                        <img
                          src={paintingWithGoldFrame1}
                          alt="Painting with Gold Frame 1"
                        />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Painting with Gold Frame 2
                        </div>
                        <img
                          src={paintingWithGoldFrame2}
                          alt="Painting with Gold Frame 2"
                        />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Painting with Gold Frame 3
                        </div>
                        <img
                          src={paintingWithGoldFrame3}
                          alt="Painting with Gold Frame 3"
                        />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Pulpit</div>
                        <img src={pulpit} alt="Pulpit" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Scepter with Gemstones 1
                        </div>
                        <img
                          src={scepterWithGemstones1}
                          alt="Scepter with Gemstones 1"
                        />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Scepter with Gemstones 2
                        </div>
                        <img
                          src={scepterWithGemstones2}
                          alt="Scepter with Gemstones 2"
                        />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Scepter with Gemstones 3
                        </div>
                        <img
                          src={scepterWithGemstones3}
                          alt="Scepter with Gemstones 3"
                        />
                      </div>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default FLFindsPreciousLair;
