import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../assets/myz/myz-section-divider.jpg";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import { useEffect } from "react";

import tanner from "../../../assets/myz/ark-mutant-tanner-cropped.jpg";
import nelma from "../../../assets/myz/nelma-portrait.jpg";
import loudSue from "../../../assets/myz/ark-boss-loud-sue.jpg";
import doubleBird from "../../../assets/myz/double-bird-portrait.jpg";
import zane from "../../../assets/myz/threat-exiled-mutants-leader-2-zane.jpg";
import subu from "../../../assets/myz/ark-subu-token-in-memorium.jpg";
import lambda from "../../../assets/myz/lambda-portrait.jpg";
import hanneth from "../../../assets/myz/hanneth.jpg";
import civilWar from "../../../assets/myz/civil-war-casualties.jpg";
import elson from "../../../assets/myz/elson-dog-mutant.jpg";
import silas from "../../../assets/myz/ark-mutant-silas-chronicler.jpg";
import demyan from "../../../assets/myz/wanderer-demyan.jpg";
import dima from "../../../assets/myz/wanderer-dima.jpg";
import hetu from "../../../assets/myz/ark-mutant-hetu-the-beast-handler.jpg";
import kyle from "../../../assets/myz/ark-kyle-mutant.jpg";
import luna from "../../../assets/myz/ark-mutant-luna-token.png";
import freddy from "../../../assets/myz/freddy.png";
import death_of_jebe from "../../../assets/myz/ark-jebe-portrait.jpg";
import fortran from "../../../assets/myz/inhibitant-fortran-chronicler.jpg";
import doublebird2 from "../../../assets/myz/ark-mutant-doublebird-third-eye.png";
import danova from "../../../assets/myz/ark-mutant-danova.jpg";
import krao from "../../../assets/myz/ark-boss-krao.jpg";
import harley from "../../../assets/myz/threat-helldrivers-harley-portrait.jpg";
import sparky from "../../../assets/myz/sparky-the-small-vicious-dog.webp";
import theodore from "../../../assets/myz/theodore.jpg";
import beto from "../../../assets/myz/beto.webp";
import patrick from "../../../assets/myz/ncp-patrick.png";
import docGladhand from "../../../assets/myz/ark-mutant-gladhand-portrait.jpg";
import dorothy from "../../../assets/myz/dorothy-portrait.jpg";
import causualties from "../../../assets/myz/zone-war-casualties.webp";

const cardTitle = {
  paddingTop: 10,
  paddingLeft: 10,
  paddingBottom: 10,
  backgroundColor: "rgba(221, 117, 28, .7) ",
};
const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "DOC GLADHAND [PC]: Sessions 16-62",
    text: (
      <p style={{ minHeight: 100 }}>
        {" "}
        (session 62):
        <br />
        Doc Gladhand was a fixer. He fixed a lot of things. He didn't just
        dream, he did things. He connected people, he brokered deals. When Boss
        Hulagu needed a spokesman, it was Gladhand to whom he handed his
        sunglasses and perfume.
        <br />
        <br />
        Always one to bring people together and build up the Ark, Gladhand is
        also the father of Fatima's baby soon to be born. The Zone will never be
        the same without Doc Gladhand making a deal, shaking hands, and bringing
        people together.
        <br />
        <br />
        <br />
      </p>
    ),
    image: {
      imagePath: docGladhand,
      altText: `Doc Gladhand was a Fixer of the Sunshine Plaza. Boss Hualgu's spokesman and a deal-maker. Father of the child of Fatima.`,
      className: "imageLeft",
      imageCaption: `Doc Gladhand`,
    },
  },
  {
    title: "Patrick Helldriver Leader [NPC]: Session 62",
    text: (
      <p style={{ minHeight: 100 }}>
        {" "}
        (session 62):
        <br />
        <br />
        Patrick was the leader of the Helldrivers.
        <br />
        <br />
        He was killed by the spear tip of a Nova Cultist after being broken by
        MIND TERROR by Plutonia. Fatima of the Sunshine Plaza landed a killing
        blow on the cultist who was leaning on Patrick with a spear and
        therefore he died. He final words were, "No, no! Not like this!" And
        then all the dreams of the boss of this vicious biker gang went dark and
        he joined Harley in whatever vile place constitutes a hell worse than
        the Zone itself.
      </p>
    ),
    image: {
      imagePath: patrick,
      altText: `Patrick, leader of the Helldrivers.`,
      className: "imageRight",
      imageCaption: `Patrick`,
    },
  },
  {
    title: "Harley Helldriver [NPC]: Session 57",
    text: (
      <p style={{ minHeight: 100 }}>
        {" "}
        (session 57):
        <br />
        <br />
        Harley was the second-in-command, squeeze-in-chief, main good girl of
        the Helldrivers.
        <br />
        <br />
        She was killed leading a unit of troops in the Zone War against the
        rusted ramparts of The Sunshine Plaza. Having held a fascination with
        Boss Hulagu for some time, she finally decided he was the BETA and her
        Mr. P was the alpha; and her allegiance to the Helldrivers was ratified
        for the final time before her demise.
      </p>
    ),
    image: {
      imagePath: harley,
      altText: ``,
      className: "imageRight",
      imageCaption: ``,
    },
  },
  {
    title: "Zone War Casualties [various NPC]: Session 58-60",
    text: (
      <p style={{ minHeight: 100 }}>
        :
        <br />
        <br />
        Boss Krao was killed in the zone war as well as others pictured.
        <br />
        <br />
        <br />
        <br />
      </p>
    ),
    image: {
      imagePath: causualties,
      altText: `Zone War casualties include Boss Brao and Chromicus' sweetheart Vanja the clone.`,
      className: "imageRight",
      imageCaption: ``,
    },
  },
  {
    title: "Harry and Darryl [NPC]: Session 53",
    text: (
      <p style={{ minHeight: 100 }}>
        12-24-0001 (session 53):
        <br />
        <br />
        Harry and Darryl are clones from the Oilfield of the Ancients. They came
        with the IL Gang to Command Center Charlie hoping to find a way to
        contact Noatun.
        <br />
        <br />
        They were both killed by TAIL SPIKE attacks from an adult necromorph on
        the final Tram Level of Command Center Charlie.
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageRight",
      imageCaption: ``,
    },
  },
  {
    title: "Dorothy the Protocol Robot [NPC]: Session 51",
    text: (
      <p style={{ minHeight: 100 }}>
        12-16-0001 (session 51):
        <br />
        <br />
        Dorothy was constructed by the IL Gang on level 2 of Command Center
        Charlie. She was a protocol robot but she came loaded with a grenade
        launcher and crowd control. She once blew up 14 Morlocks in one shot of
        the grenade launcher.
        <br />
        <br />
        <br />
        <br />
        She was destroyed by a grenade ironically enough from a Class IV Avenger
        robot on level 3 of command center.
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </p>
    ),
    image: {
      imagePath: dorothy,
      altText: `Dorothy the Protocol Robot.`,
      className: "imageRight",
      imageCaption: `Dorothy the Protocol Robot`,
    },
  },
  {
    title: "Beto the Oilfield Clone [NPC]: Session 51",
    text: (
      <p style={{ minHeight: 100 }}>
        12-16-0001 (session 51):
        <br />
        <br />
        Beto was the squad leader for the four-clone group that accompanied IL
        Gang to Command Center Charlie. He was killed by robots on level 3 of
        the command center.
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </p>
    ),
    image: {
      imagePath: beto,
      altText: `Beto the Oilfield Clone.`,
      className: "imageRight",
      imageCaption: `Beto the Clone`,
    },
  },
  {
    title: "Sparky the Small Vicous Dog [NPC]: Session 49",
    text: (
      <p style={{ minHeight: 100 }}>
        11-11-0001 (session 49):
        <br />
        <br />
        After a night of drunken carousing on CAGE ISLAND, Ghost Bird woke up to
        find he had a new little buddy; a small vicous dog he named Sparky.
        <br />
        <br />
        Sparky died fighting a guard robot in Command Center Charlie. He did get
        through the bot's armor do a point of damage before a laser pistol blast
        sent him to the Doggy Zone in the Sky.
        <br />
        <br />
        <br />
        <br />
      </p>
    ),
    image: {
      imagePath: sparky,
      altText: `Spark the Small Vicious Dog.`,
      className: "imageRight",
      imageCaption: `Sparky the Small Vicious Dog`,
    },
  },

  {
    title: "Theodore [NPC]: Session 49",
    text: (
      <p style={{ minHeight: 100 }}>
        11-11-0001 (session 49): Theodore was a bartending robot in the lounge
        of the RUST CASTLE. He served up drinks and information.
        <br />
        <br />
        He was sent with the IL Gang to investigate Command Center Charlie where
        he was murdered by a Digimax Hoverdrone.
      </p>
    ),
    image: {
      imagePath: theodore,
      altText: `Theodore. Bartending robot from the Rust Castle.`,
      className: "imageRight",
      imageCaption: `Theodore`,
    },
  },
  {
    title: "Boss Krao [NPC]: Session 45",
    text: (
      <p style={{ minHeight: 100 }}>
        09-16-0001 (session 45): Boss Krao was the favorite of the Elder. She
        was the premier Chronicler of the Sunshine Plaza; keeper of the Order of
        the New Dawn. Her intial fear of the Zone and resistance to exploration
        changed as she saw the Ark prosper under the guidance of the IL gang.
        <br />
        <br />
        Her heart was broken after the death of Hanneth the Spoiled. Then, the
        recent death of Danova seems to have pushed her further into depression.
        She was discovered one morning curled into a ball with the photo of the
        elder and the fragment of Hanneth's amulet clutched to her chest. She
        was dead. Simply passed beyond the Zone and the worries of sufferage,
        rot, and the future of The People.
      </p>
    ),
    image: {
      imagePath: krao,
      altText: `Boss Krao. Peaceful chronicler; leader of the Order of the New Dawn.`,
      className: "imageRight",
      imageCaption: `Boss Krao`,
    },
  },
  {
    title: "Danova the Envious [NPC]: Session 44",
    text: (
      <p style={{ minHeight: 100 }}>
        09-02-0001 (session 44): Danova the Envious, a Chronicler, was known as
        the envious because she has fought from day one with Hanneth the
        Chronicler, jealous of her good looks and favor from Boss Krao. Danova
        was pretty with a classic oval face. But her ability to compete with
        Hanneth was ruined by a shredded left ear, and a blind left eye. She
        used to stare nearly unblinking with her good eye which disturbed most
        everyone.
        <br />
        <br />
        She became Krao's right hand near the hand and was a great friend to the
        lost human known as Sylvia.
        <br />
        <br />
        <br />
        <br />
      </p>
    ),
    image: {
      imagePath: danova,
      altText: `Danova, a Chronicler of the Sunshine Plaza. May she rest in peace.`,
      className: "imageRight",
      imageCaption: `Danova the Envious`,
    },
  },
  {
    title: "DOUBLE BIRD THE SECOND [PC]: Session 5-41",
    text: (
      <p style={{ minHeight: 100 }}>
        06-09-0001 (session 41): Double Bird the Second was the twin brother of
        Double Bird the First. He was a slave, a bodyguard, an experimenter.
        <br />
        Double Bird and dead Double Bird were born twins. Twins are unheard of
        so the Arc argued whether the twins were a dark magic or a powerful
        sign. Boss Scag took control and sold one of the children to another
        travelling mutants outside the Ark for bullets.
        <br />
        <br />
        Double Bird #2 also believes he is a shaman. He uses cold baths and
        hyperventilating to bring on a visions. Sensing his loss and separation,
        he discovered this strategy to connect him to the spirit world and his
        brother.
        <br />
        <br />
        Typically upbeat and friendly, but with a curious dark side. He believes
        he is a Tlingkit shaman
      </p>
    ),
    image: {
      imagePath: doublebird2,
      altText: `Double Bird the Second, twin brother to Double Bird the First`,
      className: "imageLeft",
      imageCaption: `Double Bird the Second`,
    },
  },
  {
    title: "Skut [NPC, former PC]",
    text: (
      <p style={{ minHeight: 100 }}>
        06-05-0001: Skut was a PC for many sessions. He converted to NPC status
        after player stepped aside. Skut was a gearhead. He was a colorful
        character that always believed he was a rabbit. Delusions aside, he was
        a capable gearhead and a joyful spot in the grey wastleland of the Zone.
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageRight",
      imageCaption: ``,
    },
  },
  {
    title: "Burton [Wanderer]",
    text: (
      <p style={{ minHeight: 100 }}>
        05-25-0001: Wanderer Burton dies on session body count roll after an Ark
        Battle with Helldrivers. Perhaps it was wounds sustained defending his
        home at the Plaza. Now his wife Mareth must raise their adopted daughter
        Pequena alone (her father Jesus died previously). fsk
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageRight",
      imageCaption: ``,
    },
  },
  {
    title: "Rowbert [NPC, former PC]",
    text: (
      <p style={{ minHeight: 100 }}>
        05-25-0001: Rowbert was a PC for many sessions. He converted to NPC
        status after player stepped aside. Rowbert was a chronicler and an
        inspiration to all for many Zone Expeditions. Fare thee well Rowbert and
        rot angels speed thee to thy rest.
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageRight",
      imageCaption: ``,
    },
  },
  {
    title: "Fortran [NPC]",
    text: (
      <p style={{ minHeight: 100 }}>
        05-25-0001: Fortran was an NPC rescued from the Diamond Center. He was
        instrumental in helping the party overthrow the Director and his cabal
        of cannibalistic females using humans as fodder for labor and food.
        Close friend of Katie Kat and a good man.
      </p>
    ),

    image: {
      imagePath: fortran,
      altText: `Fortran was a human chronicler rescued from the Diamond Center`,
      className: "imageRight",
      imageCaption: `Fortran`,
    },
  },
  {
    title: "Timur the Gearhead [PC]",
    text: (
      <p style={{ minHeight: 100 }}>
        05-17-0001: Timur was a PC from the Sunshine Plaza. He stepped up to act
        as lieutenant of the IL Gang as they prepared to investigate the tunnels
        under the Sunshine Plaza. He was, unfortunately, eaten by a WORM SWARM
        after he charged it, pushed a roll, and broke himself with four points
        damage. The rest of the party made a strategic retreat as Timur served,
        literally, as worm food.
      </p>
    ),

    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: ``,
    },
  },
  {
    title: "JEBE THE DOG HANDLER [PC]: Session 1-28",
    text: (
      <p style={{ minHeight: 200 }}>
        05-17-0001: Jebe was a PC from the Sunshine Plaza. He lived, fought,
        commanded hounds, and drove the story of The People from Session 1 to
        Session 28. His final act of heroism was kick starting the Battle of the
        Diamond Center in the lobby when he ran out of hiding to lob a BP 12
        scrap bomb onto a mezzanine. Jebe fought valiantly halfway up the
        escalators. Alas, after two shots, a spear stab, and acid spit he left
        the People. Light a candle at the Temple of Endurance the next time you
        are at the Sunshine Plaza.
        <br />
        <br />
        <pre style={{ color: "white" }}>
          Alas, poor Jebe! I knew him Double-Bird.
          <br />
          Healer of Chromicus. Master of Subu, Rocket, Tiska, and Tiska 2.
          <br />
          A solid mutant like no other the Sunshine Plaza has known.
          <br />
          Buddy of Double-Bird, and beloved of all.
          <br />
          The hounds will howl tonight.
        </pre>
        <br />
        <br />
      </p>
    ),

    image: {
      imagePath: death_of_jebe,
      altText: `Jebe died blowing up Rebeth and other mutant scum at the Diamond Center. RIP.`,
      className: "imageLeft",
      imageCaption: `JEBE THE DOG HANDLER`,
    },
  },
  {
    title: "Freddy the Giver",
    text: (
      <p style={{ minHeight: 200 }}>
        05-17-0001: Freddy was a human "giver", enslaved by the despotic Diamond
        Center. He was the leader of the givers in cages in the basement. He
        died in session 28 in the lobby of the Diamond Center fighting for his
        freedom and the freedom of the other givers.
        <br />
        <br />
        <br />
        <br />
        <br />
      </p>
    ),

    image: {
      imagePath: freddy,
      altText: `Freddy was a human enslaved at the Diamond Center. RIP.`,
      className: "imageRight",
      imageCaption: `Freddy the Giver`,
    },
  },
  {
    title: "Luna the Chronicler",
    text: (
      <p style={{ minHeight: 200 }}>
        05-17-0001: Luna was an ex-skag mutant turned ally after being found in
        the Zone. He died in session 28 fighting in the lobby of the Diamond
        Center.
        <br />
        <br />
        <br />
        <br />
        <br />
      </p>
    ),

    image: {
      imagePath: luna,
      altText: `Luna was an ex-skag gang mutant turned ally. RIP.`,
      className: "imageRight",
      imageCaption: `Luna the Chronicler`,
    },
  },

  {
    title: "Kyle the Unmotivated",
    text: (
      <p style={{ minHeight: 200 }}>
        05-17-0001: Kyle was an ex-skag mutant turned ally after being found in
        the Zone. He died in session 27 in a hallway battle at the Diamond
        Center whilst freeing Giver humans.
        <br />
        <br />
        <br />
        <br />
        <br />
      </p>
    ),

    image: {
      imagePath: kyle,
      altText: `Kyle was an ex-skag gang mutant turned ally. RIP.`,
      className: "imageRight",
      imageCaption: `Kyle the Unmotivated`,
    },
  },
  {
    title: "Hetu the Beast Whisperer",
    text: (
      <p style={{ minHeight: 200 }}>
        05-17-0001: Hetu was the beast trainer for the Sunshine Plaza. He even
        accompanied party on Scrap Oracle Cassandra adventure. Alas, the session
        body count roll for Session 26 claimed him; and just as GM arbitrarily
        bumped his training skill up a notch to indicate progress.
        <br />
        <br />
        <br />
        <br />
        <br />
      </p>
    ),

    image: {
      imagePath: hetu,
      altText: `Hetu the beast trainer of the Sunshine Plaze will be missed. RIP.`,
      className: "imageRight",
      imageCaption: `Hetu, beast trainer`,
    },
  },
  {
    title: "Wanderer Dima",
    text: (
      <p style={{ minHeight: 200 }}>
        05-17-0001: Wanderer Dima died in the Pure Plant Patrol roll for Session
        26. She accompanied the Pure Plant Patrol out of curiosity and to see if
        she could score some interesting drugs at the Pure Plant.
        <br />
        <br />
        Wanderer Dima was a very ethereal lady. She liked to sample any
        interesting (questionable) substance.
        <br />
        <br />
        She could be vacant and wrapped up in herself, but was widely considered
        friendly. Smoke Raven will miss lighting up some zone weed with her at
        the Temple of Endurance to dream of a better future.
        <br />
        <br />
        <br />
        <br />
        <br />
      </p>
    ),

    image: {
      imagePath: dima,
      altText: `Wanderer Dima was a very ethereal lady. She liked to sample any
        interesting (questionable) substance. She could be vacant and wrapped up in
        herself, but was widely considered friendly. Smoke Raven will miss lighting
        up some zone weed with her at the Temple of Endurance to dream of a better
        future.`,
      className: "imageRight",
      imageCaption: `Wanderer Dima`,
    },
  },
  {
    title: "Wanderer Demyan",
    text: (
      <p style={{ minHeight: 200 }}>
        05-17-0001: Wanderer Demyan died as part of the body count roll for
        session 25. His ill treatment at the hands of the Helldrivers, from whom
        he was rescued by the IL Gang, left him perpetually weak and partially
        blinded. Finally, he simply wasted away persistent sickness he picked up
        months ago.
        <br />
        <br />
        <br />
        <br />
        <br />
      </p>
    ),

    image: {
      imagePath: demyan,
      altText: `Demyan is a human the IL Gang rescued from the Helldrivers. He later died as part of the body count roll for Session 25`,
      className: "imageRight",
      imageCaption: `Wanderer Demyan`,
    },
  },
  {
    title: "Silas the Chronicler",
    text: (
      <p style={{ minHeight: 200 }}>
        05-15-0001: Silas the Chronicler died due to the body count roll for
        session 23. He was found hanging in his Den. He had been talked out of
        suicide by Smoke Raven on the benches of the Temple of Endurance. Silas
        was filled with remorse for poisoning the Helldrivers with the offered
        water; a move he performed with no oversight or approval. He did it is
        secret. When the Helldrivers returned to exact vengeance, Silas blamed
        himself and after the Civil War, he was just too emotionally fractured
        to carry on. He left a suicide note and bequathed his staff to Boss
        Krao.
        <br />
        <br />
        Note also that Jebe's hound Tiska 2 was killed by zone hounds.
        <br />
        <br />
        Also, in the same battle with zone hounds, Smoke Raven's bitterbeast
        mount Valegro was killed.
        <br />
        <br />
        <a
          href="./mutant-year-zero-session-23-anchortown"
          rel="noreferrer"
          target="_blank"
        >
          Session 23
        </a>
        .
        <br />
        <br />
        <br />
        <br />
        <br />
      </p>
    ),

    image: {
      imagePath: silas,
      altText: `Silas the Chronicler. A true chronicler, taken by self-recrimination and despair.`,
      className: "imageRight",
      imageCaption: `Silas the Chronicler`,
    },
  },
  {
    title: "Elson the Dog Mutant",
    text: (
      <p style={{ minHeight: 200 }}>
        04-02-0001: Elson died in the Civil War as well but gets his own entry.
        <br />
        <br />
        He was found in the Zone and taken in after a battle near a military
        base on catalog way back in
        <a
          href="./mutant-year-zero-session-02-anchortown"
          rel="noreferrer"
          target="_blank"
        >
          Session Two
        </a>
        .
      </p>
    ),
    image: {
      imagePath: elson,
      altText: `Elson the Dog Mutant; may he RIP in peace.`,
      className: "imageRight",
      imageCaption: ``,
    },
  },
  {
    title: "CIVIL WAR CASUALTIES",
    text: (
      <p style={{ minHeight: 200 }}>
        04-02-0001: The Civil War greatly affects the population of the Ark due
        to death and also factions leaving the Sunshine Plaza. The dead are
        SEVENTEEN with a breakdown as follows.
        <br />
        <br />
        <h4>Civil War Casualty Roster [18 dead]</h4>
        <ul>
          <li>Skag Faction: BOSS SKAG</li>
          <li>Neutral: Wanderer Orben (human)</li>
          <li>Neutral: Torque the Fixer (mutant)</li>
          <li>Neutral: Sergei the Wander (human)</li>
          <li>Neutral: Medic (mutant)</li>
          <li>Krao Faction: Wanderer Viktor (human)</li>
          <li>Krao Faction: engineer (mutant)</li>
          <li>IL Gang: Elson, Dog Mutant</li>
          <li>IL Gang: Oren the Meticulous (mutant)</li>
          <li>IL Gang: Wander Jesus (human)</li>
          <li>IL Gang: Wanderer Elroy (human)</li>
          <li>IL Gang: Wanderer Montana (human)</li>
          <li>IL Gang: Wanderer Tobin (human)</li>
          <li>IL Gang: Soldier (mutant)</li>
          <li>IL Gang: Strategist (mutant)</li>
          <li>IL Gang: Janis (mutant)</li>
          <li>IL Gang: Exkurtzer (mutant)</li>
          <li>IL Gang: Soldier (mutant)</li>
        </ul>
      </p>
    ),
    image: {
      imagePath: civilWar,
      altText: ``,
      imageCaption: `Civil War Deaths`,
      className: "imageRight",
    },
  },
  {
    title:
      "Hanneth the Spoiled, Chronicler of the Order of the New Dawn of The Sunshine Plaza",
    text: (
      <p style={{ minHeight: 200 }}>
        03-22-0001: Hanneth was in her room gazing on her reflection in a
        fragment of the Aranmula Kannadi mirror artifact that was turned into
        the Order. Krao allowed her to hold onto the fragment after the artifact
        was dismantled and studied. She was staring at her nearly perfect
        features, basking in her nacissistic self-congratulations when she heard
        the roar of vehicles and the whoops and hollers of the Helldrivers
        attacking the Ark.
        <br />
        <br />
        She immediately joined her fellow Chroniclers in running information
        back and forth between bosses and fighting forces, helping coordinate
        the heroic counter attack and defenses mounted by her people. The
        Helldrivers breeched the courtyard of the Plaza. Hanneth was on her way
        to warn Berke and his sharpshooters on the roof about the breech with
        Grandma Yoya following her. They ran into Boss Patrick of the
        Helldrivers' psychotic girl Harley. Details of that encounter are soon
        to be legend in the Sunshine Plaza and forthcoming in session notes.
      </p>
    ),
    image: {
      imagePath: hanneth,
      altText: `Hanneth the Spoiled. A chronicler of the Sunshine Plaza. Favored "daughter" of Boss Krao. Her vanity did not deserve 
        such defacement.`,
      className: "imageRight",
      imageCaption: `Hanneth the Spoiled`,
    },
  },
  {
    title: "Lambda the Gearhead of The Sunshine Plaza",
    text: (
      <p style={{ minHeight: 200 }}>
        03-17-0001: while the party is en route back to the Ark with the medical
        supplies needed for the parasitic fungus pandemic, Lamda died. She was
        met at the Pure Plant while pinned down with Naptha and Grit. She was
        the most friendly member of Skag's gang to date and as one of The People
        her wit and her skills with scrap will be missed.
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </p>
    ),
    image: {
      imagePath: lambda,
      altText: `Lambda the Gearhead of the Sunshine Plaza died from parasitic fungus pandemic in the Ark.`,
      className: "imageRight",
      imageCaption: `Lambda the Gearhead`,
    },
  },
  {
    title: "Subu: Session 7",
    text: (
      <p style={{ minHeight: 200 }}>
        03-15-0001: Subu was the faithful hound of dog handler Zebe. She fought
        effectively and scouted up a storm for seven sessions. She fell to a
        bullet from a scrap rifle while fighting Zone Ghouls in the courtyard of
        skyscraper in Sector B11. She was butchered and zone cooked into four
        units of rot-free grub and consumed with veneration. She gave her life
        to sustain The People of Sunshine Plaza.
      </p>
    ),
    image: {
      imagePath: subu,
      altText: `Subu. Hound of Anchortown.`,
      className: "imageLeft",
      imageCaption: `Subu the Hound`,
    },
  },
  {
    title: "Zane",
    text: (
      <p style={{ minHeight: 200 }}>
        03-10-0001: Zane was among the trio of mutants found in the Zone on the
        first adventure. He failed roll while sick with parasitic fungus and
        wandered the Ark. He blames himself for infecting The People and after
        Loud Sue was assassinated and Double Bird died, he learned that the love
        of his life Bianca was prostituting herself to Boss Skag's gang to get
        food for him. It was all too much and Zane committed suicide.
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </p>
    ),
    image: {
      imagePath: zane,
      altText: ``,
      className: "imageRight",
    },
  },
  {
    title: "Tanner the Slave",
    text: (
      <p style={{ minHeight: 220 }}>
        03-07-0001: Tanner the Slave of Boss Skag's gang died from parasitic
        fungus on body count roll. Bad karma for messing with Skut and Elson the
        Mutant Dog.
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </p>
    ),
    image: {
      imagePath: tanner,
      altText: ``,
      className: "imageRight",
    },
  },
  {
    title: "Nelma the Enforcer",
    text: (
      <p style={{ minHeight: 200 }}>
        03-07-0001Nelma the Enforcer of Boss Skag's gang died at the Pure Plant
        in Sector B04.
      </p>
    ),
    image: {
      imagePath: nelma,
      altText: ``,
      className: "imageRight",
    },
  },
  {
    title: "DOUBLE BIRD [PC]: Session 1-3",
    text: (
      <p style={{ minHeight: 230 }}>
        03-06-0001: Double Bird: PC mutant. Died from ravages of parasitic
        fungus contracted from first trip into the Zone.
        <br />
        <br />
        CHARACTER run by Tom.
        <br />
        <br />
        Be on the lookout for Double-Bird's twin brother that was sold to
        wandering mutants as a baby. I have a feeling that kids grown and
        pissed.
      </p>
    ),
    image: {
      imagePath: doubleBird,
      altText: ``,
      className: "imageLeftGlow",
    },
  },
  {
    title: "Boss Loud Sue (proletariat): Session 2",
    text: (
      <p>
        03-03-0001: Boss Loud Sue: NPC mutant. Boss Loud Sue of The People of
        Sunshine Plaza; died crushed skull inflicted by fellow mutant Forcept in
        a cowardly assassination for reasons unknown.
      </p>
    ),
    image: {
      imagePath: loudSue,
      altText: ``,
      className: "imageRight",
    },
  },
];

const MYZAnchortownGraveyard = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">Anchortown: Graveyard</h1>
                <p className="lead leadExtra">
                  Anchortown: Chronicles of the Sunshine Plaza is a Mutant Year
                  Zero campaign set in the area known as Anchorage, Alaska long
                  before the apocalypse.
                  <br />
                  This page celebrates those lost along the way.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Title style={cardTitle}>GRAVEYARD</Card.Title>

                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownGraveyard;
