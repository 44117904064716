import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import BRSubNav from "./BRSubNav";
import bladeRunnerLogo from "../../../../src/assets/blade-runner/logo-blade-runner.webp";
import sectionDivider from "../../../../src/assets/blade-runner/blade-runner-section-divider-01.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import { useEffect } from "react";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "",
    text: (
      <p class="bladeRunnerFont">
        The sun goes down, the neon lights ignite the mist and mayhem, and Los
        Angeles comes alive in all its gloom and glory. You walk the streets,
        both badge and boogeyman. This city fears you. Resents that it needs
        you. Refuses to accept that you're here to stay. And yet that's your
        job. To stand in the rain, steam, and shadows amidst the seething crowds
        and chaos. Relentlessly pursuing what never wants to be found. That lone
        silhouette, bathed in light and darkness. Looking for meaning and
        morality in both, and finding yourself somewhere in between.
        <br />
        <div
          style={{ textAlign: "center", fontWeight: "600", fontSize: "1.6em" }}
        >
          You are a Blade Runner of the Los Angeles Police Department's
          Rep-Detect Unit.
        </div>
        It's your job to delve down the darkest alleys, strut through City Hall,
        and see enough stains on every soul and shitheel to spot one from meters
        away. All to keep one step ahead of whatever you're chasing and
        whatever's chasing you. You're sorry it had to be you, but if LA ever
        had heroes, they went off-world ages ago. All that's left are trapped
        rats and fallen angels, fighting to make do on a dying planet any way
        they can. All you can do is work it more than it's working you.
        <br />
        <br />
        Let's face it, LA is a cacophony of cultures and moral contradictions on
        the best of days, a snarling warzone on the worst. Yet when you look
        upon this city, you see 30 million stubborn survivors. And you swore an
        oath to protect them. Because it's your home. Your brutal, beautiful
        mess. And when it counts, you swear this place and its people are
        capable of more hope and compassion than anybody gives them credit for.
        So screw the off-worlders for giving up on this city. And screw the
        megacorps for thinking somebody's rights and standard of living should
        be based upon who you know and what you're born with, not what you
        deserve.
        <br />
        <br />
        More each day you'll learn that the system is broken. That the rich
        write rules only the poor must follow. That the bad guys win while the
        good guys do paperwork. That the greatest threats are far outside your
        reach, above your pay grade, and below your moral belt. And the more
        you're exposed to the city's worst corruptions and seductions, the more
        you're outnumbered, the more you're expected to run into the fight.
        Making it out alive is your problem.
        <br />
        <br />
        This is what it means to be a Blade Runner. To be a Replicant. To play a
        rigged game you're intended to lose. To serve a system that doesn't
        protect you, but expects you to protect it. But if anybody's playing
        against the house, better somebody with a badge. Hey, even pawns can
        keep kings in check. On good days, they can even make them fall.
        <br />
        <br />
        And in those moments, it doesn't matter if inside you is a soul or
        synthetics. Because when you pull that blaster, you're nothing but a
        Blade Runner. The closest thing this city's got to a fighting chance.
        And you've got a job to do.
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
];

const BROverview = () => {
  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/blade-runner/background-blade-runner-01.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={bladeRunnerLogo}
                  style={{
                    maxWidth: "800px",
                    backgroundColor: "white",
                    borderRadius: "10px",
                  }}
                  alt="Blade Runner campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">Blade Runner Overview</h1>
                <p className="lead leadExtra">
                  <BRSubNav />
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img
                src={sectionDivider}
                style={{ maxHeight: "100px" }}
                alt="section divider"
              />
              <Card.Body>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BROverview;
