import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import mbLogo from "../../../../src/assets/mork-borg/mork-borg-logo.webp";
import sectionDivider from "../../../../src/assets/mork-borg/mork-borg-section-divider.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";
import gettingBetter from "../../../../src/assets/mork-borg/session03/session-03-getting-better.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const MBMalumMortisSession03 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/mork-borg/bkgnd-01.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  const section1Data = [
    {
      title: "",
      text: (
        <div class="morkBorgFont morkBorgTitleText">
          <ImageComponent
            image={gettingBetter}
            caption="Getting Better"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Getting Better for Session 03 of Malum Mortis campaign."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>

          <p>
            Our party consists of:
            <ul>
              <li>
                <span style={{ color: "#00FFFF" }}>HILDEBRAND</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>HERETICAL PRIEST</span>,
                Hildebrand worries constantly, washes his hands often, and is
                obsessively clean and neat. He was baptized by fire!
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>SPUG</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>WRETCHED ROYALTY</span>{" "}
                afflicted with kleptomania and afraid of pain. Pain is such a
                bother!
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>HERMAN</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>GUTTERBORN SCVM</span>. He is
                worried and naive with hands caked in sores. He is a bit a
                pyromaniac.
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>KLATUUBARADA</span>: an
                argumentative, pyromaniacal{" "}
                <span style={{ color: "#ADFF2F" }}>FUGITIVE KNIGHT</span>.
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>SIR FAT HEAD</span>: an
                wacked out{" "}
                <span style={{ color: "#ADFF2F" }}>MUSHROOM EATER</span>{" "}
                (Svampätare).
              </li>
              <li style={{ fontSize: "0.8em" }}>
                <span style={{ color: "#00FFFF" }}>Donkey Hoatee</span>: donkey
              </li>
              <li style={{ fontSize: "0.8em" }}>
                <span style={{ color: "#00FFFF" }}>
                  Poltroon the Court Jester
                </span>
                : jester minion to Spug
              </li>
              <li style={{ fontSize: "0.8em" }}>
                <span style={{ color: "#00FFFF" }}>
                  Barbarister the Incredible Horse
                </span>
                : talking horse minion (master?) to Spug
              </li>
              <li style={{ fontSize: "0.8em" }}>
                <span style={{ color: "#00FFFF" }}>Jaque Manaque</span>: wannabe
                knight squire-lad trying to earn his keep
              </li>
            </ul>
            Our session starts with Sir Fat Head noticing a gang of scvm about
            to storm Nagel Krat's Goblin Grinder. He stumbles out of a nearby
            alley, shaking off the addled fog of his mushroom haze, and joins
            the party. In addition to Sir Fat Head, the scvm have two Schleswig
            guards in tow thanks to some clever parlay with Vizier Crudite.
            <br />
            <br />
            And so the party battles their way through Goblin Grinder. With only
            mild spoilers, some highlights were:
            <ul>
              <li>
                Rather than picking the lock or kicking down the door to the
                grain tower, it was burned down since we have two pyromaniacs in
                the party.
              </li>
              <li>
                Sir Fat Head finds the spare key to the grinder on papa Krat's
                corpse in the grain cellar.
              </li>
              <li>
                Jacque Manaque proves pretty damn worthy in battle and manages
                to survive to everyone's surprise. This is for the best as the
                party signed a contract at Lotta Gravy with a lawyer stating
                they would pay 70 silver to Jacque's father if he died within 7
                days of starting to squire with them.
              </li>
              <li>
                True to his nature as a chemical adventurer, Sir Fat Head licks
                the black liquid after cannon blows away alchemical detritus.
              </li>
              <li>
                The final battle has 6 goblins, The Bastard, Nagel Krat, and
                Qarg. It is a nail-biter that seems certain to end in several
                character deaths; however, Herman the gutterborn scum used he
                gob lobbing ability. It disables two goblins for several rounds
                and is a game-changer.
              </li>
              <li>
                <b>Nagel Krat escapes thanks to his emergency smoke bomb.</b>
              </li>
              <li>
                All goblins and Nagel's shovel-wielding minion Qarg are killed.
              </li>
            </ul>
            NEXT SESSION: we start with resting, getting back to Crudite, and
            getting paid.
            <br />
            <br />
            This episode of Malum Mortis brought to you in part by:
            <br />
            Game:{" "}
            <a href="https://morkborg.com/content/" rel="noreferrer">
              Mork Borg
            </a>
            <br />
            Module:{" "}
            <a
              href="https://www.drivethrurpg.com/product/328612/MORK-BORG-CULT-FERETORY"
              rel="noreferrer"
            >
              Goblin Grinder [FERETORY]
            </a>
            <br />
            Class:{" "}
            <a href="https://www.drivethrurpg.com/product/343569/Svampatare-The-Mushroom-Eater--A-Mork-Borg-Class">
              Svampätare: The Mushroom Eater
            </a>
            <br />
          </p>
        </div>
      ),
    },
  ];

  return (
    <Container>
      {/* <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin" 
                    style={{position: 'float', float: 'right',width: '120px', height: '240px'}} marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=smokeraven-20&language=en_US&marketplace=amazon&region=US&placement=9189143701&asins=9189143701&linkId=d1384e0e6b9ef083fee35d7272a71f88&show_border=true&link_opens_in_new_window=true"></iframe> */}
      <Row>
        <Col>
          <div
            style={{ backgroundColor: "transparent", fontFamily: "Orbitron" }}
          >
            <main role="main" className="inner cover text-center">
              <img
                src={mbLogo}
                style={{ maxWidth: "200px" }}
                alt="Mutant Year Zero campaign"
              />
            </main>
            <div style={pageHeader}>
              <h1 className="cover-heading">Session 03: Malum Mortis</h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title className="cardTitle-mork-borg">
                GOBLIN GRINDER
              </Card.Title>
              <Card.Text>
                <ContentContainer data={section1Data} />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MBMalumMortisSession03;
