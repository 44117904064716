import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import endel from "../../../../assets/myz/endel-the-shadow-portrait.webp";
import clarkStanley from "../../../../assets/myz/clark-stanley-robot.webp";
import drawing from "../../../../assets/myz/eden-art-drawing.webp";

import edenRegained from "../../../../assets/myz/session-64-65-eden-regained-half-page.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "EDEN LOST",
    text: (
      <p>
        <b>WARNING: Major Spoilers for EDEN campaign below.</b>
        <br />
        <br />
        The party travels through the vehicle tunnels of EDEN by the dim green
        light of the facility. They{" "}
        <span style={{ color: "#FF7F50" }}>
          recover a steam car, and a motorcycle
        </span>{" "}
        to speed their journey the several miles into the heart of the mountain
        contained Command Center Delta.
        <br />
        <br />
        <span style={{ color: "#FF7F50" }}>
          Mysterious, reptilian mutants using sonar navigation attack the party
          in the tunnel
        </span>
        . Ghost Bird breaks himself scouting, Hulagu has a mutanion misfire
        resulting disorientation; meanwhile, Smoke Raven blows away what we will
        come to know are Omega mutants. Hulagu does his patented frogleap-katana
        move, and Chromicus flys off Chi Tzu with his vibroblade in hand and
        kills TWO Omega mutants in one round.
        <br />
        <br />
        Chromicus sees a smaller mutant, a seemingly child version of the Omega
        mutants in a duct in the ceiling. Chromicus pets Chi Tzu and calms the
        frightening but curious boy mutant. Throughout the party's exploration
        of EDEN, this boy Omega mutant named Endel follows them, silently
        tracking their progress and staying just out of reach.
        <br />
        <br />
        As the party explores, a narration of the Command Center Delta history
        is played out on the monitors. It is spoken in a southern accent that we
        later find out is made by a cleaning robot with a mounted monitor
        showing the ancient entrepreneur and mogul Clark Stanley with an n-th
        generation AI of his personality loaded into the flimsy robot chassis.
        <br />
        <br />
        In a horrific nursery room filled with hundreds of cages and medical
        clipboards, Chromicus finds a child's drawing. &nbsp;
        <br />
        <span style={{ color: "#FF7F50" }}>
          Chromicus can remember making this drawing when he was a child
        </span>
        .
        <br />
        The drawing shows a woman at the top that Chromicus recalls was his
        childish conception of what a mother would be if he had ever had one. He
        remembers liking the male doctor he draw, but disliking the female
        doctor in the drawing. Another mutant in the drawing is not Chromicus,
        and Chromicus remembers with a disorienting blast of deja vu that the
        child mutant he drew was Kurtz.
        <br />
        <br />
        As the party investigates the military compound,{" "}
        <span style={{ color: "#FF7F50" }}>
          Dr. Retzius appears on monitors to reveal her role in designing the
          Omega mutants
        </span>{" "}
        and taunt the Alpha mutants (aka the players). Chromicus shouts back
        "This is our time, let it go! We can reproduce!" to Dr. Retzius' deaf
        ears. Clues through out the destroyed command center, the abandoned
        cafeterias, and the empty barracks are found on monitors, in desks, and
        among the dessicated corpses of a civil war that decimated the compound.
        The back story of Dr. Sebedius (aka The Elder of the Sunshina Plaza) and
        other scientists who resisted Dr. Retzius' desire to destroy the Alphas
        and instead focus on a new mutant breed.
        <br />
        <br />
        As the truth of what EDEN really is and was unfolds, it is a bit like
        EDEN LOST as the vestiges of hope and innocence crumble from the
        onslaught of reality.
        <br />
        <br />
        XP discussion highlighted some cool actions/events:
        <ul>
          <li>
            Ghost Bird breaking himself with four fails on a pushed roll, then
            healed by robot MULTI.
          </li>
          <li>
            Chromicus using vibroblade to devastating effect and also using it
            to slice open an electrical panel so party could escape a trapped
            room.
          </li>
          <li>
            Hulagu doing the classic frogleap-katana combo several times and
            using bookworm to help figure out medical charts.
          </li>
          <li>Bayazid jury-rigging shit like he do.</li>
          <li>Chromicus telling a great story behind drawing he found.</li>
          <li>
            Ghost Birds use of clairvoyance adding back story and details to
            story.
          </li>
        </ul>
      </p>
    ),

    image: {
      imagePath: endel,
      altText: `Endel the Shadow was a helpful child Omega mutant.`,
      className: "imageLeft",
      imageCaption: "ENDEL",
    },
    image2: {
      imagePath: drawing,
      altText: `A drawing Chromicus made as a child found in EDEN.`,
      className: "imageRight",
      imageCaption: "Chromicus' Drawing",
    },
  },
  {
    title: "EDEN REGAINED",
    text: (
      <p>
        The lower level of EDEN is fraught with trapped rooms and Dr. Retzius'
        Omega mutants. Some highlights:
        <ul>
          <li>
            Bayazid breaks himself from automatic fire, recalling his autofire
            epic moment at the Nova Cult in the battle walker.
          </li>
          <li>
            Kohen and his hound Nails heal party members effectively twice
            during fraught battles.
          </li>
          <li>
            Ghost Bird continues to enjoy blasting the shit outta bad guys with
            his BOOMSTICK.
          </li>
          <li>
            <span style={{ color: "#FF7F50" }}>Hulagu breaks his katana</span>,
            takes agility damage from pushed roll, damages his gun during
            extreme reflex, but kills his target.
          </li>
          <li>
            Chromicus again shows the majestic, slicing power of the vibroblade.
          </li>
          <li>
            Ghost Bird uses clairvoyance on the door to cryo lab and sees how
            Dr. Retzius put her detractors to sleep, and even a couple of her
            supporters were put on ice in case she needed them years later as
            she aged so that the new Project Eden could continue.
          </li>
          <li>
            <span style={{ color: "#FF7F50" }}>
              Kohen throws a grenade into cryo lab
            </span>{" "}
            where awakening human scientists, party members, and Nails the dog
            dive for cover as the blast fails to damage the monsterous, wormlike
            failed mutant abomination knows as the TITAN.
          </li>
          <li>
            After the TITAN swallows a scientist whole, the party knows to fight
            from a distance and once they know the horror does not have armor
            their hope skyrockets.
          </li>
          <li>
            The TITAN grabs and swallows Hulagu and breaks him.{" "}
            <span style={{ color: "#FF7F50" }}>
              Hulagu suffers from a CRUSHED FACE
            </span>{" "}
            and will die in five hours.
          </li>
          <li>
            <span style={{ color: "#FF7F50" }}>Bayazid blasts the TITAN</span>{" "}
            will FULL TEN MUTATION POINT PYROTECHNIC BLAST. He suffers a misfire
            wherein he loses an ability point (Wits from 3 down to 2) and gains
            TELEPATHY mutation.
          </li>
          <li>
            <span style={{ color: "#FF7F50" }}>
              Chromicus finesh off the TITAN with a crossbow shot
            </span>{" "}
            into it's eye for SEVEN points damage thanks to an INSPIRATION
            assist from GHOST BIRD.
          </li>
        </ul>
        <br />
        <br />
        Hulagu is retrieved from the body of the TITAN. Based on advice from the
        surviving scientists who side with the party, they go into the GENETICS
        LAB to rest. In the lab they find an EDEN LOG that further details the
        last missing pieces of the story of Project Eden.
        <br />
        <br />
        They also find a syninge of REGEN.{" "}
        <span style={{ color: "#FF7F50" }}>
          Chromicus offers the syringe to Hulagu, "This is for you boss."
        </span>{" "}
        The REGEN heals all the permanent ability loss that Hulagu suffered.
        While he retains the mantis face and, and Smoke Ravan says, "still looks
        like shit", there is no denying that Boss Hulagu is restored from his
        crippling ability losses and is back in fine form. As the rest period
        comes to an end, there is a rumbling sound. Scientist Jake (from State
        Farm)cries out, "it's the rocket. We have to get into the command room
        before they escape."
        <br />
        <br />
        The party confronts Dr. Retzius and her assistant Lilith in the command
        room. Through a huge window they can see a rocket primed for takeoff
        with an 8 minute countdown. The two terrified but determined scientists
        are hiding behind a desk with maser pistols trained on the overwhelming
        force of the IL Gang.
        <br />
        <br />
        <span style={{ color: "#FF7F50" }}>
          Smoke Raven speaks of how they welcome everyone, including former
          enemies, and that there is room for them in The Zone at the Plaza.
        </span>{" "}
        Dr. Retzius lowers her pistol but Dr. Lilith is firmly opposed.
        <br />
        <br />
        Chromicus steps up to INTIMIDATE Lilith. "Sit down and do nothing.
        Everything that has ever challenged us, we have defeated, and you are
        nothing." He succeeds and Lilith's choice is to attack. Her shot does
        one point of damage and Chromicus laughes it off, literally. Cowed by
        the owerwhelming forces, Lilith pauses. Dr. Retzius looks at her. "Put
        it down Lilith, just look at his forehead" she says, referring to the
        symbol of Mimir, their extraterrestrial enclave, that appears on
        Chromicus' face.
        <br />
        <br />
        <span style={{ color: "#FF7F50" }}>
          The rocket is set on a non-stoppable takeoff back to the Mimir
          enclave.
        </span>
        The discussion of who will go to MIMIR and who will stay ensues. Dr.
        Retzius, Lilth, and one of the three surviving scientists will go back
        as prisoners and criminals to face charges. The other two scientists
        that survived (Jake and Dawn) will also go home to Mimir and tell of Dr.
        Retzius driving the other scientists into the Zone and her mad plans to
        create the Omega mutants.
        <br />
        <br />
        As for our heroes, it goes like this:
        <ul>
          <li>
            Smoke Raven has always been driven to solve the mysteries of the
            zone; like a true stalker. And now, there are many more mysteries to
            solve. He and Grit go on the ship to continue the adventure in outer
            space.
          </li>
          <li>
            Chromicus once again ponders how his dream shifted when he changed
            from Enforcer to Slave. At first he wanted to keep people safe, then
            his motivation became more keeping himself safe. And now he heart
            changes again. He has lost everything. He lost Gladhand, Rel, Vanja,
            and others including many of the Kurtzers. And so, Chromicus, the
            Enforcer turned Slave, will go to new worlds with the Silver Egg
            robot assistant MULTI at his side.
          </li>
          <li>
            Hulagu says, "Eden is still out there. Even if Eden is a lie, I want
            to see it." And just like that, the Boss of the Sunshine Plaza
            decides on outer space.
          </li>
          <li>
            Bayazid, Ghost Bird, and Kohen will return to The Zone to caretake
            the building of the real EDEN.
          </li>
        </ul>
        It turns out EDEN was real; and it was the Sunshine Plaza. It was the
        heroism and vision of the IL Gang. It was the collaboration and
        sacrifice of allies at Noatun Estates and the Rust Castle. It was
        forgiveness when at all possible, and a swift, honorable death if
        required.
        <br />
        <br />
        Despite a civil war, a zone war, and betrayals, our heroes build an Ark
        that connected mutants and human alike. Birthing a new generation with
        even the HELLDRIVERS welcome in their world. Fatima and her child Glib
        will lead the Sunshine Plaza, building alliances, expanding the Temple
        of Endurance ad always remembering the lost heroes Ghost Bird, Jebe, and
        her lost partner Doc Gladhand; and she will take comfort in Kohen's
        return with Nails to stand beside her and build a better world.
        <br />
        <br />
        Ghost Bird recalls how Chromicus told him "You're the Oracle now" before
        stepping onto the spaceship. And so, Ghost Bird will shepherd the
        followers of the Silver Egg, and he will buildi an institute and museum
        of higher learning with Bayazids help.
        <br />
        <br />
        Back in EDEN, the shadow mutant Endel watches in wonder as the fiery
        rocket with the Mimir logo launches into the smoldering grey sky of The
        Zone. His curiosity leads him to start walking the Zone. Somewhere, far
        off toward the horizon, is a new world where all are welcome, danger
        lurks in every sector, and legends are made by those whose hearts stay
        strong and remain unbroken.
        <br />
        <br />
        THE END . . .
      </p>
    ),
    image: {
      imagePath: clarkStanley,
      altText: `Clark Stanley was a futuristic snake oil salesman. An entrepreneur and shady bastard of the highest order.`,
      className: "imageRight",
      imageCaption: "CLARK STANLEY",
    },
    image2: {
      imagePath: edenRegained,
      altText: `The Sunshine Plaza is a beacon of hope in a rotting world.`,
      className: "imageLeft",
      imageCaption: "EDEN REGAINED",
    },
  },
  {
    title: "CAMPAIGN WRAPUP",
    text: (
      <p>
        That's it folks. The end of the campaign. Kevin, Rob, and Tom created
        the best game I have every experienced. There will be many memories.
        Here are a few things I know I'll remember and some thoughts on MUTANT
        YEAR ZERO:
        <ul>
          <li>
            PC who can fly or frog leap with extreme reflexes and fast draw can
            do some deadly stuff in one round!
          </li>
          <li>
            Some critical skills/talents as we played seemed to be Combat
            Veteran, Weapon Specialist, Hawkeye, and others. While there are
            combinations and so forth, nothing in MYZ is overbalanced or out of
            hand.
          </li>
          <li>
            Jebe and his hounds Rocket, Tiska, Tiska 2, and the stalwart Subu.
          </li>
          <li>
            Double Bird I and II with cool backstory of being twins that we tied
            into Scrap Oracle Cassandra also being from the Plaza.
          </li>
          <li>
            Double Bird II going out with a literal bang with grenade as rot
            finally finished off this hero.
          </li>
          <li>
            Doc Gladhand always making a deal, giving speeches, pushing for
            collaboration, raising bitterbeasts, and fighting hard when needed.
            He died at the Nova Cult but his legend will live on.
          </li>
          <li>
            Chromicus' journey from the Kurtz cult to the IL Gang; his
            transition from Enforcer to Slave; and his final act as leader of
            his own cult before stepping aboard a spaceship.
          </li>
          <li>
            Boss Hulagu and how he was always fair but tough, jumping around
            with a katana for days, and his tenacity and willingness to share
            the glory of the IL Gang.
          </li>
          <li>
            Smoke Raven and how he led the way through the Zone, always looking
            for mystery and willing to give Berke a second chance. A pragmatic
            dreamer and an explorer to the end. Lets hope Mimir and outer space
            itself are ready for him.
          </li>
          <li>
            Ghost Bird always using clairvoyance to find out more about the
            world and his dedication to culture and learning. I'll always
            remember him gliding along on his hoverboard.
          </li>
          <li>
            Kohen and Nails coming in on the final act and jumping into the fray
            with the seasoned warriors. His unexpectedly excellent healing rolls
            and his compassion speak well to how he can bolster the Sunshine
            Plaza with Fatima and Glib.
          </li>
          <li>
            Bayazid's made jury-rig skills blazing a path through the technical
            jungle of Command Center Charlie and all the techno mysteries and
            dangers there. He used his mutations to great effect (remembering
            luminescence on the Green Man on Cage Island).
          </li>
          <li>
            The way disease brought into the Ark in early sessions drove need to
            find a cure.
          </li>
          <li>
            The Kurtzers in the Elysian Fileds amustment park and appearance of
            Chromicus.
          </li>
          <li>The Rust Castle</li>
          <li>Patrick and the Helldrivers</li>
          <li>
            Civil War ousting Skag, Berke, and Quiet Bob (remembering the
            assassination of Loud Sue early on).
          </li>
          <li>
            Meeting the cannibal human clan at the Homestead and playing Welcome
            Back Kotter board game.
          </li>
          <li>The Nova Cult and blowing up nuke over the Dead Blue Sea.</li>
          <li>Dr. Retzius and EDEN</li>
          <li>And so much more . . .</li>
        </ul>
        <br />
        <br />
        Check out Camaign Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          BODY COUNT AND POPULATION
        </a>
        .
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects.
        </a>
        .
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageRight",
      imageCaption: "",
    },
  },
];

const MYZAnchortownSession64_65 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 65 & 65: EDEN REGAINED
                </h1>
                <p className="lead leadExtra">
                  EDEN and its mysteries are solved. The origin of The People is
                  known, the essense of EDEN understood, and hard goodbyes are
                  spoken.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession64_65;
