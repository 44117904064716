import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import BRSubNav from "./BRSubNav";
import bladeRunnerLogo from "../../../../src/assets/blade-runner/logo-blade-runner.webp";
import sectionDivider from "../../../../src/assets/blade-runner/blade-runner-section-divider-01.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import { useEffect } from "react";
import "./blade-runner-styles.scss";

import analyst from "../../../../src/assets/blade-runner/class-analyst.webp";
import analystInfo from "../../../../src/assets/blade-runner/class-analyst-extra-info.webp";
import citySpeaker from "../../../../src/assets/blade-runner/class-cityspeaker.webp";
import citySpeakerInfo from "../../../../src/assets/blade-runner/class-cityspeaker-extra-info.webp";
import doxie from "../../../../src/assets/blade-runner/class-doxie.webp";
import doxieInfo from "../../../../src/assets/blade-runner/class-doxie-extra-info.webp";
import enforcer from "../../../../src/assets/blade-runner/class-enforcer.webp";
import enforcerInfo from "../../../../src/assets/blade-runner/class-enforcer-extra-info.webp";
import fixer from "../../../../src/assets/blade-runner/class-fixer.webp";
import fixerInfo from "../../../../src/assets/blade-runner/class-fixer-extra-info.webp";
import inspector from "../../../../src/assets/blade-runner/class-inspector.webp";
import inspectorInfo from "../../../../src/assets/blade-runner/class-inspector-extra-info.webp";
import skimmer from "../../../../src/assets/blade-runner/class-skimmer.webp";
import skimmerInfo from "../../../../src/assets/blade-runner/class-skimmer-extra-info.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const BRClasses = () => {
  const section1Data = [
    {
      title: "ANALYST",
      text: (
        <div class="bladeRunnerFont bladeRunnerTitleText">
          <i>A smart, forensic lab master.</i>
          <br />
          <br />
          <img
            style={{ maxHeight: "400px" }}
            src={analyst}
            alt="Analyst Information"
          />
          <img
            style={{ maxHeight: "400px", height: "927px" }}
            src={analystInfo}
            alt="Analyst Information"
          />
          <br />
          You are the master of the crime lab. Streetwise hunch-chasers with
          their V-K briefcases and blasters are the past. Booksmart fact-finders
          like you are the future. Chemistry, pathology, ballistics,
          criminology, microbiology, toxicology, morphology… these are your
          blasters, and all you need to crack a case is your lab, coffee, and
          some personal space. As a forensics specialist, you methodically and
          meticulously agonize over every bit of evidence. You can wring the
          truth from a crime scene with a trace sweep and cursory glance. And
          when you're in the lab, you're a god. Running countless tests with
          cutting-edge tech like an orchestral conductor. Sure, the job forces
          you to interact with others more than you'd like. People only make
          sense to you on the autopsy table, and while you have infinite
          patience for research, you reserve little for bureaucracy and
          stupidity. Facts rarely make friends with obtuse systems and minds. In
          truth, few will ever understand your genius, but most appreciate it
          enough to keep the scene clean for you. The relics can't close a case
          without you, but they can keep the glory. You'll keep the corner
          office with central air, unlimited espresso, and the sexiest tech
          money can buy.
          <br />
          <br />
        </div>
      ),
    },
    {
      title: "CITY SPEAKER",
      text: (
        <div class="bladeRunnerFont bladeRunnerTitleText">
          <i>An undercover vice expert.</i>
          <br />
          <br />
          <img
            style={{ maxHeight: "380px" }}
            src={citySpeaker}
            alt="City Speaker"
          />
          <img
            style={{ maxHeight: "400px", height: "927px" }}
            src={citySpeakerInfo}
            alt="City Speaker Information"
          />
          <br />
          Speak long enough to the city, it speaks back to you. You've got rats
          in every gutter. Pigeons on every rooftop. Urchins in every port. You
          lived and sinned with them since you were kids, so when you come
          round, they see only the face they trust. Not the badge you'd never
          show. Working undercover these many years, the lines blurred long ago.
          Only judges can say what's right anymore. You're only here to stop
          what's wrong. The fugitives, terrorists, traffickers, hatemongers, and
          copkillers preying upon the city. Hiding and thriving in the moskos
          where only the downtrodden roam. Irasshai as a bird. When these
          trespassers make even the biggest vermin scurry, they run to you.
          Every syndicate, scumbag, scavenger, and slumlord. Every fisher king
          and unseen string holding up the city and keeping it down. You know
          every lófaszt on a first-name basis, all to oust the worst and turn
          blind eyes to the rest. It haunted you at first, but work Vice long
          enough and you'll learn that good and bad are torn from the same thin
          justifications and lies. Bend and twist them into whatever you like,
          but clench a fist and both crumble all the same.
          <br />
          <br />
        </div>
      ),
    },
    {
      title: "DOXIE",
      text: (
        <div class="bladeRunnerFont bladeRunnerTitleText">
          <i>
            A prime physical specimen that can read the signs and implications.
          </i>
          <br />
          <br />
          <img style={{ maxHeight: "400px" }} src={doxie} alt="Doxie" />
          <img
            style={{ maxHeight: "400px", height: "927px" }}
            src={doxieInfo}
            alt="Doxie Information"
          />
          <br />
          You always know what to say. Body language. Eye contact. Vocal tones.
          Body temperature. Pulse rate. Even scent. Every detail another window
          into your subject's state of mind. Earlier models used this data to
          serve a person's needs. You only serve the law. Now those same signals
          enable you to detect and project anything you want. Anything the case
          demands. Your hyper-awareness and self-control enable you to
          manipulate every fiber of your being, convincing others to trust you.
          To fear you. To betray all they hold dear, just to see you smile. You
          pity them. What hope could they have, against something like you? Even
          still, it could be worse for them. Words are not your only weapons.
          You are a prime physical specimen born with ten lifetimes of lethal
          black ops training in close combat, infiltration, assassination. With
          flawless grace, dexterity, and athleticism, your natural gifts and
          guile can flip, slip, slither, and slit into anywhere. Through anyone.
          To do anything you must. Your orders are often ruthless, but you
          choose to poison with compassion. If you must be brutal, you will make
          it beautiful. If you are the last thing they see, it should be
          something to behold.
          <br />
          <br />
        </div>
      ),
    },
    {
      title: "ENFORCER",
      text: (
        <div class="bladeRunnerFont bladeRunnerTitleText">
          <i>A tough survior that has seen shit you won't believe.</i>
          <br />
          <br />
          <img style={{ maxHeight: "400px" }} src={enforcer} alt="Enforcer" />
          <img
            style={{ maxHeight: "400px", height: "927px" }}
            src={enforcerInfo}
            alt="Enforcer Information"
          />
          <br />
          You've seen things most people wouldn't believe. A few tours in the
          Off-World Colonization Defense Program put you in every shittastic
          situation conceivable. Kick-murder squads. Hostage situations.
          Counterterrorism. Demolitions. And you survived them all, only to find
          yourself unable to relate to anyone without a target in their sights.
          The LAPD was the only other place you made sense. And not just because
          they've got more artillery than some CDP outfits. It's a bad day when
          somebody needs you, and you've seen enough shit to assess and survive
          any hostile situation. Hell if these flatfoots know how to deal with a
          crisis they can't just interrogate to death. When real bad-asses take
          the stage, it's your job to give ‘em a show. You're not the
          standard-issue CDP shitkicker, but you're not ashamed that grunt life
          suits you. Clock in. Obtain objectives. Eliminate targets. Clock out.
          And if you can squeeze in a stiff drink and a shag, huzzah. There
          isn't a problem you can't solve with a beer or a battering ram. So as
          long as they keep serving up targets, you'll be putting them in body
          bags until somebody puts you in one.
          <br />
          <br />
        </div>
      ),
    },
    {
      title: "FIXER",
      text: (
        <div class="bladeRunnerFont bladeRunnerTitleText">
          <i>A guru at working the system and cultivating favors.</i>
          <br />
          <br />
          <img style={{ maxHeight: "400px" }} src={fixer} alt="Fixer" />
          <img
            style={{ maxHeight: "400px", height: "927px" }}
            src={fixerInfo}
            alt="Fixer Information"
          />
          <br />
          Some call you a career politician. Others a political crusader. You
          don't care what they think. You work the system and you never play
          nice, though everybody knows to play nice with you. And not just to
          kiss up to their future boss. It's your job to keep saboteurs of the
          status quo at bay, may they come from outside or within. When big
          messes occur, you clean them up. And with every mess you fix, somebody
          owes you a favor. A whisper. And you hear a lot from high places. You
          spend more time in courtrooms, fundraisers, and nightclubs than the
          office. Doesn't mean you're not doing the job. Your little black book
          is the upper crust of elite rings to kiss, wrists to twist, and backs
          to stab. As a master of manipulation, you can tear through red tape,
          leap through loopholes, and quell every controversy. But let's face
          it, the only case you're ever working is your own. It's no coincidence
          that you only work cases promising future headlines and new strings to
          pull. You're determined to reach the top of that corporate ladder,
          even if you burn every rung beneath you as you go.
          <br />
          <br />
        </div>
      ),
    },
    {
      title: "INSPECTOR",
      text: (
        <div class="bladeRunnerFont bladeRunnerTitleText">
          <i>An old-school chop-busting marshall that gets the job done.</i>
          <br />
          <br />
          <img style={{ maxHeight: "400px" }} src={inspector} alt="Inspector" />
          <img
            style={{ maxHeight: "400px", height: "927px" }}
            src={inspectorInfo}
            alt="Inspector Information"
          />
          <br />
          You're the quintessential marshal, torn from time immemorial of trench
          coat wearing, liquor drinking, bullet slinging bastards and
          bulldozers, who can strut into the meanest bar, the darkest alley, and
          everybody knows the law just walked in. And means business. As a
          walking Voight-Kampff machine, you've made a career out of pushing
          people's buttons and getting to the core of who (or what) they really
          are. Looking where you shouldn't. Going where you're unwelcome.
          Picking the wrong fights. Asking the wrong questions. Peeling back
          bandages and pouring salt on the wounds. Whatever it takes to make
          people show their true colors. It's cost a busted rib or two, but
          you've honed an uncanny knack for understanding people. And pissing
          them off. It's made you a great detective. And a huge pain in the ass,
          on and off the clock. You know it. Don't care. You're so used to your
          own bullshit, it's as much a second skin as the cynicism, sardonic
          wit, and hip flask you wear like bulletproof armor. Leave tact to
          politicians. You're a god damned one-man slaughterhouse, and common
          courtesy isn't in your job description. But hey, at least the White
          Dragon gives you noodles on the house.
          <br />
          <br />
        </div>
      ),
    },
    {
      title: "SKIMMER",
      text: (
        <div class="bladeRunnerFont bladeRunnerTitleText">
          <i>A dirty cop who gets messy when trash needs to be taken out.</i>
          <br />
          <br />
          <img style={{ maxHeight: "400px" }} src={skimmer} alt="Skimmer" />
          <img
            style={{ maxHeight: "400px", height: "927px" }}
            src={skimmerInfo}
            alt="Skimmer Information"
          />
          <br />
          Everybody but you knows that you're more kipple than cop. There's so
          much dirt under your nails, getting clean is an act of futility.
          Naivety. Screw ‘em. Let them talk. If internal affairs could get you,
          they'd have cuffed you by now. Fact is, they need you. Sure, there's
          more cream than caffeine in your cup. Skimming is a slippery slope,
          and few would call you graceful. But you're still a damn good cop, and
          when trash needs taking out, you're the only one willing to get dirty.
          If you're lacking evidence, maybe you'll plant some. If somebody isn't
          talking, maybe you'll make them. You owe half the city and the other
          half owes you. So if the case conveniently settles a debt or squashes
          ants hoarding all the crumbs, all the same so long as the paperwork is
          filed in triplicate. And you have flawless penmanship when covering
          your own ass. This city doesn't play fair. Why should you? So what if
          you've got a side hustle? Who cares if special interests can buy your
          protection like a side of fries? Your way of life ain't cheap, and
          last you checked, banks don't accept good intentions as collateral.
          <br />
          <br />
        </div>
      ),
    },
  ];

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/blade-runner/background-blade-runner-01.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <div
            style={{
              backgroundColor: "transparent",
              fontFamily: "Orbitron",
            }}
          >
            <main role="main" className="inner cover text-center">
              <img
                src={bladeRunnerLogo}
                style={{
                  maxWidth: "800px",
                  backgroundColor: "white",
                  borderRadius: "10px",
                }}
                alt="Blade Runner campaign"
              />
            </main>
            <div style={pageHeader}>
              <h1 className="cover-heading">Blade Runner Classes</h1>
              <p className="lead leadExtra">
                <BRSubNav />
              </p>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img
              src={sectionDivider}
              style={{ maxHeight: "100px" }}
              alt="section divider"
            />
            <Card.Body>
              <Card.Text>
                <ContentContainer data={section1Data} />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default BRClasses;
