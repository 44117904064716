import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import tank from "../../../../assets/myz/tank-4.jpg";
import purpleGirl from "../../../../assets/myz/remains-hologram-3.gif";
import stableGeniusHologram from "../../../../assets/myz/remains-hologram-2.gif";
import stableGeniusPoster from "../../../../assets/myz/stable-genius-pills.jpg";
import gladhand from "../../../../assets/myz/ark-mutant-gladhand-portrait.jpg";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title:
      "On the Road to Scrap Oracle Cassandra (and some bitterbeast huntin')",
    text: (
      <p>
        03-28-0001: Smoke Raven, the party stalker, has two lethal critical
        injuries from fighting the killer tree and it's humanoid plant pod
        people last session. Jebe, the Dog Handler, demonstrates a heretofore
        unknown benefit of his PARASITE mutation. He can cure a lethal critical
        injury for 4 mutation points. Jebe fires off eight points and cures
        Smoke Raven and miraculously no mutation misfire!
        <br />
        <br />
        As the party camps for the evening, the new party member Gladhand is
        using his Sprinter mutation to run as fast as he can to catch up with
        the party. We use the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-handwave-travel-rules"
        >
          Handwave Travel rules
        </a>{" "}
        to get him there in time to have morning coffee with the IL Gang.{" "}
        <img
          src={gladhand}
          alt="Gladhand the Fixer"
          style={{ padding: 10, float: "left", maxWidth: 175 }}
        />
        Gladhand has been a FIXER for a lot of deals in the Sunshine Plaza, and
        particularly Boss Scag. With the civil war brewing at the Ark, Gladhand
        has wisely chosen to switch sides, not only because he knows a good deal
        when he sees one, but he is sweet on Bianca and now that she has been
        rescued from body servitude to Skag's gang, the IL Gang looks
        attractive.
        <br />
        <br />
        <b>Sector C05</b>: 03-29-0001: the party continues to make
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-beast-training"
        >
          {" "}
          bitterbeast hunt
        </a>{" "}
        SCOUT rolls as they travel. Sector C05 is deadwoods. Thick, rotten trees
        and shrubs like burnt fingers everywhere. The party glimpses a flash of
        red and they see the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-session-14-anchortown"
        >
          "apocalypse girl" from the supply closet
        </a>{" "}
        at Jim's Diner as seen in Session 14. They travel through the zone
        without incident except once they get to the edge of the zone, Chromicus
        spies something on the ground. A metal box is laying next to a FLARE
        GUN. When Chromicus touches the box, it sprouts a hologram into the air
        about 3 feet wide and six feet tall. It's a purple lit female on a loop
        with no sound and is a mystery. Chromicus takes the flare gun and puts
        it in the wheeled suitcase along with the hologram of his dream girl.
        <br />
        <br />
        <b>Sector B04</b>: THE PURE PLANT: 03-29-0001: as the party continues
        combining bitterbeast hunt and quest to find Cassandra, they stop back
        at the PURE PLANT on the way. A patrol nearly fires on the mutants, but
        the scout leader recognizes the mutants as the ones LUTRELL bargained
        with back in
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-session-04-anchortown"
        >
          {" "}
          Session Four
        </a>
        . Lutrell is glad to see the mutants. He explains that he came with an
        envoy to the Ark, but they were shot at and shortly after Helldrivers
        appeared to assault the ark. They were not strong enough to help so
        Lutrell and company come back to the plant. Our PCs let him know that
        they heard he tried to make contact from another mutant in theArk. He is
        glad the Ark survived the Helldriver assault. Gladhand pitches long-term
        cooperation and the possibility of the two Arks thriving as allies.
        Lutrell agrees the talks need to proceed. If the party will stop back by
        on their way home, Lutrell will send his number one lieutenant with them
        to negotiate a deal; they should be safe to approach the Ark this time
        in the company of the IL Gang. In return for clean water, the Ark offers
        protection. The deal will likely be some kind of mutant presence that is
        relieved on a schedule with the outgoing presence carrying water back to
        the Sunshine Plaza. The details will be worked out later. The party does
        not explain their mission to find Scrap Oracle Cassandra in the hopes of
        getting a poison that will give them the upper hand in taking Boss Skag
        out.
      </p>
    ),
    image: {
      imagePath: purpleGirl,
      altText: `Another mysterious hologram found in the Zone`,
      className: "imageRight",
      imageCaption: "Mystery Hologram",
    },
  },
  {
    title: "Tanks A Lot",
    text: (
      <p>
        <b>Sector A03: Tank</b>: this sector is piled high with garbage and
        debris. Even though it is the sector referred to in Boss Berke's note
        delivered via raven, Smoke Raven sees tell-tale signs that the Scrap
        Oracle Cassandra is in the next zone to the east, closer to the ocean.
        Zone Seagulls are squawking and flying around, delighting in the
        garbage. The party is getting closer to the ocean, or whatever it is
        that fills what used to be the harbor of Anchorage, Alaska in the time
        before.
        <br />
        <br />
        Thanks to the sound of barking, our party spots nine zone hounds from
        long distance. The dogs are growling and running wildly around a rusty
        shell of a tank. As the party watches and counts the dogs, the hatch on
        top of the tank opens a bit. They see a humanoid of some kind just
        before the lid clanks back down. It appears the zone dogs are howling
        for their supper inside the metal behemoth.
        <br />
        <br />
        The three PCs with long range scrap rifles start taking potshots at the
        dogs. Chromicus moves in closer to surprise the hounds. Thanks to some
        bad rolls, Chromicus is suddenly surrounded by five hounds and goes
        down. Next there is a pell-mell battle with every party member and
        Jebe's newest hound Triska cut loose to run in screaming, shouting and
        otherwise trying to distract the zone dogs. One more bite and Chromicus
        is dead. As it stands, he has rolled neck injury and is laying paralyzed
        on the battle field!
        <br />
        <br />
        As everyone comes running down the piles of garbage to engage the dogs,
        a few dogs are at the tank. Hulagu does a leap onto the tank but suffers
        disorientation. Gladhand is left with a derringer against two dogs, with
        the remaining hounds engaged with the sudden appearance another dog and
        mutants shouting at them.
        <br />
        <br />
        Hulagu gets into the tank as a hand comes out and fires a wild shot with
        a revolver. Inside the tank are two zone ghouls; a patrol from the PURE
        PLANT. Ajax, the gunman, is hunkered in the tank with his friend who is
        quickly bleeding to death. They have a chainsaw, but it's out of fuel.
        Hulagu fails to heal the friend and he dies. Triska is killed by the two
        hounds fighting her, and the party manages to kill all the hounds before
        Chromicus is killed.
        <br />
        <br />
        After the battle winds down, the party agrees to let Ajax haul Chromicus
        back to the Pure Plant only one sector away so they can continue on
        their way. Jebe promises to bank mutation points and use parasite to
        heal Chromicus when he gets back. As Hulagu inspects the tank, he finds
        a pair of SUNGLASSES. And Gladhand finds a supply nook on the outside of
        the tank with a GAS MASK inside. After the party rolls for rot and the
        gas mask fails, they decide to put the GAS MASK away so it will not
        become broken and worthless as an artifact to turn into the Order of the
        New Dawn.
      </p>
    ),
    image: {
      imagePath: tank,
      altText: `Zone dogs have one of Lutrell's patrols pinned down inside a tank`,
      className: "imageLeft",
      imageCaption: `Another Tank.`,
    },
  },
  {
    title: "Cassandra!! Where Are You??",
    text: (
      <p>
        <b>Sector A04: Gas Station</b>: the party finds another gas station.
        There is a long-dead corpse strung up on a pole with barbed wire. The
        barbed wire is the same as found on a fence around a huge field behind
        the gas station. As they explore the gas station, they find some
        unremarkable but useful scrap, and another metal box similar to the one
        next to the flare gun is under the counter. On the box are letters
        stamped in metal "Stanley Clarks Stable Genius Pills". Double-Bird
        cannot resist the temptation and uses FORCE to open the box. Inside
        there is a Stable Genius poster as well as a small plastic medicine
        bottle. Inside are two star-shaped pink pills. Double Bird swallows one
        of the acrid, bitter pills. Without any obivous effect, he shrugs, puts
        the box and the remaining pill away and the party continues on. With no
        sign of her presence, the party shouts for Cassandra. She should be in
        this sector and the gas station seems a reasonable place for her to be.
        "Cassandra!!! Where are you?", the party shouts in case she is nearby or
        wounded.
        <br />
        <br />
        Gladhand is standing outside the gas station. The sky has darkened and
        he points to the sky. There are dim, but visible, pillars of light
        shining up into the sky. The lights would be much brighter at night.
        Oddly, they seem to be in the direction the party needs to go to find
        Cassandra. But, as they approach the fenced field, they see skull and
        crossbone signs with the words "unexploded ordinance" . . . will they
        ever get to Cassandra?
      </p>
    ),
    image: {
      imagePath: stableGeniusHologram,
      altText: `Clark Stanley Stable Genius Pills: one of many mysterious indications of the once ever-present snake oil salesman that sent The Elder of the Sunshine 
        Plaza into a fit resulting in a coma`,
      className: "imageRight",
      imageCaption: `Another Hologram`,
    },
  },
  {
    title: "Wrapup",
    text: (
      <p>
        We doled out XP and did XP spend. It looks like the trauma of being
        paralyzed so soon after being a prisoner of the Helldrivers has had an
        effect on Chromicus. He took the Jack of All Trades and will switch from
        Enforcer to Slave next time! And alternatively, Double-Bird may switch
        from Slave to Enforcer. Stay tuned.
        <br />
        <br />
        Projects were advanced on auto-pilot with random rolls for which project
        got points:
        <ul>
          <li>CROPLANDS: +2</li>
          <li>WATCHTOWER: +2</li>
          <li>WORKSHOP: +2</li>
        </ul>
        Next session starts 10am, 03-30-0001 in game. We have to: Check out
        Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        , especially for poor Triska who died the same session in which she
        debuted..
      </p>
    ),
    image: {
      imagePath: stableGeniusPoster,
      altText: `The Seed Pods are humanoid plant zombies determined to make more like themselves in service to the killer tree`,
      className: "imageLeft",
      imageCaption: "Stable Genius Pills",
    },
  },
];

const MYZAnchortownSession16 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 16: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  The IL gang keeps travelling toward the location of Scrap
                  Oracle Cassandra. They encounter zone hounds with two of
                  Lutrell's humans from the Pure Plant trapped in the rusted
                  remains of a tank. Chromicus is paralyzed from a neck injury.
                  The human Ajax offers to drag his friends body and Chromicus
                  back to the Pure Plant as the party continues. In the next
                  sector they find a gas station with some Stable Genius Pills
                  (effects unknown) and a field of unexploded ordinance blocking
                  the way to some mysterious lights in the sky . . . on the path
                  to the Scrap Oracle.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                {/*<Card.Title style={cardTitle}>Shootout at Burger Jim's, 03-27-0001 - 03-27-0001</Card.Title>*/}
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession16;
