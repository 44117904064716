import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import logo from "../../../../src/assets/forbidden-lands/forbidden-lands-logo.webp";
import sectionDivider from "../../../../src/assets/forbidden-lands/session07/bitter-reach-page-banner-session-07.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";
import "./forbidden-lands-styles.scss";

import cover from "../../../../src/assets/forbidden-lands/session07/fbl-session-07-cover.webp";
import orcs from "../../../../src/assets/forbidden-lands/session07/orc-clan-on-cliff-full.webp";
import battle from "../../../../src/assets/forbidden-lands/session07/bone-bridge-battle.webp";
import pyramid from "../../../../src/assets/forbidden-lands/session07/mysterious-statue.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "1",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Carter One",
  backgroundColor: "rgba(0, 0, 0, 1) ",
  color: "white",
  marginBottom: "15px",
};

const FBLSession07 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  const section1Data = [
    {
      title: "Bearskull Orc Avalance",
      anchor: "freedom-day",
      sectionTitleCssName: "sectionTitleFBL",
      text: (
        <div className="fblBodyText">
          <ImageComponent
            image={cover}
            caption="Throwing Bones"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Turold Blood-eye is found clutching a mysterious pyramid
            statue, a gargantuan bone bridge is crossed, and
            skeletons and ghouls arise."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          We rewind the party's approach to Ruenlocke to the pass leading up
          into the mountainous glacier. A terrible{" "}
          <span style={{ color: "burlywood" }}>
            blizzard hits and the party has to camp for a day
          </span>
          . During this time we allow everyone to spend XP to gain skills or
          talents without worrying about the WITS roll or teachers. The party
          has been moving so quickly since the campaign start that we wanted to
          dodge time rules for training to allow some of the backlog of XP to be
          spent.
          <br />
          <br />
          Lobo picks up PATH OF THE BLADE 2. Blisster trains in PATH OF THE FACE
          1 & 2. Ibak picks up PATH OF SIGHT 2 and Pack Rat.
          <br />
          <br />
          In the morning, the storm has passed. As morning rations are heated,
          Tiemiez uses a makeship thigh-bone spyglass to scan the cliffs on each
          side of the glacier pass.
          <span style={{ color: "lightgreen" }}>
            {" "}
            Tiemiez spots three humanoid figures on the cliff top. "Hey, do we
            know them?" he asks. "I think they are orcs."
          </span>{" "}
          Rog-golar asks what they look like. Based on the description of their
          furs, weapons and armor dyed black and red,{" "}
          <span style={{ color: "burlywood" }}>
            Rog-Golar realizes they are orcs from his clan, the Bearskull
          </span>{" "}
          clan who live in the far north of the Bitter Reach and are the only
          orcs known to go out on the Ice Sea.{" "}
          <span style={{ color: "lightgreen" }}>"Can you speak to them?"</span>{" "}
          Blisster asks.
          <br />
          <br />
          Rog-golar hides behind Stilak's mount moose named Mouse, grumbling{" "}
          <span style={{ color: "lightgreen" }}>
            "I can speak to them, but I don't want to."
          </span>
          <br />
          <br />
          <ImageComponent
            image={orcs}
            caption="Bearskull Orcs"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Bearskull Orcs have arrived to cause an avalanche once they spot Rog-Golar. His Dark Secret XP is activated.."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          The Bearskull Orcs are joined by a few more and suddenly they are
          pushing rocks over the cliff edge. A{" "}
          <span style={{ color: "burlywood" }}>roaring avalanche</span> starts
          gathering speed and the party has to scramble to avoid being buried.
          Tulok uses an old Ailander trick when he "surfs" with the snow instead
          of running from it or trying to stand his ground. His success is so
          great he inspires Ibax and Stilak with a bonus on their rolls as they
          also take note and swim the snow as best they can. Stilak pushes his
          moose Mouse to gallop and tries to lend the albino frailer elf Neha a
          hand. Tiemisz is buried in the snow. Ibak leads the effor to big out
          the goblin guide who is slightly damaged and COLD (as is Neha the
          elf).
          <br />
          <br />
          <span style={{ color: "lightgreen" }}>
            "This is between you and me!" bellows Rog-Golar. "Send a champion to
            talk or fight!"
          </span>{" "}
          The icy glacier ridge is bare but he knows his kin have heard him and
          that another day of reckoning will come.
          <br />
          <br />
          <a href="#pageTop">Back to Top</a>
        </div>
      ),
    },
    {
      title: "Bridge of Bones",
      anchor: "freedom-day",
      sectionTitleCssName: "sectionTitleFBL",
      text: (
        <div className="fblBodyText">
          <ImageComponent
            image={pyramid}
            caption="Mysterious Pyramid"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Turold Blood-eye is found clutching a mysterious pyramid
            statue filled with planetary bodies, stars, and swirling colors."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <span style={{ color: "burlywood" }}>
            Turold Blood-eye is found unconscious in a small cave
          </span>{" "}
          clutching a psychedelic pyramid statuette. Ibak uses his Path of Site
          powers to divine that the party should not touch the pyramid with
          anything but an object, certainly with no part of their bodies. The
          pyramid is put away and a small fire is the cave is started to get
          Neha and Tiemisz warm. Meanwhile, Ibak's new powers also discover that
          the bridge made of behemoth bones is unstable. The party grabs robe
          from their dog sled and begins to calculate how best to secure the
          bridge with the rope to give crossing the best chance.
          <br />
          <br />
          The rope helps a LOT, but eventually, after a few cross, the bone
          bridge crashes apart and becomes a ribcage hanging on the far side of
          the chasm with Aileen hanging on for dear life. Skeletons awake on the
          far side. As the party, including Turold, try to fight the undead,
          Rog-Golar is cupping his hands to give anyone running a catapult bonus
          to leap the chasm.
          <br />
          <br />
          Blisster uses his PRIDE in mid-air, calling upon the 7 Deadly Lovelies
          to help him. His PRIDE roll is one of the few successful pride rolls
          this session, and now everyone knows that the goblin culture has a
          gritty, dark counterpoint to the seven deadly sins which they consider
          to be good qualities. On the plus side, Rog-Golar spans the gap using
          a huge bone splinter as a pole vault.
          <br />
          <br />
          <img
            src={battle}
            alt="Bone Bridge Battle"
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              width: "90%",
            }}
          ></img>
          The good news is that everyone makes it across the chasm, except poor
          Moose tied to an ancient stump of crumbled stone pillar for later
          retrieval. Ibak gets a critical injury, but it is merely a PIERCED
          EAR. The druid was thinking of getting an earring so the undead did
          him a favor really. Also, Lobo uses his talents to great effect and
          destroys two full-health skeletons in a single round!
          <br />
          <br />
          The bad news is that Lobo suffers a SEVERED LEG critical injury. While
          he can live with the injury (assuming lethal HEAL roll is made), it
          will put a crimp in his ability to combine MELEE CHARGE and other
          talents quite so effectively. But that's a bridge to cross when we
          come to it because the session ended in medias res; there are
          skeletons alive and swinging and the party is fighting . . . TO BE
          CONTINUED
          <br />
          <br />
          <a href="#pageTop">Back to Top</a>
          <br />
          <br />
          <small>
            Attendees: Blisster, Tulok, Ibax, Lobo, Rog-Golar, Stillak.
          </small>
          <br />
          <small>Ending Datetime: 4pm, Winterwane 46, 1165</small>
          <h5>XP</h5>
          <ul>
            <li>Session 1.0</li>
            <li>Legend: 1.0 (Naniuk legend told by Aileen)</li>
            <li>
              Lore: 0.4 (Kin of the Bitter Reach told by Aileen and Tiemisz,
              0-500AS The Divided Years history told by Aileen.){" "}
            </li>
            <li>Hex: 0.0</li>
            <li>Pride: 1.0 (Lobo, Rog-Golar, Tulok)</li>
            <li>Treasure: 0.0</li>
            <li>Monster: 0.0</li>
            <li>Dark Secret: 1.0 (Rog-Golar)</li>
          </ul>
        </div>
      ),
    },
  ];

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/forbidden-lands/fbl-page-background-blizzard.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.backgroundWidth = "100vw";
    document.body.backgroundHeight = "100vh";
    document.body.style.backgroundSize = "cover";
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <div
            style={{
              backgroundColor: "transparent",
              fontFamily: "IM Fell Great Primer",
            }}
          >
            <div style={pageHeader}>
              <main role="main" className="inner cover text-center">
                <a href="#freedom-day" name="pageTop">
                  <img
                    src={logo}
                    style={{ maxWidth: "200px" }}
                    alt="Forbidden Lands Campaign"
                  />
                </a>
              </main>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title
                id="cardTitle-forbidden-lands"
                className="cardTitle-forbidden-lands  "
              >
                Turold Blood-eye is found clutching a mysterious pyramid statue,
                a gargantuan bone bridge is crossed, and skeletons and ghouls
                arise.
                <br />
                <br />
                <small>
                  ⇐ Session 06:{" "}
                  <a href="./forbidden-lands-session-06">Ruins of Ruenlocke</a>{" "}
                  ☠ Session 08:{" "}
                  <a href="./forbidden-lands-session-08">The Crimson Raven</a> ⇒{" "}
                </small>
              </Card.Title>
              <div>
                <ContentContainer data={section1Data} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default FBLSession07;
