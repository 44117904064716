import React from "react";
import { Route } from "react-router-dom";
import IFrameComponent from "../IFrameComponent";

import FBLSession13 from "../forbidden-lands/FBLSession13";
import FBLSession12 from "../forbidden-lands/FBLSession12";
import FBLSession11 from "../forbidden-lands/FBLSession11";
import FBLSession10 from "../forbidden-lands/FBLSession10";
import FBLSession09 from "../forbidden-lands/FBLSession09";
import FBLSession08 from "../forbidden-lands/FBLSession08";
import FBLSession07 from "../forbidden-lands/FBLSession07";
import FBLSession06 from "../forbidden-lands/FBLSession06";
import FBLSession05 from "../forbidden-lands/FBLSession05";
import FBLSession04 from "../forbidden-lands/FBLSession04";
import FBLSession03 from "../forbidden-lands/FBLSession03";
import FBLSession02 from "../forbidden-lands/FBLSession02";
import FBLSession01 from "../forbidden-lands/FBLSession01";
import FBLSession00 from "../forbidden-lands/FBLSession00";
import FBLWolfkin from "../forbidden-lands/FBLWolfkin";
import FBLGoblin from "../forbidden-lands/FBLGoblin";
import FBLGambling from "../forbidden-lands/FBLGambling";
import FBLContests from "../forbidden-lands/FBLContests";
import RandomEncounters from "../RandomEncounters";
import HexTraveller from "../HexTraveller";
import CampaignMaps from "../campaignMaps";
import FLBestiaryTokens from "../FLBestiaryTokens";
import FLFindsPreciousCarried from "../FLFindsPreciousCarried";
import FLFindsPreciousLair from "../FLFindsPreciousLair";
import FLFindsSimpleCarried from "../FLFindsSimpleCarried";
import FLBestiaryTokensBitterReach from "../FLBestiaryTokensBitterReach";

const ForbiddenLandsRoutes = () => {
  return [
    <Route
      path="/forbidden-lands-session-13"
      element={<FBLSession13 />}
    ></Route>,
    <Route
      path="/forbidden-lands-session-12"
      element={<FBLSession12 />}
    ></Route>,
    <Route
      path="/forbidden-lands-session-11"
      element={<FBLSession11 />}
    ></Route>,
    <Route
      path="/forbidden-lands-session-10"
      element={<FBLSession10 />}
    ></Route>,
    <Route
      path="/forbidden-lands-session-09"
      element={<FBLSession09 />}
    ></Route>,
    <Route
      path="/forbidden-lands-session-08"
      element={<FBLSession08 />}
    ></Route>,
    <Route
      path="/forbidden-lands-session-07"
      element={<FBLSession07 />}
    ></Route>,
    <Route
      path="/forbidden-lands-session-06"
      element={<FBLSession06 />}
    ></Route>,
    <Route
      path="/forbidden-lands-session-05"
      element={<FBLSession05 />}
    ></Route>,
    <Route
      path="/forbidden-lands-session-04"
      element={<FBLSession04 />}
    ></Route>,
    <Route
      path="/forbidden-lands-session-03"
      element={<FBLSession03 />}
    ></Route>,
    <Route
      path="/forbidden-lands-session-02"
      element={<FBLSession02 />}
    ></Route>,
    <Route
      path="/forbidden-lands-session-01"
      element={<FBLSession01 />}
    ></Route>,
    <Route
      path="/forbidden-lands-session-00"
      element={<FBLSession00 />}
    ></Route>,
    <Route
      path="/forbidden-lands-session-01"
      element={<FBLSession01 />}
    ></Route>,
    <Route
      path="/forbidden-lands-session-00"
      element={<FBLSession00 />}
    ></Route>,
    <Route path="/forbidden-lands-contests" element={<FBLContests />}></Route>,
    <Route path="/forbidden-lands-gambling" element={<FBLGambling />}></Route>,
    <Route path="/forbidden-lands-wolfkin" element={<FBLWolfkin />}></Route>,
    <Route path="/forbidden-lands-goblin" element={<FBLGoblin />}></Route>,
    <Route
      path="/forbidden-lands-random-encounters"
      element={<RandomEncounters />}
    />,
    <Route path="/forbidden-lands-hex-traveller" element={<HexTraveller />} />,
    <Route path="/forbidden-lands-campaign-maps" element={<CampaignMaps />} />,
    <Route
      path="/forbidden-lands-bestiary-roll20-tokens"
      element={<FLBestiaryTokens />}
    />,
    <Route
      path="/forbidden-lands-finds-precious-carried"
      element={<FLFindsPreciousCarried />}
    />,
    <Route
      path="/forbidden-lands-finds-precious-lair"
      element={<FLFindsPreciousLair />}
    />,
    <Route
      path="/forbidden-lands-finds-simple-carried"
      element={<FLFindsSimpleCarried />}
    />,
    <Route
      path="/forbidden-lands-bitter-reach-bestiary-roll20-tokens"
      element={<FLBestiaryTokensBitterReach />}
    />,
    <Route
      path="/forbidden-lands-legend-generator"
      element={
        <IFrameComponent
          targetUrl={
            "https://www.smokeraven.com/static/html/forbidden-lands-legend-generator.html"
          }
        />
      }
    />,
    <Route
      path="/forbidden-lands-magic-mishaps"
      element={
        <IFrameComponent
          targetUrl={
            "https://www.smokeraven.com/static/html/forbidden-lands-magic-mishaps.html"
          }
        />
      }
    />,
    <Route
      path="/forbidden-lands-lead-the-way-mishaps"
      element={
        <IFrameComponent
          targetUrl={
            "https://www.smokeraven.com/static/html/forbidden-lands-lead-the-way-mishaps.html"
          }
        />
      }
    />,
    <Route
      path="/forbidden-lands-foraging-mishaps"
      element={
        <IFrameComponent
          targetUrl={
            "https://www.smokeraven.com/static/html/forbidden-lands-foraging-mishaps.html"
          }
        />
      }
    />,
    <Route
      path="/forbidden-lands-hunting-mishaps"
      element={
        <IFrameComponent
          targetUrl={
            "https://www.smokeraven.com/static/html/forbidden-lands-hunting-mishaps.html"
          }
        />
      }
    />,
    <Route
      path="/forbidden-lands-fishing-mishaps"
      element={
        <IFrameComponent
          targetUrl={
            "https://www.smokeraven.com/static/html/forbidden-lands-fishing-mishaps.html"
          }
        />
      }
    />,
    <Route
      path="/forbidden-lands-making-camp-mishaps"
      element={
        <IFrameComponent
          targetUrl={
            "https://www.smokeraven.com/static/html/forbidden-lands-making-camp-mishaps.html"
          }
        />
      }
    />,
    <Route
      path="/forbidden-lands-sea-travel-mishaps"
      element={
        <IFrameComponent
          targetUrl={
            "https://www.smokeraven.com/static/html/forbidden-lands-sea-travel-mishaps.html"
          }
        />
      }
    />,
    <Route
      path="/forbidden-lands-stronghold-generator"
      element={
        <IFrameComponent
          targetUrl={
            "https://www.smokeraven.com/static/html/forbidden-lands-stronghold-generator.html"
          }
        />
      }
    />,
    <Route
      path="/forbidden-lands-critical-injuries"
      element={
        <IFrameComponent
          targetUrl={
            "https://www.smokeraven.com/static/html/forbidden-lands-critical-injuries.html"
          }
        />
      }
    />,
    <Route
      path="/forbidden-lands-stronghold-events"
      element={
        <IFrameComponent
          targetUrl={
            "https://www.smokeraven.com/static/html/forbidden-lands-stronghold-events.html"
          }
        />
      }
    />,
    <Route
      path="/forbidden-lands-demon-generator"
      element={
        <IFrameComponent
          targetUrl={
            "https://www.smokeraven.com/static/html/forbidden-lands-generate-demon.html"
          }
        />
      }
    />,
    <Route
      path="/forbidden-lands-adventurer-generator"
      element={
        <IFrameComponent
          targetUrl={
            "https://www.smokeraven.com/static/html/forbidden-lands-adventurer-generator.html"
          }
        />
      }
    />,
  ];
};

export default ForbiddenLandsRoutes;
