import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import NavBarIcon from "../../../assets/blade-runner/navbar-icon-blade-runner.webp";

const BRNavBar = () => {
  return (
    <NavDropdown title="Blade Runner" id="basic-nav-dropdown">
      <Navbar bg="dark" variant="dark" expand="md" sticky="top">
        <Navbar.Brand href="#">
          <div style={{ textAlign: "center", padding: "0px 0px 0px 8px" }}>
            The Immortality <br />
            Game
          </div>

          <small></small>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <img src={NavBarIcon} alt="SmokeRaven" style={{ maxHeight: 85 }} />
          </Nav>
          <NavDropdown title="Tools & Info" id="basic-nav-dropdown">
            <NavDropdown.Item href="/blade-runner-overview">
              Blade Runner Overview
            </NavDropdown.Item>
            <NavDropdown.Item href="/blade-runner-classes">
              Blade Runner Classes
            </NavDropdown.Item>
            <NavDropdown.Item href="/blade-runner-rules">
              Blade Runner Rules
            </NavDropdown.Item>
          </NavDropdown>
        </Navbar.Collapse>
      </Navbar>
    </NavDropdown>
  );
};

export default BRNavBar;
