import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../assets/myz/myz-section-divider.jpg";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import { useEffect } from "react";

const cardTitle = {
  paddingTop: 10,
  paddingLeft: 10,
  paddingBottom: 10,
  backgroundColor: "rgba(221, 117, 28, .7) ",
};
const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Why?",
    text: (
      <p>
        Sometimes you want fast Zone travel without the rigors of full
        exploration, but you do not want to magically "teleport" the party to
        target destination.
        <br />
        <br />
        In particular, our group found it useful after a some characters escaped
        enslavement by the Helldrivers to use handwave travel rules to get
        characters back to the Ark relatively quickly but without bypassing the
        dangers of the Zone.
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
    },
  },
  {
    title: "How?",
    text: (
      <p>
        <ol>
          <li>
            One Handwave Travel roll is required for each sector group wishes to
            travel through.
          </li>
          <li>
            Handwave Travel is allowed only if approved or initiated by GM.
          </li>
          <li>
            A Handwave Travel roll is a MOVE for the entire party, or FIND THE
            PATH (if player making the roll is a Stalker).
          </li>
          <li>
            Roll must be made by Stalker if one is in group (players choose if
            more than one Stalker).
          </li>
          <li>
            If no Stalker in the party, roll is made by PC with best MOVE roll
            with mods shown below to max +3 or -3:
          </li>
        </ol>
        <h4>Handwave Travel Modifiers</h4>
        <ul>
          <li>-3 if no non-broken Stalker in group.</li>
          <li>
            -1 per sentient humanoid less than four (i.e. -3 for lone mutant, -2
            for mutant duo, and -1 for three stooges)
          </li>
          <li>
            +1 per sentient humanoid over four (i.e. +1 for five mutants, +2 for
            six mutants, and +3 for seven or more)
          </li>
          <li>+2 if ALL sentient humanoids in party are in or on a vehicle.</li>
          <li>-2 if travelling through a sector at night.</li>
          <li>
            -2 if travelling with a broken sentient humanoid without broken
            humanoid being in a vehicle.
          </li>
          <li>
            +1 for each PC or NPC helping PC making the roll up to maximum of
            +3. Help can be given only if conscious and not broken.
          </li>
        </ul>
        Success means travel through sector at normal speed without incident.
        <br />
        <br />
        Each additional success can be used to get D6 scrap, D6 water (no rot),
        or D6 grub (Note: each grub has rot unless 6 rolled on separate 1d6 roll
        for each grub found).
        <br />
        <br />
        Cutting travel speed, finding artifacts, knowing threat level, and
        knowing rot level stunts are not allowed for handwave sector travel.
        <br />
        <br />
        <b>IMPORTANT:</b> a successful handwave travel roll for a sector does
        NOT mean the sector is explored. The sector has NOT been explored. If it
        is a new sector, any details about the sector, whether generated by the{" "}
        <a href="./mutant-year-zero-sector-generator">
          Roll20 Sector Generator
        </a>{" "}
        or other means remain unknown. Subsequent exploration of that sector
        occurs as though for the first time. Handwave travel simply does not
        "activate" the sector in question unless the GM decides that it does
        (and subsequently what that means since that means handwave travel may
        not be what is happening).
        <br />
        <br />
        A separate Handwave roll must be made when camping overnight in a
        sector.
        <br />
        Note: if camping, the MOVE roll is made but not with the -2 penalty
        since party is camping and not actually moving.
        <br />
        The next morning, a separate move roll must be made to travel through
        the remainder of current sector.
        <br />
        If there are no successes in Handwave Travel roll, that requires a roll
        on Sector Travel Mishap table.
        <br />
        If Handwave Sector Travel roll is pushed, each failure symbol requires
        an additional roll on mishap table.
        <br />
        Note: if the push results in a success, mishap is rolled only for each
        failure symbol (if any).
        <br />
        <br />
        <h4>Handwave Travel Mishap Mishaps</h4>
        <ol>
          <li>
            D6 mutants in party kept awake from sounds, smell, etc. and start
            the day SLEEPLESS.
          </li>
          <li>
            One random mutant in party suffers XD6 attack against STRENGTH where
            "X" is result of D6 roll (example: 5 is rolled, so mutant suffers
            5D7 attack roll). Armor may protect.
          </li>
          <li>
            One random mutant in party suffers XD6 attack against AGILITY where
            "X" is result of D6 roll (example: 5 is rolled, so mutant suffers
            5D7 attack roll). Armor does not protect.
          </li>
          <li>
            Party lost until nightfall. Next sector travelled requires night
            roll penalty if party does not stop.
          </li>
          <li>
            Party wakes to find D6 grub missing (any roll beyond what grub party
            has means no effect).
          </li>
          <li>
            Party wakes to find D6 water missing (any roll beyond what water
            party has means no effect).
          </li>
        </ol>
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
    },
  },
  {
    title: "Show Me (example handwave travel)",
    text: (
      <p>
        Example: a party of three mutants in a VW bug car without a Stalker
        travel through a sector.
        <br />
        <br />
        The modifiers are -3 (no stalker), -1 (only three mutants), +2 (all
        mutants in vehicle), +2 (two mutants help the rolling mutant) = +0 (i.e.
        no modifier).
        <br />
        <br />
        The mutant driving car has best MOVE roll and rolls Handwave for party.
        No success on the roll. The roll is pushed. Still no success and one
        failure symbol. Party must roll TWICE on the mishap table (once for
        failure, and once for failure symbol).
        <br />
        <br />
        If, for example, the initial roll had failed and followup roll had a
        success but also two failures, then the party would have to roll TWICE
        on mishap chart (twice for the two fail symbols because it was a pushed
        roll, but not for failure since the roll succeeded, albeit with
        consequences).
        <br />
        <br />
        Success, whether on push or not, always means travel through the sector
        succeeds unless mishap specifies otherwise or any party member is broken
        by mishap in which case travel halts in that sector and must be
        re-rolled (heal rolls and so forth can be made before the re-roll if GM
        allows).
        <br />
        <br />
        Note: even if travel halts in sector, continuing with handwave mechanics
        means the sector is NOT explored and actual contents of sector occur on
        later, non-handwave exploration as if sector is being explored for first
        time.
        <br />
        <br />
        NOTE: if Handwave roll is wade by a Stalker, keep in mind the Stalker's
        roll can only be helped by others that know the FIND THE PATH skill
        (i.e. other Stalkers).
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageRight",
    },
  },
];

const MYZHandwaveTravel = () => {
  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Mutant Year Zero Handwave Travel Rules
                </h1>
                <p className="lead leadExtra">
                  Handwave Travel Rules are a light mechanic for Zone travel
                  when you don't want to fully explore sectors, but you also do
                  not want to magically teleport the part to target location
                  (because that would be too easy right?).
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Title style={cardTitle}>HANDWAVE TRAVEL RULES</Card.Title>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZHandwaveTravel;
