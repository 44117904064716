import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import mbLogo from "../../../../src/assets/mork-borg/mork-borg-logo.webp";
import sectionDivider from "../../../../src/assets/mork-borg/mork-borg-section-divider.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";
import prisoners from "../../../../src/assets/mork-borg/session05/prisoners.gif";
import toothlessHag from "../../../../src/assets/mork-borg/session05/toothless-hag.webp";
import chapel from "../../../../src/assets/mork-borg/session05/chapel-olundan.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const MBMalumMortisSession05 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/mork-borg/bkgnd-01.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  const section1Data = [
    {
      title:
        "In which our scum meet the WANDERER, talk to HUGGTA, heard the story of Chapel Olundun, and begin to NURSE THE ROT.",
      text: (
        <div class="morkBorgFont morkBorgTitleText">
          <ImageComponent
            image={toothlessHag}
            caption="Toothless Hag"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Toothless Hag in the infirmary below the Chapel Olundun in village of Tunstal"
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>

          <p>
            Our party consists of:
            <ul>
              <li>
                <span style={{ color: "#00FFFF" }}>SIR FAT HEAD</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>MUSHROOM EATER</span>, a
                wacked out prophet, scrambled like eggs.
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>VESPER</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>PALE ONE</span>, a quiet and
                violently unpredictable alien with a half-bitten off tongue.
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>HILDEBRAND</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>HERETICAL PRIEST</span>,
                worries constantly, washes his hands often, and is obsessively
                clean and neat. He was baptized by fire!
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>HERMAN</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>GUTTERBORN SCVM</span>. He is
                worried and naive with hands caked in sores. He is a bit a
                pyromaniac.
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>
                  SPUG (absent this session)
                </span>
                : <span style={{ color: "#ADFF2F" }}>WRETCHED ROYALTY</span>{" "}
                afflicted with kleptomania and afraid of pain. Pain is such a
                bother!
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>
                  KLATUUBARADA (absent this session)
                </span>
                : <span style={{ color: "#ADFF2F" }}>FUGITIVE KNIGHT</span>, an
                argumentative, pyromaniacal.
              </li>
              <li style={{ fontSize: "0.8em" }}>
                <span style={{ color: "#00FFFF" }}>Donkey Hoatee</span>: donkey
              </li>
              <li style={{ fontSize: "0.8em" }}>
                <span style={{ color: "#00FFFF" }}>
                  Barbarister the Incredible Horse
                </span>
                : talking horse minion (master?) to Spug
              </li>
              <li style={{ fontSize: "0.8em" }}>
                <span style={{ color: "#00FFFF" }}>Jaque Manaque</span>: wannabe
                knight squire-lad trying to earn his keep
              </li>
            </ul>
            <div
              style={{ border: "3px solid red", padding: "5px", margin: "5px" }}
            >
              Misery Countdown:
              <ul>
                <li>
                  Psalm 6, Verse 1: You shall know the last day is come. The sun
                  shall set and never rise.
                  <ul>
                    <li>
                      EFFECT: sun never shines. World is dark always. No more
                      daytime.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            This session saw SPUG and KLATUUBARADA sitting out.
            <br />
            <br />
            Our wayward scum are hot on the trail of Orange Julius and they go
            to the Temple of the Two-Headed Basilisks to{" "}
            <span style={{ color: "#FF7F50" }}>
              petition General Huggta to let them speak to Orange Julius
            </span>
            .
            <br />
            <br />
            En route to the cathedral, the party{" "}
            <span style={{ color: "#FF7F50" }}>
              encounters the mysterious WANDERER
            </span>
            . He is an eight foot tall, skeletal presence in a black robe with a
            staff glowing puple at the end. He hands out blessings (that are
            sometimes curses) to anyone who strikes his fancy (which is entirely
            up to a random reaction roll).
            <br />
            <br />
            At the Cathedral,{" "}
            <span style={{ color: "#FF7F50" }}>
              Sir Fat Head speaks with General Huggta
            </span>
            . Huggta is a bulky, glowering female fanged deserter with grey head
            the shape of a squashed olive. She has one tusk and the other
            jutting out with a broken tip. She is briefly confused that the
            party is not there to answer a summons sacrifice. In the ensuing
            conversation, Sir Fat Head says they are trying to save the world
            and return light to banish the darkness. Huggta is no religious
            scholar but she thinks that the darkness from the Calendar of
            Necrubel might be as things are meant to be and that the energetic
            mushroom eater may be a heretic. However, she decides they are not
            actual consorts of Orange Julius and harmless enough. Without means
            to access the prisoner, the party wanders the Cathedral looking at
            the statues and art until{" "}
            <span style={{ color: "#FF7F50" }}>
              Herman finds an opportunity to bribe a guard to let the speak with
              Orange Julius
            </span>
            .
            <br />
            <br />
            Julius is held in a government building that has been transformed
            into a makeshift prison. With the comet above and the neverending
            night from the first misery, the church is summoning all heretics,
            miscreants, and scallywags to be sacrificed. Julis tells the tale of
            the Chapel Olundun in the abandoned village of Tunstal. This is the
            hook for the NURSE THE ROT adventure in HERETIC. There are tunnels
            beneath the chapel;{" "}
            <span style={{ color: "#FF7F50" }}>
              in the infirmary they will be sure to find the Staff of Awful
              Light
            </span>
            , but also they may find horrors and despair.
            <br />
            <br />
          </p>
        </div>
      ),
    },
    {
      title: "Nurse the Rot",
      text: (
        <div class="morkBorgFont morkBorgTitleText">
          <ImageComponent
            image={chapel}
            caption="Chapel Olundun"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Chapel Olundun stands in tatters, tunnels to the infimary below ready to be discovered."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>

          <ImageComponent
            image={prisoners}
            caption="Prisoner Spirits"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="Floating spirits imprisoned in Chapel Olundun begging for escape."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>

          <p>
            It's a half day travel to Tunstal. Along the way a couple of
            desperate{" "}
            <span style={{ color: "#FF7F50" }}>
              flayed vultures stalk the party
            </span>
            . Eventually it becomes necessary for the party to kill the horrid
            avians so they can get some sleep.
            <br />
            <br />
            Chapel Olundun of Tunstal is a rotting skeleton of stone, wood, and
            wild, unkept foliage.
            <br />
            <br />
            While Tunstal itself is an abandoned, largely destroyed town, the
            chapel is the apex of sorrow, standing, just barely like the corpse
            of an old priest long ago shorn of hope and forced to stand in the
            elements for eternity.
            <br />
            <br />
            The infirmary below the chapel must have some kind of entry, but{" "}
            <span style={{ color: "#FF7F50" }}>
              it will take some sifting through the broken house of faith to
              find the tunnels
            </span>
            .
            <br />
            <br />
            As the party searches, they find random treasures left undisturbed.
            The horrors of Vretul's experiments and the tales of the nurses so
            horrible that the grounds have remained undisturbed by your average
            treasure hunters.{" "}
            <span style={{ color: "#FF7F50" }}>
              Sir Fat Head swallows a mushroom that will boost his presence.
              However, he fails his presence check and finds that he has instead
              swallowed a mushroom that causes him to fumble all tests
            </span>{" "}
            for the next two hours.{" "}
            *game show failure sound effect*
            <br />
            <br />
            A battered wooden trapdoor is found buried under a layer of dirt,
            dead leaves, and rotting branches. At the end of the tunnel, the
            party emerges into the infirmary's main room. SPOILER ALERT:
            descriptions adhere to the Nurse the Rot adventure from HERETIC, so
            continue reading knowing you've been warned.
            <br />
            <br />
            In the circular main room there is a{" "}
            <span style={{ color: "#FF7F50" }}>
              cackling toothless old hag waving her arms, laughing, and tending
              to a bonfire{" "}
            </span>
            of supernatural flames forever consuming a hallway jam-packed with
            human and animal bones. She takes little notice of the party and
            does nothing but tend the flame, skipping away when the fire licks
            at here weathered flesh, laughing madly the entire time. The ceiling
            is covered with mosaical depictions of war, murder, blood, and
            death. There are three unblocked corriders leading out of the room.
            North is a quiet hallway half filled with smoke from the bonfire;
            East is a hallway with huge spider legs coming out of the walls; and
            to the South is a hallway filled with paintings. The party is wary
            of ignoring the seemingly harmless hag.
            <br />
            <br />
            Sir Fat Head dances up to the hag. Mimicing her motions in a kind of
            mime dance, he smiles and
            <span style={{ color: "#FF7F50" }}>
              {" "}
              offers her one of his paralysis mushrooms
            </span>
            . She takes the mushroom with a shrieking guffaw and eats it. Sir
            Fat Head makes his Svampatare roll and it is in fact a paralysis
            mushroom.{" "}
            <span style={{ color: "#FF7F50" }}>
              Hildebrand uses a power to commute with the spirit world
            </span>{" "}
            and gets a sense that it is VERY GOOD that they incapacitaed the
            witch but also that they did not kill her. If they kill her,
            something evil this way comes, Hildebrand is sure of it.
            <br />
            <br />
            In no short amount of time the following sequence of events takes
            place:
            <ul>
              <li>
                North corridor has a couple dozen{" "}
                <span style={{ color: "#FF7F50" }}>spectral warriors</span>{" "}
                appear and march through the party causing no harm.
              </li>
              <li>
                In the north room, Sir Fat Head triggers a{" "}
                <span style={{ color: "#FF7F50" }}>bomb trap</span>.
              </li>
              <li>
                In the eastern room, Vesper loots a{" "}
                <span style={{ color: "#FF7F50" }}>
                  purple-skinned, black-eyed corpse
                </span>
                . He nets 100sp and only has to NOT roll a 1 in 20 to die! He
                lives. Boo!
              </li>
              <li>
                One more room to the south, they encounter{" "}
                <span style={{ color: "#FF7F50" }}>
                  Kobolths; canker-skinned little bastards that do little more
                  than piss on the floor
                </span>{" "}
                and try to shank unsuspecting travellers with their infected
                daggers.
              </li>
              <li>
                As the party fights the kobolths, Sir Fat Head scouts out the
                spider leg hall. It appears{" "}
                <span style={{ color: "#FF7F50" }}>
                  below the grating in the floor is a cavernous maw
                </span>
                . The almost entirely fearless and occasionally foolish Sir Fat
                Head waves his fingers and declares, "I will go to many places
                and do many things; but, uh, I suggest we stay out of that
                hallway."
              </li>
              <li>
                Taking the long way around, the party walks through the hall of
                paintings which features a painting of King Fathmu IX holding a
                golden spire.
              </li>
              <li>
                The adventure ends in a room with four leg- and arm-less soldier
                bodies, and across a canal filled with muddy murky fluid the{" "}
                <span style={{ color: "#FF7F50" }}>
                  floating spirits of two prisoners moaning to be let out
                </span>
                . "The key to the door across the room is at the bottom of the
                canal."
              </li>
              <li>
                Wanting to get past the dismembered bodies in the makeshift
                infirmary of horror, the party wades into the canal. Within
                moments, a{" "}
                <span style={{ color: "#FF7F50" }}>
                  humanoid shape of muck and mud rises up to battle the
                  intruders
                </span>
                .
              </li>
            </ul>
            And so, knee deep in mud and shit in the infirmary below Chapel
            Olundun, our scum are faced with a raging monstrousity known as a
            SLUDGER. Tune in next time!
            <br />
            <br />
            No one died, but please visit the{" "}
            <a
              rel="noreferrer"
              href="./mork-borg-malum-mortis-graveyard"
            >
              Mork Borg Malum Mortis Campaign Graveyard
            </a>
            .
            <br />
            <br />
            This episode of Malum Mortis brought to you in part by:
            <br />
            Game:{" "}
            <a
              href="https://morkborg.com/content/"
              rel="noreferrer"
            >
              Mork Borg
            </a>
            <br />
            Module:{" "}
            <a
              href="https://www.drivethrurpg.com/product/380973/MORK-BORG-CULT-HERETIC"
              rel="noreferrer"
            >
              Nurse the Rot [HERETIC]
            </a>
            <br />
            Class:{" "}
            <a href="https://www.drivethrurpg.com/product/343569/Svampatare-The-Mushroom-Eater--A-Mork-Borg-Class">
              Svampätare: The Mushroom Eater
            </a>
            <br />
          </p>
        </div>
      ),
    },
  ];

  return (
    <Container>
      <Row>
        <Col>
          <div
            style={{ backgroundColor: "transparent", fontFamily: "Orbitron" }}
          >
            <main role="main" className="inner cover text-center">
              <img
                src={mbLogo}
                style={{ maxWidth: "200px" }}
                alt="Mutant Year Zero campaign"
              />
            </main>
            <div style={pageHeader}>
              <h1 className="cover-heading">Session 05: Malum Mortis</h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title className="cardTitle-mork-borg">
                NURSE THE ROT
              </Card.Title>
              <Card.Text>
                <ContentContainer data={section1Data} />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MBMalumMortisSession05;
