import React from "react";
import { Route } from "react-router-dom";

import KOBTurnaroundRockSession01 from "../kids-on-bikes/KOBTurnaroundRockSession01";
import KOBTurnaroundRockSession00 from "../kids-on-bikes/KOBTurnaroundRockSession00";
import KOBTurnaroundRockSession02 from "../kids-on-bikes/KOBTurnaroundRockSession02";
import KOBTurnaroundRockSession03 from "../kids-on-bikes/KOBTurnaroundRockSession03";
import KOBTurnaroundRockSession04 from "../kids-on-bikes/KOBTurnaroundRockSession04";
import KOBTurnaroundRockSession05 from "../kids-on-bikes/KOBTurnaroundRockSession05";

const KidsOnBikesRoutes = () => {
  return [
    <Route
      path="/kids-on-bikes-turnaround-rock-session-05"
      element={<KOBTurnaroundRockSession05 />}
    />,
    <Route
      path="/kids-on-bikes-turnaround-rock-session-04"
      element={<KOBTurnaroundRockSession04 />}
    />,
    <Route
      path="/kids-on-bikes-turnaround-rock-session-03"
      element={<KOBTurnaroundRockSession03 />}
    />,
    <Route
      path="/kids-on-bikes-turnaround-rock-session-02"
      element={<KOBTurnaroundRockSession02 />}
    />,
    <Route
      path="/kids-on-bikes-turnaround-rock-session-01"
      element={<KOBTurnaroundRockSession01 />}
    />,
    <Route
      path="/kids-on-bikes-turnaround-rock-session-00"
      element={<KOBTurnaroundRockSession00 />}
    />,
  ];
};

export default KidsOnBikesRoutes;
