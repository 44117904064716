import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import logo from "../../../../src/assets/forbidden-lands/forbidden-lands-logo.webp";
import sectionDivider from "../../../../src/assets/forbidden-lands/session11/bitter-reach-page-banner-session-11.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";
import "./forbidden-lands-styles.scss";

import cover from "../../../../src/assets/forbidden-lands/session11/fbl-session-11-cover.webp";
import field from "../../../../src/assets/forbidden-lands/session11/ruenlocke-field.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "1",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Carter One",
  backgroundColor: "rgba(0, 0, 0, 1) ",
  color: "white",
  marginBottom: "15px",
};

const FBLSession11 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  const section1Data = [
    {
      title: "Panoowa, Ghost Boy",
      anchor: "freedom-day",
      sectionTitleCssName: "sectionTitleFBL",
      text: (
        <div className="fblBodyText">
          <ImageComponent
            image={cover}
            caption="Panoowa, Ghost Boy"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="In the moat room of Ruenlocke is the mysterious ghost of Panoowa the ghost boy. He attacks screaming 'I am SO hungry.' He is stopped when given food, but it seems Ruenlocke as a stronghold will have a tempermental ghost inhabitant."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          The party this session is: Ibax, Lobo, Stilak, and Rog-Golar.
          <br />
          <br />
          <h2>The Moat Room</h2>
          <i>
            Two <strong>ledges</strong> look over a <strong>moat</strong>. Both
            ledges are connected to a <strong>broken corridor</strong> above the
            water via makeshift bundles of wood bound with leather straps. The
            northern part of chamber has a door but there is a wide gap in the
            broken corridor. In the <strong>murky waters 15 feet below</strong>{" "}
            are fish-shaped swimming <strong>shadows</strong>. To the south,
            there is a set of slightly ajar <strong>double doors</strong>. At
            the gap between the doors you see a shimmering,{" "}
            <strong>semi-translucent little boy</strong> looking at you. He
            turns and runs through the doors to the south quick as a thought.
          </i>
          <br />
          <br />
          The party pursues the shimmering boy and discovers a ghost wearing
          shabby grey cloak with manacles hanging from the wrists. The{" "}
          <span style={{ color: "burlywood" }}>ghost attacks</span> while
          moaning, "I am SO hungry!"
          <br />
          <br />
          Rog-golar and Lobo become COLD from the boys supernatural attacks, and
          Aileen is broken by the ghost's keening wail. The party uncovers a
          monster lore factoid; namely that ghosts are not damaged by anything
          besides fire or magic. As the party tries to fight with lit torches,
          <span style={{ color: "burlywood" }}>
            Rog-golar suddenly stops fighting and offers the ghost a piece of
            dried meat
          </span>{" "}
          as he says,{" "}
          <span style={{ color: "lightgreen" }}>
            We were prisoners too! We do not have to fight.
          </span>
          The ghost smiles and lifts the ectoplasmic mimic of the real-world
          food to its lips and fades. Responding to Rog's shout the ghost tells
          the party it's name is Panoowa.
          <br />
          <br />
          The party spends{" "}
          <span style={{ color: "burlywood" }}>
            two quarter days resting and training
          </span>{" "}
          as they gather treasure and get ready for trip back to Northfall.
          Rog-golar gets MELEE CHARGE with only one WITS dice to roll. Lobo gets
          AMBIDEXTROUS. Stilak picks up SHARPSHOOTER RANK 2 by augmenting failed
          roll with PRIDE die.
          <br />
          <br />
          <ImageComponent
            image={field}
            caption="Ruenlocke Field"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="An ultra-rare bit of arable land on the Morma glacier due to volcanic activity. Mouse the Moose and dogsled there to greet party emerging from Ruenlocke."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          The party ties up their captive Turold Blood-eye and exits the ruins.
          In the time they spent in the ruin, a crevass opened on the skeleton
          ledge and they notice that Mouse the Moose and the dogsled are no
          longer across the dragon bridge chasm. Following they crevass,{" "}
          <span style={{ color: "burlywood" }}>
            they discover an area of arable land with no glacier cover!
          </span>{" "}
          It's due to the volcanic heat of the area. It looks like making
          Ruenlocke a stronghold would come with perhaps the only arable FIELD
          in the entire Bitter Reach!!
          <br />
          <br />
          We discuss a little about{" "}
          <span style={{ color: "burlywood" }}>stronghold rules</span> and how
          we may simplify managing materials to costs alone and how Ruenlocke
          comes with FIELD function (miraculously) and has the defct of a GHOST
          on-site. However, the priority for the party is to get back to
          Northfall and start turning their loot into cash, train some more, and
          check out the Awakening Day festival.
          <br />
          <br />
          Before starting hex crawl, the party says{" "}
          <span style={{ color: "burlywood" }}>goodbye to Company Zed</span>.
          Aileen asks for a spot of treasure so they may outfit themselves.{" "}
          <span style={{ color: "burlywood" }}>
            They plan on travelling toward the meteor that fell from the sky
          </span>{" "}
          and want to restock supplies in CRISTALBREAK settlement. The party
          lets the company keep some of the treasure they are holding and gifts
          them with a bit more. It is enough that the former camp thieves turned
          allies part with Aileen proclaiming{" "}
          <span style={{ color: "lightgreen" }}>
            "May the road always rise to meet you."
          </span>
          Rog-golar replies with an aphorism of the Bear Skull clan, saying{" "}
          <span style={{ color: "lightgreen" }}>
            "May the snow never be over your head!"
          </span>
          <br />
          <br />
          Travelling back to Northfall the party treks through a new hex for XP
          and{" "}
          <span style={{ color: "burlywood" }}>
            battles a STONE RAVEN flock
          </span>{" "}
          for the first time. Stilak is broken by the ravens and later brought
          back from despair by Ibak performing a dance with drums. Stilak is
          still wracked with NIGHTMARES for another day and evening, the raucous
          and gritty cries of the supernatural birds echoing in his ears.
          <br />
          <br />
          At the gates of Northfall the guards see wolfkin, orc and two
          ailanders approaching, one of whom is riding a moose with Turold
          Blood-eye marching behind bound with rope. Reputation rolls do not
          work but then the guard BILIQ that Lobo patrolled with vouches for
          them. He smells like a distellery and is a bit drunk. He says, "I know
          these folks. They are good. The party is allowed into the settlement.
          As they walk the streets they see a pair of HUGE wooden pillars with a
          cloth covered sign in the main square outside Cuss & Dangs. Two guards
          sit atop the poles and many gawkers are gathered.{" "}
          <span style={{ color: "burlywood" }}>
            BILIQ helps the party get Turold into a cell
          </span>
          . He tells them Marshall Volle is busy with the festivities but once
          they catch up with him they can settle up with the bounty.
          <br />
          <br />
          Batachikan (Batu) approaches the jail house and is relieved to see his
          fellow Gomsteaders. He has them follow him to Hillis' shop.{" "}
          <span style={{ color: "burlywood" }}>
            Hillis was arrested for harboring Roar
          </span>
          . Roar's love for Iona is not illegal, but the family accused her of
          kidnapping Since both the fishermen aand whalers are in an uproar over
          the illicit affair between Roar and Iona, Marshall Volle had to arrest
          Hillis to assuage feelings on both sides so the critically important
          and financially critical Awakening Day festival is not impacted more
          than it needs to be.
          <br />
          <br />
          The party stores their dogsled and treasure under the shop.{" "}
          <span style={{ color: "burlywood" }}>
            Batu has been trying to run the shop
          </span>{" "}
          and keep it in business for Hillis, but the chaos of the festival has
          him scrambling and having a difficult time. He tells them that Ingmarg
          is about to address the town about a contest and that the annual
          reinactment of the story of Jura the Giantess is about to take place.
          We end there.
          <br />
          <br />
          <small>
            Attendees: Blisster, Ibax, Lobo, Rog-Golar, Stillak, Tulok Fook.
          </small>
          <br />
          <small>Ending Datetime: 6pm, Springrise 05, 1165</small>
          <h5>XP</h5>
          <ul>
            <li>Session 1.0</li>
            <li>Legend: 0.0</li>
            <li>Lore: 0.0</li>
            <li>Hex: 1.0</li>
            <li>Pride: 1.0 (Stilak: success, training sharpshooter 2)</li>
            <li>Treasure: 0.0</li>
            <li>Monster: 1.0 (stone ravens, ghost boy)</li>
            <li>Dark Secret: 0.0</li>
          </ul>
          <a href="#pageTop">Back to Top</a>
        </div>
      ),
    },
  ];

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/forbidden-lands/fbl-page-background-blizzard.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.backgroundWidth = "100vw";
    document.body.backgroundHeight = "100vh";
    document.body.style.backgroundSize = "cover";
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <div
            style={{
              backgroundColor: "transparent",
              fontFamily: "IM Fell Great Primer",
            }}
          >
            <div style={pageHeader}>
              <main role="main" className="inner cover text-center">
                <a href="#freedom-day" name="pageTop">
                  <img
                    src={logo}
                    style={{ maxWidth: "200px" }}
                    alt="Forbidden Lands Campaign"
                  />
                </a>
              </main>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title
                id="cardTitle-forbidden-lands"
                className="cardTitle-forbidden-lands  "
              >
                Ruenlocke concludes with discovery of ghost boy. Back to
                Northfall, battle Stone Ravens. Hillis in jail; Awakening Day
                festival.
                <br />
                <br />
                <small>
                  ⇐ Session 10:{" "}
                  <a href="./forbidden-lands-session-10">The Sting</a> ☠{" "}
                  <a href="./forbidden-lands-session-12">
                    Session 12: Awakening Day
                  </a>{" "}
                  ⇒{" "}
                </small>
              </Card.Title>
              <div>
                <ContentContainer data={section1Data} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default FBLSession11;
