import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import mbLogo from "../../../../src/assets/mork-borg/mork-borg-logo.webp";
import sectionDivider from "../../../../src/assets/mork-borg/mork-borg-section-divider.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";
import kingFathmu from "../../../../src/assets/mork-borg/session04/king-fathmu-ix-token.webp";
import janHus from "../../../../src/assets/mork-borg/session04/jan-hus.webp";
import xdMeadows from "../../../../src/assets/mork-borg/session04/xd-meadows-portrait.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const MBMalumMortisSession04 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/mork-borg/bkgnd-01.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  const section1Data = [
    {
      title:
        "In which a jester is disembowled, a heretic roasted alive, and Orange Julius searched for.",
      text: (
        <div class="morkBorgFont morkBorgTitleText">
          <ImageComponent
            image={kingFathmu}
            caption="King Fathmu IX"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="King Fathmu IX of Schleswig. Art by the inimitable Brian Yaksha (@goatmansgoblet - twitter)"
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>

          <ImageComponent
            image={janHus}
            caption="Heretic from Galgenbeck"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="an Hus: Galgenbeck heretic and real-life heretic burned at stake at the Council of Constance."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>

          <p>
            Our party consists of:
            <ul>
              <li>
                <span style={{ color: "#00FFFF" }}>SPUG</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>WRETCHED ROYALTY</span>{" "}
                afflicted with kleptomania and afraid of pain. Pain is such a
                bother!
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>KLATUUBARADA</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>FUGITIVE KNIGHT</span>, an
                argumentative, pyromaniacal.
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>SIR FAT HEAD</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>MUSHROOM EATER</span>, a
                wacked out prophet, scrambled like eggs.
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>VESPER</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>PALE ONE</span>, a quiet and
                violently unpredictable alien with a half-bitten off tongue.
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>HILDEBRAND</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>HERETICAL PRIEST</span>,
                worries constantly, washes his hands often, and is obsessively
                clean and neat. He was baptized by fire!
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>HERMAN</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>GUTTERBORN SCVM</span>. He is
                worried and naive with hands caked in sores. He is a bit a
                pyromaniac.
              </li>
              <li style={{ fontSize: "0.8em" }}>
                <span style={{ color: "#00FFFF" }}>Donkey Hoatee</span>: donkey
              </li>
              <li style={{ fontSize: "0.8em" }}>
                <span style={{ color: "#00FFFF" }}>
                  Poltroon the Court Jester
                </span>
                : jester minion to Spug
              </li>
              <li style={{ fontSize: "0.8em" }}>
                <span style={{ color: "#00FFFF" }}>
                  Barbarister the Incredible Horse
                </span>
                : talking horse minion (master?) to Spug
              </li>
              <li style={{ fontSize: "0.8em" }}>
                <span style={{ color: "#00FFFF" }}>Jaque Manaque</span>: wannabe
                knight squire-lad trying to earn his keep
              </li>
            </ul>
            Misery Countdown:
            <ul>
              <li>
                Psalm 6, Verse 1: You shall know the last day is come. The sun
                shall set and never rise.
                <ul>
                  <li>
                    EFFECT: sun never shines. World is dark always. No more
                    daytime.
                  </li>
                </ul>
              </li>
            </ul>
            This session saw HILDEBRAND and HERMAN sitting out. They remained
            unconscious at the inn of coffin tables, tuckered out from defeating
            the GOBLIN GRINDER. As a side note: there was zero combat this
            session. It felt like we were improvising Three Stooges meets Monty
            Python and it was glorious tomfoolery all around. Here's the deets:
            <br />
            <br />
            After checking on their sleeping companions (i.e. player could not
            make session), our party ambles to the palace of Schleswig to{" "}
            <span style={{ color: "#FF7F50" }}>
              demand payment from Crudite
            </span>
            .
            <br />
            <br />
            Crudite explains that{" "}
            <span style={{ color: "#FF7F50" }}>
              King Fathmu wants to meet the party
            </span>
            . He is pleased that the goblin menance is defeated, but quite angry
            about the burned down alchemical shop. Spug protests but Crudite
            explains long before it was a shop, it was a low-rent house for
            courtesans wherein Prince Fathmu used to meet his favorite trollop.{" "}
            <i>
              "Sentimental value, you see? But perhaps his joy over the goblin
              defeat will unseat his displeasure over a lost teenage memory. One
              can only hope."
            </i>
            &nbsp;Anxious to legitimize his pseudo-royalty, KLATUU clammers a
            bit about his status as a former, if not fugitive knight, and ends
            his oratory with the summation: "I have been in a palace before.
            This, ugh, is not my first time in a hallway."
            <br />
            <br />
            King Fathmu's court is littered with linen-clad playthings on divans
            and blood-thirsty guards.&nbsp;
            <span style={{ color: "#FF7F50" }}>
              The party stands at the base of a mighty throne, ready to face
              King Fathmu IX.
            </span>
            &nbsp;Crudite indroduces the party to the king. The king asks who
            will speak first for the party. SPUG steps up, craning his neck to
            see the ruler of Schleswig towering above in his absurb throne, and
            Spug is hit with the question:
            <br />
            <br />
            <h4>
              King Fathmu IX:{" "}
              <span style={{ color: "#FF7F50" }}>
                "are you . . . a butcher? Or . . . a hog?
              </span>
            </h4>
            <br />
            Spug gamely spouts that he is a butcher who is "high on the hog."
            Fathmu frowns at the mixed answer as Spug makes a grand show of
            kissing the proffered ring. Spug uses his cloak deftly and does not
            actually kiss the ring. Instead he hovers near and makes kissing
            sounds. It fools everyone in throne room, but Fathmu sees the trick
            and smiles; he enjoys a clever bit of tomfoolery as long as it is in
            fact second to obeying him. Next up is Sir Fat Head.
            <br />
            <br />
            Unbeknownst to anyone else,{" "}
            <span style={{ color: "#FF7F50" }}>
              Sir Fat Head has extracted a swollen shroom from his satchel and
              secretly smeared it on his lips
            </span>
            . Immune to mushroom gloss, Sir Fat Head makes a grand, cracked
            speech then kisses the ring, smearing the mushroom juices on it.
            King Fathmu gifts Sir Fat Head with 82 silver since his performance
            kissing the ring was so grand as to win the Beneficent Bestowal
            (i.e. Presence check with critical success).
            <br />
            <br />
            Next up is Spug's minion, Poltroon the Jester. Poltroon capers and
            giggles and eventually kisses the ring. Unfortunately, the shroom
            poison on the ring is such that the imbiber must tell the truth and
            no lies for d20 days. Spug and Crudite blanche as &nbsp;
            <span style={{ color: "#FF7F50" }}>
              Poltroon rambles on, contradicting himself and veering dangerously
              close to giving away the party secrets
            </span>
            ; he is amazed at the words coming out of his mouth, unable to
            understand the compulsion to never lie he is under. Crudite surmises
            what has happened and gives Spug a look that says, "your clown could
            blow our cover as agents of Tergol and get us all killed for fucks
            sake man!"
            <br />
            <br />
            As Crudite closes down the audience and ushers the party toward the
            throne room exit, Poltroon bawls out, "I'm a hog, I'm a hog, I'm a
            hog!!!" A random reaction check reveals that far from laughing at
            the courtly clown, Fathmu is furious.{" "}
            <span style={{ color: "#FF7F50" }}>
              Six guards lift up the tiny jester and take him away
            </span>{" "}
            as Spug sputters. Crudite assures Spug he will do his best.
            Meanwhile, there is a banquet in honor of the heroes.
            <br />
            <br />
            Our heroes sit near Fathmu at an{" "}
            <span style={{ color: "#FF7F50" }}>BBQ held in their honor</span>.
            As a salute to the overkill, but effective, burning of a city shop
            to roust Nagel Krat, the BBQ features fire jugglers, BBQ, and the
            burning of a heretic from Galgenbeck named Jan Hus. "It's considered
            bad form to lose your appetite from the smoke of the corpse",
            Crudite explains. Klaatu piles his plate high to distract from his
            suspicious habit of never removing his helmet. Zealots dance in the
            courtyard, vying to get the best place in the path of the charred
            corpse smoke drifting off the cindered heretic. In other words, it's
            a fun party.
            <br />
            <br />
            Spug makes a plea to see Poltroon and have him dance for the king to
            see if King Fathmu will change his mind. Unfortunately, outside the
            jail cells, Crudite can only shake his head like a doctor in an
            episode of St. Elsewhere. Poltroon the Jester is dead.{" "}
            <span style={{ color: "#FF7F50" }}>
              The guards beat Poltroon until he spilled his guts; literally
            </span>
            .
          </p>
        </div>
      ),
    },
    {
      title: "The Search for Orange Julius",
      text: (
        <div class="morkBorgFont morkBorgTitleText">
          <ImageComponent
            image={xdMeadows}
            caption="X.D.Meadows"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="X.D. Meadows: drunkard and secret agent of Tergol in the sewers beneath Galgenbeck"
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>

          <p>
            The party leaves the banquet and shops for provisions. They are
            still well stocked on lamp oil to light the forever darkness that
            has engulfed the dying lands from the first misery. As they travel
            back to Galgenbeck, they{" "}
            <span style={{ color: "#FF7F50" }}>
              wander into an ethereal canyon
            </span>
            . Cut off from the "real world", they wander through the foggy,
            indistinct haze of an incomplete, not quite real landscape. Sir Fat
            Head gathers a mushroom which boosts Perception, and in a grand bit
            of kismet that mushroom is what allows the party to find their way
            out of the canyon.
            <br />
            <br />
            On land near Galgenbeck,{" "}
            <span style={{ color: "#FF7F50" }}>
              Jacque Manaque is excited to find his first trinket
            </span>{" "}
            in an abandoned abbey. He is only slightly crestfallen when Spug
            gently asks the squire to hand it over. The crestfallen squire
            steadies himself and realizes this is the price to pay travelling
            and learning with such famous men!
            <br />
            <br />
            Back under Galgenbeck in Tergol's lair. The party hands over the
            BOOKS OF UNFORGIVING LIGHT. Tergol is pleased and examines them
            under a black light.{" "}
            <span style={{ color: "#FF7F50" }}>
              Tergol is exicited and preaches to the choir
            </span>
            :
            <br />
            <br />
            <i>
              We have all accepted the Nameless Scriptures as the only truth.
              But, as I have said Anuk Schleger was misrepresented, misquoted.
              In fact,{" "}
              <span style={{ color: "#FF7F50" }}>
                Verhu did not whisper to Anuk Schleger; it was Arkh of SHE
              </span>
              . Anuk was killed and dismembered by some dark faction, and then
              they planted the scriptures as Anuk’s interpretation of Verhu. The
              story says his body was divided to be kept as relics to inspire
              devotion. But I believe his body was separated to reduce the
              chance his power would cause the parts to reanimate and tell the
              truth to the world. However, there are many fake relics and many
              false claims. Finding the true relics without travelling back and
              forth uselessly as the Calender of Nechrubel counts down is
              paramount. The grimoires and tomes from Schleswig help. Crudite is
              our greatest ally. If we can gather the relics here and put them
              into the ossuary nook of the Grotto of Anuk we can learn the
              truth..
            </i>
            <br />
            <br />
            Tergol continues in an aside to the party: "Unfortunately, there are
            passages that the black light alone cannot reveal.{" "}
            <span style={{ color: "#FF7F50" }}>
              We will also need the STAFF OF AWLFUL LIGHT
            </span>
            . In order to find out the last known location of the staff, you'll
            have to find the heretic Julius at the Galgenbeck University of
            All-Embracing Knowledge. He studies the Ochre Tablets of Obscurity
            and is sometimes called Orange...Orange Julius."
            <br />
            <br />
            On their way out of the sewers to street level, the{" "}
            <span style={{ color: "#FF7F50" }}>
              party encounters the drunkard XD Meadows
            </span>
            . He is a drunkard (profession from the{" "}
            <a
              rel="noreferrer"
              href="https://www.drivethrurpg.com/product/423719/Malum-Mortis-A-Mork-Borg-Metaplot"
            >
              Malum Mortis PDF
            </a>{" "}
            found at DriveThruRPG). He overheard what Tergol said about the
            relics. He informs the party that Saint Thrylla is in town.{" "}
            <i>
              "That's Thrylla not Thriller. A lot of folks misunderstand that
              see? But I'm in the know. She has a cult and they are looking for
              relics. Whether they have anything to do with the relics of Anuk
              Schlerger or not I do not know. But, word to the wise, it may be
              good to find out their agenda and if they might be friend or foe
              in the future."
            </i>
            Spug's kleptomania has been itching for some time, so he cons the
            drunkard into a hug and tries to pickpocket XD Meadows. Sir Fat Head
            moves in for the group hug as well. Alas, both pickpocket attempts
            fail as XD Meadows gives a squirmy whiskey-soaked menage-a-hug.
            Then, he stumbles away to sit by a bonfire with other wastrels and
            winos. Spug spits, "Sir Fat Head, that was my balliwick. My
            wheelhouse!" To which Sir Fat Head laughes goodnaturedly and flits
            away down the dark sewer looking for mushrooms as Spug glares after
            the spore-addled son of a scourge. Forces in the know predict a real
            bromance between Spug and Sir Fat Head; be sure to drop out and stay
            tuned in!
            <br />
            <br />
            The{" "}
            <span style={{ color: "#FF7F50" }}>
              party searches the campus of the Galgenbeck university
            </span>{" "}
            looking for Julius. At first, they are on the lookout for students
            with red or orange hair. After accosting a book-laden student a
            misunderstanding with a professor, the group switches tactics and
            looks for someone eating an orange. They speak with Chadwick who
            loves oranges. However, he doesn't have any useful information. And
            so then, they notice a female student reading an orange book, really
            more of a tablet. Gretil is friendly and excited. She explains that
            the tablet she is holding is a facsimile of a tablet of Ochre
            Obscurity. She, Chadwick, and Julius are the clique studying these
            fragile but fascinating relics. "
            <span style={{ color: "#FF7F50" }}>
              It's too bad about Julius though. He received a Holy Summons from
              Josifa Migol and has been taken to the Cathehdral of the
              Two-Headed Basilisks.
            </span>
            I'm frightened I'll never see him again. You might speak to Migol's
            army Lieutenant Huggta at the Cathedral."
            <br />
            <br />
            And so, with a real lead to find Julius as a captive, hopefully
            alive, of Huggta at the Cathredral, we wait until next time to see
            how our heroes will fare.
            <br />
            <br />
            Feel free to visit Poltroon in the{" "}
            <a
              rel="noreferrer"
              href="./mork-borg-malum-mortis-graveyard"
            >
              Mork Borg Malum Mortis Campaign Graveyard
            </a>
            .
            <br />
            <br />
            This episode of Malum Mortis brought to you in part by:
            <br />
            Game:{" "}
            <a
              href="https://morkborg.com/content/"
              rel="noreferrer"
            >
              Mork Borg
            </a>
            <br />
            Module:{" "}
            <a
              href="https://www.drivethrurpg.com/product/328612/MORK-BORG-CULT-FERETORY"
              rel="noreferrer"
            >
              Goblin Grinder [FERETORY]
            </a>
            <br />
            Class:{" "}
            <a href="https://www.drivethrurpg.com/product/343569/Svampatare-The-Mushroom-Eater--A-Mork-Borg-Class">
              Svampätare: The Mushroom Eater
            </a>
            <br />
          </p>
        </div>
      ),
    },
  ];

  return (
    <Container>
      {/* <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin" 
                    style={{position: 'float', float: 'right',width: '120px', height: '240px'}} marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=smokeraven-20&language=en_US&marketplace=amazon&region=US&placement=9189143701&asins=9189143701&linkId=d1384e0e6b9ef083fee35d7272a71f88&show_border=true&link_opens_in_new_window=true"></iframe> */}
      <Row>
        <Col>
          <div
            style={{ backgroundColor: "transparent", fontFamily: "Orbitron" }}
          >
            <main role="main" className="inner cover text-center">
              <img
                src={mbLogo}
                style={{ maxWidth: "200px" }}
                alt="Mutant Year Zero campaign"
              />
            </main>
            <div style={pageHeader}>
              <h1 className="cover-heading">Session 04: Malum Mortis</h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title className="cardTitle-mork-borg">
                FATHMU FOLLIES
              </Card.Title>
              <Card.Text>
                <ContentContainer data={section1Data} />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MBMalumMortisSession04;
