import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import flTitle from "../../../src/assets/forbidden-lands/forbidden-lands-logo.webp";
import "../../index.css";

import artifact from "../../../src/assets/finds/precious carried finds/artifact.webp";
import boneDice from "../../../src/assets/finds/simple carried finds/bone-dice.webp";
import boneStatuette1 from "../../../src/assets/finds/simple carried finds/bone-statuette-1.webp";
import boneStatuette2 from "../../../src/assets/finds/simple carried finds/bone-statuette-2.webp";
import boneStatuette3 from "../../../src/assets/finds/simple carried finds/bone-statuette-3.webp";
import boneWhistle from "../../../src/assets/finds/simple carried finds/bone-whistle.webp";
import book from "../../../src/assets/finds/simple carried finds/book.webp";
import bronzeBeltBuckle1 from "../../../src/assets/finds/simple carried finds/bronze-belt-buckle-1.webp";
import bronzeBeltBuckle2 from "../../../src/assets/finds/simple carried finds/bronze-belt-buckle-2.webp";
import bronzeBeltBuckle3 from "../../../src/assets/finds/simple carried finds/bronze-belt-buckle-3.webp";
import bronzeBracelet1 from "../../../src/assets/finds/simple carried finds/bronze-bracelet-1.webp";
import bronzeBracelet2 from "../../../src/assets/finds/simple carried finds/bronze-bracelet-2.webp";
import bronzeBracelet3 from "../../../src/assets/finds/simple carried finds/bronze-bracelet-3.webp";
import bronzeDagger1 from "../../../src/assets/finds/simple carried finds/bronze-dagger-1.webp";
import bronzeDagger2 from "../../../src/assets/finds/simple carried finds/bronze-dagger-2.webp";
import bronzeDagger3 from "../../../src/assets/finds/simple carried finds/bronze-dagger-3.webp";
import bronzeDrinkingHorn from "../../../src/assets/finds/simple carried finds/bronze-drinking-horn.webp";
import bronzeEarring1 from "../../../src/assets/finds/simple carried finds/bronze-earring-1.webp";
import bronzeEarring2 from "../../../src/assets/finds/simple carried finds/bronze-earring-2.webp";
import bronzeEarring3 from "../../../src/assets/finds/simple carried finds/bronze-earring-3.webp";
import bronzeMedallion1 from "../../../src/assets/finds/simple carried finds/bronze-medallion-1.webp";
import bronzeMedallion2 from "../../../src/assets/finds/simple carried finds/bronze-medallion-2.webp";
import bronzeMedallion3 from "../../../src/assets/finds/simple carried finds/bronze-medallion-3.webp";
import bronzeNecklace1 from "../../../src/assets/finds/simple carried finds/bronze-necklace-1.webp";
import bronzeNecklace2 from "../../../src/assets/finds/simple carried finds/bronze-necklace-2.webp";
import bronzeNecklace3 from "../../../src/assets/finds/simple carried finds/bronze-necklace-3.webp";
import bronzeShield1 from "../../../src/assets/finds/simple carried finds/bronze-shield-1.webp";
import bronzeShield2 from "../../../src/assets/finds/simple carried finds/bronze-shield-2.webp";
import bronzeShield3 from "../../../src/assets/finds/simple carried finds/bronze-shield-3.webp";
import bronzeStatuette1 from "../../../src/assets/finds/simple carried finds/bronze-statuette-1.webp";
import bronzeStatuette2 from "../../../src/assets/finds/simple carried finds/bronze-statuette-2.webp";
import bronzeStatuette3 from "../../../src/assets/finds/simple carried finds/bronze-statuette-3.webp";
import coins from "../../../src/assets/finds/simple carried finds/coins-copper.webp";
import copperCrown from "../../../src/assets/finds/simple carried finds/copper-crown-1.webp";
import copperHeadband from "../../../src/assets/finds/simple carried finds/copper-headband.webp";
import copperRing1 from "../../../src/assets/finds/simple carried finds/copper-ring-1.webp";
import copperRing2 from "../../../src/assets/finds/simple carried finds/copper-ring-2.webp";
import copperRing3 from "../../../src/assets/finds/simple carried finds/copper-ring-3.webp";
import goodBoots1 from "../../../src/assets/finds/simple carried finds/good-boots-1.webp";
import goodBoots2 from "../../../src/assets/finds/simple carried finds/good-boots-2.webp";
import goodBoots3 from "../../../src/assets/finds/simple carried finds/good-boots-3.webp";
import goodGloves1 from "../../../src/assets/finds/simple carried finds/good-gloves-1.webp";
import goodGloves2 from "../../../src/assets/finds/simple carried finds/good-gloves-2.webp";
import goodGloves3 from "../../../src/assets/finds/simple carried finds/good-gloves-3.webp";
import lantern1 from "../../../src/assets/finds/simple carried finds/lantern-1.webp";
import lantern2 from "../../../src/assets/finds/simple carried finds/lantern-2.webp";
import lantern3 from "../../../src/assets/finds/simple carried finds/lantern-3.webp";
import pieceOfAmber from "../../../src/assets/finds/simple carried finds/piece-of-amber.webp";
import simpleGemstone from "../../../src/assets/finds/simple carried finds/simple-gemstone.webp";

const cardTitle = {
  paddingTop: 10,
  paddingLeft: 10,
  paddingBottom: 10,
  fontSize: 24,
  backgroundColor: "rgba(177, 83, 90, 1) ",
};
const pageHeader = {
  color: "black",
  backgroundColor: "white",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Aladin",
  fontSize: 20,
  backgroundColor: "rgba(118, 131, 87, 1)",
  color: "white",
  marginBottom: "15px",
};

class FLFindsSimpleCarried extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "BEGINNING NAME",
      text: "Click button for some fun.",
      testValue: "test value",
      selectedMutation: null,
    };
  }

  componentWillMount() {
    document.body.style.backgroundImage = `url(${require("../../../src/assets/fl-map-groveland-woods.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "round";
    document.body.style.backgroundAttachment = "fixed";
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = null;
  }

  render() {
    return (
      <div>
        <Container>
          <Row>
            <Col>
              <div
                style={{
                  backgroundColor: "transparent",
                  fontFamily: "Orbitron",
                }}
              >
                <main role="main" className="inner cover text-center">
                  <img
                    src={flTitle}
                    style={{ maxWidth: "400px" }}
                    alt="Forbidden Lands campaign"
                  />
                </main>
                <div className={"forbiddenLandsFont"} style={pageHeader}>
                  <h1 className="cover-heading">
                    Forbidden Lands Finds - Simple Carried [VTT Tokens]
                  </h1>
                  <p className="lead leadExtra">
                    The simple carried finds from core Forbidden Lands GM guide
                    as transparent webp files in 512x512 dimension, perfect for
                    Foundry VTT.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card style={cardStyle}>
                <Card.Body>
                  <Card.Title style={cardTitle}>
                    Simple Carried Finds
                  </Card.Title>

                  <Card.Text>
                    <div className={"tokenWrapper"}>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Artifact</div>
                        <img src={artifact} alt="Artifact" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Bone Dice</div>
                        <img src={boneDice} alt="Bone Dice" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Bone Statuette 1
                        </div>
                        <img src={boneStatuette1} alt="Bone Statuette 1" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Bone Statuette 2
                        </div>
                        <img src={boneStatuette2} alt="Bone Statuette 2" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Bone Statuette 3
                        </div>
                        <img src={boneStatuette3} alt="Bone Statuette 3" />
                      </div>

                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Bone Whistle</div>
                        <img src={boneWhistle} alt="Bone Whistle" />
                      </div>
                    </div>
                    <div className={"tokenWrapper"}>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Book</div>
                        <img src={book} alt="Book" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Bronze Belt Buckle 1
                        </div>
                        <img
                          src={bronzeBeltBuckle1}
                          alt="Bronze Belt Buckle 1"
                        />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Bronze Belt Buckle 2
                        </div>
                        <img
                          src={bronzeBeltBuckle2}
                          alt="Bronze Belt Buckle 2"
                        />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Bronze Belt Buckle 3
                        </div>
                        <img
                          src={bronzeBeltBuckle3}
                          alt="Bronze Belt Buckle 3"
                        />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Bronze Bracelet 1
                        </div>
                        <img src={bronzeBracelet1} alt="Bronze Bracelet 1" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Bronze Bracelet 2
                        </div>
                        <img src={bronzeBracelet2} alt="Bronze Bracelet 2" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Bronze Bracelet 3
                        </div>
                        <img src={bronzeBracelet3} alt="Bronze Bracelet 3" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Bronze Dagger 1
                        </div>
                        <img src={bronzeDagger1} alt="Bronze Dagger 1" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Bronze Dagger 2
                        </div>
                        <img src={bronzeDagger2} alt="Bronze Dagger 2" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Bronze Dagger 3
                        </div>
                        <img src={bronzeDagger3} alt="Bronze Dagger 3" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Bronze Drinking Horn
                        </div>
                        <img
                          src={bronzeDrinkingHorn}
                          alt="Bronze Drinking Horn"
                        />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Bronze Earring 1
                        </div>
                        <img src={bronzeEarring1} alt="Bronze Earring 1" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Bronze Earring 2
                        </div>
                        <img src={bronzeEarring2} alt="Bronze Earring 2" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Bronze Earring 3
                        </div>
                        <img src={bronzeEarring3} alt="Bronze Earring 3" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Bronze Medallion 1
                        </div>
                        <img src={bronzeMedallion1} alt="Bronze Medallion 1" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Bronze Medallion 2
                        </div>
                        <img src={bronzeMedallion2} alt="Bronze Medallion 2" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Bronze Medallion 3
                        </div>
                        <img src={bronzeMedallion3} alt="Bronze Medallion 3" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Bronze Necklace 1
                        </div>
                        <img src={bronzeNecklace1} alt="Bronze Necklace 1" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Bronze Necklace 2
                        </div>
                        <img src={bronzeNecklace2} alt="Bronze Necklace 2" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Bronze Necklace 3
                        </div>
                        <img src={bronzeNecklace3} alt="Bronze Necklace 3" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Bronze Shield 1
                        </div>
                        <img src={bronzeShield1} alt="Bronze Shield 1" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Bronze Shield 2
                        </div>
                        <img src={bronzeShield2} alt="Bronze Shield 2" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Bronze Shield 3
                        </div>
                        <img src={bronzeShield3} alt="Bronze Shield 3" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Bronze Statuette 1
                        </div>
                        <img src={bronzeStatuette1} alt="Bronze Statuette 1" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Bronze Statuette 2
                        </div>
                        <img src={bronzeStatuette2} alt="Bronze Statuette 2" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Bronze Statuette 3
                        </div>
                        <img src={bronzeStatuette3} alt="Bronze Statuette 3" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Coins, copper</div>
                        <img src={coins} alt="Coins, copper" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Copper Crown</div>
                        <img src={copperCrown} alt="Copper Crown" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Copper Headband
                        </div>
                        <img src={copperHeadband} alt="Copper Headband" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Copper Ring 1</div>
                        <img src={copperRing1} alt="Copper Ring 1" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Copper Ring 2</div>
                        <img src={copperRing2} alt="Copper Ring 2" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Copper Ring 3</div>
                        <img src={copperRing3} alt="Copper Ring 3" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Good Boots 1</div>
                        <img src={goodBoots1} alt="Good Boots 1" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Good Boots 2</div>
                        <img src={goodBoots2} alt="Good Boots 2" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Good Boots 3</div>
                        <img src={goodBoots3} alt="Good Boots 3" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Good Gloves 1</div>
                        <img src={goodGloves1} alt="Good Gloves 1" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Good Gloves 2</div>
                        <img src={goodGloves2} alt="Good Gloves 2" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Good Gloves 3</div>
                        <img src={goodGloves3} alt="Good Gloves 3" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Lantern 1</div>
                        <img src={lantern1} alt="Lantern 1" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Lantern 2</div>
                        <img src={lantern2} alt="Lantern 2" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Lantern 3</div>
                        <img src={lantern3} alt="Lantern 3" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Piece of Amber
                        </div>
                        <img src={pieceOfAmber} alt="Piece of Amber" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Simple Gemstone
                        </div>
                        <img src={simpleGemstone} alt="Simple Gemstone" />
                      </div>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default FLFindsSimpleCarried;
