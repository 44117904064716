import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import mbLogo from "../../../../src/assets/mork-borg/mork-borg-logo.webp";
import sectionDivider from "../../../../src/assets/mork-borg/mork-borg-section-divider.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";
import cathedral from "../../../../src/assets/mork-borg/session02/cathedral-of-the-two-headed-basilisks.webp";
import josilfa from "../../../../src/assets/mork-borg/session02/josilfa-migol-portrait-no-text.webp";
import tergol from "../../../../src/assets/mork-borg/session02/tergol.webp";
import anuk from "../../../../src/assets/mork-borg/session02/anuk-schleger-statue.webp";
import vestibulations from "../../../../src/assets/mork-borg/session02/book-coronaeic-vestibulations.webp";
import crudite from "../../../../src/assets/mork-borg/session02/crudité-token.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const MBMalumMortisSession02 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/mork-borg/bkgnd-01.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  const section1Data = [
    {
      title: "Back in Galgenbeck",
      text: (
        <div class="morkBorgFont morkBorgTitleText">
          <ImageComponent
            image={anuk}
            caption="Anuk Schleger"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Anuk Schleger: monk who received the words of Verhu and wrote the Nameless Scriptures."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>

          <p>
            Our party consists of:
            <ul>
              <li>
                <span style={{ color: "#00FFFF" }}>PLAYTOPUSS</span>: an
                egocentric{" "}
                <span style={{ color: "#ADFF2F" }}>FORLORN PHILOSOPHER</span>{" "}
                that sweats like a pig and is a cannibal. He has hypersentive
                mental tissues that are inflamed by ambient stupidity and bright
                lights.
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>HILDEBRAND</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>HERETICAL PRIEST</span>,
                Hildebrand worries constantly, washes his hands often, and is
                obsessively clean and neat. He was baptized by fire!
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>SPUG</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>WRETCHED ROYALTY</span>{" "}
                afflicted with kleptomania and afraid of pain. Pain is such a
                bother!
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>HERMAN</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>GUTTERBORN SCVM</span>. He is
                worried and naive with hands caked in sores. He is a bit a
                pyromaniac.
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>Donkey Hoatee</span>: donkey
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>
                  Poltroon the Court Jester
                </span>
                : jester minion to Spug
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>
                  Barbarister the Incredible Horse
                </span>
                : talking horse minion (master?) to Spug
              </li>
            </ul>
            Our session starts with{" "}
            <span style={{ color: "#FF7F50" }}>SPUG entering the stage</span>{" "}
            once the party has returned to Galgenbeck. They are searching for
            The Master and His Faithful Hounds when SPUG rides up and says,
            "hey, you dropped this" and hands back the wayward tooth they
            retrieved from the Emperor of Teeth.
            <br />
            <br />
            The streets of Galgenbeck are filled with doomsayers and celebrants;
            each citizen gripped with fear and anxiously awaiting a sermon from
            Josilfa Migol. The wayward{" "}
            <span style={{ color: "#FF7F50" }}>
              PCs wander to Riverside
            </span>{" "}
            &nbsp; and grab a room at Lotta Gravy. After buying a round of
            drinks, they learn the location in Chalk where the Master will be in
            the evening. Some highlights of city exploration are:
            <ul>
              <li>
                <span style={{ color: "#FF7F50" }}>Jacque Manaque</span>{" "}
                approaches party to ask if he can squire for them. Word of their
                battle with Emperor of Teeth preceeded them. He insists they
                sign a legal contract witnessed by Lotta promising if he dies in
                their service anytime after Scorch 14, they promise to pay a
                weregild of 75 silver to his father, a stevedore on the docks of
                Riverside in Galgenbeck.
              </li>
              <li>
                The rest of the party go{" "}
                <span style={{ color: "#FF7F50" }}>
                  hunting for rats, cats, and pigeons
                </span>{" "}
                to make some extra coin on behalf of Lotta.
              </li>
              <li>
                While rat hunting, Platopuss encounters a guard barricade. They
                are investigating the murder of a minor noble and checking
                weapons for blood. &nbsp;
                <span style={{ color: "#FF7F50" }}>
                  Platopuss is thrown in jail
                </span>{" "}
                as the fairly fresh severed arm he uses as a club seems
                suspicious.
              </li>
            </ul>
          </p>
        </div>
      ),
    },

    {
      title: "Lets Us Purify",
      text: (
        <div class="morkBorgFont morkBorgTitleText">
          <ImageComponent
            image={cathedral}
            caption="Cathedral of the Basilisks"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="Cathedral of the Two-Headed Basilisks"
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>

          <ImageComponent
            image={josilfa}
            caption="Josilfa Migol"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Josilfa Migol, arch-priestess of the Inquisition of the Two-Headed Basilisks."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>

          <p>
            After returning to Lotta Gravy, the{" "}
            <span style={{ color: "#FF7F50" }}>
              streets are filled with the sonorous tones of the bells of the
              Cathedral of the Two-Headed Basilisks
            </span>
            . Criers walk the streets shouting:
            <br />
            <i>
              "Josilfa Migol will speak at the Cathedral of the Two-Headed
              Basilisks in two hours time. First come first served. Find out
              about the Comet. Josilfa Migol will speak."
            </i>
            <br />
            <br />
            The PCs, minus a sleeping Hildebrand, rush to the Cathedral.
            Approaching the Cathedral's Shadow district, there are parades in
            the street, including a basilisk dance wherein dancers under
            decorative cloth undulate a two-headed serpent costume as other
            dancers in simple white robes ape supplication and servitude to the
            dancing basilisk as fireworks are crackling and Galbeck guards are
            enforcing crowd control with flails.
            <br />
            <br />
            After failing sneak and presence rolls to gain entrance to the
            Cathedral,{" "}
            <span style={{ color: "#FF7F50" }}>
              only Herman the gutterborn scum gains entrance to the historic
              sermon
            </span>
            . The service begins with a reading from the Nameless Scripture.
            This is followed by a sermon from the arch-priestess Josilfa Migol.
            <br />
            <br />
            <h4>Josilfa's Sermon</h4>
            <i>
              In a dying world prison becomes salvation.
              <br />
              Manacles become foundation.
              <br />
              Imposition of will upon the wandering weak is our sacred duty.
              <br />
              Verhu tells us that which is hidden must be exposed.
              <br />
              Psalm 1, verse 1 from the Calendar of Nechrubell: The City shall
              be made hollow. Of those who rest in hollowness, they shall not be
              seen.
              <br />
              The darkest of times are upon us. To forestall the destruction of
              our glorious city, I hereby issue this terrible ordinance.
              <br />
              <b>LET US PURIFY!</b>
            </i>
            <br />
            <br />
            The lead flagellent leads the purification. Each pew has a spiked
            swingle available for self-montification. Herman has to decide to
            not participate or choose poseur, dedicated, or fanatical level of
            self-flagellation. Recalling the congregant that shouted
            "Nechrubel's Whore" when Josilfa was introduced and then suffered
            pulped and splattered head, Herman decides to flagellate at
            dedicated level. He suffers some damage but a priest gives him
            Verhu's Blessing (no need to eat or drink for 7 days and +1 STR for
            seven days).
            <span style={{ color: "#FF7F50" }}>
              Everyone at the sermon is given a copy of the Ordination of
              Devotion
            </span>{" "}
            which is also being hung throughout the city on every streetlamp and
            storefront.
            <br />
            Note: for details on the Ordination, see the adventure{" "}
            <a
              href="https://www.drivethrurpg.com/product/376385/Galgenbeck-Sacrifice--A-Mork-Borg-Adventure"
              rel="noreferrer"
            >
              Galgenbeck: Sacrifice
            </a>
            .
          </p>
        </div>
      ),
    },
    {
      title: "The Ultimate Heresy",
      text: (
        <div class="morkBorgFont morkBorgTitleText">
          <ImageComponent
            image={tergol}
            caption="Tergol"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Tergol the chield-thief holds the keys to the ultimate heresy."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>

          <ImageComponent
            image={vestibulations}
            caption="Coronaeic Vestibulations"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="Coronaeic Vestibulations, the ultimate heresy"
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>

          <p>
            After the sermon, the PCs follow Hildebrand's information about
            Platopuss being in jail. Upon bribing the guards it is discovered
            that Platopuss is simply gone. A raven perched between the bars of
            Platopuss' cell leads the party to Chalk where they find The Master
            again.
            <br />
            <br />
            They say Platopuss is missing and he has the teeth. The Master
            smiles shallowly and says that the teeth are in good hands then.
            They must follow his raven Hodde and one of the wolves to find their
            reward and make a new friend.
            <br />
            <br />
            <span style={{ color: "#FF7F50" }}>
              The PCs follow the animals and enter into the labyrinthe sewers
              beneath Galgenbeck
            </span>
            . After an hour of dark, claustrophobic twists and turns in the foul
            sewers, the party plunges into a seemingly bottomless darkness and
            seem to fall forever as they lose consciousness.
            <br />
            <br />
            They awaken in a domed cave with{" "}
            <span style={{ color: "#FF7F50" }}>Tergol standing over them</span>.
            He accepts the teeth and pays the promised 200 silver. He is not the
            child-thief or the monster as the legends claim. Rather than a
            boogeyman for tormenting children before bedtime, he is in fact an
            excommunicated priest from the Inquisition of the Two-Headed
            Basilisks.
            <br />
            <br />
            He explains that his army of apostates, drunks, and hoboes live in
            these caves far beneath the tomb of Anuk Schleger and the Catheral.
            They have built these caves as headquarters for a{" "}
            <span style={{ color: "#FF7F50" }}>
              rebellion based on a book Tergol found called the Coronaeic
              Vestibulations
            </span>
            .
            <br />
            <br />
            In the Coronaeic Vestibulations, it is told that Verhu did not
            whisper the secrets to Anuk.
            <span style={{ color: "#FF7F50" }}>
              In fact, it was Arkh, the deceptive head of SHE that told Anuk
              prophesies
            </span>
            . Tergol shares the page know as The Tree of Creation. He shows how
            the art depicting Verhu is a palimpsest and underneath it actually
            shows Arkh.
            <br />
            <br />
            The Tree of Creation has seven cycles. Tergol and his minions have
            carved a replica of the page into the walls of a cave with a
            necessed niche for each of the seven cycles. If the{" "}
            <span style={{ color: "#FF7F50" }}>
              seven relics of Anuk Schleger
            </span>{" "}
            can be retrieved and place in these niches, Tergol believes they can
            resurrect Anuk and have him re-tell the true truths he received and
            bring about a new age of enlightenment and open the Shimmering Lands
            for everyone.
            <br />
            <br />
            The first step in this great plan requires tomes and information.{" "}
            <span style={{ color: "#FF7F50" }}>
              Tergol has a silent ally in the vizier to King Fathmu in Schlewig,
              a man called Crudite
            </span>
            . The PCs are given a marked replica of the Anuk Amulet. Telltale
            scratches in the shape of a sigil on the back of the amulet will be
            meaningful to anyone in the know that closely examines them. They
            are to show these amulets to Crudite and get the books for Tergol.
          </p>
        </div>
      ),
    },
    {
      title: "The Sad And Gaudy City of Schleswig",
      text: (
        <div class="morkBorgFont morkBorgTitleText">
          <ImageComponent
            image={crudite}
            caption="Vizier Crudite"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Crudite: vizier to King Fathmu IX in Schleswig."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>

          <p>
            The{" "}
            <span style={{ color: "#FF7F50" }}>PCs travel to Schleswig</span>.
            They are turned away by the guards at the palace because they "have
            the stench of Galgenbeck" about them. The party finds an inn called
            the Exploded Coffin. They clean off the residue of feces and toxic
            chemicals from the sewers of Galgenbeck. Their next attempt is
            successful and Crudite meets them on the steps of the palace. After
            he examines one of their amulets, he hurries them into a tea room in
            the palace. He never says the name Tergol, he speaks only of "our
            mutual friend". Crudite will get the books together. Meanwhile, he
            offers 200 silver apiece if they can help with this goblin
            infestation taking over the city.
            <br />
            <br />
            Anyone attacked by a goblin will turn into a goblin themselves
            within d6 days. And the true horror of it all is that the goblin has
            only to attack with intenion. They need not even succeed in striking
            their target for that target to get the{" "}
            <span style={{ color: "#FF7F50" }}>goblin sickness</span>. The cure
            for goblin sickness is to kill the goblin(s) that attacked you.
            Clearly, if left unchecked, the goblin sickness could wipe out the
            entire city.
            <br />
            <br />
            <span style={{ color: "#FF7F50" }}>
              Nagel Krat has been selling his "goblin cure" from The Magickal
              Shoppe
            </span>{" "}
            he inherited from his father. The change of ownership to Nagel when
            his father disappeared two weeeks ago, and the five days he has been
            selling the cure coincides with the goblin infestation. It's a
            suspicious co-incidence. Hildebrand uses his Frighful Visions feat
            to convince Crudite to cough up two guards to help with the
            investigation.
            <br />
            <br />
            Outside the palace a begger approaches the party. As he makes his
            pitch for coin, a dozen goblins are suddenly swarming the party and
            the beggar. The goblins whoop and chitter as they slap everyone and
            run past them. The beggar screams, "Dear god! We have been attacked.{" "}
            <span style={{ color: "#FF7F50" }}>
              We have to kill those goblins!
            </span>
            " A footrace ensues with these highlights:
            <ul>
              <li>
                Jacque is given the letter from Crudite and told to find the
                barracks where they can requisition two soldiers and then Jacque
                is to bring them to the fight ASAP.
              </li>
              <li>
                Spug tries to mount Barbarister. Despite Spug's logic,
                Barbarister is in a huff and refuses.
              </li>
              <li>
                The beggar and others run as fast as they can, but just when it
                seems they might catch up, the goblins split off into half a
                dozen different directions. The party splits so they can
                hopefully kill as many goblins as possible since no one knows
                which particular goblin is the one they may have struck them.
              </li>
              <li>
                Thanks to fighting separately, the battles are hair-raising and
                deadly; notably, &nbsp;
                <span style={{ color: "#FFFF00" }}>Platopuss is killed</span>.
              </li>
            </ul>
            The party then goes to the Magickal Shoppe.
            <br />
            <span style={{ color: "#FF7F50" }}>
              Spug, Poltroon the Jester, and Herman all have the goblin disease.
            </span>
            Nagel runs the shop with the help of Qarg. Nagel is an electric,
            silver-tongued salesman and entrepreneur, whereas Quarg is a silent,
            large minion armed with a dirty shovel. The party buys goblin cures
            for each of the infected players and Spug, Poltroon, and Herman
            quaff them. Spug's kleptomania gets the best of him and he
            successfully steals an extra goblin cure. The shop closes down for
            the day with Nagel promising more cure the next day at the only
            slightly increased price of 45 silver per dose.
            <br />
            <br />
            Outside the shop, the party tries to sell their extra bottle to an
            indignant noble that curses at them. Since the group has two
            pyromaniacs and Nagel seems to be staying inside the shoppe for the
            night, they burn bown the shoppe! They do it successfully and
            without being noticed. They spy on Nagel as he flees his burning
            store into the night. He leads them to a mysterious tower that is
            humming with energy. He enters and our party is standing outside,{" "}
            <span style={{ color: "#FF7F50" }}>
              ready to enter the GOBLIN GRINDER
            </span>
            !
            <br />
            <br />
            We ended the session with GETTING BETTER rolls, dividing up
            Platopuss' stuff, and rolling up a new character named{" "}
            <span style={{ color: "#FF7F50" }}>
              KLATUUBARADA the FUGITIVE KNIGHT
            </span>
            ;
            <br />
            <br />
            <span style={{ color: "#FF7F50" }}>Next session</span> we don't have
            to worry about food & water or the Calendar roll, but we do have to
            make the goblin disease rolls right outside the tower before the
            carnage begins.
            <br />
            <br />
            This episode of Malum Mortis brought to you in part by:
            <br />
            Game:{" "}
            <a
              href="https://morkborg.com/content/"
              rel="noreferrer"
            >
              Mork Borg
            </a>
            <br />
            Artwork:{" "}
            <a
              href="https://goatmansgoblet.itch.io/"
              rel="noreferrer"
            >
              The Goatman's Goblet
            </a>
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <i>Art includes city of Schleswig and portrait for Crudite</i>.
            <br />
            Module:{" "}
            <a
              href="https://www.drivethrurpg.com/product/376385/Galgenbeck-Sacrifice--A-Mork-Borg-Adventure"
              rel="noreferrer"
            >
              Galgenbeck Sacrifice
            </a>
            <br />
            Rules:{" "}
            <a href="https://www.drivethrurpg.com/product/341379/Deck-of-Terribly-Broken-Bodies-A-ThirdParty-Mork-Borg-Card-Deck">
              Terribly Broken Bodies
            </a>
            <br />
            Class:{" "}
            <a href="https://neonon.itch.io/mork-borg-fugitive-knight">
              Fugitive Knight
            </a>
            <br />
            Tergol is based on Kurt Reimann of{" "}
            <a href="https://www.nightworksmedia.com/">Nightworks Media</a>.
          </p>
        </div>
      ),
    },
  ];

  return (
    <Container>
      {/* <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin" 
                    style={{position: 'float', float: 'right',width: '120px', height: '240px'}} marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=smokeraven-20&language=en_US&marketplace=amazon&region=US&placement=9189143701&asins=9189143701&linkId=d1384e0e6b9ef083fee35d7272a71f88&show_border=true&link_opens_in_new_window=true"></iframe> */}
      <Row>
        <Col>
          <div
            style={{ backgroundColor: "transparent", fontFamily: "Orbitron" }}
          >
            <main role="main" className="inner cover text-center">
              <img
                src={mbLogo}
                style={{ maxWidth: "200px" }}
                alt="Mutant Year Zero campaign"
              />
            </main>
            <div style={pageHeader}>
              <h1 className="cover-heading">Session 02: Malum Mortis</h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title className="cardTitle-mork-borg">
                THE ULTIMATE HERESY
              </Card.Title>
              <Card.Text>
                <ContentContainer data={section1Data} />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MBMalumMortisSession02;
