import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import morena from "../../../../assets/myz/morena-and-friends.jpg";
import amnesiac from "../../../../assets/myz/threat-amnesiac-1.jpg";
import battle from "../../../../assets/myz/session-23-battle.jpg";
import bakis from "../../../../assets/myz/bakis.png";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Sector L08 [Highway]: Heat Wave, Old Enemies",
    text: (
      <p>
        05-15-0001: this session we only had two players so the party consisted
        of Smoke Raven (stalker), Double-Bird (slave), Jebe (dog handler), and
        Boss Hulagu.
        <br />
        <br />
        We took care of non-party business first which included:
        <ul>
          <li>BITTERBEASTS: one new egg hatched. No successes on training.</li>
          <li>
            PROJECT ROLLS: +6 croplands (+2 from Hulagu roll and +1 for each
            non-present PC)
          </li>
          <li>
            PURE PLANT PATROL: the first Pure Plant patrol is attacked by
            creature. No fatalities, but also no water/supplies traded.
          </li>
        </ul>
        Section L08 is a HIGHWAY sector. A HEAT WAVE kicks in and characters
        have to make ENDURE rolls and drink a water every hour for three hours.
        As the party slogs through the heat they encounter three ex-SKAG gang
        members.
        <ul>
          <li>
            Morena the Perseverant (enforcer): bossy, tough, and a bit of a
            jerk. In other words, an enforcer.
          </li>
          <li>
            Kyle the Unmotivated (stalker): bloodthirsty, unhinged and strong.
            In other words, a psycho stalker.
          </li>
          <li>Luna the Chronicler: calm, cautious and relatively sane.</li>
        </ul>
        Morena walks toward the party using INTIMIDATE to distract them as Kyle
        works on starting up a chainsaw. Morena does some serious trash talking
        but The IL Gang takes it in stride. After briefly considering putting
        the Skaggers out of their misery, they appeal to the Skaggers.
        <br />
        <br />
        The Skaggers shrug off the invitation to return to the fold and walk
        away. The party follows Morena and her co-horts for several hours until
        they discover the three of them are not part of a larger group. They
        have been living in a cave-like recess under the highway in a pitiful
        little camp.
        <br />
        <br />
        Boss Hulagu spritzes himself with COLOGNE and dons his SUNGLASSES to get
        the best modifiers and finally convinces Morena, Kyse, and Luna to come
        with them.
      </p>
    ),
    image: {
      imagePath: morena,
      altText: `Morena, Kyle, and Luna are ex-Skag gang members met in the Zone. They fled the Sunshine Plaza after losing the Civil War.`,
      className: "imageRight",
      imageCaption: `Morena & Friends`,
    },
  },
  {
    title: "Bakis the Scrap Oracle",
    text: (
      <p>
        At the southern edge of Sector L08 the party encounters a lone male
        mutant that is carrying a wild amount of scrap. He has a massive
        backpack stuffed with things jutting out in every direction and has a
        belt with dozens of items clipped to it. He seems entirely unafraid of
        the party. He is covered with odd tattoos and is very friendly.
        <br />
        <br />
        "Welcome friends! My name is Bakis. Care to look at my wares? I have
        lots of scrap for sale", he says as he sets down the backpack. He flips
        up a rigged piece of wood on one side of the pack that has a collapsible
        stand leg to form a rickety table of sorts. He begins unclipping items
        from his belt and arranging them on the table; all the while he is
        encouraging the party to come take a look. He unpacks a blanket from the
        backpack and arranges more items on the blanket. He keeps unpacking
        scrap and setting it on display until he is fully nude; literally
        everything on him is for sale.
        <br />
        <br />
        He takes a look at Double-Bird's rot to see if he can help as the other
        PCs scan the scrap for hidden artifacts or usable items. Bakis has the
        ROT EATER mutation but he cannot help Double-Bird's permanent rot. As
        Bakis banters and offers up some advice/fortunes for PCs, they feel the
        warm happy glow of love and peace. They realize Bakis is doing
        something, but they feel so good they don't care. Metagame: Bakis also
        has TELEPATHY and generates a field of warmth and love as part of his
        sales pitch. Even though the PCS do not trade or barter for any scrap,
        Bakis gives some advice:
        <ul>
          <li>
            Jebe: “When you part from your friend (looks at Double-Bird), you
            grieve not; For that which you love most in him may be clearer in
            his absence, as the zone is clearer from the ruins of a tall
            building."
          </li>
          <li>
            Smoke Raven: "Say not I have found the path, but rather that you
            have met your soul walking on the path. All souls walk the Zone."
          </li>
          <li>
            Hulagu: "There will be a choice between evils. This is the burden of
            the boss. Always there must be darkness for the light to shine. Do
            not hesitate. Pay the price. The mighty are on high, but the
            basement of their soul is where weakness and lies breed like zone
            rot."
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: bakis,
      altText: `Bakis the scrap oracle. He carries a scrap marketplace on his body and strips naked to setup shop. He has ROT EATER and TELEPATHY mutations (at least) and seems unafraid alone in the Zone.`,
      className: "imageLeft",
      imageCaption: "Bakis, Scrap Oracle",
    },
  },
  {
    title: "Sector M08 [Pipeline]: Those $%*#ing Zone Hounds!",
    text: (
      <p>
        Sector M08 has a rusted pipeline running (mostly) next to an ancient,
        shattered road. After a couple of hours walking in the sector the party
        sees an old lady on top of a shipping container near a broken down jeep.
        She hears Hulagu's motorcycle and starts babbling about the zone dogs.
        No dogs are in sight so Smoke Raven and Double Bird ride up next to the
        container. That's when they hear the howling . . .
        <br />
        <br />
        A fight with over a dozen zone dogs breaks out. Jebe is brought to the
        brink of being broken, Smoke Raven's bitterbeast mount Valegro is killed
        by the zone dogs. As the starving hounds clump around Valegro's corpse,
        a crossbow bolt wipes out one of the hounds. Strange mutants from The
        Other Ark appear on the edge of the battle map to help out.
        <br />
        <br />
        At the end of the battle we have two deaths: Tiska 2, Jebe's hound is
        dead. Also, Smoke Raven's mount Valegro was destroyed as mentioned
        above.
        <br />
        <br />
        The small group seems to be led by Krinn. The others in the group are
        Scrunchie, Sanna Tee, and Dink. The session ended right at the end of
        combat. There are some questions to be answered at the beginning of next
        session:
        <ul>
          <li>
            Who exactly are Krinn and company? Are they friendly once dogs are
            dead?
          </li>
          <li>
            Who is the confused and wounded old lady wearing a jumpsuit with the
            Mimir logo?
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: battle,
      altText: `Once again a pack of Zone Dogs is a deadly encounter. They kill Tiska 2 the hound and the bitterbeast Valegro.`,
      className: "imageLeft",
      imageCaption: "Battle in Sector M08",
    },
  },
  {
    title: "Wrapup",
    text: (
      <p>
        Next session will begin afternoon of 05-15-0001. We will have to award
        three XP each to Smoke Raven, Double-Bird, Jebe, and Hulagu. We ended
        right at end of combat; so interaction with Krinn and friends and the
        old lady on top of the container still to be played out.
        <br />
        <br />
        The body count roll was 4. Minus one due to FOOD DEV level of 14 leaves
        us with three casualties. The dead include two unnamed mutants and Silas
        the Chronicler (Rowbert's childhood friend).
        <br />
        <br />
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: amnesiac,
      altText: `A confused old lady in a jumpsuit with the Mimir logo. She can barely speak to be understood and has a heavy accent similar to the Wanderers.`,
      className: "imageLeft",
      imageCaption: "Amnesiac Mimir Lady",
    },
  },
];

const MYZAnchortownSession23 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 23: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  The IL gang encounters some ex-SKAG gang members, walks
                  through a heat wave, meets a weird scrap oracle named Bakis,
                  and has a huge fight with Zone Dogs to save an amnesiac with a
                  last minute hand from mutants from The Other Ark.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                {/*<Card.Title style={cardTitle}>Shootout at Burger Jim's, 03-27-0001 - 03-27-0001</Card.Title>*/}
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession23;
