import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";

import { useEffect } from "react";

import septina from "../../../../assets/myz/npc-septina.png";
import battle from "../../../../assets/myz/battle-royale.jpg";
import berke from "../../../../assets/myz/boss-berke.png";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Bookkeeping",
    text: (
      <p>
        06-08-0001:
        <br />
        <br />
        Points are spend and mutation points allocated. With a battle royale
        waiting and one player out, we decided to bump ark reputation,
        bitterbeast, pure plant patrol, and project rolls to next session
        (double rolls).
        <br />
        <br />
        NOTE: Altair and Chi Tzu are housed at the Rust Castle along with Summer
        of Love bus as the Gang travels the high seas.
        <br />
        <br />
        Critical Injury Roundup
        <ul>
          <li>
            GLADHAND has a DAMAGED KIDNEY. Ends morning of 06-13-0001. Point of
            damage for roll to Force, Move or Fight.
          </li>

          <li>
            SMOKE RAVEN has a GROIN HIT. End morning of 06-10-0001. Point of
            damage for roll to Force, Move or Fight.
          </li>
          <li>
            DOUBLE BIRD no longer suffering from rot critical REDNESS & STENCH.
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: battle,
      altText: `The IL Gang and new saurian allies tear apart the scrap pirates and Capt. Ossian turns over Berke to the gang.`,
      className: "imageLeft",
      imageCaption: "Battle Royale",
    },
  },
  {
    title: "Battle Royale",
    text: (
      <p>
        <br />
        <br />
        Ladies and gentlemen, in this corner we have the IL Gang. We have Smoke
        Raven, Double Bird, Bayazid, and Boss Hulagu. They're teamed with the
        paralyzed Mechie captain of the Steelcrest Elwith and her bodyguard
        Elli. In addition, saurian leader Septina (thanks to Hulagu's ruthless
        assassination of saurian boss Dante) and about 14 of her people are
        ready to fight with the gang.
        <br />
        <br />
        On the other side, we have about 25 scrap pirates, some on The Wrecker
        and some on shore, led by Captain Ossian and Boss Berke.
        <br />
        <br />
        <h4>Battle Highlights</h4>
        <ul>
          <li>
            Bayazid holds his own, wielding his kantana in a gearhead's
            emulation of Boss Hulagu.
          </li>
          <li>
            Smoke Raven, still crippled with a GROIN HIT injury, kicks back
            against a swamp log and fires round after round with his big gun (to
            devastating effect generally speaking).
          </li>
          <li>
            Boss Hulagu authorizes saurians swimming out to dismantle the
            engienes of The Wrecker and hops into battle with his kanata and
            quick draw follow-up pistol shot when needed.
          </li>
          <li>
            Whopper Roggi from the RUST CASTLE breaks free from her saurian
            guards and swims toward the Steelcrest. Just as Roggi reaches the
            vessel, she is shot by Elli and sinks into the dark waters of the
            DEAD BLUE SEA. Dead? Alive? We do not know.
          </li>
          <li>
            Double Bird runs in a slow motion BODYGUARD moment to intercept a
            deadly shot from Berke on the scrap cannon aimed at Hulagu. With a
            pushed MOVE roll Double Bird takes the damage and then shakes it
            off. He comes out of his ninja roll, shakes off the damage, then
            grins at the boss and gives a high five. And then . . . HE DOES IT
            AGAIN on the next lucky shot from a scrap pirate who zeroes in on
            Hulagu for cutting down his "bunk buddy" Darrian.
          </li>
        </ul>
        <h4>IL Gang Brokers Handover of Berke</h4>
        As the battle rages, Captain Ossian runs to the tower of the ship. Soon
        he is arguing with Boss Berke who is trying to load the scrap cannon
        again. Ossian shouts and holds out a hand to drain Berke's vitality with
        his PARASITE mutation. Ossian calls or a cease-fire. He offers up Berke
        to the IL Gang in exchange for he and his remaining crew leaving without
        further molestation.
        <br />
        <br />
        Smoke Raven takes a leading role in the negociations. He draws a hard
        line and demands recompense from the pirate for having made the choice
        to side with Berke. Ossian explains that Berke came to them with high
        quality scrap that allowed them to get The Wreker operational again and
        fund their exploits. Berke was connected in some way with ability to get
        to supplies. They reveal that Berke sometimes spends his shore leave at
        the Oilfield of the Ancients. Smoke Raven handcuffs Berke but treats him
        with respect; giving water and grub after telling Berke his command to
        action is redemption. The spoils of war are:
        <ul>
          <li>Berke handed over to the IL Gang.</li>
          <li>Scrap pilot Julian is assigned as Berke's dedicated guard.</li>
          <li>
            Scrap Pirates hand over 10 GRUB, 10 WATER, 8 SCRAP, 13 BULLETS
          </li>
          <li>Ossian gives location of Oilfield of the Ancients</li>
          <li>
            Ossian and crew will return to Sector A08 in a week to check on
            Julian and learn Berke's fate.
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: berke,
      altText: `Boss Berke, the resilient traitor of the Sunshine Plaza, now working with Ossian and the scrap pirates.`,
      className: "imageLeft",
      imageCaption: "Boss Berke",
    },
    image2: {
      imagePath: septina,
      altText: `Septina, second-in-command and soon to be leader of the Saurians.`,
      className: "imageRight",
      imageCaption: "Septina",
    },
  },
  {
    title: "Wrapup",
    text: (
      <p>
        Using our modified XP rules, Double-Bird and Hulagu are tied for
        nominations ( which is inevitable with two players). Hulagu wins the
        roll off. And Double Bird won an extra Xp (for a total of 4) thanks to
        sacrificing for his buddies with the double BODYGUARD move to protect
        Hulagu. Everyone else get 3 XP.
        <br />
        <br />
        Next session will start on in-game in the early morning hours of
        06-08-2021 with party about to wrap up with Septina and saurians by
        searching the submarine for the scuba gear Nikkil asked them to
        retrieve.
        <br />
        <br />
        NEXT SESSION WILL START WITH:
        <ul>
          <li>
            Searching the Submarine for treasure Septina allows and the scuba
            gear.
          </li>
          <li>
            Roll location of Oilfield of the Ancients on the DEAD BLUE SEA.
          </li>
          <li>Scrap Handout breakdown from the scrap pirates handed over.</li>
          <li>
            Double rolls for projects, bitterbeasts, ark reputation, and pure
            plant patrols.
          </li>
        </ul>
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        .
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
];

const MYZAnchortownSession40 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 40: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  The IL gang and new saurian allies face off against Berke and
                  the scrap pirates in a battle royale.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                {/*<Card.Title style={cardTitle}>Shootout at Burger Jim's, 03-27-0001 - 03-27-0001</Card.Title>*/}
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession40;
