import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import NavBarKidsOnBikes from "../../../assets/kids-on-bikes/navbar-icon-kids-on-bikes.webp";

const KidsOnBikesNavBar = () => {
  return (
    <NavDropdown title="Kids on Bikes" id="basic-nav-dropdown">
      <Navbar bg="dark" variant="dark" expand="md" sticky="top">
        <Navbar.Brand href="#">
          <div style={{ padding: "0px 0px 0px 5px" }}>Kids On Bikes</div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <a href=".">
              <img
                src={NavBarKidsOnBikes}
                alt="Kids On Bikes"
                style={{ maxHeight: 85 }}
              />
            </a>
          </Nav>
          <NavDropdown title="Sessions" id="basic-nav-dropdown">
            <NavDropdown.ItemText></NavDropdown.ItemText>
            <NavDropdown.Item href="/kids-on-bikes-turnaround-rock-session-05">
              Turnaround Rock: Session 05:
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;05-31-2024
            </NavDropdown.Item>
            <NavDropdown.Item href="/kids-on-bikes-turnaround-rock-session-04">
              Turnaround Rock: Session 04:
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;05-04-2024
            </NavDropdown.Item>
            <NavDropdown.Item href="/kids-on-bikes-turnaround-rock-session-03">
              Turnaround Rock: Session 03:
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;02-16-2024
            </NavDropdown.Item>
            <NavDropdown.Item href="/kids-on-bikes-turnaround-rock-session-02">
              Turnaround Rock: Session 02:
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;01-19-2024
            </NavDropdown.Item>
            <NavDropdown.Item href="/kids-on-bikes-turnaround-rock-session-01">
              Turnaround Rock: Session 01:
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;01-05-2024
            </NavDropdown.Item>
            <NavDropdown.Item href="/kids-on-bikes-turnaround-rock-session-00">
              Turnaround Rock: Session 00:
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;12-22-2023
            </NavDropdown.Item>
          </NavDropdown>
        </Navbar.Collapse>
      </Navbar>
    </NavDropdown>
  );
};

export default KidsOnBikesNavBar;
