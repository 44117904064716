import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import flTitle from "../../../src/assets/forbidden-lands/forbidden-lands-logo.webp";
import "../../index.css";

import artifact from "../../../src/assets/finds/precious carried finds/artifact.webp";
import dragonScaleBoots from "../../../src/assets/finds/precious carried finds/dragonscale-boots.webp";
import furCloakGoldClasp from "../../../src/assets/finds/precious carried finds/fur-cloak-gold-clasp.webp";
import goldAmulet1 from "../../../src/assets/finds/precious carried finds/gold-amulet-1.webp";
import goldAmulet2 from "../../../src/assets/finds/precious carried finds/gold-amulet-2.webp";
import goldAmulet3 from "../../../src/assets/finds/precious carried finds/gold-amulet-3.webp";
import goldBowl from "../../../src/assets/finds/precious carried finds/gold-bowl.webp";
import goldBracelet from "../../../src/assets/finds/precious carried finds/gold-bracelet.webp";
import goldChainmail from "../../../src/assets/finds/precious carried finds/gold-chainmail.webp";
import goldCoins from "../../../src/assets/finds/precious carried finds/gold-coins.webp";
import goldCrown from "../../../src/assets/finds/precious carried finds/gold-crown.webp";
import drinkingHorn from "../../../src/assets/finds/precious carried finds/gold-drinking-horn-1.webp";
import embroidery from "../../../src/assets/finds/precious carried finds/gold-embroidery.webp";
import helmet1 from "../../../src/assets/finds/precious carried finds/golden-helmet-1.webp";
import helmet2 from "../../../src/assets/finds/precious carried finds/golden-helmet-2.webp";
import helmet3 from "../../../src/assets/finds/precious carried finds/golden-helmet-3.webp";
import shield1 from "../../../src/assets/finds/precious carried finds/golden-large-shield-1.webp";
import shield2 from "../../../src/assets/finds/precious carried finds/golden-large-shield-2.webp";
import shield3 from "../../../src/assets/finds/precious carried finds/golden-large-shield-3.webp";
import flail from "../../../src/assets/finds/precious carried finds/gold-flail.webp";
import handaxe from "../../../src/assets/finds/precious carried finds/gold-handaxe.webp";
import katar from "../../../src/assets/finds/precious carried finds/gold-katar.webp";
import mug from "../../../src/assets/finds/precious carried finds/gold-mug.webp";
import necklace from "../../../src/assets/finds/precious carried finds/gold-necklace.webp";
import paddedArmor from "../../../src/assets/finds/precious carried finds/gold-padded-armor.webp";
import pikemansArmor from "../../../src/assets/finds/precious carried finds/gold-pikemans-armor.webp";
import goldRing from "../../../src/assets/finds/precious carried finds/gold-ring.webp";
import goldStatuette1 from "../../../src/assets/finds/precious carried finds/gold-statuette-1.webp";
import goldStatuette2 from "../../../src/assets/finds/precious carried finds/gold-statuette-2.webp";
import goldStatuette3 from "../../../src/assets/finds/precious carried finds/gold-statuette-3.webp";
import pearls from "../../../src/assets/finds/precious carried finds/pearls.webp";
import rareGemstone from "../../../src/assets/finds/precious carried finds/rare-gemstone.webp";
import ringWithGemstone from "../../../src/assets/finds/precious carried finds/ring-with-gemstone.webp";
import scepter1 from "../../../src/assets/finds/precious carried finds/scepter-1.webp";
import scepter2 from "../../../src/assets/finds/precious carried finds/scepter-2.webp";
import scepter3 from "../../../src/assets/finds/precious carried finds/scepter-3.webp";
import uniqueBook from "../../../src/assets/finds/precious carried finds/unique-book.webp";
import velvetGloves from "../../../src/assets/finds/precious carried finds/velvet-gloves.webp";

const cardTitle = {
  paddingTop: 10,
  paddingLeft: 10,
  paddingBottom: 10,
  fontSize: 24,
  backgroundColor: "rgba(177, 83, 90, 1) ",
};
const pageHeader = {
  color: "black",
  backgroundColor: "white",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Aladin",
  fontSize: 20,
  backgroundColor: "rgba(118, 131, 87, 1)",
  color: "white",
  marginBottom: "15px",
};

class FLFindsPreciousCarried extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "BEGINNING NAME",
      text: "Click button for some fun.",
      testValue: "test value",
      selectedMutation: null,
    };
  }

  componentWillMount() {
    document.body.style.backgroundImage = `url(${require("../../../src/assets/fl-map-groveland-woods.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "round";
    document.body.style.backgroundAttachment = "fixed";
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = null;
  }

  render() {
    return (
      <div>
        <Container>
          <Row>
            <Col>
              <div
                style={{
                  backgroundColor: "transparent",
                  fontFamily: "Orbitron",
                }}
              >
                <main role="main" className="inner cover text-center">
                  <img
                    src={flTitle}
                    style={{ maxWidth: "400px" }}
                    alt="Forbidden Lands campaign"
                  />
                </main>
                <div className={"forbiddenLandsFont"} style={pageHeader}>
                  <h1 className="cover-heading">
                    Forbidden Lands Finds - Precious Carried [VTT Tokens]
                  </h1>
                  <p className="lead leadExtra">
                    The precious carried finds from core Forbidden Lands GM
                    guide as transparent webp files in 512x512 dimension,
                    perfect for Foundry VTT.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card style={cardStyle}>
                <Card.Body>
                  <Card.Title style={cardTitle}>
                    Precious Carried Finds
                  </Card.Title>

                  <Card.Text>
                    <div className={"tokenWrapper"}>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Artifact</div>
                        <img src={artifact} alt="Artifact" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Dragonscale Boots
                        </div>
                        <img src={dragonScaleBoots} alt="Dragonscale Boots" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Fur Cloak, gold clasp
                        </div>
                        <img
                          src={furCloakGoldClasp}
                          alt="Fur Cloak, gold clasp"
                        />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Gold Amulet 1</div>
                        <img src={goldAmulet1} alt="Gold Amulet 1" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Gold Amulet 2</div>
                        <img src={goldAmulet2} alt="Gold Amulet 2" />
                      </div>
                    </div>
                    <div className={"tokenWrapper"}>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Gold Amulet 3</div>
                        <img src={goldAmulet3} alt="Gold Amulet 3" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Gold Bowl</div>
                        <img src={goldBowl} alt="Gold Bowl" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Gold Bracelet</div>
                        <img src={goldBracelet} alt="Gold Bracelet" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Gold Chainmail
                        </div>
                        <img src={goldChainmail} alt="Gold Chainmail" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Gold Coins</div>
                        <img src={goldCoins} alt="Gold Coins" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Gold Crown</div>
                        <img src={goldCrown} alt="Gold Crown" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Gold Drinking Horn
                        </div>
                        <img src={drinkingHorn} alt="Gold Drinking Horn" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Gold Embroidery
                        </div>
                        <img src={embroidery} alt="Gold Embroidery" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Gold Helmet 1</div>
                        <img src={helmet1} alt="Gold Helmet 1" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Gold Helmet 2</div>
                        <img src={helmet2} alt="Gold Helmet 2" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Gold Helmet 3</div>
                        <img src={helmet3} alt="Gold Helmet 3" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Gold Large Shield 1
                        </div>
                        <img src={shield1} alt="Gold Large Shield 1" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Gold Large Shield 2
                        </div>
                        <img src={shield2} alt="Gold Large Shield 2" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Gold Large Shield 3
                        </div>
                        <img src={shield3} alt="Gold Large Shield 3" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Gold Flail</div>
                        <img src={flail} alt="Gold Flail" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Gold Handaxe</div>
                        <img src={handaxe} alt="Gold Handaxe" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Gold Katar</div>
                        <img src={katar} alt="Gold Katar" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Gold Mug</div>
                        <img src={mug} alt="Gold Mug" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Gold Necklace</div>
                        <img src={necklace} alt="Gold Necklace" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Gold Padded Armor
                        </div>
                        <img src={paddedArmor} alt="Gold Padded Armor" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Gold Pikemans Armor
                        </div>
                        <img src={pikemansArmor} alt="Gold Pikemans Armor" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Gold Ring</div>
                        <img src={goldRing} alt="Gold Ring" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Gold Statuette 1
                        </div>
                        <img src={goldStatuette1} alt="Gold Statuette 1" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Gold Statuette 2
                        </div>
                        <img src={goldStatuette2} alt="Gold Statuette 2" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Gold Statuette 3
                        </div>
                        <img src={goldStatuette3} alt="Gold Statuette 3" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Pearls</div>
                        <img src={pearls} alt="Pearls" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Rare Gemstone</div>
                        <img src={rareGemstone} alt="Rare Gemstone" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>
                          Ring with Gemstone
                        </div>
                        <img src={ringWithGemstone} alt="Ring with Gemstone" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Scepter 1</div>
                        <img src={scepter1} alt="Scepter 1" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Scepter 2</div>
                        <img src={scepter2} alt="Scepter 2" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Scepter 3</div>
                        <img src={scepter3} alt="Scepter 3" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Unique Book</div>
                        <img src={uniqueBook} alt="Unique Book" />
                      </div>
                      <div className={"finds"}>
                        <div style={{ textAlign: "center" }}>Velvet Gloves</div>
                        <img src={velvetGloves} alt="Velvet Gloves" />
                      </div>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default FLFindsPreciousCarried;
