import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import ohShit from "../../../../assets/myz/oh-shit.jpg";
import healChromicus from "../../../../assets/myz/rowbert-heals-chromicus.jpg";
import chromicusArrives from "../../../../assets/myz/chromicus-arrives.jpg";
import raff from "../../../../assets/myz/inhabitant-raff.jpg";
import illona from "../../../../assets/myz/inhabitant-illona-the-corpse-eater.jpg";
import lukeTony from "../../../../assets/myz/threat-helldrivers-tony-luke.jpg";
import hanneth from "../../../../assets/myz/hanneth.jpg";

const cardTitle = {
  paddingTop: 10,
  paddingLeft: 10,
  paddingBottom: 10,
  backgroundColor: "rgba(221, 117, 28, .7) ",
};
const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Scouting the Helldrivers",
    text: (
      <p>
        Player not able to make session so Hulagu and Jebe go into suspended
        animation. We do not run characters for absent players. They simply
        disappear from the stage, earn no XP, and then reappear when player is
        back. Also, since player for Rowbert and Skut was back he was given
        choice and decided that Rowbert would be with the party scouting the
        Helldrivers headquarters, and Skut would be back at the Ark.
        <br />
        <br />
        Rowbert, Chromicus, and Smoke Raven are on a ridge scouting the
        Helldrivers headquarters as Double Bird leads Fatima and her band of
        Wanderers back to the Ark. The rumor word from Calihan the Stalker is
        that the{" "}
        <b>
          <u>
            dog mutant Elson and the mutant Kublai from the Sunshine Plaza are
            prisoners here
          </u>
        </b>
        .
        <br />
        <br />
        For each hour the players SCOUT they got a result from scouting table
        for each success; and there is also a patrol roll each hour to see if
        they are spotted by wandering Helldrivers keeping a half-assed watch
        over their sector.
        <br />
        <br />
        The Helldrivers are down to small presence because their main force is
        off attacking the players Ark!! Meanwhile at headquarters our scouts see
        the Helldrivers are staging combats in their makeshift, outdoor fight
        arena. Mutants are cheering drunkenly in the stands while one or two
        motorcycles are randomly buzzing around in the compound in inebriated
        circles.
        <br />
        <br />
        The players recognize the master of ceremonies in thering announcing
        fights. It is Luke; Boss Patrick's right-hand man who was front and
        center on the original Helldriver visit to extort the Sunshine Plaza for
        water and grub (see "Helldriver Shakedown" section of{" "}
        <a
          href="./mutant-year-zero-session-06-anchortown"
          rel="noreferrer"
          target="_blank"
        >
          Session 06
        </a>
        ).
      </p>
    ),
    image: {
      imagePath: lukeTony,
      altText: `Luke has frog legs and Tony is a human toad; a match made in heaven. Tony is hyper-attached to her man Luke to the exclusion of her own well-being and Luke dotes on Tony with dedicated ferocity.`,
      className: "imageLeft",
      imageCaption: "Luke and Tony",
    },
  },
  {
    title: "Fight Club",
    text: (
      <p>
        A mutant gearhead with a wrench and ring of keys leads human prisoners
        to the ring to fight. The players notice other Helldrivers tossing cups
        and scrap at the gearhead and Luke cuffs him in the head as the
        prisoners are led into the ring. He is clearly a Helldriver but very low
        on the social hierarchy and mistreated.
      </p>
    ),
    image: {
      imagePath: raff,
      altText: `Raff is a browbeaten gearhead of the Helldriver gang. Mistreated, he scuttles and defers constantly.`,
      className: "imageRight",
      imageCaption: "Raff, gearhead",
    },
  },
  {
    title: "",
    text: (
      <p>
        Next to the ring is a huge monstrosity reminiscent of Janis from Elysium
        Fields amusement park. A multi-headed, multi-limbed mutant named Illona,
        as dangerous as she is stupid.
        <br />
        <br />
        One of the two pitiful, half-starved humans forced to fight in the ring
        is broken and falls to the floor of the arena. Luke shouts something and
        the meager crowd of 18-26 mutants shouts in approval. Then, Illona walks
        over, picks up the loser and takes a bite out of his head. Illona eats
        the fallen prisoner as the players feverishly concoct a plan.
        <br />
        <br />
        Based on seeing Raff bring prisoners from middle area of garage to the
        ring, the party surmises Smoke Raven and Rowbert can sneak into the
        garage from a different side while Chromicus flys into the fight ring to
        distract the Helldrivers. The Helldrivers have never met Chromicus, a
        more recent addition to the Sunshine Plaza, so the hope is that he can
        convince the Helldrivers he is a random thrill-seeker from the Zone
        looking to fight and earn respect or membership in their gang.
      </p>
    ),
    image: {
      imagePath: illona,
      altText: `Illona is one of the rare super-mutants gifted with extra heads and limbs. More strength than smarts; approach with caution`,
      className: "imageLeft",
      imageCaption: "Illona, corpse eater",
    },
  },
  {
    title: "Chromicus Arrives",
    text: (
      <p>
        Chromicus sneaks close to the edge of the Helldrivers compound so his
        Insect Wings will allow him to fly all the way directly into the fight
        ring from "out of nowhere."
        <br />
        <br />
        Chromicus flies into the ring, landing as Luke is about to deliver the
        punchline to a dirty joke. Luke falls silent, dumb-founded and the crowd
        is astonished, including Luke's sweetheart Tony sloshing a rotten mai
        tai as she reclines or a rusty lawn chair. Chromicus makes a COMPREHEND
        roll and it turns out he knows the joke; so, Chromicus delivers a better
        version of the punchline right as he lands, stealing all of Luke's
        thunder.
        <br />
        <br />
        Chromicus gives some trash talk explaining that he's a badass from the
        Zone and wants to fight their greatest warrior, "except for that
        nightmare over there" (pointing to Illona). He has won the crowd's favor
        and he can see Luke is shaking from rage at being upstaged.
      </p>
    ),
    image: {
      imagePath: chromicusArrives,
      altText: `Chromicus flies directly into the Helldrivers' fight right to distract the mutants.`,
      className: "imageLeft",
      imageCaption: "Chromicus Arrives",
    },
  },
  {
    title: "Finding Elson and Kublai",
    text: (
      <p>
        As Chromicus wows the Helldriver crowd, Smoke Raven and Rowbert expertly
        SNEAK into the garage. Once inside the structure, they can hide more
        easily thanks to columns, debris, and dozens of rotting or defunct cars,
        motorcycles, and even a motor home or two.
        <br />
        <br />
        As they silently scour the first level of the garage, they spot Raff
        walking toward a stairwell going down to a basement under the garage.
        They wait until Raff emerges with a pathetic, half-starved and beaten
        human prisoner in chains. Raff leads the prisoner outside toward the
        fight stage as Rowbert and Smoke Raven sneak downstairs.
        <br />
        <br />
        They find a dozen or so Helldrivers laying on debris and scrap in a
        makeshift lazarette, sickened and half- conscious. On the far side of
        the garage they find rows of cages housing a dozen prisoners and also
        Elson and Kublai.
        <br />
        <br />
        Elson tells them he has seen guards leave a ring of keys in a desk near
        the cages. As Smoke Raven and Rowbert unlock the cages, they give the
        prisoners a pep talk to inspire them to feel confident in their rescue,
        now is the time to fight for their freedom and so forth. The 12
        prisoners besides Kublai and Elson are all mutants except one human.
        <br />
        <br />
        Once freed, half of the 12 prisoners immediately hobble away or run,
        heedless of their rescuers and the plan to stick together. Of the
        remaining six, five are ready to follow the party but the one human is
        hopeless and staying in his cage, clinging to the pitiful hours of life
        assured from living in the cage rather than face the wrath of the
        Helldrivers from running.
        <br />
        <br />
        Smoke Raven gives the human some rot-free water and gets an extra
        MANIPULATE chance. He successfully convinces the human to get to his
        feet and try for his freedom. Smoke Raven and Rowbert have the prisoners
        wait by the top of the stairs as they search the vehicles of the first
        floor for a working conveyance. They find a working motorcycle! Rowbert
        jumps on the two-wheeled monster as Smoke Raven lights up his
        flamethrower, getting ready to make an entrance.
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "",
    },
  },
  {
    title: "Luke versus Chromicus",
    text: (
      <p>
        Meanwhile, Raff brings the prisoner to the fight ring. The crowd is
        excited and cheering wildly. Chromicus sees the beaten, half-dead human
        prisoner and immediately INTIMIDATES Luke, talking about "how dare you
        present such a pitiful opponent" and so on. Luke takes two points of
        doubt damage, but Chromicus has a fail on the push. As a consequence,
        Luke tries to recapture his lost pride by launching at Chromicus with a
        vicous bicycle chain. The mutants are shouting as the Lieutenant of the
        Helldrivers takes on the winged, scar-eyed badass stranger.
        <br />
        <br />
        Luke gets the upper hand and after a couple of rounds breaks Chromicus
        who falls to the ground clutching his guts. Chromicus rolls BLEEDING GUT
        critical injury with only one hour to live before he bleeds to death
        unless he is healed.
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
    },
  },
  {
    title: "OH SHIIIITT!!!",
    text: (
      <p>
        Just as Chromicus hits the floor of the fight ring in a puddle of blood,
        two things happen. Smoke Raven walks out from the shadows of the parking
        garage; ringed by freed prisoners, he lets loose a NINE MUTATION POINT
        BEASTIAL ROAR as he fires the flamethrower into the air. Let's remember
        that Smoke Raven has fangs from a mutation misfire. Fangs . . .
        flamethrower . . bestial roar . . . grimy, angry prisoners flanking him.
        This is a poster moment in our minds. And as a bonus, the roar not only
        topples 3 of the six random Helldrivers near the fight ring, it breaks
        Luke who had already taken two points of doubt damage.
        <br />
        <br />
        Tony runs into the ring screaming and sobbing, "baby, oh god baby" and
        kneels next to Luke, completely heedless of the prisoners and the
        flames, ignoring danger to her life to cradle Luke.
        <br />
        <br />
        Simultaneously, Rowbert comes frapping out of the garage on a sputtering
        hog, blowing smoke as he screetches up to the fight ring, skids to a
        dusty halt and jumps off the motorcycle to jump in the ring and heal
        Chromicus so they can all try to escape together.
        <br />
        <br />
        Rowbert makes a HEAL roll . . . sees little choice but to push it . . .
        he fails to heal Chromicus and completely breaks himself. See the inset
        picture for screen capture of the HEAL roll and push. Now you know why
        this section has the title that it does.
        <br />
        <br />
        All remaining parties make MOVE rolls to escape the Helldrivers before
        they are organized enough to attack back or get on their motorcycles or
        into their cars. Dog mutant Elson fails spectacularly and breaks his
        agility. Of the six prisoners, only the human makes his MOVE roll to
        escape; the same human that almost stayed in the cage
      </p>
    ),
    image: {
      imagePath: ohShit,
      altText: `Rowbert tries to get Chromicus to his feet in the fight ring. He gets a total of FOUR fails with a pushed roll and breaks himself`,
      className: "imageLeft",
      imageCaption: "Healing Chromicus?",
    },
  },
];
const section2Data = [
  {
    title: "Ark Battle",
    text: (
      <p>
        03-22-0001: Patrick rides up to the edge of the Sunshine Plaze in the
        Juggernaut. A dozen other vehicles and a handful of motorcycles surround
        the plaza. Patrick in uncharacteristically livid, shouting about The
        People and their "filthy disease" and needing to cleanse them from the
        Zone entirely. Our first Ark battle erupts.
        <br />
        <br />
        We decide to include a homebrew rule that allows players to have a
        character participate in the battle in a significant way wherein that
        character's involvement adds a Battle Dice to the Battle Level, but also
        puts the character in jeopardy. Any failure on a roll during the Ark
        Battle means of the PCs suffers a 6D6 attack roll that damages strength
        (we may modify it to ALL characters involved suffer that attack roll
        since Battle Dice are so powerful). At this time the rule is also that
        the maximum number of characters able to add to Battle Level is three.
        <br />
        <br />
        Skut and Double Bird both sign up for the front line. The Ark has a
        WARFARE DEV level of 9, so 1/10 rounded up gives them one. That one plus
        the two characters gives the Sunshine Plaza a Battle Level of 3 which
        matches the Battle Level of the Helldriver forces.
        <br />
        <br />
        The People battle valiantly, matching the Helldrivers blow for blow. As
        the Helldrivers buzz around the Plaza and even breach the wall of cars
        into the yard while Patrick spits fore with his mutation, the People are
        busy coordinating their attack with the help of Boss Krao's Order of the
        New Dawn running information and supplies to each of the bosses who are
        cooperating 100% in the face of this common enemy. At the end of the
        day, both the Helldrivers and The People push all their dice until no
        more rolls are possible. The end result is a -1 for The People.
        <br />
        <br />
        Consulting the Battle Results table we find that the battle ends with 6
        of The People of the Sunshine Plaze dead and the Temple of Endurance
        project burned to the ground. Horrible losses, but surely not as bad as
        it could have been.
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
    },
  },
  {
    title: "",
    text: (
      <p>
        03-22-0001: Back at the Helldrivers headquarters, Luke has ordered Raff
        to try and keep Chromicus alive. Luke wants Chromicus dead; but he
        doesn't want him to bleed out within an hour. He wants him alive,
        screaming from torture for many days as payback for being broken in
        front of his people and his partner Tony.
        <br />
        <br />
        Raff is terrified. He has no healing skill and worries Luke might kill
        him if he doesn't keep Chromicus alive. Rowbert, delirious from empathy
        breakage, tells Raff he can heal Chromicus if given a chance. Raff gives
        Rowbert enough sympathy that Rowbert can crawl over to Chromicus and
        make a healing roll. After his epic failure to HEAL in the fight ring,
        Rowbert redeems himself with another roll that requires a push, but this
        time with THREE successes and no failures as you can see in the
        screenshot.
        <br />
        <br />
        Raff is grateful but catious. He seems like the sort the party could
        convince to help them, but he is also clearly a flinching coward that
        survives by obsequious deference and cannot be fully trusted.
      </p>
    ),
    image: {
      imagePath: healChromicus,
      altText: `Hanneth the Spoiled. Chronicler. Boss Krao's favorite. Victim of Helldriver attack on the Ark.`,
      className: "imageLeft",
    },
  },
  {
    title: "Wrapup",
    text: (
      <p>
        XP for the session
        <br />
        <br />
        <ul>
          <li>Rowbert: 4 </li>
          <li>Skut: 3</li>
          <li>Chromicus: 4</li>
          <li>Double-Bird: 3</li>
          <li>Smoke Raven: 4</li>
          <li>Hulagu: 0</li>
          <li>Jebe: 0</li>
        </ul>
        Next session starts 3pm on 03-22-0001. Status of characters is:
        <br />
        <br />
        <ul>
          <ol>
            Rowbert, Chromicus, and dog mutant Elson are prisoners at
            Helldrivers HQ.
          </ol>
          <ol>
            Double Bird and Skut are in the Ark helping The People recover from
            Ark battle.
          </ol>
          <ol>
            Smoke Raven and Kublai are on a motorcycle racing through the Zone
            on their way back to the Ark.
          </ol>
        </ul>
        <br />
        Check out Session Body Count at:{" "}
        <a href="./mutant-year-zero-anchortown-population-notable-events">
          SESSION BODY COUNT AND POPULATION
        </a>
        <br />
        <br />
        Among the casualties of the Ark Battle is{" "}
        <strong>Hanneth the Spoiled - Chronicler</strong>
        <br />
        Please feel free to stop by the{" "}
        <a href="./mutant-year-zero-anchortown-graveyard">
          Anchortown Graveyard to pay your respects
        </a>{" "}
        and find out what happened to her.
      </p>
    ),
    image: {
      imagePath: hanneth,
      altText: `Hanneth the Spoiled`,
      className: "imageRight",
    },
  },
];

const MYZAnchortownSession10 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 11: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  An epic infiltration of the Helldrivers partially empty HQ
                  gone awry just as the main body of the Helldrivers attack the
                  Ark in our first Ark Battle.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Title style={cardTitle}>
                  Giving the Helldrivers Hell, 03-22-0001 to 03-22-0001
                </Card.Title>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
                <Card.Title style={cardTitle}>
                  Bringing Fire to the Sunshine Plaza, 03-22-0001
                </Card.Title>
                <Card.Text>
                  <ContentContainer data={section2Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession10;
