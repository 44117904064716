import React from "react";
import "./modal.styles.scss";
import Modal from "react-bootstrap/Modal";
import { Interweave } from "interweave"; // allows html string output without setting dangerous helpers
import { useEffect, useState } from "react";

// function ModalComponent(WrappedComponent) {
//   return function WithCounter(props) {
//     const [count, setCount] = useState(0);

//     return (
//       <ModalComponentInner count={count} setCount={setCount} {...props} />
//     );
//   };
// }

const ModalComponent = ({
  show,
  onHide,
  image,
  cssClass,
  modalBody,
  modalData,
}) => {
  const [modalDataRef, setModalData] = useState("");
  const [blur, setBlur] = useState("");
  console.log(modalDataRef);

  useEffect(() => {
    console.log("modal data effect");
    console.log(modalData);
    setModalData(modalData);
    setBlur(modalData.isLegend);
  }, [modalData]);
  console.log(`blur: ${blur}`);
  const handleTextClick = (e) => {
    if (modalData.isLegend) {
      setBlur(!blur);
      e.stopPropagation();
    }
  };

  if (!cssClass) {
    cssClass = "imageLeft";
  }
  if (show) {
    let spoiler = "";
    if (modalDataRef.isLegend) {
      spoiler = <h3>Spoiler: click text to reveal spoiler.</h3>;
    }
    let image = "";
    let pageTitleElement = "";
    if (modalDataRef.isLegend) {
      pageTitleElement = <h4>{`Legend: ${modalDataRef}`}</h4>;
    }
    if (!modalDataRef.isLegend) {
      image = (
        <img
          className="img-fluid"
          alt={modalDataRef.image}
          src={modalDataRef.image}
        ></img>
      );
    }
    return (
      <Modal
        key={image}
        scrollable
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="false"
        onHide={onHide}
        onClick={onHide}
      >
        <Modal.Body>
          <div className="modal-title" onClick={handleTextClick}>
            {spoiler}
            {pageTitleElement}
            <Interweave
              className={blur ? "text-blur" : ""}
              content={modalDataRef.body}
            ></Interweave>
          </div>
          <br />
          {image}
          {/* <img
            className="img-fluid"
            alt={modalDataRef.image}
            src={modalDataRef.image}
          ></img> */}
        </Modal.Body>
      </Modal>
    );
  } else {
    return "";
  }
};

export default ModalComponent;
