import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import denChromicus from "../../../../assets/myz/den-chromicus.jpg";
import denSmokeRaven from "../../../../assets/myz/den-smoke-raven.jpg";
import julian from "../../../../assets/myz/ark-mutant-julian-the-deep.jpg";
import compass from "../../../../assets/myz/compass.jpg";
import fatima from "../../../../assets/myz/wanderer-fatima-face-portrait.jpg";

const cardTitle = {
  paddingTop: 10,
  paddingLeft: 10,
  paddingBottom: 10,
  backgroundColor: "rgba(221, 117, 28, .7) ",
};
const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Bookkeeping",
    text: (
      <p>
        Player not able to make session so Rowbert and Skut hang out in their
        dens this session taking well-deserved, extended naps.
        <br />
        <br />
        Body count back down to D6 per session thanks to medicine from Elysium
        Fields amusement park (See{" "}
        <a
          href="./mutant-year-zero-session-08-anchortown"
          rel="noreferrer"
          target="_blank"
        >
          Session 08
        </a>
        ).
        <br />
        <br />
        Jebe's sprained wrist finally heals morning of 03-20-0001.
        <br />
        <br />
        Project Rolls:
        <ul>
          <ol>Defenses [Chromicus/Smoke Raven]: +2</ol>
          <ol>
            Cropland [Jebe]. +0. Jebe stands alone in a field of weeds; "am I
            early?"
          </ol>
          <ol>
            Stables [Hulagu/Double-Bird]: +2 (COMPLETE despite -1 from POLITICAL
            SNUB by Berke from fails on push last session).
          </ol>
        </ul>
      </p>
    ),
    image: {
      imagePath: denChromicus,
      altText: `Den of Chromicus and friends`,
      className: "imageLeft",
      imageCaption: "Den of Chromicus",
    },
  },
  {
    title: "",
    text: (
      <p>
        Since the party finished the Stables, we now have{" "}
        <a
          href="./mutant-year-zero-beast-training"
          rel="noreferrer"
          target="_blank"
        >
          BEAST TRAINING
        </a>{" "}
        posted.
        <br />
        <br />
        Trying to scare up some grub, some PCs took the basic 70% to scrounge a
        grub infested with Rot. Others chose to try and use skills for a chance
        at getting rot-free grub.
        <ul>
          <li>
            Hulagu: used racketeering and pushed to get TWO FAILS [consequences
            pending].
          </li>
          <li>
            Chromicus: used INTIMIDATE and pushed to get THREE FAILS
            [consequences pending].
          </li>
          <li>
            Smoke Raven: tries to sneak into Boss Skag's den to steal food and
            whatever can be found. He is helped by Hulagu and Grit on lookout.
            Place is too well guarded and operation is aborted.
          </li>
          <li>
            Jebe tries to pickpocket someone at a dogfight staged by Torque but
            no luck.
          </li>
        </ul>
        Grub with rot is put into Rowbert's den to be cleaned via Zone Cook when
        player returns next session.
      </p>
    ),
    image: {
      imagePath: denSmokeRaven,
      altText: `Den of Smoke Raven and Grit.`,
      className: "imageRight",
      imageCaption: "Den of Smoke Raven",
    },
  },
  {
    title: "Scene: Chromicus",
    text: (
      <p>
        Check out our adaptation of{" "}
        <a
          href="/mutant-year-zero-scenes"
          rel="noreferrer"
          target="_blank"
        >
          scenes mechanic from VAESEN.
        </a>
        <br />
        <br />
        Most of the Exkurtzers spend their first day at the Ark working together
        to set up shelters in the parking lot and to begin to shore up defenses.
        While setting up their camps, they clear old wreckage that was blocking
        the entrance into a semi-trailer from a long ago crashed 16-wheeler.
        Chromicus, Janis, and Rel take over the semi-trailer as their home while
        other Exkhurtzers set up housing constructed of vans, cars, tents, and
        other salvaged materials.
        <br />
        <br />
        Visitors come by throughout the day. Some others occasionally watch
        while keeping their distance. Bianca visits to see some of her friends
        from the Elysian Fields amusement park. When the friends learn about
        Bianca’s situation with Boss Skag’s gang, they bring her before
        Chromicus.
        <br />
        <br />
        Chromicus learns the details of how The People found Bianca with her
        lover Zane and their friend Bianca in the zone. That they were the ones
        sick with the parasite fungus that drove the journey to Elysium Fields
        to get the hoarded medcine Kurtz kept in a bunker.
        <br />
        <br />
        Chromicus learns that a stalker known as Julian the Deep is Bianca's
        main keeper. Chromicus visits Julian and tries to INTIMIDATE him into
        letting Bianca go so she can come live with Chromicus or the IL Gang in
        general. Janis helps by standing nearby and looking like the monstrous
        four-legged, two-headed behemoth he/she/it is.
        <br />
        <br />
        Chromicus fails initially as Julian backtalks, "you talking about MY
        woman? She belongs to the whole gang really anyhow fool." Chromicus
        pushes and gets two successes and one failure. As Chromicus take a point
        of damage to doubt, questioning why a punk like Julian had the steel to
        talk back in the first place; and then on top of the two points of doubt
        his successes cause, Chromicus starts buzzing his insect wings to cause
        another point of doubt, breaking Julian. We roll quickly on the
        mini-doubt break chart and find that Julian falls to the ground sobbing.
        (Note: the possibilities on the chart currently are: pisses self,
        sobbing, faints, and pathetic pleading). In the end, Bianca is home
        living among her friend in the Exkurtzers. Some of them that she didn't
        like and drove her, Zane, and Ariana to leave the cult to begin with
        perished when that sector was nuked.
      </p>
    ),
    image: {
      imagePath: julian,
      altText: `Julian the Deep is a stalker for Boss Skag's gang. He is still stinging from being broken by Chromicus.
         Chromicus intimidated Julian into letting Biana go free and followed with buzzing wings to break him and 
         make him fall to the ground sobbing. We can be sure Julian plots revenge. An angry Stalker is a dangerous stalker.`,
      className: "imageRight",
    },
  },
  {
    title: "Sector F05 - Car Park 2 [03-21-0001]",
    text: (
      <p>
        Yet another car park. The random sector generation has cooperated nicely
        to give us a clear understanding of how The Sunshine Plaza came to have
        all those cars for it's perimeter wall and for housing. This sector
        promises D6 scrap per hour spent searching up to 4D6 maximum, but the
        party decides they can spare the time some other time. They press into
        the next sector.
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
    },
  },
  {
    title: "Sector G04 - Scrubland 1 [03-21-0001]",
    text: (
      <p>
        The foilage and debris in this sector are coated with a greyish sheen
        that looks like dirty snow but is just another variation on the toxic
        particulates polluting the land. There are tunnels though mountains of
        debris and garbage here. Smoke Raven tells the party to keep an eye out
        for bullets and sure enough the tunnels has seen action in their history
        and the party picks up bullets. In addition they also find an artifact
        which they succesfully COMPREHEND . . . it as a COMPASS; complete win
        for a Zone Stalker in every way.
        <br />
        <br />
        <br />
      </p>
    ),
    image: {
      imagePath: compass,
      altText: `COMPASS gives +2 Gear bonus to Find the Path. A Stalkers dream artifact.`,
      className: "imageLeft",
    },
  },
  {
    title: "Sector H03 - Scrubland 2 [03-21-0001]",
    text: (
      <p>
        More scrublands. Seeming on little interest until silhouettes are seen
        several hundred yards off. The mutants hide and Jebe's new hound Scooby
        is sent to scout. Scooby seems friendly and the figures do not seem
        alarmed. As she runs back at her master's whistle, the figures follow.
        <br />
        <br />
        As they near, it is clear they are zone ghouls. But they are not the
        mindless, rotted husks of legend; they are simply humans like Lutrell
        and his folks at the{" "}
        <a
          href="./mutant-year-zero-session-03-anchortown"
          rel="noreferrer"
          target="_blank"
        >
          Pure Plant.
        </a>
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
    },
  },
];
const section2Data = [
  {
    title: "The Wanderers",
    text: (
      <p>
        After a semi-tense introduction, including arrows levelled at Chromicus'
        chest after he flew in close for a parley, the two groups communicate.
        As the weapons are lowered, more of the group emerge from hiding
        including very small people. Children as it happens!
        <br />
        <br />
        After initially speaking with Montana, it's clear that Fatima is the
        leader of this group called The Wanderers. The Wanderers speak with a
        rough dialect; a sort of neo-Russian slang. With calm and persistence as
        camp is being setup, the two groups can talk. Once Hulagu shows good
        faith by handing over grub, they learn this group has walked from the
        west "for years" across mountains. They are half-starved and looking for
        a settlement where they can combine forces and make a home.
        <br />
        <br />
        Fatima's little boy Elroy approaches Double-Bird and once he tests the
        slave's bluster he blurts out they are looking for Eden. As the groups
        talk, Fatima offers to trade information about the location of a group
        of mutants that live in a gigantic building and have machines of fire
        and hairless, burnt bodies in exchange for an escort to The Sunshine
        Plaza.
        <br />
        <br />
        Sector P25 is identified as location.
        <br />
        <br />
        To seal the deal, Fatima gifts Hulagu with an artifact SEMI-AUTOMATIC
        PISTOL.
        <br />
        <br />
        The next morning Double-Bird leaves with the Wanderers and the rest of
        the group continues.
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
    },
  },
  {
    title: "Sector I02: Helldrivers Cliffhanger",
    text: (
      <p>
        03-22-0001: as the party continues they hear a rumbling sound in the
        distance. Soon, it is clear they are hearing machinery; motorcycles and
        cars. Scooby is sent ahead and she runs back excitedly. She leads the
        party to the edge of a ridgeline where they can hide and look down on
        the headquarters of the Helldrivers; an eight-story parking garage. As
        the party settles in to observe in hiding for over an hour, I let them
        know that while there are some dozens of figures, it seems less
        populated than expected and that's because next session we have to
        resolve a Helldriver attack on the Ark!
      </p>
    ),
    image: {
      imagePath: fatima,
      altText: `Fatima is a human; leader of The Wanderers, a small group of 17 humans.`,
      className: "imageLeft",
    },
  },
  {
    title: "Wrapup",
    text: (
      <p>
        We ended session talking out XP which turns out to be better than
        automated assignment. having taken care of grub/water for healing and
        maintenance, rot points, and assigning XP points. Every one got baseline
        of three for attendence, zone exploration, and project work.
        <br />
        <br />
        <ul>
          <li>Rowbert / Skut: 0 (sat out session)</li>
          <li>Chromicus: 4 (big dream to protect when he saved Bianca)</li>
          <li>Double-Bird: 4 (?)</li>
          <li>Smoke Raven: 4</li>
          <li>Hulagu: 4</li>
          <li>Jebe: 3</li>
        </ul>
        Next session starts morning of 03-22-0001 with all PCs on the ridge
        observing the Helldrivers HQ except Double-Bird who is leading The
        Wanderers back to the Sunshine Plaza. There is also an attack on the Ark
        by the Helldrivers.
        <br /> <br />
        We will add mutation points, allow for XP spend, and then we will jump
        right in.
        <br />
        Check out Session Body Count at:{" "}
        <a href="./mutant-year-zero-anchortown-population-notable-events">
          SESSION BODY COUNT AND POPULATION
        </a>
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a href="./mutant-year-zero-anchortown-graveyard">
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
    },
  },
];

const MYZAnchortownSession10 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 10: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  Our mutants heal at the Ark, complete stables, deepen
                  animosity with Boss Skag, travel toward the Helldrivers
                  community and meet wandering humans travelling with children.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Title style={cardTitle}>
                  Hanging Out at the Ark, 03-20-0001 ho 03-21-0001
                </Card.Title>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
                <Card.Title style={cardTitle}>
                  The Wanderers and the Helldrivers, 03-22-0001
                </Card.Title>
                <Card.Text>
                  <ContentContainer data={section2Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession10;
