import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import mbLogo from "../../../../src/assets/mork-borg/mork-borg-logo.webp";
import sectionDivider from "../../../../src/assets/mork-borg/mork-borg-section-divider.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import { useEffect } from "react";

import innocence from "../../../../src/assets/mork-borg/session01/donkey.webp";
import klaptrap from "../../../../src/assets/mork-borg/slapping-bastard.png";
import platopuss from "../../../../src/assets/mork-borg/forlorn-philosopher.png";
import poltroon from "../../../../src/assets/mork-borg/jester.png";
import vesper from "../../../../src/assets/mork-borg/session06/vesper.webp";

const cardTitle = {
  paddingTop: 10,
  paddingLeft: 10,
  paddingBottom: 10,
  backgroundColor: "rgba(156, 5, 5, 1) ",
};
const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Vesper the Pale One [PC]: Session 6",
    text: (
      <p style={{ minHeight: 100 }}>
        Scorch 11, 0001 (session 6):
        <br />
        <br />
        Vesper was a Pale One; a strange, sometimes unreliable weirdo that could
        not speak well due to a bit off tongue and a background of unknown
        origins. All we know is that he crawled out of a crack in the ground not
        far from Grift. And to the ground he returns having gone mad with terror
        at the hands of a ROT NURSE in the Chapel Olandun. He will likely be
        forgotten in annals of an uncaring, diseased world; but with luck his
        body may rot and nourish a twisted tree that grows in mangled tangle
        with the same impulsivity and sadness Vesper brought to the Dying Lands.
        <br />
        <br />
        <b>Cause of Death</b>: killed by Rot Nurse in Chapel Olandun whilst
        search for the Staff of Awful Light.
        <br />
        <br />
      </p>
    ),
    image: {
      imagePath: vesper,
      altText: `Vesper was a Pale One. A strange one. A cloven-tongued alien who beat himself when he was sad.`,
      className: "imageLeft",
      imageCaption: `Vesper`,
    },
  },
  {
    title: "Poltroon the Jester [NPC]: Session 4",
    text: (
      <p style={{ minHeight: 100 }}>
        Scorch 09, 0001 (session 4):
        <br />
        <br />
        Poltroon was dancing fool. A beloved minion of SPUG and a boon to the
        party spirit. Unfortunately, Poltroon's mouth was his downfall. After
        taunting King Fathmu of Schleswig, Poltroon was taken to the palace
        dungeon and questioned with spiked clubs until he spilled his guts;
        literally.
        <br />
        <br />
        <b>Cause of Death</b>: killed by Schleswig guards after being arrested
        at banquet for flapping his mouth.
        <br />
        <br />
        <br />
        <br />
      </p>
    ),
    image: {
      imagePath: poltroon,
      altText: `Poltroon was a dancing fool.`,
      className: "imageLeft",
      imageCaption: `Poltroon`,
    },
  },
  {
    title: "Platopuss the Forlorn Philosopher [PC]: Session 2",
    text: (
      <p style={{ minHeight: 100 }}>
        Scorch 07, 0001 (session 2):
        <br />
        <br />
        Platopuss was a sweaty, egomaniacal, cannibal and a good friend. He was
        never one to let you leave your severed arm behind. He would be sure to
        grab it and use it as a weapon until he got hungry. A pioneer of
        recycling in the Dying Lands. As they sayin Kergus: the Platopuss has
        landed.
        <br />
        <br />
        <b>Cause of Death</b>: killed by goblins in the gaudy streets of
        Schleswig.
        <br />
        <br />
        <br />
        <br />
      </p>
    ),
    image: {
      imagePath: platopuss,
      altText: `Platopuss was a sweaty, egomaniacal, cannibal and a good friend.`,
      className: "imageLeft",
      imageCaption: `Platopuss`,
    },
  },
  {
    title: "Klaptrap the Slapping Bastard [PC]: Session 1",
    text: (
      <p style={{ minHeight: 100 }}>
        Scorch 01, 0001 (session 1):
        <br />
        <br />
        Klaptrap was a slapping bastard. He was the Dodge Cannonball champion of
        Kergus for five years running. Then, he had to flee with his friends
        Platopuss, Hildebrand, and Klaatubarada due to a misunderstanding about
        the death of a minor noble and some severed, and partially eaten, body
        parts.
        <br />
        <br />
        <b>Cause of Death</b>: killed by the Emperor of Teeth.
        <br />
        <br />
        <br />
        <br />
      </p>
    ),
    image: {
      imagePath: klaptrap,
      altText: `Klaptrap was a slapping bastard, killed by the Emperor of Teeth`,
      className: "imageLeft",
      imageCaption: `Klaptrap`,
    },
  },
  {
    title: "Innocence the Donkey [NPC]: Session 1",
    text: (
      <p style={{ minHeight: 100 }}>
        Scorch 01, 0001 (session 1):
        <br />
        <br />
        Innocence was a donkey. He carried stuff until he could carry no more.
        He was, in fact, innocent.
        <br />
        <br />
        <b>Cause of Death</b>:: Black Salt Wind hurricane.
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </p>
    ),
    image: {
      imagePath: innocence,
      altText: `Innocence the Donkey.`,
      className: "imageLeft",
      imageCaption: `Innocence the Donkey`,
    },
  },
];

const MBMalumMortisGraveyard = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/mork-borg/bkgnd-01.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={mbLogo}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">Malum Mortis: Graveyard</h1>
                <p className="lead leadExtra">
                  Malum Mortis is a Mork Borg campaign.
                  <br />
                  This page celebrates those lost along the way.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Title style={cardTitle}>GRAVEYARD</Card.Title>

                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MBMalumMortisGraveyard;
