import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import logo from "../../../../src/assets/forbidden-lands/forbidden-lands-logo.webp";
import sectionDivider from "../../../../src/assets/forbidden-lands/campaign/bitter-reach-page-banner-contests.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";
import "./forbidden-lands-styles.scss";
import earPull from "../../../../src/assets/forbidden-lands/contests/ear-pull.webp";
import knuckleHop from "../../../../src/assets/forbidden-lands/contests/knuckle-hop.webp";
import snowsnakes from "../../../../src/assets/forbidden-lands/contests/snowsnakes.webp";
import storytelling from "../../../../src/assets/forbidden-lands/contests/storytelling.webp";
import highKick from "../../../../src/assets/forbidden-lands/contests/two-foot-high-kick.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "1",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Carter One",
  backgroundColor: "rgba(0, 0, 0, 1) ",
  color: "white",
  marginBottom: "15px",
};

const FBLContests = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  const section1Data = [
    {
      title: "Contests in the Bitter Reach",
      anchor: "prisoners",
      sectionTitleCssName: "sectionTitleFBL",
      text: (
        <div>
          <div className="fblBodyText">
            <p>
              Contests inspired by the{" "}
              <a href="https://www.weio.org/" rel="noreferrer" target="_blank">
                WEIO (World Eskimo-Indian Olympics)
              </a>{" "}
              in Fairbanks, Alaska.
            </p>
            <hr
              style={{
                backgroundColor: "white",
                color: "white",
                height: "5px",
                opacity: "1.0",
              }}
            />
            <ImageComponent
              image={earPull}
              caption="Ear Pull"
              cssClass="imageLeft"
              handleClick={handleClick}
              modalBody="The ear pull tests competitors' ability to endure pain."
            ></ImageComponent>
            <ModalComponent
              show={showModal}
              onHide={handleCloseModal}
              modalData={modalData}
            ></ModalComponent>
            <h2>Ear Pull</h2>
            Tests competitors' ability to endure pain.
            <br />
            <br />
            Competitors sit facing each other, legs straddled and interlocked. A
            two-foot-long loop of string is looped behind their ears.
            Competitors pull upon opposing ear using their own ear until the
            cord comes free or opponent quits from pain.
            <br />
            <br />
            ENDURANCE + D6 (on a 1 string slips and most successess wins).
            Alternate ENDURANCE rolls until string slips or someone breaks.
            <hr
              style={{
                backgroundColor: "white",
                color: "white",
                height: "5px",
                opacity: "1.0",
              }}
            />
            <ImageComponent
              image={knuckleHop}
              caption="Knuckle Hop"
              cssClass="imageLeft"
              handleClick={handleClick}
              modalBody="Jump forward in pushup position on fists and toes, remaining off ground at all times."
            ></ImageComponent>
            <ModalComponent
              show={showModal}
              onHide={handleCloseModal}
              modalData={modalData}
            ></ModalComponent>
            <h2>Knuckle Hop</h2>
            <br />
            <br />
            Jump forward in pushup position on fists and toes, remaining off
            ground at all times.
            <br />
            <br />
            Record: 200 feet 8 inches.
            <br />
            <br />
            SURVIVAL + ENDURANCE + MOVE; each success is D6 feet. Each roll can
            be pushed. Go until broken.
            <br />
            <br />
            After 100 feet, every roll MUST be pushed. And after 150 feet, the
            distance gained is 1d6 inches per success instead of feet.
            <hr
              style={{
                backgroundColor: "white",
                color: "white",
                height: "5px",
                opacity: "1.0",
              }}
            />
            <ImageComponent
              image={snowsnakes}
              caption="Snowsnakes"
              cssClass="imageLeft"
              handleClick={handleClick}
              modalBody="Played by four teams who throw wooden 'snow snakes' farthest along a trough of snow."
            ></ImageComponent>
            <ModalComponent
              show={showModal}
              onHide={handleCloseModal}
              modalData={modalData}
            ></ModalComponent>
            <h2>Snow Snakes</h2>
            Played by four teams who throw wooden "snow snakes" farthest along a
            trough of snow.
            <br />
            <br />
            Game divided into rounds; in a round each team gets four throws. At
            end of round, two points to team with farthest throw in round, and
            one point for second farthest throw. Play continues until one of the
            teams wins, by achieving a certain predetermined number of points
            (usually 7 or 11).
            <br />
            <br />
            There are two roles on a snow snake team: the Player, and the
            Goaler. Goaler selects and crafts the snow snake and Player throws
            it.
            <br />
            <br />
            CRAFTING (goaler, each success gives Player +1 mod) + MOVE (player,
            each success is d6 feet).
            <hr
              style={{
                backgroundColor: "white",
                color: "white",
                height: "5px",
                opacity: "1.0",
              }}
            />
            <ImageComponent
              image={storytelling}
              caption="Storytelling"
              cssClass="imageLeft"
              handleClick={handleClick}
              modalBody="In the Bitter Reach, storytelling is not just a performance, but an artistic rendition of lore and history."
            ></ImageComponent>
            <ModalComponent
              show={showModal}
              onHide={handleCloseModal}
              modalData={modalData}
            ></ModalComponent>
            <h2>Storytelling</h2>
            <br />
            <br />
            Competitors sit facing each other, legs straddled and interlocked. A
            two-foot-long loop of string is looped behind their ears.
            Competitors pull upon opposing ear using their own ear until the
            cord comes free or opponent quits from pain.
            <br />
            <br />
            ENDURANCE + D6 (on a 1 string slips and most successess wins).
            <br />
            Alternate ENDURANCE rolls until string slips or someone breaks.
            <br />
            <br />
            <hr
              style={{
                backgroundColor: "white",
                color: "white",
                height: "5px",
                opacity: "1.0",
              }}
            />
            <ImageComponent
              image={highKick}
              caption="Two Foot High Kick"
              cssClass="imageLeft"
              handleClick={handleClick}
              modalBody="Jump with both foot close together to strike a suspended target with both feet, then land on both feet without losing balance."
            ></ImageComponent>
            <ModalComponent
              show={showModal}
              onHide={handleCloseModal}
              modalData={modalData}
            ></ModalComponent>
            <h2>Two Foot High Kick</h2>
            <br />
            <br />
            Jump with both foot close together to strike a suspended target with
            both feet, then land on both feet without losing balance.
            <br />
            <br />
            MIGHT + AGILITY rolls; most successes wins. Pushing allowed. If
            tied, both rolles again with pushing allowed. If broken you lose if
            opponent not broken and has more wins after their turn.
            <br />
            <br />
            <a href="#pageTop">Back to Top</a>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/forbidden-lands/fbl-page-background-blizzard.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.backgroundWidth = "100vw";
    document.body.backgroundHeight = "100vh";
    document.body.style.backgroundSize = "cover";
  }, []);

  return (
    <Container>
      {/* <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin" 
                    style={{position: 'float', float: 'right',width: '120px', height: '240px'}} marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=smokeraven-20&language=en_US&marketplace=amazon&region=US&placement=9189143701&asins=9189143701&linkId=d1384e0e6b9ef083fee35d7272a71f88&show_border=true&link_opens_in_new_window=true"></iframe> */}
      <Row>
        <Col>
          <div
            style={{
              backgroundColor: "transparent",
              fontFamily: "IM Fell Great Primer",
            }}
          >
            <div style={pageHeader}>
              <main role="main" className="inner cover text-center">
                <a href="#freedom-day" name="pageTop">
                  <img
                    src={logo}
                    style={{ maxWidth: "200px" }}
                    alt="Forbidden Lands Campaign"
                  />
                </a>
              </main>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title className="cardTitle-forbidden-lands  ">
                An overview of some contests found in the Forbidden Lands.
                {/* <small>
                  <br />☠ Session 01:{" "}
                  <a href="./forbidden-lands-session-01">
                    Freedom Day in Gomsten
                  </a>{" "}
                  ⇒
                </small> */}
              </Card.Title>
              <div>
                <ContentContainer data={section1Data} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default FBLContests;
