import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";
import battleRoyale from "../../../../assets/myz/battle-royale-overview.jpg";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "The Journey Home",
    text: (
      <p>
        The action picks up in the sector with the crashed tram from Command
        Center Charlie. Smoke Raven, Bayazid, Chromicus, Ghost Bird, Vanja, and
        the mechies Fistula and Kort have escaped the Helldrivers at the
        destroyed Rust Castle.
        <br />
        <br />
        The group plans a trap wherein Smoke Raven and Chromicus will go back
        and strafe the Helldrivers to lure them to the tram and hopefully blow
        them up in the crashed train. However, upon return to the beach at the
        Rust Castle the three cars that had the 9 Helldrivers are gone,
        presumably back to their parking garage to cry to Boss Patrick about
        their losses.
        <br />
        <br />
        The group travels back to the Sunshine Plaza. Since it's through
        previously visited sectors, we can use script written to simulate the
        entire journey based on inputs such as number of sectors and rot level,
        stalkers abilities, etc. They suffer some rot and loss of two water but
        arrive in good shape.
        <br />
        <br />
        There is a tense moment at nightfall as they arrive when they spot the
        BattleWalker from the Rust Castle outside the Plaza. However, Cad pops
        out and there is a reunion. The next morning Nikkil tells how the
        Helldrivers planted bombs and sank the Rust Castle and destroyed them
        all except for a few mechies, Cad, Tir, and the two mechies rescued by
        the party. We roll to see what named NPCs died and Adalia, the doctor
        and chronicler of the Rust Castle did not make it.
        <br />
        <br />
        It is revealed that the Pure Plant Patrol has halted recently due to
        much Helldriver activity, a betrayal by the Cage Island folks led by
        Ibor, and the escape of Berke who is at the saurian's lair tokens
        provide a safe landing spot for the Cage Island reinforcements seeking
        to join The Diamond Center and the Helldrivers to destroy the Sunshine
        Plaza and their allies forever.
        <br />
        <br />
        Special visitors to the Rust Castle were also part of the rescue trek to
        the Plaza. Abbetina and her human donor Verter came to the Rust Castle
        in the company of Dr. Theopolis (a monstrous floating brain with
        metallic tentacles and ability to communicate empathatically). Abbetina
        is carrying the Luminescent Child; the prophesized savior of the Zone
        according to Plutonia, the leader of the Nova Cult.
        <br />
        <br />
        We have a abstract Battle Royale map that will be used for a meta-turn
        based Zone War starting next session.
        <br />
        <br />A mutation misfire this session gave Bayazid a cosmetic alteration
        and now he is emaciated and thin.
      </p>
    ),
    image: {
      imagePath: battleRoyale,
      altText: `Battle Royale`,
      className: "imageLeft",
      imageCaption: "Battle Royale",
    },
  },
  {
    title: "I'm Your Captain",
    text: (
      <p>
        Meanwhile, Gladhand and Boss Hulagu load up the Steelcrest with
        bitterbeast, gear, two gyrocopters on deck; and towing the boat Furthur,
        and a jet ski. The head directly south on the Dead Blue Sea making a
        beeline for the Plaza. However, the gods of the ocean are not kind. The
        journey southward is plagued by Storm winds that last for at least four
        sectors in a row. Despite Gladhand taking levels in Navigator, the
        chaotic nature of the zone ocean takes it's toll.
        <ul>
          <li>Steelcrest takes damage and eventually starts to sink.</li>
          <li>
            Gladhand and Hulagu shepard the bitterbeast and gear from Command
            Center Charlie onto the boat further, but lack of successes forces
            Gladhand to abandon the jet ski. Gladhand drives Further as Boss
            Hulagu goes aloft on the gyrocopter Pegasus, and the gyrocopter
            named Dragonfly sinks to the bottom of the ocean along with the
            Steelcrest.
          </li>
          <li>
            In some beautiful emergent irony, the sector where the Steelcrest
            sinks is actually tagged as Underwater City!
          </li>
          <li>
            The Further is taking damage and struggling and Hulagu goes to
            cruising altitude on the gyrocopter to try and get out of the worst
            of the storm. Despite his efforts the flimsy craft takes damage and
            he plummets into the ocean.
          </li>
          <li>
            Hulagu uses barkskin to absorb damage from plunging into the ocean,
            and despite the raging storm Gladhand sees Hulagu plummet and
            manages to navigate Further close enough to rescue the boss.
          </li>
          <li>
            Jet ski, two gyrocopters, and Steelcrest lost, but as Hulagu and
            Gladhand approach the saurian lair, they spot Berke and Cage Island
            goons holding some of the saurians captive!
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "Wrapup",
    text: (
      <p>
        We did last session and this session rolls (except for Pure Plant Patrol
        which is on hold due to war brewing).
        <br />
        <br />
        We will pick up with finding out if Gladhand and Hulagu are spotted or
        not and their desire to land at Noatun Estates. Also, back at the Plaza,
        as preparations for war culminate, what sort of information can be
        learned from Abbetina of the Nova Cult who is in the Surgery project
        being guided through a dangerous birth.
        <ul>
          <li>Ark Arrivals: 4 humans, 3 mutants.</li>
          <li>Ark Reputation: 2 mutants, 1 human.</li>
          <li>
            Projects: Railroad finished, Generator finished, and Train begun.
          </li>
        </ul>
        Next session starts in-game 06-30-0001, approximately 5am.
        <br />
        <br />
        Base XP is 4 per character this session; a good buff as warfare looms.
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        .
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
];

const MYZAnchortownSession55 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">Session 55: I'm Your Captain</h1>
                <p className="lead leadExtra">
                  The IL Gang sets a trap for the Helldrivers and returns to the
                  Ark as Hulagu and Gladhand battle the Dead Blue Sea to journey
                  home.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession55;
