import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import flTitle from "../../../src/assets/forbidden-lands/forbidden-lands-logo.webp";
import sectionDivider from "../../../src/assets/fl-header-bar.jpg";
import "../../index.css";

import direWolf from "../../../src/assets/bitter-reach-bestiary-tokens/dire-wolf-token.png";
import dragon from "../../../src/assets/bitter-reach-bestiary-tokens/frost-dragon-token.png";
import iceGiant from "../../../src/assets/bitter-reach-bestiary-tokens/ice-giant-token.png";
import naniuk from "../../../src/assets/bitter-reach-bestiary-tokens/naniuk-token.png";
import pikeBeast1 from "../../../src/assets/bitter-reach-bestiary-tokens/pikebeast-1-token.png";
import pikeBeast2 from "../../../src/assets/bitter-reach-bestiary-tokens/pikebeast-2-token.png";
import stoneGuardian from "../../../src/assets/bitter-reach-bestiary-tokens/stone-guardian-token.png";
import stoneRaven1 from "../../../src/assets/bitter-reach-bestiary-tokens/stone-raven-1-token.png";
import stoneRaven2 from "../../../src/assets/bitter-reach-bestiary-tokens/stone-raven-2-token.png";
import wendigo from "../../../src/assets/bitter-reach-bestiary-tokens/wendigo-token.png";

const cardTitle = {
  paddingTop: 10,
  paddingLeft: 10,
  paddingBottom: 10,
  fontSize: 24,
  backgroundColor: "rgba(177, 83, 90, 1) ",
};
const pageHeader = {
  color: "black",
  backgroundColor: "white",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Aladin",
  fontSize: 20,
  backgroundColor: "rgba(118, 131, 87, 1)",
  color: "white",
  marginBottom: "15px",
};

class FLBestiaryTokens_BitterReach extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "BEGINNING NAME",
      text: "Click button for some fun.",
      testValue: "test value",
      selectedMutation: null,
    };
  }

  componentWillMount() {
    document.body.style.backgroundImage = `url(${require("../../../src/assets/fl-map-groveland-woods.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "round";
    document.body.style.backgroundAttachment = "fixed";
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = null;
  }

  render() {
    return (
      <div>
        <Container>
          <Row>
            <Col>
              <div
                style={{
                  backgroundColor: "transparent",
                  fontFamily: "Orbitron",
                }}
              >
                <main role="main" className="inner cover text-center">
                  <img
                    src={flTitle}
                    style={{ maxWidth: "400px" }}
                    alt="Forbidden Lands campaign"
                  />
                </main>
                <div className={"forbiddenLandsFont"} style={pageHeader}>
                  <h1 className="cover-heading">
                    Forbidden Lands Bitter Reach Bestiary VTT Tokens
                  </h1>
                  <p className="lead leadExtra">
                    The Bitter Reach bestiary of Forbidden Lands in transparent
                    token form for your virtual tabletop enjoyment. Made with
                    roll20 in mind.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card style={cardStyle}>
                <img src={sectionDivider} alt="section divider" />
                <Card.Body>
                  <Card.Title style={cardTitle}>
                    Bitter Reach Bestiary Tokens
                  </Card.Title>

                  <Card.Text>
                    <div className={"tokenWrapper"}>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Dire Wolf</div>
                        <img src={direWolf} alt="Dire Wolf" />
                      </div>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Frost Dragon</div>
                        <img src={dragon} alt="Frost Dragon" />
                      </div>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Ice Giant</div>
                        <img src={iceGiant} alt="Ice Giant" />
                      </div>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Naniuk</div>
                        <img src={naniuk} alt="Naniuk" />
                      </div>
                    </div>
                    <div className={"tokenWrapper"}>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Pikebeast 1</div>
                        <img src={pikeBeast1} alt="Pikebeast 1" />
                      </div>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Pikebeast 2</div>
                        <img src={pikeBeast2} alt="Pikebeast 2" />
                      </div>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>
                          Stone Guardian
                        </div>
                        <img src={stoneGuardian} alt="Stone Guardian" />
                      </div>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Stone Raven 1</div>
                        <img src={stoneRaven1} alt="Stone Raven 1" />
                      </div>
                    </div>
                    <div className={"tokenWrapper"}>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Stone Raven 2</div>
                        <img src={stoneRaven2} alt="Stone Raven 2" />
                      </div>
                      <div className={"tokens"}>
                        <div style={{ textAlign: "center" }}>Wendigo</div>
                        <img src={wendigo} alt="Wendigo" />
                      </div>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default FLBestiaryTokens_BitterReach;
