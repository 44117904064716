import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../assets/myz/myz-section-divider.jpg";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import { useEffect } from "react";

import oneClickInstall from "../../../assets/one-click-script-install.jpg";
import childrensGame from "../../../assets/myz/childrens-game.jpg";
import aranmulaKanadi from "../../../assets/myz/artifact-aranmula-kannadi-card.jpg";
import cereal from "../../../assets/myz/cereal-card.jpg";
import stableGeniusPills from "../../../assets/myz/stable-genius-pills.jpg";
import movie from "../../../assets/myz/movie-canadian-mounties-versus-atomic-raiders.jpg";

const cardTitle = {
  paddingTop: 10,
  paddingLeft: 10,
  paddingBottom: 10,
  backgroundColor: "rgba(221, 117, 28, .7) ",
};
const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "",
    text: (
      <p>
        Smoke Raven is proud to present our one-click Roll20 Sector Generator.
        Now you can install this script into your Mutant Year Zero game (or any
        game actually) as a one-click Roll20 script. See the install picture for
        a summary. The steps are simply:
        <ul>
          <ol>Navigate to your Roll20 game.</ol>
          <ol>Click on the Settings dropdown and choose "API Scripts".</ol>
          <ol>
            Make sure the "Script Library" tab is the active tab and click the
            Roll20 API Script Library dropdown.
          </ol>
          <ol>
            Start typing the word "Mutant"; you should see
            MutantYearZero_SectorGenerator; click that choice.
          </ol>
          <ol>Click the "Add Script" button.</ol>
          <ol>
            Login to your game and in the chat windows type "!sector-generate"
            and voila!
          </ol>
        </ul>
        Note that the output is a GM whisper. For our purposes, we cut and paste
        the output into the GM Only section of a handout, and now we have a
        sector ready to play.
        <br />
        <br />
        Note also that the script has a few custom artifacts (see below).
        Additionally, there are a few custom moods and several settlements you
        may not expect. The settlements include Cmd Center Alpha, Bravo,
        Charlie, and Echo. And there is also the Elysium Cult and the Elta Cult.
        These will have links in the future to custom content, but since there
        are unknown elements in active campaign, they are a mystery for now.
        Feel free to design your own encounter or ignore them for the time
        being.
      </p>
    ),
    image: {
      imagePath: oneClickInstall,
      altText: `SmokeRaven Sector Generator is now part of the Roll20 API Script Library`,
      className: "imageLeft",
      imageCaption: "How to Install Script",
    },
  },
];
const section2Data = [
  {
    title: "",
    text: (
      <p>
        The SmokeRaven sector generator has a few custom artifacts not in the
        Core rules. You can ignore these items and roll something different.
        However, if you would like to use them, there are included here for you.
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
    },
  },
  {
    title: "Artifact: ARANMULA KANNADI",
    text: (
      <p>
        <p>
          <b>ARANMULA KANNADI: </b>A beautiful handmade mirror in a
          well-preserved wooden case. It comes with paperwork explaining that it
          was handmade in India by a single family. Famously has no distortion
          or background noise like a normal mirror and allows one to see their
          "true self."
        </p>
        <p>
          <b>Effect:</b>Looking into mirror for first time causes 2d6 Empathy
          attack (one time only per person).. Tiny item.
        </p>
        <p>
          <b>DEV Requirement:</b> Culture 10
        </p>
        <p>
          <b>DEV Bonus:</b> Culture +D6, Technology +D6{" "}
        </p>
        <br />
        <br />
        <br />
      </p>
    ),
    image: {
      imagePath: aranmulaKanadi,
      altText: `ARTIFACT: ARANMULA KANNADI`,
      className: "imageLeft",
      imageCaption: "ARANMULA KANNADI",
    },
  },
  {
    title: "Artifact: CEREAL",
    text: (
      <p>
        <p>
          <b>CEREAL: </b>A colorful box filled with bits of grub. The musty,
          sweet crunchy bits inside are very refreshing to tired Zone wanderers.
          The Old Ones’ really knew how to get the day started.
        </p>
        <p>
          <b>Effect: </b>When you eat this, you immediately heal as if you ate
          two units of grub. There are D6 servings left in the box. After it is
          empty, you must discard this artifact. Light item.>
        </p>
        <p>
          <b>DEV Requirement:</b> Technology 10
        </p>
        <p>
          <b>DEV Bonus:</b> --{" "}
        </p>
        <br />
        <br />
        <br />
        <br />
      </p>
    ),
    image: {
      imagePath: cereal,
      altText: `ARTIFACT: CEREAL`,
      className: "imageLeft",
      imageCaption: "CEREAL",
    },
  },
  {
    title: "Artifact: CHILDREN'S GAME",
    text: (
      <div>
        <p>
          <b>CHILDREN'S GAME</b>
          <br />A box filled with plastic pieces. Most of them are square white
          pieces, but there is one red piece shaped like a person. Then, there
          are two pieces shaped like skinny hammers. Finally, there is a larger
          blue piece with legs. The white pieces seem to fit into the blue
          piece. There is writing inside the lid of the box top the pieces came
          in, but it's almost impossible to read.
        </p>
        <p>
          <b>Effect:</b>None. Small item.
        </p>{" "}
        <p>
          <b>DEV Requirement:</b> --
        </p>{" "}
        <p>
          <b>DEV Bonus:</b> Culture +D6{" "}
        </p>
      </div>
    ),
    image: {
      imagePath: childrensGame,
      altText: `ARTIFACT: CHLIDREN'S GAME`,
      className: "imageLeft",
      imageCaption: "CHILDREN'S GAME",
    },
  },
  {
    title: "Artifact: MOVIE",
    text: (
      <p>
        <p>
          <b>MOVIE: </b>A plastic package with a cool drawing of a mutant or a
          human attacking another with a bomb. But it doesn't seem like a
          poster. Gearheads think it might be something that can be used inside
          another artifact to understand more.
        </p>
        <p>
          <b>Effect:</b>--
        </p>
        <p>
          <b>DEV Requirement:</b> Culture 10.
        </p>
        <p>
          <b>DEV Bonus:</b> Culture +D6{" "}
        </p>
        <p>
          <b>Other:</b> If used with the Video Camera (METAPLOT), it gives an
          additional +D6 Culture and +D6 Technology and +D6 Defense.
        </p>
      </p>
    ),
    image: {
      imagePath: movie,
      altText: `ARTIFACT: MOVIE`,
      className: "imageLeft",
      imageCaption: "MOVIE",
    },
  },

  {
    title: "Artifact: STABLE GENIUS PILLS",
    text: (
      <p>
        <p>
          <b>STABLE GENIUS PILLS: </b>Small wooden box holding a plastic bottle
          with D6 pink, star-shaped pills. The label reads Clark Stanleys Stable
          Genius Pills.
        </p>
        <p>
          <b>Effect:</b>Taking a pill causes one to feel hot and queasy. Then,
          D6 rot after one hour. For an D6 hours, mutant is very sick and cannot
          move on their own. At the end of that sickness period, roll D6. On
          aall rot is removed, permanent and non-permanent (including any from
          the D6 taking he pill). In other words, the mutant is miraculously
          free of rot as they were when first created. On a , mutant must
          immediately roll as if the rot points from the pill have been removed
          (which may result in permanent rot, but otherwise all the rots points
          from pill go away -- this does not effect any existing rot points
          before the pill was taken). Then, after rolling to see if any of the
          rot from pill becomes permanent, the mutant receives a new mutation.
        </p>{" "}
        <p>
          <b>DEV Requirement:</b> Technology 20.
        </p>{" "}
        <p>
          <b>DEV Bonus:</b> Technology +2D6{" "}
        </p>
      </p>
    ),
    image: {
      imagePath: stableGeniusPills,
      altText: `ARTIFACT: STABLE GENIUS PILLS`,
      className: "imageLeft",
      imageCaption: "STABLE GENIUS PILLS",
    },
  },
];

const MYZSectorGenerator = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Mutant Year Zero Sector Generator
                </h1>
                <p className="lead leadExtra">
                  Now you can generate random sectors with threats and artifacts
                  with a single click in your Roll20 game.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Title style={cardTitle}>
                  Roll20 Sector Generator
                </Card.Title>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
              <Card.Body>
                <Card.Title style={cardTitle}>
                  Custom Artifacts in Generator Script
                </Card.Title>
                <Card.Text>
                  <ContentContainer data={section2Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZSectorGenerator;
