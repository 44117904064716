import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import NavBarFL from "../../../assets/navbar-icon-bitter-reach.webp";

const ForbiddenLandsNavBar = () => {
  return (
    <NavDropdown title="Forbidden Lands" id="basic-nav-dropdown">
      <Navbar bg="dark" variant="dark" expand="md" sticky="top">
        <Navbar.Brand href="#">
          <div style={{ padding: "0px 0px 0px 5px" }}>Forbidden Lands</div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <a href=".">
              <img
                src={NavBarFL}
                alt="Forbidden Lands"
                style={{ maxHeight: 85 }}
              />
            </a>
          </Nav>
          <NavDropdown title="Sessions 11-20" id="basic-nav-dropdown">
            <NavDropdown.ItemText></NavDropdown.ItemText>
            <NavDropdown.Item href="/forbidden-lands-session-13">
              <b>13: The Five Seals</b> [09-13-2024]
            </NavDropdown.Item>
            <NavDropdown.Item href="/forbidden-lands-session-12">
              <b>12: Awakening Day</b> [08-30-2024]
            </NavDropdown.Item>
            <NavDropdown.Item href="/forbidden-lands-session-11">
              <b>11: Panoowa, Ghost Boy</b> [08-02-2024]
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Sessions 01-10" id="basic-nav-dropdown">
            <NavDropdown.ItemText></NavDropdown.ItemText>
            <NavDropdown.Item href="/forbidden-lands-session-10">
              <b>10: The Sting</b> [07-19-2024]
            </NavDropdown.Item>
            <NavDropdown.Item href="/forbidden-lands-session-09">
              <b>09: Æther Serpents of Ruenlocke</b> [07-05-2024]
            </NavDropdown.Item>
            <NavDropdown.Item href="/forbidden-lands-session-08">
              <b>08: The Crimson Raven</b> [06-21-2024]
            </NavDropdown.Item>
            <NavDropdown.Item href="/forbidden-lands-session-07">
              <b>07: Throwing Bones</b> [05-31-2024]
            </NavDropdown.Item>
            <NavDropdown.Item href="/forbidden-lands-session-06">
              <b>06: Ruins of Ruenlocke</b> [04-19-2024]
            </NavDropdown.Item>
            <NavDropdown.Item href="/forbidden-lands-session-05">
              <b>05: When Stars Fall</b> [04-12-2024]
            </NavDropdown.Item>
            <NavDropdown.Item href="/forbidden-lands-session-04">
              <b>04: Murder in Northfall</b> [03-30-2024]
            </NavDropdown.Item>
            <NavDropdown.Item href="/forbidden-lands-session-03">
              <b>03: Winter Spirits & Tundra Walk</b> [03-15-2024]
            </NavDropdown.Item>
            <NavDropdown.Item href="/forbidden-lands-session-02">
              <b>02: The Elven Lighthouse</b> [03-02-2024]
            </NavDropdown.Item>
            <NavDropdown.Item href="/forbidden-lands-session-01">
              <b>01: Freedom Day in Gomsten</b> [02-02-2024]
            </NavDropdown.Item>
            <NavDropdown.Item href="/forbidden-lands-session-00">
              <b>00: Prisoners in Gomsten</b> [01-26-2024]
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Campaign" id="basic-nav-dropdown">
            <NavDropdown.ItemText></NavDropdown.ItemText>
            <NavDropdown.Item href="/forbidden-lands-contests">
              Contests
            </NavDropdown.Item>
            <NavDropdown.Item href="/forbidden-lands-gambling">
              Gambling
            </NavDropdown.Item>
            <NavDropdown.Item href="/forbidden-lands-goblin">
              Goblins
            </NavDropdown.Item>
            <NavDropdown.Item href="/forbidden-lands-wolfkin">
              Wolfkin
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Maps" id="basic-nav-dropdown">
            <NavDropdown.Item href="/forbidden-lands-campaign-maps">
              Campaign Maps
            </NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title="Generators" id="basic-nav-dropdown">
            <NavDropdown.ItemText></NavDropdown.ItemText>
            <NavDropdown.Item href="/forbidden-lands-demon-generator">
              Generate Demon
            </NavDropdown.Item>
            <NavDropdown.Item href="/forbidden-lands-adventurer-generator">
              Generate Adventurer
            </NavDropdown.Item>
            <NavDropdown.Item href="/forbidden-lands-legend-generator">
              Generate Legend
            </NavDropdown.Item>
            <NavDropdown.Item href="/forbidden-lands-stronghold-generator">
              Generate Stronghold
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Mishaps" id="basic-nav-dropdown">
            <NavDropdown.ItemText></NavDropdown.ItemText>
            <NavDropdown.Item href="/forbidden-lands-magic-mishaps">
              Spell Mishaps
            </NavDropdown.Item>
            <NavDropdown.Item href="/forbidden-lands-lead-the-way-mishaps">
              Lead the Way Mishaps
            </NavDropdown.Item>
            <NavDropdown.Item href="/forbidden-lands-foraging-mishaps">
              Foraging Mishaps
            </NavDropdown.Item>
            <NavDropdown.Item href="/forbidden-lands-hunting-mishaps">
              Hunting Mishaps
            </NavDropdown.Item>
            <NavDropdown.Item href="/forbidden-lands-fishing-mishaps">
              Fishing Mishaps
            </NavDropdown.Item>
            <NavDropdown.Item href="/forbidden-lands-making-camp-mishaps">
              Making Camp Mishaps
            </NavDropdown.Item>
            <NavDropdown.Item href="/forbidden-lands-sea-travel-mishaps">
              Sea Travel Mishaps
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Random Tables" id="basic-nav-dropdown">
            <NavDropdown.ItemText></NavDropdown.ItemText>

            <NavDropdown.ItemText></NavDropdown.ItemText>

            <NavDropdown.Item href="/forbidden-lands-critical-injuries">
              Critical Injuries
            </NavDropdown.Item>
            <NavDropdown.Item href="/forbidden-lands-hex-traveller">
              Hex Traveller
            </NavDropdown.Item>
            <NavDropdown.Item href="/forbidden-lands-random-encounters">
              Random Encounters
            </NavDropdown.Item>
            <NavDropdown.Item href="/forbidden-lands-stronghold-events">
              Stronghold Events
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="VTT Resources" id="basic-nav-dropdown">
            <NavDropdown.ItemText></NavDropdown.ItemText>
            <NavDropdown.Item href="/forbidden-lands-finds-simple-carried">
              VTT Finds: Simple, Carried
            </NavDropdown.Item>
            <NavDropdown.Item href="/forbidden-lands-finds-precious-carried">
              VTT Finds: Precious, Carried
            </NavDropdown.Item>
            <NavDropdown.Item href="/forbidden-lands-finds-precious-lair">
              VTT Finds: Precious, Lair
            </NavDropdown.Item>
            <NavDropdown.Item href="/forbidden-lands-bestiary-roll20-tokens">
              VTT Tokens: Bestiary
            </NavDropdown.Item>
            <NavDropdown.Item href="/forbidden-lands-bitter-reach-bestiary-roll20-tokens">
              VTT Tokens: Bitter Reach Bestiary
            </NavDropdown.Item>
          </NavDropdown>

          {/*TODO: use other navbar to get submenus: https://www.npmjs.com/package/react-bootstrap-submenu*/}
        </Navbar.Collapse>
      </Navbar>
    </NavDropdown>
  );
};

export default ForbiddenLandsNavBar;
