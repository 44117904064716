import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import chomicus_attenae from "../../../../assets/myz/ark-mutant-chromicus-3-attenae.png";
import ballroom from "../../../../assets/myz/ballroom-blitz.jpg";
import jebe from "../../../../assets/myz/ark-jebe-portrait.jpg";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Bookkeeping",
    text: (
      <p>
        05-17-0001: We took care of non-party business first which included:
        <br />
        <br />
        <ul>
          <li>
            BITTERBEASTS: three new hatchlings (11D6) and training rolls made.
            Altair and Shadowmere are now trained mounts.
          </li>
          <li>
            PROJECT ROLLS: Workshop takes 1 point damage from Zone quakes. +3
            points to Defenses and +3 points to Expand the Ark.
          </li>
          <li>
            PURE PLANT PATROL: guards are attacked by zone creatures. No
            casualties, but also no goods from the PURE PLANT this session.
          </li>
        </ul>
        <br />
        <br />
      </p>
    ),
    image: {
      imagePath: chomicus_attenae,
      altText: `Chromicus gets a cosmetic mutation misfire and gets antennae to go with his wings.`,
      className: "imageLeft",
      imageCaption: `Chromicus with antennae`,
    },
  },
  {
    title: "Ballroom Crits",
    text: (
      <p>
        The IL gang, 6 givers, Freddy the human, 4 human guards turned ally, and
        8 pacifist human prisoners make their way to the ballroom of the Diamond
        Center. Jebe sees Rebeth on the mezzanine with Morena, the traitorous
        ex-skag. Just as soon as Jebe gets wind of the IL gang coming into the
        lobby, he dashes from his hiding place to run up the escalators and toss
        the huge scrap bomb (BP 12 with shrapnel) onto the mezzanine floor with
        Rebeth in near range of the blast.
        <br />
        <br />
        Rebeth and other mutants as well as the two human guards grappling Krinn
        take damage, as well as Krinn herself. Rebeth is broken with critical
        injury of CRUSHED FOOT. Krinn is also broken with THIGH WOUND. In the
        ensuing three rounds of combat we had the IL gang and allies streaming
        into the lobby, snipers outside the lobby firing through the windows,
        and mayhem on the escalator with the following highlights:
        <ul>
          <li>
            Boss Hulagu has a mutation misfire and his frog legs locked down for
            rest of session AND he breaks his gun.
          </li>
          <li>
            Smoke Raven has a wild, epic turn in which he has a mutation misfire
            which causes a permanent loss of attribute point (EMPATHY) and a new
            mutation of EXTREME REFLEXES. Smoke Raven uses this new mutation on
            the same turn to fire again which itself causes a second mutation
            misfire in the same turn; this time a cosmetic mutation (his third).
            Smoke Raven gains a beak to go with his bird feather hair and fangs.
          </li>
          <li>
            Jebe shoots Rebeth who had gotten back to her feet thanks to Morena,
            and she gets a second critical injury of SPRAINED WRIST.
          </li>
          <li>
            Three giver guards switch sides to become allies thanks to
            INTIMIDATE rolls, battle momentum, and the cries of their fellow
            humans calling for freedom and revolution.
          </li>
          <li>Double Bird uses body guard to protect Jebe from gunshot.</li>
          <li>
            Boss Hulagu uses insectoid to climb wall and get up on the
            mezzanine.
          </li>
          <li>
            Dink spears Jebe on escalator and breaks him. Jebe rolls DAMAGED
            SHIN for critical injury.
          </li>
          <li>
            Delta shoots Jebe for 3 damage. Double Bird bodyguards for a second
            time and saves Jebe's life. (note: the extreme prejudice against
            Jebe was helped by his presence on the escalators from throwing the
            bomb, but every time he was one of several targets that random dice
            decided to hate Jebe, almost like the session where he was knocked
            off motorcycle from lightning).
          </li>
          <li>
            Jebe is shot again by another mutant guard and Double Bird
            bodyguards him for a THIRD time and saves his life again. Thanks to
            SHAKE IT OFF and BARKSKIN mutation Double Bird himself is still
            alive but quickly running out of mutation points.
          </li>
          <li>
            Double Bird smashes Dink over the head with spiked bat, shattering
            the sniveling, traitors brain pan so hard that the bat breaks.
          </li>
          <li>
            Another female mutant, Astoria, spits acid on Jebe. Jebe's only hope
            is his shield. Shield does not word and Jebe dies (see graveyard for
            eulogy).
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: ballroom,
      altText: `In medias res shot of the Diamond Center ballroom as battle ensues from
        ground floor and mezzanine with escalators complicating things.`,
      className: "imageLeft",
      imageCaption: "Ballroom Battle",
    },
  },

  {
    title: "Wrapup",
    text: (
      <p>
        The ballroom blitz went hard on Jebe. The random rolls targeting him in
        a way that made it clear fate decided to repay him for his heroic bomb
        toss that freed Krinn from her guards and took Rebeth down (but not yet
        out).
        <br />
        <br />
        Luna the Chronicler, an ex-skag gang member turned ally was killed, as
        was Freddy, the leader of the basement giver humans.
        <br />
        <br />
        And so, after 4 critical injuries, 3 mutation misfires, 2 gear breaks,
        and 3 named PC/NPC deaths in just THREE ROUNDS of action, we left the
        game right at the top of round four with Boss Hulagu first to act next
        time. All the action is still taking place on day 05-17-0001 with no
        need to worry about rot, food or water.
        <br />
        <br />
        Next session will begin late afternoon/evening of 05-17-0001. We awarded
        five XP at end of session, with Double Bird getting 8 thanks to three
        heroic saves of his buddy Jebe. We will do XP spend, award session
        mutation points, and get rolling.
        <br />
        <br />
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        . Get your ass over there, this is Jebe we're talking about!!
      </p>
    ),
    image: {
      imagePath: jebe,
      altText: `Jebe the Dog Handler of the Sunshine Plaza. Lived from session 1 to session 28.`,
      className: "imageRight",
      imageCaption: "Jebe, RIP",
    },
  },
];

const MYZAnchortownSession28 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 28: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  The IL gang is in a fight for their lives and the lives of the
                  humans they freed in the lobby of the Diamond Center. Bombs,
                  critical injuries, broken gear, mutation misfires, and the
                  loss of JEBE make this an epic session of violence drowned in
                  sorrow.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                {/*<Card.Title style={cardTitle}>Shootout at Burger Jim's, 03-27-0001 - 03-27-0001</Card.Title>*/}
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession28;
