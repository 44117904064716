import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import marshlands from "../../../../assets/myz/marshlands-4.jpg";
import snakeOil from "../../../../assets/myz/clark-stanley-snake-oil.jpg";
import demyan from "../../../../assets/myz/human-demyan.jpg";

const cardTitle = {
  paddingTop: 10,
  paddingLeft: 10,
  paddingBottom: 10,
  backgroundColor: "rgba(221, 117, 28, .7) ",
};
const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Riding the Zone",
    text: (
      <p>
        03-22-0001: This entire session was wrapping up the afternoon and
        evening of 03-22-0001.
        <br />
        <br />
        SmokeRaven and Kublai rode motorcycle to Sector F05 and then camped.
        Thanks to fast speed and decent Find the Path rolls, they were able to
        speed past a WORM SWARM and get through three sectors in the three hours
        of daylight they had left. Sector G05 had clear blue sky and is
        analogous to Potter's Marsh outside Anchorage Alaska. See MARSHLANDS
        picture for my take on using real-world location as Zone Sector handout
        graphic. .
      </p>
    ),
    image: {
      imagePath: marshlands,
      altText: `Marshland sector based on real-world Potter's Marsh outside Anchorage, Alaska.`,
      className: "imageLeft",
      imageCaption: "Potters Marsh",
    },
  },
  {
    title: "Rebuilding at The Sunshine Plaza",
    text: (
      <p>
        Meanwhile, back at the Sunshine Plaza, Boss Krao has a funeral for
        Hanneth the Spoiled, her proxy daughter and member of the Order of the
        New Dawn chroniclers. During the HELLDRIVER attack on the Plaza, Grandma
        Yo-ya and Hanneth were on their way to the rooftop to deliver news that
        the Helldrivers had breach the courtyard when Harley of the Helldrivers
        confronted them and killed Hanneth. Harley took the aranmula kannadi
        mirror artifact from Hanneth and left Grandma Yo-ya wailing over her
        sisters corpse (see{" "}
        <a
          href="./mutant-year-zero-anchortown-graveyard"
          rel="noreferrer"
          target="_blank"
        >
          Graveyard
        </a>{" "}
        for Hanneth's memorial).
        <br />
        <br />
        Boss Krao spoke with tears in her eyes for Hanneth, ending her memorial
        with, "Vanity such as hers did not deserve defacement." Boss Krao
        announces at the funeral: "I was wrong before to oppose zone
        expeditions. If the Zone will come into our home, then we must go out
        into the Zone and make our mark!" Boss Krao's conservatism regarding
        exploring has changed in the wake of Hanneth's murder and the invasion
        from the Helldrivers.
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageRight",
      imageCaption: null,
    },
  },
  {
    title: "Party, Wanderers, and Rebuilding the Temple of Endurance",
    text: (
      <p>
        The 17 members of the human WANDERERS group are introduced at an
        impromptu party. The Elder is brought out in his scrap wheelchair for
        the celebration of the new members of the ark and beating back the
        Helldrivers.
        <br />
        Each of the bosses gives their speech, establishing their basic attitude
        toward the new members of The People:
        <br />
        <br />
        <ul>
          <li>
            <u>Boss Krao</u>: cautious but welcoming, clearly distracted by the
            trauma of losing Hanneth.
          </li>
          <li>
            <u>Boss Berke</u>: solicious and welcoming. He really turns on the
            politician charm.
          </li>
          <li>
            <u>Boss Skag</u>: his speech boils down to, "don't fuck with us and
            we won't fuck with you; except maybe this one." He leers at Wanderer
            Belka.
          </li>
          <li>
            <u>Boss Hulagu (PC)</u>: Hulagu welcomes the mutants and says, "if
            you have any problems or you need anything, you come see me.
          </li>
          <li>
            <u>Boss Quiet Bob</u>: he gives a speech that culminates in his
            attempt to ape former Boss Loud Sue's tourette-like proclamations by
            stating: "mastering others is strength. Mastering yourself is true
            power." He then pulls out the severed head of Forcept, mutant
            assassination of Loud Sue and shouts "And weakness is this slapdash,
            taint-whistling shitbird!!" He places Forcept head on a spear as
            members of Quiet Bob's gang clap and a few of the ExKurtzers applaud
            as well.
          </li>
        </ul>
        Finally, The Elder glowers when asked to speak. He waves a weak,
        rot-speckled hand and proclaims:
        <br />
        <i>
          "Surely some revelation is at hand; Surely the Second Coming is at
          hand."
        </i>
        <br />
        Krao is pouring some of the CEREAL artifact food for him as he
        continues:
        <br />
        <br />
        <i>
          "somewhere in sands of the desert
          <br />
          A shape with lion body and the head of a man,
          <br />
          A gaze blank and pitiless as the sun,
          <br />
          Is moving its slow thighs, while all about it
          <br />
          Reel shadows of the indignant desert birds"
        </i>{" "}
        <br />
        <br />
        The Elder then slumps back as The People applaud lightly, not sure what
        The Elder has spoken or what it means. Boss Krao spoons some cereal for
        The Elder. He suddenly exclaims: "Dear God". The Elder grabs the box of
        cereal. "How did he get in here?" He fumbles in his pocket and pulls out
        a folded piece of paper. "Blasphemer!" His old hands flail as he speaks
        and he drops the paper to the group. The bowl of cereal falls to the
        floor and the Elder tries to get out of his wheeled chair to get the
        paper, or the cereal?
        <br />
        <br />
        Boss Krao is beside herself trying to reassure him and get him to calm
        down. The Elder is shouting like none heard him. Many mutants can recall
        as children hearing him give a sharp retort to their antics, but he
        never shouted and frothed like this. <br />
        "He was a snake oil salesman!" The Elder whirls to gesture at everything
        and everyone. His thin, unsteady legs give way and he crashes to the
        ground. He is shaking with fury on the ground and continues ranting.
        <br />
        "He thought he could buy his way into science! With money!! Into
        science! No soul . . . Scoundrel! God strike me dead if this isn't all
        his fault! Him and others like him!" <br />
        Boss Krao is struggling to lift the Elder up to a sitting position and
        calm him. "Some one help" she cries. "Help me get him back in his
        chair."
        <br />
        <br />
        The Elder faints. Hulagu, Double-Bird, and Skut help take the Elder to
        his room upstairs.
      </p>
    ),
    image: {
      imagePath: snakeOil,
      altText: `Clark Stanley portrait dropped by The Elder having a seizure when he saw Clark on box of cereal`,
      className: "imageRight",
      imageCaption: `snake oil science?`,
    },
  },
  {
    title: "Escaping the Helldrivers",
    text: (
      <p>
        Rowbert, Chromicus, Elson the dog mutant are prisoners at the
        Helldrivers parking garage. Chromicus' bleeding gut has been staunched
        by Rowbert with Raff's help, but only so Raff could fulfill Luke's
        demand that Chromicus be kept alive for torture.
        <br />
        <br />
        The lone human that was liberated from the cages last session sneaks
        back and appears suddenly near the cages holding our heroes. "My name is
        Damyen" he says with a rough halting dialect similar to Fatima and her
        band of Wanderers that Double-Bird guided back to the Ark. "I had given
        up my life. You saved it. Now it is yours. How can I help?" The party
        has Damyen scout to find working vehicle, Raff's location, and their
        gear.
        <br />
        <br />
        Random rolls help us form the story that Raff is worried about splitting
        his duties as gearhead and jailer (taking the place of actual jailer who
        is in the sick room). Therefore, he is sleeping a room in the basement
        level near the prisoner cages.
        <br />
        <br />
        Normally mutant prisoners are broken and forced to use their mutation
        points, but since it is the same day and Luke is busy with the bodies
        and mess outside, the escape begins with Rowbert using acid spit on
        three locks to get everyone out of their cages.
        <br />
        <br />
        Chromicus wakes Raff with a dangerous headlock and uses INTIMIDATE to
        suggest Raff unlock their gear and come with them back to The Sunshine
        Plaza. No success, so Chromicus growls "You're coming with us, I really
        must insist" (or something similar). Unfortunately, Chromicus breaks
        himself on the that push. We envisioned it as Raff striking Chromicus in
        the head with his WRENCH in a panicked over-reaction.
        <br />
        <br />
        Just as Demyan is about to tackle Raff, Rowbert says "hold on everybody"
        and continues on to succeed with a manipulate on Raff to convince him he
        should come with them after unlocking their gear. Raff must agree, but
        in return he wants to return to the body shop on level 3 and get his
        precious blanket.
        <br />
        <br />
        Rowbert sneaks along with Raff to keep an eye on the little weasel. Raff
        would sell his own grandmother to protect himself, but it's a trait the
        party uses against the little guy. Raff's co-worker in the shop fails to
        notice Raff's nervousness and the blanket mission is completed
        successfully. GM rolls for guards to appear is making party nervous.
        They decide finding a working car and getting out of the garage with the
        noise is too risky.
        <br />
        <br />
        Rowbert does a heal to get Chromicus back on his feet. Then, with decent
        MOVE rolls, the party absconds into the dark night of the Zone to camp
        in the same sector, but away from the parking garage.
      </p>
    ),
    image: {
      imagePath: demyan,
      altText: `Demyan is one of the WANDERERS who was rescued from the Helldriver parking garage.`,
      className: "imageLeft",
      imageCaption: "Demyan, human",
    },
  },
  {
    title: "Wrapup",
    text: (
      <p>
        XP for the session was THREE for each mutant.
        <br />
        <br />
        Next session starts morning of 03-23-0001. Status of characters is:
        <br />
        <br />
        <ul>
          <li>
            Rowbert, Chromicus, Elson, Demyan, and Raff are in sector I02
            travelling back to the Plaza.
          </li>
          <li>
            Hulagu, Jebe, Double Bird, and Skut are in the Ark helping rebuild
            Temple of Endurance.
          </li>
          <li>Smoke Raven and Kublai camped in sector G05.</li>
        </ul>
        <br />
        Check out Session Body Count at:{" "}
        <a href="./mutant-year-zero-anchortown-population-notable-events">
          SESSION BODY COUNT AND POPULATION
        </a>
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a href="./mutant-year-zero-anchortown-graveyard">
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageRight",
    },
  },
];

const MYZAnchortownSession12 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 12: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  Smoke Raven and Kublai ride motorcycle to Potter's Marsh and
                  see blue sky in the Zone for the first time in their lives.
                  Meanwhile at the Ark: the Wanderers are introduced, work to
                  rebuild Temple of Endurance begins, and The Elder has a fit
                  when he sees the picture of Clark Stanley on box of cereal.
                  And finally, our heroes trapped at the Helldrivers parking
                  garage make a daunting escape with the Helldriver gearhead and
                  jailer Raff in tow.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Title style={cardTitle}>
                  Three-Way Party Split, 03-22-0001
                </Card.Title>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession12;
