import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import daisy from "../../../../assets/myz/mutant-daisy.jpg";
import baila from "../../../../assets/myz/mutant-baila.jpg";
import katie from "../../../../assets/myz/katie-kat-token.png";
import trix from "../../../../assets/myz/trix-token.png";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Digging Deeper into the Diamond Center",
    text: (
      <p>
        05-17-0001: We took care of non-party business first which included:
        <ul>
          <li>
            BITTERBEASTS: two new eggs hatched. one beast moved into training.
          </li>
          <li>PROJECT ROLLS: +6 to homebrew project Expand the Ark</li>
          <li>
            PURE PLANT PATROL: the third Pure Plant patrol is successful and
            grub, booze, and water supplied to general Ark supplies.
          </li>
        </ul>
        As the gang hangs out, plans are made to investigate the "basement" that
        Krinn mentioned to Smoke Raven during their tryst.
        <br />
        <br />
        Most of the party heads to the shop to talk with gearhead Naphta. The
        party meets Fortran's little friend Katie Kat and her pet fox Trix.{" "}
        <br />
        "I'm Katie Kat. My mom was a dog handler. She died from Zone Ghouls.
        This is my friend Trix."
        <br />
        "I don't have a mutation yet. The other kids call me a Zone Ghoul but I
        tell Trix to bite them. I've already taught him some tricks. Wanna see?"
        <br />
        <br />
        Gladhand tries to suss out more information as guards oversee in a
        half-assed manner. Naphta fashions two saddlebags for the IL Gang's
        bitterbeasts. Jebe at some point makes a roll to become co-dog handler
        for Trix so he can help Katie Kat learn more and command the fox if
        needed.
        <br />
        <br />
        Meanwhile, Smoke Raven proceeds down the hallway to another wing of the
        Diamond Center that has yet to be explored. He encounters two guards,
        Baila and Daisy. Daisy plays coy and flirty good cop whilst Baila is
        clearly the bad cop. Smoke Raven banters long enough to realize he is
        not going to be allowed into that area of the ark. So, Smoke Raven does
        what he does best and melts into the shadows to sneak into the basement
        under that wing of the Diamond Center.
        <h4>SPOILER ALERT</h4>This special zone is based on The Other Ark from
        the core rulebook and contains information that could ruin the
        experience for players who have not yet gone through this zone.
      </p>
    ),
    image: {
      imagePath: daisy,
      altText: `Daisy is one of the mutant guards at the Diamond Center.`,
      className: "imageLeft",
      imageCaption: `Daisy of the Diamond Center`,
    },
  },
  {
    title: "What Fresh Hell Awaits",
    text: (
      <p>
        As the party speaks with Katie Kat, Fortran, and Naphta, Smoke Raven
        makes his way into the basement of the Diamond Center. The basement
        smells terribly. Smoke Raven sees a guard watching men, mostly humans,
        tied to and pushing a huge, metal spoked wheel in circles. The wheel is
        attached to a vertical shaft. The source of at least some of the power
        at the Diamond Center has been revealed.
        <br />
        <br />
        The reason for basement smells is found when Smoke Raven finds a room
        stained with blood and containing several metal tables with restraints.
        A few small body parts make it clear this room is a slaughterhouse for
        killing people.
        <br />
        <br />
        Further into the depths of the basement, Smoke Raven finds male humans
        crowded into fetid, cramped cages. Smoke talks with the nominal leader
        of the prisoners, a male human named Freddy. Freddy confirms injured
        prisoners or prisoners unable to work are killed and turned into food.
        The dried "jerky" the party has been eating since arriving at the
        Diamond Center is people.
        <br />
        <br />
        Smoke Raven speaks eloquently of his vision to Freddy but must make a
        difficult decision to leave the prisoners in their cages. Freddy exacts
        a solemn promise from Smoke Raven that Smoke Raven will be back to
        rescue the men.
      </p>
    ),
    image: {
      imagePath: katie,
      altText: `Katie Kat is a half-mutant girl from the Diamond Center. She is a dog handler with a pet fox named Trix`,
      className: "imageRight",
      imageCaption: "Katie Kat",
    },
  },
  {
    title: "Logans Run",
    text: (
      <p>
        As Smoke Raven discovers the secrets of the Dimand Cenwer, the party is
        in the lobby having breakfast when shouting erupts. "Stop Logan! Stop
        right there."
        <br />
        <br />
        Three ragged men run through the lobby with several guards in pursuit.
        The men ignore the guards' warnings and flee through the doors of the
        hotel ark into the yard. One of the men is shot dead and the other two
        are brought back into the lobby and made to kneel on the shattered tile
        of the main entrance.
        <br />
        <br />
        Krinn arrives and speaks with the guards. And then Rebeth also arrives,
        walking down the static escalators from the upper mezzanine with
        ex-skagger Morena and Krinn's personal slave/assistant Dink walking
        beside her.
        <br />
        <br />
        Rebeth and Krinn argue as Boss Hulagu, Gladhand, and Double Bird are
        drawn into the conversation. Smoke Raven appears on the edges of the
        tense standoff and speaks with ex-Skag gang member Kyle. The IL Gang
        suspects betrayal as Dink's manner seems to indicate he may have
        betrayed Krinn, and Morena's favored position next to Rebeth and her
        pointedly ignoring Kyle and Luna bode ill for the IL gang. Has Morena
        traded the location of the Sunshine Plaza for a position in the Diamond
        Center?
        <br />
        <br />
        During the tense standoff, there is a rumbling sound and glass comes
        crashing down as the earth itself seems to move. After the world stops
        swimming wildly, Gladhand makes a play on the stunned confusion by
        shouting about how Mimir is angry and the ground moving is indication
        that he is displeased. Everyone is confused and unsure what to think.
        <br />
        <br />
        The Chairman arrives with half a dozen male human guards. The human
        guards do not seem as well armed as the female mutant guards, but the
        number of Diamond Center guards is overwhelming. The Chairman explains
        it was an earthquake and everything is ok. He explains how rules are
        needed to have such a large ark run smoothly and that these foolish men
        thought the Zone had something better to offer and that the ones who
        surrendered will be put to work instead of ostrasized or killed to earn
        their place back among the people of the Diamond Center. Since the main
        party does not yet know what Smoke Raven found, they pretend to be
        mollified by the Chairman's speech and the tense standoff disperses.
        <br />
        <br />
        Sitting in the ruined cafe section of the lobby, Smoke Raven explains
        what he found. Fortran gets a note to the party via Katie Kat and warns
        them not to sleep or eat or drink anything; also, he charges the IL Gang
        with getting Katie Kat and Trix out of the Diamond Center. Chromicus
        gives a speech to Kyle and Luna, the ex-skag gang members that have been
        running with Morena. He exacts their loyal oath to rejoining the
        Sunshine Plaza now that Morena seems to have abandoned them. Kyle, the
        poor little psychotic, keeps saying of Morena, "I thought she liked me."
        <br />
        <br />
        It's clear the guards are on heightened alert while still pretending to
        be somewhat casual. The party understands they are in danger; that
        beneath the civil surface there are forces at work and should Smoke
        Raven's jaunt be discovered they will surely to imprisoned. They began
        to hatch plans:
        <ul>
          <li>
            Can they kidnap the Chairman, or is getting to the top of the tower
            via a manually operated elevator that takes two trips for the entire
            part worth considering? Hulagu could try insectoid again, but what
            if something goes wrong?
          </li>
          <li>
            Can they get the men out of the basement and into the vehicle bay
            onto one or more of the armored buses? Can they drive the buses? Are
            there keys needed to start the vehicles? Can Naphta help and be
            relied upon?
          </li>
          <li></li>
        </ul>
        Tune in next time for Escape From the Diamond Center.
      </p>
    ),
    image: {
      imagePath: trix,
      altText: `Trix is a young fox companion of Katie Kat; also befriended by Jebe the Dog Handler`,
      className: "imageLeft",
      imageCaption: "Trix the fox",
    },
  },

  {
    title: "Wrapup",
    text: (
      <p>
        Next session will begin late afternoon/evening of 05-17-0001. We awarded
        three XP at end of session, so there is no prep at all since food, water
        and rot of no concern in ark. We will do XP spend, award session
        mutation points, and get rolling.
        <br />
        <br />
        The body count roll was 6. Minus two due to FOOD DEV level of 20+ leaves
        us with four casualties. The dead were two unnamed mutants, one unnamed
        ex-Kurtzer mutant, and Wanderer Demyan, a human.
        <br />
        <br />
        Note: Morena has been removed from the Ark population as she has
        switched sides to work with the Diamond Center due to promises from
        Rebeth that her status will be much greater there as a female mutant
        than it would ever be living in exile or going back to the Sunshine
        Plaza which is ruled by a "bunch of shriviled dicks" according to
        Rebeth. "Did you know Double Bird tried to woo me with a poem? A poem!
        What a sad little fellow; a let him live just to see him squirm more
        hahaha!"
        <br />
        <br />
        On the plus side, Kyle and Luna have re-joined the Sunshine Plaza,
        convinced that Morena betrayed them and that they need to help the IL
        Gang get out of the Diamond Center.
        <br />
        <br />
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: baila,
      altText: `Baila, a stern, humorless guard at the Diamond Center`,
      className: "imageRight",
      imageCaption: "Baila",
    },
  },
];

const MYZAnchortownSession25 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 25: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  The IL gang reaches The Other Ark, better known as the Diamond
                  Center. Depite plentiful food and water, the abundance of
                  children, the friendly but insistent questions of The
                  Chairman, and the subservient role of the male population have
                  raised some questions; questions for which our players intend
                  to find the answers.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                {/*<Card.Title style={cardTitle}>Shootout at Burger Jim's, 03-27-0001 - 03-27-0001</Card.Title>*/}
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession25;
