import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import logo from "../../../../src/assets/forbidden-lands/forbidden-lands-logo.webp";
import sectionDivider from "../../../../src/assets/forbidden-lands/session06/bitter-reach-page-banner-session-06.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";
import "./forbidden-lands-styles.scss";

import aileen from "../../../../src/assets/forbidden-lands/session06/aileen.webp";
import neha from "../../../../src/assets/forbidden-lands/session06/neha.webp";
import shima from "../../../../src/assets/forbidden-lands/session06/shima.webp";
import stillak from "../../../../src/assets/forbidden-lands/session06/stilak.jpg";
import tiemisz from "../../../../src/assets/forbidden-lands/session06/tiemisz.webp";
import map from "../../../../src/assets/forbidden-lands/maps/map-bitter-reach-overview.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "1",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Carter One",
  backgroundColor: "rgba(0, 0, 0, 1) ",
  color: "white",
  marginBottom: "15px",
};

const FBLSession06 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  const section1Data = [
    {
      title: "An Ailander Standoff",
      anchor: "freedom-day",
      sectionTitleCssName: "sectionTitleFBL",
      text: (
        <div className="fblBodyText">
          <ImageComponent
            image={stillak}
            caption="Stillak the Hunter"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="Stillak is a male ailander hunter who wandered in his childhood. He knows Ibak and Tulok; they came from the same small village."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <span style={{ color: "burlywood" }}>
            Stillak joins the hunt for Turold Blood-eye.
          </span>
          . Now each player has two PCs. Stillak knows Tulok and Ibak; they are
          all wanderers since childood from the same lost village..
          <br />
          <br />
          Last session, the stars fell in the heavens and an awesome light shot
          into the firmament from the southern reach of the Bitter Reach; an
          omen of great import. The party knows the hex to explore related to
          the star, but they cannot vary from their pursuit of Turold.
          <br />
          <br />
          As the light of the ascending star fades, they see by firelight a
          group of four rogues trying to steal away with the party sled. A tense
          encounter begins.
          <br />
          <br />
          <ImageComponent
            image={aileen}
            caption="Aileen"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Aileen is the commander of Company Zed; a group of treasure hunters recently ravaged by snow storms and brought to desperate measures."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <ImageComponent
            image={neha}
            caption="Neha"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="Neha is a frailer elf from Alderland. She is haughty and quick to anger. She detests orcs and wolfkin and is a skilled archer."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          Each person on each side generally draws their weapon but calls for
          parlay. Rog-Golar simply barks,{" "}
          <span style={{ color: "lightgreen" }}>
            "Ok. We're good. Just give sled back".
          </span>
          &nbsp; Tulok, Stillak, Ibak all draw weapons but speak out for parley.
          Lobo moves closer to the thieves with weapon drawn saying,{" "}
          <span style={{ color: "lightgreen" }}>
            "let's talk, but know that I will protect my friends."{" "}
          </span>
          Blisster moves closer to fellow goblin Tiemisz and says &nbsp;
          <span style={{ color: "lightgreen" }}>
            "I want to learn more about my culture and my people"{" "}
          </span>{" "}
          as Tiemiez lows his boarding axe and says to Aileen,{" "}
          <span style={{ color: "lightgreen" }}>
            "I guide, not guard. I guide."
          </span>
          The strangers trying to steal the sled are:
          <br />
          <br />
          <ul>
            <li>
              <span style={{ color: "burlywood" }}>Aileen</span>: a dwarf that
              begins the encounter with his battleaxe drawn but draped over his
              shoulder.{" "}
              <span style={{ color: "lightgreen" }}>
                "Steady friends, you were all asleep and we could have killed
                you. We merely wanted your sled. Let's all take a breath."
              </span>
            </li>
            <li>
              <span style={{ color: "burlywood" }}>Shima</span>: a female human
              that stands by the sled with broadsword in hand as she
              gesticulates.{" "}
              <span style={{ color: "lightgreen" }}>
                "We're sorry. We were only taking what you had on the sled.
                Clearly Wyrm is displeased and showed us in the sky that we
                should all remember we live under the same sky."
              </span>
            </li>
            <li>
              <span style={{ color: "burlywood" }}>Tiemisz</span>: a male goblin
              of the Grohnewailz clan. He is wearing padded armor with a leather
              cap adorned with two severed wolf paws. He points at Blisster and
              demands,{" "}
              <span style={{ color: "lightgreen" }}>
                "you Nogoon? you green?"
              </span>{" "}
              Later we discover that the Nogoon goblins (aka the green goblins)
              are the goblins of Trottersmark less affiliated with the wolfkin.
              They are a smaller force of wild and hearty wolf riders that often
              hire themselves as guides to treasure hunters.
            </li>
            <li>
              <span style={{ color: "burlywood" }}>Neha</span>: a female frailer
              elf with white hair, pale white skin and light blue eyes. She
              points a draw bow at the players and on her action she snarls
              &nbsp;
              <span style={{ color: "lightgreen" }}>
                "More shabby mules. Your hesitation is weakness Aileen, we need
                to take what should be ours."
              </span>
              .
            </li>
          </ul>
          <ImageComponent
            image={shima}
            caption="Shima"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Shima is a femal Alderlander. She is a basic outlaw that wields a broadsword. She would rather not risk her life if coin can be made without risk."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <ImageComponent
            image={tiemisz}
            caption="Tiemisz"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="Tiemisz is a Grohnewailz goblin. The Grohnewailz are less affiliated with the wolfkin than the Nogoon clan. Some of the best hunters and guides among the goblins come from the Grohnewailz clan."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          Neha shoots her arrow at Lobo as her hatred for wolfkin is palpable.
          Lobo uses his FAST FOOTWORK to dodge and remain standing. Neha then
          trains her arrow on Aileen. She attempts to manipulate Aileen into
          attacking instead of parley but fails and so gives up the fight and
          the encounter comes to a close as everyone gathers around the midnight
          fire to parley.
          <br />
          <br />
          Aileen plunges his battleaxe into the deep snow and sits to show his
          good will. Neha crouches further from the fire than the others as
          Stillak approaches.{" "}
          <span style={{ color: "lightgreen" }}>
            "I see you carry a bow"
          </span>{" "}
          he remarks seeking common ground with the angry elf. She gives him a
          sour look.{" "}
          <span style={{ color: "lightgreen" }}>
            "I see yours too; it's big."
          </span>
          <br />
          <br />
          Before everyone sleeps, food is cooked and shared and Aileen tells
          them that Company Zed was eight strong but they were turned "head over
          ass" by days of snowstorms.&nbsp;
          <span style={{ color: "lightgreen" }}>
            "We were travelling to Ruenlocke, an ancient dwarf ruin build in or
            around a monstrous skeleton said by many to have been a dragon in
            forgotten ages. My family used to speak of the ancient stronghold as
            I grew up in Alderland."
          </span>{" "}
          It turns out that Aileen, Neha, and Shima are all from Alderland. They
          stowed away on an exploratory vessel of Baraggor's that came to the
          Bitter Reach 4-6 months ago to open discussions on trade, offloading
          prisoners from Gomsten Island, and other business. They all came to
          the Bitter Reach to find Ruenlocke and make their fortune. They hired
          Tiemiez as a guide. Four of their group which they call Company Zed
          died in the snowstorms.
          <br />
          <br /> Aileen seems surprised the party does not have a name for
          itself. &nbsp;
          <span style={{ color: "lightgreen" }}>
            "In dwarf culture we name any group gathered for a common purpose to
            bond the effort and strengthen the endeavor; you should have a
            name!"
          </span>
          <br />
          <br />
          Aileen also shares LORE about the locations of the Bitter Reach as
          shown at end of this session write-up.
          <br />
          <br />
          There is a settlement of about 100 citizens just to the southwest
          named CRISTALBREAK that Company Zed wants to go to. However, Rog-Golar
          reminds them they said they would help track Turold Bloodeye first.
          Also, Lobo explains leaving the trail will break his HUNTING INSTINCTS
          connection to he prey. And so the party goes along the northern curve
          of the river KELD and arrive at the top of an ancient ice staircase.
          At the top the stairs there are mountain-like walls of ice bulging up
          out of the Morma Glacier. There is a small cave near them and there
          are the bones of a monsterous, ancient creature spanning a chasm next
          to a waterfall cascasding down the icy sides of the glacier. There is
          also a carved entrance into the rising glacier wall, clearly the work
          of humanoid hands from long ago, dwarves as Aileen insists.
          <br />
          <br />
          Welcome to the Ruins of Ruenlocke.
          <br />
          <a href="#pageTop">Back to Top</a>
        </div>
      ),
    },
    {
      title: "Areas of the Bitter Reach",
      anchor: "freedom-day",
      sectionTitleCssName: "sectionTitleFBL",
      text: (
        <div className="fblBodyText">
          <ImageComponent
            image={map}
            caption="Bitter Reach Overview"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Aileen the dwarf gives an overview of the Bitter Reach and its locations."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          Based on Aileen's descriptions, the party learns about the areas of
          the Bitter Reach.
          <br />
          <br />
          <h2>THE SILVER COAST</h2>
          Farthest to the east lies the Silver Coast, a thin coastline populated
          by settlers and adventurers who have come to the Bitter Reach to find
          a new life, free from oppression and poverty.
          <br />
          <br />
          Those who make it to the Bitter Reach often find a miserable life
          lying in wait; they’ve come to a cold and inhospitable realm. Despite
          the harsh conditions the coastline was named the Silver Coast, since
          no one wants to admit that the new land isn’t the paradise that they
          dreamed of.
          <br />
          <br />
          Little comfort is found in the newfound freedom here, but the dream of
          one day finding treasure that will change life forever drives most of
          those who live here to keep going.
          <br />
          <br />
          The beaches of the Silver Coast consist of black volcanic sand, and
          even the small patches of soil that is found on the Silver Coast are
          black and sandy.
          <br />
          <br />
          Situated along the coast are small fishing villages, from which the
          fishermen embark onto the icy waves in search of cod, the main staple
          food. The fishing villages are also populated by hunters, who go after
          seals, Nanuiks, and Pike Whales.
          <br />
          <br />
          Sometimes adventurers visit the fishing villages, but they rarely stay
          for long. A visit to such a place usually has the dual purpose of
          refilling supplies and listening to rumors and legends from the
          villagers.
          <br />
          <br />
          <h2>THE GLACIAL SEA</h2>
          To the north, the Bitter Reach is bordered by a seemingly endless
          glacial ocean. The Silver Coast stretches some two hundred kilometers
          northward from Northfall, but finally veers west and disappears under
          the ice.
          <br />
          <br />
          Fishermen mostly move about in the open parts of the glacial sea.
          Hunters find their prey both on land and on the ice floes, which bar
          larger ships from sailing farther north.
          <br />
          <br />
          Legends say that there are icebergs so huge that adventurers have
          found entire ancient elven ruins on them, ruins that may harbor
          fabulous treasures.
          <br />
          <br />
          <h2>FALLOWMOOR</h2>
          West of the Silver Coast lies an icy tundra, a flat stretch of the
          Bitter Reach with rough winds and encroaching snow. Because of the
          inhospitable terrain the tundra is known as Fallowmoor, since no life
          is welcome there.
          <br />
          <br />
          Snowfall and storms are common, but the wind keeps the blanket of snow
          thin. The ground is mostly bare, but here and there windswept groves
          of petrified trees from forgotten ages dot the landscape.
          <br />
          <br />
          In Fallowmoor, smaller ruins and remnants from ages past can be found.
          These are more often than not already plundered by adventurers, as
          they are the closest to the coast.
          <br />
          <br />
          <h2>THE MORMA GLACIER</h2>
          The coastal tundra gives way to an enormous ice cap. Deep crevices
          crisscross the glacier. Some opened long ago but many quite recently,
          as the ice cap has mysteriously begun to melt in recent years. Hot
          springs dot the landscape, caused by volcanic activity in combination
          with the powerful magic that froze the land thousands of years ago.
          <br />
          <br />
          <h2>THE BARREN HILLS</h2>
          Farther west, the ice cap gives way to a low mountain range. This is
          where the grip of the ice hardens, the cold begins to crush hearts and
          souls. There is life in these mountains, however, life that clings to
          existence despite the never-ending winter. This area is often visited
          by adventurers, as there are ancient ruins here that are yet to be
          explored.
          <br />
          <br />
          <h2>ISED AND KELD</h2>
          Two mighty rivers, the Ised and the Keld, flow from the Barrens Hill
          down to the coast. These partially run under the ice of the Morma
          Glacier, and only after they reach Fallowmoor do they flow under the
          open sky to the sea.
          <br />
          <br />
          It is said that it is possible to follow the rivers upstream under the
          ice, to find ruins and remnants from forgotten ages here as well.
          Ancient artifacts occasionally wash down river to the coast, and it is
          therefore common for less enterprising adventurers to pan the cold
          streams for treasure. The more treasure hunters that gather in the
          same place, the more trouble there is.
          <br />
          <br />
          <h2>ICEMOOR</h2>
          Beyond the Barren Hills, the ice cap continues, hundreds of fathoms
          thick, on what is known as the Icemoor. The ice continues across this
          frozen plateau that never seems to end, and few adventurers who have
          come this far west have ever returned to the coast alive to tell the
          story.
          <br />
          <br />
          <h2>THE WHITE PEAKS</h2>
          In the end, there are only mountains. Immense peaks where the wind
          howls, the snow falls eternally, and the frozen corpses of a few lost
          adventurers are the only signs of life ever reaching this far. This is
          where the largest strongholds of the lost elven civilization were
          built, and some say they are still here, frozen and preserved. The
          legends also say that this is where the greatest treasures can be
          found, fortunes of unimaginable value. What lies beyond the White
          Peaks is shrouded in mystery. Covered in winter winds and blizzards,
          these mountains are impossible to pass, though legends tell of
          monsters and horrors beyond the mountains.
          <br />
          <br />
          <h2>THE SOUTHERN RANGE</h2>
          The Southern Range forms the border between the Bitter Reach and
          Ravenland. These mountains are insurmountable peaks that reach through
          the clouds. Only two known ways through exist: the elven Stillmist and
          the dwarven Winding Ways, a system of passages and caves that are only
          described in dwarven legends and songs. It is said that only the
          dwarves have an inkling of where the Winding Ways begin, and only the
          wisest of the kin know how to get from one end to the other.
          <br />
          <br />
          <a href="#pageTop">Back to Top</a>
          <br />
          <br />
          <small>
            Attendees: Blisster, Tulok, Ibax, Lobo, Rog-Golar, Stillak.
          </small>
          <br />
          <small>Ending Datetime: 12pm, Winterwane 45, 1165</small>
          <h5>XP</h5>
          <ul>
            <li>Session 1.0</li>
            <li>Legend: 0.0</li>
            <li>
              Lore: 1.8 (Locations of the Bitter Reach told by Aileen the
              dwarf.){" "}
            </li>
            <li>Hex: 1.0</li>
            <li>Pride: 1.0 (Lobo, Ibax)</li>
            <li>Treasure: 0.0</li>
            <li>Monster: 0.0</li>
            <li>Dark Secret: 0.0</li>
          </ul>
        </div>
      ),
    },
  ];

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/forbidden-lands/fbl-page-background-blizzard.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.backgroundWidth = "100vw";
    document.body.backgroundHeight = "100vh";
    document.body.style.backgroundSize = "cover";
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <div
            style={{
              backgroundColor: "transparent",
              fontFamily: "IM Fell Great Primer",
            }}
          >
            <div style={pageHeader}>
              <main role="main" className="inner cover text-center">
                <a href="#freedom-day" name="pageTop">
                  <img
                    src={logo}
                    style={{ maxWidth: "200px" }}
                    alt="Forbidden Lands Campaign"
                  />
                </a>
              </main>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title
                id="cardTitle-forbidden-lands"
                className="cardTitle-forbidden-lands  "
              >
                Our raiders confront treasure hunters trying to steal their gear
                and travel to the Ruins of Ruenlocke.
                <br />
                <br />
                <small>
                  ⇐ Session 05:{" "}
                  <a href="./forbidden-lands-session-05">When Stars Fall</a> ☠{" "}
                  Session 07:{" "}
                  <a href="./forbidden-lands-session-07">Throwing Bones</a> ⇒{" "}
                </small>
              </Card.Title>
              <div>
                <ContentContainer data={section1Data} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default FBLSession06;
