import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import relationships from "../../../../assets/myz/ark-mutant-relations.jpg";
import rook from "../../../../assets/myz/ark-rook-the-dog-handler.jpg";
import quietBob from "../../../../assets/myz/ark-boss-quiet-bob.jpg";
import patrick from "../../../../assets/myz/threat-helldrivers-boss-patrick.jpg";
import harley from "../../../../assets/myz/threat-helldrivers-harley.jpg";
import maxim from "../../../../assets/myz/ark-maxim-the-disloyal.jpg";
import deathworm from "../../../../assets/myz/deathworm.png";

const cardTitle = {
  paddingTop: 10,
  paddingLeft: 10,
  paddingBottom: 10,
  backgroundColor: "rgba(221, 117, 28, .7) ",
};
const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Mutant Relationships",
    text: (
      <p>

        Upon review of mutant relationships, Smoke Raven had to choose new
        PROTECT since Loud Sue died. He chose Grit who moved into his den. And
        then, since Skut's nemesis Tanner died of the pandemic, Skut chose to
        hate Rook for always messing around with Rowbert.
        <br />
      </p>
    ),
    image: {
      imagePath: relationships,
      altText: `Mutant Relationships are a fun way to drive story and stir up events.`,
      className: "imageLeft",
    },
  },
  {
    title: "",
    text: (
      <p>
        Astrina approached Jebe. Smiling wanly, remembering their one night
        together,she was clearly unhappy and bearing bad news.
        <br />
        "Jebe. I'm glad you're back safe." She pauses and looks at the ground.
        The morning you left into the Zone . . . Zane . . . he, uh . . . killed
        himself."
        <br />
        >She sits next to you. "He had wandered out of his room and feels he
        infected the Ark. And that's not all."
        <br />
        "He found out Bianca was sleeping with Skag's gang to get him and Double
        Bird food. She loved Zane. With Zane and Double-Bird both dead she feels
        cursed."
        <br />
        "That same morning, after Zane's body was found; Bianca . . . she moved
        over to Skag's area and left the IL gang. Hulagu is going to be pissed.
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
    },
  },
  {
    title: "Rook Makes A Move",
    text: (
      <p>
        As Rowert climbed into your his, freshly cleansed of rot from adventure
        at the Pure Plant, he sensed a shadow at the doorway.
        <br />
        "So storyteller: let me know if you've heard this one." Rook steppe into
        Rowbert's den, the moonlight from a dusty window hitting his stern face.
        <br />
        "There once was a bookworm who kept talking to a girl. It wasn't his
        girl so he snuck around and met her on the sly; the way cowardly
        bookworms will do."
        <br />
        He grins. "But I admire your tenacity Rowbert; I really do."
        <br />
        >"Tell you what. Pitch your efforts into the Zone Wrestling project
        tomorrow and I'll help out right beside you. Try and and get it done.
        And when it's done? You and me. First bout in the ring, alright?"
      </p>
    ),
    image: {
      imagePath: rook,
      altText: `
        Rook the Dog Handler is dating Ariana; an old frined of Rowbert's. He is Rowbert's HATED mutant relationship.`,
      className: "imageLeft",
    },
  },
  {
    title: "Other Events",
    text: (
      <p>
        Kublai has been hanging out with Elson the dog mutant. Elson told Hulagu
        that Kublai wants to go on an expedition and wondered if Hulagu wanted
        Elson encouraging him or discouraging him. Hulagu gave green light to
        Elson green-lighting Kublai going on expedition.
        <br />
        Rowbert performs "The Tale of the Trash Hawk" in the Gathering Place
        (i.e. PERFORM).
        <br />
        Berke discusses Hulagu leaving the guard down on Forcept (the assassin
        of Loud Sue) so one of Berke's men can murder Forcept and take credit to
        even things out from Hulagu's racketeering affecting Berke.
      </p>
    ),
  },
];
const section2Data = [
  {
    title: "",
    text: (
      <p>
        The part goes around to meet the bosses and try to MANIPULATE them into
        supporting the various projects.
        <br />
        <br />
        Boss Quiet Bob has stepped into the void left by the assassination of
        Boss Loud Sue. Bob has been a polarizing follower on Sue's gang for a
        few years. He is loud and inappropriate; hardly a bastion of good
        behavior. And yet, he is very clever and has memorized most of her wise
        sayings and he can imitate her profanity pretty well.
        <br />
        <br />
        Whereas Loud Sue's proclamations were spontaneous (and actually a result
        Tourette's magnified by toxic radiation), Quiet Bob is obviously working
        to mimic Sue. However, his efforts are appreciated by her followers, and
        he has always had a few of the rougher fringe followers in her gang as
        friends.
        <br />
        <br />
        He has promoted those who were his friends and demoted Loud Sue's most
        faithful. Rumors of snide attitudes and verbal abuse are already
        whispered at night.
        <ul>
          <li>
            <b>Smoke Raven visits Boss Quiet Bob to push Temple</b>: Smoke Raven
            is coy with Bob about what they found at the Pure Plant, not
            committing to whether the creatures encountered were Zone Ghouls or
            Mutants. Bob played along and suggested they have dinner some
            evening, but the Smoke Raven failed to sway Quiet Bob on the
            projects.
          </li>
          <li>
            <b>Hulagu and Rowbert visit Boss Krao to push Pigsty</b>: Krao gives
            Boss Hulagu a balanced lecture. She points out that the pandemic is
            his fault for bringing back mutants for The Zone. "This is why we
            should leave The Zone alone", she says.
            <br />
            <br />
            However, she has to admit food and water and getting low and the
            fact that they recused Lambda and Grit at least and brought back
            water from the Pure Plant is a good thing.
          </li>
          <li>
            <b>Double Bird visits Boss Berke to push Zone Wrestling</b>: Double
            Bird offers a kickback which Berke likes, but he is essentially
            unmoved.
          </li>
          <li>Skut and Jebe visit Boss Skag to push Pigsty</li>: Boss Skag
          points finger at Boss Berke for hoarding water and points out that
          since he joined forces with Boss Krao for the 1st expedition to the
          Pure Plant that despite his reputation ho actually cares about The
          People in his way.
        </ul>
        <br />
        <br />
        The Pigsty, Zone Wrestling, and Temple are all completed! The party
        chose to begin Defenses and Cropland next time.
      </p>
    ),
    image: {
      imagePath: quietBob,
      altText: ``,
      className: "imageRight",
    },
  },
];
const section3Data = [
  {
    title: "",
    text: (
      <p>
        At the crack of dawn, the Ark is awakened by the shattering roars and
        rumbles of cars and motorcycles. It's a ruckus unlike anything heard in
        many years. Over a dozen motorcycles with two riders each and several
        vehicles pull up outside the front gates of the Sunshine Plaza. The
        vehiles are armed with flamethrower turrents and at least three
        passengers on motorcycles are holding flamethrowers and shooting them
        into the air.
      </p>
    ),
    image: {
      imagePath: harley,
      altText: `
        Harley is the dangerous, psychotic girlfriend and right hand gal to Patrick, leader of the 
        Helldrivers.`,
      className: "imageLeft",
    },
  },
  {
    title: "",
    text: <p></p>,
    image: {
      imagePath: null,
      altText: ``,
      className: "imageRight",
    },
  },
  {
    title: "",
    text: (
      <p>
        Patrick gives a little speech and demands 20 grub and 20 water by the
        next morning. He is accompanied by his girlfriend Harley who sports a
        spiked bat decorated with dried blood.
        <br />
        <br />
        <citation>
          Boss Patrick is based on Stanley Baker in the classic film{" "}
          <a
            rel="noreferrer"
            href="https://www.imdb.com/title/tt0051713/"
          >
            "Hell Drivers"
          </a>
          (with Sean Connery, Patrick McGoohan, and Jill Ireland).
        </citation>
        <br />
        <br />
        Silas the Chronicler and a couple of Hulagu's men carry out the water
        for the Helldrivers the next morning. They have much more water than
        food. Patrick accepts this, but Silas gets a 6D6 scorch from the big rig
        known as The Juggernaut.
      </p>
    ),
    image: {
      imagePath: patrick,
      altText: `Patrick is the leader of the Helldrivers; a dangerous, rowdy, well-armed and mobile mutant gang.`,
      className: "imageRight",
    },
  },
  {
    title: "",
    text: <p></p>,
    image: {
      imagePath: null,
      altText: ``,
      className: "imageRight",
    },
  },
  {
    title: "",
    text: <p></p>,
    image: {
      imagePath: null,
      altText: ``,
      className: "imageRight",
    },
  },
];
const section4Data = [
  {
    title: "",
    text: (
      <p>
        Bianca tells about a possible cure in location near Elysium to get
        medicine rumored to be there. It's the old amusement park she escaped
        from . The leader is a man named Kurtz. He thinks the world is doomed
        and is hoarding medicine because the world isn't good enough to deserve
        a cure.
        <br />
        <br />
        And so our mutants head back into the Zone, woefully low on grub, to
        find a cure before the Sunshine Plaza suffers too much.
        <br />
        <br />
        Since our party is doing something for the greater good, they are
        accompanied by <b>Astrina the Resourceful</b> who wants to live up to
        her role as the odd woman out in the Order of the New Dawn and put in
        some time actually in The Zone exploring.
        <br />
        <br />
        Also, along for the ride is Maxim the Disloyal. He is named thus due to
        switching from Skag's gang to the Order of the New Dawn as their most
        "dangerous" chronicler; be sure to click his portrait for more details.
      </p>
    ),
    image: {
      imagePath: maxim,
      altText: `
        Maxim the Disloyal, Chronicler is known as the disloyal because he was once a member of Skag's gang and 
        jumped ship to help the Chroniclers. Even so, he is looked upon with a wary eye despite Krao's endorsement. 
        
        Maxim has a chisled face, talks with a wheeze from Rot, always smells like booze, and has a history of 
        being homicidal. 
        
        He scares the other Chroniclers, but then again he scares everybody so maybe that's helpful?`,
      className: "imageRight",
    },
  },
  {
    title: "Sector D07: Airplane Wreck",
    text: (
      <p>
        In Sector D07, it's practically an outdoor maze from all the foundations
        of long destroyed buildings and sewer lines. There is an airplane that
        fell from the sky and crashed into a building that looks promising for
        scrap if nothing else. But first, the party is attacked by a deathworm.
        <br />
        <br />
        They feel pretty good about their quick work on the horrific worm, and
        then four more attack! During the course of the fight, Skut is broken
        and the deathworm starts dragging him underground, tunneling almost as
        fast as a man walking. Maxim pushes his scrap rifle to save Skut and the
        gun blows up and breaks Maxim! Rowbert is doing his best with acid spit
        as the rest of the gang kills the other worms, but Skut is dragged away.
        <br />
        <br />
        They track with Jebe's hound Subu andfind Skut nearby, dropped by the
        deathworm that presumably lost its appetite from such heavy wounds.
        <br />
        <br />
        Skut rolls an 11 on the critical injury chart, so he basically dusts
        himself off and laughs. Maxim has suffered a groin hit and will have a
        harder time of things for a few days, but at least he can stay back and
        fire his gun.
      </p>
    ),
    image: {
      imagePath: deathworm,
      altText: `DEATHWORM`,
      className: "imageLeft",
    },
  },
];
const section5Data = [
  {
    title: "",
    text: (
      <div>
        <p>Next session starts morning of 03-13-0001.</p>
        <br />
        Check out Session Body Count at:{" "}
        <a href="./mutant-year-zero-anchortown-population-notable-events">
          SESSION BODY COUNT AND POPULATION
        </a>
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a href="./mutant-year-zero-anchortown-graveyard">
          Anchortown Graveyard to pay your respects
        </a>
        .
      </div>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageRight",
    },
  },
];

const MYZAnchortownSession06 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 06: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  Our mutants return home to find Quiet Bob has taken over Loud
                  Sue's gang.
                  <br />
                  Jebe gets a <b>permanent rot point</b> (1st one of campaign).
                  <br />
                  Zone projects for Pigsty, Temple, and Zone Wrestling are
                  completed
                  <br />
                  Patrick and the Helldrivers show up and demand food and water.
                  <br />
                  Mutants head back out into the zone and battle FIVE
                  deathworms.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Title style={cardTitle}>
                  Relationships, 03-10-0001{" "}
                </Card.Title>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
                <Card.Title style={cardTitle}>
                  Meet the New Boss and Projects, 03-10-0001{" "}
                </Card.Title>
                <Card.Text>
                  <ContentContainer data={section2Data} />
                </Card.Text>
                <Card.Title style={cardTitle}>
                  Helldrivers Shakedown, 03-11-0001
                </Card.Title>
                <Card.Text>
                  <ContentContainer data={section3Data} />
                </Card.Text>
                <Card.Title style={cardTitle}>
                  Let's Amuse Ourselves, 03-12-0001
                </Card.Title>
                <Card.Text>
                  <ContentContainer data={section4Data} />
                </Card.Text>
                <Card.Title style={cardTitle}>Executive Summary</Card.Title>
                <Card.Text>
                  <ContentContainer data={section5Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession06;
