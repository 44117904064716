import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import "../../index.css";
import { ContentContainer } from "./SessionSection";
import blades from "../../assets/game-rundown/blades-in-the-dark.webp";
import bladerunner from "../../assets/game-rundown/bladerunner.webp";
import coriolis from "../../assets/game-rundown/coriolis.webp";
import forbiddenLands from "../../assets/game-rundown/forbidden-lands.webp";
import frontierScum from "../../assets/game-rundown/frontier-scum.webp";
import kidsOnBikes from "../../assets/game-rundown/kids-on-bikes.webp";
import morkBorg from "../../assets/game-rundown/mork-borg.webp";
import myz from "../../assets/game-rundown/mutant-year-zero.webp";
import oldGods from "../../assets/game-rundown/old-gods.webp";
import vaesen from "../../assets/game-rundown/vaesen.webp";

const cardTitle = {
  paddingTop: 10,
  paddingLeft: 10,
  paddingBottom: 10,
  backgroundColor: "rgba(156, 5, 5, 1) ",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "BLADES IN THE DARK",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <i>
          You're scoundrels in the foggy city of Doskvol, a haunted
          Victorian-era city surrounded by a wall of lightning powered by demon
          blood. You and your crew try to make scores, take over turf, and gain
          reuptation.
        </i>
        <br />
        <br />
        It's own game system. Heavy story emphasis but still crunchy. No rounds
        of combat and no hex crawl. Rather, you roll to resolve situations and
        chain together with fiction telling. Characters and the gang have stats,
        wounds, reputation, heat, and the Gang levels up just like PCs.
        <br />
        <br />
        This is a very different game and I am REALLY excited to run it. More
        info:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./blades-in-the-dark-overview"
        >
          BiTD Overview
        </a>
        <br />
        <br />
      </div>
    ),
    image: {
      imagePath: blades,
      altText: `Blades in the Dark`,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "BLADERUNNER",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <i>
          This is the BLADE RUNNER roleplaying game - a neon-noir wonderland
          that'll take your breath away. One way or another. An evocative world
          of conflicts and contrasts that dares to ask the hard questions and
          investigate the powers of empathy, the poisons of fear, and the
          burdens of being human during inhumane times. An iconic and
          unforgiving playground of endless possibilities that picks you up,
          slaps you in the face, and tells you to wake up. Time to live. Or time
          to die.
        </i>
        <br />
        <br />
        Year Zero system with sigificant tweak (i.e. stacked dice). I have the
        first of three sourcebooks for campaign prepped. It's based on movies
        completely and will be very rich for fans of the film. After the first
        module (3-6 sessions), we would switch to another game until the next
        installment of the official campaign is released.
        <br />
        <br />
      </div>
    ),
    image: {
      imagePath: bladerunner,
      altText: `Bladerunn`,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "CORIOLIS",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <i>
          Welcome to Coriolis - The Third Horizon! In this role-playing game,
          you take on the role of adventurers, traveling the stars.
        </i>
        <br />
        <br />
        Year Zero game. Huge, rich world with many planets, an official
        three-part campaign, tons of third-party materials. Party has a starship
        which is a mobile headquarters that can be manned in battles and
        modified. This world has a LOT of lore and has been around a long time.
        <br />
        <br />
        <br />
      </div>
    ),
    image: {
      imagePath: coriolis,
      altText: `Coriolis`,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "FORBIDDEN LANDS",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <i>
          Welcome to Forbidden Lands. In this tabletop roleplaying game, you are
          not heroes sent on missions dictated by others - instead, you are
          raiders and rogues bent on making your own mark on a cursed world. You
          will wander the wild lands, discover lost tombs, fight monsters, and,
          if you live long enough, build your own stronghold to defend. During
          your adventures, you will uncover the secrets of dark powers lurking
          in the shadows and, in the end, you can be the ones to decide the fate
          of the Forbidden Lands.
        </i>
        <br />
        <br />
        Year Zero engine. Campaign starts in the Bitter Reach and is prepped
        through multiple sourcebooks. This is a big one and I have it prepped to
        the Nth degree!
        <br />
        <br />
      </div>
    ),
    image: {
      imagePath: forbiddenLands,
      altText: `Forbidden Lands`,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "FRONTIER SCUM",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <i>
          FRONTIER SCUM is a rules-lite Acid Western roleplaying game. An
          auto-destructive, violent and LSD-infused take on Spaghetti Westerns,
          about wanted outlaws making their mark on an unreal Lost Frontier.
          Probably getting shot before having the chance to shoot. A
          rough-and-tumble world of insatiable greed where scum live one slug
          from the grave. Inspired by such things as El Topo, Dead Man, and Bone
          Tomahawk.
        </i>
        <br />
        <br />
        Mork Borg hack. So, it's similar rules but a few differences and of
        course a different tone with the Western emphasis.
        <br />
        <br />
        I've created the hex-crawl map for the Acid frontier and you can check
        it out on the{" "}
        <a
          href="https://smokeraven.itch.io/lost-frontier-map"
          rel="noreferrer"
          target="_blank"
        >
          SmokeRaven ItchIO
        </a>
        .
        <br />
        <br />
      </div>
    ),
    image: {
      imagePath: frontierScum,
      altText: `Frontier Scum`,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "KIDS ON BIKES",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <i>
          The door to the old house creaks open, the rust on the hinges groaning
          as you see the dust floating like spores in the air inside. By the
          faint light of your cheap flashlights, you see the stairs to the upper
          floor, its railings gnarled and broken like crooked teeth. Their curve
          makes the stairs seem almost like a hungry grin, and you wonder if
          their age will support your weight. Still, you must go in. The only
          question is who will go first?
          <br />
          <br />
          In Kids on Bikes, you'll take on the roles of everyday people
          grappling with strange, terrifying, and exceptionally powerful forces
          that they cannot defeat, control, or even fully understand. The only
          way to face them is to work together, use your strengths, and know
          when you just have to run as fast as you can.
        </i>
        <br />
        <br />
        This is a game ROB would run. It's very story-based and is it's own
        system. This game reminds me of Blades in the Dark in terms of
        fiction-building and strong player agency. However, in Kids on Bikes the
        players build the campaign world itself as well.
        <br />
        <br />
      </div>
    ),
    image: {
      imagePath: kidsOnBikes,
      altText: `Kids on Bikes`,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "MORK BORG",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <i>
          MÖRK BORG is a pitch-black apocalyptic fantasy RPG about lost souls
          and fools seeking redemption, forgiveness or the last remaining riches
          in a bleak and dying world. Who are you? The tomb-robber with silver
          glittering between cracked fingernails? The mystic who would bend the
          world's heart away from it's inevitable end?
        </i>
        <br />
        <br />
        Fun, fast, and crazy. Wild character classes like Fanged Deserter or
        Forlorn Philosopher wander the Dying Lands trying to avoid or create
        heresy, unravel the Calendar of Necrubel, and generally battle or create
        darkness.
        <br />
        <br />
        Check out our ongoing Mork Borg campaign named{" "}
        <a
          href="./mork-borg-session-01-malum-mortis"
          rel="noreferrer"
          target="_blank"
        >
          Malum Mortis
        </a>
        .
        <br />
        <br />
      </div>
    ),
    image: {
      imagePath: morkBorg,
      altText: `Mork Borg`,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "MUTANT YEAR ZERO",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <i>
          Welcome to Mutant: Year Zero. In this roleplaying game you play
          mutated human beings in a fallen world. On your shoulders rests the
          greatest responsibility of all: to save the last glimmer of human
          civilization from total annihilation.
        </i>
        <br />
        <br />
        Year Zero system. The game that woke me up out of decades of DnD
        sleepwalking. <br />
        <br />
        Our &nbsp;
        <a
          href="./mutant-year-zero-session-01-anchortown"
          rel="noreferrer"
          target="_blank"
        >
          campaign Anchortown
        </a>{" "}
        draws to a close, so check it out!
        <br />
        <br />
        <br />
      </div>
    ),
    image: {
      imagePath: myz,
      altText: `Mutant Year Zero`,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "OLD GODS OF APPALACHIA",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <i>
          Welcome, family, to a game and a place like no other. The Old Gods of
          Appalachia Roleplaying Game is set in an alternate Appalachia, a place
          filled with creatures of the Green, the Inner Dark, and things even
          more ancient. Old Gods is a captivating eldritch horror game based on
          the award-winning podcast.
        </i>
        <br />
        <br />
        Old Gods uses the Cypher system by Monte Cook (former DnD champion who
        formed his own company awhile back). The rule system is an interesting
        mash-up of DnD-like rules but influenced by OSR, rules-light games such
        as Year Zero and Mork Borg. Cyphers are one-time use items found
        frequently in the game so characters have different options and
        interesting things they can do in addition to their core abilities from
        session to session.
        <br />
        <br />
      </div>
    ),
    image: {
      imagePath: oldGods,
      altText: `Old Gods of Appalachia`,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "VAESEN",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <i>
          This is a game of investigating the strange and the supernatural, from
          ghosts to fae folk (collectively known as 'Vaesen'). In a rational
          1800s world of metal and steam, ancient traditions are being
          forgotten, and the entities they were designed to honor are not happy
          about it. Players take on the role of everyday individuals who, thanks
          to some past trauma, can see into this spirit world, revealing
          creatures invisible to most. You’ll need to use your second sight to
          intervene before they cause havoc.
        </i>
        <br />
        <br />
        Year Zero game. It has mechanics that favor mission-based excursions
        from headquarters to hunt cryptids or fae folk and solve mysteries.
        <br />
        <br />I have all the sourcebooks and we could easily fold-in USA
        missions based on our own unique cryptids.
        <br />
        <br />
      </div>
    ),
    image: {
      imagePath: vaesen,
      altText: `Vaesen`,
      className: "imageLeft",
      imageCaption: "",
    },
  },
];

class GameRundown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "BEGINNING NAME",
      text: "Click button for some fun.",
      testValue: "test value",
      selectedMutation: null,
    };
  }

  componentWillMount() {
    document.body.style.backgroundImage = `url(${require("../../assets/blades-in-the-dark/mood-doskvol_street-scene-01.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = null;
  }

  render() {
    return (
      <div>
        <Container>
          <Row>
            <Col>
              <Card style={cardStyle}>
                <Card.Title style={cardTitle}>Game Rundown</Card.Title>
                <Card.Body>
                  <Card.Text>
                    <font color="red">Blades in the Dark</font>: you play a gang
                    of criminals in 1870s city Doskvol filled with ghosts, demon
                    blood, and politics using the Blades system.
                    <br />
                    <br />
                    <font color="red">Bladerunner</font>: you play a bladerunner
                    (replicant or human) in a trilogy sourcebook campaign very
                    true to the film world using Year Zero system.
                    <br />
                    <br />
                    <font color="red">Coriolis</font>: you play starfaring crew
                    with a starship in a galaxy filled with lore, fanatiscm, and
                    deep campaign using Year Zero system.
                    <br />
                    <br />
                    <font color="red">Forbidden Lands</font>: you play fantasy
                    characters in rich world spanning multiple sourcebooks using
                    Year Zero system.
                    <br />
                    <br />
                    <font color="red">Frontier Scum</font>: an LSD-infused take
                    on the spaghetti western using a hack of the Mork Borg
                    system.
                    <br />
                    <br />
                    <font color="red">Kids on Bikes</font>: you play kids on
                    bikes unearthing secrets and mystery in your small town
                    using Kids on Bikes system.
                    <br />
                    <br />
                    <font color="red">Mork Borg</font>: you play heretics in the
                    Dying Lands with the Calendar of Necrubel counting down the
                    end of the world using Mork Borg system.
                    <br />
                    <br />
                    <font color="red">Mutant Year Zero</font>: you play mutants
                    living in an Ark trying to explore and survive the
                    postapocalypse using Year Zero system.
                    <br />
                    <br />
                    <font color="red">Old Gods of Appalachia</font>: you play
                    ordinary folk in an eldrich horror alternate Appalachia
                    using the Cypher system.
                    <br />
                    <br />
                    <font color="red">Vaesen</font>: you play Victorian-era
                    investigators solving mysteries around supernatural beings
                    and fae folk using Year Zero system.
                    <br />
                    <br />
                    <ContentContainer data={section1Data} />
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default GameRundown;
