import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import kanthaka from "../../../../assets/myz/token2_kanthaka.png";
import zoneExploreSession34 from "../../../../assets/myz/zone-explore-session-34.jpg";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Bookkeeping",
    text: (
      <p>
        05-26-0001:
        <br />
        <br />
        <ul>
          <li>
            PARTY ROLL CALL: Hulagu, Bayazid, Gladhand, Chromicus, Smoke Raven,
            Double Bird.
          </li>
          <li>
            BITTERBEASTS: three eggs hatched and at least one beast became
            trained.
          </li>
          <li>
            PROJECT ROLLS: zone expedition was a bust, but no lives lost. They
            reported some kind of irradiated humanoids coming out of the Elysium
            Fields sector that the party nuked back in{" "}
            <a
              href="./mutant-year-zero-session-08-anchortown"
              rel="noreferrer"
              target="_blank"
            >
              Session 8
            </a>
            . Work on the Foundry and the Distillery continues without a hitch
            now that the world worm and earthquakes are gone.
          </li>
          <li>
            PURE PLANT PATROL: mutants and humans argue. Only 4 water this
            shift.
          </li>
          <li>
            ARK REPUTATION EFFECT: Humans arrive to join Ark. SIX HUMANS ADDED.
          </li>
          <li>
            ARK THREAT: Ark Threat roll results in Stalker's telling about an
            increased about of humanoid animal creatures in the zone that are
            intelligent (A NEW KIND OF BEAST event). It sounds like the
            chimpanzee and Elson that the party met. What could be behind the
            increase in sentient animal creatures?
          </li>
          <li>
            MISC: turned in Welcome Back Kotter magazine, Boxing Gloves, and
            Canadian Mountie movie to the Order of the New Dawn. The Culture DEV
            level goes up to 29.
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: kanthaka,
      altText: `Kanthaka RIP. Killed by ACID RAIN`,
      className: "imageLeft",
      imageCaption: `Kanthaka RIP`,
    },
  },
  {
    title: "Rust Castle or Bust",
    text: (
      <p>
        The IL gang heads back to the Sunshine Plaza with Big Barbro and Olle
        the Hat on board the Summer of Love battle bus. The reption at the ark
        is warm and excited. Big Barbro and Olle and a little surprised at the
        open, inclusive atmosphere between humans and mutants. After doing the
        bitterbeast rolls, Gladhand makes good on his deal and gives Big Barbro
        and Olle two newly hatched bitterbeast babies. They say their goodbyes
        and head back to The Homestead.
        <br />
        <br />
        Gladhand explains Sylvia was a family member of The Homestead and that
        "they are allies, but they have a different code . . . for what to eat."
        Boss Krao doesn't pick up on the hint and Danova has to lean over and
        explain that the newest allies of the Sunshine Plaza are cannibal
        humans.
        <br />
        <br />
        Hulagu racketeers, Gladhand makes a deal, equipment is repaired, and the
        Summer of Love battle bus is loaded with supplies, especially booze
        since it gobbles two units of booze per sector.
        <br />
        <br />
        The IL gang heads into the zone determined to travel as quickly as
        possible to the Rust Castle. They make great time through previously
        explored zones and even in new zones. Some highlights are:
        <ul>
          <li>
            Sector C11: they meet <b>scrap oracle Inua</b>. He wears a bone mask
            with wooden horns and a parka at all times. He had scrap for trade
            and told a couple of fortunes. He has never visited the Silver Egg
            Oracle, but he tells Smoke Raven that there is some kind of
            connection. The party knows the oracle or someone close to the
            oracle. Gladhand, upon learning Inua is going to the Sunshine Plaza
            to trade, asks him to stop in and check on Fatima and bless her
            pregnancy.
          </li>
          <li>
            Sector D19: ACID RAIN drives party toward Gas Station. It's a small
            building and the bitterbeasts are forced to flee the acid rain and
            fend for themselves. The riders run into the gas station and the
            rest of the party stays on the Summer of Love. However, there are
            five desperate, hungry CANNIBALS in the gas station and a fight
            breaks out. Hulagu suffers a permanent attribute loss from mutation
            misfire and now has an AGILITY of two. However, he also gains a
            FOURTH MUTATION (Acid Spit). It's safe to say that Hulagu would have
            been broken if not for mutation powers absorbing damage.
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: zoneExploreSession34,
      altText: `The IL Gang heading straight toward the Rust Castle at the farthest East reaches of the map.`,
      className: "imageLeft",
      imageCaption: "Heading to Rust Castle",
    },
  },

  {
    title: "Wrapup",
    text: (
      <p>
        Next session will start on in-game morning of 05-28-2021 with party in
        Sector D19, ready to continue toward the Rust Castle.
        <br />
        <br />
        Everyone will take a point of rot in the morning and we have to make
        sure healing is handled correctly. Mutation points and 3 XP need to be
        awarded to each PC.
        <br />
        <br />
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        .
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
];

const MYZAnchortownSession34 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 34: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  The IL gang makes a pit stop at the Sunshine Plaza, then heads
                  out toward the Rust Castle on the farthest eastern reaches of
                  the zone map.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                {/*<Card.Title style={cardTitle}>Shootout at Burger Jim's, 03-27-0001 - 03-27-0001</Card.Title>*/}
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession34;
