import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../assets/myz/myz-section-divider.jpg";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import { useEffect } from "react";

import yorrick from "../../../assets/myz/yorrick.jpg";

const cardTitle = {
  paddingTop: 10,
  paddingLeft: 10,
  paddingBottom: 10,
  backgroundColor: "rgba(221, 117, 28, .7) ",
};
const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Why?",
    text: (
      <div>
        Inspired by reading Vaesen, we decided to try adding an explicit scene
        mechanic for when the mutants are at the Ark. <br />
        Scenes are designed to give more player agency to create or develop
        story components in the game without waiting to react to events the GM
        throws at them (although there are still those of course).
        <br />
        <br />
        The Scene allows each player to take center stage and show the group
        something about what their character values and highlight a little piece
        of what they do in their spare time or the pursuits that motivate them.
        <br />
        It also allows the player to have some control over events occuring that
        might relate to the NPC they hate, the NPC they want to protect, or even
        their big dream.
      </div>
    ),
    image: {
      imagePath: yorrick,
      altText: `You're Rick? I thought you'd be taller.`,
      className: "imageLeft",
      imageCaption: "Alas!",
    },
  },
  {
    title: "How?",
    text: (
      <div>
        Ask your players to consider preparing a SCENE for next session in the
        Ark. Scenes are not mandatory.
        <br />
        <br />
        Anatomy of a scene:
        <br />
        <br />
        <ul>
          <li>Scene is simple and short.</li>
          <li>Scene has an intended goal.</li>
          <li>
            Scene includes statement of how character will try and achieve
            intended goal.
          </li>
          <li>
            Scene includes suggestion for how to determine outcome of the scene
            (often a dice roll).
          </li>
          <li>
            Scene illuminates character and/or their relationships with other
            PCs or NPCs, or their big dream.
          </li>
          <li>
            Scene has ideas for outcome if character fails to achieve the
            intended goal.
          </li>
        </ul>
      </div>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
    },
  },
  {
    title: "Show Me (example scenes)",
    text: (
      <div>
        Let's say I am running character named Danova as a player. When the GM
        calls out for scenes, I might say:
        <br />
        <br />
        "Danova the Envious is going to try and get Liam to fall in love with
        her. She flirts with him off and on, then shows up in his bed one night
        in a tattered Hello Kitty tee shirt she traded for from Jabba the Fixer.
        I think this might be a MANIPULATE roll. If I fail I might never get
        Liam to fall for me again."
        <br />
        <br />
        GM says yep, let's make it an opposed MANIPULATE roll. So, rolls are
        made and Danova is successful.
        <br />
        <br />
        The outcome is that Liam lets her stay in the bed. GM asks the player
        "what word or short phrase describes the kind of night in bed it was?"
        to give him the player chance something like "loving", "talking and
        laughing all night", or "brown chicken brown cow".
        <br />
        <br />
        If the roll failed, the GM will ask the player "what went wrong?" and
        then accept explanation with or without modifications to determine the
        final failed outcome. In this case, maybe Liam asked Danova to leave
        kindly, blew his top screamed with anger, or mocked her and spread
        gossip the day, and so on.
        <br />
        <br />
        Some other ideas:
        <br />
        <br />
        "Xena wants to protect Benji as always. <br />
        One night Xena is walking with Benji around the Plaza.
        <br />A couple of thugs from so-and-so's gang start to harass Benji and
        it starts to get physical with some shoving.
        <br />I think Xena would try to INTIMIDATE the thugs.
        <br />
        Failure might mean they attack us or run away to get others to hunt us
        down."
        <br />
        <br />
        "Bolo wants to talk to Boss Krao about the problem the Order had with
        the water filters.
        <br />
        He wants to see if he can help get them working properly or come up with
        a way to make access to the filters harder for unauthorized mutants.
        <br />
        Since Bolo is a gearhead I think he would spend scrap and try to
        JURY-RIG something like a lock to get to the roof area where the filters
        are.
        <br />
        If he fails, maybe the lock is faulty and failure to help comes out
        later, or maybe someone convinces Boss Krao that my intensions are
        suspect because of XYZ (some specific earlier event in the campaign),
        and she fires me from the job."
        <br />
        <br />
        And so on . . . again, we do not make scenes mandatory. They are just
        another way to make sure that in addition to regular events and threats,
        that the players have a chance to try something specific to encourage
        roleplay, expand their characters, and influence the game world even
        more. It also encourages the GM to fold in the events in an open,
        emergent way so everyone is building the game together.
      </div>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageRight",
    },
  },
];

const MYZScenes = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">Mutant Year Zero Scenes</h1>
                <div className="lead leadExtra">
                  Scenes are a light mechanic for ensuring player agency in
                  influencing the game and giving them a moment to put their
                  character center stage to show off.
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Title style={cardTitle}>Scenes</Card.Title>
                <ContentContainer data={section1Data} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZScenes;
