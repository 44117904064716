import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import logo from "../../../../src/assets/kids-on-bikes/logo-kids-on-bikes.webp";
import sectionDivider from "../../../../src/assets/kids-on-bikes/kids-on-bikes-page-banner.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";
import "./kids-on-bikes-styles.scss";

import rock from "../../../../src/assets/kids-on-bikes/session01/turnaround-rock-island.webp";
import school from "../../../../src/assets/kids-on-bikes/session01/turnaround-rock-combined-school.webp";
import geoduck from "../../../../src/assets/kids-on-bikes/session01/geoduck-inn.webp";
import felix from "../../../../src/assets/kids-on-bikes/session01/npc-felix-waites.webp";
import cover from "../../../../src/assets/kids-on-bikes/session01/session-01-cover.webp";
import rodney from "../../../../src/assets/kids-on-bikes/session01/npc-rodney-green.webp";
import billy from "../../../../src/assets/kids-on-bikes/session01/npc-billy-porder.webp";
import christopher from "../../../../src/assets/kids-on-bikes/session01/npc-christopher-liu.webp";
import harry from "../../../../src/assets/kids-on-bikes/session01/npc-harry-darkstar.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "1",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Carter One",
  backgroundColor: "rgba(0, 0, 0, 1) ",
  color: "white",
  marginBottom: "15px",
};

const KOBTurnaroundRockSession01 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  const section1Data = [
    {
      title: "BACK TO SCHOOL",
      sectionTitleCssName: "sectionTitleKOB",
      text: (
        <div className="kidsOnBikesBodyText">
          <ImageComponent
            image={cover}
            caption="Session 01"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Ajax, Ariadne, and Sheldon search for a missing boy scout, hear
            the story of the wendigo, and come face to face with a ghost."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <ImageComponent
            image={school}
            caption="School"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="Turnaround Rock Combined School."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          Before we started our session,{" "}
          <a
            href="https://www.roblecrone.com/"
            rel="noreferrer"
            target="_blank"
          >
            CobraRex
          </a>
          , our GM, asked each player to choose the neighborhood where their
          character lived and why. They weren't limited to neighborhoods on the
          map, as those were just representative and there could be more
          neighborhoods.
          <br />
          <br />
          Because his dad is absent and his single mother works at the bar of
          the Geoduck Inn downtown, Ajax lives with her in an SRO (single
          residence occupancy) apartment above The Geoduck Inn. He has a certain
          streetwise quality that comes, in part, from having seen and dealt
          with adults compromised by alcohol in the Geoduck.
          <br />
          <br />
          Sheldon lives in Talbot Lake neighborhood (which was added to the map)
          near Talbot Memorial Hospital where his mother works.
          <br />
          <br />
          Ariadne lives in the Coastal Nose neighborhood, an upper middle-class
          single-road access area known for its cliffs and well-off residents.
          Ariadne's parents (Kreeger and Kelly Vale) are both doctors at the
          Memorial Hospital.
          <hr className="hrKidsOnBikes" />
          Welcome to Turnaround Rock, a sleepy little town on the Oregon coast,
          about 80 miles north of the California border. Here generations of
          families work the sea, a hard life made more difficult by The Rock, a
          large rocky island which keeps larger boats and ships from coming into
          the harbor. It is Monday, April 10, early in the Spring/Summer fishing
          season, and the kids are just coming back from Spring Break. The
          Winter Rockfish, Lingcod, and Crab season hasn't been great, and there
          has been some scuttle around the town about it being the 100-year
          anniversary of the Cursed Year of Turnaround Rock. 1889 will always be
          infamous here, and the organization known as The Fishermen are wary
          about speaking too much about it for fear of bringing on bad luck.
          <br />
          <br />
          <ImageComponent
            image={billy}
            caption="Billy Porder"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="Billy Porder, boy scout who was missing but came back to school."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          Our story begins with the kids returning to school from Spring Break
          on Monday, April 10th, 1989. When asked how they spent spring break,
          the kids said:
          <br />
          <b>Sheldon</b>: visited the Qua-toh-mah village site and looked for
          arrowheads and read down by the beach there. While out messing about,
          he ran into the boy scout troop that was camping on the final weekend
          of Spring Break.
          <br />
          <b>Ariadne</b>: went to Disneyland for the week to see Captain Eo
          ("like only three years after it opened jeez"). She went with her Aunt
          Felicia because her parents were too busy to take her.
          <br />
          <b>Ajax</b>: worked with Abner "the geoduck man" to collect the
          mammoth mollusk for Donald's restaurant and earn pocket change. This
          was down by Lower Tooth Beach where he saw Sheldon biking back from
          the Qua-to-mah site and stepped in when he was being bullied by older
          kid, Jack Melvin. Ajax sports a black eye on his return to school, an
          injury he treated by smoking some weed he had scored from a patron of
          The Geoduck Inn over the break.
          <br />
          <br />
          <ImageComponent
            image={rodney}
            caption="Rodney Green"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Rodney Green; a boy scout who is swee on Ariadne but did not show up to school after springt break."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          During that first day back at school after spring break, Ariadne
          notices that Rodney Green, who usually bugged her constantly, had not
          been around for the morning classes. In fact, it became quickly
          apparent to everyone that all the kids in the boy scouts were not at
          school this first day back.
          <br />
          <br />
          Ariadne, Ajax, and Sheldon share the same final class of the day in
          computer lab at the Turnaround Rock Combined School. Following an
          announcement from teachers earlier in the day that boy scouts missing
          from morning classes had been temporarily lost on a camping trip but
          had returned safely, they are surprised when boy scout Billy Porder
          comes to this final class of the day. He sits at the desk between
          Sheldon and Ajax, with Ariadne behind him.
          <br />
          <br />
          "Where's Rodney?", demands Ariadne. "Did he come back too?" Billy
          assures her that Rodney had made it back home with the scouts near the
          end of the school day, but that he probably wouldn't be coming to his
          final class. A stressed Billy decided he needed the familiarity of
          school, even if only for one class, and he narrates the misadventure
          of the scouts. While camping south of the Qua-to-mah site, they had
          heard an eerie sound in the night. Frightened by the sound, Billy and
          many of the other scouts had wanted to hunker down in their camp. But
          the scouts emboldened one another and headed out into the woods where
          they got turned around, felt as if they weren't even in the same area,
          and wandered lost throughout the night, unable to make it back to
          camp. Billy sounds confused about what had happened. Ariadne jokes
          that maybe weed was thrown in the campfire to make the scouts high.
          This is a dig at Ajax, but it falls flat as Billy is nearly tearful
          and clearly traumatized. Billy gulps then blurts out that "We lost
          Christopher Liu. He's still out there. They're still searching for
          him."
        </div>
      ),
    },
    {
      title: "Searching for Christopher",
      sectionTitleCssName: "sectionTitleKOB",
      text: (
        <div className="kidsOnBikesBodyText">
          <ImageComponent
            image={christopher}
            caption="Christopher Liu"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Christopher Liu; a missing boy scout who did not return with the others to school."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          It is decided that the kids will bike out to the Qua-to-mah site and
          look for Christopher. Sheldon tells the other that while there are a
          lot of rumors about the place, it's safe, and he'll show them. Billy
          Porder declines to join them.
          <br />
          <br />
          As the kids pedal their bikes through the upper Marina, Harry Darkstar
          shouts out a howdy. Ajax and Sheldon stop to talk to Harry while
          Ariadne rides in restless circles. Harry is a boat mechanic; a
          friendly soul. After letting Ajax know the term "indian" is not
          preferred and "First People" is better, Ajax declares he will call him
          "First Harry".
          <br />
          <br />
          Sheldon tells Harry the three of them are seaching for Christopher
          Liu. Harry talks about the land that Turnaround Rock sits on. "The
          legends say the village of the Qua-toh-mah site was destroyed by a
          wendigo. A wendigo is a creature that used to be a man but becomes
          wendigo from corrupt practices, like cannibalism." The kids ask if
          Harry has ever eaten someone and he laughs it off. "But, this land
          doesn't want most people here. You either attune to the land or get
          pushed away."
          <br />
          <br />
          Harry notices Ariadne has stopped her bike and is wandering around the
          boat parts and work benches. She charms him (with our first skill
          check and an exploding roll at that!) so he is not angry. He even
          moves quickly to mop up some oil about to drip on Ariadne so it won't
          soil her pretty dress she was talking about earlier. Not so odd
          except: Harry seemed to move so quickly it's almost as if he just
          appeared next to her . . . certainly too fast for the old man he
          appears to be.
          <br />
          <br />
          "I know your father" Harry tells Sheldon. Sheldon is taken aback at
          the mention of his absent father. He covers his embarrassment by
          asking sarcastically, "what happened to him? Did the land push him
          away?" Harry answers, “what we do with our fear is what makes it
          become courage.”
          <br />
          <br />
          Harry talks about how a talisman from the land can help if things
          become dark or bleak. He shows the kids a simple rock that he says is
          his talisman. Ajax is fascinated and begins to imagine what sort of
          talisman he might be able to find.
          <ImageComponent
            image={harry}
            caption="Harry Darkstar"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Harry Darkstar: a boat mechanic at the upper marina."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <br />
          <br />
          As the kids continue their ride they encounter a hubbub of people
          pointing to the woods at the edge of the city square. A man stumbles
          out of the brush, covered in blood, stumbling, seemingly confused
          "Hey, that's Felix Waites, owner of the cannery", someone says.
          <br />
          <br />
          A crowd gathers around Felix, and the kids ride up and ask if he is
          hurt. He seems very confused and unable to explain what has happened
          or if it's even his own blood on him. There are murmurs in the crowd
          about the old rumor that Felix has something against Turnaround Rock
          and means the town harm. A man heads off to the payphone, and Ariadne
          bikes near enough to hear him calling the sheriff. Very soon, they
          hear the distinct sirens of both a police car and an ambulance.
          Ariadne gets a wild idea that "whatever took Christopher" is at the
          cannery RIGHT NOW!! She pops a wheelie and jumps her bike off the edge
          of a fountain. Ajax quickly joines her, while Sheldon yells something
          about the Qua-toh-ma site before taking off after them.
          <br />
          <br />
          The kids book it hardcore toward the cannery. Cannery Row is in a
          state of transition. What used to be many buildings taking care of the
          fish processing and cannery needs is now a series of dilapidated
          buildings or buildings in the process of demolition, all leading down
          the road to an already cleared area where the huge and much newer
          processing facility now stands. The main building and its
          out-buildings are surrounded by a fence. As a truck arrives and the
          gate opens, Sheldon gets in using his UNASSUMING ability. He is aided
          by the foibles of Ariadne, who fails to hooky-bob on the back bumper
          of the truck. Ajax succeeds in hooky-bobbing into the facility.
          Ariadne's failure and the way she makes a show of her skinned knee
          helps distract the truck driver and the guard just inside the fence.
          Sheldon slips in easily and unnoticed.
          <br />
          <br />
          As Ajax and Sheldon sneak around the cannery, Ariadne tries to charm a
          guard near the fence by asking for help for her knee. She gets the
          guard to go inside to find a first aid kit, giving the others the
          opportunity to attempt to get into the building.
          <br />
          <br />
          Sheldon manages to get into a door on the left side of the building
          while Ajax must rush back behind the bushes as the guard reappears
          with alcohol wipes and band-aids for Ariadne. At the same time,
          another truck approaches from the road outside the facility, and Ajax
          is in danger of being seen by that driver. Finally, as Ariadne
          continues to distract the guard, Ajax sneaks in to join Sheldon. The
          two teen boys don the rain gear just as two cannery workers head out
          for a smoke break. Despite their small size they try lower voices and
          strategic hood tricks to try and pass as actual workers such as Ajax
          mumbling "another day, another dollar" as he tries to walk past the
          workers. But no dice . . . the kids are grabbed. The workers exit the
          building to talk to the security guard, who quickly concludes that
          Ariadne is part of this incursion. The guard goes through the gate,
          now temporarily open to allow entrance to the second truck, and nabs
          Ariadne.
          <br />
          <br />
          <ImageComponent
            image={felix}
            caption="Felix Waites"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Felix Waites: owner of the town cannery. Rumors say he does not have the interests of Turaround Rock in his heart."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          Ariadne yelps about lawsuits and "don't touch me.” All of the kids are
          taken to the security office where the security supervisor tells them
          he'll have to call their parents. One of the kids says they were only
          there because something happened to Felix Waites and that the sheriff
          had shown up, and the supervisor asks why they didn't just say that to
          the guard. The supervisor calls the sheriff first to ask about Felix.
          He doesn't mention the kids. He then calls their parents, leaving a
          message on Ariadne's answering machine, talking to Sheldon's mom, and
          leaving a message with one of the bar staff for Ajax's mom.
          <br />
          <br />
          Set free, the kids collect their bikes and hurry to the area where the
          scouts had been camping and then to the Quah-toh-mah site before dark.
          <br />
          <br />
          METATALK: fiction-first gaming, and Kids on Bikes, was new to all
          three players. While we may have taken a detour from the expected
          "one-shot" investigation path, we felt we bonded as a group from the
          misadventure, we learned more about the rules, and who knows . . .
          Ariadne's insistence that "they're hiding something" at cannery could
          play out later.
        </div>
      ),
    },
    {
      title: "The Quah-toh-mah Site",
      sectionTitleCssName: "sectionTitleKOB",
      text: (
        <div className="kidsOnBikesBodyText">
          <ImageComponent
            image={rock}
            caption="The Rock"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="The Rock; name sake of Turnaround Rock and the its famous lighthouse."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          With twilight threatening, the kids arrive at the campground. Ajax's
          SKILLED AT BRAINS allows him to quickly find Christopher Liu's wallet.
          In the wallet is a picture of Christopher with his sister and parents.
          Ajax holds onto the wallet, thinking "this could be my talisman".
          Sheldon saysm "C'mon we have to get to the Quah-toh-mah site before
          dark. There's lots of stories and rumors about that place, but I'm
          going to show you that it's safe.”
          <br />
          <br />
          The touristy part of the Quah-toh-mah site is simply Forest Service
          cabin and a sample reconstruction of traditional native dwelling.
          Beyond that is the actual site itself, a level jut of land that has
          some minor remnants of the old village, but which is otherwise mostly
          unremarkable. From the site, and especially at the ocean's edge, there
          is a clear view of The Rock with its steps leading up to the
          lighthouse. Of course, The Rock can be seen from most places in
          Turnaround Rock.
          <br />
          <br />
          <ImageComponent
            image={geoduck}
            caption="Geoduck Inn"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="The Geoduck Inn: a bar in downtown Turnaround Rock, OR. Ajax lives above it in an apartment with his mom."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          Determined to find Christopher, the kids keep searching even after the
          ranger leaves. As night falls, Ariadne suggests camping out, though
          they do have school the next day. and night falls. They eventually
          find a shallow cave where rocks meet the sea. Inside, Ajax finds a
          sash that he almost mistakes for the sea moss that alerts them that
          the cave sometimes fills with the tide. Ajax examines the sash,
          finding that it is clearly a boy scout sash with the initials “CL”
          sewn into the inside at the bottom.
          <br />
          <br />
          As the kids chatter about the sash, they hear an unearthly screeching
          howl from somewhere outside the cave. It is a horrid sound that sets
          everyone's heart racing with fear. This must be what the boy scouts
          heard. And then, now in the darkness of early night, the water-logged
          ghost of Christopher Liu appears at the mouth of the cave floating in
          mid-air!! He is a ghostly figure but clearly has claw marks on his
          side and is missing his sash. "Ajax, give him the sash,” Ariadne says,
          “put the sash on him!!"
          <br />
          <br />
          Ajax offers the sash to Christopher, who reaches for it and then pulls
          out a ghostly version of the sash as the real one stays in Ajax's
          outstretched hands. Christopher puts on the sash, and the horrible
          screeching howl sounds again.
          <br />
          <br />
          "Christopher, what is that sound?!?" asks Sheldon.
          <br />
          <br />
          Christopher's spirit opens its mouth to answer and … END OF SESSION.
        </div>
      ),
    },
  ];

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/kids-on-bikes/kids-on-bikes-page-background.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <Container>
      {/* <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin"
                    style={{position: 'float', float: 'right',width: '120px', height: '240px'}} marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=smokeraven-20&language=en_US&marketplace=amazon&region=US&placement=9189143701&asins=9189143701&linkId=d1384e0e6b9ef083fee35d7272a71f88&show_border=true&link_opens_in_new_window=true"></iframe> */}
      <Row>
        <Col>
          <div
            style={{
              backgroundColor: "transparent",
              fontFamily: "Carter One",
            }}
          >
            <main role="main" className="inner cover text-center">
              <img
                src={logo}
                style={{ maxWidth: "200px" }}
                alt="Kids on Bikes Turnaround Rock Campaign"
              />
            </main>
            <div style={pageHeader}>
              <h1 className="cover-heading-kids-on-bikes">
                Session 01: Turnaround Rock
              </h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title className="cardTitle-kids-on-bikes">
                Ajax, Ariadne, and Sheldon search for a missing boy scout, hear
                the story of the wendigo, and come face to face with a ghost.
              </Card.Title>
              <div>
                <ContentContainer data={section1Data} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default KOBTurnaroundRockSession01;
