import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import mbLogo from "../../../../src/assets/mork-borg/mork-borg-logo.webp";
import sectionDivider from "../../../../src/assets/mork-borg/mork-borg-section-divider.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";

import winstonDunghill from "../../../../src/assets/mork-borg/session07/winston-dunghill.jpg";
import relicBlood from "../../../../src/assets/mork-borg/session07/relic-blood.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const MBMalumMortisSession07 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/mork-borg/bkgnd-01.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  const section1Data = [
    {
      title:
        "In which our scum fight a RATBIT, some AUTOMATONS in an abandoned workshop near a graveyard, and approach LAKE ONDA searching for Hagauzza the Blood Witch for clues to the BLOOD RELIC of Anuk Schleger.",
      text: (
        <div class="morkBorgFont morkBorgTitleText">
          <ImageComponent
            image={winstonDunghill}
            caption="Winston Dunghill"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="Winston Dunghill, a nervous, soul-damned drunkard."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <p>
            Our party consists of:
            <ul>
              <li>
                <span style={{ color: "#00FFFF" }}>SIR FAT HEAD</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>MUSHROOM EATER</span>, a
                wacked out prophet, scrambled like eggs.
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>HILDEBRAND</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>HERETICAL PRIEST</span>,
                Worries, washes hands & obsessively clean. Baptized by fire!
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>WINSTON DUNGHILL</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>SOUL-DAMNED DRUNKARD</span>,
                a nervous drunk with suprising resiliancy.
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>HERMAN</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>GUTTERBORN SCVM</span>.
                Worried and naive; hands caked in sores. Pyromaniac.
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>SPUG (absent)</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>WRETCHED ROYALTY</span>{" "}
                kleptomaniac and afraid of pain.
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>KLATUUBARADA (absent)</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>FUGITIVE KNIGHT</span>, an
                argumentative, pyromaniacal.
              </li>
              <li style={{ fontSize: "0.8em" }}>
                <span style={{ color: "#00FFFF" }}>Donkey Hoatee</span>: donkey
              </li>
              <li style={{ fontSize: "0.8em" }}>
                <span style={{ color: "#00FFFF" }}>
                  Barbarister the Incredible Horse
                </span>
                : talking horse minion (master?) to Spug
              </li>
              <li style={{ fontSize: "0.8em" }}>
                <span style={{ color: "#00FFFF" }}>Jaque Manaque</span>: wannabe
                knight squire-lad trying to earn his keep
              </li>
            </ul>
            <div
              style={{ border: "3px solid red", padding: "5px", margin: "5px" }}
            >
              Misery Countdown:
              <ul>
                <li>
                  Psalm 6, Verse 1: You shall know the last day is come. The sun
                  shall set and never rise.
                  <ul>
                    <li>
                      EFFECT: sun never shines. World is dark always. No more
                      daytime.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            This session saw SPUG and KLATUUBARADA sitting out.
            <br />
            <br />
            Our gang gets the gory deets on the{" "}
            <span style={{ color: "#FF7F50" }}>RELICS OF ANUK SCHLEGER</span>.
            Details can be found in the &nbsp;
            <a
              href="https://www.drivethrurpg.com/product/423719/Malum-Mortis-A-Mork-Borg-Metaplot"
              rel="noreferrer"
              target="_blank"
            >
              Malum Mortis Metaplot
            </a>{" "}
            on DriveThruRPG.
            <br />
            <br />
            In summary, the relics and their initial clues are:
            <ul>
              <li>
                <span style={{ color: "#FF7F50" }}>HAND</span>: The location of
                the temple is said to be known only to to Vårdare (vur-dar) of
                Belgerfriest; a city in the lower mountains of Bergen Chrypt
                known for it's sky burials.
              </li>
              <li>
                <span style={{ color: "#FF7F50" }}>HEART</span>: A cult named
                the Sepulcher of the Swamp Witch engages in ecstatic drugs that
                allow the unseen to be seen. It is said the depths of their lair
                has ethereal tunnels that lead to a mystic pyramid where the
                heart resides. Their lair is in a cave near the southern shore
                of LAKE ONDA.
              </li>
              <li>
                <span style={{ color: "#FF7F50" }}>BLOOD</span>: Best bet for a
                clue is to interrogate Hagauzza the Blood Witch. Her location
                may be known by Gesa of the Arcane Eye and fingers who runs the
                Occult Cellar in Galgenbeck.
              </li>
              <li>
                <span style={{ color: "#FF7F50" }}>TEETH</span>: The many
                legends about Countess Anthelia of Allians include one that
                claims she sometimes wears a crown of teeth when dallying with a
                new lover. And some have said it is the Crown of Teeth.
              </li>
              <li>
                <span style={{ color: "#FF7F50" }}>JAW</span>: Location unknown,
                but the Shadow King has long boasted he knows how to find this
                relic. Surely he would welcome an unexpected visit from
                strangers? If one survives, certainly he might offer some odious
                task in exchange for information?
              </li>
              <li>
                <span style={{ color: "#FF7F50" }}>FINGER</span>: Rumored to be
                have been found in Grift in a marketplace mixed in with
                trinkets. It is said it is now worn by King Sigfum on a
                necklace.
              </li>
              <li>
                <span style={{ color: "#FF7F50" }}>SKULL</span>: Verhu and
                Gorgh, HE of the two heads, combined their power in a rare
                moment of cooperation. They cast the skull into a dimesion and
                time beyond the Dying Lands. The good news is, we know where the
                relic is; it's in the Pain Realm. The bad news is: we do not
                know where the pain realm is; only that It is said finding a way
                into that dimension is deep in the foul, black heart of the
                Valley of the Unfortunate Undead.
              </li>
            </ul>
            As a bonus, Tergol says the Coronaeic Vestibulations has mention of
            a <span style={{ color: "#FF7F50" }}>WAY TO UNDO A MISERY</span>.
            <br />
            There is a small town named Blysborg on the eastern edge of the
            Sarkash forest. It is said the town is dominated by a charismatic
            priest named Pavlov. Pavlov is said to have esoteric interpretation
            of the Nameless Scriptures. It is also said he knows one of the many
            secrets of the Sarkash forest and that the secret has the power to
            undo a Misery.
            <br />
            <br />
            The party buys some clean and unclean (shhh, be quiet) scrolls at
            the{" "}
            <span style={{ color: "#FF7F50" }}>Occult Cellar in Altmar</span>.
            They get a cart, stock up on oil, fix some armor, and replenish
            weapons. Since the rumors about Hagauzza the Blood Witch claim she
            will demand a blood sacrifice, Sir Fat Head hires Igor the Carrier
            to pull one of the new carts, thinking the stalwart minion could
            serve more than one purpose should it be necessary.
            <br />
            At last, they are ready to head south toward Lake Onda and get their
            relic on.
          </p>
        </div>
      ),
    },
    {
      title: "Romancing the Blood Relic",
      text: (
        <div class="morkBorgFont morkBorgTitleText">
          <ImageComponent
            image={relicBlood}
            caption="Blood Relic of Anuk Schleger"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="<small>The <b>Blood Relic of Anuk Schleger</b> is said to contain the blood of the prophet in a square, translucent cube.
  
    The cube sits atop a pewter base depicting tortured and ecstatic blood cultists writhing in supplication to the life fluid.
    
    The blood is said to be inert except when in the presence of a condemned criminal or when near a martyr being tortured.</small>"
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>

          <p>
            <br />
            Traveling south, the party awakens one night to the shouting of
            Jacque Manaque{" "}
            <span style={{ color: "#FF7F50" }}>battling a RATBIT</span>. The
            gang wakes up and dispatches the dog-sized rabbit abomination. Turns
            out ratbit tastes like chicken!
            <br />
            <br />
            Later, Sir Fat Head charges into a lonely clock tower to find it
            empty except for a{" "}
            <span style={{ color: "#FF7F50" }}>
              scroll of AEGIS OF SORROW
            </span>{" "}
            gathering dust on an ancient stone table. It's not a mushroom but he
            takes it gladly.
            <br />
            <br />
            Near the shores of Lake Onda, the party investigates an{" "}
            <span style={{ color: "#FF7F50" }}>
              abandoned workshop near a forgotten graveyard
            </span>
            . Inside the workshop are rotting planks and an assortment of
            coffins in various stages of construction. Along the wall are three
            humanoid-shaped constructions. They don't have a seam or hinges and
            are not the custom coffins they appear to be. They animate suddenly
            and attack like the devious AUTOMATONS that they are:{" "}
            <span style={{ color: "#FF7F50" }}>
              ceramic golems about 5-6 feet tall filled with rotting organic
              matter, excrement, blood, and all manner of foul liquid used to
              animate their forsaken shell
            </span>
            .
            <br />
            <br />
            The battle is difficult as the AUTOMATONS are very tough. Scrolls
            are used and sweihander's swung. Sir Fat Head dodges a death roll
            thanks to OMEN use, and at one point{" "}
            <span style={{ color: "#FF7F50" }}>
              HERMAN has an arcane catastrophe that summons HE to devour him
            </span>{" "}
            . . . luckily another OMEN negates that critical fumble he had when
            wielding a power and HE dissipates like a nightmare dream.
            <br />
            <br />
            After slugging it out for awhile, the party decides retreat would be
            a wise choice of action. They disingage and the AUTOMATONS will not
            follow past the grounds of the workshop.
            <br />
            <br />
            Our miserable scvm continue onward, guaranteed to reach the far side
            of LAKE ONDA next session.
            <br />
            <br />
            This episode of Malum Mortis brought to you in part by:
            <br />
            Game:{" "}
            <a
              href="https://morkborg.com/content/"
              rel="noreferrer"
              target="_blank"
            >
              Mork Borg
            </a>
            <br />
            Module (METAPLOT):{" "}
            <a
              href="https://www.drivethrurpg.com/product/423719/Malum-Mortis-A-Mork-Borg-Metaplot"
              rel="noreferrer"
              target="_blank"
            >
              Malum Mortis: A Mork Borg Metaplot.
            </a>
            <br />
          </p>
        </div>
      ),
    },
  ];

  return (
    <Container>
      {/* <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin" 
                    style={{position: 'float', float: 'right',width: '120px', height: '240px'}} marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=smokeraven-20&language=en_US&marketplace=amazon&region=US&placement=9189143701&asins=9189143701&linkId=d1384e0e6b9ef083fee35d7272a71f88&show_border=true&link_opens_in_new_window=true"></iframe> */}
      <Row>
        <Col>
          <div
            style={{ backgroundColor: "transparent", fontFamily: "Orbitron" }}
          >
            <main role="main" className="inner cover text-center">
              <img
                src={mbLogo}
                style={{ maxWidth: "200px" }}
                alt="Malum Mortis Mork Borg campaign"
              />
            </main>
            <div style={pageHeader}>
              <h1 className="cover-heading">Session 07: Malum Mortis</h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title className="cardTitle-mork-borg">
                ROMANCING THE BLOOD RELIC
              </Card.Title>
              <Card.Text>
                <ContentContainer data={section1Data} />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MBMalumMortisSession07;
