import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import dima from "../../../../assets/myz/wanderer-dima.jpg";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Bookkeeping",
    text: (
      <p>
        05-17-0001: We took care of non-party business first which included:
        <br />
        <br />
        <ul>
          <li>
            BITTERBEASTS: no new eggs (even with ten dice!). Beast training
            rolls went ok and afterward Hetu's beast talent went up to 4 points
            which will help going forward.
          </li>
          <li>&nbsp;</li>
          <li>
            PROJECT ROLLS: +6 points to random projects. Earthquake damaged
            Watchtower by one point.
          </li>
          <li>&nbsp;</li>
          <li>
            PURE PLANT PATROL: the fourth Pure Plant patrol is a bust. Humans at
            the Pure Plant and the away team get into a leathal scuffle. One
            Pure Plant human dies and Wanderer Dima dies. See her remembrance in
            the{" "}
            <a
              rel="noreferrer"
              target="_blank"
              href="./mutant-year-zero-anchortown-graveyard"
            >
              graveyard page
            </a>
            .
          </li>
        </ul>
        <br />
        <br />
      </p>
    ),
    image: {
      imagePath: dima,
      altText: `Dima was a very ethereal lady. She likes to sample any interesting (questionable) substance. She can be friendly, but is mostly vacant and wrapped up in herself.`,
      className: "imageLeft",
      imageCaption: `Wanderer Dima`,
    },
  },
  {
    title: "Make God Laugh By Making A Plan",
    text: (
      <p>
        The IL gang interviews Krinn and Naphta to make their plan. The pieces
        on play are: Kidnapping/killing the Chairman, Rescuing the Prisoners,
        and Escaping with the Armored Buses.
        <br />
        <br />
        After a lot of strategy talks and several variations on a base plan, it
        turns out the bomb Naphta built for the IL Gang is so powerful they
        change their plan at the last minute. Due to three stunts, the BOMB is
        BP 12 with extra shrapnel damage. Therefore, the simplified plan is
        merely:
        <ul>
          <li>
            Tie the bitterbeasts together while feeding them so one rider might
            conceivably be able to ride away and take all three beasts along.
            It's possible the beasts will be left as fodder much to Gladhand's
            dismay.
          </li>
          <li>
            The entire party except for Jebe and Smoke Raven will go to rescue
            the prisoners while Jebe and Smoke Raven hide in the lobby.
            Meanwhile, Fortran will try to keep Katie Kat and the elderly
            Amnesiac lady in the hallways near the lobby without drawing
            attention.
          </li>
          <li>
            As the rescue party and any rescued get back to the lobby, the large
            bomb will hopefully blast open or soften up the door to the bus bay
            and kill the guards; perhaps even other Diamond Center forces if
            they are in the lobby due to the rescue commotion.
          </li>
          <li>
            Naphta will try to get into the Bay to "work on the vehicles in case
            they are needed due to the suspicious activities of the Sunshine
            Plaza guests. She will try to get the booze out of the storeroom and
            fuel the buses. Only sticking point is that Rebeth has the key to
            the storage area and we don't yet know if Krinn was able to get the
            key or not.
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },

  {
    title: "Rescue Begins and Wrapup",
    text: (
      <p>
        The party takes out the two topside guards without much ado. Baila trys
        to fly away but is taken down before she can alert the Diamond Center,
        or so it seems.
        <br />
        <br />
        Once below the main floor, the party takes out two more guards, then
        Gladhand sprints to the far end of the wing to guard the known doorway
        there and Luna stands guard at the one they used to get into the
        basement. Chromicus has a mutation misfire for cosmetic change and gains
        antennae (which go nicely with his wings).
        <br />
        <br />
        The rescue team finds the main group of prisoners and talks to Freddy.
        They get the best roll possible and have Freddy as well as six other
        capable men ready to fight. An additional impassioned speech from
        Chromicus pulls another 9 non-combatant prisoners that will not (or
        cannot) fight. They will be slower and more problematic, but certainly
        help shore up the appearance of a major jail break and the size of the
        rebel forces trying to leave the Diamond Center death star.
        <br />
        <br />
        Next session will begin late afternoon/evening of 05-17-0001. We awarded
        three XP at end of session, so there is no prep at all since food, water
        and rot of no concern in ark. We will do XP spend, award session
        mutation points, and get rolling.
        <br />
        <br />
        The body count roll was 5. Minus two due to FOOD DEV level of 20+ leaves
        us with three casualties. The dead were one unnamed mutants, one unnamed
        ex-Kurtzer mutant, and HETU the beast whisperer. GM had just announced
        Hetu's training skill went up from 3 to 4 and then he dies! That's how
        the dice roll. A nameless mutant of the People will be promoted to Hetu
        Jr. with a low initial skill to take over at the stables of the Sunshine
        Plaza.
        <br />
        <br />
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageRight",
      imageCaption: "",
    },
  },
];

const MYZAnchortownSession26 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 26: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  The IL gang makes their move at the Diamond Center. They have
                  Naphta build a HUGE bomb and rescue the prisoners. But can
                  they get the prisoners back to the lobby and onto the buses?
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession26;
