import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";
import catfish from "../../../../assets/myz/unbearded-catfish.png";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Exploring the Ghost Ship",
    text: (
      <p>
        After defeating the Zone Rats in the Casino, the IL Gang continues
        toward the stern of the ship to take a look at the helicopter wreck.
        Hulagu commands his gang to scout ahead. They draw out more SKY KING
        enemies as the rest of the party fires from the cover of thick
        vegetation.
        <br />
        <br />
        Bayazid gets to work and fixes one of the davits and a motorboat. The
        gang lowers a now-working motorboat to moor next to the Steelcrest. The
        helicopter itself is not repairable and is too large to lower with the
        davit. It looks like towing the ghost ship as the First Secretary
        surmised will be the way to do it.
        <br />
        <br />
        Then, three parties of two swim under the ship to try and find the
        source of why the luxury liner is anchored in place. SmokeRaven and
        Bayazid encounter a massive, eel-like UNBEARDED CATFISH. It ambushes
        SmokeRaven and immediately grabs him and starts swimming deeper to have
        him as a snack in the depths of the Dead Blue Sea. Bayazid immediately
        flares a powerful blast of luminescence and blinds the catfish. The
        catfish is confused and begins swimming in a circle without going
        deeper.
        <br />
        <br />
        SmokeRaven is pinned but spends SIX mutation points to manbeast. His
        FORCE is boosted and he breaks free from the jaws of the aquatic
        monstroustity. He gets a mutation misfire that allows him to keep the
        mutation points and use the power again!! So, he fires off manbeast
        again, spends all six again, and starts clawing and biting the catfish
        into a bloody mess. However, this second activation also gets a misfire
        which causes SmokeRaven to also take damage. So, after witnessing a
        blast of outrageous fury, Bayazid floats open-mouthed as the catfish
        swims away trailing ichor and guts as a now-broken SmokeRaven starts
        sinking. Bayazid rescues SmokeRaven and with the help of the IL Gang and
        Cashel on deck they use the davit to get the stalker back on-board the
        ghost ship.
        <br />
        <br />
        Meanwhile Doc Gladhand and Skye find the land and scrap that the ship
        has crashed into. It's a giant spur of land. They contemplate blasting
        and possibly using tools to dig it out. They decide more information on
        the breech is needed and they decide to continue exploring the ship to
        find the puncture from the inside of the vessel.
        <br />
        <br />
        Working their way through the levels of the ghost ship, the IL Gang
        encounters Zone Wasps and again find the critical value of fire. The
        flamethrower is invaluable assisted by Chromicus using some of the fuel
        they found in abandoned cars on the car deck to start a fire for
        lighting arrows on fire. They also encounter seven hungry Zone Spiders
        that ambush the party.
        <br />
        <br />
        Among the rusty cars and abandoned luxury state rooms the party finds
        two pairs of BINOCULARS, an UMBRELLA, and a GENERATOR (currently carried
        by the android Theodore).
      </p>
    ),
    image: {
      imagePath: catfish,
      altText: `SmokeRaven and Bayazid face a monstrous, eel-like catfish beneath the ghost ship.`,
      className: "imageLeft",
      imageCaption: "Unbearded Catfish",
    },
  },
  {
    title: "Wrapup",
    text: (
      <p>
        <h4>Bookkeeping</h4>
        <ul>
          <li>Mutation points and XP spend</li>
          <li>Bitterbeasts: more grub!!</li>
          <li>Pure Patrol: everything goes smoothly.</li>
          <li>
            Ark Reputation: Unexpected stash with some grub, booze, and bullets.
          </li>
        </ul>
        NOTE: Altair is housed at the Rust Castle along with Summer of Love bus.
        Chi Tzu is with the party on the Steelcrest.
        <br />
        <br />
        Next session starts in-game 06-19-0001, 9am on board the ghost ship on
        the bottom level. There are hundreds of containers with lots of scrap to
        explore, and Theodore has flown back up to the top deck to inform
        Bayazid and Doc Gladhand that the plan to use a waterproof scrap bomb is
        a green light.
        <br />
        <br />
        We again tweaked XP to be 3 per character. Then, we discuss cool stuff
        that happened. Based on discussion, GM awards a bonus xp to one of the
        characters for each player. Some highlights discussed were:
        <ul>
          <li>
            Gladhand making a plan for loosening the ghost ship and generally
            forming plans.
          </li>
          <li>
            Bayazid's use of luminescence to blind the catfish was important and
            bad-ass.
          </li>
          <li>
            SmokeRaven's epic manbeast to break out of the jaws of the catfish
            and tear it apart whilst breaking himself.
          </li>
          <li>
            Chromicus fly folks back and forth and built a fire to enable party
            to combat zone wasps effectively.
          </li>
          <li>Bonus XP went to: SmokeRaven, Bayazid, and Chromicus.</li>
        </ul>
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        .
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
];

const MYZAnchortownSession46 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 46: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  The IL Gang arrives at Cage Island, bargain with the First
                  Secretary, and make a plan for the Green Man.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession46;
