import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import ordinance from "../../../../assets/myz/noatun-estates/unexploded-ordinance.jpg";
import swampBattle from "../../../../assets/myz/swamp-airplane-battle.jpg";
import noatunEstates from "../../../../assets/myz/noatun-estates/noatun-estates.jpg";
import lemmy from "../../../../assets/myz/noatun-estates/lemmy-portrait.jpg";
import noatunLogo from "../../../../assets/myz/noatun-estates/noatun-logo.jpg";
import cassandra from "../../../../assets/myz/noatun-estates/threat-scrap-oracle-1.jpg";
import yaoGuai from "../../../../assets/myz/noatun-estates/yao-guai-portrait.jpg";
import fortune from "../../../../assets/myz/noatun-estates/room-of-fortune.jpg";
import bonzo from "../../../../assets/myz/noatun-estates/bonzo-dog-food.jpg";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Travel Slowly Thru The Fields; The Fields of Joy",
    text: (
      <p>
        03-30-0001: Our session starts with Rowbert and Skut once again in
        stasis as real-life hammers one of our players. With Chromicus resting
        at the Pure Plant with paralysis critical injury, our party is currently
        Gladhand, Smoke Raven, Double Bird, Hulagu, Hetu, and Jebe.
        <br />
        <br />
        We pick up the action with the gang looking into the field of unexploded
        ordinance. They toss a few experimental rocks and sure enough one of
        them triggers an impressive explosion. This field is dangerous! Double
        Bird sees a flash of red and realizes it is the Apocalypse Girl that has
        been following them since they rescued her at Jim's Diner from the Zone
        Ghouls. The girl is standing among the tall grass in the field; she
        crooks her finger in a "follow me" motion and takes off like a rabbit
        into the field. Single file, as fast as they can, the party follows the
        girl as best they can. Thanks to Smoke Raven rolling FIVE successes and
        Gladhand with one, they get through the field unscathed.
        <br />
        <br />
        As they travel, Double Bird is feeling very hot and queasy. He is
        certain it is the effect of the STABLE GENIUS PILL.
      </p>
    ),
    image: {
      imagePath: ordinance,
      altText: `Apocalypse Girl leads the party through the field of unexploded ordinance`,
      className: "imageLeft",
      imageCaption: "Unexploded Ordinance",
    },
  },
  {
    title: "Swamp Plane and Cannibals",
    text: (
      <p>
        Emerging from the field, the gang is confronted with a particularly wet,
        nasty swampland. The Apocalypse Girl is leaping with almost supernatural
        speed and dexterity from grassy mound to wood chunk to debris and so
        forth. The party tries to follow but are soon far behind as they
        struggle to make MOVE and ENDURE rolls to get through the wet, ice cold
        swamp. Mostly the rolls are good, and they find their way to an airplane
        wreck in the swamp. It's been there a long time based on the vines,
        moss, and other growth crusting the airplane.
        <br />
        <br />
        Everyone except Hetu and Gladhand enter the airplane to search. As the
        party searches the plane, Hetu and Gladhand walk the perimeter of the
        airplane searching. Smoke Raven pushes toward the cockpit of the
        airplane and finds limbs and human body parts that have bite marks. The
        meat is fresh. He sees a pair of coveralls with an intact torso propped
        in a corner, the source of the gruesome meal. Mixed with the fetid swamp
        smell is the latrine area next to the cockpit door with fresh feces. A
        CANNIBAL rushes from behind the cockpit door to attack.
        <br />
        <br />
        Smoke Raven immediately activates MANBEAST and tears the cannibal to
        shreds. Gladhand shouts from outside the airplane, "more of them are
        coming!!" Hetu forces the pilot-side door of the airplane open to seek
        cover and Gladhand tries out his new bow with just two arrows for
        ammunition. Jebe and Smoke Raven break out airplane windows to fire from
        cover as Double-Bird and Hulagu exit the plane for some hand-to-hand
        dancing. One of the cannibals is a horrific, twisting human cannibal
        boss. After the four minions fall, the boss makes a MOVE roll and
        escapes combat hissing "you will see me again."
      </p>
    ),
    image: {
      imagePath: swampBattle,
      altText: `Our gang faces cannibals in the swamp. We used auras to help determine short and near distance`,
      className: "imageLeft",
      imageCaption: "Swamp Battle",
    },
  },
  {
    title: "Noatun Estates",
    text: (
      <p>
        The sound of scrap seagulls, fire crackling and machinery drifts through
        the weeds and trees as the party emerges from the swamp. They find a
        battered, makeshift road that has seen recent use. They follow the road
        and finally find a settlement; a maritime military facility with a metal
        gate between a trailer and corrugated fence. There are watch towers and
        quanset huts. Off to one side is a large scrap field. Outside the gate
        are a couple of fire barrels actively burning. <br />
        <br />
        They see humans patrolling yard beyond gate. In the guard towers are
        humans with rifles. From vented windows in the trailer that comprise
        part of the "gate", there are nasty, long guns sticking out with humans
        standing behind them inside the trailer.
        <br />
        <br />
        On the gate, there is a symbol of some kind of aquatic, tentacled
        creature. A worn sign to the left of the gate reads "Noatun Estates".
        Next to one of the fire barrels outside the gate area is a zone hound
        scrounging for scraps.
        <br />
        <br />
        Most startling is the large metal contraption in the yard behind the
        gate. It is shaped like a large insect. While unmoving, it has has
        visible plumes of black smoke coming off the top of the machine (it is a
        machine right?). There are bright lights shining up from the tops of the
        guard towers; the source of the mysterious lights seen in the sector. As
        the party approaches the gate, the Apocalypse Girl blinks into
        visibility and runs through the gate. A human voice, unusually loud and
        metallic blurts, "she's here . . . she's been found . . . shut em down"
        . . . two loud clank sounds and a hissing and the two big lights power
        down. A sudden voice from a guard tower barks, "Hey you mutants! Stay
        right there. Don't move. Don't reach for anything, and do . . . not . .
        . move or we'll shred you into dog meat for that zone hound."
      </p>
    ),
    image: {
      imagePath: noatunEstates,
      altText: `On the coast is the settlement Noatun Estates, home of scrap oracle Cassadra and her many "husbands".`,
      className: "imageLeft",
      imageCaption: `Noatun Estates`,
    },
  },
  {
    title: "Lemmy and Delta KCG-966",
    text: (
      <p>
        An affable human named Lemmy and an apparently well-armed robot named
        Delta KCG-966 come out to interrogate the party. Lemmy is a handsome
        fellow with a big knife. He is the leader of the military part of Noatun
        Estates. He seems smart and respectful, but solid in his capacity as a
        soldier for Cassandra.
        <br />
        <br />
        His clothes are in better shape than any the party has seen in the Zone.
        His overall hygiene speaks to both access and passion for grooming
        products. He has some kind of an accent that sounds clipped and rough;
        it is vaguely reminiscent of The Wanderers.
        <br />
        <br />
        Hulagu and Gladhand claim to be present for trading opportunities and
        because they are looking for a fellow mutant sold from their Ark when
        she was a child named Cassandra. Lemmy says he has to relay the
        information; there will be news in the morning.
        <br />
        <br />
        Someone brings the party grub and water and they are encouraged to camp
        near a fire barrel outside the Estates for the time being. During the
        evening, Gladhand tries to make a side deal with the male human that
        comes to tend the fire barrels, but no luck. Jebe tames the zone hound
        that has been hanging around and Tiska 2 joins the party! Double Bird
        becomes incapacitated with nausea and sickness, and morning finds him
        SLEEPLESS and unable to do much except lie on the ground suffering.
        <br />
        <br />
        During the night, Hetu receives another message from Berke via crow
        delivery. Berke warns that the Sunshine Plaza is near total civil war.
        The Wanderers are being used as pawns. Bosses Skag and Quiet Bob claim
        the presence of the Wanderers compromises the integrity of the mutant
        domination of the Ark and they are there to destabilize the safety of
        the people at the behest of the IL Gang.
      </p>
    ),
    image: {
      imagePath: lemmy,
      altText: `Lemmy is the military commander of Noatun Estates; home of scrap oracle Cassandra`,
      className: "imageRight",
      imageCaption: "Lemmy",
    },
  },
  {
    title: "Into the Camp",
    text: (
      <p>
        03-31-0001: the next morning, Lemmy asks what is wrong with Double-Bird.
        Hulagu shows Lemmy the box they found with the vial of Stable Genius
        Pills. Lemmy has heard the name Clark Stanley but does not know more. He
        says bring the box, leave Double Bird and the hound. Hetu stays to keep
        an eye on Double Bird. The rest of the party follows Lemmy into the
        camp. As they move through the camp, they notice the logo from the
        entrance gate to the camp on faded posters on walls, engraved on
        surfaces of mysterious equipment, and as fabric adornment on some of the
        outfits worn by the soldiers of the camp. It's also notable that all of
        the denizens of Noatun Estates seem to be human males exclusively.
      </p>
    ),
    image: {
      imagePath: noatunLogo,
      altText: `The Noatun logo is some kind of tentacled sea creature.`,
      className: "imageLeft",
      imageCaption: "Noatun Logo",
    },
  },
  {
    title: "Interview",
    text: (
      <p>
        The party is led into a squat, concrete building; ugly but in very good
        shape compared to many ruins in the Zone. A human male named Yao Guai
        enters to interview them. Yao Guai is a slim mutant. He seems to be the
        second in command after Cassandra in terms of camp intelligence. He
        carries cables and other technology that the party cannot understand. He
        speaks quietly with a soft voice. He has a bandolier of grenades across
        his chest. These do not look like the scrap grenades made by gearheads;
        these are uniform in size and smooth.
        <br />
        <br />
        Yao Guai asks about how the party knows Cassandra; he asks about the
        rescue of the Apocalypse Girl whom we learn is named Zeta and is
        daughter to Cassandra. And finally, Yao Guai asks about the pill Double
        Bird took. After each round of questioning, he seems to space out for a
        few seconds as he telepathically communicates with Cassandra.
        <br />
        <br />
        At the end, Yao Guai announces "Cassadra will see you." After a brief
        wait, the party is accompanied to a boat by Lemmy and several other camp
        guards. The mutants are amazed by the sight of the ocean and the
        floating artifact in which they ride. The boat is taken out into the bay
        for a few minutes until it reaches a 40 by 40 foot metal island. It is a
        simple metal square with ocean water lapping over it's edges. In the
        center is a porthole. They entrance is opened. Lemmy gestures toward the
        ladder heading downward and says "good luck". Jebe senses emotion and
        gets the impression this is not a trap, but it is also not without
        danger.
      </p>
    ),
    image: {
      imagePath: yaoGuai,
      altText: `Yao Guai is second in command of intelligence operations at Noatun Estates`,
      className: "imageRight",
      imageCaption: "Yao Guai",
    },
  },
  {
    title: "Scrap Oracle Cassandra",
    text: (
      <p>
        At the bottom of the ladder, the party emerges into a small waiting room
        with metal benches. After just a few moments, a robot opens the single
        door of the room. This robot is much less threatening than Delta and
        seems to be made to assist and help. It leads them into the central
        chamber beyond the door.
        <br />
        <br />
        The central chamber of Cassandra's lair is a circular room with convex,
        rounded windows. Outside the windows is a great view under the icy
        waters of Anchortown's bay with the occasional horrific fish or
        mysterious aquatic monster swimming by. On the opposite wall of the
        spherical room are two doors. Presently, Cassandra enters. Cassandra
        radiates a palpable aura of power. The air around her up to arm's length
        causes the hair on ones neck or arms to raise. The atmosphere near her
        feels heavier and charged with a damp, electrical smell. She has a
        third, full-sized eye on her forehead that is closed . . . for now.
        <br />
        <br />
        She acknowledges knowing the party members and that she was a child in
        their Ark. But she has clearly become more powerful. Her presence is
        both thrilling and frightening. She tells them the girl they rescued
        from Jim's Diner is her daughter Zeta. Cassandra takes each of the party
        members individually to query them about why they are here and what
        their personal desires are.
      </p>
    ),
    image: {
      imagePath: cassandra,
      altText: `Cassandra was a mutant at Sunshine Plaza. She was sold along with Double Bird when they were children. He fate was unknown until it was discovered she is the Scrap Oracle of Noatun Estates. She is a super-powered mutant who surrounds herself with many human "husbands".`,
      className: "imageLeft",
      imageCaption: "Scrap Oracle Cassandra",
    },
  },
  {
    title: "Hope, Dreams, and Danger",
    text: (
      <p>
        Cassandra interviews each mutant in her room of fortune (see picture).
        She is coy, mildly flirtatious, and captivating. She asks each mutant
        about their dream or goal. She presents a choice of colored vials filled
        with liquid to see if each mutant will choose one. The vials are red
        (passion), blue (rest), black (truth), mixed colors (beyond illusion),
        and green (decisions/choices).
        <br />
        <br />
        <ul>
          <li>
            Gladhand lays out a vision of trade and cooporation among all
            settlements in the zone. He explains the party is here because they
            want poison to kill Boss Skag. He chooses the MIXED COLOR vial.
          </li>
          <li>
            Smoke Raven speaks about his drive to solve the mysteries of the
            Zone. He chooses the RED vial.
          </li>
          <li>
            Hulagu explains his dream preciesly: "To crush my enemies, see them
            driven before me, and to hear the lamentations of the gangs." [kudos
            on repurposing classic Conan quote!] He also appeals to her
            powerfully with "you're family. We need your help." He chooses the
            GREEN vial
          </li>
          <li>
            Jebe speaks of his desire to leave a legacy. Cassandra seems
            enamored of Jebe's goal and his connection to animals. "These men at
            Noatun Estates? They are a bit like your hounds. I love them, I
            train them. They serve me. They are all my husbands you know." Jebe
            chooses the BLACK vial.
          </li>
        </ul>
        The party is them taken to the room though the other door. This room is
        adorned with multiple beds, chaise lounges, and other furniture shaped
        in ways to assist sexual acrobatics. This is her mating room. Each of
        the mutants sits or lays down as Cassandra asks, "are you ready?". She
        raises her arms as the lights in the room dim and the mutants drink
        their potions. A light glows from the ceiling as Cassandra closes her
        eyes, and the third eye on her forehead opens.
        <br />
        <br />
        Each mutant has to make an ENDURE roll opposed by potency six poison. In
        other words, the potion rolls 6D6 and the successes opposed the number
        of successes from the mutants roll. If the mutant gets the same or more
        successes as the potion, they suffer the LIMITED effect of the poison;
        otherwise, they suffer the FULL effect of the poison. The poison effects
        are:
        <br />
        <br />
        <ul>
          <li>
            LETHAL (black): <u>Full</u>: 1 dam to STR each round until broken.
            Critical Injury is non-typical table - critical injuries.{" "}
            <u>Limited</u>: 1 dam to STR.
          </li>
          <li>
            PARALYZING (green): <u>Full</u>: 1/AGI/rnd. <u>Limited</u>: 1 dam to
            AGI.
          </li>
          <li>
            SLEEPING (blue): <u>Full</u>: 1/WITS/rnd. No critical. Asleep 1dD6
            hours.
            <u>Limited</u>: 1 dam wits.
          </li>
          <li>
            HALLUCINOGENIC (mixed color): <u>Full</u>: 1/EMP/rnd. Critical
            non-typical, non-lethal. Incapacity 1D6 days. Must succeed on
            COMPREHEND to take action in following round.
            <u>Limited</u>: 1 EMP.
          </li>
          <li>
            LOVE (red): <u>Full</u>: must serve Cassandra faithfully 1D6 weeks.{" "}
            <u>Limited</u>: must serve for rest of day. (note: those who serve
            genuinely fall in love).
          </li>
        </ul>
        Aside from some attribute damage, the notable outcome of the poisons are
        that Gladhand is straight trippin' for a week (must roll comprehend to
        activate skill or mutation or take decisive action; wears off by morning
        of 4-8-0001). Additionally, Smoke Raven is enamored with Cassandra due
        to the LOVE potion and will have to serve her faithfully for one week.
        <br />
        <br />
        The final effects of the STABLE GENIUS PILL occurs. Double Bird rolls D6
        and does not receive the miraculous rot heal, but also does not suffer
        failure. So, Double Bird immediately rolls for the 6 rot points caused
        by the pill as if they were washed off. He rolls THREE successes and
        Double Bird finds himself with SIX permanent rot (yikes!). And the final
        effect of the pill is to gain a mutation. Double Bird rolls and gets
        HUMAN PLANT.
        <br />
        <br />
        Cassandra comes ashore to the interview room in the squat, ugly
        building. The camp is buzzes with excitement since Cassandra rarely
        leaves her bathysphere to come ashore. She asks about the pills and
        inspects the one reamining star-shaped pink STABLE GENUIS PILL. She
        makes a trade with the party. Each mutant gets a piece of intelligence
        which will help in the coming civil war. She also trades a SCOOTER
        artifact. And finally, she gives them what they came for; a deadly
        poison.
        <br />
        <br />
        She hands them a sinle can of grub; a seemingly average can of grub with
        a label showing a dog holding a can of food showing a dog holding a can
        of food, and so on until the print is too small to see. Gladhand, still
        tripping from hallucinagenic poison, is blown away by the infinite
        regression of the can's label.
        <br />
        <br />
        Cassandra says the contents are delicious and will kill whoever eats it
        with no chance of failure whatsoever and in a way that no one will be
        able to say he was poisoned. It has an hour delay and then a fast-acting
        rot kicks in and will kill the target in D6 hours.
      </p>
    ),
    image: {
      imagePath: fortune,
      altText: `The room of fortune is where Cassadra holds interviews and does her experimental work.`,
      className: "imageRight",
      imageCaption: "Room of Fortune",
    },
  },
  {
    title: "Wrapup",
    text: (
      <p>
        Next session will begin morning of 04-01-0001. Grub and water taken care
        of by Noatun Estates. All rot rolls were made for washing up at the
        camp. Gladhand wants to make a side deal before leaving the camp and
        Smoke Raven would like his rifle fixed. We wonder if Gladhand can pull
        off a deal while stoned, and what plans does Cassandra have for Smoke
        Raven?
        <br />
        <br />
        Bitter Beast hatch roll is 2D6 since the Ark now has two bitterbeasts in
        their stable. No successes. Project rolls are bypassed because the Ark
        is in chaos and projects are not making progress as Civil War looms
        (next session).
        <br />
        <br />
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: bonzo,
      altText: `Bonzo Dog Grub: a can of poison grub given by Scrap Oracle Cassandra that promises to kill without chance of failure`,
      className: "imageLeft",
      imageCaption: "Bonzo Dog Grub",
    },
  },
];

const MYZAnchortownSession17 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 17: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  The IL gang finds the scrap oracle Cassandra leading the
                  settlement known as Noatun Estates. After dangerous interview
                  sessions involving experimental poisons, she gifts them with
                  what they need, and a little bit more in trade for the last
                  Stable Genius Pill from the Clark Stanley stash.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                {/*<Card.Title style={cardTitle}>Shootout at Burger Jim's, 03-27-0001 - 03-27-0001</Card.Title>*/}
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession17;
