import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import logo from "../../../../src/assets/forbidden-lands/forbidden-lands-logo.webp";
import sectionDivider from "../../../../src/assets/forbidden-lands/session03/bitter-reach-page-banner-session-03.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";
import "./forbidden-lands-styles.scss";

import julla from "../../../../src/assets/forbidden-lands/session03/julla.webp";
import tuuq from "../../../../src/assets/forbidden-lands/session03/tuuq.webp";
import biliq from "../../../../src/assets/forbidden-lands/session03/biliq.webp";
import wolfkin from "../../../../src/assets/forbidden-lands/session03/versipel.webp";


const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "1",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Carter One",
  backgroundColor: "rgba(0, 0, 0, 1) ",
  color: "white",
  marginBottom: "15px",
};

const FBLSession03 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  const section1Data = [
    {
      title: "Winter Spirits",
      anchor: "freedom-day",
      sectionTitleCssName: "sectionTitleFBL",
      text: (
        <div className="fblBodyText">
          <ImageComponent
            image={biliq}
            caption="Biliq"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Biliq is a guardsman in Northfall. A tall, sad-faced Alderlander."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <span style={{ color: "burlywood" }}>Lobo has to carry Blisster back to camp in the elven ruins</span>. We forgot last session that the wraith attack making him 
          COLD automatically does a point of damage to WITS, which combined with the FEAR attack broke Blisster. It's just as well as Blisster's player had to sit out this
          session. Once back at the campfire, Lobo explains what happened to Rog-Golar who has just finished an evening shift as a town guard. The two of them heal Blisster 
          with a performance roll but Blisster will be recovering for the remainder of the session.
          <br/><br/>
          <span style={{ color: "lightgreen" }}>"What was it??"</span> Rog-Golar asks Lobo. <span style={{ color: "lightgreen" }}>"I don't know. It was a lost spirit. 
          It was a ghost maybe. It was a Winter Elf who wanted oil for the lighhouse lamp."</span> Lobo answers. The players plan to leave the lighthouse alone for the evening as no 
          promises were made to the spirit as to how long the oil might take. The plan is to do some shopping the next day to get clothes and oil and so forth.
          <br/><br/>
          Rog-Golar missed last session so he is brought up to speed as he and Blisster 
           are tamping down the camp fire in the elven ruins they call home for now. Lobo is on a mission to learn MELEE CHARGE and eventually gets Marshall Volle to agree to teach him.
           Meanwhile Rog-Golar goes to Ogrin's to get some winter clothing.
           
            <span style={{ color: "lightgreen" }}>"We're like brothers"</span> cries <span style={{ color: "burlywood" }}>Rog-Golar</span> since Ogrin is one of the 
            few humans tall enough to look him in the eye. <span style={{ color: "lightgreen" }}>"Tall and good looking!"</span>. Rog-Golar gets a thin smile out of 
            Ogrin which is quite a feat for anyone let alone an orc. However, Rog's coin spends well which is a consideration that rises about Ogrin's mild prejudices.
            <br/><br/>
            
            Lobo goes on guard patrol and asks to do a shift with the biggest fellow guard and meets <span style={{ color: "burlywood" }}>Biliq</span>, an Alderlander 
            fighter with big, round sad eyes, light brown skin standing 6' 2". Biliq explains about the ice wall around Northfall and how pikebeast bones are 
            used as spikes to make the wall effective. He also relays some lore about finding treasure in the Bitter Reach. The patrol takes Lobo and Biliq outside 
            the city gates to the merchant camp as the land darkens with night. Biliq seems more interested in talking to some friends in the camp, especially a 
            rough looking female trader that he touches a lot and seems to like. Lobo sees something bipedal but furry scuttering past the edge of the merchant 
            camp and reports to Biliq. Biliq is unconcerned so Lobo goes to investigate.
            <br/><br/>
            <ImageComponent
            image={wolfkin}
            caption="Wolfkin Mutant?"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Lobo sees a wolfkin that is twisted, deformed and terryfing. It runs away into the night."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
            A few dozen yards befond the fringes of campfire light Lobo sees a wolfkin. But <span style={{ color: "burlywood" }}>this wolfkin is a horrible and twisted sight. Some kind of deformed monstrousity 
            that turns and stares at Lobo</span>. The creatues visage is so terrifying that Lobo is nearly broken from FEAR attack. As Lobo stands shuddering, the monster runs 
            into the midnight tundra. Biliq and Lobo report this sighting in the guard log at the end of the shift.
            <br/><br/>
            That night Lobo and Rog-Golar go back to the lighthouse during the wee hours when it is glowing again in a cascade of colors. 
            At the top of the lighthouse, 
            the ghostly apparition of the winter elf is seen again. <span style={{ color: "burlywood" }}>Rog-golar adds oil to the lamp and it begins to brighten</span> into a 
            white light . . . soon it is blinding.
            Stumbling to the balcony Rog-golar and Lobo see a massive, glowing ship gliding into the harbor of Northfall. Horrified they watch as the ship 
            sets anchor and the flickering <span style={{ color: "burlywood" }}>undead wraiths of winter elves and skeletons begin to form up and march on the beach of Northfall</span>. Whalers and fishermen in 
            the camps are waking up and commotion is growing by the moment as the Northfallers prepare to battle this supernatural army.
            <br/><br/>Rog-golar rips up his shirt and uses it to soak up the oil in the lamp tray. As he soaks up the oil, the light dies down and with it the night parade of 
            ancient elven spirits fades like a dream. The ghost of the lighthouse is gone with the fading light but on the ground below where the ghost hovered is a 
            &nbsp;<span style={{ color: "orange" }}>CRYSTALLINE FLASK</span> (magic item that can cast blue, unwavering glow like a torch for 15 minutes per Willpower spent to activate it). Lobo and Rog-Golar are spotted 
            by the populace when Rog-Golar shouts from the balcony 100 feet above in a proud thundering blast <span style={{ color: "lightgreen" }}>We stopped 'em!! Everything is ok!</span>
            <br/><br/>
            And so, the next day Marshall Volle summons Lobo and Rog-Golar.
            <ImageComponent
            image={julla}
            caption="Iona"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Julla the Elder is a member of the elder of the Northfall town council. She is a lifelong Bitter Reach Ailander and is much respected except by Ingmar who balks at the taxes on goods."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
            Our rogues meet the town council including <span style={{ color: "burlywood" }}>Julla the Elder</span>. Lobo and Rog-Golar spit out simple but viable explanations of how 
            that did not CAUSE a ghostly invasion of the town, but they in fact saved the town! The council is not pleased but they are not angry either. 
            But Julla is satisfied and has Lobo and Rog gifted 2sp each for going above and beyond in their duties. Meanwhile, Marshall Volle gives them the kind of hardened side-eye
            that speaks to his doubts about whether they reacted to the lighthouse lighting up or caused the whole damn commotion.
            <ImageComponent
            image={tuuq}
            caption="Tuuq"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="Tuuq tells the legend of HOPE'S LAST REST. He says his is friends with Tryngar and to say hello if the party ever reaches the settlement."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
            <br/><br/>
            Bumping around town that day, Rog-golar is on partrol and <span style={{ color: "burlywood" }}>meets Tuuq who tells the legend of Hope's Last Rest</span>.
            And at Cuss & Dang's the party learns lore about Beneath the Ice in the Bitter Reach from Berry Wight. While Rog-Golar talks to Berry, Lobo carves 
            a baby wolf statuette from the bones of a pikebeast.
       
            <br/><br/>
            Lobo and Rog-Golar leave Northfall and travel a day's worth of travel just outside Northfall; our groups first taste of Forbidden Lands style hex 
            crawl mechanics. Realizing they have earned their XP, running low on torches, and with no clear goal in mind, they come back to Northfall to rest.
          <br/><br/>
          
 
          
          <br/><a href="#pageTop">Back to Top</a>
          <br/><br/>
          <small>Attendees: Blisster, Lobo, (Rog-Golar)</small><br/>
          <small>Ending Datetime: 6am, Winterwane 38, 1165</small>
          <h5>XP</h5>
          <ul>
            <li>Session 1.0 (Lobo, Rog-Golar)</li>
            <li>Legend: 1.0 (Hopes Last Rest by Tuuq to Rog-Golar)</li>
            <li>Lore: 0.4 (Beneath the Ice by Berry Wight to Blisster, Treasures of the Bitter Reach by Biliq to Lobo)</li>
            <li>Hex: 1.0</li>
            <li>Pride: 1.0 (Rog-Golar)</li>
            <li>Treasure: 0.0</li>
            <li>Monster: 0.0</li>
            <li>Dark Secret: 0.0</li>
          </ul>
        </div>
      ),
    },
    
  ];

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/forbidden-lands/fbl-page-background-blizzard.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.backgroundWidth = "100vw";
    document.body.backgroundHeight = "100vh";
    document.body.style.backgroundSize = "cover";
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <div
            style={{
              backgroundColor: "transparent",
              fontFamily: "IM Fell Great Primer",
            }}
          >
            <div style={pageHeader}>
              <main role="main" className="inner cover text-center">
                <a href="#freedom-day" name="pageTop">
                  <img
                    src={logo}
                    style={{ maxWidth: "200px" }}
                    alt="Forbidden Lands Campaign"
                  />
                </a>
              </main>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title id="cardTitle-forbidden-lands" className="cardTitle-forbidden-lands  ">
              Our rogues unleash an army of Winter Elf wraiths, shop for goods, and walk the tundra to get a feel for travel in the Bitter Reach.
                <br /><br/>
                <small>
                  ⇐ Session 02:{" "}
                  <a href="./forbidden-lands-session-02">
                    The Elven Lighthouse
                  </a>{" "}
                  ☠{" "}
                  Session 04:{" "}
                  <a href="./forbidden-lands-session-04">
                    Murder in Northfall
                  </a>{" "}
                  ⇒{" "}
                </small>
                
              </Card.Title>
              <div>
                <ContentContainer data={section1Data} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default FBLSession03;
