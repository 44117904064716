import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import plaza1 from "../../../../assets/myz/ark-sunshine-plaza-1st-floor.jpg";
import plaza2 from "../../../../assets/myz/ark-sunshine-plaza-2nd-floor.jpg";
import zoneMap from "../../../../assets/myz/zone-map-anchortown.jpg";
import bossKrao from "../../../../assets/myz/ark-boss-krao.jpg";
import elderAndKrao from "../../../../assets/myz/the-elder-and-krao.jpg";
import bossSkag from "../../../../assets/myz/ark-boss-scag.jpg";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "",
    text: (
      <p>
        The Ark was excited for an impromptu party celebrating an appearance by
        The Elder who has not been seen by any mutants other than the Order of
        the New Dawn in almost a year.
        {/* <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin" 
    style={{margin: '10px', position: 'float', float: 'left',width: '120px', height: '200px'}} marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=smokeraven-20&language=en_US&marketplace=amazon&region=US&placement=9188805816&asins=9188805816&linkId=8e8c28a4345a6230550e3e53c260f79f&show_border=true&link_opens_in_new_window=true"></iframe> */}
      </p>
    ),
  },
  {
    title: "",
    text: (
      <p>
        As the People gathered to wait for The Elder, Silas the Chronicler spoke
        about the portrait of Boss Krao and The Elder. The "drawing" was created
        by the now defunct "kammer uh" atop Silas' staff. Silas is proud of the
        staff because The Elder gave him the kammer-uh when Silas was a child.
        <br />
        <br />
        Boss Krao based on &nbsp;
        <citation>
          <a
            rel="noreferrer"
            href="https://en.wikipedia.org/wiki/Krao_Farini"
          >
            Krao Farini
          </a>
        </citation>
      </p>
    ),
    image: {
      imagePath: bossKrao,
      altText: `
        Krao the Chronicler is the Boss of the Order of the New Dawn. She is the favorite of The Elder as immortalized 
        in drawing "The Elder and Krao". 
      
        It is said she is the only one that knows his true name. She is a kind leader and beloved mutant of The People. 
        She adheres to the rules and commandments of the Elder. 
      
        She believes The People should not change and keep doing 
        what they always have. However, as grub is becoming scare, her conservative position is not going over well with 
        hungry, angry mutants in an Ark with an increasingly unstable population.`,
      className: "imageLeft",
    },
  },
  {
    title: "",
    text: (
      <p>
        Silas recounts the joy of the early days in the Ark when The Elder
        taught the mutants and told stories of the world before the Dawnworld.
        The Elder had not seen the Old Age himself but he spoke of moving
        pictures and other wonders that described that magical time.
      </p>
    ),
  },
  {
    title: "",
    text: (
      <p>
        Silas' story is met with derision by Boss Skag. Skag intimidates Silas
        and wonders aloud if The Elder is even still alive. Hanneth the Spoiled
        Chronicler chimes in and says The Elder will soon appear. Skag
        intimidates Hanneth and Smoke Raven is quick to step over with weapon
        ready. Soon all our mutant PCs are gathering and Smoke Raven issues a
        threat. Tensions are high when Boss Loud Sue and three of her followers
        enter the gathering.
      </p>
    ),
    image: {
      imagePath: elderAndKrao,
      altText: `
        A drawing of The Elder and Boss Krao created by an artifact from the Old Age called a "kammer uh". The artifact 
        was given to Silas the Chronicler when Silas was a child. The drawing shows The Elder and Boss Krao. 
      
        The drawing was defaced with a red drawing by Boss Skag years ago. The Order of the New Dawn wanted to repair the 
        drawing and restore it, but The Elder said to leave it as a reminder of how important it is they all learn to 
        understand and support each other.`,
      className: "imageRight",
    },
  },
  {
    title: "",
    text: (
      <p>
        Loud Sue approaches Skag and issues one of her famous profanity
        outburts. Skag is infuriated and motions to his guards; violence seems
        inevitable. Then, Boss Krao enters with The Elder in a wheelchair.
      </p>
    ),
  },
  {
    title: "",
    text: (
      <p>
        The Elder's presence breaks the tension. As everyone relaxes and
        celebrates, The Elder reminds everyone how the portrait of he and Krao
        was defaced years ago (he generously does not mention that it was Boss
        Krao when he was a young boy). He explains how he did not allow the
        Order of the New Dawn to restore the "drawing"; rather, leaving the
        graffiti would serve to remind The People how despite their differences
        and disagreements, it was important they always learn to cooperate in
        order to survive.
      </p>
    ),
    image: {
      imagePath: bossSkag,
      altText: `
        Boss Skag the Enforcer (Despot) leads a gang that defies The Elder. 
    
        Skag thinks he should have all the power. He makes other, weaker mutants do his dirty work.
    
        He thinks everyone is out to get him (and to be fair, many are), and reacts violently to perceived threats.`,
      className: "imageLeft",
    },
  },
  {
    title: "",
    text: (
      <p>
        After servings of Grandma Yoya's "cake" (i.e. post-apocalyptic version
        of Eskimo ice cream: rendered fat and berries), The Elder retires as The
        People remain gathered for Assembly.
      </p>
    ),
  },
];

const section2Data = [
  {
    title: "",
    text: (
      <p>
        The mutants kick off three projects: pigsty, temple, and zone wrestling.
        Clearly the effects of the tension at the party have brought things to a
        boiling point. Having all four bosses present and arguing in front of
        the entire population has left some resentment and fear in the hearts of
        many. Here's the summary of project rolls.
      </p>
    ),
    image: {
      imagePath: plaza1,
      altText: "Sunshine Plaza - First Floor",
      className: "imageLeft",
    },
  },
  {
    title: "",
    text: (
      <p>
        Double Bird does zone wrestling . . . no success, push, two success
        <br />
        Smoke Raven . . . temple, rowbert helps (inspire), 2 success from
        rowbert.
        <br />
        Hulagu . . . zone wrestling . . . no success.
        <br />
        Jebe . . . temple . . . one fail. . . push . . . no success.
        <br />
        Skut . . . pigsty . . one fail . . . push . . . total two failures (+1
        to session body count)
      </p>
    ),
    image: {
      imagePath: plaza2,
      altText: "Sunshine Plaza - Second Floor",
      className: "imageRight",
    },
  },
  {
    title: "",
    text: (
      <p>
        The bosses and their attitudes toward the projects are:
        <ol>
          <li>
            <b>Hulagu</b> supports all three.
          </li>
          <li>
            <b>Berke</b> (kingpin) is neutral about the pigsty, neutral about
            the temple, and supportive of zone wrestling.
          </li>
          <li>
            <b>Loud Sue</b> (proletariat) is positive about the pigsty, positive
            about the temple (unless it's about the Rot or similar negative),
            and opposed to zone wrestling.
          </li>
          <li>
            <b>Krao</b> (bureaucrat) is positive about the pigsty, opposed to
            the temple (unless it's all about The Elder), and opposed to zone
            wrestling.
          </li>
          <li>
            <b>Skag</b> (despot) is opposed to the pigsty, opposed to the
            temple, and supportive of zone wrestling (but makes it clear he
            expects to dominate the project during and after).
          </li>
        </ol>
      </p>
    ),
    image: {
      imagePath: null,
      altText: "",
      className: "imageLeft",
    },
  },
];
const section3Data = [
  {
    title: "",
    text: (
      <p>
        All six of our mutants go into the zone to explore Sector F07. Howling
        is heard in this sector. Could be Zone Dogs or worse. Also, a
        semi-intact ruin of a Shelter is found.
      </p>
    ),
    image: {
      imagePath: zoneMap,
      altText:
        "Mutant Year Zero - The Zone - area around what was formerly known as Anchorage Alaska",
      className: "imageLeft",
    },
  },
  {
    title: "",
    text: (
      <p>
        Jebe has Subu scout the building. Subu starts barking madly and runs
        back when Jebe summons her back. Three gaunt, pitiful mutants appear at
        the broken door of the shelter, hands in the air. They are weak and
        hungry.
      </p>
    ),
    image: {
      imagePath: null,
      altText: "",
      className: "imageLeft",
    },
  },
  {
    title: "",
    text: (
      <p>
        After given one grub and one water each, they introduce selfs: Zane,
        Bianca, and Ariana. Ariana is extremely pale, gaunt and sickly. Double
        Bird gives her food specifically.
      </p>
    ),
    image: {
      imagePath: null,
      altText: "",
      className: "imageLeft",
    },
  },
  {
    title: "",
    text: (
      <p>
        They explain they are exiled mutants from a cult living in an abandoned
        amusement park near ambulance on Zone Map. They welcome coming to the
        Ark and living as part of The People with Hulagu as their Boss.
      </p>
    ),
    image: {
      imagePath: null,
      altText: "",
      className: "imageLeft",
    },
  },
];

const MYZAnchortownSession01 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <div
            style={{ backgroundColor: "transparent", fontFamily: "Orbitron" }}
          >
            <main role="main" className="inner cover text-center">
              <img
                src={myzTitle}
                style={{ maxWidth: "400px" }}
                alt="Mutant Year Zero campaign"
              />
            </main>
            <div style={pageHeader}>
              <h1 className="cover-heading">
                Session 01: Anchortown: Chronicles of the Sunshine Plaza
              </h1>
              <p className="lead leadExtra">
                Our mutants celebrate the "birthday" of The Elder.
                <br />
                At the party the politics of the bosses, including PC Boss
                Hulagu, are set on the stage.
                <br />
                Our mutants venture into the zore for the first time and find
                three sickly mutants hiding in shelter and bring them back to
                the Ark.
              </p>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title className="cardTitle">
                Party for The Elder, 03-01-0001
              </Card.Title>
              <Card.Text>
                <ContentContainer data={section1Data} />
              </Card.Text>
              <Card.Title className="cardTitle">Assembly and Threat</Card.Title>
              <Card.Text>
                <ContentContainer data={section2Data} />
              </Card.Text>
              <Card.Title className="cardTitle">Into the Zone</Card.Title>
              <Card.Text>
                <ContentContainer data={section3Data} />
              </Card.Text>
              <Card.Text>
                <Card.Title className="cardTitle">
                  Back to Sunshine Plaza
                </Card.Title>
                <p>
                  On return to the Ark, Hulagu eats grub to heal as his new gang
                  members are decontaminated and clothed.
                </p>
                <p>Next session starts morning of in-game date, 03-02-0001.</p>
                <br />
                Check out Session Body Count at:{" "}
                <a href="./mutant-year-zero-anchortown-population-notable-events">
                  SESSION BODY COUNT AND POPULATION
                </a>
                <br />
                <br />
                As always, feel free to stop by the{" "}
                <a href="./mutant-year-zero-anchortown-graveyard">
                  Anchortown Graveyard to pay your respects
                </a>
                .
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MYZAnchortownSession01;
