import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import septina from "../../../../assets/myz/npc-septina.png";
import dante from "../../../../assets/myz/npc-dante.png";
import saurians from "../../../../assets/myz/player-lair-of-the-sauriants.jpg";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Bookkeeping",
    text: (
      <p>
        06-07-0001:
        <br />
        <br />
        Points are spend and mutation points allocated. More bitterbeast eggs
        hatch and some training goes on. The ARK REPUTATION roll shows that the
        Pure Plant is attacked (again!). But the rolls are beneficial and then
        the actual PURE PLANT PATROL roll is a good one too. The Sunshine Plaza
        finishes the FOUNDRY project which puts them in a position to go for
        some more advanced projects.
        <br />
        <br />
        Gladhand's evangelism back at the Rust Castle pays off as 3 humans and 2
        mutants show up to join the Plaza (aside from ARK REPUTATION roll).
        <br />
        <br />
        NOTE: Altair and Chi Tzu are housed at the Rust Castle along with Summer
        of Love bus as the Gang travels the high seas.
      </p>
    ),
    image: {
      imagePath: saurians,
      altText: `Lair of the Saurians`,
      className: "imageLeft",
      imageCaption: "Lair of the Saurians",
    },
  },
  {
    title: "Lair of the Saurians",
    text: (
      <p>
        The group continues travel through the Dead Blue Sea. They find a trash
        belt with friendly, although scared, human scavengers. They find
        painkillers and an umbrella. They also find a working car which is too
        wedged in the trash belt to be recovered. However, it provides great
        cover during a sudden Ice Storm for Bayzid, Double-Bird, and Chromicus
        whilst the rest of the group on the Steelcrest finds cover on the boat.
        <br />
        <br />
        While the rest of the crew sleeps, Bayazid stays up. Roggi the Whopper
        from Rust Castle wanders over and propositions him. Just as the cuddling
        starts, Bayazid sees the flash of steel in time to catch her arm. They
        struggle and the knife plunges into Roggi from a poorly pushed role.
        Bayazid's yelling wakes everyone. Accusations ensue. As Roggi stands
        arms up and invites Smoke Raven to check her boots for a knife, she
        kicks him like a mule and dives overboard. The IL Gang gives chase:
        Hulagu by diving straight into the water, Double-Bird and Chromicus in
        the canoe, and Smoke Raven bringing the Steelcrest around.
        <br />
        <br />
        Roggi reaches the swampy island that has been their distination.
        Chromicus uses his wings to fly after her. She reaches a huge metal tube
        and is surrounded by scaled humanoids. Chromicus lands nearby unseen and
        sees the saurians take Roggi into the metal tube that we as players know
        is a submarie.
        <br />
        <br />
        The rest of the IL gang arrives and they rush the tower of the
        submarien. Before they can reach Roggi they fight and kill four or five
        saurians as the rest take Roggi below decks. Our guys go below and
        search. They see a blood trail but follow through the next hatch
        carefully. They encounter two saurians who are wielding weapons while
        shuffling backward to try and go deeper in to the sub. Double-Bird uses
        TELEPATHY to put an idea in their mind and to read their thoughts in
        separate uses of the power.
        <br />
        <br />
        They then communicate as well as possible through gestures and
        Double-Birds telepathic connection. The saurians show them the hatch
        further down and take them to the Captains Quarters.
        <br />
        <br />
        In the captian's quarters they meet Dante and his second-in-command
        Septina. It is difficult to communicate still, but with a COMPREHEND
        roll the leaders can be understood. Gladhand offers a glow stick and
        water. The dialog between Dante and Septina, as well as their reactions
        to the IL Gang indicate that Dante feels threatened by Septina. The Gang
        says they want Roggi, but only because she is a traitor that must face
        justice. Dante and Gladhand begin drawing up a deal on the white board
        as Septina receives a message from saurian that came in through the
        torpedo tube. There are more humans and mutants outside!!
        <br />
        <br />
        The IL Gang follows Dante and his people outside where they see a huge
        passenger ferry ship looming over the Steelcrest. This is the infamous
        WRECKER of the Dead Blue Sea. And lording it over the scrap pirates and
        their captain is none other than BOSS BERKE, the perfidious traitor of
        the Plaza. Chromicus shouts to Roggi, "is they why you betrayed us?" and
        she spits at him. Meanwhile, Septina has whispered in Gladhand's ear
        that the saurians will follow her if they cut down Dante rather than
        back down. Hulagu cuts Dante in half, the guards holding Roggi are
        unsure what to do, and we have a dozen or more saurins and scrap pirates
        ready to rumble. Tune in next time.
        <br />
        <br />
        Note: on board the Steelcrest, Elwith is still paralyzed and wounded,
        and Elli stands ready to defend her to the death from Berke and his sea
        scum.
        <br />
        <br />
        Critical Injury Roundup
        <ul>
          <li>
            GLADHAND has a DAMAGED KIDNEY. Ends morning of 06-13-0001. Point of
            damage for roll to Force, Move or Fight.
          </li>
          <li>
            DOUBLE BIRD got rot critical of REDNESS & STENCH. -1 for Empathy
            rolls until morning of 6-8-0001.
          </li>
          <li>
            SMOKE RAVEN has a GROIN HIT. End morning of 06-10-0001. Point of
            damage for roll to Force, Move or Fight.
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: dante,
      altText: `Dante, leader of the Saurians . . . for now.`,
      className: "imageLeft",
      imageCaption: "Dante",
    },
    image2: {
      imagePath: septina,
      altText: `Septina, second-in-command and soon to be leader of the Saurians.`,
      className: "imageRight",
      imageCaption: "Septina",
    },
  },
  {
    title: "Wrapup",
    text: (
      <p>
        Using our modified XP rules, Double-Bird wins the nomination for an XP
        point thanks to his use of TELEPATHY that paved the way to Dante with
        far less fighting and killing than would have otherwise occured.
        <br />
        <br />
        Next session will start on in-game in the dark wee hours of 06-08-2021
        with party on the shore outside the Lair of the Saurians about to face
        Berke and his sea pirates.
        <br />
        <br />
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        .
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
];

const MYZAnchortownSession39 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 39: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  The IL gang arrive at the Lair of the Saurians, form a shaky
                  alliance, and get ready to face down the perfidious Boss Berke
                  and his newly formed band of scrap pirates.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                {/*<Card.Title style={cardTitle}>Shootout at Burger Jim's, 03-27-0001 - 03-27-0001</Card.Title>*/}
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession39;
