import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";
import secretary from "../../../../assets/myz/ephraim-strong-portrait.jpg";
import cage from "../../../../assets/myz/npc-the-green-man-in-cage.jpg";
import greenMan from "../../../../assets/myz/the-green-man-portrait.jpg";
import krao from "../../../../assets/myz/ark-boss-krao.jpg";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Bookkeeping",
    text: (
      <p>
        06-16-0001, 6am:
        <br />
        <br />
        <ul>
          <li>Mutation points and XP spend</li>
          <li>
            Bitterbeasts: 7 hatched. At trained max capacity, humming along like
            a real sideline business.
          </li>
          <li>Pure Patrol: mutants and humans fight, only d6 water.</li>
          <li>
            Ark Reputation: Ark Battle with the Helldrivers (BattleLevel 8)
            versus the Plaza at 6. Helldrivers lose 9 thugs and the Plaza loses
            12 members in a deadly but even battle with no clear winner.
          </li>
        </ul>
        NOTE: Altair is housed at the Rust Castle along with Summer of Love bus.
        Chi Tzu is with the party on the Steelcrest.
        <br />
        <br />
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageRight",
      imageCaption: " ",
    },
  },
  {
    title: "Cage Island",
    text: (
      <p>
        Just before arriving at Cage Island, the IL Gang hears from the goons
        that there is an air compressor fall underwater. Hulagu and Doc Gladhand
        dive into the waters of the Dead Blue Sea. Hulagu takes some damage from
        the cold, rough waters but Doc gets his hands on the air compressor
        which is stowed aboard the Steelcrest. Hulagu heals himself and thanks
        to another cosmetic mutation misfire grows even taller. His frog leg
        mutation and misfire decisions have made our Boss a true long-legged
        mutant.
        <br />
        <br />
        The party meets with First Secretary Ephraim Strong; a seemingly
        mild-mannered mutant with a near OCD penchant for cataloging and
        notating inventory, observational notes, and generally recording nearly
        everything. Gladhand, acting as Hulagu's chief negociator, explains
        their conversation with Gregoria at the Rust Castle. Ephraim has some
        note of the conversation that has reached him somehow ahead of the
        Steelcrest's arrival. The discussion is about:
        <ul>
          <li>
            Trade Partnership: it is made clear that bitterbeasts for aged
            slaves to become citizens of the Plaza are the interesting trade
            bits. It is clear the bitterbeasts will be worth more than a
            one-to-one for slaves.
          </li>
          <li>
            The Green Man: the gang expresses interest in having the Green Man
            show them to Command Center Charlie. Ephraim insists they visit him
            to be sure they are interested in making a deal.
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: secretary,
      altText: `Ephraim Strong, the First Secretary of Cage Island`,
      className: "imageLeft",
      imageCaption: "First Secretary",
    },
  },
  {
    title: "The Green Man",
    text: (
      <p>
        While Smoke Raven, Beto the clone, Hulagu, and Gladhand travel on a raft
        to the island prison of the Green Man, Ghost Bird arrives to speak with
        the First Secretary. The promise of a show of Ghost Bird's powers has
        resulted in waiver of entry fees to Cage Island as well as the
        authorized visit to the Green Man.
        <br />
        <br />
        Ghost Bird stands atop the artifact hoverboard and arranges his robes so
        he arrives for the demonstration as thought floating above the ground.
        Considering that he arrives with robot Theodore hovering next to him,
        it's an impressive entrance. Ephraim is intrique and gives Ghost Bird a
        locket with a photo in it of a female mutant. Ghost Bird uses his
        clairvoyance and speaks of how much Ephraim loved his wife, and how hard
        it was to watch her perish from sickness and rot. Ghost Bird hams it up
        and speaks in language that rabid accountant would appreciate: "you
        tried to measure her last days in your books and could not account for
        the love you felt for her."
        <br />
        <br />
        Meanwhile, the Green Man in his cell has a padlocked hood removed as a
        hatch in the ceiling is opened to let light into the dank, wet cell with
        metal walls spattered with mold. He slowly rises from the ground and
        bends his head toward the light. He appears to be a mutant with wildly
        advanced human plant mutation; or perhaps even some sort of plant being
        with a humanoid appearance. His appearance is alien, even to the variety
        of mutants. He speaks slowly and obliquely. "I have lived so long; if I
        am alive." and so forth. Gladhand askes, "what is your name?" to which
        the Green Man answers with a slow, rambling discourse showing that his
        sense if identity is nearly non-existent, concluding with "I have the
        sensation of being someone, therefore I like to stand in the light." He
        is obviously weakened without light and made stronger when there is
        light. The interview concludes when the Green Man leaves the circle of
        light to look more closely at Hulagu to see if Hulagu's humant plant
        mutation makes him like the Green Man. The Green Man looks disappointed:
        "you are not like me. I am still alone." He collapses in the shadows by
        the door and the interview is over.
        <br />
        <br />
        Returning to Ephraim's office, the First Secretary offers to trade the
        Green Man for Ghost Bird and Theodore the robot. The gang explains this
        will not be possible. Ephraim mentions a mission to retrieve a ghost
        boat (or the scrap helicopter aboard it) in exchange for the Green Man.
        Smoke Raven speaks up about the worth of the Green Man. Ephraim gives
        them the binder of notes on the Green Man to study overnight and sends
        assistant Roberto to discuss the scrap helicopter with Bayazid so he
        knows a bit about what they are looking for.
      </p>
    ),
    image: {
      imagePath: cage,
      altText: `The dank, wet cell that has housed the Green Man for five years at Cage Island.`,
      className: "imageRight",
      imageCaption: "The Green Man in His Cell",
    },
  },
  {
    title: "The Ghost Ship",
    text: (
      <p>
        The binder about the Green Man seems to support the theory that he has
        been alive a very long time. If not a being from the Old Age, he has
        certainly lived long beyond the normal lifespan in the Zone. His capture
        seems to indicate he can be quite powerful if charged with light and he
        can sustain a wild amount of damage. It is theorized he could be near
        invulernable potentially, and at least one chronicler/secretary thinks
        he may even be able to reconstitute/regrow like a plant. It is clear he
        has some kind of military background and there seems to be a possibility
        he does in fact know about Command Center Charlie, but in what capacity
        it is unclear.
        <br />
        <br />
        The gang revisits the Green Man in morning before letting Ephraim know
        of their decision. This time they have Bayzid along who uses
        luminescence to bathe the Green Man in light. He comes all the way to
        the door marvelling at the light as Gladhand holds out the access card
        to Command Center Charlie. The Green Man's abyssal eyes open wide enough
        for pinpoints of redish light to show as he reaches for the card. The
        guard hits the Green Man's arm. The arm seems to lengthen and grow
        strong and the Green Man tosses the guard to the ground in the hall
        outside the cell. "The defenses of Command Center Charlie are alive. I
        am not alive in the same way. That will get you into Command Center
        Charlie. But, you will need me." He speaks with lucidity and then walks
        away from the light.
        <br />
        <br />
        The Gang agrees to go to the ghost ship and retrieve it for the First
        Secretary. Since they asked, they are provided with scrap flotation
        inventory so if the boat is lodged with something piercing it's hull,
        they can plug any below-water hole with floation materials. The boat has
        not moved in over a day; given the recent storms, the boat's stationary
        status is the clue it may have run aground on some scrap.
        <br />
        <br />
        The ghost ship turns out to a huge luxury liner from the Old Age. It is
        rusted with holes in places and covered in thick, verdant vegetation.
        The party currently consists of our six PCs, the three castaways found,
        the four Oilfield clones, four IL Gang members, and the robot Theodore.
        Eighteen party members!
        <br />
        <br />
        The highlights aboard the ship are:
        <ul>
          <li>
            Sarge is broken by wits from Sky King giant bees that attack the
            party. After the kings are defeated, Sarge is lowered onto the
            Steelcrest to hang out with Darryl, Beto, and Harry (clones) and
            Bayazid to guard the boat.
          </li>
          <li>
            Remaining 13 folks explore the Main Deck a bit, focusing on the
            Casino first.
          </li>
          <li>
            A massive swarm of zone rats is encountered in the casino. Except
            for Ghost Bird's flamethrower and Cashel's flamebreath, the party
            lacks fire and explosives to deal with the swarm.
          </li>
          <li>
            After defeating the Zone Rats, the party finds 14 units of scrap and
            a vial of the legendary REGEN chemical.
          </li>
        </ul>
        There is a moment of silence in remembrance of Double Bird the Second,
        knowing that if the REGEN had been found at an earlier time, his
        permanent rot could have been cured.
      </p>
    ),
    image: {
      imagePath: greenMan,
      altText: `The Green Man. Plant, mutant, other? Deluded monstrousity or suvivor from the Old Age?`,
      className: "imageLeft",
      imageCaption: "The Green Man",
    },
  },

  {
    title: "Wrapup",
    text: (
      <p>
        Next session starts in-game 06-18-0001, 9am on board the ghost ship
        inside the casino where Zone Rats have been killed. We again tweaked XP
        to be 3 per character. Then, we discuss cool stuff that happened. Based
        on discussion, GM awards a bonus xp to one of the characters for each
        player. This lifts the competitive(ish) burden of nominations and allows
        more open-ended discussion. Some highlights discussed were:
        <ul>
          <li>Gladhand getting the air compressor.</li>
          <li>Ghost Bird's floating in on hoverboard and clairvoyance.</li>
          <li>Bayazid's use of Luminescence mutation with the Green Man.</li>
          <li>Ghost Bird's INSPIRE adding four successes.</li>
          <li>
            <i>Much more but GM notes critical failure</i>
          </li>
        </ul>
        <b>
          BOSS KRAO dies in session body count. Visit the graveyard for details
          of her passing.
        </b>
        <br />
        <br />
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        .
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: krao,
      altText: `Boss Krao. Chronicler of the Order of the New Dawn of the Sunshine Plaza. Died of a broken heart.`,
      className: "imageLeft",
      imageCaption: "Boss Krao",
    },
  },
];

const MYZAnchortownSession45 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 45: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  The IL Gang arrives at Cage Island, bargain with the First
                  Secretary, and make a plan for the Green Man.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession45;
