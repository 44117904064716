import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import jebe from "../../../../assets/myz/ark-jebe-portrait.jpg";
import garageGetaway from "../../../../assets/myz/session-29-garage-getaway.jpg";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Bookkeeping",
    text: (
      <p>
        05-17-0001: We took care of non-party business first which included:
        <br />
        <br />
        <ul>
          <li>BITTERBEASTS: no hatchlings (14D6) and training rolls made.</li>
          <li>
            PROJECT ROLLS: Expand the Ark takes 2 points damage from Zone
            quakes. +3 points to Defenses, +2 points to Militia, and +2 points
            to Expand the Ark.
          </li>
          <li>
            PURE PLANT PATROL: everything goes smoothly: 9 water, 9 grub, 11
            booze
          </li>
        </ul>
        <br />
        <br />
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: ``,
    },
  },
  {
    title: "Garage Getaway",
    text: (
      <p>
        The battle continues starting with Round 4. Some highlights in order:
        <ul>
          <li>
            The mutant captain Rebeth is broken and before she can get back on
            her feet, Hulagu finishes her off with thorn hands via mutation
            power.
          </li>
          <li>
            Hulagu is in trouble up on the mezzanine. He uses barkskin to
            deflect some damage, but due to misfire it locks down for session.
          </li>
          <li>
            Hulagu is shot and Double Bird does his bodyguard move when Double
            Bird has only one STR left! It succeeds and Double Bird shakes off
            the damage from saving his boss' life.
          </li>
          <li>Double Bird suffers critical injury: DISLOCATED SHOULDER</li>
          <li>
            Earthquake kicks in again with a vengeance. For the rest of the
            battle anyone who does not use their action to DODGE will face a 4D6
            debris attack from falling ceiling, shattering glass, chandeliers
            crashing down, and so forth.
          </li>
          <li>
            Hulagu kills the ex-Skag traitor Morena. Morena and her companions
            who joined the IL Gang (Luna and Kyle) are all dead; that's the
            danger you face if you hang with the IL Gang.
          </li>
          <li>
            Everyone is starting to rush toward the garage except for the folks
            on mezzanine level. Gladhand pushes and breaks his slingshot and
            suffers misfire from his SPRINTER use and is disoriented for the
            next round.
          </li>
          <li>
            Hulagu is broken again and suffers SEVERED LEG ARTERY. Hulagu will
            be DEAD IN ONE MINUTE (i.e. the end of the next round). Hulagu tells
            Double Bird to leave him and get to the buses. And he says it . . .
            like a boss.
          </li>
          <li>
            Double Bird defies Hulagu and stays to try and tourniquet his leg
            and fails. Two of the giver allies try to help. One of them succeeds
            in tourniquet with only three dice to roll for HEAL. And then, in
            the same round, a second giver succeeds with only three dice to roll
            in getting Hulagu back on his feet!
          </li>
          <li>
            Mutants are falling; some crushed by debris as a coup de grace. But
            another elevator load of mutants is nearly at the ground floor and
            the IL gang really starts for the garage: only Sanna Tee remains to
            block their path as Scrunchie writhes on the ground in the turnstile
            whining about the pain.
          </li>
          <li>
            As everyone makes their way toward the garage doors, one of the
            snipers from outside gets a lucky shot and breaks little Katie Kat.
            Her baby fox Trix is chittering and trying to drag her to safety
            from debris under the mezzanine roof, but Trix is too small to move
            his person. Chromicus pulls some hero shit and flys over. He loses
            the three bonus dice he planned on using from earlier shake it off
            with rebel talent to deflect debris damage. With a shoddy base HEAL
            roll Chromicus succeeds in getting Katie Kat back on her feet!
          </li>
          <li>
            The IL Gang makes it to the garage and piles up a bunch of car parts
            and crates against the door.
          </li>
        </ul>
        The garage is oddly quiet and Naphta is nowhere to be seen. There is a
        note on a wooden crate from her telling that the Yellow bus does not
        require a key to start, and detailing the keys for the blue and for the
        red buses. Everyone starts to pile onto the buses with Smoke Raven
        driving the red, Hulagu driving the blue, and Gladhand driving the
        yellow.
        <br />
        <br />
        Just as Gladhand turns the screwdriver that starts the yellow bus,
        Naphta runs out from one of the hallways up by the offices screaming,
        "Katie Kat, get off that bus!! It's a trap, they made me . . ." and then
        she is shot in the back of the head by one of five female mutant guards
        that appear from their hiding places. Everyone on the yellow bus hears
        the warning and a click-click sound. They all have to make MOVE rolls to
        get off the bus as it bursts into flames from an internal booze trap
        explosion.
        <br />
        <br />
        As the air in the garage fills with black smoke from the burning yellow
        bus, everyone from the yellow bus is making MOVE rolls to get on one of
        the other buses. Failure means a pot shot from one of the mutant guards
        that are running around in the garage. The door is starting to give way
        as Katie Kat is shot and broken. No one wants to get off the buses as
        the garage door crashes open and the mutants in the garage are firing
        shots. No except Chromicus that is!
        <br />
        <br />
        Chromicus, at one STR, risks his life again to save Katie Kat and Trix
        and he succeeds. Now the garage doors are open and the buses are
        beginning to roll out. Humans and mutants come busting into the garage.
        More human givers are running toward the buses; a few of them fighting
        the mutant guards, trying to hold them back as their brothers run for
        freedom. Givers are pulled onto the buses via open windows and some
        cling to the buses and fall dead as the mutant guards are firing their
        scrap rifles.
        <br />
        <br />A total of TEN human givers make it onto the buses. The IL Gang
        makes the difficult but survival wise decision to leave the three
        bitterbeasts Tencendur, Bucephalus, and Marengo (as well as their
        motorcycle) behind.
      </p>
    ),
    image: {
      imagePath: garageGetaway,
      altText: `After three sessions and a blow-by-blow battle that takes three
        gaming sessions, the IL Gang escapes the Diamond Center with new allies,
        two battle buses, and some loot.`,
      className: "imageLeft",
      imageCaption: "Garage Getaway",
    },
  },

  {
    title: "Wrapup",
    text: (
      <p>
        We hand-waved travel back to the Sunshine Plaza except for dealing with
        one rot point each for the overnight drive lasting over a day. On the
        buses, Naphta did manage to sneak aboard some loot as follows (which
        indicates consumables remaining after the consumption required to get to
        the Ark):
        <ul>
          <li>Booze: 7, Grub: 9, Scrap: 6.25</li>
          <li>
            Artifacts: FLARE GUN, PROTECTIVE SUIT, CHAINSAW (from mutant Kyle as
            carried by Giver Guard who escaped), and ASSAULT RIFLE
          </li>
        </ul>
        <br />
        <br />
        Next session will begin late morning of 05-19-0001 back at the Sunshine
        Plaza. We awarded five XP at end of session with 6 to Chromicus for the
        dual Katie Kat save. We will do XP spend, award session mutation points,
        and get rolling next time. Rot wash and healing already taken care of.
        <br />
        <br />
        <b>Homebrew Rule</b>: we decided that for bitterbeast egg hatch roll, we
        will only count dice for beasts in the Ark; in other words, any
        bitterbeast used as mounts in the field that were not in the ark at any
        time during the session cannot count toward roll to generate eggs.
        <br />
        <br />
        <b>Consider Scene for Next Time</b>
        <br />
        All players are urged to consider a scene for next session. Here are
        some things to consider not just as scenes, but as story drivers (if
        interesting):
        <ul>
          <li>
            We will have a new PC coming onto stage to replace Jebe. Who dat?
          </li>
          <li>
            What are these earthquakes about? Apparently a crack opened in the
            basement of the Plaza leading to a tunnel? Pequena has disappeared
            and everyone thinks she went into the tunnel . . .
          </li>
          <li>
            What about Berke and Hulagu's men tasked to keep an ear to the
            ground about him? Have they heard anything about him and his group
            out in the Zone?
          </li>
          <li>
            What about Beta the Sour nemesis who is cruel to animals? She was
            close to Berke in some way wasn't she? I wonder if she would be
            fascinated with Katie Kat's pet fox; like, she's never seen a fox
            and maybe it screams in pain in some new, cool way? Exciting!
          </li>
          <li>
            Is Fortran settling in and/or getting along with Krao as a human
            chronicler?
          </li>
          <li>
            What are the battle buses like? Should they have one scrap cannon or
            flamethrower each? Maybe one of the them has two weapons mounted?
            How will there ever be enough booze for the battle buses? They need
            explosives and booze to operate even if only at the Ark for
            protection.
          </li>
          <li>
            Fatima's voice as the leader of the humans is getting stronger and
            while she was acknowledged and respected by the IL Gang as a
            decision maker, maybe she wants to be a boss officially? Maybe
            having 22 new humans arrive shifts political/power balance in her
            favor? The IL Gang including Hulagu are gone a lot and she feels she
            has to pick up the slack. "You know, that Boss Krao and Order of New
            Dawn are ok, but they all about books and sit at table looking at
            things all day. I am one who keep Plaza running." I'll bet she needs
            to be heard and a serious talk needs to happen?
          </li>
          <li>
            Sylvia the Bell Jar (aka Jezebel) is being hidden from the Pure
            Plant. Who is she really? Did Lutrell really impregnate her? What's
            her story and what would happen if her existence was found out by
            PURE PLANT?
          </li>
          <li>
            Rel is little but she is becoming head gearhead at the Plaza. She
            has been thinking about the EXPAND THE ARK and how the Plaza might
            make a workshop, find a way to make booze, and what if there was a
            railroad between the two places from the new sector once Ark is
            expanded?
          </li>
        </ul>
        <br />
        <br />
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: jebe,
      altText: `Jebe the Dog Handler of the Sunshine Plaza. Lived from session 1 to session 28.`,
      className: "imageRight",
      imageCaption: "Jebe, RIP",
    },
  },
];

const MYZAnchortownSession29 = () => {
  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 29: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  The IL gang finishes their epic escape from the Diamond
                  Center!!!
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                {/*<Card.Title style={cardTitle}>Shootout at Burger Jim's, 03-27-0001 - 03-27-0001</Card.Title>*/}
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession29;
