import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import berke from "../../../../assets/myz/ark-boss-berke.jpg";
import loudSue from "../../../../assets/myz/ark-boss-loud-sue.jpg";
import fangTheMountain from "../../../../assets/myz/ark-mutant-fang-the-mountain-stalker.jpg";
import purePlant from "../../../../assets/myz/pure-plant-player-map.jpg";

const cardTitle = {
  paddingTop: 10,
  paddingLeft: 10,
  paddingBottom: 10,
  backgroundColor: "rgba(221, 117, 28, .7) ",
};
const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};
const section1Data = [
  {
    title: "",
    text: (
      <p>
        Mutants bedded down after rising off the Zone rot only to awaken to
        confusion in the Ark. Loud Sue had been attacked and was gravely
        injured. Smoke Raven and Rowbert went to help her acolytes try to
        stabilize her wounds. Hulagu and Jebe went searching with other mutants
        to find the culprit. The pathetic mutant Forcept was found brandishing
        the bat he used to attack Boss Sue. Hulagu stepped up and took control.
        Forcept would not say who "put him up to it because I'm starving" but
        with Hulagu offering at least temporary protection (to try and get
        information), Forcept went along since he thinks he is dead eventually
        regardless. That night
        <span className="wtf"> Loud Sue dies of a crushed skull</span>; she just
        doesn't make it.
      </p>
    ),
    image: {
      imagePath: loudSue,
      altText: "Boss Loud Sue - Proletariat Boss of the Sunshine Plaza.",
      className: "imageLeft",
    },
  },
];
const section2Data = [
  {
    title: "",
    text: (
      <p>
        The party decides they will hang out for three days to let Double Bird
        hit rock bottom and see how he does with survival.
      </p>
    ),
    image: { imagePath: null, altText: "", className: "imageLeft" },
  },
  {
    title: "Reckoning with Berke",
    text: (
      <p>
        Grandma Yoya reported that{" "}
        <b>Elson the dog mutant was beat up by Berke's men</b>. Hulagu and Jebe
        went to have a chat. They made it clear Elson was their boy. Berke made
        it clear this was retaliation for racketeering his gang and that he now
        understands Elson is one of Hulagu's gang. Berke was calm but mildly
        disrespectful; hard to say but let's recall Hulagu did another ninja
        racketeering on Berke's men during this session.
        <br />
        <br />
        Boss Berke based on &nbsp;
        <citation>
          <a
            rel="noreferrer"
            href="https://en.wikipedia.org/wiki/Sydney_Greenstreet"
          >
            Sydney Greenstreet
          </a>
          , the original inspiration for Kingpin in the Marvel comics)
        </citation>
      </p>
    ),
    image: {
      imagePath: berke,
      altText: `
        Berke is a ruthless slave holder that often resorts to violence to solve problems. But, unlike Boss Skag, 
        Berke is more strategic in his application of violence and intimidation. He doesn't want the Ark to fail, but 
        whatever success comes to The People, he plans to rule them all with an iron fist.
        
        Berke's favorite move is to confuse an enemy with his eyes using Reptilian mutation and then slice their throat 
        with ivory-handled straight razor from the Old Age that he oils and cares for with great attention.
        
        Berke hates Boss Hulagu and the hatred is mutual.
        
        Berke has a fixation on fancy clothing and smoking. Any clothing or smoking scrap will fetch a high price or 
        great favor from him.`,
      className: "imageRight",
    },
  },
  {
    title: "Fang the Mountain and the Cure for Pandemic",
    text: (
      <p>
        Rowbert and Smoke Raven feed Fang (stalker) six booze to get him talking
        about the plague. Turns out it's <b>parasitic fungus</b> and{" "}
        <b>
          the only "cure" is to be broken by the disease and then see if you
          live
        </b>
        .
        <br />
        <br />
        Fang art by &nbsp;
        <citation>
          <a
            title="pablomoreno.deviantart.com"
            rel="noreferrer"
            href="https://www.deviantart.com/pablomoreno/art/Roto-667436508"
          >
            Pablo Moreno
          </a>
          .
        </citation>
      </p>
    ),
    image: {
      imagePath: fangTheMountain,
      altText: `
        Fang the Mountain is a Stalker. He is tall but skinny (so Mountain is a joke like Little John from Robin Hood). 
        
        He has dead eyes and manic energy. He likes to avoid other mutants and stay alone. Rarely, usually when booze 
        is involved, he will tell stories about Zone Ghouls.`,
      className: "imageLeft",
    },
  },
  {
    title: "Takin' Care Of Business",
    text: (
      <div>
        <p>
          <b>Smoke Raven breaks from empathy damage</b> over guilt that he
          manipulated Fang while intoxicated to get a free scrap knife. Rowbert
          talks Smoke Raven into realizing Raven's promise to get a gift for
          Fang is sincere and as fellow stalkers it wasn't such a bad thing to
          do.
        </p>
        <ul>
          <li>
            <b>
              Rowert seeks sympathy in the arms of Ariana behind Rook's back
            </b>{" "}
            for empathy damage he took wondering if his pep talk to Smoke Raven
            was enabling bad behavior or promoting the truth.
          </li>
          <li>
            <b>Jebe sells Rocket to Rook</b> for a bullet and a night with
            Astrina the Resourceful.
          </li>
          <li>
            <b>Skut cooks the rot out of eight units of grub</b> gathered over
            three days with his new zone cook talent.
          </li>
          <li>
            <b>Skut makes arrows and durable scrap pistol</b> for Hulagu.
          </li>
          <li>
            <b>
              <u>Project work</u>
            </b>
            : +3 for pigsty. +1 zone wrestling. +0 for temple.
          </li>
          <li>
            On day 6,{" "}
            <strong className="wtf">
              Double Bird dies from the parasitic fungus
            </strong>{" "}
            (good old number 65 on critical injury table - pierced heart).
          </li>
          <li>
            <b>Funeral for Loud Sue and Double Bird</b>: Smoke Raven gathers
            mutants for funeral at temple-in-progress. Loud Sue is celebrated,
            and Double Bird is remembered with reference to his helping bring
            back artificts to the Order of the New Dawn always being even
            influence in a chaotic world.
          </li>
        </ul>
      </div>
    ),
    image: { imagePath: null, altText: "", className: "imageLeft" },
  },
];
const section3Data = [
  {
    title: "",
    text: (
      <p>
        As our party leaves the Ark,{" "}
        <b>
          Astrina shows up to give Jebe a hug and tell them about secret
          expedition
        </b>{" "}
        arranged by Loud Sue to find machinery that creates fresh water and it's
        in the area party is travelling toward.
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageRight",
    },
  },
  {
    title: "",
    text: (
      <p>
        Party travels thru previous zones without mishap and even finding some
        water along the way.
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageRight",
    },
  },
  {
    title: "Sector B04: The Pure Plant",
    text: (
      <p>
        They find the <b>Pure Plant</b>; an old water processing facility in the
        middle of a small, shallow lake. Jebe sends Subu to scout. Subu is hurt
        and runs back at whistle from Jebe and they discover she has been shot
        by an arrow.
      </p>
    ),
    image: {
      imagePath: purePlant,
      altText: `The Pure Plant is an operational industrial ruin run by Zone Ghouls.`,
      className: "imageRight",
    },
  },
  {
    title: "",
    text: (
      <p>
        The party sneaks up to tall metal silos. They scouted and know there are
        humanoids and something in the top of the smaller building tower. They
        are <b>attacked by five zone ghouls</b>. Three are broken and the other
        two flee. That's where our action stopped.
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageRight",
    },
  },
];

const MYZAnchortownSession03 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <div
            style={{ backgroundColor: "transparent", fontFamily: "Orbitron" }}
          >
            <main role="main" className="inner cover text-center">
              <img
                src={myzTitle}
                style={{ maxWidth: "400px" }}
                alt="Mutant Year Zero campaign"
              />
            </main>
            <div style={pageHeader}>
              <h1 className="cover-heading">
                Session 03: Chronicles of the Sunshine Plaza
              </h1>
              <p className="lead leadExtra">
                Boss Loud Sue is assassinated. Culprit is Forcept but he won't
                tell who put him up to it.
                <br />
                The disease is parasitic fungus and Double-Bird dies from it
                after a couple of days waiting to be broken which Fang the
                Mountain says is the only "cure".
                <br />
                Boss Hulagu messes with Boss Berke and other Ark shenanigans.
                <br />
                The party heads into the Zone and finds the sector with the Pure
                Plant as hoped and fights zone ghouls for the first time.
              </p>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title style={cardTitle}>
                The Assassination of Loud Sue, 03-02-0001
              </Card.Title>
              <Card.Text>
                <ContentContainer data={section1Data} />
              </Card.Text>
              <Card.Title style={cardTitle}>
                Parasitic Fungus Infects The People, 03-03 to 03-06-0001
              </Card.Title>
              <Card.Text>
                <ContentContainer data={section2Data} />
              </Card.Text>
              <Card.Title style={cardTitle}>
                Back in the Zone, 03-07-0001
              </Card.Title>
              <Card.Text>
                <ContentContainer data={section3Data} />
              </Card.Text>
              <Card.Title style={cardTitle}>Executive Summary</Card.Title>
              <Card.Text>
                <p>
                  <p>
                    Next session starts right where it left off entire zone
                    expedition at the Pure Plant on 03-07-0001 about mid-day.
                  </p>
                  <br />
                  Check out Session Body Count at:{" "}
                  <a href="./mutant-year-zero-anchortown-population-notable-events">
                    SESSION BODY COUNT AND POPULATION
                  </a>
                  <br />
                  <br />
                  As always, feel free to stop by the{" "}
                  <a href="./mutant-year-zero-anchortown-graveyard">
                    Anchortown Graveyard to pay your respects
                  </a>
                  .
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MYZAnchortownSession03;
