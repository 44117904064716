import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import hetu from "../../../../assets/myz/ark-mutant-hetu-the-beast-handler.jpg";
import bitterbeast from "../../../../assets/myz/bitterbeast.jpg";

const cardTitle = {
  paddingTop: 10,
  paddingLeft: 10,
  paddingBottom: 10,
  backgroundColor: "rgba(221, 117, 28, .7) ",
};
const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Easy Rider, or How I Used Handwave Travel and Survived",
    text: (
      <p>
        03-23-0001 to 03-27-0001: This session spanning five in-game days
        between Ark activities and a short but successful bitterbeast hunt.
        <br />
        <br />
        We all agreed last session that a three-way party split with a
        three-hour table time every two weeks cut into the fun factor. The GM
        didn't feel good about "teleporting" everyone back to the Ark since this
        is a gritty, deadly post-apocalypse after all, so we implemented a new
        houserule called{" "}
        <a href="./mutant-year-zero-handwave-travel-rules">
          Handwave Travel Rules
        </a>
        .
        <br />
        <br />
        We used handwave travel rules to get SmokeRaven and Kublai back to the
        Ark. We also handwaved the travel of Rowbert, Chromicus, Elson, Demyan
        and Raff escaping from the HELLDRIVERS ark.
      </p>
    ),
    image: {
      imagePath: hetu,
      altText: `Hetu is a dog handler who volunteered to run the stables. He has the Beast Whisperer talent and considers himself a Beast Handler`,
      className: "imageRight",
      imageCaption: "Hetu, beast handler",
    },
  },
  {
    title: "Let's Make Deal",
    text: (
      <p>
        Boss Berke summons Boss Hulagu and Smoke Raven for a pow-wow concerning
        recent events.
        <br />
        <br />
        First on the agenda is Berke's plea that Hulagu and Smoke Raven consider
        returning Bianca to Boss Skag before Chromicus returns to "mollify the
        bastard for a little while." Berke points out that Skag is becoming
        emboldened due to friendship with Boss Quiet Bob who is close to making
        at least half of the Ex-Kurtzers from the Elysian Fields amusement park
        his gang members.
        <br />
        <br />
        The long and short of the charged dialog is Berke conspiring with the IL
        Gang to generate ideas about how to kill Boss Skag. They discuss using
        the parasitic fungus if a sample can be found, or another poison; and
        Skag "having an accident" is discussed, including, but not limited to, a
        mishap in a Zone Wrestling match. Additionally, the idea of staging the
        return of Bianca to Skag's gang to act as a double-spy was put forth.
        <br />
        <br />
        Berke vows to help with projects this session and to look into other
        forms of poison.
      </p>
    ),
    image: {
      imagePath: bitterbeast,
      altText: `Bitterbeast: better than a car, if you can train one and not die.`,
      className: "imageLeft",
      imageCaption: "Bitterbeast",
    },
  },
  {
    title: "Getting Loud With Quiet Bob",
    text: (
      <p>
        The party realizes the ExKurtzers are at risk for falling under the sway
        of Quiet Bob. They are conditioned to follow a charismatic madmen.
        Speaking of which, Chromicus speaks to the ExKurtzers.
        <br />
        <br />
        He explains why Quiet Bob is not a good replacement for Kurtz and why
        what happened at Elysian Fields is not a bad thing or Chromicus' fault.
        He was joined by Hulagu and Smoke Raven.{" "}
        <b>Super excellent roleplay and speeches</b> caused the remaining half
        of the Kurtzers in doubt to question themselves. Quiet Bob hears about
        this and appears. Chromicus does an INTIMIDATE roll. While he succeeds,
        there is a fail on the push. Chromicus explains the damage hes takes is
        from the Kurtzers not convinced attacking him. All told, they get 2 more
        Kurtzers to join the IL Gang from undecided.
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "Working Hard",
    text: (
      <p>
        Projects are worked upon with the following results:
        <ul>
          <li>MUSEUM: +1 success (Boss Krao)</li>
          <li>TEMPLE +3 success (Smoke Raven, 1 and Boss Berke, 2)</li>
          <li>
            DEFENSES: +5 success (Hulagu, 3; Chromicus, 1; Jebe, 1; Double Bird,
            0)
          </li>
        </ul>
        In preparation for the bitterbeast hunt, the party has Grandma Yoya use
        her Zone Cook skills to get the rot out of rot grub stored in Skut's
        den.
        <br />
        <br />
        Chromicus' little pal Rel works of creating protection suits while Raff
        from the Helldrivers is drafted to construct scrap armor.
        <br />
        <br />
        After spending 17 scrap in the available time, the party wound up with 2
        durable protection suits and one not durable; and also two non-durable
        scrap armor and one durable scrap armor.
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageRight",
      imageCaption: ``,
    },
  },
  {
    title: "Boss Krao's Information and Bitterbeast Hunt",
    text: (
      <p>
        As party is cooking grub and constructing gear, Boss Krao lets Boss
        Hulagu know that the Elder is in a coma; alive but unresponsive since
        his{" "}
        <a href="./mutant-year-zero-session-12-anchortown">
          freak out about Clark Stanley
        </a>
        . She found writing in a journal of his talking about a settlement
        called the RUST CASTLE and a location (sector D28). He didn't want the
        Sunshine Plaza to have contact with the castle but it is apparently a
        place where gear and other goods can be obtained as the Elder last
        understood it.
        <br />
        <br />
        Also, conversations with the human Demyan rescued from the HELLDRIVERS
        is correlated with post-Ark combat stories that Krao and her chroniclers
        has gathered, and it becomes clear that Patrick of the HELLDRIVERS has
        an artifact that can record sights and sounds. It is usually carried by
        him or in his armored trailer den at the parking garage headquarters.
        <br />
        <br />
        Finally, the bitterbeast hunt begins! The rules for Bitterbeast Hunt are
        found in{" "}
        <a href="./mutant-year-zero-beast-training">
          SmokeRaven Beast Training
        </a>
        .
        <br />
        <br />
        The party explores sector E06. The sector is full of giant piles of
        debris and garbage. Then they see it: a tank. But, next to the tank is a
        bitterbeast. Hentu calms the beast and they lead it back to the ark,
        just like that (NOTE: rules changed from 2D6 target scout points needed
        to 4D6 in Beast Training rules).
        <br />
        <br />
        The gang leaves Ark right away to explore more of the zone. They enter
        sector F05 and find a FAST FOOD JOINT filled with Zone Ghouls (the real
        Ghouls, not like the nice humans we now know). PCs take out six zone
        ghouls in the parking lot and session ends mid-combat.
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "Wrapup",
    text: (
      <p>
        XP for the session was THREE for each mutant.
        <br />
        <br />
        Next session starts 10am, 03-27-0001 in game. The party is mid-combat
        with zone ghouls outside a fast good place with old, rusty gas pumps.
        Status of characters is:
        <br />
        <br />
        <ul>
          <li>
            Rowbert, Skut: napping at the Ark (player absent from session).
          </li>
          <li>
            Hulagu, Jebe, Double Bird, Smoke Raven, Chromicus and Hetu (NPC) in
            sector F05 fighting zone ghouls.
          </li>
        </ul>
        <br />
        Check out Session Body Count at:{" "}
        <a href="./mutant-year-zero-anchortown-population-notable-events">
          SESSION BODY COUNT AND POPULATION
        </a>
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a href="./mutant-year-zero-anchortown-graveyard">
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageRight",
    },
  },
];

const MYZAnchortownSession13 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 13: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  Everyone makes it back to the Ark. Projects are worked upon,
                  mechanations for getting rid of Boss Skag are advancade, and
                  Hetu helps party on first bitterbeast hunt.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Title style={cardTitle}>
                  Sunshine Plaza and Bitterbeast Hunt, 03-23-0001 - 03-27-0001
                </Card.Title>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession13;
