import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Bloodbath Beach",
    text: (
      <p>
        The action picks up with the IL Gang split. Boss Hulagu and Gladhand are
        on the Steelcrest at Command Center Charlie island. The rest of the gang
        are in the Zone. Two IL Gang members guard the wrecked tram while the
        others are on a ridge in neighbor sector scouting the Helldrivers who
        have destroyed the Rust Castle. There are anywhere from a dozen to two
        dozen Helldrivers on the beach of the Rust Castle with a handful
        partying on the tilted captains tower of the now nearly sunk Rust
        Castle.
        <br />
        <br />
        <h4>
          We did not do Ark rolls or Projects and will pick that up next
          session.
        </h4>
        <ul>
          <li>
            Smoke Raven, Ghost Bird, Bayazid, Vanja, and two IL gang members
            scout the beach scene.
          </li>
          <li>
            There are two mechies tied to wooden stakes driven into the beach
            sand; they are near a large bonfire that is one of the popular spots
            for the partying Helldrivers.
          </li>
          <li>
            Bayazid stays back at the ridge as the others go to the Tram and
            return with the IL gang members, all the bullets and other stuff,
            and a barrel of jet fuel. Three barrels of jet fuel are left at the
            tram.
          </li>
          <li>
            A plan is formed: after nightfall, Bayazid will use luminescece to
            sneak around the camp disabling motorcycles and cars. Then, Smoke
            Raven and Vanja will push the jet fuel from the ridge, aiming for
            the large central bonfire. And then, folks on the ridge will lay
            down suppressing fire as someone(s) rush the beach to find an escape
            vehicle.
          </li>
          <li>
            After night falls, the layout is as seen in the picture to the left.
            There are a dozen Helldrivers asleep or passed out at the central
            bonfire with two active, but complacent guards. One range category
            further out there are three more Helldrivers, and finally, on the
            tilted tower of the Rust Castle are four Helldrivers; two asleep and
            two standing guard. There are six motorcycles, ten cars, and two
            mechies on the beach when the IL gang acts.
          </li>
          <li>
            Bayazid activates LUMINESCENCE four times; each time rolling
            misfire, how many targets he can approach (1d4-1), and danger
            (1-in-6). He has no misfire and no danger. He is able to flatten the
            tires on five of the six motorcycles before returning up the ridge.
          </li>
          <li>
            The barrel is rolled down the hill with two successes, and Smoke
            Raven shoots the barrel twice using EXTREME REFLEXES to make sure it
            explodes as it bonces into the bonfire.
          </li>
          <li>
            Vanja is volunteered to sneak back down to the beach and look for a
            vehicle with keys while everyone else lays suppressing gun fire.
          </li>
          <li>
            A guard notices Vanja. He shoots and deals three points damage. The
            ridge folks are alerted by the gunshot and Vanja jumps in the
            nearest vehicle and with a lucky roll it has keys.
          </li>
          <li>
            Vanja drives up the ridge as everyone else fires down on beach
            Helldrivers.
          </li>
          <li>
            The jet fuel explosion has killed six of the twelve Helldrivers
            around the campfire and it left the Mechies damaged but alive. Three
            Helldrivers are down to 1 STR, and three Helldrivers at full 4 STR.
            Not to mention the other 7 in other rage categories.
          </li>
          <li>
            Several of the closer Helldrivers run and hop on motorcycles only to
            find the tires are flat, wasting valuable time.
          </li>
          <li>
            Everyone on the ridge piles into the car. As the car races away,
            Bayazid switches so Smoke Raven can take the driver seat because he
            actually has the talent Zone Driver.
          </li>
          <li>
            Three cars with three Helldrivers are in pursuit. They fall off the
            grid fairly quickly as Smoke Raven tears through the Zone like a
            Raven out of a Glassified Crater in a hot rot zone.
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "Wrapup",
    text: (
      <p>
        <h3>Bookkeeping</h3>
        <h4>
          We did not do Ark rolls or Projects and will pick that up next
          session.
        </h4>
        NOTE: Altair and Summer of Love are dead and/or destroyed.
        <br />
        <br />
        We will pick up with Hulagu and Gladhand at the Steelcreast next time.
        <br />
        <br />
        Next session starts in-game 06-26-0001, approximately 5am.
        <br />
        <br />
        Base XP is 3 per character. Then, we discussed cool stuff that happened.
        Based on discussion, GM awards a bonus xp to one of the characters for
        each player. Some highlights discussed were:
        <ul>
          <li>
            Smoke Raven: did a lot of Helldriver attack planning, sharpshooter
            on the jet fuel barrel, and finally getting everyone out of danger
            with first time use of ZONE DRIVER.
          </li>
          <li>
            Ghost Bird: used Inspire a lot and did a great move to swoop in and
            rescue Mechie with his hoverboard.
          </li>
          <li>
            Bayazid: used LUMINESCENCE to sneak around and sabotage vehicles in
            the Helldriver camp.
          </li>
        </ul>
        Extra points went to Smoke Raven and Bayazid.
        <h6>Critical Injury Roundup</h6>
        <ul>
          <li>
            Bayazid: BLEEDING GUT: You suffer one point of damage for every roll
            you make to Force, Move or Fight. Healing Time: 5 days. Lethality
            was healed. Ends morning of the 30th.
          </li>
        </ul>
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        .
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
];

const MYZAnchortownSession54 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 54: Rust Castle Revenge
                </h1>
                <p className="lead leadExtra">
                  The IL Gang, split between the Dead Blue Sea and the Zone,
                  decide to take revenge on the Helldrivers for the destruction
                  of the Rust Castle.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession54;
