import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import handdrawnSectors from "../../../../assets/myz/zone-map-handdrawn-sectors.jpg";
import nightmareFlowers from "../../../../assets/myz/nightmare-flowers.jpg";
import theHomestead from "../../../../assets/myz/the-homestead-with-sylvia.jpg";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Bookkeeping",
    text: (
      <p>
        05-20-0001:
        <br />
        <br />
        <ul>
          <li>
            PARTY ROLL CALL: Hulagu, Bayazid, Gladhand, Chromicus, Smoke Raven,
            Double Bird.
          </li>
          <li>
            BITTERBEASTS: 0 eggs hatched (with 16D6!!) and at least three beasts
            became trained. We have section to track generic trained beasts and
            beasts to harvest as grub now.
          </li>
          <li>
            PROJECT ROLLS: Zone Wrestling rebuilt since it was broken by the
            earthquakes from the World Worm. Also, Militia finished, Ark Warfare
            DEV at 38 now.
          </li>
          <li>
            PURE PLANT PATROL: everything goes smoothly: 9 water, 12 grub, 3
            booze.
          </li>
          <li>
            NEW GUY: Bayazid really earned his keep by chewing up all the scrap
            and repairing the hell out of rot suits, artifacts, and armor this
            session.
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: ``,
    },
  },
  {
    title: "Emergence from the Underground",
    text: (
      <p>
        The party rests in the bunker where they exited the underground
        labyrinth of tunnels. With the passage of the world worm, the
        underground tunnels are collapsed and essentially gone. The party is
        uncertain where they are in the zone but Smoke Raven can ascertain they
        are likely west of the Ark and no more than four, and probably less,
        sectors away from home.
        <br />
        <br />
        Healing and repairs are the order of the day. And with no threat
        encounter, the party rests easy and prepares to journey home.
        <br />
        <br />
        Back in the Sunshine Plaza, Gladhand milks his emergence with Pequena to
        gain political capital and MAKE A DEAL.
        <br />
        <br />
        SECTOR F04: CHURCH 1
        <br />
        The party finds a church. Smoke Raven uses ARCHAEOLOGY to determine it
        was built in the Old Age. It is missing a wall and half a roof, but
        there are stone basins with clean water. Chromicus notices the ZONE
        LEECHES in the water before any harm is done. Using left over wood
        inside the church, the party starts a fire and cooks the leeches inside
        their basins and then harvests them with ZONE COOK and BUTCHER. Yum!
        <br />
        <br />
        SECTOR F05: we have a major snafu thinking this is an unexplored zone.
        It turns out to be hot, Double Bird got critical rot injury, etc etc.
        And then we realized . . oh hey, that's the sector that was an oasis and
        became part of the SUNSHINE PLAZA thanks to homebrew project EXPAND THE
        ARK. So, we had to roll back about an hour of gaming. The moral of the
        story is: update your map!! The positive outcome is that instead of
        using online app after each session, we are just having players draw on
        explored sectors, and that's faster, easier, more fun, and it looks more
        like a mutant-scrawled zone map!! (see picture).
      </p>
    ),
    image: {
      imagePath: handdrawnSectors,
      altText: `We discovered it's faster, cooler, and more accurate to simply draw on explored sectors`,
      className: "imageLeft",
      imageCaption: "Explored Sectors",
    },
  },
  {
    title: "Home Again, and then The Homestead",
    text: (
      <p>
        05-22-0001 to 05-25-0001: With the sector snafu reversed, the party
        simply travels through the expanded Ark sector to get home. Once back at
        the Ark, the flashlight and gas mask artifacts are returned to Boss Krao
        for the Order to re-catalog. More repairs and creation of rot suits are
        made.
        <br />
        <br />
        We "rediscovered" critical rule about having to roll to take damage with
        each new rot point gained (separate from issue of washing off rot and
        chance of permanence), so now the criticality of rot ruits is wildly
        clear!!
        <br />
        <br />
        As Boss Hulagu showers, Sylvia sneak up on him. She is oblivious to his
        suprise as she says, "I remember home! I don't remember their names but
        I have a family." Armed with her proclamation and some details, Smoke
        Raven helps piece together a 9-sector square that is the likely area
        Sylvia is talking about. It's near the Plaza so the gang gets in gear.
        They load up one of the battle buses, now called Summer of Love, with
        booze. They ride out into the zone with the bus loaded with Sylvia,
        Bayazid, Boss Hulagu, four IL Gang members and Smoke Raven driving the
        bus; meanwhile, Double Bird, Chromicus, and Gladhand are riding
        bitterbeasts. It's an impressive parade.
        <br />
        <br />
        SECTOR E04: HUNTING STORE 1: the gang pulls up to a fairly intact
        hunting store. Inside they are greeted by two mutant CANNIBALS behind
        the counter. Boss Hulagu manipulates the two reluctant vagrants to come
        out from around the counter and approach the party. However, as the
        cannibals move into NEAR range, the Cannibal boss and four more
        cannibals emerge from the back room. It is the cannibal boss that
        escaped from an encounter around a downed airplane way back in "Swamp
        Plane and Cannibals" section of{" "}
        <a
          href="./mutant-year-zero-session-17-anchortown"
          rel="noreferrer"
          target="_blank"
        >
          Session 17
        </a>
        . The cannibal boss screams "eat them" and battle begins. What's amazing
        is that Smoke Raven, with his triple-barreled gun, gets the right
        combination of using Extreme Reflexes and a mutation misfare that he
        fires THREE times in one round, blowing away the cannibal boss and a
        henchmen! And then Boss Hualgu does the frog leap and slices another to
        the ground. In short, the living cannibals flee except for the one who
        fails. He bargains for his life by showing the party where artifacts are
        hidden under the store counter. He is rewarded with a bullet through his
        head, and the party finds a FLASHLIGHT and a WRENCH. Bayazid is geared
        up for repairs!
        <br />
        <br />
        SECTOR F03: NIGHTMARE FLOWERS: this sector is completely covered in
        bright, nearly psychedelic flowers. This dove-tails nicely with the
        theme of Ken Kesey-named battle buses. As the battle bus drives over the
        flowers and the bitterbeasts stomp, Gladhand dismounts to gather some of
        the flowers for Fatima; easily the most romatic, loving event in the
        Zone for many years. However, in true MYZ fashion, Gladhand sees a skull
        tangled in the flower's vines. Suddenly the flowers burrow into his legs
        and hands and pin hiw so he cannot move. As he takes damage he shouts,
        "I'm in trouble! Flamethrower! Now!" Smoke Raven jumps up from behind
        the wheel of Summer of Love, lights up his flamethrower, and starts
        burning a path to Gladhand. The bitterbeasts get skittish from the
        high-pitched keening sound of a sector-sized collective plant monster
        screaming due to flamethrower damage. The party has planned well and all
        riders have the homebrew ZONE RIDER talent, so they control the beasts.
        Smoke Raven gets to Gladhand who is babbling about a house and where
        they have to go . . . clearly the damage and pollen from the hellish
        flowers has addled the fixer. Once back on the bus,it is recalled that
        the field of flowers was one of the details Sylvia gave that made
        targeting her "home" possible. The flowers die as a collective unit and
        now the zone is carpeted in brown and black tendrils that are harmless.
        With the flowers dead, the party finds two artifacts among the flowers
        which we forgot to roll, so they are at the top of next session's
        agenda.
        <br />
        <br />
        SECTOR F02: THE HOMESTEAD: the party sees a house in the distance and
        Sylvia begins spanking the bus seat in front of her, "that's my home!"
        The house is surrounded by a sturdy, spiked fence. As the bus and beasts
        approach, they see a humanoid figure with a shopping cart notice them
        and hurriedly get into the home compound as two other people open the
        gate. Once the party gets close enough to take a look, they are greeted
        by several dogs behind the gate barking like mad. They stink like hell
        and Smoke Raven thinks back to stories of STINK DOGS he has heard.
        Beyond the gate and the wall, there is a well-preserved home with a
        garden on one side, a garage with a car and trailer parked out front,
        and a statue of a bitterbeast in the front yard. As the mutants are
        gawking, the door of the house opens. Four humans step out with the man
        in the middle spitting on the ground and declaring, "by god, I think we
        got us some visitors."
      </p>
    ),
    image: {
      imagePath: nightmareFlowers,
      altText: `Nightmare flowers covered an entire sector. Anyone touching them suffers damage and visions. Easy to never get up again unless you have companions and a flamethrower.`,
      className: "imageLeft",
      imageCaption: `Nightmare Flower Trip`,
    },
  },
  {
    title: "Wrapup",
    text: (
      <p>
        Next session will start on in-game day 05-25-2021 at 3pm with party
        outside the gate of THE HOMESTEAD.
        <br />
        <br />
        Rot and food taken care of so no need at top of session. 4 XP awarded to
        each PC.
        <br />
        <br />
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        .
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: theHomestead,
      altText: `Sylvia claims she has a home, and it seems it may be The Homestead.`,
      className: "imageLeft",
      imageCaption: "The Homestead",
    },
  },
];

const MYZAnchortownSession32 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 32: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  The IL gang straggles back to the Sunshine Plaza. Based on
                  some information from Sylvia (aka Jezebel), they load up the
                  battle bus Summer of Love and straddle a few bitterbeasts to
                  ride into the zone and find Sylvia's family.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                {/*<Card.Title style={cardTitle}>Shootout at Burger Jim's, 03-27-0001 - 03-27-0001</Card.Title>*/}
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession32;
