export let encounters = {
	'no encounter': {
    'freq':0,'flavor':'You wander slowly across the frozen wastes. Only the cold keeps you company.',
		'terrain': [{'name': 'tundra', 'freq': 9}, {'name': 'beneath the ice', 'freq': 23}, {'name': 'ice forest', 'freq': 16}, {'name': 'mountains', 'freq': 7}, {'name': 'ocean', 'freq': 17}, {'name': 'sea ice', 'freq': 18}]
	},
	'blizzard': {
		'freq':0,'flavor':'Dark clouds blot the sky. A cold wind blows across\n' +
			'the plains and you see a whirling darkness moving\n' +
			'relentlessly towards you.','terrain': [
				{'name': 'tundra', 'freq': 1}, {'name': 'ice cap', 'freq': 1}, {'name': 'mountains', 'freq': 1}, {'name': 'ocean', 'freq': 1}, {'name': 'sea ice', 'freq': 1}]
	},
  'needle storm': {
		'freq':0,'flavor':'The cold wind carries snow that stings your faces.\n' +
			'As you press on, the pain gets worse, and you soon\n' +
			'realize that the sharp needles of ice have drawn\n' +
			'blood from your skin.','terrain': [
				{'name': 'tundra', 'freq': 1}, {'name': 'ice cap', 'freq': 1}, {'name': 'ice forest', 'freq': 1}, {'name': 'mountains', 'freq': 1}, {'name': 'ocean', 'freq': 1}, {'name': 'sea ice', 'freq': 1}]
	},
	'rupturing ice': {
		'freq':0,'flavor':'With a rumble, the ice beneath your feet cracks\n' +
			'open! Snow and blocks of ice tumble into the abyss\n' +
			'and you throw yourselves to the ground, desperately\n' +
			'clawing for purchase to stop yourself from\n' +
			'plummeting into the yawning gulf.','terrain': [
				{'name': 'ice cap', 'freq': 1}, {'name': 'mountains', 'freq': 1}, {'name': 'sea ice', 'freq': 1}, {'name': 'beneath the ice', 'freq': 1}]
	},
	'geyser': {
		'freq':0,'flavor':'Something moves beneath the ice, something that\n' +
			'pushes against the ice cap, wanting to get out. Suddenly\n' +
			'the ice cracks and a cloud of boiling water\n' +
			'and steam spews forth from underground.','terrain': [
				{'name': 'ice cap', 'freq': 1}, {'name': 'beneath the ice', 'freq': 1}, {'name': 'mountains', 'freq': 1}]
	},
	'the frozen dead': {
		'freq':0,'flavor':'','terrain': [
			{'name': 'tundra', 'freq': 1}, {'name': 'ice cap', 'freq': 1}, {'name': 'beneath the ice', 'freq': 1}, {'name': 'ice forest', 'freq': 1}, {'name': 'mountains', 'freq': 1}, {'name': 'ocean', 'freq': 1}, {'name': 'sea ice', 'freq': 1}]
	},
	'the orcs': {
		'freq':0,'flavor':'You hear grunting noises before you see a handful of\n' +
			'orcs come riding across the snow-covered wastes. A\n' +
			'huge sled is drawn behind two of the compact and\n' +
			'powerful snow boars the orcs use as mounts.','terrain': [
			{'name': 'tundra', 'freq': 1}, {'name': 'ice cap', 'freq': 1}, {'name': 'ice forest', 'freq': 1}, {'name': 'mountains', 'freq': 1}]
	},
	'misgrown forerunners': {
		'freq':0,'flavor':'A group of warriors moves across the ice and\n' +
			'snow with great difficulty. You glimpse weapons\n' +
			'poking out from under cumbersome hides and\n' +
			'pelts, but other than that you can’t make out any\n' +
			'details.','terrain': [
				{'name': 'tundra', 'freq': 1}, {'name': 'ice cap', 'freq': 1}, {'name': 'beneath the ice', 'freq': 1}, {'name': 'ice forest', 'freq': 1}]
	},
	'alderlander spies': {
		'freq':0,'flavor':'Three colorfully dressed characters ski towards\n' +
			'you, each one pulling a cloth-covered sledge. It is\n' +
			'apparent that the strangers are inexperienced\n' +
			'wanderers, and now and then one of them falls\n' +
			'over.','terrain': [
				{'name': 'tundra', 'freq': 1}, {'name': 'ice cap', 'freq': 1}, {'name': 'mountains', 'freq': 1}]
	},
	'ruined building': {
		'freq':0,'flavor':'The remains of a building lie half buried under\n' +
			'snow and ice. The roof has partially collapsed,\n' +
			'and the walls have crumbled. The place is\n' +
			'eerily quiet.','terrain': [
				{'name': 'tundra', 'freq': 1}, {'name': 'ice cap', 'freq': 1}, {'name': 'ice forest', 'freq': 1}, {'name': 'mountains', 'freq': 1}]
	},
		'the dark cave': {
		'freq':0,'flavor':'A dark opening leads to an even darker cave. You\n' +
				'peer into the shadowy interior and sense danger.\n' +
				'Something in the darkness wants to hurt you.','terrain': [
					{'name': 'ice cap', 'freq': 1}, {'name': 'mountains', 'freq': 1}]
	},
	'treasure hunters': {
		'freq':0,'flavor':'You see a party of adventurers approaching you.\n' +
			'A warrior, a scout… you think you see a scholar\n' +
			'as well, and two other characters. They must be\n' +
			'treasure hunters. They seem to follow your tracks\n' +
			'and slowly move towards you.','terrain': [
				{'name': 'tundra', 'freq': 1}, {'name': 'ice cap', 'freq': 1}, {'name': 'beneath the ice', 'freq': 1}, {'name': 'mountains', 'freq': 1}, {'name': 'ocean', 'freq': 1}, {'name': 'sea ice', 'freq': 1}, {'name': 'ice forest', 'freq': 1}]
	},
	'redrunners': {
		'freq':0,'flavor':'For hours you’ve had the feeling of someone observing\n' +
			'your journey from a distance, but every time\n' +
			'you’ve stopped to scout behind you all you’ve seen\n' +
			'are shadows playing across snow and ice. But now\n' +
			'three shapes emerge from the shadows, elegantly\n' +
			'moving toward you across the difficult terrain.','terrain': [
				{'name': 'tundra', 'freq': 1}, {'name': 'ice cap', 'freq': 1}, {'name': 'beneath the ice', 'freq': 1}, {'name': 'ice forest', 'freq': 1}, {'name': 'mountains', 'freq': 1}, {'name': 'ocean', 'freq': 1}, {'name': 'sea ice', 'freq': 1}]
	},
	'prey of the wolves': {
		'freq':0,'flavor':'At a distance, you see a dark shape fleeing across\n' +
			'the frozen ground. Then, you hear a series of deep\n' +
			'howls, and you realize that the shape is a hunted\n' +
			'prey.','terrain': [
				{'name': 'tundra', 'freq': 1}, {'name': 'ice forest', 'freq': 1}, {'name': 'mountains', 'freq': 1}]
	},
	'nobody home': {
		'freq':0,'flavor':'A small cabin disrupts the white scenery. No\n' +
			'smoke rises from the chimney and the roof is\n' +
			'laden with a heavy burden of snow. The building\n' +
			'appears to be intact, but you see the door wide open.\n' +
			'It has probably been open for some time, judging by\n' +
			'the amount of snow that has blown into the cabin.','terrain': [
				{'name': 'tundra', 'freq': 1}, {'name': 'ice cap', 'freq': 1}, {'name': 'ice forest', 'freq': 1}, {'name': 'mountains', 'freq': 1}]
	},
	'dark wings': {
		'freq':0,'flavor':'A black dot in the sky catches your attention.\n' +
			'Something soars high above your heads. You can’t\n' +
			'make out if it’s a single creature or several, but\n' +
			'soon enough you’ll know… whatever it is that flies\n' +
			'above is now diving towards you.','terrain': [
				{'name': 'tundra', 'freq': 1}, {'name': 'ice cap', 'freq': 1}, {'name': 'mountains', 'freq': 1}, {'name': 'ocean', 'freq': 1}, {'name': 'sea ice', 'freq': 1}]
	},
	'outlaws': {
		'freq':0,'flavor':'“Treasure or your life!” You throw anxious glances\n' +
			'around you, but the wastes are empty. Or is something\n' +
			'moving over there by the rocks? Then, a\n' +
			'man dressed in leather armor and thick furs steps\n' +
			'out from his hiding place. And then another warrior\n' +
			'steps forth, and another… the bandit leader is\n' +
			'soon surrounded by five ruffians who glare greedily\n' +
			'at you.','terrain': [
				{'name': 'tundra', 'freq': 1}, {'name': 'ice cap', 'freq': 1}, {'name': 'ice forest', 'freq': 1}, {'name': 'ocean', 'freq': 1}, {'name': 'sea ice', 'freq': 1}]
	},
	'the whispering wind': {
		'freq':0,'flavor':'You hear voices from ages past whispering in the\n' +
			'wind. They speak of lost kingdoms and of treasures\n' +
			'beyond comprehension, they sing of peril and\n' +
			'indescribable monsters, they whisper of heroes\n' +
			'who will subdue this frozen realm… or unleash\n' +
			'destruction upon the Bitter Reach.','terrain': [
				{'name': 'tundra', 'freq': 1}, {'name': 'ice cap', 'freq': 1}, {'name': 'ice forest', 'freq': 1}, {'name': 'mountains', 'freq': 1}, {'name': 'ocean', 'freq': 1}, {'name': 'sea ice', 'freq': 1}]
	},
	"the ice giant's lament": {
		'freq':0,'flavor':'','terrain': [{
			'name': 'ice cap',
			'freq': 1
		}, {
			'name': 'mountains',
			'freq': 1
		}]
	},
	'the white bear': {
		'freq':0,'flavor':'Something has been stalking you for hours. A beast,\n' +
			'a creature roaring in pain, a monster that follows\n' +
			'your tracks to kill you, one by one. Suddenly, you hear\n' +
			'a gut-wrenching roar and an enormous white shape\n' +
			'rushes towards you. A spear protrudes from its bloodstained\n' +
			'fur and the eyes glow red with bloodlust.','terrain': [
				{'name': 'tundra', 'freq': 1}, {'name': 'ice cap', 'freq': 1}, {'name': 'beneath the ice', 'freq': 1}, {'name': 'ice forest', 'freq': 1}, {'name': 'mountains', 'freq': 1}, {'name': 'ocean', 'freq': 1}, {'name': 'sea ice', 'freq': 1}]
	},
			'a wayward friend': {
		'freq':0,'flavor':'A solitary bark greets you. A worried whimpering\n' +
					'follows, and you see a lone sled-dog watching\n' +
					'you. The dog wears a torn harness. Despite seeming\n' +
					'to be unhurt, it regards you with apprehension.','terrain': [
						{'name': 'tundra', 'freq': 1}, {'name': 'ice cap', 'freq': 1}, {'name': 'mountains', 'freq': 1}]
	},
	'avalanche': {
		'freq':0,'flavor':'A mighty roar rolls across the land. The ground\n' +
			'trembles and when you look around you see an\n' +
			'enormous cloud of white death hurtling towards\n' +
			'you!','terrain': [
				{'name': 'ice cap', 'freq': 1}, {'name': 'mountains', 'freq': 1}]
	},
	'cold, cold, cold': {
		'freq':0,'flavor':'Just when you thought it couldn’t get any colder,\n' +
			'the temperature suddenly drops and an even\n' +
			'more frigid wind blows out of the wastes. You\n' +
			'must seek shelter and warmth before the cold\n' +
			'consumes you.','terrain': [
				{'name': 'tundra', 'freq': 1}, {'name': 'ice cap', 'freq': 1}, {'name': 'ice forest', 'freq': 1}, {'name': 'mountains', 'freq': 1}, {'name': 'ocean', 'freq': 1}, {'name': 'sea ice', 'freq': 1}]
	},
	'a generous offer': {
		'freq':0,'flavor':'A simple song wafts across the wastes. It takes a\n' +
			'while before you spot the singer, a person on skis\n' +
			'pulling a heavily loaded sledge behind them. The\n' +
			'sledge is almost overflowing with trinkets, you\n' +
			'even think that you see an armchair… a rare sight\n' +
			'in the Bitter Reach.','terrain': [
				{'name': 'tundra', 'freq': 1}, {'name': 'ice cap', 'freq': 1}, {'name': 'ocean', 'freq': 1}]
	},
	'tracks in the snow': {
		'freq':0,'flavor':'The snow lies white and untouched before you.\n' +
			'Hour after hour, you have wandered these cold\n' +
			'wastes, with the freezing wind as your only companion.\n' +
			'Then, you spot tracks in the snow, trailing\n' +
			'off into the distance.','terrain': [{
			'name': 'tundra',
			'freq': 1
		}, {'name': 'ice cap', 'freq': 1}, {'name': 'ice forest', 'freq': 1}, {'name': 'mountains', 'freq': 1}]
	},
	'fallen adventurer': {
		'freq':0,'flavor':'You come to a large crack in the ice. From the\n' +
			'depths, you hear a cry for help. As you peer into the\n' +
			'abyss, you see a figure clinging to the wall of ice.','terrain': [
				{'name': 'ice cap', 'freq': 1}, {'name': 'sea ice', 'freq': 1}, {'name': 'mountains', 'freq': 1}]
	},
		'slavers': {
		'freq':0,'flavor':'Lamentation and despair are what you see up\n' +
				'ahead. A group of warriors clad in thick skins\n' +
				'herds an equally large group of prisoners in front\n' +
				'of them through the snow.','terrain': [
					{'name': 'tundra', 'freq': 1}, {'name': 'ice cap', 'freq': 1}, {'name': 'ice forest', 'freq': 1}, {'name': 'mountains', 'freq': 1}]
	},
	'sinksnow': {
		'freq': 0, 'flavor': 'Something feels wrong, but you see nothing out of\n' +
			'the ordinary as you look out across the ice. Then,\n' +
			'the snow beneath your feet flows away and you\n' +
			'begin to sink into the ground. You scramble and\n' +
			'claw for purchase, seeking something to prevent\n' +
			'the snow from devouring you!', 'terrain': [
				{'name': 'ice cap', 'freq': 1}, {'name': 'mountains', 'freq': 1}, {'name': 'sea ice', 'freq': 1}]
	},
		'snow blindness': {
		'freq':0,'flavor':'The snow shines strong and merciless across\n' +
				'the frozen landscape and the ice glistens like\n' +
				'diamonds.','terrain': [
					{'name': 'ice cap', 'freq': 1}, {'name': 'mountains', 'freq': 1}, {'name': 'ocean', 'freq': 1}, {'name': 'sea ice', 'freq': 1}]
	},
	'shooting star': {
		'freq':0,'flavor':'A trail of fire and smoke streaks across the clear\n' +
			'sky. The vision lasts for a second before disappearing\n' +
			'as quickly as it appeared.','terrain': [
				{'name': 'tundra', 'freq': 1}, {'name': 'ice cap', 'freq': 1}, {'name': 'ice forest', 'freq': 1}, {'name': 'mountains', 'freq': 1}, {'name': 'sea ice', 'freq': 1}]
	},
	'northern lights': {
		'freq':0,'flavor':'Fabulously beautiful northern lights spread across\n' +
			'the sky. The colors are stupefying. Red, green, blue\n' +
			'and purple blend in a hypnotic dance. You even\n' +
			'think that you hear ethereal tones that shift with\n' +
			'the movements of the lights.','terrain': [
				{'name': 'tundra', 'freq': 1}, {'name': 'ice cap', 'freq': 1}, {'name': 'ice forest', 'freq': 1}, {'name': 'mountains', 'freq': 1}, {'name': 'ocean', 'freq': 1}, {'name': 'sea ice', 'freq': 1}]
	},
	'the grave': {
		'freq':0,'flavor':'On a hill you see a snow-covered mound. The\n' +
			'shape is oblong, somewhat longer than a fullgrown\n' +
			'human. A feeling of sorrow seeps into your\n' +
			'thoughts. It is a grave that lies before you and someone\n' +
			'has left their friend here.','terrain': [
				{'name': 'tundra', 'freq': 1}, {'name': 'ice cap', 'freq': 1}, {'name': 'beneath the ice', 'freq': 1}, {'name': 'ice forest', 'freq': 1}, {'name': 'mountains', 'freq': 1}, {'name': 'ocean', 'freq': 1}, {'name': 'sea ice', 'freq': 1}]
	},
	'stone circle': {
		'freq':0,'flavor':'Stones tall as humans form a circle ahead of you.\n' +
			'The stones are roughly hewn and lack inscriptions.\n' +
			'The ground inside the circle is bare and you\n' +
			'see the air within shimmer with heat that rises\n' +
			'towards the sky.','terrain': [
				{'name': 'tundra', 'freq': 1}, {'name': 'ice cap', 'freq': 1}, {'name': 'beneath the ice', 'freq': 1}, {'name': 'ice forest', 'freq': 1}, {'name': 'mountains', 'freq': 1}]
	},
			'wendigo': {
		'freq':0,'flavor':'An cold wind sweeps over you. It carries with\n' +
				'it the stench of death and rotten corpses. In the\n' +
				'distance you see a horned shape plod towards you\n' +
				'through the snow.','terrain': [
					{'name': 'ice cap', 'freq': 1}, {'name': 'ice forest', 'freq': 1}, {'name': 'mountains', 'freq': 1}]
	},
		'the soul of the winter king': {
		'freq':0,'flavor':'A shimmering translucent shape strides towards\n' +
			'you, a proud figure clad in white robes and wearing\n' +
			'a crown of ice.','terrain': [
				{'name': 'tundra', 'freq': 1}, {'name': 'ice cap', 'freq': 1}, {'name': 'beneath the ice', 'freq': 1}, {'name': 'ice forest', 'freq': 1}, {'name': 'mountains', 'freq': 1}, {'name': 'ocean', 'freq': 1}, {'name': 'sea ice', 'freq': 1}]
	},
			'mirage': {
		'freq':0,'flavor':'The sunlight dances across the glistening snow.\n' +
			'Suddenly, something moves further ahead. You\n' +
			'see a shape in the distance… someone watching\n' +
			'you, or just some ruins? It is difficult to make out\n' +
			'at this distance.','terrain': [
				{'name': 'ice cap', 'freq': 1}, {'name': 'mountains', 'freq': 1}, {'name': 'ocean', 'freq': 1}, {'name': 'sea ice', 'freq': 1}]
	},
		'battlefield': {
		'freq':0,'flavor':'A trail of blood leads you to a gruesome scene.\n' +
			'You discover a site where several warriors have\n' +
			'fought their last battle. Bodies lie strewn across\n' +
			'the ground, all bearing mortal wounds.','terrain': [
				{'name': 'tundra', 'freq': 1}, {'name': 'ice cap', 'freq': 1}, {'name': 'mountains', 'freq': 1}]
	},
		'sea monster': {
		'freq':0,'flavor':'The waves part and an enormous shape rises\n' +
			'from the depths. Freezing water rains down and\n' +
			'your ship rocks violently','terrain': [
				{'name': 'ocean', 'freq': 1}]
	},
		'derelict': {
		'freq':0,'flavor':'Across the still waters you hear creaking and\n' +
			'groaning. Then, you behold a dark shadow further ahead. A vessel glides across the icy sea.\n' +
			'There is no crew to be seen.','terrain': [
				{'name': 'ocean', 'freq': 1}, {'name': 'sea ice', 'freq': 1}]
	},
		'the ice statues': {
		'freq':0,'flavor':'Ahead of you towers an enormous dragon of\n' +
			'ice. It’s fighting three treasure hunters, also\n' +
			'made from clear ice. None are moving. More\n' +
			'bodies lie strewn across the site, evidently\n' +
			'slain by the dragon… the dead are also sculptures\n' +
			'of ice.','terrain': [
				{'name': 'tundra', 'freq': 1}, {'name': 'ice cap', 'freq': 1}, {'name': 'mountains', 'freq': 1}]
	},
		'hour of the worm': {
		'freq':0,'flavor':'The walls collapse and an enormous mouth\n' +
			'filled with tiny teeth pushes through the opening.\n' +
			'An immense creature follows, and you realize\n' +
			'that you have happened upon one of the terrible\n' +
			'Abyss Worms!','terrain': [
				{'name': 'beneath the ice', 'freq': 1}]
	}
}
let terrainList = ['mountains','tundra','ice cap','beneath the ice','ice forest','ocean','sea ice']
export {encounters as default, terrainList}