import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";
import casualties from "../../../../assets/myz/zone-war-casualties.webp";
import docGladhand from "../../../../assets/myz/ark-mutant-gladhand-portrait.jpg";
import plutonia from "../../../../assets/myz/plutonia.webp";
import mosaic from "../../../../assets/myz/cinema-poster-bomb-worship.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Session 61: Do Not Drink the Kool-Aid",
    text: (
      <p>
        Smoke Raven is standing outside the battlewalker with a broken, bleeding
        Patrick looking up at him. Even broken, the leader of the Helldrivers
        manages to say "I thought it would be you. Hulagu doesn't have the balls
        to step outside that walker". And so begins an epic parley between
        Patrick and Smoke Raven. Meanwhile, the stragglers in the Zone Warrior
        road battle are still raging.
        <br />
        <br />
        Gladhand is riding his bitterbeast in battle. He uses ROT EATER and his
        eyes glow red as an aura of black energy rises up through his body and
        the rot is eaten. Ford, the scrap pirate from the Dead Blue Sea, is
        killed with a flamethrower mounted on a dune buggy by Helldriver
        minions.
        <br />
        <br />
        Patrick is drawing a map in the sand for Smoke Raven, revealing
        everything he has heard about the layout of the mysterious underground
        ark of the Nova Cult. Smoke Raven has Patrick stand on top of the broken
        Juggernaut vehicle and wave white flag to signify peace to helldrivers
        arriving on the scene. The rest of the Helldrivers and the IL Gang
        arrive and things are tense.
        <br />
        <br />
        Hulagu orders Doc Gladhand to talk to the Helldrivers and insist that
        they voluntarily disarm. Gladhand uses his tuxedo, sunglasses, and
        perfume to try and win over the gnarly bikers. In a manipulate versus
        sense emotion between Doc Gladhand and Patrick respectively Gladhand
        wins the day, but not before he over gesticulates and rips the tuxedo
        and the lens pops out of the sunglasses (i.e. gear damage from pushed
        roll). Gladhand looks down at the tuxedo as the lens pops out and he
        laughs.
        <br />
        <br />
        Ghost Bird lays hands on the closed metal circle closing off the Nova
        Cult ark. His mutation supercharges and he sees the hisory of the bunker
        in his minds eye.
        <br />
        <br />
        Dr. Theopolis arrives with Verter and the pregnant Abbetina. With Dr.
        Theopolis in play, the Nova Cult welcomes the IL Gang into their home;
        that is, if sulky silence, fish-eyed stares, and creepy chanting are
        "welcoming".
        <br />
        <br />
        <h5>XP Discussion</h5>
        <ul>
          <li>
            Doc Gladhand: epic manipulation of Patrick and Helldrivers. Tore
            tuxuedo and broke sunglasses.
          </li>
          <li>Doc Gladhand: spore cloud took out dune buggy.</li>
          <li>Smoke Raven: great roleplay with handling Patrick.</li>
          <li>Bayazid: rocked jury-rigs all night.</li>
          <li>
            Ghost Bird: clairvoyance on the metal portal to the Cult HQ was
            cool.
          </li>
        </ul>
        Extra XP points went to: Gladhand, Smoke Raven, and Bayazid.
      </p>
    ),

    image: {
      imagePath: plutonia,
      altText: `Plutonia was the deluded leader of the nuclear death cult called the Nova Cult.`,
      className: "imageLeft",
      imageCaption: "Plutonia",
    },
  },
  {
    title: "Session 62: Unbridled Power and the Passing of Doc Gladhand",
    text: (
      <p>
        In the Bomb Silo, Bayzid is trying to understand the bomb controls.
        Multi skitters over the surface of the control panels but watchful
        cultists will not let Bayazid touch the controls.
        <br />
        <br />
        Meanwhile, the living quarters are being toured by a helldriver minion,
        Chromicus, and Ghost Bird. A little boy named Zero and his baby sister
        Infinity approach Ghost Bird. Zero is wearing a too-large, but smooth
        and obvious artifact helmet. "Oh cool" he says pointing to Ghost Bird's
        hoverboard. Ghost Bird lets Zero try the hoverboard and gives him a
        fatherly pat on the head. This is of course an excuse to use
        clairvoyance on the helmet. The mutation supercharges and Ghost Bird
        sees that Zero and Infinity are children of Verter and Abbetina but were
        not judged as luminescent by Plutonia. He also gets the full stats on
        the helmet. It acts as armor PR 4 and deflects almost all psychic
        mutations. While wearing the helmet, the wearer is -1 SCOUT and cannot
        use psychic mutations themselves.
        <br />
        <br />
        When Ghost Bird offers to trade the hover board for the helmet, Zero
        encourages him to sneak into supply room where theres other neat stuff.
        Chromicus falls on the ground to distract the cultists in the nursery.
        The helldriver catches on and hams it up as well. Chromicus has a
        discussion with cultist named Thalia. She sits next to him and senses he
        is hurt in his mind. Chromicus realizes that even though he is putting
        on a show to distract the Nova Cultists, that he has in fact lost nearly
        everything he cares about. He lost Rel and Vanya in the Zone War. He
        lost his people at Elysium Fields. He even lost himself when he switched
        from Enforcer to Slave. Thalia is caught up in his emotions and Ghost
        Bird hunts for treasure unimpeded and without any rolls needing to be
        made.
        <br />
        <br />
        He finds WALKIE-TALKIES, a BIG STICK OR SOMETHING, and a KNIFE. The big
        stick and knife have tech levels of 70 and require some time to figure
        out. So, Ghost Bird pockets the artifacts and the cultists are none the
        wiser.
        <br />
        <br />
        Meanwhile in the Cult Throne room, Plutonia greets Patrick, Doc
        Gladhand, Smoke Raven, and Boss Hulagu. Above her hangs a disco ball.
        The cultists and the IL Gang sit on meditation cushions as Plutonia sits
        on a simple chair in front of the mosaic artwork on the wall behind her.
        The mosiac shows cultists worshipping a bomb. Plutonia thanks the IL
        Gang for taking care of Abbetina. Smoke Raven tells Plutonia that the
        Sunshine Plaza is seeking Eden. Plutonia laughs and says, "that fairy
        tale again?" Boss Hulagu asks, "You don't believe in Eden?", to which
        Plutonia says, "I don't believe in Eden because you cannot believe in
        something that does not exist. Let me show you something."
        <br />
        <br />
        Plutonia opens a cabinet using a silver key on a necklace she is
        wearing. Inside the cabinet are numerous VHS tapes. The IL Gang still
        needs to find the VHS Tape metaplot item and they are sure they want to
        have a look when they can. Plutonia plays a video showing a montage of
        images featuring a cult worshipping a bomb, statues of monkey men
        burning, all of which plays as she sermonizes (it is of course Planet of
        the Apes footage).
        <br />
        <br />
        Plutonia preaches fervently of how the elders from the time before
        created the Zone with a bomb, and the Zone IS Eden. And with their bomb,
        and the birth of the Luminescent Child, the Nova Cult will create a
        second, better Eden. She ends her sermon shouting "LET THE CONTRACTIONS
        BEGIN" in a voice that echoes supernaturally throughout the cult silo.
        <br />
        <br />
        The IL Gang in the throne room feels terrified, each wrapped in
        nightmares in their own mind, and they realize they are suffering an
        amplified MIND TERROR attack. Doc Gladhand, Smoke Raven, Patrick of the
        Helldrivers, and Boss Hulagu are all broken. Then, events spiral quickly
        something like this:
        <ul>
          <li>
            Plutonia drags Hulagu over to a cage at the side of the room. Inside
            is a glowing feral ghoul wearing a Mimir jumpsuit. "One of your
            friends from Elysian Fileds" Plutonia croons as Hulagu suffers a rot
            attack. Plutonia then licks his face and eats the rot with one of
            her mutations.
          </li>
          <li>
            Smoke Raven calls out in his mind to his former lover Scrap Oracle
            Cassandra. "This is the time. We need you."
          </li>
          <li>
            Cassandra heals the four PCs attributes from afar and will send help
            as well.
          </li>
          <li>
            Smoke Raven bounds up once his wits are back and spends 8 MP to
            MANBEAST and rip Plutonia to shreds while shouting, "Dont mess with
            the claw."
          </li>
          <li>
            Plutonia dodges supernatually and takes half-damage. Then, she goes
            invisible.
          </li>
          <li>
            Plutonia call out alerts the silo and everyone springs into action
            and combat begins.
          </li>
          <li>Chromicus tries to comprehend the BIG STICK but fails.</li>
          <li>
            Hulagu protects himself from pyroteknetic flames with insectoid
            mutation.
          </li>
          <li>
            Ghost Bird comphenends the BIG STICK which is in fact a BOOMSTICK
            (mythic artifact that makes a shot gun look like a BB Gun).
          </li>
          <li>
            Portal appears thanks to Cassandra, and through the portal steps
            Fatima, Grit, Cad and Maxim.
          </li>
          <li>
            Chromicus goes on a killing spree, flying and getting supercharged
            misfire at least twice. He kills three cultists in one round, then
            two in the next round and so on. His final body could was nearly
            half the entire Nova Cult.
          </li>
          <li>
            Bayazid unlocks the round portal to the outside so the helldrivers
            and Hetu Jr can enter the silo.
          </li>
          <li>
            Hulagu gets broken again but then gets up when he takes an
            anti-depressant pill; lord we knew those might come in handy!
          </li>
          <li>
            Fatima splits cultist head with axe; the cultist that was
            threatening Patrick who dies crying, "no, no! Not like this"
          </li>
          <li>
            Ghost Bird has meanwhile learned that the BOOMSTICK in fact booms.
            Our formerly floating clairvoyant mutant (who we think may have only
            killed once or maybe never before), is blasting cultists left and
            right, holding them off in the corridor between the living quarters
            and the headquarters.
          </li>
          <li>
            Doc Gladhand is among the mutants racing to the Missile Silo. He
            shoots a cloud of spores at a cultist who in their dying throes
            reflects a psychic blast back at Gladhand who dies. (It was a
            mutation mishap where he took damage from his own mutation. It broke
            him and on rolling critical injury he got a 66 for crushed skull).
          </li>
          <li>
            Fatima collapses in the hallway with Gladhand's body as others race
            past her to the missile silo.
          </li>
          <li>
            Bayazid sat broken in the corner watching Plutonia appear, finish
            arming the nuclear bomb, then disappear again.
          </li>
          <li>
            Everyone is rolling SCOUT to try and find Plutonia and fighting off
            cultists.
          </li>
          <li>
            Boss Hulagu gets another cosmetic misfire and his face becomes like
            a praying mantis; he doesn't even begin to look like a humanoid any
            longer above the shoulders.
          </li>
          <li>
            Hulagu SCOUTS and sees a wavering outline by the ladders leading
            upward and shoots Plutonia. She dies laughing and spitting blood.
            "You don't have the code. You can't stop the contractions!!"
          </li>
          <li>
            Smoke Raven talks down the Helldrivers who are angry when they learn
            Patrick has died. Outnumbered and probably outthought, they are
            mollified.
          </li>
          <li>
            Bayazid suggests cannibalizing MULTI so he can be turned into a
            password hacker to help stop the missle countndown or at least
            redirect the missile. Chromicus who feels he has lost nearly
            everything does not want to. Bayazid respects that and hacks away
            with killer JURY-RIG rolls.
          </li>
          <li>
            Bayazid aims the nuclear weapon toward the farthest NE sector of the
            Zone where the 9 square wasteland and 6 squares outward rot wave
            will do the least damage.
          </li>
          <li>
            The IL Gang grabs the VHS Tape for the METAPLOT and goes outside
            with Gladhand's body. The Helldrivers bring Patrick's body.
            Together, everyone watches the devastating mushroom cloud on the
            horizen level 9 sectors!
          </li>
          <li>
            The sight of such unbridled power brings clarification to one and
            all who witness it. The helldrivers will return to their garage with
            a message to the survivors of the Zone War.
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: docGladhand,
      altText: `Doc Gladhand was the fixer of the Plaza, Hulagu's spokesperson, and fathere of Fatima's child.`,
      className: "imageLeft",
      imageCaption: "RIP Doc Gladhand",
    },
    image2: {
      imagePath: mosaic,
      altText: `Doc Gladhand was the fixer of the Plaza, Hulagu's spokesperson, and fathere of Fatima's child.`,
      className: "imageRight",
      imageCaption: "RIP Doc Gladhand",
    },
  },
  {
    title: "Wrapup",
    text: (
      <p>
        The XP discussion gave extra points to Ghost Bird for the mythic
        artifact hunt and BOOMSTICK action, Boss Hulagu for getting the killing
        blow on an invisible Plutonia, and Chromicus who was basically flying
        Rambo with a spiked bat!
        <br />
        <br />
        Next session we are going to do a time lapse of about 9-12 months back
        at the Ark.
        <br />
        <br />
        We know there is only one artifact left at the Fire Cult. So we will
        weave that visit in with a montage of the Ark and after some time the
        party will have decoded all there is to know about Eden and then the
        final chapter begins.
        <br />
        <br />
        Questions and things to think about for next session include:
        <ul>
          <li>How will Doc Gladhand be remembered/honored?</li>
          <li>
            How do The People react to Boss Hulagu with praying mantis face?
          </li>
          <li>
            Will Fatima's child be a boy or a girl? Will it take after her or
            departed Gladhand? What is the child's name?
          </li>
          <li>
            What replacement character will come to the Ark to fill the party
            out?
          </li>
          <li>
            Did Scrap Oracle Cassandra's efforts to assist from afar cost her?
            Is she ok? Did she go back to Noatun Estates?
          </li>
          <li>
            What kind of projects will be built and completed? How will the
            projects impact allies such as The Homestead, The Pure Plant, The
            Silver Egg, the saurians, and so forth?
          </li>
          <li>
            Will Chromicus go to live the the Silver Egg and lead the believers
            with Quiet Bob as his general?
          </li>
          <li>
            What place does Katie have in the Plaza as more children are born?
          </li>
          <li>
            Who running the Order of the New Dawn now? (i.e. the chroniclers)
          </li>
          <li>
            Will Grit ask Smoke Raven to marry her after the chroniclers tell
            her about a ceremony from the olden times?
          </li>
          <li>Does Kublai stay loyal to the aging Hulagu?</li>
          <li>And so on . . .</li>
        </ul>
        <br />
        <br />
        <h4>CRITICAL INJURY ROUNDUP</h4>
        <ul>
          <li>
            Chromicus: SPRAINED WRIST, done by the time they arrive back at the
            Ark which will not be done as hex crawl.
          </li>
        </ul>
        Note: no population effects this session since we are near end of
        campaign.
        <br />
        <br />
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        .
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects, especially to Doc Gladhand
        </a>
        .
      </p>
    ),
    image: {
      imagePath: casualties,
      altText: `Zone War Casualties include Chromicus' sweetheart Vanja and Rel.`,
      className: "imageRight",
      imageCaption: "",
    },
  },
];

const MYZAnchortownSession61_62 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 61-62: Death of Doc Glandhand
                </h1>
                <p className="lead leadExtra">
                  The Nova Cult is defeated, a nuclear bomb is detonated, and we
                  say goodbye to Doc Gladhand.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession61_62;
