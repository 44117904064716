import React from "react";
import Iframe from "react-iframe";

class IFrameComponent extends React.Component {
  render() {
    return (
      <div>
        {this.props.creditName && (
          <div>
            <a
              rel="noreferrer"
              target="_blank"
              href={this.props.creditUrl}
            >
              Artwork by {this.props.creditName}
            </a>
          </div>
        )}
        <Iframe
          frameborder="0"
          url={this.props.targetUrl}
          width="90%"
          height="1000px"
          id=""
          className=""
          display="initial"
          position="block"
        />
      </div>
    );
  }
}

export default IFrameComponent;
