import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import NavBarMYZ from "../../../assets/navbar-icon-myz.jpg";
import NavBarAnchortown from "../../../assets/navbar-icon-anchortown.jpg";

const MYZNavBar = () => {
  return (
    <NavDropdown title="Mutant Year Zero" id="basic-nav-dropdown">
      <Navbar bg="dark" variant="dark" expand="md" sticky="top">
        <Navbar.Brand href="#">MYZ</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <a href=".">
              <img src={NavBarMYZ} alt="SmokeRaven" style={{ maxHeight: 40 }} />
            </a>
          </Nav>
          <NavDropdown title="Rules and Game Aids" id="basic-nav-dropdown">
            <NavDropdown.ItemText></NavDropdown.ItemText>
            <NavDropdown.Item href="/mutant-year-zero-civil-war-rules">
              Civil War Rules
            </NavDropdown.Item>
            <NavDropdown.Item href="/ark-projects-summary">
              Ark Projects Summary
            </NavDropdown.Item>
            <NavDropdown.Item href="/mutant-year-zero-beast-training">
              Beast Training
            </NavDropdown.Item>
            <NavDropdown.Item href="/mutant-year-zero-scrap-rules">
              Custom Scrap Rules
            </NavDropdown.Item>
            <NavDropdown.Item href="/mutant-year-zero-errata">
              Errata
            </NavDropdown.Item>
            <NavDropdown.Item href="/mutant-year-zero-handwave-travel-rules">
              Handwave Travel Rules
            </NavDropdown.Item>
            <NavDropdown.Item href="/mutant-generator">
              Mutant Generator
            </NavDropdown.Item>
            <NavDropdown.Item href="/mutant-year-zero-sector-generator">
              Roll20: Sector Generator
            </NavDropdown.Item>
            <NavDropdown.Item href="/mutant-year-zero-rot-critical-injuries">
              Rot Critical Injuries
            </NavDropdown.Item>
            <NavDropdown.Item href="/scrap-generator">
              Scrap Generator
            </NavDropdown.Item>
            <NavDropdown.Item href="/mutant-year-zero-scenes">
              Scenes
            </NavDropdown.Item>
          </NavDropdown>

          <Navbar bg="dark" variant="dark" expand="md" sticky="top">
            <Navbar.Brand href="#">Anchortown</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <a href=".">
                  <img
                    src={NavBarAnchortown}
                    alt="SmokeRaven"
                    style={{ maxHeight: 40 }}
                  />
                </a>
              </Nav>
              <NavDropdown
                title="Maps, Graveyard, Misc."
                id="basic-nav-dropdown"
              >
                <NavDropdown.ItemText></NavDropdown.ItemText>
                <NavDropdown.Item href="/mutant-year-zero-anchortown-population-notable-events">
                  Anchortown Population & Notable Events
                </NavDropdown.Item>
                <NavDropdown.Item href="/mutant-year-zero-anchortown-graveyard">
                  Anchortown Graveyard
                </NavDropdown.Item>
                <NavDropdown.Item href="/mutant-year-zero-cover-gallery">
                  Anchortown Art Gallery
                </NavDropdown.Item>
                <NavDropdown.Item href="/mutant-year-zero-anchortown-map">
                  Anchortown Map
                </NavDropdown.Item>
                <NavDropdown.Item href="/mutant-year-zero-anchortown-map-grid-exploration">
                  Anchortown Map: Grid Exploration
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Sessions 61-65" id="basic-nav-dropdown">
                <NavDropdown.Item href="/mutant-year-zero-session-64_65-anchortown">
                  Anchortown: Session 64-65:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10-27-2023
                </NavDropdown.Item>
                <NavDropdown.Item href="/mutant-year-zero-session-63-anchortown">
                  Anchortown: Session 63:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;09-15-2023
                </NavDropdown.Item>
                <NavDropdown.Item href="/mutant-year-zero-session-61-62-anchortown">
                  Anchortown: Session 61-62:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;09-01-2023
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Sessions 51-60" id="basic-nav-dropdown">
                <NavDropdown.Item href="/mutant-year-zero-session-58-60-anchortown">
                  Anchortown: Session 58-60:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;07-22-2023
                </NavDropdown.Item>
                <NavDropdown.Item href="/mutant-year-zero-session-56-57-anchortown">
                  Anchortown: Session 56-57:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;05-19-2023
                </NavDropdown.Item>
                <NavDropdown.Item href="/mutant-year-zero-session-55-anchortown">
                  Anchortown: Session 55:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;03-18-2023
                </NavDropdown.Item>
                <NavDropdown.Item href="/mutant-year-zero-session-54-anchortown">
                  Anchortown: Session 54:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;03-04-2023
                </NavDropdown.Item>
                <NavDropdown.Item href="/mutant-year-zero-session-53-anchortown">
                  Anchortown: Session 53:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;02-17-2023
                </NavDropdown.Item>
                <NavDropdown.Item href="/mutant-year-zero-session-52-anchortown">
                  Anchortown: Session 52:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;02-04-2023
                </NavDropdown.Item>

                <NavDropdown.Item href="/mutant-year-zero-session-51-anchortown">
                  Anchortown: Session 51:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;12-16-2022
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Sessions 41-50" id="basic-nav-dropdown">
                <NavDropdown.Item href="/mutant-year-zero-session-50-anchortown">
                  Anchortown: Session 50:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;12-03-2022
                </NavDropdown.Item>
                <NavDropdown.Item href="/mutant-year-zero-session-49-anchortown">
                  Anchortown: Session 49:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;11-11-2022
                </NavDropdown.Item>
                <NavDropdown.Item href="/mutant-year-zero-session-48-anchortown">
                  Anchortown: Session 48:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10-28-2022
                </NavDropdown.Item>
                <NavDropdown.Item href="/mutant-year-zero-session-47-anchortown">
                  Anchortown: Session 47:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10-14-2022
                </NavDropdown.Item>
                <NavDropdown.Item href="/mutant-year-zero-session-46-anchortown">
                  Anchortown: Session 46:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;09-30-2022
                </NavDropdown.Item>

                <NavDropdown.Item href="/mutant-year-zero-session-45-anchortown">
                  Anchortown: Session 45:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;09-16-2022
                </NavDropdown.Item>
                <NavDropdown.Item href="/mutant-year-zero-session-43_44-anchortown">
                  Anchortown: Session 43-44:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;08-26-2022
                </NavDropdown.Item>
                <NavDropdown.Item href="/mutant-year-zero-session-42-anchortown">
                  Anchortown: Session 42:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;08-12-2022
                </NavDropdown.Item>
                <NavDropdown.Item href="/mutant-year-zero-session-41-anchortown">
                  Anchortown: Session 41:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;07-29-2022
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Sessions 31-40" id="basic-nav-dropdown">
                <NavDropdown.ItemText></NavDropdown.ItemText>

                <NavDropdown.Item href="/mutant-year-zero-session-40-anchortown">
                  Anchortown: Session 40:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;07-15-2022
                </NavDropdown.Item>
                <NavDropdown.Item href="/mutant-year-zero-session-39-anchortown">
                  Anchortown: Session 39:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;06-24-2022
                </NavDropdown.Item>
                <NavDropdown.Item href="/mutant-year-zero-session-38-anchortown">
                  Anchortown: Session 38:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;05-13-2022
                </NavDropdown.Item>
                <NavDropdown.Item href="/mutant-year-zero-session-37-anchortown">
                  Anchortown: Session 37:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;04-15-2022
                </NavDropdown.Item>
                <NavDropdown.Item href="/mutant-year-zero-session-36-anchortown">
                  Anchortown: Session 36:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;04-01-2022
                </NavDropdown.Item>
                <NavDropdown.Item href="/mutant-year-zero-session-35-anchortown">
                  Anchortown: Session 35:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;03-18-2022
                </NavDropdown.Item>
                <NavDropdown.Item href="/mutant-year-zero-session-34-anchortown">
                  Anchortown: Session 34:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;03-04-2022
                </NavDropdown.Item>
                <NavDropdown.Item href="/mutant-year-zero-session-33-anchortown">
                  Anchortown: Session 33:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;02-04-2022
                </NavDropdown.Item>
                <NavDropdown.ItemText></NavDropdown.ItemText>
                <NavDropdown.Item href="/mutant-year-zero-session-32-anchortown">
                  Anchortown: Session 32:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;01-22-2022
                </NavDropdown.Item>
                <NavDropdown.ItemText></NavDropdown.ItemText>
                <NavDropdown.Item href="/mutant-year-zero-session-31-anchortown">
                  Anchortown: Session 31:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;01-07-2022
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Sessions 01-30" id="basic-nav-dropdown">
                <Navbar bg="dark">
                  <NavDropdown
                    bg="dark"
                    title="Sessions 21-30"
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown.Item href="/mutant-year-zero-session-30-anchortown">
                      Anchortown: Session 30:
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;12-17-2021
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/mutant-year-zero-session-29-anchortown">
                      Anchortown: Session 29:
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;12-03-2021
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/mutant-year-zero-session-28-anchortown">
                      Anchortown: Session 28:
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;11-19-2021
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/mutant-year-zero-session-27-anchortown">
                      Anchortown: Session 27:
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;11-05-2021
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/mutant-year-zero-session-26-anchortown">
                      Anchortown: Session 26:
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10-22-2021
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/mutant-year-zero-session-25-anchortown">
                      Anchortown: Session 25:
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10-08-2021
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/mutant-year-zero-session-24-anchortown">
                      Anchortown: Session 24:
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;09-25-2021
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/mutant-year-zero-session-23-anchortown">
                      Anchortown: Session 23:
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;09-11-2021
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/mutant-year-zero-session-22-anchortown">
                      Anchortown: Session 22:
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;08-07-2021
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/mutant-year-zero-session-21-anchortown">
                      Anchortown: Session 21:
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;07-23-2021
                    </NavDropdown.Item>
                  </NavDropdown>
                </Navbar>

                <Navbar bg="dark">
                  <NavDropdown
                    bg="dark"
                    title="Sessions 11-20"
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown.Item href="/mutant-year-zero-session-18-19-20-anchortown">
                      Anchortown: Session 18-20: 07-09-2021
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/mutant-year-zero-session-17-anchortown">
                      Anchortown: Session 17:
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;05-22-2021
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/mutant-year-zero-session-16-anchortown">
                      Anchortown: Session 16:
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;05-07-2021
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/mutant-year-zero-session-15-anchortown">
                      Anchortown: Session 15:
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;04-24-2021
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/mutant-year-zero-session-14-anchortown">
                      Anchortown: Session 14:
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;04-09-2021
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/mutant-year-zero-session-13-anchortown">
                      Anchortown: Session 13:
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;03-28-2021
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/mutant-year-zero-session-12-anchortown">
                      Anchortown: Session 12:
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;03-14-2021
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/mutant-year-zero-session-11-anchortown">
                      Anchortown: Session 11:
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;02-27-2021
                    </NavDropdown.Item>
                  </NavDropdown>
                </Navbar>

                <Navbar bg="dark">
                  <NavDropdown
                    bg="dark"
                    title="Sessions 01-10"
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown.Item href="/mutant-year-zero-session-10-anchortown">
                      Anchortown: Session 10:
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;02-12-2021
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/mutant-year-zero-session-09-anchortown">
                      Anchortown: Session 09:
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;01-30-2021
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/mutant-year-zero-session-08-anchortown">
                      Anchortown: Session 08:
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;01-16-2021
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/mutant-year-zero-session-07-anchortown">
                      Anchortown: Session 07:
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;01-02-2021
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/mutant-year-zero-session-06-anchortown">
                      Anchortown: Session 06:
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;12-18-2020
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/mutant-year-zero-session-05-anchortown">
                      Anchortown: Session 05:
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;12-05-2020
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/mutant-year-zero-session-04-anchortown">
                      Anchortown: Session 04:
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;11-21-2020
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/mutant-year-zero-session-03-anchortown">
                      Anchortown: Session 03:
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;11-06-2020
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/mutant-year-zero-session-02-anchortown">
                      Anchortown: Session 02:
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10-23-2020
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/mutant-year-zero-session-01-anchortown">
                      Anchortown: Session 01:
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10-09-2020
                    </NavDropdown.Item>
                  </NavDropdown>
                </Navbar>
              </NavDropdown>
            </Navbar.Collapse>
          </Navbar>
        </Navbar.Collapse>
      </Navbar>
    </NavDropdown>
  );
};

export default MYZNavBar;
