import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import tram from "../../../../assets/myz/ccc-tram-level.webp";
import theEnd from "../../../../assets/myz/0-beginning-of-the-end.mp3";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Tram Level",
    text: (
      <p>
        The action picks up with the IL Gang exploring the Tram Level of Command
        Center Charlie.
        <br />
        <br />
        <ul>
          <li>
            Bayzid struggles to keep generators in the array running with the
            help of Vanja and IL gang minions.
          </li>
          <li>
            Harry the clone is attacked in the Supply Room by an adult
            necromorph; presumably the baby necro from the deep freeze having
            grown at an accelerated rate.
          </li>
          <li>
            Doc Gladhand and Chromicus investigate the gyrocopters as Smoke
            Raven inspects vehicles.
          </li>
          <li>
            Scuba tank, acetylene torch, and fuel are loaded in the tram car.
          </li>
          <li>
            Hulagu and Ghost Bird wake up the navigation controls of the tram.
          </li>
          <li>
            Scuba gear, weapons, and barrels of gas and jet fuel are loaded onto
            the tram.
          </li>
          <li>
            Hulagu notices a morlock in the tunnel shadows and calls out a heads
            up that tunnel may have enemies.
          </li>
          <li>
            Bayazid figures out the tram controls and programs it to go to the
            Rust Castle.
          </li>
          <li>
            Everyone piles on the tram except Harry. He is killed at the door of
            the tram with a TAIL SPIKE attack from the necromorph.{" "}
          </li>
          <li>
            Several dozen nercomorphs come howling out of the depths of the dark
            tunnel toward the tram.
          </li>
          <li>
            Doc Gladhand uses sprinter to bolt toward the gyrocopters shouting
            "Get out of here, I'll fly to the Steelcrest and meet you at the
            Rust Castle!"
          </li>
          <li>
            Hulagu mutters, "that's a crazy move; I can't let him go alone" and
            uses his mutation to FROG LEG to the copters with Gladhand.
          </li>
          <li>
            Gladhand and Hulagu spart up the copters as the tram accerelates,
            plowing through the crowd of Morlocks.
          </li>
          <li>
            Hulagu is struggling with the gyrocopter controls as Command Center
            Charlie is imploding. The directive to get all the holotapes is
            clearly because the center was booby-trapped so the technology would
            not fall into the hands of enemies (Noatun bastards!)
          </li>
          <li>
            Hulagu pumps seven points into EXTREME REFLEXES to give next MOVE
            check best chance. He succeeds, the mutation locks down, and he flys
            up the vertical tunnel hot on Gladhand's trail.
          </li>
        </ul>
        The tram cruises along fairly smoothly for some time at a whopping 400+
        miles per hour. As Doc Gladhand and Hulagu exit the vertical tunnel into
        the air of the Dead Sea zone, the adult necromorph drops down into the
        engine of the tram from the shadows. In the ensuing fight, another TAIL
        SPIKE takes out Darryl clone. Chromicus is battering the alien as Vanja
        and the IL Gang are shooting. Bayazid takes his eyes off the tram
        controls long enough to dump 7 points into TELEKINESIS and pepper the
        alien with a handful of arrows from the storage car. In the end, one of
        the IL Gang minions get a lucky shot and delivers the killing blow.
        <br />
        <br />
        Meanwhile, Doc Gladhand and Hulagu find the Steelcrest completely
        unchanged, unlooted, and unharmed!
        <br />
        <br />
        The tram comes crashing up through the ground one sector away fro the
        Rust Castle. Thanks to a JURY-RIG with four successes, Bayazid pilots
        the rolling, crashing tram as it careens through scrublands and the exit
        tunnel collapses behind it. A few folks take a point of damage, but the
        jet fuel remains unexploded and the party is relatively unhurt as they
        exit the tram. They see a bus-sized skeleton overgrown with scub plants
        and grunters grazing nearby.
        <br />
        <br /> The two IL Gang members are left to guard the tram and the
        supplies as Bayazid, Vanja, and Chromicus journey into the Rust Castle
        sector. As they near the castle, they hear the familiar cacaphony of
        Helldriver motorcycles and vehicles. With a very good SNEAK, the trio is
        able to spy from hiding and see that the Rust Castle is mostly submerged
        now with only the top level jutting up at a crazy angle from the water.
        A handful of Helldrivers are dancing on the sloped sides of the castle
        and partying. On the beach, motorcycles are spinning around in circles
        as other Helldrivers hoot and holler from their campfires. The Summer of
        Love is off to the side, a burnt out carcass of rust half-melted metal
        with smashed windows like broken teeth. The Helldrivers have clearly
        been party to an attack that has decimated the Rust Castle.
      </p>
    ),
    image: {
      imagePath: tram,
      altText: `The Tram Level`,
      className: "imageLeft",
      imageCaption: "Tram Level",
    },
    audio: {
      audioPath: theEnd,
      altText: `The fall of Command Center Charlie`,
      className: "imageLeft",
      audioCaption: "CCC Falling Apart 7 minute countdown",
    },
  },
  {
    title: "Wrapup",
    text: (
      <p>
        <h3>Bookkeeping</h3>
        <ul>
          <li>Bitterbeasts: food harvested.</li>
          <li>
            Pure Plant Patrol: Everything is Beautiful! 19 water, 8 booze, and 6
            grub.
          </li>
          <li>Ark Reputation: a human arrives seeking citizenship.</li>
          <li>
            Wanderers Arrive: three mutants and two humans arrive hungry and
            scared, but wanting a new home.
          </li>
          <li>
            Ark Projects: 3 point GENERATOR, 1 points SMOKEHOUSE (COMPLETED),
            and 2 point RAILROAD.
          </li>
        </ul>
        NOTE: Altair is housed at the Rust Castle along with Summer of Love bus.
        But Rust Castle has been destroyed, more details to come. Chi Tzu is on
        the Steelcrest anchored off the island of Command Center Charlie.
        <br />
        <br />
        We lost Harry and Darryl clones from the Oilfields of the Ancients. Only
        Vanja is left and she accepts Chromicus' offer to become one of the IL
        Gang and forge a new, unique life at the Sunshine Plaza.
        <br />
        <br />
        Next session starts in-game 06-25-0001, approximately 12pm. Doc Gladhand
        and Hulagu are at the Steelcrest with two gyrocopters, and the rest of
        the party is at the Rust Castle or one sector over guarding the crashed
        tram.
        <br />
        <br />
        Base XP is 3 per character. Then, we discussed cool stuff that happened.
        Based on discussion, GM awards a bonus xp to one of the characters for
        each player. Some highlights discussed were:
        <ul>
          <li>
            Gladhand: Gladhands's sudden bold sprint for the gyrocopters as CCC
            fell apart.
          </li>
          <li>
            Hulagu: following Gladhand to the copters and using seven points in
            extreme reflexses to get flying.
          </li>
          <li>
            Bayzaid: pumping seven points into TELEKINESIS to damage the
            necromorph.
          </li>
        </ul>
        Bonus xp was player choice due to largely even session. Extra points
        went to Gladhand and Hulagu.
        <h6>Critical Injury Roundup</h6>
        <ul>
          <li>
            Ghost Bird: SPRAINED ANKLE and THIGH WOUND healed morning of 25th.
          </li>
          <li>Gladhand: CRUSHED FACE healed morning of 25th.</li>
          <li>
            Chromicus: GROIN HIT: -1 FIGHT, MOVE, FORCE; heal time 4 days; ends
            morning of 26th.
          </li>
          <li>
            Smoke Raven: DAMAGED SHIN, -1 MOVE and SNEAK, heal time 4 days; ends
            morning of 26th.
          </li>
          <li>
            Bayazid: BLEEDING GUT: You suffer one point of damage for every roll
            you make to Force, Move or Fight. Healing Time: 5 days. Lethality
            was healed. Ends morning of the 30th.
          </li>
        </ul>
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        .
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
];

const MYZAnchortownSession53 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 53: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  The IL Gang battles a necromorph and escapes Command Center
                  Charlie on an underground tram and gyrocopters.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession53;
