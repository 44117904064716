import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import mbLogo from "../../../../src/assets/mork-borg/mork-borg-logo.webp";
import sectionDivider from "../../../../src/assets/mork-borg/mork-borg-section-divider.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";

import solitaryDefilement from "../../../../src/assets/mork-borg/exquisite-corpses/solitary-defilement-icon.webp";
import handOfDoom from "../../../../src/assets/mork-borg/exquisite-corpses/01-adventure-begin.webp";
import pitTerrorRoomIntro from "../../../../src/assets/mork-borg/exquisite-corpses/pit-of-terror/pit-of-terror-room-00-intro.webp";
import pitTerrorRoom1 from "../../../../src/assets/mork-borg/exquisite-corpses/pit-of-terror/pit-of-terror-room-01.webp";
import pitTerrorRoom2 from "../../../../src/assets/mork-borg/exquisite-corpses/pit-of-terror/pit-of-terror-room-02.webp";
import pitTerrorRoom3 from "../../../../src/assets/mork-borg/exquisite-corpses/pit-of-terror/pit-of-terror-room-03.webp";
import pitTerrorRoom3Item01 from "../../../../src/assets/mork-borg/exquisite-corpses/pit-of-terror/pit-of-terror-room-03-item-01.webp";
import pitTerrorRoom3Item02 from "../../../../src/assets/mork-borg/exquisite-corpses/pit-of-terror/pit-of-terror-room-03-item-02.webp";
import pitTerrorRoom4 from "../../../../src/assets/mork-borg/exquisite-corpses/pit-of-terror/pit-of-terror-room-04.webp";
import pitTerrorRoom5 from "../../../../src/assets/mork-borg/exquisite-corpses/pit-of-terror/pit-of-terror-room-05.webp";
import pitTerrorRoom6 from "../../../../src/assets/mork-borg/exquisite-corpses/pit-of-terror/pit-of-terror-room-06.webp";
import pitTerrorRoom7 from "../../../../src/assets/mork-borg/exquisite-corpses/pit-of-terror/pit-of-terror-room-07.webp";
import pitTerrorRoom7Creature from "../../../../src/assets/mork-borg/exquisite-corpses/pit-of-terror/pit-of-terror-room-07-creature.webp";
import pitTerrorRoom8 from "../../../../src/assets/mork-borg/exquisite-corpses/pit-of-terror/pit-of-terror-room-08.webp";
import pitTerrorRoom9 from "../../../../src/assets/mork-borg/exquisite-corpses/pit-of-terror/pit-of-terror-room-09.webp";
import pitTerrorRoom9Trap from "../../../../src/assets/mork-borg/exquisite-corpses/pit-of-terror/pit-of-terror-room-09-trap.webp";
import pitTerrorRoom10 from "../../../../src/assets/mork-borg/exquisite-corpses/pit-of-terror/pit-of-terror-room-10.webp";
import pitTerrorRoom11 from "../../../../src/assets/mork-borg/exquisite-corpses/pit-of-terror/pit-of-terror-room-11.webp";
import pitTerrorRoom12 from "../../../../src/assets/mork-borg/exquisite-corpses/pit-of-terror/pit-of-terror-room-12.webp";
import pitTerrorRoom12Creature from "../../../../src/assets/mork-borg/exquisite-corpses/pit-of-terror/pit-of-terror-room-12-creature.webp";
import pitTerrorRoom13 from "../../../../src/assets/mork-borg/exquisite-corpses/pit-of-terror/pit-of-terror-room-13.webp";
import pitTerrorRoom14 from "../../../../src/assets/mork-borg/exquisite-corpses/pit-of-terror/pit-of-terror-room-14.webp";
import pitTerrorRoom15 from "../../../../src/assets/mork-borg/exquisite-corpses/pit-of-terror/pit-of-terror-room-15.webp";
import pitTerrorRoom16 from "../../../../src/assets/mork-borg/exquisite-corpses/pit-of-terror/pit-of-terror-room-16.webp";
import pitTerrorRoom17 from "../../../../src/assets/mork-borg/exquisite-corpses/pit-of-terror/pit-of-terror-room-17.webp";
import pitTerrorRoom18 from "../../../../src/assets/mork-borg/exquisite-corpses/pit-of-terror/pit-of-terror-room-18.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const MBExquisiteCorpsesSession01 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/mork-borg/bkgnd-01.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  const section1Data = [
    {
      title: "DAY 1",
      text: (
        <div class="morkBorgFont morkBorgTitleText">
          <ImageComponent
            image={handOfDoom}
            caption="Map: Hand of Doom"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Hand of Doom adventure map."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>

          <p>
            <h4>DAY 1, QUARTER 1</h4>
            <b>Calendar</b>: no misery
            <br />
            <b>Weather</b>: The Black Salt Wind
            <br />
            Sickly cursed salt-breeze, Toughness DR 10. Effect: The Old Salt
            Madness.
            <br />
            Henvir: fails, spends omen, passes.
            <br />
            Marocain: fails twice, spends two omens, critical success pass.
            <br />
            Shpeelg: fails three times, spends three omens to pass.
            <br />
            Svenka: pass
            <br />
            <br />
            General Adventuring Move (GAM) to buy gear at the Temple of Madness:{" "}
            <br />
            WEAK HIT (1-2 on d6 that desired item to purchase not available).
            <br />
            Henvir: buys 20 bolts and 10 food.
            <br />
            Marocain: betrayed phantom has no silver.
            <br />
            Shpeelg: buys helmet and light armor for Marocain and tracks debt.
            <br />
            Two extra waterskins to put on wagon.
            <br />
            Tries to buy food but rolled 1 on d6 so no more food available at
            temple.
            <br />
            Svenka: buys light armor and sword.
            <br />
            <br />
            <h4>DAY 1, QUARTER 2</h4>
            Travel Slavers Pass toward Bandit Camp. On the way, they encounter
            cult Holders of the Unsacred Signs led by Kattilfridh the Safe of
            Schleswig. They walk barefoot to believing one must be shoeless to
            enter the Shimmering Lands. They are currently in turmoil with
            leadership in question.
            <br />
            Reaction: Angered.
            <br />
            Kattilfridh is angry at the party. She saw a glimpse of Shpeelg's
            Saint Pelle's Apocalypse and claims they are heretics. Shpeelg
            decides to appeal to Kattilfridh's rival to see if they can foster
            resentment and get away from the cult.
            <br />
            Presence DR12 Roll: SUCCESS
            <br />
            As the party tries to slip away unnoticed, Svenka, who has a problem
            with authority, tries to steal a bottle of booze.
            <br />
            Agility DR12 Roll: SUCCESS
            <br />
            "Drink to this assholes," Svenka mutters under his breath as the
            party leaves the cult arguing in the middle of the road.
            <br />
            <br />
            <h4>DAY 1, QUARTER 3</h4>
            Arriving at the Bandit Camp in early evening. Reaction of the
            bandits but with a +1 as Svenka offers seeming leader of greeting
            party a bottle of booze.
            <br />
            Reaction: 9, almost friendly.
            <br />
            So our party decides to stay at bandit camp and Svenka buys booze.
            He gets drunk and using Frontier Scum rules, we find out that when
            Svenka is drunk his TOUGHNESS switches places with his STRENGTH.
            <br />
            Party pays 4 silver for food and straw mattress.
            <br />
            <br />
            <h4>DAY 1, QUARTER 4</h4>
            Sleep. Encounter roll: nothing.
          </p>
        </div>
      ),
    },
    {
      title: "DAY 2",
      text: (
        <div class="morkBorgFont morkBorgTitleText">
          <p>
            <h4>DAY 2, QUARTER 1</h4>
            Everyone rolls their omens and powers etc.
            <br />
            <b>Calendar</b>:{" "}
            <span class="creature">
              MISERY (Psalm 3:3): and hunger shall come among you. You shall dig
              roots and pull children from the breast. The gaunt shall prey upon
              the gaunt.
              <br />
              (effect: must eat three food per day instead of just one)
            </span>
            <br />
            <b>Weather</b>: The Black Salt Wind
            <br />
            Weak, vile gust, TOUGHNESS DR8.
            <br />
            Henvir fails. Effect: Maddened with Rage. Attacks are DR8 with +3
            damage and Defense is DR18 for the day.
            <br />
            GAM to travel south to Pigskin Port.
            <br />
            Travel Roll: weather changes, make new weather roll.
            <br />
            New Weather: hammering rain (AGI DR12 or blinded if spotting whilst
            raining).
            <br />
            <br />
            <h4>DAY 2, QUARTER 2</h4>
            Arrive in Pigskin Port amidst hammering rain.
            <br />
            City Encounter roll as they travel toward The Watchtower.
            <br />
            Religious Persecution (composed encounter). Woman is locked in the
            stocks. Church and guards are looking for her husband. There is a
            reward (d4 * 100 silver) for his capture.
            <br />
            Party decides to hunt him down and it is a small dungeon crawl.
            Husband is Klort, who has become a Catacomb Saint due to rituals he
            was performing with his wife.
            <br />
            <br />
            <h4>DAY 2, QUARTER 3</h4>
            Explore Pit of Terror (see below), come back to Pigskin Port and
            trade, get paid, and get room. 12 sp for three food each due to
            misery (just going to get rarer and more expensive). And 2 sp each
            for room. They buy 20 food at 2sp each (double price already). Rest.
            <br />
            <br />
            <h4>PIT OF TERROR</h4>
            We must find the catacomb saint Klort in the name of justice, and to
            get some money!
            <table class="dungeon-table">
              <tr>
                <td colspan="2">
                  <img
                    class="dungeon-intro-tile"
                    src={pitTerrorRoomIntro}
                    alt="Pit of Terror Introduction"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <img src={pitTerrorRoom1} alt="Pit of Terror Room 1" />
                </td>
                <td>
                  Steep stairs collapsing into forceful swinging axes. DR12 AGI
                  or take d8 damage.
                  <br />
                  Svenka: ok, Marocain: ok after spending two omens, Henvir: ok
                  after spending two omens, Shpeelg: ok with critical success
                  (give Henvir an omen back).
                </td>
              </tr>
              <tr>
                <td>
                  <img src={pitTerrorRoom2} alt="Pit of Terror Room 2" />
                </td>
                <td>
                  Childish motifs and inscriptions on the walls. Basket with
                  rotten fruit.
                </td>
              </tr>
              <tr>
                <td>
                  <img src={pitTerrorRoom3} alt="Pit of Terror Room 3" />
                </td>
                <td>
                  Theme for dungeon is Prison so common encounter for prison
                  from (https://1d105.itch.io/dngnstock) yields:
                  <br />
                  <img src={pitTerrorRoom3Item01} alt="Room 3 Creature" />
                  <br />
                  Party has Shpeelg identify. Roll: 19. They are: sleep d20
                  minutes. Party sleeps for 9 minutes. Roll d20 for another
                  common encounter. 1-9 means encounter. Roll: 1. Another
                  encounter in same room yields:
                  <br />
                  <img src={pitTerrorRoom3Item02} alt="Room 3 Corpse" />
                  <br />
                  Is Zombie?: no. Roll for Item or Trinket: Helmet; Shpeelg
                  takes it.
                </td>
              </tr>
              <tr>
                <td>
                  <img src={pitTerrorRoom4} alt="Pit of Terror Room 4" />
                </td>
                <td>
                  Roll on Generate Cult table.
                  <br />
                  Hateful Dark Thrones led by Dalibor of Tveland. They are
                  digging more tunnels in belief it will lead them to Shimmering
                  Lands.
                  <br />
                  Reaction: Indifferent
                  <br />
                  Roll to see if they will trade PRE DR12. Nope. Ok. Leave them
                  to dig their own grave. Moving on.
                </td>
              </tr>
              <tr>
                <td>
                  <img src={pitTerrorRoom5} alt="Pit of Terror Room 5" />
                </td>
                <td>
                  Party encounters a malign brewer who is mentally confused and
                  helpless.
                  <br />
                  Reaction: almost friendly.
                  <br />
                  Party seems uninterested but Svenka wants him to come along
                  because he may be able to make yummy booze. So, NPC joins
                  party. <span class="npc">Brewer is a female named Nifeh</span>
                  . Rolled as classless adventurer. 1-in-2 she is wounded: yes.
                  d4 damage. She is down to 3hp from her max of 5. 1-in-2 does
                  she have omens left for the day? yes.
                </td>
              </tr>
              <tr>
                <td>
                  <img src={pitTerrorRoom6} alt="Pit of Terror Room 6" />
                </td>
                <td>
                  Since it's adjacent to room 4 we assume party just waves hello
                  to the Hateful Dark Thrones as they continue digging.
                </td>
              </tr>
              <tr>
                <td>
                  <img src={pitTerrorRoom7} alt="Pit of Terror Room 7" />
                </td>
                <td>
                  Roll on The Monster Approaches (FERETORY).
                  https://monster.makedatanotlore.dev/
                  <br />
                  <img
                    style={{ maxWidth: 700 }}
                    src={pitTerrorRoom7Creature}
                    alt="Trauma Moth"
                  />
                  <br />
                  Claws burn for 1hp per round until action spend extinguishing
                  flames with AGI DR6.
                  <br />
                  Nifeh is first. Evens she attacks, odds she is just confused.
                  SHe attacks!
                  <br />
                  Henvir draws first blood with the Shoe of Death's Horse. The
                  treacherous moth flees.
                </td>
              </tr>
              <tr>
                <td>
                  <img src={pitTerrorRoom8} alt="Pit of Terror Room 8" />
                </td>
                <td>
                  Random sacred scroll on a lecturn. Whispers Pass the Gate.
                </td>
              </tr>
              <tr>
                <td>
                  <img src={pitTerrorRoom9} alt="Pit of Terror Room 9" />
                </td>
                <td>
                  Generate trap
                  <br />
                  <img
                    style={{ maxWidth: 700 }}
                    src={pitTerrorRoom9Trap}
                    alt="Etheral Bola Trap"
                  />
                  <br />
                  Whoa. Sounds serious. Whomever reached for the crystal goblet
                  must make AGI DR14 or be trapped in ethereal suspension. Only
                  way out is for party to find next special room and release the
                  trap there. Who reaches? Haha Nifeh, the confused NPC brewer.
                  Does she get trapped? Yep!
                </td>
              </tr>
              <tr>
                <td>
                  <img src={pitTerrorRoom10} alt="Pit of Terror Room 10" />
                </td>
                <td>
                  Poisonous mold on animal cadaver. Presence DR6 for everyone to
                  make sure they didn't touch it. Every one is smart enough.
                  Debate whether to harvest poison or not. Party decides not to
                  so they can get out and get paid.
                  <br />
                  Turns out the key to unlocking the ethereal trap that holds
                  Nifeh requires mucking about in the poisonous mold. The party
                  decides to leave things as is, certain that Nifeh is happier
                  suspended in whatever dimension she is trapped in.
                </td>
              </tr>
              <tr>
                <td>
                  <img src={pitTerrorRoom11} alt="Pit of Terror Room 11" />
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <img src={pitTerrorRoom12} alt="Pit of Terror Room 12" />
                </td>
                <td>
                  <img
                    style={{ maxWidth: 700 }}
                    src={pitTerrorRoom12Creature}
                    alt="Parasitic Trilobite"
                  />
                  <br />
                  Since it's next to tunnels being dug and we have poisonous
                  mold discovered, it's one of the Hate Dark Throne members who
                  was consumed and tied down, but now the bonds are broken as
                  party enters!!
                  <br />
                  Marocain goes first and kills the Thorned Cultist in one blow;
                  but the trilobite erupts!
                  <br />
                  Marocain gets the killing blow.
                </td>
              </tr>
              <tr>
                <td>
                  <img src={pitTerrorRoom13} alt="Pit of Terror Room 13" />
                </td>
                <td>
                  Hidden enemy triggers moldy darts. Random monster is{" "}
                  <span class="creature">Infested Toad</span>. Say the darts are
                  poison. TOU DR12 or d10 damage. Svenka fails to activate
                  Agitated Agility.
                  <br />
                  Marocain kills the toad with critical hit.
                </td>
              </tr>
              <tr>
                <td>
                  <img src={pitTerrorRoom14} alt="Pit of Terror Room 14" />
                </td>
                <td>
                  1-in-6 its an ambush. it's an ambush!
                  <br />
                  Random creature: <span class="creature">Despair Slime</span>.
                  Despair special ability targets Marocain who fails and is out
                  of omens. He will die on the d8 since he has only one and will
                  run. Svenka uses one of his omens and Marocain gets critical
                  success.
                  <br />
                  Marocain hits for 4 damage. Shpeelg uses Grace for a Sinner
                  out of turn to try and give Marocain more damage. Shpeelg
                  fails and takes 1 damage. Henvir crossbows it for 8! Svenka's
                  sword breaks. Marocain hit, no damage thanks to armor, but
                  then there's that despair attack again. Party spends FOUR
                  omens so Marocain can survive.
                  <br />
                  Marocain gets the killing blow (again!)
                </td>
              </tr>
              <tr>
                <td>
                  <img src={pitTerrorRoom15} alt="Pit of Terror Room 15" />
                </td>
                <td>
                  Has object, weak hit might be trapped. Weak hit. Evens it's
                  trapped. Not trapped.
                  <br />
                  1-2 corpse plunder 3-4 items & trinkets 5-6 valuable but
                  useless junk (d6, 6 means two rolls).
                  <br />
                  one roll on valuable but useless:{" "}
                  <span class="treasure">
                    Bone Circle Ring of Banding (30s)
                  </span>
                  .
                </td>
              </tr>
              <tr>
                <td>
                  <img src={pitTerrorRoom16} alt="Pit of Terror Room 16" />
                </td>
                <td>Is it empty? 1-in-6 its an ambush. Its empty.</td>
              </tr>
              <tr>
                <td>
                  <img src={pitTerrorRoom17} alt="Pit of Terror Room 17" />
                </td>
                <td>
                  Weak hit object may be trapped; weak hit; even its trapped;
                  its trapped; moldy caltrops on an ornate throne. AGI DR12 to
                  avoid d4 with TOU DR12 if damaged to not be infected.
                  <br />
                  Svenka the drunk sits on throne. Damaged 4 but no infection.
                </td>
              </tr>
              <tr>
                <td>
                  <img src={pitTerrorRoom18} alt="Pit of Terror Room 18" />
                </td>
                <td>
                  <span class="creature">Klort the Catacomb Saint</span> sitting
                  on a chest covered in plague ooze. Sheelpg grace for a sinner
                  on Henvir to give +6 to hit. Kills the saint in one blow.
                  Klorts Monkey surrenders. Presence DR 12 to make it friendly
                  with party. Nope. Let it go free, too wild. Grab Klorts stuff
                  and leave.
                  <br />
                  Loot includes Klort's monkey who is convinced to join the
                  party.
                </td>
              </tr>
            </table>
            <h4>DAY 2, QUARTER 4</h4>
            Sleep
            <h4>DAY 3, QUARTER 1</h4>
            Everyone rolls their omens and powers refresh.
            <br />
            <b>Calender</b>: no misery.
            <br />
            <b>Weather</b>: crackling frost (TOU DR10 or Freezing Ass Off
            condition: all AGI checks +2 DR)
            <br />
            Henvir is freezing ass off even after two omens.
            <br />
            Marocain good after two omens.
            <br />
            Shpeelg good after one omen. CRitical success.
            <br />
            Party paid 300 silver.
            <br />
            Svenka rests (has to eat three food for healing).
            <br />
            Others shop in the town for gear.
          </p>
        </div>
      ),
      image: {
        imagePath: null,
        altText: ``,
        className: "imageLeft",
        imageCaption: "",
      },
      image2: {
        imagePath: null,
        altText: ``,
        className: "imageRight",
        imageCaption: "",
      },
    },

    {
      title: "END CREDITS",
      text: (
        <div class="morkBorgFont morkBorgTitleText">
          <p>
            This episode of Exquisite Corpses brought to you in part by:
            <br />
            <ul>
              <li>
                <a
                  href="https://morkborg.com/"
                  rel="noreferrer"
                >
                  MORK BORG
                </a>
                : the game, the legend, the horror!
              </li>
              <li>
                <a
                  href="https://foundryvtt.com/packages/morkborg"
                  rel="noreferrer"
                >
                  MORK BORG FOUNDRY SYSTEM MODULE
                </a>
                : module for &nbsp;
                <a
                  href="https://foundryvtt.com/"
                  rel="noreferrer"
                >
                  Foundry
                </a>{" "}
                VTT by the magnificent &nbsp;
                <a
                  href="https://mcglintlock.itch.io/"
                  rel="noreferrer"
                >
                  mcglintlock
                </a>
                .
              </li>
              <li>
                <a
                  href="https://dngngen.makedatanotlore.dev/"
                  rel="noreferrer"
                >
                  DNGNGEN
                </a>
                : generate dungeon with four special rooms.
              </li>
              <li>
                <a
                  href="https://perchance.org/dngnstock"
                  rel="noreferrer"
                >
                  DNGNSTOCK
                </a>
                : encounters for the DNGNGEN with theme, common, rare, and NPCs
                to correlate with core Solitary Defilement rulebook.{" "}
              </li>
              <li>
                <a
                  href="https://www.gamenerdz.com/dungeon-degenerates-hand-of-doom-preorder#:~:text=DUNGEON%20DEGENERATES%20%2D%20HAND%20OF%20DOOM,of%20swords%2C%20sausages%20%26%20sorcery."
                  rel="noreferrer"
                >
                  HAND OF DOOM
                </a>
                : map from the Dungeon Degenerates board game by GOBLINKO.
              </li>
              <li>
                <a
                  href="https://www.youtube.com/watch?v=UOOBf83nIco"
                  rel="noreferrer"
                >
                  THE DUNGEON DIVE
                </a>
                : videos for a solo campaign using Solitary Defilement to
                inspire and instruct.
              </li>
              <li>
                <a
                  href="https://www.tablemonger.com/"
                  rel="noreferrer"
                >
                  TABLEMÖNGER
                </a>
                : tables for flavor and cool stuff.
              </li>
              <li>
                <a
                  href="https://chaoclypse.itch.io/solitary-crawl"
                  rel="noreferrer"
                >
                  SÖLITARY CRAWL
                </a>
                : super awesome graphical dungeon generator.
              </li>
            </ul>
            PREVIOUS:{" "}
            <a href="./mork-borg-session-00-exquisite-corpses">Session 00</a>
          </p>
        </div>
      ),
      image: {
        imagePath: null,
        altText: ``,
        className: "imageLeft",
        imageCaption: "",
      },
      image2: {
        imagePath: null,
        altText: ``,
        className: "imageRight",
        imageCaption: "",
      },
    },
  ];

  return (
    <Container>
      {/* <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin" 
                    style={{position: 'float', float: 'right',width: '120px', height: '240px'}} marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=smokeraven-20&language=en_US&marketplace=amazon&region=US&placement=9189143701&asins=9189143701&linkId=d1384e0e6b9ef083fee35d7272a71f88&show_border=true&link_opens_in_new_window=true"></iframe> */}

      <Row>
        <Col>
          <div
            style={{ backgroundColor: "transparent", fontFamily: "Orbitron" }}
          >
            <main role="main" className="inner cover text-center">
              <img
                src={mbLogo}
                style={{ maxWidth: "200px" }}
                alt="Mutant Year Zero campaign"
              />
              <div style={{ float: "right" }}>
                <a
                  href="https://1d105.itch.io/solitary-defilement"
                  rel="noreferrer"
                >
                  <img src={solitaryDefilement} alt="Solitary Defilement"></img>
                  <br />
                  <span style={{ backgroundColor: "white", opacity: 0.8 }}>
                    GET SOLITARY DEFILEMENT
                  </span>
                </a>
              </div>
            </main>
            <div style={pageHeader}>
              <h1 className="cover-heading">Session 01: Exquisite Corpses</h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title className="cardTitle-mork-borg">
                WHEREUPON OUR SCVM TAKE ON THE PIT OF TERROR
              </Card.Title>
              <Card.Text>
                PREVIOUS:{" "}
                <a href="./mork-borg-session-00-exquisite-corpses">
                  Session 00
                </a>
                <ContentContainer data={section1Data} />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MBExquisiteCorpsesSession01;
