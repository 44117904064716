import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import logo from "../../../../src/assets/forbidden-lands/forbidden-lands-logo.webp";
import sectionDivider from "../../../../src/assets/forbidden-lands/campaign/bitter-reach-page-banner-goblin.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";
import "./forbidden-lands-styles.scss";
import tiemisz from "../../../../src/assets/forbidden-lands/session06/tiemisz.webp";


const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "1",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Carter One",
  backgroundColor: "rgba(0, 0, 0, 1) ",
  color: "white",
  marginBottom: "15px",
};

const FBLGoblin = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  const section1Data = [
    {
      title: "Goblin Clans in the Bitter Reach",
      anchor: "prisoners",
      sectionTitleCssName: "sectionTitleFBL",
      text: (
        <div className="fblBodyText">
          
          The goblin of the Bitter Reach live mostly in the Trottersmark area of the northern tundra.
          <br/><br/>
          There are two main goblin clans in the Trottersmark area of the Bitter Reach's Silver Coast:
          <br/><br/>
          <h2>Nogoon Goblins</h2>
          The Nogoon goblins are ofter called the "Green Goblins" because of their greenish skin. They are known for their skill in crafting and their tight alliance with the Wolfkin of 
          Wolvenhome. The Nogoon goblins are more sedentary than their cousins in other clan and they are less open to contact with other kin.
          <br/><br/>
          <h2>Grohnewailz Goblins</h2>
          <ImageComponent
            image={tiemisz}
            caption="Grohnewailz Goblin"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="The Grohnewailz goblins are less affiliated with the wolfkin and make their way as chaotic, sneaky wolf riders often hiring themselves as guides to other kin."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          The Grohnewailz goblins are less affiliated with the wolfkin and make their way as chaotic, sneaky wolf riders often hiring themselves as guides to other kin. The Grohnewailz live 
          closer to the Morma Glacier than the relatively coastal Nogoon goblins and Wolfkin in Trottersmark.
          They are embroiled in a low-key, but persistent, feud with the wolfkin over hunting grounds. 
          The goblins are also enemies of the Canide dwarves, and from time to time they attack traders to get supplies. 
          Despite this, Grohnewailz goblins are occasionally hired as pathfinders by treasure hunters. 
          <br/><br/>
          <h2>Goblins and Halflings in the Bitter Reach</h2>
          The fates of goblins and halflings is even closer entwined here in the Bitter Reach than in Ravenland. Due to the cold, few halflings are born, and those that do live as lesser 
          members among their cousins.
          <br/><br/>
          <a href="#pageTop">Back to Top</a>
        </div>
      ),
    },
  ];

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/forbidden-lands/fbl-page-background-blizzard.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.backgroundWidth = "100vw";
    document.body.backgroundHeight = "100vh";
    document.body.style.backgroundSize = "cover";
  }, []);

  return (
    <Container>
      {/* <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin" 
                    style={{position: 'float', float: 'right',width: '120px', height: '240px'}} marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=smokeraven-20&language=en_US&marketplace=amazon&region=US&placement=9189143701&asins=9189143701&linkId=d1384e0e6b9ef083fee35d7272a71f88&show_border=true&link_opens_in_new_window=true"></iframe> */}
      <Row>
        <Col>
          <div
            style={{
              backgroundColor: "transparent",
              fontFamily: "IM Fell Great Primer",
            }}
          >
            <div style={pageHeader}>
              <main role="main" className="inner cover text-center">
                <a href="#freedom-day" name="pageTop">
                  <img
                    src={logo}
                    style={{ maxWidth: "200px" }}
                    alt="Forbidden Lands Campaign"
                  />
                </a>
              </main>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title className="cardTitle-forbidden-lands  ">
                Here being a study of the sneaky, hearty wolf riding goblins.
                {/* <small>
                  <br />☠ Session 01:{" "}
                  <a href="./forbidden-lands-session-01">
                    Freedom Day in Gomsten
                  </a>{" "}
                  ⇒
                </small> */}
              </Card.Title>
              <div>
                <ContentContainer data={section1Data} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default FBLGoblin;
