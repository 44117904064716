import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import mbLogo from "../../../../src/assets/mork-borg/mork-borg-logo.webp";
import sectionDivider from "../../../../src/assets/mork-borg/mork-borg-section-divider.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import { useEffect } from "react";

import solitaryDefilement from "../../../../src/assets/mork-borg/exquisite-corpses/solitary-defilement-icon.webp";
import handOfDoom from "../../../../src/assets/mork-borg/exquisite-corpses/01-adventure-begin.webp";
import missionPath from "../../../../src/assets/mork-borg/exquisite-corpses/01-mission-path.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "TOOLS OF THE TRADE",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <p>
          Mork Borg is so fun to prep as a GM. When you run a game over any VTT
          such as Foundry, you have to test your stuff. Are the macros working,
          are the icons for the documents linked, and so on.
          <br />
          <br />
          Why not cast your testing of stuff as a solo campaign to really test
          it out? And so, let's do it.
          <br />
          <br />
          There are a few key things I am using:
          <ul>
            <li>
              <a href="https://morkborg.com/" rel="noreferrer">
                MORK BORG
              </a>
              : the game, the legend, the horror!
            </li>
            <li>
              <a
                href="https://foundryvtt.com/packages/morkborg"
                rel="noreferrer"
              >
                MORK BORG FOUNDRY SYSTEM MODULE
              </a>
              : module for &nbsp;
              <a
                href="https://foundryvtt.com/"
                rel="noreferrer"
              >
                Foundry
              </a>{" "}
              VTT by the magnificent &nbsp;
              <a
                href="https://mcglintlock.itch.io/"
                rel="noreferrer"
              >
                mcglintlock
              </a>
              .
            </li>
            <li>
              <a
                href="https://dngngen.makedatanotlore.dev/"
                rel="noreferrer"
              >
                DNGNGEN
              </a>
              : generate dungeon with four special rooms.
            </li>
            <li>
              <a
                href="https://perchance.org/dngnstock"
                rel="noreferrer"
              >
                DNGNSTOCK
              </a>
              : encounters for the DNGNGEN with theme, common, rare, and NPCs to
              correlate with core Solitary Defilement rulebook.{" "}
            </li>
            <li>
              <a
                href="https://www.gamenerdz.com/dungeon-degenerates-hand-of-doom-preorder#:~:text=DUNGEON%20DEGENERATES%20%2D%20HAND%20OF%20DOOM,of%20swords%2C%20sausages%20%26%20sorcery."
                rel="noreferrer"
              >
                HAND OF DOOM
              </a>
              : map from the Dungeon Degenerates board game by GOBLINKO.
            </li>
            <li>
              <a
                href="https://www.youtube.com/watch?v=UOOBf83nIco"
                rel="noreferrer"
              >
                THE DUNGEON DIVE
              </a>
              : videos for a solo campaign using Solitary Defilement to inspire
              and instruct.
            </li>
            <li>
              <a
                href="https://www.tablemonger.com/"
                rel="noreferrer"
              >
                TABLEMÖNGER
              </a>
              : tables for flavor and cool stuff.
            </li>
            <li>
              <a
                href="https://chaoclypse.itch.io/solitary-crawl"
                rel="noreferrer"
              >
                SÖLITARY CRAWL
              </a>
              : super awesome graphical dungeon generator.
            </li>
          </ul>
        </p>
      </div>
    ),
    image: {
      imagePath: handOfDoom,
      altText: `Hand of Doom adventure map`,
      className: "imageLeft",
      imageCaption: "HAND OF DOOM MAP",
    },
  },

  {
    title: "GROUND RULES",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <p>
          We'll generate a quest using the points on the HAND OF DOOM map and
          some flavor text I threw into some Foundry roll tables. Then, I will
          use the Create Scvm button in Foundry with the third-party module
          loaded (and custom work of my own to wire in other classes).
          <br />
          <br />
          <h3>MISSION</h3>
          You must find{" "}
          <span style={{ color: "#ADFF2F", fontWeight: "bold" }}>
            Cursed Spindle
          </span>{" "}
          in{" "}
          <span style={{ color: "#00FFFF", fontWeight: "bold" }}>
            Ghost Gate
          </span>{" "}
          in the{" "}
          <span style={{ color: "#ADFF2F", fontWeight: "bold" }}>Wetlands</span>{" "}
          and bring to{" "}
          <span style={{ color: "#00FFFF", fontWeight: "bold" }}>
            The Hell Pit
          </span>{" "}
          in{" "}
          <span style={{ color: "#ADFF2F", fontWeight: "bold" }}>
            The Badlands
          </span>
          . You start in{" "}
          <span style={{ color: "#00FFFF", fontWeight: "bold" }}>
            Temple of Madness
          </span>{" "}
          in the{" "}
          <span style={{ color: "#ADFF2F", fontWeight: "bold" }}>
            Highlands
          </span>
          .<h3>CALENDAR OF NECHRUBEL</h3>
          d6 each game day.
          <h3>PARTY</h3>
          <ul>
            <li>
              <span style={{ color: "#00FFFF" }}>HENVIR</span>: a grumpy,
              deceitful{" "}
              <span style={{ color: "#ADFF2F" }}>GUTTERBORN SCUM</span> with a
              hearing loss.
            </li>
            <li>
              <span style={{ color: "#00FFFF" }}>MAROCAIN</span>:{" "}
              <span style={{ color: "#ADFF2F" }}>BETRAYED PHANTOM</span>;
              shrewd, slothful, and doesn't listen. He has been a male harlot
              and likes pleasures of the flesh.
            </li>
            <li>
              <span style={{ color: "#00FFFF" }}>SHPEELG</span>:{" "}
              <span style={{ color: "#ADFF2F" }}>CATACOMB SAINT</span> prone to
              nihilism with an inferiority complex. Sweats like a pig and makes
              sound effects.
            </li>
            <li>
              <span style={{ color: "#00FFFF" }}>SVENKA</span>:{" "}
              <span style={{ color: "#ADFF2F" }}>SOUL-DAMNED DRUNKARD</span>.
              Cowardly, hates authority, but in unnervingly beautiful and clean
              . . . for a NERVOUS drunk.
            </li>
          </ul>
        </p>
      </div>
    ),
    image: {
      imagePath: missionPath,
      altText: `MISSION: You must find Cursed Spindle in Ghost Gate in the Wetlands and bring to The Hell Pit in The Badlands. You start in Temple of Madness in the Highlands.`,
      className: "imageLeft",
      imageCaption: "MISSION PATH",
    },
  },

  {
    title: "END CREDITS",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <p>
          This episode of Exquisite Corpses brought to you in part by:
          <br />
          <ul>
            <li>
              <a href="https://morkborg.com/" rel="noreferrer">
                MORK BORG
              </a>
              : the game, the legend, the horror!
            </li>
            <li>
              <a
                href="https://foundryvtt.com/packages/morkborg"
                rel="noreferrer"
              >
                MORK BORG FOUNDRY SYSTEM MODULE
              </a>
              : module for &nbsp;
              <a
                href="https://foundryvtt.com/"
                rel="noreferrer"
              >
                Foundry
              </a>{" "}
              VTT by the magnificent &nbsp;
              <a
                href="https://mcglintlock.itch.io/"
                rel="noreferrer"
              >
                mcglintlock
              </a>
              .
            </li>
            <li>
              <a
                href="https://dngngen.makedatanotlore.dev/"
                rel="noreferrer"
              >
                DNGNGEN
              </a>
              : generate dungeon with four special rooms.
            </li>
            <li>
              <a
                href="https://perchance.org/dngnstock"
                rel="noreferrer"
              >
                DNGNSTOCK
              </a>
              : encounters for the DNGNGEN with theme, common, rare, and NPCs to
              correlate with core Solitary Defilement rulebook.{" "}
            </li>
            <li>
              <a
                href="https://www.gamenerdz.com/dungeon-degenerates-hand-of-doom-preorder#:~:text=DUNGEON%20DEGENERATES%20%2D%20HAND%20OF%20DOOM,of%20swords%2C%20sausages%20%26%20sorcery."
                rel="noreferrer"
              >
                HAND OF DOOM
              </a>
              : map from the Dungeon Degenerates board game by GOBLINKO.
            </li>
            <li>
              <a
                href="https://www.youtube.com/watch?v=UOOBf83nIco"
                rel="noreferrer"
              >
                THE DUNGEON DIVE
              </a>
              : videos for a solo campaign using Solitary Defilement to inspire
              and instruct.
            </li>
            <li>
              <a
                href="https://www.tablemonger.com/"
                rel="noreferrer"
              >
                TABLEMÖNGER
              </a>
              : tables for flavor and cool stuff.
            </li>
            <li>
              <a
                href="https://chaoclypse.itch.io/solitary-crawl"
                rel="noreferrer"
              >
                SÖLITARY CRAWL
              </a>
              : super awesome graphical dungeon generator.
            </li>
          </ul>
          NEXT:{" "}
          <a href="./mork-borg-session-01-exquisite-corpses">Session 01</a>
        </p>
      </div>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
    image2: {
      imagePath: null,
      altText: ``,
      className: "imageRight",
      imageCaption: "",
    },
  },
];

const MBExquisiteCorpsesSession00 = () => {
  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/mork-borg/bkgnd-01.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <Container>
      {/* <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin" 
                    style={{position: 'float', float: 'right',width: '120px', height: '240px'}} marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=smokeraven-20&language=en_US&marketplace=amazon&region=US&placement=9189143701&asins=9189143701&linkId=d1384e0e6b9ef083fee35d7272a71f88&show_border=true&link_opens_in_new_window=true"></iframe> */}

      <Row>
        <Col>
          <div
            style={{ backgroundColor: "transparent", fontFamily: "Orbitron" }}
          >
            <main role="main" className="inner cover text-center">
              <img
                src={mbLogo}
                style={{ maxWidth: "200px" }}
                alt="Mutant Year Zero campaign"
              />
              <div style={{ float: "right" }}>
                <a
                  href="https://1d105.itch.io/solitary-defilement"
                  rel="noreferrer"
                >
                  <img src={solitaryDefilement} alt="Solitary Defilement"></img>
                  <br />
                  <span style={{ backgroundColor: "white", opacity: 0.8 }}>
                    GET SOLITARY DEFILEMENT
                  </span>
                </a>
              </div>
            </main>
            <div style={pageHeader}>
              <h1 className="cover-heading">Session 00: Exquisite Corpses</h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title className="cardTitle-mork-borg">
                WELCOME TO EXQUISITE CORPSES, A MORK BORG SOLO CAMPAIGN
              </Card.Title>
              <Card.Text>
                NEXT:{" "}
                <a href="./mork-borg-session-01-exquisite-corpses">
                  Session 01
                </a>
                <ContentContainer data={section1Data} />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MBExquisiteCorpsesSession00;
