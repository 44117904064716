import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import deepFreeze from "../../../../assets/myz/level-3-deep-freeze.jpg";
import recCenter from "../../../../assets/myz/level-3-rec-center.jpg";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Playing Around in the Rec Center",
    text: (
      <p>
        Our action picks up with the IL Gang in the Rec Center. They move into
        the exercise room and Chromicus, Dorothy, and Beto stand guard outside
        in the dormitory watching for incoming robots. They know a hoverdrone
        spotted them and a patrol is sure to arrive.
        <br />
        <br />
        The robots approach in stealth mode and are within SHORT range when
        spotted. It's a Class IV Avenger, seven drone soldiers, and two digimax
        hoverdrones. The Avenger immediately fires a grenade at Beto, Chromicus
        and Dorothy. Dorothy is broken and Beto badly hurt.
        <br />
        <br />
        The rest of the party in the exercise room are hunkered down behind
        cover waiting. Smoke Raven has spent two rounds trying to grab a
        hand-sized metallic spider. Then, a swarm of metallic spiders (aka
        COMBINATORS) floods out af a tipped pool table. Smoke Raven flees into
        the exercise room and Gladhand scans the CCC card to close the door.
        <br />
        <br />
        As the combinators chip away at the bottom of the Rec Center door, the
        Avenger disappears with a flicker. The hoverdrones and drone soldiers
        enter the Exercise Room to do battle. As the battle rages, the
        Combinators are working their way through the Rec Center door. Some
        highlights:
        <ul>
          <li>
            Bayazid uses telekinesis from cover to throw a barbell straight
            through the torso of a drone soldier, shattering the robot into
            scrap. The mutation misfires and Bayazid gains new mutation
            PATHOKINESIS and suffers permanent ability loss of Agility going
            down to 2 from 3.
          </li>
          <li>
            Ghost Bird inspires Gladhand on a chain knife attack on the final
            Drone Soldier making it a kill.
          </li>
          <li>
            The Avenger's invisibility is disrupted by the combinators rushing
            to attack the robot. The Avenger nearly kills Vanja with a laser gun
            blast and desperatly trys to attack the combinators with its
            chainsaw. Smoke Raven had the flamethrower prepared and opens fire
            with a bestial roar. The flames engulf the Class IV Avenger and the
            Combinators swarming the metal behemoth. The robot and nano spiders
            all die in a melting slag heap.
          </li>
          <li>DEATHS: Beto and Dorothy did not make it.</li>
        </ul>
        Searching the Rec Center yields a COMIC BOOK, a DECK OF CARDS, a SODA
        CAN, and CHEWING GUM with 6 uses.
        <br />
        <br />
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "Wrapup",
    text: (
      <p>
        <h3>Bookkeeping</h3>
        <ul>
          <li>Bitterbeasts: food harvested.</li>
          <li>
            Pure Plant Patrol: Everything is Beautiful! 12 water, 8 booze, and 7
            grub.
          </li>
          <li>
            Ark Reputation: 2 humans and 5 mutants arrive wanting citizenship.
          </li>
          <li>
            Wanderers Arrive: 1 human and 2 mutants arrive wanting citizenship.
          </li>
          <li>
            Ark Projects: 3 points GENERATOR, 2 points SMOKEHOUSE, and 1 point
            RAILROAD.
          </li>
        </ul>
        NOTE: Altair is housed at the Rust Castle along with Summer of Love bus.
        Chi Tzu is on the Steelcrest anchored off the island of Command Center
        Charlie.
        <br />
        <br />
        Next session starts in-game 06-23-0001, approximately 10pm inside
        Command Center Charlie. The party is in the Rec Center ready to spend 3
        hours healing and resting which is where we will pick up.
        <br />
        <br />
        Base XP is 3 per character. Then, we discussed cool stuff that happened.
        Based on discussion, GM awards a bonus xp to one of the characters for
        each player. Some highlights discussed were:
        <ul>
          <li>
            Hulagu did a brave (foolish?) frog leap right into the midst of
            drone soldiers.
          </li>
          <li>
            Bayazid destroyed a drone solider using barbell and telekinesis then
            developed a new mutation.
          </li>
          <li>
            Ghost Bird did an amazing inspire to turn Gladhand's nada with chain
            knife into one-round kill of drone solider.
          </li>
          <li>
            Smoke Raven pulled of cinematic flamethrower moment against the
            Avenger and the carpet of Combinators swarming.
          </li>
          <li>
            Gladhand used SPRINTER to do a classic move, shoot robot in the
            face, then move back out of arms length.
          </li>
        </ul>
        Bonus xp was player choice due to largely even session. Extra points
        went to Bayazid, Smoke Raven, and Gladhand.
        <h6>Critical Injury Roundup</h6>
        <ul>
          <li>
            Chromicus: GROIN HIT: -1 FIGHT, MOVE, FORCE; heal time 4 days; ends
            morning of 26th.
          </li>
          <li>
            Ghost Bird: SPRAINED ANKLE: -1 MOVE, SNEAK; heal time 3 days; ends
            morning of 25th.
          </li>
          <li>
            Ghost Bird: THIGH WOUND: -2 MOVE and SNEAK; heal time 3 days; ends
            morning of 25th.
          </li>
          <li>
            Smoke Raven: DAMAGED SHIN, -1 MOVE and SNEAK, heal time 4 days; ends
            morning of 26th.
          </li>
          <li>
            Gladhand...CRUSHED FACE: Chromicus rolled and it's no longer
            lethal...Effect: -2 to Manipulate, Healing Time: 12 day (morning of
            25th)
          </li>
        </ul>
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        .
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: deepFreeze,
      altText: `In the deep freezer, a corpse is discovered.`,
      className: "imageRight",
      imageCaption: "Deep Freeze",
    },
    image2: {
      imagePath: recCenter,
      altText: `Recreation Center has pool tables, game tables, a rack of books and a small refrigerator.`,
      className: "imageLeft",
      imageCaption: "Rec Center",
    },
  },
];

const MYZAnchortownSession51 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 51: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  Exploration of level 3 in Command Center Charlie reveals the
                  Chronicle of the Titan Powers and morlocks!
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession51;
