import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import breakRoom from "../../../../assets/myz/miner-break-room.jpg";
import boxingGloves from "../../../../assets/myz/artifact-boxing-gloves.jpg";
import digger from "../../../../assets/myz/digger.jpg";
import worldWorm from "../../../../assets/myz/world-worm.jpg";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Bookkeeping",
    text: (
      <p>
        05-20-0001:
        <br />
        <br />
        <ul>
          <li>
            PARTY ROLL CALL: Hulagu, Bayazid, Gladhand, Chromicus, Smoke Raven,
            Double Bird.
          </li>
          <li>
            BITTERBEASTS: 3 eggs hatched and at least one beast became trained.
          </li>
          <li>
            PROJECT ROLLS: Project rolls not made due to persistent earthquake,
            gas, and general mayhem. However, at end of session (see below)
            EXPAND THE ARK was considered complete due to exploration of tunnels
            below the plaza.
          </li>
          <li>
            PURE PLANT PATROL: everything goes smoothly: 11 water, 3 booze, and
            3 grub.
          </li>
          <li>
            NEW GUY: Bayazid the gearhead introduced as replacement for Timur
            who died last session, his first.
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: boxingGloves,
      altText: `  BOXING GLOVES: padded lumps of fabric that fit on your hands. 

Effect: Light item that can be worn to make unarmed combat damage apply to AGI and WITS 
instead of STR. If you deal more than one point of damage in a single hit, the damage 
applies to AGI, then WITS and so on until all damage distributed (ex: if you deal 2 
points of damage due to Sucker Punch talent, one point goes to AGI and second point applies to WITS).
DEV Requirement: --
DEV Bonus: Culture +1 (or D6 if turned in and Zone Wrestling project already exists completed).`,
      className: "imageLeft",
      imageCaption: `BOXING GLOVES`,
    },
  },
  {
    title: "When Hell Freezes Over",
    text: (
      <p>
        We left last session with a Polar Devourer about to attack Boss Hulagu.
        In this session, we had four IL Gang members and Smoke Raven and Double
        Bird appear as backup reinforcements.
        <br />
        <br />
        The gang dispatched the devourer after it nearly broke the boss. Then
        the party fought a DEATHWORM, rested in a cave for a few hours to heal,
        and narrowly avoided a collapse from a spike in earthquake activity.
        Finally, the gang finds an ancient break room where workers used to
        relax.
        <br />
        <br />
        There are chairs, a table, a garbage can, lockers, an armchair, and a
        hole in the ground that is an inconceivably deep shaft with wires and
        metal runners going down its length. Nearby is a podium with a slot and
        some buttons.
        <br />
        <br />
        Double Bird uses FORCE to open the lockers and finds a piece of scrap
        metal that is shaped to fit in the slot next to the hole in the ground.
        Hanging on the locker doors is a pair of boxing gloves (see image
        details above for rules on this homebrew artifact). Chromicus finds a
        COLD SUIT hanging on the back of a chair with a grub in one pocket and a
        red plastic card with "priority access" printed on it.
        <br />
        <br />
        After trying the plastic card, Double Bird trys the scrap metal. This
        summons a high speed elevator that takes almost a minute to arrive. The
        gang gets into the elevator to go down.
        <br />
        <br />
        At the bottom of the shaft, the elevator simply ends in a fancy tunnel
        (aka subway). Pequena is broken and laying a few feet from the elevator,
        freezing. She is holding a flashlight and gas mask that she "borrowed"
        from the Order of the New Dawn to go search for her daddy. All the PCs
        have to roll ENDURE against cold this far underground and everyone's
        breath drifts out in visible clouds.
        <br />
        <br />
        The gang takes the elevator back to the break room with Pequena. The
        gang tears apart the break room to get scrap and the new guy, Bayazid,
        makes three cold suits; two of which are durable. Gladhand offers to
        take her back to the Ark and pack mule any extra gear from the rest of
        the party. Once Gladhand and Pequena are on their way, the gang goes
        back down into the freezing subway to solve the mystery of the
        earthquakes.
      </p>
    ),
    image: {
      imagePath: breakRoom,
      altText: `The Stanley Clark break room below the Sunshine Plaza`,
      className: "imageLeft",
      imageCaption: "BREAK ROOM",
    },
  },
  {
    title: "The Digger and the World Worm",
    text: (
      <p>
        05-22-0001: As the party endures the cold and wanders the subway, above
        ground it rolls over to the next day (i.e. food and grub requirements
        next session). Finally, they find a small maintenance tunnel and at the
        end of it is a red door that is operated by the "priority access" card
        found in the break room.
        <br />
        <br />
        Beyond the red door is another hallway. As they walk down this hall,
        they hear clanking and banging. The racket gets louder and louder as
        they approach an open doorway into massive cavern. The cavern is roughly
        the size of a football field. The hallway continues into the room as a
        balcony of sorts as it falls off to the side into a gigantic pit about a
        hundred feet below the level of the balcony. In the pit are tarps,
        pallets and old supplies. On one side of the pit is a big iron loading
        door that is closed and a gigantic digger machine.
        <br />
        <br />
        The machine is moving and smashing into the walls of the pit, with it's
        big augur occasionally drilling into the ground at a terrible angle then
        retracting. The digger, which is huge (see details of graphic), is
        malfunctioning, and trying the same movements and digging over and over
        without success and making terrible machine noise. Halfway down the
        balcony walkway is a door into a control room.
        <br />
        <br />
        Inside the control room is a tube with arm holes near a hanging
        controller, and next to the tube is a central podium. Three metallic
        containers are on the wall opposite. The podium and the tube are tossing
        off sparks occasionally and there is a blinking red light on the podium
        display that seems to indicate the distressed digger in the pit outside
        the room. Smoke Raven uses his knowledge of the zone and archaeology to
        determine that all of the machinery in this room controls the digger.
        However, his pushed roll takes him down to one point of wit so when it's
        time to operate the machine, the gearhead Bayazid has to step up.
        Bayazid rolls well and stops the errant digger. Once that is done, there
        is a hissing sound as the metallic chests open automatically. One of the
        other mutants helping Bayazid by watching the digger notices that the
        large metal bay doors down in the pit opens as well. At the same time,
        the podium near the tube starts smoking and a pre-recorded voice starts
        playing as an emergency light comes on in the control room.
        <br />
        <br />
        "Warning. Facility compromised. Those son of a bitches at Noatun messed
        with our shit again. Warning. Get above ground. Facility compromised."
        The party quickly searches the containers and find a REVOLVER and a
        sturdy shelf? In fact, the sturdy shelf is a mythic artifact that
        requires DEV level of 60. With homebrew rules that impose -1 for every
        complete 10 points above ark's DEV level, it means the Sunshine Plaze
        will need to get three more DEV level points in technology before a
        COMPREHEND roll on this artifact is even possible.
        <br />
        <br />
        Once the artifacts are picked up, the gang realizes the tube and the
        podium are on fire. Smoke and acrid metal fumes are filling the control
        room. Also, the earthquake is increasing in power again. In fact, moment
        by moment the earthquake is getting worse and it seems to be getting
        closer. Smoke Raven makes an incredible KNOW THE ZONE with -3 to connect
        what's happening to boogeymen stories told by stalkers about a WORLD
        WORM that tunnels below the zone and can eat entire buildings and
        destroy anything. It is in fact a WORLD WORM closing in on the pit,
        having been attracted by the vibrations of the malfunctioning machine.
        This has been the cause of the Zone quakes plaguing several sessions!
        <br />
        <br />
        Smoke Raven knows if the stories are true that the mere knowledge that
        the worm is coming can cause mental damage, and if it gets even closer,
        it exudes poisonous fumes. It is said if you smell those fumes, it
        brings a blissful mental collapse as the worm grabs you with it's
        pincers and tosses you into it mouth of rotating, saw-like teeth. In
        short, Smoke Raven informs the party by shouting, "it's the legends!
        It's the world worm. It will eat the entire world. We have to run!"
        Never ones to doubt their stalker, the PCs run toward the ladders going
        into the pit, determined to get to the large metal bay door.
        <br />
        <br />
        Except, Chromicus hesitates. He hears the voice over the speakers saying
        "get above ground" and wonders if the bay door down in the pit is the
        way to go. In the end he decides, "I'm going to stick with my gang."
        However, as the last mutant down the ladder, he suffers the worst from
        the mental assault of the WORLD WORM (a crippling 6D6 empathy attack
        just from the horror of the approaching world eater). Chromicus
        collapses to his knees at the bottom of the ladder, awash with
        hopelessness and fear. Smoke Raven also breaks, the weight of the
        stories of the World Worm mixing with the earth-shaking din filling the
        chamber. Double Bird, Bayazid, and Hulagu have a moment of panic; they
        can't possible carry or drag their broken comrades and make it out
        before the worm can they? Then they remember all the scrap they found
        including a nice flatbed cart to carry the scrap. They toss Smoke Raven
        and Chromicus onto the cart and the three of them push the cart at a
        run.
        <br />
        <br />
        The mutants are scrambling and running, all three pushed the flatbed
        cart loaded with two mutants and scrap. They enter the wide construction
        tunnel; the worm breaks through the far wall of the pit in pursuit. They
        are nearing the elevator as the worm closes in. The poisonous vapors are
        a 8D6 attack on wits meant to break down prey so it cannot get away.
        Bayazid and Double Bird are coughing from the poison, dust and falling
        debris, but they keep pushing. Boss Hulagu succumbs to the poison vapors
        rolling off the mythic beast so close at hand. Hulagu stops pushing the
        cart and just stands, hopeless, arms limp at his sides. Double Bird
        rolls FORCE to get Hulagu onto the cart.
        <br />
        <br />
        As Bayazid and Double Bird slam the flatbed cart on the waiting elevator
        at the end of the maintenance tunnel, they have to roll a MOVE to avoid
        an attack from the world worms hooked proboscis meant to hold prey for
        the rotating, razor jaws of it's mouth. Bayazid fails and we fear the
        new gearhead will die (i.e. the World Worm does FIVE points damage with
        its bite). However, on a 15d6 roll to bite Bayazid, the world worm
        fails; probably because it is also turning to avoid the metal elevator
        shaft, wires and runners (which it does not like to eat if it can be
        avoided). The elevator climbs, metal screaming in cacophony the entire
        way. However, it stays on track and the party makes it to the top. They
        are in a small cement room with the elevator and a small metal ladder to
        a trap door in the ceiling. The earthquake and the final passage of the
        worm has essentially collapsed and destroyed the caverns beneath the
        Sunshine Plaza; however, the story benefit of whatever remaining tunnels
        are intact and the exploration equates to an automatic completion of the
        EXPAND THE ARK project (home brew project that allows Ark to occupy two
        hexes if both are free of rot with DEV Bonus: Warfare +2D6, Food +1D6).
        <br />
        <br />
        Poking their head above ground, the party emerges from a disguised metal
        trapdoop in a sector of the zone. After many many hours travelling
        underground they think they might be no more than six hexes from the
        Plaza, but they have no idea which hex. Next session, they travel blind
        and must find their way home.
      </p>
    ),
    image: {
      imagePath: digger,
      altText: `Machinery from the Old Age found in huge pit under the Sunshine Plaza`,
      className: "imageLeft",
      imageCaption: `THE DIGGER`,
    },
  },
  {
    title: "Wrapup",
    text: (
      <p>
        Next session will start on in-game day 05-22-2021 at 5am with party in
        random sector.
        <br />
        <br />
        No need to worry about rot due to day spent underground. 5 XP awarded so
        spend next time.
        <br />
        <br />
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        .
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: worldWorm,
      altText: `The World Worm - the most feared apparition of the Zone known to Stalkers`,
      className: "imageLeft",
      imageCaption: "WORLD WORM",
    },
  },
];

const MYZAnchortownSession31 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 31: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  The IL gang returns to the Sunshine Plaza, has a council
                  meeting, and goes underground to investigate the mystery of
                  why the Zone Quakes.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                {/*<Card.Title style={cardTitle}>Shootout at Burger Jim's, 03-27-0001 - 03-27-0001</Card.Title>*/}
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession31;
