import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../assets/myz/myz-section-divider.jpg";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import { useEffect } from "react";

const cardTitle = {
  paddingTop: 10,
  paddingLeft: 10,
  paddingBottom: 10,
  backgroundColor: "rgba(221, 117, 28, .7) ",
};
const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Dog Handler",
    text: (
      <p>
        <ul>
          <li>Dog has weapon damage 2.</li>
          <li>
            It is action to command dog to attack; after that they attack on
            their own.
          </li>
          <li>It is a maneuver to call your dog back.</li>
        </ul>
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
    },
  },
  {
    title: "Bomb Shrapnel Damage",
    text: (
      <p>
        Misprint: Page 94 - Shrapnel:
        <br />
        This is a stunt when using the Jury-Rig skill. If so armed, the{" "}
        <strike>Blast Power</strike> weapon damage of the bomb is increased to
        2.
        <br />
        The base damage for the first RAD would be 2, but then each additional
        RAD would just add 1 damage (not the base weapon damage again). <br />
        This would make it work like other all the other weapons. So if Naphta
        rolled 4 RAD, it would be 5 damage (2+1+1+1) not 8 (2+2+2+2).
        <br />
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
    },
  },
  {
    title: "Chronicler Inspire",
    text: (
      <p>
        'The chroniclers specialist skill Inspire has proven to be a little go
        good. Now the first success rolled gives the target +2 or -2 to the
        skill he is about to roll for. Each additional success gives an
        additional +1 or -1 to this."
        <br />
        <br />
        So Inspire no longer gives successes to the target, only modification
        dice (skill dice)
        <br />
        <br />
        Note also that we are using homebrew rule that Inspire cannot be used to
        assist on a skill that the Chronicler using Inspire{" "}
        <b>could not themselves roll</b>
        due to lack of talent, skill, gear, or whatever.
        <br />
        This includes not being able to inspire Zone Cook if Chronicler doesn’t
        also have that talent. Even though the Zone Cook talent requires a roll
        using the Know the Zone skill,{" "}
        <b>you cannot inspire a skill roll that you could not roll yourself</b>
        &nbsp;due to lack of talent (or other reason) as noted above.{" "}
        <a href="https://forum.frialigan.se/viewtopic.php?f=111&t=7238">
          Reference
        </a>
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
    },
  },
  {
    title: "NPCs and Mutations",
    text: (
      <p>
        At the start of each session, the GM gets a number of Mutation Points to
        her pool equal to the total number of MP that the players have combined.
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeftGlow",
    },
  },
  {
    title: "Reflexes Mutation",
    text: (
      <p>
        Fight or Shoot an enemy a second time in the same turn. The extra attack
        costs you 1 MP. You don’t get an extra maneuver. (E)
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
    },
  },
  {
    title: "Initiative Roll",
    text: (
      <p>
        Roll a D6 each. No skill is used, and you can’t push the roll. The one
        who rolls highest acts first. If it’s a tie, highest current Agility
        score goes first. If it’s still a tie, break it with any unmodified die
        roll.
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
    },
  },
  {
    title: "Grappling",
    text: (
      <p>
        As a stunt when you Fight, you can choose to pin your opponent down
        using fangs and claws, or just your body weight.
        <br />
        To break loose, your{" "}
        <b>opponent needs to win an opposed roll for Fight</b> against you.
        <br />
        This roll counts as an action for your opponent but not for you. <br />
        While pinned, your opponent can perform no other action requiring
        physical movement.
        <br />
        <br />
        <b>Grapple Attack</b>: While grappling someone, the only physical action
        you can perform is a grapple attack against the opponent.
        <br />
        This counts as a normal close combat attack, with these differences:
        <br />
        <ul>
          <li>--You can’t use any weapon.</li>
          <li>--You get a +2 modification.</li>
          <li>--Your enemy cannot defend against the attack.</li>
        </ul>
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
    },
  },
  {
    title: "Recovery",
    text: (
      <p>
        <ul>
          <li>
            --Strength: A ration of food per point of Strength to be restored.
          </li>
          <li>
            --Agility: A ration of water per point of Agility to be restored.
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
    },
  },
  {
    title: "Rule Clarifications (homebrew based on fria ligan form talks)",
    text: (
      <p>
        <ul>
          <li>
            Scrap Armor and Protective Suits are REGULAR encumbrance weight.
          </li>
          <li>
            Non-durable items breaks after use even if they do not work
            correctly (i.e. scrap armor that fails to protect from damage still
            breaks after roll for protection if it is not durable ).
          </li>
          <li>
            INSECT WINGS mutation effect of sound attack is SHORT range. Mutant
            does not choose targets; it affects closest enemies first with
            random tie-break if more than one at same distance. We decided
            attack only affects enemies (pending clarificaiton from forums).
          </li>
          <li>
            SHAKE IT OFF is rolled after armor or other effects that prevent
            damage. In other words, SHAKE IT OFF is rolled against damage that
            would otherwise be taken once damage prevention effects resolve.
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
    },
  },
];

const MYZErrata = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">Mutant Year Zero: Errata</h1>
                <p className="lead leadExtra">
                  Errata based on discussions in{" "}
                  <a
                    href="https://forum.frialigan.se/viewtopic.php?f=111&t=2556"
                    rel="noreferrer"
                  >
                    Fria Ligan forum
                  </a>
                  .<br />
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Title style={cardTitle}>MYZ Errata</Card.Title>

                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZErrata;
