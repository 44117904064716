import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import mbLogo from "../../../../src/assets/mork-borg/mork-borg-logo-horizontal.webp";
import sectionDivider from "../../../../src/assets/mork-borg/mork-borg-section-divider.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import { useEffect } from "react";

import fangedDeserter from "../../../../src/assets/mork-borg/class-fanged-deserter.webp";
import gutterbornScum from "../../../../src/assets/mork-borg/class-gutterborn-scum.webp";
import esotericHermit from "../../../../src/assets/mork-borg/class-esoteric-hermit.webp";
import wretchedRoyalty from "../../../../src/assets/mork-borg/class-wretched-royalty.webp";
import hereticalPriest from "../../../../src/assets/mork-borg/class-heretical-priest.webp";
import occultHerbmaster from "../../../../src/assets/mork-borg/class-occult-herbmaster.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Fanged Deserter",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <p>
          You have thirty or so friends who never let you down: YOUR TEETH.
          <br />
          <br />
          Disloyal, deranged or simply uncontrollable, any group that didn't
          boot you out you left anyway.
          <br />
          <br />
          But your parliament of teeth—enormous, protruding, thick and
          sharp—have always been your allies.
        </p>
        <br />
        <br />
      </div>
    ),
    image: {
      imagePath: fangedDeserter,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "Gutterborn Scum",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <p>
          An ill star smiled upon your birth. Poverty, crime and bad parenting
          didn't help either. <br />
          <br />
          In your community an honest day's work was never an option. Not that
          you ever tried, what are you, some kind of mug? <br />
          <br />A razor blade and a moonless night are worth a week of
          chump-work.
        </p>
        <br />
        <br />
      </div>
    ),
    image: {
      imagePath: gutterbornScum,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "Esoteric Hermit",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <p>
          The stone of your cave is one with the stars.
          <br />
          <br />
          Silence and perfection.
          <br />
          <br />
          Now the chaos of a fallen world disturbs your rituals and the caul of
          night grows blacker than your cavern's gloom.
          <br />
          <br />
          Irritating!
        </p>
        <br />
        <br />
      </div>
    ),
    image: {
      imagePath: esotericHermit,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "Wretched Royalty",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <p>
          Bowed down only by the memories of your own lost glory, <br />
          you could never submit to anyone else.
          <br />
          <br /> Not you, of noble blood!
        </p>
        <br />
        <br />
        <br />
        <br />
      </div>
    ),
    image: {
      imagePath: wretchedRoyalty,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "Heretical Priest",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <p>
          Hunted by the Two-Headed Basilisks of the One True Faith,
          <br />
          this heretic can be found raving in ruins,
          <br />
          traipsing endlessly down dusty roads and <br />
          desecrating cathedrals by night.
        </p>
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    ),
    image: {
      imagePath: hereticalPriest,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "Occult Herbmaster",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <p>
          Born of the mushroom,
          <br />
          raised in the glade,
          <br />
          watched by the eye of the moon in a silverblack pool.
        </p>
        <br />
        <br />
        <br />
        <br />
      </div>
    ),
    image: {
      imagePath: occultHerbmaster,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
];

const MBClassesCore = () => {
  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/mork-borg/bkgnd-01.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={mbLogo}
                  style={{ maxWidth: "200px" }}
                  alt="Mork Borg campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">Mork Borg: Core Classes</h1>
                <p className="lead leadExtra">
                  <div
                    class="morkBorgFont morkBorgText"
                    style={{ textAlign: "center" }}
                  >
                    💀{" "}
                    <a
                      style={{ color: "yellow" }}
                      href="./mork-borg-classes-gallery"
                    >
                      Class Gallery
                    </a>{" "}
                    💀{" "}
                    <a
                      style={{ color: "yellow" }}
                      href="./mork-borg-classes-third-party"
                    >
                      Third Party Classes Overview
                    </a>{" "}
                    💀
                  </div>
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MBClassesCore;
