import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import logo from "../../../../src/assets/forbidden-lands/forbidden-lands-logo.webp";
import sectionDivider from "../../../../src/assets/forbidden-lands/campaign/bitter-reach-page-banner-gambling.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";
import "./forbidden-lands-styles.scss";
import alderWars from "../../../../src/assets/forbidden-lands/gambling/game-alder-wars.webp";
import kingdom from "../../../../src/assets/forbidden-lands/gambling/game-kingdom-of-the-ice-elves.webp";
import settlers from "../../../../src/assets/forbidden-lands/gambling/game-settlers-of-galdane.webp";
import storytelling from "../../../../src/assets/forbidden-lands/contests/storytelling.webp";
import highKick from "../../../../src/assets/forbidden-lands/contests/two-foot-high-kick.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "1",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Carter One",
  backgroundColor: "rgba(0, 0, 0, 1) ",
  color: "white",
  marginBottom: "15px",
};

const FBLGambling = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  const section1Data = [
    {
      title: "Gambling in the Bitter Reach",
      anchor: "prisoners",
      sectionTitleCssName: "sectionTitleFBL",
      text: (
        <div>
          <div className="fblBodyText">
            <p>
              Gambling inspired by the excellent board games from companies like{" "}
              <a
                href="https://www.riograndegames.com/"
                rel="noreferrer"
                target="_blank"
              >
                Rio Grande Games
              </a>{" "}
              .
            </p>
            <hr
              style={{
                backgroundColor: "white",
                color: "white",
                height: "5px",
                opacity: "1.0",
              }}
            />
            <ImageComponent
              image={alderWars}
              caption="Alder Wars"
              cssClass="imageLeft"
              handleClick={handleClick}
              modalBody="Dice game played with much gusto, and cheating is 'allowed'."
            ></ImageComponent>
            <ModalComponent
              show={showModal}
              onHide={handleCloseModal}
              modalData={modalData}
            ></ModalComponent>
            <h2>Alder Wars</h2>
            Dice game played with much gusto. Cheating is "allowed", but only if
            you're not caught. Enact the alder wars of old and alter the outcome
            in your favor.
            <br />
            <br />
            Each player rolls INSIGHT + SLEIGHT OF HAND. Most successes wins.
            <br />
            <br />
            While playing, one is likely to hear lore about the first, second,
            or third Alder Wars.
            <hr
              style={{
                backgroundColor: "white",
                color: "white",
                height: "5px",
                opacity: "1.0",
              }}
            />
            <ImageComponent
              image={kingdom}
              caption="Kingdom"
              cssClass="imageLeft"
              handleClick={handleClick}
              modalBody="This simple physical game is played by flicking little, flat pieces of wood across a table."
            ></ImageComponent>
            <ModalComponent
              show={showModal}
              onHide={handleCloseModal}
              modalData={modalData}
            ></ModalComponent>
            <h2>Kingdom of the Ice Elves</h2>
            This simple physical game is played by flicking little, flat pieces
            of wood across a table. Players try to get wooden figures
            representing humans across the table by "stepping" on the flat
            pieces. It's a game of physical dexterity and strategy.
            <br />
            <br />
            Each player rolls MOVE + SCOUTING. Most successes wins.
            <br />
            <br />
            During the game it's possible one might heard the legend of{" "}
            <em>The Naniuk</em>, or perhaps even the{" "}
            <em>Palace of the Winter King</em>.
            <hr
              style={{
                backgroundColor: "white",
                color: "white",
                height: "5px",
                opacity: "1.0",
              }}
            />
            <ImageComponent
              image={settlers}
              caption="Settlers"
              cssClass="imageLeft"
              handleClick={handleClick}
              modalBody="Card game that emphasizes resource management, bluffing, and cunning."
            ></ImageComponent>
            <ModalComponent
              show={showModal}
              onHide={handleCloseModal}
              modalData={modalData}
            ></ModalComponent>
            <h2>Settlers of Galdane</h2>
            Card game that emphasizes resource management, bluffing, and
            cunning.
            <br />
            <br />
            Each player rolls MANIPULATION + INSIGHT. Most successes wins.
            <br />
            <br />
            While playing, one is sure to hear more about the Galdanes and their
            culture.
            <br />
            <br />
            <a href="#pageTop">Back to Top</a>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/forbidden-lands/fbl-page-background-blizzard.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.backgroundWidth = "100vw";
    document.body.backgroundHeight = "100vh";
    document.body.style.backgroundSize = "cover";
  }, []);

  return (
    <Container>
      {/* <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin" 
                    style={{position: 'float', float: 'right',width: '120px', height: '240px'}} marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=smokeraven-20&language=en_US&marketplace=amazon&region=US&placement=9189143701&asins=9189143701&linkId=d1384e0e6b9ef083fee35d7272a71f88&show_border=true&link_opens_in_new_window=true"></iframe> */}
      <Row>
        <Col>
          <div
            style={{
              backgroundColor: "transparent",
              fontFamily: "IM Fell Great Primer",
            }}
          >
            <div style={pageHeader}>
              <main role="main" className="inner cover text-center">
                <a href="#freedom-day" name="pageTop">
                  <img
                    src={logo}
                    style={{ maxWidth: "200px" }}
                    alt="Forbidden Lands Campaign"
                  />
                </a>
              </main>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title className="cardTitle-forbidden-lands  ">
                An overview of some forms of gambling found in the Forbidden
                Lands.
                {/* <small>
                  <br />☠ Session 01:{" "}
                  <a href="./forbidden-lands-session-01">
                    Freedom Day in Gomsten
                  </a>{" "}
                  ⇒
                </small> */}
              </Card.Title>
              <div>
                <ContentContainer data={section1Data} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default FBLGambling;
