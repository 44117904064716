import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import moonPark from "../../../../assets/myz/overgrown-park-0.jpg";
import fungus from "../../../../assets/myz/parasitic-fungus.jpg";

const cardTitle = {
  paddingTop: 10,
  paddingLeft: 10,
  paddingBottom: 10,
  backgroundColor: "rgba(221, 117, 28, .7) ",
};
const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "",
    text: (
      <p>
        After their first day in The Zone, our mutants are back at the Sunshine
        Plaza. Double Bird is so sick he could not sleep. He is _sleepless_ and
        suffering from Parasitic fungus that damages strength and wits. Bianca
        comes by to give Double Bird food and announce that Zane is sick too.
        {/* <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin" 
    style={{margin: '10px', position: 'float', float: 'left',width: '120px', height: '200px'}} marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=smokeraven-20&language=en_US&marketplace=amazon&region=US&placement=1912200902&asins=1912200902&linkId=48e81a9781e8ce503062cca08f867e95&show_border=true&link_opens_in_new_window=true"></iframe> */}
      </p>
    ),
    image: {
      imagePath: fungus,
      altText: `
        Not every danger in the Zone has a face. Some dangers, such as this mysterious, toxic infection, can be
        worse than a drunk Zone Ghoul on a hot tin roof.`,
      className: "imageRight",
    },
  },
  {
    title: "",
    text: (
      <p>
        Hulugu uses racketeering to shake down some of Berke's men for food. The
        rest of the gang gathers in the Gathering Place to hear Danova the
        Envious tell stories about The People and their tradition of burning
        dead bodies.
      </p>
    ),
  },
  {
    title: "",
    text: (
      <p>
        Smoke Raven and Skut visit Loud Sue to lobby her support for Temple
        project. Smoke Raven pushes with a fail (+1 Session Body Count), but
        they get her attitude to shift toward positive.
      </p>
    ),
  },
];

const section2Data = [
  {
    title: "Sector D05: Valley of the Moon Park",
    text: (
      <p>
        Overgrown park found. Subu barking at base of slide on metal rocket in
        the park. Smoke Raven climbs up to find starving zone dog and corpse in
        yellow body suit. Scrap, one bullet, and artifact [protective suit]
        found. Smoke Raven clears path and dog goes down slide and is friendly
        with Subu. Jebe adopts new dog and its name is Rocket.
      </p>
    ),
    image: {
      imagePath: moonPark,
      altText: "Sunshine Plaza - First Floor",
      className: "imageRight",
    },
  },
  {
    title: "Sector C04: Military Base",
    text: (
      <p>
        As woods in this sector clear, party finds metal statue of mastodon with
        weather-worn, unreadable plaque. Further on, they find ruins of military
        camp. They see humanoid monkey and humanoid dog rummaging and party
        hides. As monkey and dog approach, Jebe sends Subu out of hiding. The
        monkey (who turns out later to be named Gary) shoots an Subu with
        slingshot and fight ensues.
      </p>
    ),
  },
  {
    title: "",
    text: (
      <p>
        Gary the monkey is killed and the dog mutant named Elson surrenders
        after being wounded and grappled by Smoke Raven. Elson switches to
        sub-alpha surrender. He shows the party to a nearby pond with an
        artifact (Canoe) tangled in the brush in the middle. Smoke Raven wades
        out to grab canoe and is attacked by Zone Leeches. The party, including
        Elson, rush in to rescue. Smoke Raven is broken but is only out of
        breath and is Healed. The leeches are defeated and canoe retrieved.
      </p>
    ),
  },
];

const section3Data = [
  {
    text: `Gang returns to the ark by retracing cleared zones with no encounters, arriving just before nightfall. 
    They wash off the rot and turn in the protective suit and canoe to the Order of the New Dawn to increase 
    tech level of Sunshine Plaza.`,
    title: "",
  },
  {
    text: (
      <p>
        <br />
        Check out Session Body Count at:{" "}
        <a href="./mutant-year-zero-anchortown-population-notable-events">
          SESSION BODY COUNT AND POPULATION
        </a>
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a href="./mutant-year-zero-anchortown-graveyard">
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    title: "",
  },
  {
    text: `Next session starts morning of in-game date, 03-03-0001.`,
    title: "",
  },
];

const MYZAnchortownSession02 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 02: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  Our mutants explore the Zone while Double Bird stays home sick
                  with mysterious illness.
                  <br />
                  They discover a zone hound and an artifact in an abandoned
                  park and animals mutants on an old military base.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Title style={cardTitle}>
                  Sickness and Projects, 03-02-0001
                </Card.Title>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
                <Card.Title style={cardTitle}>The Zone</Card.Title>
                <Card.Text>
                  <ContentContainer
                    data={section2Data}
                    imagePath={moonPark.valueOf()}
                  />
                </Card.Text>
                <Card.Title style={cardTitle}>Home Again</Card.Title>
                <Card.Text>
                  <ContentContainer data={section3Data} />
                </Card.Text>
                <Card.Title style={cardTitle}>Executive Summary</Card.Title>
                <Card.Text>
                  <ul>
                    <li>Double Bird and Zane sick with parasitic fungus.</li>
                    <li>
                      Two artifacts turned in to the Order of the New Dawn
                      (protective suit and canoe).
                    </li>
                    <li>Elson the dog mutant added to IL gang.</li>
                    <li>Rocket added to dogs of The People.</li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession02;
