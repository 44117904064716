import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import mbLogo from "../../../../src/assets/mork-borg/mork-borg-logo.webp";
import sectionDivider from "../../../../src/assets/mork-borg/mork-borg-section-divider.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";

import hagauzza from "../../../../src/assets/mork-borg/session08/hagauzza.webp";
import wailingWell from "../../../../src/assets/mork-borg/session08/the-wailing-well.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const MBMalumMortisSession08 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/mork-borg/bkgnd-01.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  const section1Data = [
    {
      title:
        "Our savior scum have tea with a blood witch, capture a liar bird, and battle goblins and a troll beneath an abandoned graveyard in Sarkash.",
      text: (
        <div class="morkBorgFont morkBorgTitleText">
          <ImageComponent
            image={hagauzza}
            caption="Hagauzza the blood witch"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="<b>Hagauzza</b> the Blood Witch of Lake Onda."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <p>
            Our party consists of:
            <ul>
              <li>
                <span style={{ color: "#00FFFF" }}>KLATUUBARADA</span>: <br />
                <span style={{ color: "#ADFF2F" }}>fugitive knight</span>,
                argumentative, pyromaniacal.
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>SIR FAT HEAD</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>mushroom eater</span>, wacked
                out prophet, scrambled like eggs.
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>SPUG</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>wretched royalty</span>{" "}
                kleptomaniac, afraid of pain.
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>WINSTON DUNGHILL</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>soul-damned drunkard</span>,
                a nervous drunk with resiliancy.
              </li>
              <li>
                <span style={{ color: "#030303" }}>HERMAN (absent)</span>:{" "}
                <span style={{ color: "#030303" }}>gutterborn scvm</span>.
                worried, naive, hands sore caked, pyromaniac.
              </li>
              <li>
                <span style={{ color: "#030303" }}>HILDEBRAND (absent)</span>:{" "}
                <span style={{ color: "#030303" }}>heretical priest</span>,
                washes hands & obsessively clean, fire baptized!
              </li>
              <li style={{ fontSize: "0.8em" }}>
                <span style={{ color: "#00FFFF" }}>Donkey Hoatee</span>: donkey
              </li>
              <li style={{ fontSize: "0.8em" }}>
                <span style={{ color: "#00FFFF" }}>
                  Barbarister the Incredible Horse
                </span>
                : talking horse minion (master?) to Spug
              </li>
              <li style={{ fontSize: "0.8em" }}>
                <span style={{ color: "#00FFFF" }}>Jaque Manaque</span>: wannabe
                knight squire-lad trying to earn his keep
              </li>
            </ul>
            <div
              style={{ border: "3px solid red", padding: "5px", margin: "5px" }}
            >
              Misery Countdown:
              <ul>
                <li>
                  Psalm 6, Verse 1: You shall know the last day is come. The sun
                  shall set and never rise.
                  <ul>
                    <li>
                      EFFECT: sun never shines. World is dark always. No more
                      daytime.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            This session saw HERMAN and HILDEBRAND sitting out.
            <br />
            <br />
            On the road, our stalwart sickos spy an abandoned monastery with a
            big ole bogfeeder grazing out front. They enter the monastery and
            track down a cheeping sound interspersed with a human voice. The
            party &nbsp;
            <span style={{ color: "#FF7F50" }}>discovered a LIAR BIRD</span>,
            much prized by members of royal court and inquisitors for their
            ability to help ferret out untruths. Spug's background as a wretched
            royal gave him insight into the worth of the bird.
            <br />
            <br />
            Barbarister the Talking Horse advises Spug that rather than
            capturing the bird, they may be able to convince it to willingly
            come with the party. At first Sir Fat Head and then Spug try to
            cajole the bird with tall tales and exaggerations. Eventually the
            bird becomes bored and starts to fly away, but not before Spug
            stuffs the avian lie detector into the bag he was holding all the
            while. He gives his talking horse a knowing, told-you-so look and
            the party continues onward.
            <br />
            <br />
            The terrain is increasingly marshy near the southern end of Lake
            Onda. The party braves the swamp with their lamp lit, as it is at
            all times thanks to an ever-dark world with no sunlight at all due
            to the first misery (see above). They approach a leaning hovel made
            of long reeds, sticks and mud. The darkness is illuminated with the
            pulsing redness of crimson fireflies hovering languidly in the humid
            air. There are tendrils of greenish-grey smoke seeping out of the
            pointed top of the hovel and a grating, throaty female voice
            sings-and chants.
            <br />
            <br />
            Just as the party ponders about stopping and hiding or continuing
            onward, the voice intones{" "}
            <span style={{ color: "#FF7F50" }}>
              "sacrifice to prove you are serious"
            </span>
            ; then the hovel rises up on two legs made of plant stalks and moves
            20 feet away. On the ground in the hovel's original location is a
            glowing yellow outline on the flattened patch of ground, it is a
            spiral sigil that terminates with jagged point in the center and it
            is about 10 feet in diameter.
            <br />
            <br />
            Every exchanges knowing looks whilst Igor the Carrier cluelessly
            picks his nose; blithely unaware his services were always intended
            to end in a blood sacrifice for the blood witch Hagauzza per
            instrutions from Gesa of the Ancient Eye in Galgenbeck. "Hey Igor",
            says Klaatubarada with a voice like gravel dropping on a ceramic
            serving plate, "grab that bedroll! We need it in the middle of that
            glowing yellow sigil on the ground there." Igor's bulging eyes go
            wider than normal. "Oh there is it? On the ground eh?"
            <br />
            <br />
            Klaatubarada tries to shove the clueless sacrifice into the magic
            circle but fails. Spug has a Tidecall scroll ready as backup and
            shouts the magical invocation. However, it is a failed reading. Igor
            crys out in a tremulous voice, "I do believe you meant me harm, I'm
            going back to Galgenbeck"; and with a frightened grin he turns 180
            degrees to flee. "Hey Buddy!!" shouts Winston Dunghill. Like all
            true drunkards, he was sprawled on the ground unnoticed and uses his
            drunken power to surprise Igor and aikido his bony ass into the
            middle of the sigil. The yellow sigil animates with the jagged piece
            in the middle rearing up like an energy snake.{" "}
            <span style={{ color: "#FF7F50" }}>
              The magical power thrusts through Igor's chest with a radiant
              blast of power, and the blood sacrifice is acheived.
            </span>{" "}
            Igor's skin sloughs off and he melts into a puddle of organic ruin
            and blends into the moist ground of the swamp. From the now open
            doorway of the smoky hut there is a leathery golf clap and a ragged
            giggle. Hagauzza the blood witch stands hunched over, caked in blood
            and rimmed with a crimson glow from the fire inside her
            Baba-Yaga-wanna-be Hut. "Come inside, we have business you fancy
            fellows!" she shouts. Sir Fat Head beams, "Fancy is as fancy does;
            I'm the fanciest in fancytown!" Spug glares at Sir Fat Head then
            darts his eyes toward Barbarister the Talking Horse and tells the
            horse, "keep an eye on Jacque Manaque."
            <br />
            <br />
            Inside the hut{" "}
            <span style={{ color: "#FF7F50" }}>
              Hagauzza offers tea from a stinked cauldron
            </span>
            . She serves the foul brew in remarkably anotopic porcelain tea
            cups. Spug foregoes the brew as does Klaatubarada (who finds
            fascinating, dancing figures in the fire, clearly held together by
            magic). Sir Fat Head and Winston, both fond of dazzling their
            senses, gulp down the tea. Both make unfortunate rolls and{" "}
            <span style={{ color: "yellow" }}>gain a curse</span> each.
            <br />
            <br />
            SIR FAT HEAD receives the curse "glass shatters in your presence".
            He discovers that rigth away as his tea cup explodes in his face.
            <br />
            <u>Remove the curse</u>: Must visit Tuwold the Red, a former priest
            now rotting in King Fathmu's dungeons in Schleswig for murdering
            dozens one crazed night. Tuvold claims to have been possessed by a
            devil.
            <br />
            <br />
            WINSTON receives the curse "You cannot die. Your body can still be
            broken, beaten, crushed, pierced and mauled. And it still hurts. But
            you are never released from agony until everything blackens and
            burns."
            <br />
            <u>Remove the curse</u>: must find Ketel-Erg, an eyeless hermit who
            once lifted a curse, they say.
            <br />
            <br />
            Hagauzza tends to a beautiful blonde, blue-eyed baby changeling as
            she explains her older daughter has the goblin curse and to get
            Hagauzza's help locating the next clue for the BLOOD RELIC, the
            party must travel to the Waling Well near the edge of the Sarkash
            forest and kill the goblins and bring her back the ears.
          </p>
        </div>
      ),
    },
    {
      title: "Alls Well That Ends In Hell",
      text: (
        <div class="morkBorgFont morkBorgTitleText">
          <ImageComponent
            image={wailingWell}
            caption="The Wailing Well"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="The Wailing Well in an abandoned graveyard in Sarkash forest is infested with goblins."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>

          <p>
            <br />
            Upon arriving at the graveyard in Sarkash, our party finds the well.
            Goblin footprints in the moise forest ground made finding the well a
            cinch. They{" "}
            <span style={{ color: "#FF7F50" }}>
              meet KAROUL THE WICKHEAD exhuming graves
            </span>{" "}
            looking for food. Sir Fat Head donates food and KAROUL tells party
            in his/her broken speech that goblins "and something else" live in
            the ground under the graveyard by way of the well. The well is too
            deep for the bottom to be visible but a non-goblin sound of moaning
            and wailing drifts up from the darkness below.
            <br />
            Everyone finds hiding places and begins talking and shouting and
            generally making a racket, hoping to{" "}
            <span style={{ color: "#FF7F50" }}>
              lure the goblins out to investigate
            </span>
            . After a minute or so, a grappling hook appears and hooks the edge
            of the well. The rope attached to the hook vibrates as something
            climbs up. It's a goblin. Spug drags the critter across the ground
            with Tidecall power and reduces it to one hit poin. Then, using
            Enochian Syntax, Klaatubarada commands the goblin to cajole his
            friends to come on out to see something interesting. The power
            activation is a CRITICAL SUCCESS and we surmise that the goblin
            (named Knifey) invents the best most plausible bullshit a goblin
            could ever hear. What ensues is a battle wherein three goblins
            arrive one by one and are surprised. With a combination of powers
            and melee, the goblins are wiped out. Before killing Knifey last,
            BESTIAL SPEECH is used to understand his chittering and the party
            realizes there are still two more goblins underground "cookin' the
            stew".
            <br />
            <br />
            And so with the handy rope in place the party descends with roughly
            the following bullet points:
            <ul>
              <li>
                Spug slips and crashes into the dark waters below. Without light
                he stumbles blindly to find the wall of the cave and hide in the
                chest-deep water.
              </li>
              <li>
                Sir Fat Head and Klaatubarada press through a makeshift door of
                hair and glass midway down the tunnel.
              </li>
              <li>
                Keroul the Wickhead also slips and falls into the water below.
                But not at least there is light!
              </li>
              <li>
                Klaatub and Fat Head investigate. They find an undying{" "}
                <span style={{ color: "#FF7F50" }}>zombie named ROLF</span> in
                the storage room. His ever re-newing, never-dying guts have
                harvested for the goblins' psychedelic stew for months and he
                begs for realease.{" "}
              </li>
              <li>
                Winston Churchill drops in on the boys at the bottom of the
                well. Just in time for to see a massive TROLL charging. The
                troll hits KEROUL with a critical hit and vaporizes the wickhead
                with 20 points damage and its light out.
              </li>
              <li>
                After Sir Fat Head gets the story on ROLF's pact with Necrubel
                that led to his life as respawning goblin chow, Klaatu convinces
                ROLF to jump down and help with whatever godawful racket is
                going on at the bottom of the well.{" "}
              </li>
              <li>
                As the battle with the troll rages, Klaatu and Fat Head find the
                last two goblins. They dodge the foul stew tossed at them and
                kill the goblins.
              </li>
              <li>
                <span style={{ color: "#FF7F50" }}>
                  Spug and Winston kill the TROLL with amazing uses of Tidecall
                  scroll by Spug, some deadly crossbow shots from Spug, and
                  Winston going full MOOD SWING and slicing the troll like a
                  turkey on Thanksgiving
                </span>
                . The troll randomly tagets NPCs and thanks to some failed
                morale checks due to cowardly nature, the battle ends with
                Winston getting the killing blow at the same time as Spug moving
                in and cleaving with a critical hit with a battle axe to chop of
                the trolls head (which is worth a good amount of silver).
              </li>
              <li>
                Klaatu and Fat Head find some more rope, some silver, and an
                IMMORTAL TORCH.
              </li>
              <li>
                With the extra rope, everyone manages to climb out of the well
                to rejoin Jacque, Barbarister the Talking Horse, and Hoatee the
                donkey.
              </li>
            </ul>
            The adventure ends with GETTING BETTER, rest rolls, and calendar of
            Necrubel already rolled. Next session, we can hit the road right
            away.
            <br />
            <br />
            This episode of Malum Mortis brought to you in part by:
            <br />
            Module:{" "}
            <a
              href="https://xenokraft.itch.io/the-wailing-well"
              rel="noreferrer"
              target="_blank"
            >
              The Wailing Well.
            </a>
            <br />
            Game:{" "}
            <a
              href="https://morkborg.com/content/"
              rel="noreferrer"
              target="_blank"
            >
              Mork Borg
            </a>
            <br />
            Module (METAPLOT):{" "}
            <a
              href="https://www.drivethrurpg.com/product/423719/Malum-Mortis-A-Mork-Borg-Metaplot"
              rel="noreferrer"
              target="_blank"
            >
              Malum Mortis: A Mork Borg Metaplot.
            </a>
            <br />
          </p>
        </div>
      ),
    },
  ];

  return (
    <Container>
      <Row>
        <Col>
          <div
            style={{ backgroundColor: "transparent", fontFamily: "Orbitron" }}
          >
            <main role="main" className="inner cover text-center">
              <img
                src={mbLogo}
                style={{ maxWidth: "200px" }}
                alt="Malum Mortis Mork Borg campaign"
              />
            </main>
            <div style={pageHeader}>
              <h1 className="cover-heading">Session 08: Malum Mortis</h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title className="cardTitle-mork-borg">
                THE WAILING WELL
              </Card.Title>
              <Card.Text>
                <ContentContainer data={section1Data} />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MBMalumMortisSession08;
