import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../assets/myz/myz-section-divider.jpg";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import { useEffect } from "react";

import populationGraph from "../../../assets/myz/sunshine-plaza-population.jpg";
import populationComposition from "../../../assets/myz/sunshine-plaza-composition.jpg";

const cardTitle = {
  paddingTop: 10,
  paddingLeft: 10,
  paddingBottom: 10,
  backgroundColor: "rgba(221, 117, 28, .7) ",
};
const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "",
    text: (
      <div>
        <img
          src={populationGraph}
          alt="Sunshine Plaza Population"
          style={{ width: "75%", float: "left" }}
        />
        <img
          src={populationComposition}
          alt="Sunshine Plaza Composition"
          style={{ width: "25%", float: "left" }}
        />
        <br />
        <br />
        <h5>POPULATION COMPOSITION</h5>
        <small>
          <table class="sr_table">
            <thead>
              <th>Faction</th>
              <th>Number</th>
              <th>Notes</th>
            </thead>
            <tbody>
              <tr>
                <td>Mutants</td>
                <td>121</td>
                <td>
                  (6 PCs, 15 named NPCs, 76 nameless, and 24 mechies. Includes
                  Egg Guardians and ex-Helldrivers)
                </td>
              </tr>
              <tr>
                <td>Humans</td>
                <td>37</td>
                <td>
                  (1 boss, 3 named NPCs, and 33 nameless. Includes slave men
                  from Diamond Center, wanderers, etc.)
                </td>
              </tr>
              <tr>
                <td>TOTAL</td>
                <td>158</td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <br />
          <h5>POPULATION AUDIT (current: 158)</h5>
          <table className="sr_table">
            <thead>
              <th>Population</th>
              <th>Session</th>
              <th>Notes</th>
            </thead>
            <tbody>
              <tr>
                <td style={{ "text-align": "right" }}>214</td>
                <td>Session 01</td>
                <td>(03-01-0001 to 03-01-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  -4
                </td>
                <td colspan="2" style={{ "background-color": "#e1e1e1" }}>
                  -04 session body count, +1 ZANE, +1 BIANCA (dead includes
                  Ariana)
                </td>
              </tr>
              <tr>
                <td style={{ "text-align": "right" }}>210</td>
                <td>Session 02</td>
                <td>(03-02-0001 to 03-02-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  -02
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -02 session body count, -01 dead from project push, +01 ELSON
                </td>
              </tr>
              <tr>
                <td style={{ "text-align": "right" }}>208</td>
                <td>Session 03</td>
                <td>(03-02-0001 to 03-07-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  -08
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -06 session body count, -01 DOUBLE-BIRD died, -01 LOUD SUE
                </td>
              </tr>
              <tr>
                <td style={{ "text-align": "right" }}>200</td>
                <td>Session 04</td>
                <td>(03-04-0001 to 03-04-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  -07
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -06 session body count, -01 NELMA died at Pure Plant
                </td>
              </tr>
              <tr>
                <td style={{ "text-align": "right" }}>193</td>
                <td>Session 05</td>
                <td>(03-08-0001 to 03-09-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  -03
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -03 session body count
                </td>
              </tr>
              <tr>
                <td style={{ "text-align": "right" }}>190</td>
                <td>Session 06</td>
                <td>(03-10-0001 to 03-12-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  -08
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -09 session body count, +1 Food DEV Lvl
                </td>
              </tr>
              <tr>
                <td style={{ "text-align": "right" }}>182</td>
                <td>Session 07</td>
                <td>(03-13-0001 to 03-15-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  -05
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -06 session body count, +1 Food DEV Lvl
                </td>
              </tr>
              <tr>
                <td style={{ "text-align": "right" }}>177</td>
                <td>Session 08</td>
                <td>(03-16-0001 to 03-17-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  -08
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -09 session body count, +1 Food DEV Lvl
                </td>
              </tr>
              <tr>
                <td style={{ "text-align": "right" }}>169</td>
                <td>Session 09</td>
                <td>(03-18-0001 to 03-19-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  +07
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -11 session body count, +1 Food DEV Lvl, +17 WANDERERS
                  (includes Chromicus, Rel, and Janis)
                </td>
              </tr>
              <tr>
                <td style={{ "text-align": "right" }}>176</td>
                <td>Session 10</td>
                <td>(03-20-0001 to 03-21-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  +00
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -1 session body count, +1 Food DEV Lvl
                </td>
              </tr>
              <tr>
                <td style={{ "text-align": "right" }}>176</td>
                <td>Session 11</td>
                <td>(03-22-0001 to 03-22-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  +09
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -03 session body count, +01 Food DEV Lvl, -06 Ark Battle with
                  Helldrivers, +17 WANDERERS
                </td>
              </tr>
              <tr>
                <td style={{ "text-align": "right" }}>185</td>
                <td>Session 12</td>
                <td>(03-22-0001 to 03-22-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  +00
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -01 session body count, +01 Food DEV Lvl
                </td>
              </tr>
              <tr>
                <td style={{ "text-align": "right" }}>185</td>
                <td>Session 13</td>
                <td>(03-23-0001 to 03-27-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  -02
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -05 session body count, +01 Food DEV Lvl, +1 Wanderer
                  (Demyan), +1 Helldriver (Raff)
                </td>
              </tr>
              <tr>
                <td style={{ "text-align": "right" }}>183</td>
                <td>Session 14</td>
                <td>(03-27-0001 to 03-27-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  -01
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -2 session body count, +01 Food DEV Lvl
                </td>
              </tr>
              <tr>
                <td style={{ "text-align": "right" }}>182</td>
                <td>Session 15</td>
                <td>(03-27-0001 to 03-28-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  -04
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  5 session body count, +01 Food DEV Lvl
                </td>
              </tr>
              <tr>
                <td style={{ "text-align": "right" }}>178</td>
                <td>Session 16</td>
                <td>(03-28-0001 to 03-29-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  -01
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  2 session body count, +1 Food DEV lvl
                </td>
              </tr>

              <tr>
                <td style={{ "text-align": "right" }}>177</td>
                <td>Session 17</td>
                <td>(03-30-0001 to 04-01-0001)</td>
              </tr>

              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  +0
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  1 session body count, +1 Food DEV lvl
                </td>
              </tr>
              <tr>
                <td style={{ "text-align": "right" }}>177</td>
                <td>Session 18, 19, and 20</td>
                <td>(04-01-0001 to 05-10-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  -90
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  18 dead from Civil Wear; 72 lost due to runaway/flight
                </td>
              </tr>
              <tr>
                <td style={{ "text-align": "right" }}>87</td>
                <td>Session 21</td>
                <td>(05-11-0001 to 05-12-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  -1
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -4 session body count, +2 Food DEV lvl, +1 Jezebel
                </td>
              </tr>

              <tr>
                <td style={{ "text-align": "right" }}>86</td>
                <td>Session 22</td>
                <td>(05-13-0001 to 05-14-0001)</td>
              </tr>

              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  -5
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -6 session body count, +1 Food DEV lvl, dead include Rook and
                  Astrina
                </td>
              </tr>

              <tr>
                <td style={{ "text-align": "right" }}>81</td>
                <td>Session 23</td>
                <td>(05-15-0001 to 05-15-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  +0
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -4 session body count, +1 Food DEV lvl, +3 ex-Skag mutants
                  re-join
                </td>
              </tr>

              <tr>
                <td style={{ "text-align": "right" }}>81</td>
                <td>Session 24</td>
                <td>(05-15-0001 to ??-??-????)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  -3
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -5 session body count, +2 Food DEV lvl
                </td>
              </tr>

              <tr>
                <td style={{ "text-align": "right" }}>78</td>
                <td>Session 25</td>
                <td>(05-16-0001 to 05-17-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  -5
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -6 session body count, +2 Food DEV lvl, -1 Morena defection
                </td>
              </tr>

              <tr>
                <td style={{ "text-align": "right" }}>73</td>
                <td>Session 26</td>
                <td>(05-17-0001 to 05-17-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  -3
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -5 session body count. +2 Food DEV lvl. Casualties includes
                  Wanderer Dima
                </td>
              </tr>

              <tr>
                <td style={{ "text-align": "right" }}>70</td>
                <td>Session 27</td>
                <td>(05-17-0001 to 05-17-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  +0
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  No body count roll. GM forgot for two weeks. Kyle died in
                  battle.
                </td>
              </tr>

              <tr>
                <td style={{ "text-align": "right" }}>70</td>
                <td>Session 28</td>
                <td>(05-17-0001 to 05-17-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  -6
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -6 session body count. +2 Food DEV level -2 Luna and JEBE died
                  in battle.
                  <br />
                  Lost 3 nameless mutants and 1 ex-kurtzer mutant.
                </td>
              </tr>

              <tr>
                <td style={{ "text-align": "right" }}>64</td>
                <td>Session 29</td>
                <td>(05-17-0001 to ??-??-????)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  +25
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -2 session body count, +2 Food DEV level, allies from Diamond
                  Center: 22 Human Males, 1 old human female amensiac, 1 human
                  male named Fortran, 1 female child named Katie Kat (and her
                  pet baby fox Trix).
                </td>
              </tr>

              <tr>
                <td style={{ "text-align": "right" }}>89</td>
                <td>Session 30</td>
                <td>(05-19-0001 to 05-21-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  +1
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -2 session body count, +2 Food DEV level, +1 new PC
                </td>
              </tr>

              <tr>
                <td style={{ "text-align": "right" }}>90</td>
                <td>Session 31</td>
                <td>(05-21-0001 to 05-22-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  0
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -2 session body count, +2 Food DEV lvl
                </td>
              </tr>

              <tr>
                <td style={{ "text-align": "right" }}>90</td>
                <td>Session 32</td>
                <td>(05-22-0001 to 05-25-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  -4
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -6 session body count, +2 Food DEV lvl (two nameless mutants,
                  Rowbert, and Fortran)
                </td>
              </tr>

              <tr>
                <td style={{ "text-align": "right" }}>86</td>
                <td>Session 33</td>
                <td>(05-25-0001 to 05-25-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  -9
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -3 session body count, +2 Food DEV lvl, -5 nameless mutants,
                  -1 diamond center human, -1 ex-kurtzer, -1 Sylvia moves back
                  to The Homestead (other deaths are due to Sickness and Pure
                  Plant patrol attacked by monsters.
                </td>
              </tr>

              <tr>
                <td style={{ "text-align": "right" }}>77</td>
                <td>Session 34</td>
                <td>(05-26-0001 to 05-28-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  +5
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -3 session body count, +2 Food DEV lvl, +6 humans (Ark
                  Reputation attracted them).
                </td>
              </tr>

              <tr>
                <td style={{ "text-align": "right" }}>84</td>
                <td>Session 35</td>
                <td>(05-29-0001 to 05-30-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  +2
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -1 session body count. +2 Food DEV level. No death from
                  session body count. -1 mutant from Pure Plaant Patrol. +3
                  mutants from Ark Reputation.
                </td>
              </tr>

              <tr>
                <td style={{ "text-align": "right" }}>86</td>
                <td>Session 36</td>
                <td>(05-30-0001 to 06-05-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  -4
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -6 session body count. +2 Food DEV level. Lost Wanderer
                  Burton, another nameless wanderer, and two of the human Givers
                  from Diamond Center. [+4 Helldriver prisoners, not counted
                  toward population]
                </td>
              </tr>

              <tr>
                <td style={{ "text-align": "right" }}>82</td>
                <td>Session 37</td>
                <td>(06-05-0001 to 06-06-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  +0
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -5 session body count. +2 Food DEV level. +3 mutants recruited
                  at the Rust Castle. Lost Skut, old Mimir amnesiac lady,
                  wanderers Nuan and Derek, and one of the Giver human males
                  from the Dimond Center.
                </td>
              </tr>

              <tr>
                <td style={{ "text-align": "right" }}>82</td>
                <td>Session 38</td>
                <td>(06-06-0001 to 06-07-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  +2
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -1 session body count. +2 Food DEV level. +1 SURGERY. Total of
                  2 new folks: rolled 1 zone human and 1 mutant.
                </td>
              </tr>
              <tr>
                <td style={{ "text-align": "right" }}>84</td>
                <td>Session 39</td>
                <td>(06-07-0001 to ??-??-????)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  +3
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -5 session body count, +2 Food DEV level. +1 SURGERY. +5 (3
                  humans, 2 mutants) Gladhand evangelism at Rust Castle. (Two
                  deaths are mutants, even though +3 overall gain it affects
                  mutant/human distribution.
                </td>
              </tr>

              <tr>
                <td style={{ "text-align": "right" }}>87</td>
                <td>Session 40</td>
                <td>(06-08-0001 to 06-08-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  -2
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -5 session body count, +2 Food DEV level. +1 SURGERY. Two
                  deaths are diamond center male and one nameless mutant.
                </td>
              </tr>

              <tr>
                <td style={{ "text-align": "right" }}>85</td>
                <td>Session 41</td>
                <td>(06-08-0001 to 06-09-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  +4
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -3 session body count, +2 Food DEV level. +1 SURGERY. +4
                  ARRIVALS. Two mutants and one human died, and the 4 arrivals
                  are 1 mutant and 3 humans. Demographics reflected in chart
                  above.
                </td>
              </tr>

              <tr>
                <td style={{ "text-align": "right" }}>89</td>
                <td>Session 42</td>
                <td>(06-09-0001 to 06-12-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  -5
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -6 session body count, -2 Ark Reputation sickness, +2 Food DEV
                  level. +1 SURGERY. (2 mutants, 3 humans: two wanderers and one
                  Diamond Center giver)
                </td>
              </tr>

              <tr>
                <td style={{ "text-align": "right" }}>84</td>
                <td>Session 43-44</td>
                <td>(06-12-0001 to 06-17-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  +1
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -4 session 43 body count, -5 session 44 body count, +4 Food
                  DEV level, +2 SURGERY, +3 Scrap Pirates, +1 Ford the Scrap
                  Pirate. The three random deaths were two nameless mutants and
                  Danova the Envious.
                </td>
              </tr>
              <tr>
                <td style={{ "text-align": "right" }}>85</td>
                <td>Session 45</td>
                <td>(06-17-0001 to 06-18-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  -10
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -4 session body count, -12 ark battle, +3 (new mutants
                  cashel/skye/sarge), +1 Food DEV Level, +2 Surgery. (BOSS KRAO
                  dies, Wanderer Faith, 7 mutants, and 4 humans).
                </td>
              </tr>
              <tr>
                <td style={{ "text-align": "right" }}>75</td>
                <td>Session 46</td>
                <td>(06-18-0001 to 06-19-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  +3
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -3 session body count, +1 Food DEV level, +2 Surgery, +3
                  mutants (Cashel, Skye, Sarge)
                </td>
              </tr>

              <tr>
                <td style={{ "text-align": "right" }}>78</td>
                <td>Session 47</td>
                <td>(06-19-0001 to 06-22-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  +3
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -3 session body count, +1 Food DEV level, +2 Surgery, +3
                  mutants (Ark Reputation arrivals).
                </td>
              </tr>
              <tr>
                <td style={{ "text-align": "right" }}>81</td>
                <td>Session 48</td>
                <td>(06-22-0001 to 06-22-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  +3
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -4 session body count, +1 Food DEV level, +2 Surgery, +1 bonus
                  human arrival, +1 bonus mutant arrival, +2 mutants (Ark
                  Reputation arrivals), -2 humans (pure plant patrol, 1 given
                  and 1 wanderer), +2 (correction for math).
                </td>
              </tr>
              <tr>
                <td style={{ "text-align": "right" }}>84</td>
                <td>Session 49</td>
                <td>(06-22-0001 to 06-23-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  -1
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -4 session body count, +1 Food DEV Level, +2 surgery (net loss
                  of one was ex-kurtzer from random roll), +1 mutant bonus
                  arrival, -1 death due to SICKNESS event (ex-kurtzer)
                </td>
              </tr>
              <tr>
                <td style={{ "text-align": "right" }}>83</td>
                <td>Session 50</td>
                <td>(06-23-0001 to 06-23-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  +6
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -4 session body count, +1 Food DEV level,+2 surgery (net loss
                  of one nameless from random roll), +5 mutant bonus arrival, +2
                  mutants from Ark Reputation
                </td>
              </tr>
              <tr>
                <td style={{ "text-align": "right" }}>89</td>
                <td>Session 51</td>
                <td>(06-23-0001 to ??-??-????)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  +8
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -5 session body count, +1 Food DEV Level, +2 surgery (net loss
                  of one nameless mutant and one ex-kurtzer), +3 humans and + 7
                  mutants from Ark Reputation and Wanderers Arrive.
                </td>
              </tr>
              <tr>
                <td style={{ "text-align": "right" }}>97</td>
                <td>Session 52</td>
                <td>(06-23-0001 to 06-24-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  +4
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -4 session body count, +1 Food DEV Level, +2 surgery, +1
                  mutant from Ark Reputation, +4 mutants from Wanderers Arrive.
                  (lose one nameless mutant from session body count){" "}
                </td>
              </tr>
              <tr>
                <td style={{ "text-align": "right" }}>101</td>
                <td>Session 53</td>
                <td>(06-24-0001 to 06-25-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  +5
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -6 session body count, +3 Food DEV Level, +1 surgery, +1 human
                  from Ark Reputation, +3 mutants and +2 humans from Wanderers
                  Arrive. Vanja the clone is added as Ark NPC now that she is
                  official. (lose one nameless mutant and one nameless human
                  from session body count)
                </td>
              </tr>
              <tr>
                <td style={{ "text-align": "right" }}>106</td>
                <td>Session 54</td>
                <td>(06-25-0001 to 06-30-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  +16
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -3 session body count, +3 Food DEV level, +1 surgery, 6
                  mutants and 4 humans from wanderers and reputation. Cad, Tir,
                  Nikkil, Elwith, Fistula and Kort added.
                </td>
              </tr>
              <tr>
                <td style={{ "text-align": "right" }}>122</td>
                <td>Session 55-60</td>
                <td>(06-30-0001 to 07-15-0001)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  +34
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  -18 zone war casualties, +54 zone war additions(see notes for
                  session 58-60 for details)
                </td>
              </tr>
              <tr>
                <td style={{ "text-align": "right" }}>158</td>
                <td>Session 61</td>
                <td>(07-15-0001 to ??-??-????)</td>
              </tr>
              <tr style={{ color: "#000" }}>
                <td
                  style={{
                    "text-align": "right",
                    "background-color": "#e1e1e1",
                  }}
                >
                  TBD
                </td>
                <td colSpan="2" style={{ "background-color": "#e1e1e1" }}>
                  TBD
                </td>
              </tr>
            </tbody>
          </table>
        </small>
        <br />
        <br />
      </div>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageRight",
    },
  },
  {
    title: "Notable Events",
    text: (
      <div>
        <p>
          <strong className="wtf">Nova Cult Destroyed</strong>. See &nbsp;
          <a
            style={{ color: "white", textDecoration: "underline" }}
            href="/mutant-year-zero-session-61-62-anchortown"
            rel="noreferrer"
          >
            Session 61-62
          </a>{" "}
          for details.
        </p>
        <p>
          <strong className="wtf">Zone War Won with Casualties</strong>. See
          &nbsp;
          <a
            style={{ color: "white", textDecoration: "underline" }}
            href="/mutant-year-zero-session-58-60-anchortown"
            rel="noreferrer"
          >
            Session 58-60
          </a>{" "}
          for details.
        </p>
        <p>
          Clone Beto and robot Dorothy die in Rec Center Battle [
          <a
            style={{ color: "white", textDecoration: "underline" }}
            href="/mutant-year-zero-session-51-anchortown"
            rel="noreferrer"
          >
            Session 51
          </a>
          ].
        </p>
        <p>
          <strong className="wtf">Death of Boss Krao</strong>. Deaths of the
          Elder, Hanneth, and Danova broke Krao's heart. And the recent ark
          battle with the Helldrivers yet again were too much for her. Read
          about her passing in the graveyard.
          <a
            style={{ color: "white", textDecoration: "underline" }}
            href="/mutant-year-zero-session-45-anchortown"
            rel="noreferrer"
          >
            Session 41
          </a>
          ].
        </p>
        <p>
          <strong className="wtf">Death of Doublebird</strong>. Rot caught up
          with Doublebird. He went out with a speech and grenade in hand! [
          <a
            style={{ color: "white", textDecoration: "underline" }}
            href="/mutant-year-zero-session-41-anchortown"
            rel="noreferrer"
          >
            Session 41
          </a>
          ].
        </p>
        <p>
          Captured Boss Berke in Battle with saurians against Scrap Pirates [
          <a
            style={{ color: "white", textDecoration: "underline" }}
            href="/mutant-year-zero-session-40-anchortown"
            rel="noreferrer"
          >
            Session 40
          </a>
          ].
        </p>
        <p>
          Sailed on Dead Blue Sun for the 1st time [
          <a
            style={{ color: "white", textDecoration: "underline" }}
            href="/mutant-year-zero-session-37-anchortown"
            rel="noreferrer"
          >
            Session 37
          </a>
          ].
        </p>
        <p>
          Arrived at Rust Castle [
          <a
            style={{ color: "white", textDecoration: "underline" }}
            href="/mutant-year-zero-session-36-anchortown"
            rel="noreferrer"
          >
            Session 36
          </a>
          ].
        </p>
        <p>
          Allied with the family at The Homestead [
          <a
            style={{ color: "white", textDecoration: "underline" }}
            href="/mutant-year-zero-session-33-anchortown"
            rel="noreferrer"
          >
            Session 33
          </a>
          ].
        </p>
        <p>
          Disabled Digger that attracted World Worm which caused Zone Quakes [
          <a
            style={{ color: "white", textDecoration: "underline" }}
            href="/mutant-year-zero-session-31-anchortown"
            rel="noreferrer"
          >
            Session 31
          </a>
          ].
        </p>
        <p>
          Escape from Diamond Center. An epic, multi-session battle and the
          death of Jebe, but the IL Gang escapes with new human allies and two
          battle buses as well as a few artifacts [
          <a
            style={{ color: "white", textDecoration: "underline" }}
            href="/mutant-year-zero-session-29-anchortown"
            rel="noreferrer"
          >
            Session 23
          </a>
          ].
        </p>
        <p>
          <strong className="wtf">Death of Jebe</strong>. Jebe fell at the
          Diamond center after tossing scrap bomb in major battle [
          <a
            style={{ color: "white", textDecoration: "underline" }}
            href="/mutant-year-zero-session-28-anchortown"
            rel="noreferrer"
          >
            Session 28
          </a>
          ].
        </p>
        <p>
          Another near death for Jebe thanks to a pack of zone hounds. In the
          battle that almost took Jebe, his hound Tiska 2 was killed as well as
          the bitterbeast Valegro [
          <a
            style={{ color: "white", textDecoration: "underline" }}
            href="/mutant-year-zero-session-23-anchortown"
            rel="noreferrer"
          >
            Session 23
          </a>
          ].
        </p>
        <p>
          The near death of Jebe and finding crazy Jezebel at her tea party [
          <a
            style={{ color: "white", textDecoration: "underline" }}
            href="/mutant-year-zero-session-21-anchortown"
            rel="noreferrer"
          >
            Session 21
          </a>
          ].
        </p>
        <p>
          Civil War in the Sunshine Plaza leaves the popultion down 90 due to 18
          deaths and fleeing enemies [
          <a
            style={{ color: "white", textDecoration: "underline" }}
            href="/mutant-year-zero-session-18-19-20-anchortown"
            rel="noreferrer"
          >
            Session 18, 19, and 20
          </a>
          ].
        </p>
        <p>
          Scrap Oracle Cassandra found ruling Noatun Estates settlement in
          Sector A04 [
          <a
            style={{ color: "white", textDecoration: "underline" }}
            href="/mutant-year-zero-session-17-anchortown"
            rel="noreferrer"
          >
            Session 17
          </a>
          ].
        </p>
        <p>
          Triska the dog died fighting Zone Dogs at tank in Sector A03 [
          <a
            style={{ color: "white", textDecoration: "underline" }}
            href="/mutant-year-zero-session-14-anchortown"
            rel="noreferrer"
          >
            Session 16
          </a>
          ]. She will be remembered although she lived and died within the same
          session.
        </p>
        <p>
          Scooby the hound died fighting Zone Ghouls at Burger Jim's and
          REVOLUTION breaks out at Ark [
          <a
            style={{ color: "white", textDecoration: "underline" }}
            href="/mutant-year-zero-session-14-anchortown"
            rel="noreferrer"
          >
            Session 14
          </a>
          ].
        </p>
        <p>
          Hanneth killed in Ark battle as Chromicus, Rowbert, Elson, and Raff
          Escape HELLDRIVERS parking garage [
          <a
            style={{ color: "white", textDecoration: "underline" }}
            href="/mutant-year-zero-session-12-anchortown"
            rel="noreferrer"
          >
            Session 12
          </a>
          ]
        </p>
        <p>
          Subu died fighting Zone Ghouls [
          <a
            style={{ color: "white", textDecoration: "underline" }}
            href="/mutant-year-zero-session-07-anchortown"
            rel="noreferrer"
          >
            Session 07
          </a>
          ]. Subu lived for seven sessions; true champion among canines.
        </p>
        <p>
          Pure Plant liberated from worm swarm [
          <a
            style={{ color: "white", textDecoration: "underline" }}
            href="/mutant-year-zero-session-05-anchortown"
            rel="noreferrer"
          >
            Session 05
          </a>
          ]
        </p>
        <p>
          Boss Loud Sue assassinated.{" "}
          <strong className="wtf">
            Double Bird dies from parasitic fungus.
          </strong>{" "}
          [
          <a
            style={{ color: "white", textDecoration: "underline" }}
            href="/mutant-year-zero-session-03-anchortown"
            rel="noreferrer"
          >
            Session 03
          </a>
          ]
        </p>
        <p>
          Rocket the Zone Hound rescued from Valley of the Moon park. Elson
          joins Ark. Double Bird and Zane sick. Two artifacts turned in
          (protective suit and canoe). [
          <a
            style={{ color: "white", textDecoration: "underline" }}
            href="/mutant-year-zero-session-02-anchortown"
            rel="noreferrer"
          >
            Session 02
          </a>
          ]
        </p>
        <p>
          Zane, Ariana, and Bianca brought back to Ark [
          <a
            style={{ color: "white", textDecoration: "underline" }}
            href="/mutant-year-zero-session-01-anchortown"
            rel="noreferrer"
          >
            Session 01
          </a>
          ]
        </p>
      </div>
    ),
  },
];

const MYZAnchortownLegendGraveyard = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Anchortown: Legends & Graveyard
                </h1>
                <p className="lead leadExtra">
                  Anchortown: Chronicles of the Sunshine Plaza is a Mutant Year
                  Zero campaign set in the area known as Anchorage, Alaska long
                  before the apocalypse.
                  <br />
                  This page celebrates heroic actions, and . . .
                  <br />
                  it is the digital graveyard for those lost along the way..
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Title style={cardTitle}>
                  Population and Notable Events
                </Card.Title>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownLegendGraveyard;
