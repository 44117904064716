import React, { Fragment } from "react";
import "../../../index.css";
import "./blade-runner-styles.scss";

const BRSubNav = () => {
  return (
    <Fragment>
      <div class="bladeRunnerTitleText" style={{ textAlign: "center" }}>
        🤖 <a href="./blade-runner-overview">Overview</a> 🤖
        <a href="./blade-runner-classes">Classes</a> 🤖
        <a href="./blade-runner-rules">Rules</a> 🤖
      </div>
    </Fragment>
  );
};

export default BRSubNav;
