import React from 'react';
import {encounters as encounters_ravenland, terrainList as terrain_ravenland} from '../../../src/json/encounter_data_gamemasters_guide';
import {encounters as encounters_bitterreach, terrainList as terrain_bitterreach} from '../../../src/json/encounter_data_bitter_reach';
import background1 from "../../../src/assets/random_encounters/autumn_stream_by_ninjatic.jpg";
import background2 from "../../../src/assets/random_encounters/a_morning_pilgrimage_by_ninjatic.jpg"
import background3 from "../../../src/assets/random_encounters/a_visit_from_an_old_friend_by_ninjatic.jpg"
import background4 from "../../../src/assets/random_encounters/cavern_by_ninjatic.jpg"
import background5 from "../../../src/assets/random_encounters/deep_swamp_by_ninjatic.jpg"
import background6 from "../../../src/assets/random_encounters/hidden_falls_by_ninjatic.jpg"
import background7 from "../../../src/assets/random_encounters/holy_glade_by_ninjatic.jpg"
import background8 from "../../../src/assets/random_encounters/misty_mountains_by_ninjatic.jpg"
import background9 from "../../../src/assets/random_encounters/ninjatic-01.jpg"
import background10 from "../../../src/assets/random_encounters/ninjatic-02.jpg"
import background11 from "../../../src/assets/random_encounters/ninjatic-03.jpg"
import background12 from "../../../src/assets/random_encounters/ninjatic-04.jpg"
import background13 from "../../../src/assets/random_encounters/ninjatic-05.jpg"
import background14 from "../../../src/assets/random_encounters/ninjatic-clear-sky.jpg"
import background15 from "../../../src/assets/random_encounters/ninjatic-dark-road-02.jpg"
import background16 from "../../../src/assets/random_encounters/ninjatic-dark-road.jpg"
import background17 from "../../../src/assets/random_encounters/ninjatic-ice-and-stone.jpg"
import background18 from "../../../src/assets/random_encounters/ninjatic-snowy-path.jpg"
import background19 from "../../../src/assets/random_encounters/ninjatic-snowy-tree.jpg"
import background20 from "../../../src/assets/random_encounters/ninjatic-thru-the-trees.jpg"
import background21 from "../../../src/assets/random_encounters/old_ruins_by_ninjatic_d67rzxi-fullview.jpg"
import background22 from "../../../src/assets/random_encounters/ruined_ship_by_ninjatic.jpg"
import background23 from "../../../src/assets/random_encounters/this_is_not_good__by_ninjatic.jpg"
import background24 from "../../../src/assets/random_encounters/to_high_region_by_ninjatic_d6fqf9y-fullview.jpg"
import background25 from "../../../src/assets/random_encounters/unexpected_quest_by_ninjatic.jpg"
import background26 from "../../../src/assets/random_encounters/until_then_by_ninjatic.jpg"
import background27 from "../../../src/assets/random_encounters/white_drifters_by_ninjatic.jpg"
import background28 from "../../../src/assets/random_encounters/long_journey_ahead_by_ninjatic.jpg"
import background29 from "../../../src/assets/random_encounters/frozen_mountains_by_ninjatic.jpg"
import background30 from "../../../src/assets/random_encounters/waterfalls_by_ninjatic.jpg"
import background31 from "../../../src/assets/random_encounters/lost_by_ninjatic.jpg"
import background32 from "../../../src/assets/random_encounters/swamp_fairies_by_ninjatic.jpg"
import background33 from "../../../src/assets/random_encounters/prophecy_by_ninjatic.jpg"
import background34 from "../../../src/assets/random_encounters/the_road_by_ninjatic.jpg"
import background35 from "../../../src/assets/random_encounters/the_shore_by_ninjatic.jpg"
import background36 from "../../../src/assets/random_encounters/shore_city_by_ninjatic.jpg"
import background37 from "../../../src/assets/random_encounters/thousand_suns_by_ninjatic.jpg"
import background38 from "../../../src/assets/random_encounters/the_ruins_by_ninjatic.jpg"
import background39 from "../../../src/assets/random_encounters/holy_ruins_by_ninjatic.jpg"
// import {hexData} from "../../../src/js/hexData"

let backgroundImages = [{background1},{background2},{background3},{background4},{background5},{background6},{background7},{background8},{background9},{background10},{background11},{background12},{background13},{background14},{background15},{background16},{background17},{background18},{background19},{background20},{background21},{background22},{background23},{background24},{background25},{background26},{background27},{background28},{background29},{background30},{background31},{background32},{background33},{background34},{background35},{background36},{background37},{background38},{background39}]

function getRandomIndexFromList(inputList) {
  return Math.floor(Math.random() * inputList.length);
}

function getTerrainNew(name, terrain) {
  let z = terrain.filter(x => x.name === name)
  return (z[0]) ? z[0].freq : 0;
}

class RandomEncounters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      encountersData: null,
      selectedTerrain: '',
      terrain: '',
      encounterName: null,
      encounter: null,
      backgroundImage: null,
      ignoreNoEncounterResults: false,
      selectedCampaign: 'ravenland'
    };
  }

  randomEncounterMonster = (encounterListNew) => {
    let r = Math.floor(Math.random() * encounterListNew.length);
    return (encounterListNew[r])?encounterListNew[r].name:null;
}
  buildTerrainList = () => {
    //NOTE: in future we may combine all terrain lists into master of unique terrains
    if(this.state.selectedCampaign === 'bitter reach') {
      return terrain_bitterreach;
    }
    return terrain_ravenland;
  }
  createSelectItems = () => {
    let items = [];
    let terrainList = this.buildTerrainList();
    items.push(<option key={-1} value={-1}></option>);
    terrainList.forEach(function (item, index) {
      items.push(<option key={index} value={item}>{item.toUpperCase()}</option>);
    });
    return items;
  }

  componentDidMount() {
    this.setState({backgroundImage: background1})
    this.setState({encountersData: this.getCampaignData()})
  }
  handleChangeNoEncounter = (e) => {
    this.setState({ignoreNoEncounterResults: e.target.checked});
  }
  getCampaignData = (name) => {
    switch(name) {
      case 'bitter reach':
        return encounters_bitterreach
      default:
        return encounters_ravenland
    }
  }
  handleChangeCampaign = (e) => {
    let selectedCampaign = e.target.value;
    this.setState({selectedCampaign: e.target.value}, this.createSelectItems);
    this.setState({encountersData: this.getCampaignData(selectedCampaign)})
  }
  /*
Create list of objects that have encounter names. There are as many rows with same encounter name
as their designated frequency.
NOTE: convert to binary tree search - https://stackoverflow.com/questions/16927299/coding-random-encounter
[
  {name: "bloodmist"}
  {name: "bloodmist"}
  {name: "death from above"}
  {name: "death from above"}
  {name: "the harpies' feast"}
  {name: "the harpies' feast"}
]
 */
  buildEncounterList = (terrain, ignoreNoEncounters) => {
  if (!terrain) {
    return
  }
  let encounters = this.state.encountersData
  let encounterList = []
  for (let name in encounters) {
    if (ignoreNoEncounters && name === 'no encounter') { continue; }
    let frequency = getTerrainNew(terrain, encounters[name].terrain)
    for (let i = 0; i < frequency; i++) {
      encounterList.push({'name': name})
    }
  }

  return encounterList;
}
  handleChange = (e) => {
    let newImageObj = backgroundImages[getRandomIndexFromList(backgroundImages)];
    let newImage = Object.values(newImageObj)[0];
    //this.setState({backgroundImage: backgroundImage})
    let terrain = e.target.value;

    this.setState({selectedTerrain: terrain});
    if (terrain === "-1") {
      this.setState({encounter: null});
      this.setState({encounterName: null});
      this.setState({terrain: ''});
      return
    }
    let encounterListNew = this.buildEncounterList(terrain, this.state.ignoreNoEncounterResults);
    this.setState({encounterList: encounterListNew})
    let encounterName = this.randomEncounterMonster(encounterListNew)
    let target = this.state.encountersData[encounterName];
    let flavor = '';
    if(target) {
      target['selectedTerrain'] = terrain;
      target['name'] = encounterName;
      flavor = target.flavor;
    }
    // TODO: uhh, consolidate LOL
    this.setState({encounter: target});
    this.setState({encounterName: encounterName});
    this.setState({flavor: flavor});
    this.setState({selectedTerrain: ''});
    this.setState({terrain: terrain});
    this.setState({backgroundImage: newImage})
    console.log('WTF')
    console.log(this.state.backgroundImage)
  }

  render() {
    let showEncounter = this.state.encounter;
    let campaignLink = (this.state.selectedCampaign === 'bitter reach')
      ? 'https://frialigan.se/en/store/?product_id=5362320277653':
      'https://frialigan.se/en/store/?product_id=2164836859952';
let backgroundStyle = {
  backgroundImage: `url(${this.state.backgroundImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed',
  backgroundSize: 'cover',
  width:'100vw',
  height:'2000px'
}
    const renderEncounter = () => {
      if (showEncounter) {
        return <div style={{fontSize: 18,backgroundColor: '#000000',color:'#ffffff',padding: 20,marginTop:20, maxWidth:650,margin:'0 auto',textAlign:'left'}}>
          <div>Party encounters <span style={{textTransform: 'capitalize',fontWeight:600}}>
          {this.state.encounter.name}
          </span> in the <span style={{fontWeight:600}}>{this.state.encounter.selectedTerrain.toUpperCase()}</span>.
            <div style={{marginTop: 20}}>[flavor text]: <i>{this.state.encounter.flavor}</i>
            </div>
        </div>

            <div style={{marginTop:20}}><small>&nbsp;&nbsp;-- Encounter details:
              <a target='_blank' rel="noopener noreferrer" href={campaignLink}>
              Forbidden Lands Gamemaster's Guide</a></small>
            </div>
                    <div style={{fontSize:14,fontWeight:400,padding:10,textAlign:'left'}}>
          It's not fancy (yet), but this generator does pick
          random encounters based on terrain with the exact weight shown in the Forbidden Lands
          Gamemasters guide.<br/>This means The Stowaway will only appear if LAKE terrain is selected,
          the Ruins of Old appears in RUINS terrain 3 out of 18 possibilities (i.e. approx. 16.7% chance)
          as shown in the Random Encounters table.<br/><br/>The Bitter Reach and other material will be
        added soon, including SmokeRaven encounters flavored to fit Forbidden Lands like a spiked glove!
          </div>
        </div>
      }
    }
    return (

      <div style={backgroundStyle}>
        <div style={{backgroundColor: '#ffffff',padding: 20,opacity:0.7,width:'50vw',margin:'0 auto'}}>
          <center><h2>Forbidden Lands Random Encounters</h2>
            <small>art by <a target='_blank' rel="noopener noreferrer" href='https://www.deviantart.com/ninjatic/gallery'>Ninjatic</a></small>
            <br/>
            <input onChange={this.handleChangeNoEncounter} type='checkbox' value='force encounter'
                   checked={this.state.ignoreNoEncounterResults}/> ignore "no encounter" results
            <br/>
            <input checked={this.state.selectedCampaign==='ravenland'}
                   onChange={this.handleChangeCampaign} type='radio' value='ravenland'/> Ravenland -&nbsp;
            <input checked={this.state.selectedCampaign==='bitter reach'}
                   onChange={this.handleChangeCampaign} type='radio' value='bitter reach'/> Bitter Reach
          </center>

        <div style={{margin: 20}}><b>Go ahead, pick one; we dare you ---></b>
          <br/><select
          value={this.state.selectedTerrain}
          onChange={this.handleChange}>
          {this.createSelectItems()}
        </select>

        </div>

        {renderEncounter()}
        </div>
      </div>
    );
  }
}

export default RandomEncounters;