import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import krinn from "../../../../assets/myz/inhabitant-krin.jpg";
import elevator from "../../../../assets/myz/location-hand-cranked-elevator.jpg";
import rebeth from "../../../../assets/myz/inhabitant-rebeth.jpg";
import naphta from "../../../../assets/myz/inhabitant-naphta.jpg";
import chairman from "../../../../assets/myz/the-chairman.jpg";
import office from "../../../../assets/myz/location-the-chairmans-suite-portrait.jpg";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "The Diamond Center",
    text: (
      <p>
        05-15-0001: We took care of non-party business first which included:
        <ul>
          <li>
            BITTERBEASTS: one new egg hatched. one beast moved into training,
            and one or two training successes.
          </li>
          <li>PROJECT ROLLS: +6 croplands and croplands finished!!</li>
          <li>
            PURE PLANT PATROL: the second Pure Plant patrol is successful and
            grub, booze, and water supplied to general Ark supplise.
          </li>
        </ul>
        In the aftermath of the battle with the zone dogs, our team meets Krinn,
        Sanna Tee, Scrunchie, and Dink; mutants from The Other Ark (except Dink
        who is a human). They seem friendly enough but their leader Krinn
        assumes the ex-Skag gang members Morena is in charge. Dink lets Gladhand
        butcher the fallen bitterbeast Valegro and works on zone dog corpses as
        Hulagu and Smoke Raven pow-wow with Krinn. She betrays a certain
        bright-eyed, but silent interest in the mention of Eden. She seems to
        want to say more but will not do so in front of her underlings.
        <br />
        <br />
        The AMNESIAC with Mimir shirt is too confused to communicate anything of
        value but she follows along as the party approaches The Other Ark (which
        we seen learn is called The Diamond Center).
      </p>
    ),
    image: {
      imagePath: krinn,
      altText: `Krinn is a stalker from The Diamond Center. After sleeping with Smoke Raven, she drops hints then leaves in fear.`,
      className: "imageLeft",
      imageCaption: `Krinn of the Diamond Center`,
    },
  },
  {
    title: "Lay of the Land",
    text: (
      <p>
        The Diamond Center is a skyscraper, visible from half a sector away or
        more. The cleared ground approaching the walled fortress (formerly a
        high-rise hotel), is covered with the bodies of dead zone ghouls. There
        are lights in the skyscraper and a spotlight on the wall near the gate.
        After a shot rings out above their heads, Krinn shouts "The Chairman
        loves and feeds his children" as a pass phrase.
        <br />
        <br />
        The party lets the bitterbeasts stable in the front yard of the Diamond
        Center with Jebe's motorcycle parked nearby. They are taken inside where
        they are surprised to see a fairly large number of children. These
        children gawk at the IL Gang as they cling to their mutant parents. No
        one is unfriendly, but no one is gregarious or talkative either as Krinn
        leads the IL Gang to the showers.
        <br />
        <br />
        After scrubbing off Zone Rot, Gladhand trades his artifact protective
        suit to Double Bird who is living on the edge with EIGHT permanent rot
        points. The gang is fed and watered. Gladhand meets a human chronicler
        named Fortran whilst feeding the bitterbeasts in the yard of the Ark.
        Fortran is quiet, thoughtful, and attentive. He mentions he might have a
        friend that would want a ride on a bitterbeast and can possibly help
        with the needs of the IL Gang to get some things repaired. Then, they
        are taken to a middle story floor by way of a hand-cranking elevator
        shaft manned by male humans.
        <br />
        <br />
        There is a pattern of females being abusive or cold toward males who
        seem to occupy a lower social position in this Ark. Also, as Jebe looks
        around for a hound, he notices that there are no hounds around at all
        which seems very unusual.
      </p>
    ),
    image: {
      imagePath: elevator,
      altText: `The upper floors of the Diamond Center can be reached thanks to a manually, hand-cranked elevator`,
      className: "imageRight",
      imageCaption: "",
    },
  },
  {
    title: "Night Moves",
    text: (
      <p>
        Once the party is assembled on their floor (two separate trips on the
        elevator which cannot be cranked with more than five people in it),
        Krinn takes the party to meet Rebeth. Rebeth is an imposing,
        green-skinned female mutant with four arms. She lounges on a scrap
        beanbag chair under black light lamps that loom darkly over the
        flickering lantern light in her room. She lets the party know they will
        meet The Chairman around midday the next day. She also assumes Morena is
        in charge and seems disgusted when she learns a man is in charge. "What
        are you doing back there honey? Don't ever stand behind men; you stand
        in front of or on top of men", she advises Morena.
        <br />
        <br />
        Instead of bedding down, the IL Gang has some serious plans to check
        shit out. It went down like this:
        <ul>
          <li>
            <b>Boss Hulagu</b>: Hulagu climbs down the elevator shaft using his
            INSECTOID powers. Then, he tries a MOVE roll to reach a spot unseen
            where he might climb the outside of the Diamond Center. After
            pushing the roll he has no success and two fails. The guards notice
            him and casually track him the entire time. Mission is a bust.
          </li>
          <li>
            <b>Gladhand</b> spends a mutation point to use SPRINTER. His plan is
            to run up and down the halls of the floor they are on claiming he is
            getting his "exercise" whenever one of the female guards asks him
            what the hell he is doing.
          </li>
          <li>
            <b>Double Bird</b> decides he likes Rebeth and wants to win her
            over. He writes a little poem and wanders down toward her room to
            deliver the poem which is, for the record, "Green like my dream /
            with powerful loins / I lay awake at night / with my hand on my
            groin" . . . he knocks on her door, as he enters he activates
            TELEPATHY to plant notion in Rebeth's mind that he is her lover as
            he goes to his knees to deliver the poem. No successes and after a
            push it's still a fail. Rebeth grabs his face with her large
            lime-colored hand and says something like, "you disgusting twerp.
            Lucky you're a visitor and The Chairman wants to see you. Now get
            the hell out." Mission bust.
          </li>
          <li>
            <b>Smoke Raven</b> sneaks around the floor trying to find
            information. He doesn't encounter much of note until he comes back
            by his own room and finds Krinn lightly knocking on this bedroom
            door. Then, she opens the door and goes in. Still in shadows Smoke
            Raven follows. Krinn goes to leave and bumps into him and is
            surprised. It quickly becomes clear she has come to his room to hook
            up. Smoke Raven considers relationship with Grit back at the
            Sunshine Plaza, but Krinn mentions "she isn't here" as she undoes
            some buttons and basically wins that debate. The pillow talk after
            shows that Krinn is VERY interested in Eden and possibly unhappy at
            the Diamond Center. She says "you need to find a way to see the
            basement" . . .she hurries away, shaking her head as Smoke Raven
            says "join us" . . . he rushes to the door to pursue her and then
            notices the ever-present man-servant Dink has been out in the
            hallway this entire time. Dink is following his mistress toward the
            elevator but he looks back, winks, and makes gun-shooting fingers at
            Smoke Raven with a smile.
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: rebeth,
      altText: `Rebeth is cheif enforcer at the Diamond Center and an imposing, man-hating bad-ass bitch`,
      className: "imageLeft",
      imageCaption: "Rebeth the Enforcer",
    },
  },
  {
    title: "In the Light of Day",
    text: (
      <p>
        The next day, the IL Gang is gathered in the mess hall which was once
        the off-lobby restaurant when the Diamond Center was a hotel in The Old
        Days. Fortran comes over to sit and hang out. As the party eats and
        drinks, Scrunchie comes over and does her version of flirty
        intimidation. As she leaves, she slaps Fortran's book to the ground.
        When Fortran goes to pick up the glasses, Scrunchie is pissed and goes
        to knock off his glasses. She is blocked by Chromicus, but Chromicus'
        INTIMIDATION has a fail on the push which means Scrunchie steps back in
        alarm and still happens to break Fortran's glasses.
        <br />
        <br />
        There is a tense stand-off as Smoke Raven and Chromicus posture in
        defense of poor Fortran and Scrunchie calls guards. However, Krinn
        intervenes and defuses the situation. Fortran says it's time to meet his
        friend.
        <br />
        <br />
        Fortran takes a few party members to the back of the north wing on the
        ground floor. An old loading bay has been transformed into a gearhead
        shop. Naphta is the resident gearhead. She is concerned and solicitous
        of Fortran; perhaps the first female showing concern for a male since
        they arrived. Naphta fixes Fortran's glasses and does some repairs for
        the IL Gang after Gladhand makes an excellent MAKE A DEAL roll. Then,
        Rebeth comes to have them meet The Chairman. Once the party is assembled
        on their floor (two separate trips on the elevator which cannot be
        cranked with more than five people in it), Krinn takes the party to meet
        Rebeth. Rebeth is an imposing, green-skinned female mutant with four
        arms. She lounges on a scrap beanbag chair under black light lamps that
        loom darkly over the flickering lantern light in her room. She lets the
        party know they will meet The Chairman around midday the next day. She
        also assumes Morena is in charge and seems disgusted when she learns a
        man is in charge. "What are you doing back there honey? Don't ever stand
        behind men; you stand in front of or on top of men", she advises Morena.
      </p>
    ),
    image: {
      imagePath: naphta,
      altText: `Naphta is head gearhead for the Diamond Center`,
      className: "imageRight",
      imageCaption: "Naphta the Gearhead",
    },
  },
  {
    title: "The Chairman",
    text: (
      <p>
        The IL Gang is brought to the top of the Diamond Center. There are four
        extra guards here, posted outside a corner office where The Chairman
        rules the roost. The Chairman is a thin, pale-skinned human in a black
        robe with a bald head. His office has a circular window looking out onto
        the sectors of the zone on all sides. There is a desk and a chair in
        pretty good condition. Books are scattered on the desk, there are books
        and papers in boxes all over the office, and there is a large corkboard
        festooned with lots of paper, drawings, and what appears to be a map of
        the zone.
        <br />
        <br />
        He gestures toward two empty chairs and Hulagu and Smoke Raven take a
        seat. "Welcome to the Diamond Center" intones the Chairman. For the next
        half hour there is a dialog that bounces between The Chairman trying
        fairly hard, but not aggressively, to find out more about the Ark that
        the IL Gang comes from (what is it called, where is it located, and so
        forth). The IL Gang dodges most questions but they do mention a
        partnership with another faction in the zone and their interest in Eden.
        <br />
        <br />
        The Chairman says he believes Eden is real and it is a place. He
        explains that most of the children in the Diamond Center are his because
        human males can produce children with mutant females (which is the
        opposite of what the party already know -- so now we know as long as one
        party in a couple is a human, a child can be produced). He also explains
        that the seeming subservience of the males in the Center is part of
        their spiritual belief and worship of the females since they are bring
        life, light and hope.
        <br />
        <br />
        The IL Gang points out it is they who have travelled to them and not the
        other way around. The Chairman explains that the Diamond Center being so
        tall, fortified, and alight draws Zone Ghouls for sectors around and
        they are so beset that they can hardly form a decent zone expedition due
        to need to protect the Ark. Krinn and company were a scouting force and
        the IL Gang was lucky to have met them.
        <br />
        <br />
        Gladhand has been looking carefully at the map and all the materials
        around the room. There is some distrust voiced. The Chairman backs up a
        little and there seems to be a mutual respect and the possibility of an
        alliance. The Chairman suggestes everyone think about it and they will
        meet again the next day.
      </p>
    ),
    image: {
      imagePath: chairman,
      altText: `The Chairman is a human that rules the Diamond Center. He has fathered many children.`,
      className: "imageLeft",
      imageCaption: "The Chairman",
    },
  },
  {
    title: "Wrapup",
    text: (
      <p>
        Next session will begin afternoon of 05-16-0001. We awarded four XP at
        end of session, so there is no prep at all since food, water and rot of
        no concern in ark. We will do XP spend, award session mutation points,
        and get rolling.
        <br />
        <br />
        The body count roll was 5. Minus two due to FOOD DEV level of 20+ leaves
        us with three casualties. The dead were three unnamed mutants.
        <br />
        <br />
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: office,
      altText: `Office of The Chairman at the Diamond Center.`,
      className: "imageLeft",
      imageCaption: "Office of The Chairman",
    },
  },
];

const MYZAnchortownSession24 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 24: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  The IL gang reaches The Other Ark, better known as the Diamond
                  Center. Depite plentiful food and water, the abundance of
                  children, the friendly but insistent questions of The
                  Chairman, and the subservient role of the male population have
                  raised some questions; questions for which our players intend
                  to find the answers.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession24;
