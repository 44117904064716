import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import tram from "../../../../assets/myz/ccc-tram-level.webp";
import poster from "../../../../assets/myz/poster-clark-stanley-life-and-times.jpg";
import systemFailure4 from "../../../../assets/myz/system-failure-4.mp3";
import mimir from "../../../../assets/myz/enclave-mimir-history.mp3";
import jarOfLight from "../../../../assets/myz/jar-of-nanotech.gif";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Finding the Eden ID Card and Getting to Tram Level 4",
    text: (
      <p>
        The action picks up with party resting in the Rec Center room to heal
        and recover. Action highlights this session were:
        <br />
        <br />
        <ul>
          <li>
            Level getting hotter and air thinner as generator array continues to
            fail.
          </li>
          <li>
            During battle with Drone Robots while resting in the Rec Center,
            BAYAZID is broken and suffers the Chromicus Effect (aka DAMAGED
            SPINE).
          </li>
          <li>WATER TREATMENT: found BATTERY artifact.</li>
          <li>
            LOUNGES: Guard Robots attack while mutants investigate lounge areas.
            Bayazid is shot and suffers another critical injury: this time its a
            BLEEDING GUT.
          </li>
          <li>
            Party fights off the guard robots at the lounge with Gladhand
            pulling Bayazid to safety to get him back on his feet.
          </li>
          <li>
            Party takes another rest block so lethality of Bayazid's BLEEDING
            GUT can be healed and the healing time for his DAMAGED SPINE could
            be halved.
          </li>
          <li>
            During this second rest, a few Morlocks try to ambush the party.
            Chromicus storms out shouting invectives and scares them off with
            successful ENFORCER INTIMIDATION.
          </li>
          <li>JANITOR CLOSET: found holotape.</li>
          <li>
            LAUNDRY ROOM: found some scrap and ENERGY PILLS (1 pill) artifact.
          </li>
          <li>
            FILM ROOM: found holotape, CLARK STANLEY Life and Times poster
            arifact, and artifact VHS tape of Rutger Hauer in Hobo With A
            Shotgun.
          </li>
          <li>
            EDUCATION ROOM: found holotape, EDEN ID CARD (metaplot artifact),
            and mysterious Jar of Light.
          </li>
        </ul>
        As the party searches the Education Center, another Drone Robot patrol
        arrives. They are fought off, but Hulagu's signature move goes wrong
        (i.e. Frog Leap, Fast Draw katana, chop chop, and Extreme Reflexes to
        chop again and/or draw gun on another target). His katana snaps in half
        as he cuts through a Drone Guard robot.
        <br />
        <br />
        Chromicus is shaken. The katana was in{" "}
        <a
          href="./mutant-year-zero-session-08-anchortown"
          rel="noreferrer"
          target="_blank"
        >
          Kurtz' museum at Elysium Fields
        </a>{" "}
        and Chromicus feels a pang of complex emotion tied to his old home and
        the nuclear blast used to destroy Kurtz and his cult. Finding the
        holotape for Mimir, and realizing it has no answers deepens Chomicus'
        funk. The self-professed Mimirian struggles with the mystery of what
        Mimirian even means. Who is Chromicus really? Meanwhile, Hulagu is just
        pissed and hopes Bayazid can work a miracle on the katana when they have
        time.
        <br />
        <br />
        Bayazid looks up information on the Jar of Light in education center
        computer system. Apparently it's a container of rogue nanotech. The
        effects are random and range from beneficial to damaging. In the spirit
        of investigation, the mutants decide they will open the jar and see what
        happens. The clones Harry, Darryl, and Vanja decide to step out in the
        hall and Vanja wishes her paramour Chromicus good luck.
        <br />
        <br />
        When the Jar of Nanotech is unleashed, each of the mutants breathes in
        the glittering cloud of micro-particles with the following effects:
        <ul>
          <li>GLADHAND: gains the mutation ROT-EATER.</li>
          <li>
            CHROMICUS: afflicted by a Virulence 6 fever that drains strength. He
            fights it off on the initial onslaught, a testimony to his
            constitution and slave-like grit.
          </li>
          <li>
            HULAGU: suffers Inhibition effect which takes his STR and AGI down
            to 1 for a day; a serious demerit in the final run to getting out of
            Cmd Ctr Charlie for sure.
          </li>
          <li>
            BAYAZID: he gets the effect of a REGEN and looses his one point of
            permanent rot and his DAMAGED SPINE is healed!
          </li>
          <li>
            IL GANG 1: afflicted with SCHIZOPHRENIA. He will now be commanded
            with -3 penalty and is subject to doing weird messed up shit at GMs
            discretion.
          </li>
          <li>IL GANG 3: enhanced to STR and AGI of 5 for 6 days.</li>
        </ul>
        <br />
        <br />
        After breathing in the nanoparticles, the party has everything they need
        to activate the bypass on the elevator. With all the holotapes on hand,
        they can scan them to show their evacuation of the facility is allowed
        since they are taking history with them. They take an elevator ride to
        level 4, the Tram Level. After a snarky goodbye from the Command Center
        Charlie systems robot voice, they step out into an area filled with
        tanks, gyrocopters, an underground tram, and much more. There is also,
        of course, a failing generator array. Feeling hope and excitement, the
        mutants start to think how they can find a way out, find the scuba gear
        and other loot, and escape. Then, their hear an alien, echoing screetch;
        the adult version of the sound they heard from the chest-burster in the
        Cold Storage room. They are being hunted and the clock is ticking . . .
      </p>
    ),
    image: {
      imagePath: jarOfLight,
      altText: `Jar of Light`,
      className: "imageLeft",
      imageCaption: "Jar of Light",
    },
    image2: {
      imagePath: poster,
      altText: `Life and Times of Clark Stanley`,
      className: "imageRight",
      imageCaption: "Clark Stanley",
    },
    audio: {
      audioPath: mimir,
      altText: `Enclave Mimir Holotape`,
      className: "imageLeft",
      audioCaption: "Enclave Mimir Holotape",
    },
  },
  {
    title: "Wrapup",
    text: (
      <p>
        <br />
        <br />
        <br />
        <br />
        <h3>Bookkeeping</h3>
        <ul>
          <li>Bitterbeasts: food harvested and some put in training.</li>
          <li>
            Pure Plant Patrol: Everything is Beautiful! 11 water, 2 booze, and 4
            grub.
          </li>
          <li>Ark Reputation: a mutant arrives seeks citizenship.</li>
          <li>
            Wanderers Arrive: four mutants arrive hungry and scared, but wanting
            a new home.
          </li>
          <li>
            Ark Projects: 1 point GENERATOR, 2 points SMOKEHOUSE, and 3 point
            RAILROAD.
          </li>
        </ul>
        NOTE: Altair is housed at the Rust Castle along with Summer of Love bus.
        Chi Tzu is on the Steelcrest anchored off the island of Command Center
        Charlie.
        <br />
        <br />
        Next session starts in-game 06-24-0001, approximately 10am inside
        Command Center Charlie on the Tram Level.
        <br />
        <br />
        Base XP is 3 per character. Then, we discussed cool stuff that happened.
        Based on discussion, GM awards a bonus xp to one of the characters for
        each player. Some highlights discussed were:
        <ul>
          <li>
            Hulagu: poor boss Hulagu. His katana snapped and the nanotech
            drained his already ailing body even more.
          </li>
          <li>
            Bayazid: used telekinesis to move hoverboard while he was sitting on
            it paralyzed from the waist down so he could see down corridor
            during battle.
          </li>
          <li>
            Gladhand: dragged Bayazid to safety during combat and got him back
            on his feet.
          </li>
          <li>
            Chromicus: used Enforcer Intimation to scare the shit out of
            straggler, ambushing Morlocks; they fled in terror.
          </li>
        </ul>
        Bonus xp was player choice due to largely even session. Extra points
        went to Bayazid and Chromicus.
        <h6>Critical Injury Roundup</h6>
        <ul>
          <li>
            {" "}
            Ghost Bird: SPRAINED ANKLE: -1 MOVE, SNEAK; heal time 3 days; ends
            morning of 25th.
          </li>
          <li>
            Ghost Bird: THIGH WOUND: -2 MOVE and SNEAK; heal time 3 days; ends
            morning of 25th.
          </li>
          <li>
            Gladhand: CRUSHED FACE: Chromicus rolled and it's no longer
            lethal...Effect: -2 to Manipulate, Healing Time: 12 days; ends
            morning of 25th.
          </li>
          <li>
            Chromicus: GROIN HIT: -1 FIGHT, MOVE, FORCE; heal time 4 days; ends
            morning of 26th.
          </li>
          <li>
            Smoke Raven: DAMAGED SHIN, -1 MOVE and SNEAK, heal time 4 days; ends
            morning of 26th.
          </li>
          <li>
            Bayazid: BLEEDING GUT: You suffer one point of damage for every roll
            you make to Force, Move or Fight. Healing Time: 5 days. Lethality
            was healed. Ends morning of the 30th.
          </li>
        </ul>
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        .
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: tram,
      altText: `Command Center Charlie tram level.`,
      className: "imageLeft",
      imageCaption: "Tram Level",
    },

    audio2: {
      audioPath: systemFailure4,
      altText: `System Failure Warning 4.`,
      className: "imageRight",
      audioCaption: "System Failure 4",
    },
  },
];

const MYZAnchortownSession52 = () => {
  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 52: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  Our mutants find the Eden Access ID card and arrive at the
                  tram level of Command Center Charlie.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession52;
