import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import berke from "../../../../assets/myz/ark-boss-berke.jpg";
import skag from "../../../../assets/myz/ark-boss-scag.jpg";
import bonzo from "../../../../assets/myz/noatun-estates/bonzo-dog-food.jpg";
import bucephalus from "../../../../assets/myz/token2_bucephalus.png";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "CIVIL WAR",
    text: (
      <p>
        04-31-0001: The IL Gang returns to the Sunshine Plaza, minus Smoke
        Raven. They pick up Chromicus at the Pure Plant and a human spokesperson
        for the Pure Plant to try and make good on talking out a deal for water
        in exchange for protection. Smoke Raven has stayed behind at Noatun
        Estates due to the effect of Cassandra's love potion.
        <br />
        <br />
        The raven notes from Berke have been accurate; the Plaza is in a
        full-blown state of civil war. The human from Pure Plant goes offstage
        as we transition into Civil War rules.
        <br />
        <br />
        Our Civil War rules are an adaptation of the&nbsp;
        <a
          href="https://www.drivethrurpg.com/product/304293/Mutant-Mass-Combat"
          rel="noreferrer"
          target="_blank"
        >
          Mutant Year Zero Mass Combat
        </a>{" "}
        rules by Stéphane Van Cauwenberghe. But modified. Heavily modified.
      </p>
    ),
    image: {
      imagePath: berke,
      altText: `Boss Berke switches sides from Allies to Axis after Boss Skag bites the dust from Cassandra's poison.`,
      className: "imageLeft",
      imageCaption: `Boss Berke: TRAITOR`,
    },
  },
  {
    title: "Civil War Rules",
    text: (
      <p>
        You can find the notes for the heavily modified&nbsp;
        <a
          href="/mutant-year-zero-civil-war-rules"
          rel="noreferrer"
          target="_blank"
        >
          Civil War Rules
        </a>{" "}
        online.
        <br />
        <br />
        WARNING: the Civil War rules are not written for mass consumption; they
        have not been edited and so forth. You will find it is campaign specific
        (i.e. the roster of Axis and Allies are the particular character of the
        Sunshine Plaza); however, it is posted in case someone finds use of
        inspiration therein.
        <br />
        <br />
        The rules in a nutshell are a way of aggregating all the mutants and
        humans in each faction. Then, through a series of battle phases, which
        are broken down into battle rounds, the bosses of each faction roll for
        successes to see how many Action Points they will have to spend in that
        Battle Phase.
        <br />
        <br />
        Action Points are of four types (which are analogous to mutant
        attributes) which are spend to roll dice for chances at successes on
        Actions. Below is the last of Action Types and the two action each has.
        <ul>
          <li>BATTLE: Fight, Recon</li>
          <li>STRATEGY: Maneuver, Tactics</li>
          <li>SUPPORT: Logistics, Propaganda</li>
          <li>MORALE: Medical, Recruit</li>
        </ul>
        For more detail, just take a look at the{" "}
        <a
          href="/mutant-year-zero-civil-war-rules"
          rel="noreferrer"
          target="_blank"
        >
          Civil War Rules
        </a>
        .
      </p>
    ),
    image: {
      imagePath: skag,
      altText: `Boss Skag bites the dust thanks to Cassandra's Bonzo Dog Food Poison.`,
      className: "imageRight",
      imageCaption: "Boss Skag: DEAD",
    },
  },
  {
    title: "What Happened Overview",
    text: (
      <p>
        If you look at the rules, you can see that one of the Event Card
        possibilities is one called Assassination Attempt. The IL Gang started
        with special Assassination card based on the poison they obtained from
        the scrap oracle Cassandra (see{" "}
        <a
          href="https://www.smokeraven.com/mutant-year-zero-session-17-anchortown"
          rel="noreferrer"
          target="_blank"
        >
          Session 17
        </a>
        ). The special card was like so:
        <br />
        <br />
        <div
          style={{
            border: "2px solid white",
            padding: 15,
            marginBottom: "15px",
            backgroundColor: "#e6e6e6",
            color: "red",
          }}
        >
          <h4>Bonzo Dog Food Assassination</h4>
          <p>
            Assassination attempt using Bonzo Dog Food.
            <br />
            <br />
            Choose a category of action points to use and describe how it is
            going to work.
            <br />
            <br />
            Roll Actions Points for chosen category to damage STR of target boss
            or general. If number of successes meet or beat STR, that boss is
            broken. Can be opposed with SUPPORT points with three success
            ignored.
            <br />
            <br />
            Once this effect is kicked off, even if target is not broken on
            first attempt; each subsequent battle round at the beginning of IL
            Gang turn; target receives 10D6 attack against their STR as the
            amount they ate continues to damage them.
            <br />
            <br />
            If attempt is against Boss Skag and Bianca is deployed, there can be
            no opposed roll on first or subsequent battle rounds, and +5 on each
            roll. However, after target of assassination dies, Bianca suffers
            8D6 attack.
          </p>
        </div>
        And so the battle began. It seemed overwhelming, then as we got used to
        the rules, it was kinda fun. We had the Helldrivers Reinforcement event
        thanks to the perfidious Raff using his walkie-talkie that he smuggled
        out when brought to the Sunshine Plaza as a "guest". The IL Gang beat
        back the Helldrivers using Heroic Actions no problem. Skag died of the
        poison after about three or four battle rounds and Julian the Deep
        stepped in as interim leader (yes, the same sniveling fool that
        Chromicus intimidated previously).
        <br />
        <br />
        By and large, the mechanics of the Civil War seemed sound; however, the
        points and damage to the points was much slower than expected. After two
        full session, we decided the third session would accelerate the final
        Battle Phases as a series of one-off event challenges using off the cuff
        rules.
        <br />
        <br />
        The off-the-cuff events and their outcome were:
        <ul>
          <li>
            ELDER KIDNAPPED: comatose elder kidnapped by Skag's gang. IL Gang
            gets Elder back into Krao's camp.
          </li>
          <li>
            THE EXODUS OF QUIET BOB: Quiet Bob offers to give intelligence if
            the IL Gang will let his faction sneak out without interference. The
            IL Gang agrees and this negates a substantial bonus that the final
            event would have benefitted from.
          </li>
          <li>
            ROT BOMB: several suicide rot bombers run into crowds and blow up
            rot bombs. Each PC has to roll on random table to see how close they
            are to anyone of the given blats and then possibly have to suffer
            rot damage. (1-2: safe distance; 3-5: in the area [4D6 rot attack];
            6-8 somewhat near [6D6 rot attack], 9-10, next to it [8D6 rot
            attack])
          </li>
          <li>
            DEFECTION OF BERKE: Boss Berke decides to throw over to the Axis
            side now that Skag is dead. IL Gang won challenge so bonuses to
            final event negatedhd.
          </li>
          <li>
            FLIGHT OF THE SKAGS: the Skag gang flees, taking to the Zone in
            whatever way possible. Because the IL Gang has done a good job on
            previous events, they get to roll and see if they can convince some
            Skags to stay and know that they will be loyal (modified Sense
            Emotion roll with faction stats in play). The IL Gang recruits five
            of the Skag gang and the rest are out in the Zone. Good luck boys
            and girls!!
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: bonzo,
      altText: `Bonzo dog food. Inspired by the awesome movie A Mouse and His Child.`,
      className: "imageLeft",
      imageCaption: "Bonzo Dog Food",
    },
  },
  {
    title: "Aftermath",
    text: (
      <p>
        The IL Gang and Boss Hulagu arise triumphant. Boss Krao and the Order of
        the New Dawn chroniclers will play an advisory and support role to the
        IL Gang going forward. Boss Hulagu suffered a Severed Leg Artery, but
        with a successful heal roll to stave of fatality, we can assume he
        recovers during the extended rebuild phase. After rolling 6D6, we
        determine that the time passing as Plaza recovers and so forth is 39
        days; that puts the next session beginning on May 11th, 0001 which gives
        the party the best month of the year in terms of number of daylight
        hours available when exploring the Zone.
        <br />
        <br />
        <h4>Treasure Haul</h4>
        <ul>
          <li>6 XP per mutant</li>
          <li>
            1 rot free grub per day for each PC while in the Plaza going forward
          </li>
          <li>+3 on every project roll for D6 sessions: roll was a THREE.</li>
          <li>6D6 grub: roll was TWENTY.</li>
          <li>6D6 water: roll was TWELVE.</li>
          <li>6D6 bullets: roll was EIGHTEEN.</li>
          <li>6D6 booze: roll was TWENTY-FOUR.</li>
          <li>
            12D6 scrap: result was a bunch of scrap used to repair gear and
            build armor/weapons during the 39 day rebuild of the Plaza
          </li>
          <li>
            1D6+1 Artifacts: roll was THREE. They got BOW AND ARROW, PERFUME
            BOTTLE, and CROSSBOW.
          </li>
        </ul>
        Other business handled was Bitterbeast Training and Egg Hatch rolls. At
        the end of session, our gang has FOUR bitterbeasts. One is fully
        trained, and there are three in training (both more than halfway
        trained). Feel free to check out our{" "}
        <a
          href="./mutant-year-zero-beast-training"
          rel="noreferrer"
          target="_blank"
        >
          Beast Training rules
        </a>
        . The bitterbeasts are Bucephalus (trained), Valegro, Tencendur, and
        Marengo.
        <br />
        <br />
        We handled all bookkeeping including XP spends and rot wash. It's worth
        noting the PERMANENT ROT roster at this point.
        <h4>Permanent Rot Points for PCs</h4>
        <ul>
          <li>Smoke Raven: TWO</li>
          <li>Skut: 0</li>
          <li>Rowbert: ONE</li>
          <li>Jebe: FOUR</li>
          <li>Hulagu: 0</li>
          <li>Gladhand: 0</li>
          <li>Double Bird: SIX</li>
          <li>Chromicus: TWO</li>
        </ul>
      </p>
    ),
    image: {
      imagePath: bucephalus,
      altText: `Bucephalus, the first trained bitterbeast of the Sunshine Plaza.`,
      className: "imageLeft",
      imageCaption: "Bucephalus",
    },
  },
  {
    title: "Wrapup",
    text: (
      <p>
        Next session will begin morning of 05-11-0001. All XP award and spent,
        repairs, injuries, food etc are taken care of. Next session hits the
        ground running.
        <br />
        <br />
        The Civil War took EIGHTEEN lives (see Graveyard for details). It's
        worth noticing that Wanderer Fatima, the leader of the Wanderer humans,
        has lost her son Elroy and her right-hand man Montana.
        <br />
        Another unfortunate loss is Janis, the mighty two-headed mutant from
        Elysium Fields.
        <br />
        The deaths included:
        <ul>
          <li>
            5 named NPCs: Boss Skag, Torque the Fixer, Elson the Dog Mutant,
            Oren the Meticulous, and Janis
          </li>
          <li>
            7 Wanderers: Orben, Viktor, Jesus, Elroy, Montana, Tobin and Sergei
          </li>
          <li>5 nameless People of the Sunshine Plaza (aka mutants)</li>
          <li>1 nameless Exkurtzer mutant</li>
        </ul>
        Additionally, there were losses due to factions leaving or fleeing the
        Plaza which include:
        <ul>
          <li>
            Quiet Bob and has 26 followers which include Calihan the Promiscuous
            whom he won over as a recruit during the Civil War.
          </li>
          <li>
            Skags Gang which was 33 mutants after Skag died. Among those who
            fled are named NPCs Raff the Helldriver, Lystra the Wild Slave,
            Julian the Deep, and Wanderer Belka disappeared. However, 5 were
            convinced to join IL Gang.
          </li>
          <li>Boss Berke and his 16 followers.</li>
          <li>
            TOTAL LOSS FROM RUNAWAYS: 77 - 5 (ex Skags convinced to stay) = 72
          </li>
          <li>TOTAL LOSS FROM DEATHS: 18</li>
          <li>TOTAL POPULATION LOSS (runaways/deaths): 90</li>
        </ul>
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: bonzo,
      altText: `Bonzo dog food. Inspired by the awesome movie A Mouse and His Child.`,
      className: "imageLeft",
      imageCaption: "Bonzo Dog Food",
    },
  },
];

const MYZAnchortownSession18_19_20 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 18, 19 and 20: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  This post covers session notes for Sessions 18, 19, and 20,
                  also known as
                  <h4>Civil War at Sunshine Plaza!</h4>
                  Yes, the IL Gang returned from from Noatun Estates to find the
                  Ark is in full-blown Civil War mode. The allies? Bosses Krao,
                  Berke, and Hulagu. The axis? Bosses Skag and Quiet Bob. Only
                  one true master boss will rise from this civil war.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                {/*<Card.Title style={cardTitle}>Shootout at Burger Jim's, 03-27-0001 - 03-27-0001</Card.Title>*/}
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession18_19_20;
