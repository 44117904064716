import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import elysiumFields from "../../../../assets/myz/threat-doomsday-cultist-kurtzland.jpg";
import janis from "../../../../assets/myz/ark-mutant-janis.jpg";
import encounter from "../../../../assets/myz/threat-doomsday-cultist-kurtzland-encounter.jpg";
import kurtz from "../../../../assets/myz/threat-doomsday-cult-kurtzland-kurtz.jpg";
import theEnd from "../../../../assets/myz/threat-doomsday-cultist-elysium-this-is-the-end.jpg";
import generator from "../../../../assets/myz/generator.jpg";
import lambda from "../../../../assets/myz/lambda-portrait.jpg";

const cardTitle = {
  paddingTop: 10,
  paddingLeft: 10,
  paddingBottom: 10,
  backgroundColor: "rgba(221, 117, 28, .7) ",
};
const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Bookkeeping",
    text: (
      <p>
        Projects continued back at the Ark with four points going to STABLE and
        three to MUSEUM.
        <br />
        <br />
        XP Spend saw Hulagu pick up talent LIGHT EATER; Double-Bird chose
        BODYGUARD, and Rowbert bumped INSPIRE up one skill level to help off set
        the "nerf" of ability.
        <br />
        See <a href="./mutant-year-zero-errata">Rules Errata</a>.
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
    },
  },
  {
    title: "Sector B11 - Skyscraper Scouting",
    text: (
      <p>
        The Mission: the party is trying to find the amusement park led by a
        cult leader. It is the cult that Biana, Zana, and Ariana came from. It
        is said the cult leader is hoarding medicine because no one deserves to
        live. This medicine could help the raging parasitic fungus pandemic
        afflicting the Sunshine Plaza.
        <br />
        <br />
        Note: this special sector was a complete rip-off of Apocalypse Now as
        you can see. It was also a bit of a forced adventure that presupposed
        party submission as most likely scenario. I didn't care for the forced
        part and am glad to return to more emergent play pattern next session.
        That said, I really wanted to distort this piece of classic American
        cinema to give flavor to Anchortown, drop a few tidbits of lore, and
        play with the theme of childbirth which has become dominant, second only
        to daily survival itself. We also got a new player in the group
        (bringing total number of players to four), and we conspired to make the
        NPC originally called Hopper (of course) be his character. The scripted
        bits were more loose and brilliantly interpreted to be more Chromicus
        than Hopper, but I have the Hopper bits below for reading sake.
        <br />
        <br /> 03-16-0001: Party camped in the first floor lobby of skyscraper
        after defeating the Zone Ghouls. These are true Zone Ghouls; soulless,
        toxic husks, and nothing like the humans of the Zone Plant near the Ark.
        <br />
        <br />
        The party fails to repair Smoke Raven's spiked bat, but everyone gets
        sleep and eats from their waning supply of grub. <br />
        <br />
        In the morning, Jebe, Smoke Raven, and Rowbert climb the heavily damaged
        skyscraper with MOVE rolls, avoiding damage from an old pitfall trap
        along the way made by the zone ghouls or some previous, perhaps more
        intelligent, occupant.
        <br />
        <br />
        From the top of the skyscraper, Smoke Raven is able to scout Sectors
        A10, A11, A12, B10, B12, and C12. The results are roughly:
        <br />
        <br />
        <ul>
          <li>
            A10: Purification Plant in a foggy sector. Landscape is riddled with
            MUD PUDDLES threat but there at at least three artifacts in this
            sector.
          </li>
          <li>A11: Ash Desert. FIND THE PATH roll had no successes.</li>
          <li>A12: Ruined Bridge. FIND THE PATH roll had no successes.</li>
          <li>
            B10: Dead woods with SCRAP CROWS and some kind of metal road sign
            too far to see.
          </li>
          <li>B12: Glassified Fields. FIND THE PATH roll had no successes.</li>
          <li>
            C12: Amusement Park. The Elysium Fields amusement park is in this
            sector.
          </li>
        </ul>
        Now that they know where the amusement park is, and with the possibility
        of finding medicine for the pandemic in the Sunshine Plaza, they travel.
      </p>
    ),
    image: {
      imagePath: encounter,
      altText: `Mysterious mutants adorned in antler crowns and boneware jewelry guide our party through a choked highway underpass.`,
      className: "imageLeft",
    },
  },
];
const section2Data = [
  {
    title: "Sector C12 - Elysium Fields Amusement Park",
    text: (
      <p>
        03-16-0001: As the party approaches an underpass choked with the rusted
        remnants of Old Age automobiles laced with dead plants, vines, and
        broken concrete, they are approached by half a dozen mutants wearing
        antlers and bone necklaces. They say nothing and are non-threatening.
        With a wave, the lead mutant shows the party the best path through the
        underpass wreckage. The horned mutants are very relaxed, almost torpid.
        On the other side of the overpass, the party emerges into an open field
        with a view of Elysium Fields amusement park in the distance. As they
        cross the field they see several cow-like creatures wandering the
        flattened, greenish-grey fields.
      </p>
    ),
    image: {
      imagePath: lambda,
      altText: `Lambda the Gearhead was a member of Boss Skag's gang. She was met at the Pure Plant and was the
        most reasonable and decent Skagian to date. She was lost on 03-17-0001 to the parasitic fungus raging thru
        the Ark. The party is on their way with medicine, but not in time for Lambda.`,
      className: "imageLeft",
    },
  },
  {
    title:
      "Chromicus the Enforcer -- NPC turned PC to introduce new player to game.",
    text: (
      <p>
        03-16-0001: As the mutants approach the amusement park with their
        guides, many more mutants adorned with boneware jewelry and antler
        crowns emerge from behind towering, rusted hulks of metal created by the
        Old Ones for amusement. They hear a buzzing sound and then see a mutant
        flying overhead and then land on a boat-shaped amusement ride. This is
        the new party-member Chromicus. The new player of said character and GM
        conspired to shift NPC role in this special zone as a way to introduce
        character. Here is a version of the flavor text used as reference by the
        new player running Chromicus as a member of the amusement park cult:
        <br />
        <br />
        Before you stands a goliath of a man, toweringly tall with thick stringy
        muscles. Even his large fly-like insect wings look powerful, but then
        they would have to be to carry him. His skin is a light grey color. You
        notice that his eyes are different colors, but that’s not the only thing
        you notice about his eyes, even from a distance. His left, grey-blue eye
        has painful-looking scarring around it, exposing a little too much of
        the white of the eye. Black and dark red tendrils of scar tissue thread
        outward from the somewhat exposed orb and across the left side of his
        face. Despite this disfigurement, there is a charisma to him, and he is
        an intimidating and powerful figure. He carries a spiked bat but not
        like someone who needs it.
        <br />
        <br />
        “Welcome on behalf of Kurtz. I am Chromicus…the Enforcer.” He points to
        a flag patch on his jacket. “I’m a <strong>Mimirian</strong>. Kurtz has
        asked me to speak with you.”
        <br />
        <br />
        Chromicus flys down from the boat to land right in front of the new
        mutants, our other players.
        <br />
        <br />
        “This boat is a mess. But never leave the boat.” He smirks, as if he’s
        made a very clever joke, the smile made almost gruesome by the scarring
        on the left side of his face; then he lands again, much closer to the
        Sunshine Plaza crew.
        <br />
        <br />
        Almost playfully he says, “Watch out. These Kurtzers bite. Nah. They’re
        okay. They’re good people under the spell of a powerful man. These are
        Kurtz’ children as far as you can see! They think you’ve come to take
        him away.” He gets more conspiratorial, “But I know what he wants. He’s
        been waiting. He’s tired. He’s been waiting for mutants…just…like…you.”
        <br />
        <br /> Among the dozen or so Kurtzers wandering out from behind
        amusement rides, the party sees a mutant named Janis. An abomination!!
        Two mutants fused together in a horrific slagheap of flesh that shares
        four arms, four legs, and two heads (mostly). It has a spiked club arm
        and uses a roadsign on a metal pole as both a weapon and as a shield.
        <br />
        <br />
        “That’s Janis. Isn’t she a beauty? Now look, the world is a dangerous
        place, but all is not ever just what it seems. You don’t have anything
        to worry about. The Kurtz himself has a plan, see. There’s a plan. Just
        go with the flow, like floating down a stream.”
        <br />
        <br />
        Chromicus produces an artifact that looks like the tip of Silas' staff
        back at the Ark; but this is an actual working Kammeruh. He takes a
        picture of the group, then flies away.
      </p>
    ),
    image: {
      imagePath: janis,
      altText: `Janis the Slave. Strength 9, Agility 3, Wits 3, Empathy 3. Mutations: MIND TERROR, FOUR ARMED. Scrap 
        Axe in shape of roadsign that can be used reactively as a shield for PR 3. Because of Janis' extra head, the 
        use of same object as armor and weapon is a special ability.`,
      className: "imageRight",
    },
  },
  {
    title: "Overwhelming Odds",
    text: (
      <p>
        03-16-0001: The People of the Sunshine Plaza fight a mighty fight.
        Mutation points are spent and Kurtzers are falling left and right.
        Double-Bird uses TELEPATHY to mentally destabilize Janis and send the
        monstrousity to the ground sobbing and confused. Then, at least a dozen
        more Kurtzers join the fray. They are throwing tear gas into the fight;
        gas the Kurtzers themselves are immune to. <br />
        <br />
        Smoke Raven goes down and is broken but revives himself with MANBEAST,
        rolls a Mutatation Misfire that boosts the mutation allowing him to have
        his MP back and attack multiple targets in the same round, so he tears
        apart two Kurtzers in one round after they thought he was dead on the
        ground!
        <br />
        <br />
        But eventually the party is overwhelmed. Chromicus returns to help lead
        the party on a small tour of the little amusement park, pointing out the
        teacup ride, the go-kart track, and the roller-coaster. On their way to
        a series of small buildings, they see a Snack Shack and an arcade with
        Kurtzers standing guard at the door. Then, they are taken into one the
        buildings. It seems like it used to be a place where objects were put on
        display but is mostly a cache of broken junk now with a few cages in the
        corners.
        <br />
        <br />
        The party is locked into metal cages. On their way to the cages, they
        see a shiny sword in a display case among the broken scrap of the main
        room of the museum; a KATANA.
        <br />
        <br />
        Chromicus returns to speak in riddles and assure them "The Man" has a
        plan and give them water and grub. Hulagu, Rowbert, Double-Bird, and
        Skut volunteer for "the trial".
        <br />
        <br />
        The Trial turns out to be them our of them bound into a roller-coaster
        cart. Four Kurtzers are restrained at the bottom of the first big
        roller-coaster hill with their heads sticking up through the gaps in the
        old wooden track ties. Four Kurtzers manually push the kart to the top
        of the roller-coaster, and then the kart careens to the bottom of the
        hill and mercilessly decapitates and crushes the heads of the Kurtzers.
        Our mutants take a 4D6 Empathy attack.
        <br />
        <br />
        After the ride is over, the mutants are returned to their cages. This
        time, Rowbert is put in a special cell with thicker metal and a keypad
        lock because they saw his acid spit in action and didn't want him
        getting out of the cage. Hulagu and Double-Bird are placed into a
        separate cell together; they are both candidates to become the "Chosen
        One" due to taking the least empathy damage. In the middle of the night,
        Hulagu and Double-Bird are taken to see The Man.
      </p>
    ),
    image: {
      imagePath: elysiumFields,
      altText: ``,
      className: "imageLeft",
    },
  },
  {
    title: "Interview with Kurtz",
    text: (
      <p>
        The Man, Kurtz, is sitting in a corner of an old arcade. Guards stand to
        the sides and Chromicus is squatting to the side near an old video game
        machine. Shadows of the old machines and Kurtz are cast onto the walls
        from animal fat candles. Kurtz has a pregnant cow laying in front of
        him. She is lowing mildly, clearly pregnant. Kurtz pets her head which
        lays in his lap with one hand; in the other hand, he is holding a book;
        it is titled "Elementary School Mathematics: Fun with Fractions".
        <br />
        <br />
        "You can see Bessie there. She will give birth very soon. Possibly
        tonight. What an auspicious sign that you arrived at this moment of
        birth. But also, we have had death. Death on the coaster. That was
        horrible I know. But they had to be punished; an example made. They
        tried to steal at the <strong>Rust Castle</strong>. I met
        <strong>Elwith</strong> once before she and the others settled in the
        Castle. Wonderful people. Little people. Like children. We must always
        honor children because, it's not their fault; they didn't choose to come
        to this world or even to be born. Before all this, this destruction,
        this destroyed land; children were born."
        <br />
        <br />
        Kurtz splashes water on his bald head, the beads running down his grimy,
        painted face.
        <br />
        <br />
        "I’ve seen horrors, horrors that you’ve seen. But you have no right to
        call me a murderer. You have a right to kill me. You have a right to do
        that, but you have no right to judge me. It’s impossible for words to
        describe what is necessary to those who do not know what horror means.
        Horror. Horror has a face. And you must make a friend of horror. Horror
        and moral terror are your friends. If they are not then they are enemies
        to be feared."
        <br />
        <br />
        Chromicus, fidgeting, cannot contain himself and interrupts:
        <br />
        <br />
        "Do you know what the man is saying? Do you? It's very simple. One
        through nine, no maybes, no supposes, no fractions -- you can't travel
        in space, you can't call up <strong>Mimir</strong> and ask to go
        underground, you can't go out into space, you know, without, like, you
        know, with fractions -- what are you going to land on, one quarter,
        three-eighths?" [Kurtz throws the mathematics book at Chromicus who
        concludes with: "This is the way the fucking world ends! Look at this
        fucking shit we're in, man! Not with a bang, with a whimper. I'm fucking
        splitting, jack!"
        <br />
        <br />
        Kurtz continues: "I remember when I thought the Ark would save us. I was
        drunk on pride. I was an <strong>Elder</strong>. Yes. I'm not a mutant
        you know? We left the Ark and went into the zone to find the truth. Some
        of my children stayed behind. I thought they were scared, cowards. They
        cried and threatened, but I knew the truth was in the zone. One of the
        children left behind came running after us the next day, and he was
        crying. He couldn’t see. He told us all the children, our fellow people,
        the mutants of the Ark, had killed themselves. They decided they could
        not survive in the Zone and they would not allow themselves to stay and
        starve in the Ark. And I remember, I, I, I cried, I wept like some
        grandmother. I wanted to tear my teeth out. And then I realized, like I
        was split, like I was split in twain with sword, a razor-sharp katana
        right through my forehead. And I thought, my God, the genius of that.
        The genius. The will to do that. They were not cowards after all. They
        were perfect, genuine, complete, crystalline, pure. And then I realized
        they were stronger than us. You have to have primordial instincts to
        kill without feeling, especially when it is yourself. To kill without
        passion, without judgment, without judgment. Because it’s judgment that
        defeats us."
        <br />
        <br />
        "Do you judge me? What is your judgment?"
        <br />
        <br />
        Double-Bird answers the question, but it is Hulagu's simple question
        back "What is it you want?" that triggers Kurtz into realizing Hulagu is
        his chosen one.
        <br />
        <br />
        The mutants are taken back to the cages, and this time Hulagu is locked
        alone in a tiny cramped cage.
      </p>
    ),
    image: {
      imagePath: kurtz,
      altText: `Kurtz . . . straight out of Apocalypse Now.`,
      className: "imageLeft",
    },
  },
  {
    title: "This Is The End",
    text: (
      <p>
        Chromicus appears again and gives Hulagu a key to open his cage. The
        keys to the others cages are on Kurtz, and the code for the keypad cage
        is in his journal. Hulagu grabs the KATANA as Chromicus leads him back
        toward the arcade. The sky filled with lightning and storming. Rain
        comes down in sheets. The Kurtzers are all dancing, banging on objects
        rhythmically and generally celebrating some ecstatic portent of the calf
        about to be born.
        <br />
        <br />
        In the arcade, Kurtz is alone with the cow that is still struggling to
        give birth. Kurtz is resigned and turns to face his executioner, his
        angel of mercy. "Kill them all. Exterminate them."
        <br />
        <br />
        The katana drops and Hulagu gives Kurtz the deliverance he wanted but
        was too cowardly to give to himself. Then, Hulagu grabs the keys and
        journal to free his friends while Chromicus goes around the camp calling
        his friends to him to get ready to leave. Of the 80 or so Kurtzers,
        about 20 of them and Janis are friends with Chromicus and see the wisdom
        in leaving if Kurtz is gone. The rest of the Kurtzers are dancing and
        unaware, but it is only a matter of time before the calf is born and a
        Kurtzer discovers their cult leader is dead.
        <br />
        <br />
        Smoke Raven, Jebe, and Chromicus sneak to the snack shack as the rest of
        the party leaves the amusement park to await them in the fields of
        Elysium. Under a warped trapdoor in the snack shack is a metal door to a
        bomb shelter. As the mutants make COMPREHEND rolls, the weather keeps
        howling and the Kurtzers have yet to discover the fate of The Man
        himself. After unlocking the door, the trio discovers tubs of medicine
        in the bomb shelter, a GENERATOR, a bunch of scrap (see below), and a
        BIG RED BUTTON.
        <br />
        <br />
        After some deliberation, they decide to enter the code and hit the
        button; in essence, to honor Kurtz' last wish that his followers, his
        children, be destroyed; but also, as a benefit, it ensures that the 60
        or so still-loyal Kurtzers cannot follow the party.
        <br />
        <br />
        Pressing the buttons activates klaxons and a recorded voice (from whence
        they know not) warning of a nuclear strike in 60 minutes. They party
        flees from Elysium Fields with 20 Kurtzers and Janis in tow. They make
        it only halfway to the edge of this sector when the nuclear bomb
        explodes. Everyone in the party suffers a 6D6 explosion attack. Then the
        party reaches Sector C11 to camp, fighting some Zone Rats on the way. By
        the time they a place to camp there are only 15 Kurtzers left alive, as
        well as Janis.
      </p>
    ),
    image: {
      imagePath: theEnd,
      altText: `Hulagu takes the KATANA artifact and decapitates a willing Kurtz.`,
      className: "imageLeft",
    },
  },
];
const section3Data = [
  {
    title: "",
    text: (
      <p>
        We ended session having taken care of grub/water for healing and
        maintenance, rot points, and assigning XP points. Everyone got 4 as an
        alternation with the usual 3. We tend to run out of time for the XP
        discussion as well as finding that while in the Zone it is difficult to
        have the Ark relationships play out.
        <br />
        <br />
        Next session starts morning of 03-18-0001 in the beginning edge of
        Sector C11.
        <br /> We will add mutation points, allow for XP spend, and then getting
        back to the Ark is on the agenda.
        <br />
        Finally: the summary of critical injuries is as follows (which includes
        Care rolls to half time):
        <br />
        <br />
        <ul>
          <li>
            Jebe: Sprained Wrist, -1 to Shoot and Fight. Will be healed Morning
            of 03-20-0001 (3 days).
          </li>
          <li>
            Maxim: Groin Hit (from session 06) is finally healed morning of
            18th.
          </li>
          <li>
            Astrina: Crushed Face, -2 to Manipulate. Will be healed morning of
            03-21-0001 (4 days).
          </li>
        </ul>
        <br />
        Check out Session Body Count at:{" "}
        <a href="./mutant-year-zero-anchortown-population-notable-events">
          SESSION BODY COUNT AND POPULATION
        </a>
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a href="./mutant-year-zero-anchortown-graveyard">
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: generator,
      altText: ``,
      className: "imageLeft",
    },
  },
];

const MYZAnchortownSession08 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);
  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 08: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  Our mutants climb a skyscraper to find the amusement park
                  Elysium Fields where they hope to find medicine to stop the
                  pandemic parasitic fungus in the Ark.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Title style={cardTitle}>Housekeeping</Card.Title>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
                <Card.Title style={cardTitle}>
                  Elysium Fields, 03-16-0001 to 03-17-0001
                </Card.Title>
                <Card.Text>
                  <ContentContainer data={section2Data} />
                </Card.Text>
                <Card.Title style={cardTitle}>Wrapup</Card.Title>
                <Card.Text>
                  <ContentContainer data={section3Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession08;
