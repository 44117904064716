import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import doorToEden from "../../../../assets/myz/door to eden.jpg";
import eltaLeader from "../../../../assets/myz/elta-cult-leader.jpg";
import eltaLandscape from "../../../../assets/myz/elta-cult-landscape.webp";
import kohen from "../../../../assets/myz/kohen-the-dog-handler.webp";
import video from "../../../../assets/myz/video-camera-METAPLOT.jpg";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "A Very Good Year",
    text: (
      <p>
        imagePath: kohen, altText: `Kohen is one of the Exkurtzers who ran with
        Chromicus and crew from the Elysian Fields. He shared Chromicus' need to
        protect Rel and commiserated after her death. He fought under Chromicus
        in the most recent war and is a trusted ally and friend. He was the
        deputy left behind to command Chromicus' troops while Il Gang took their
        fateful trip to the Nova Cult.`, className: "imageLeft", imageCaption:
        "Kohen the Dog Handler", After returning to the Sunshine Plaza from the
        Nova Cult, the IL Gang settles into a year-long montage. Projects are
        built, alliances are strengthened, the Plaza prospers, and Doc
        Glandhand's (RIP) child Glib is born to Fatima.
        <br />
        <br />
        We players driving narrative, we storied out what would happen in this
        year-long renaissance. Midway (or so) through the year, the IL Gang
        travels to the Elta Cult to get a CASSETTE PLAYER artifact to unravel
        the mysteries of Eden. And then, EDEN itself is found.
        <br />
        <br />
        <b>WARNING: Major Spoilers for EDEN campaign below.</b>
        <br />
        <br />
        Summary of events for the Very Good Year (massive shout out to Rob who
        authored this and thought of all story threads!)
        <ul>
          <li>
            A beachhead settlement is established in A07 or A08, with a dock, a
            shipyard, and strong defenses, including the harbor. The Oil Fields
            are an ally, while Cage Island is an enemy.
          </li>
          <li>
            Along with the Oil Fields, Sunshine Plaza made a small joint
            settlement on the Command Center Charlie Island. Under the influence
            of the Green Man's growth, the island has become filled with plant
            and fauna, and it is incredibly pure.
          </li>
          <li>
            The ark expansion zone at E05 becomes Gladhand Market, a trading
            zone overseen by Sunshine Plaza.
          </li>
          <li>
            More railroad lines have been completed. The railroad now connects
            all of the allied groups together: Sunshine Plaza, Noatun Estates,
            Pure Plant, the Saurians and our new beachhead settlement, the
            Silver Egg., the ark expansion zone., etc. No railroad to Homestead.
          </li>
          <li>There are 2-4 trains running on the railroad lines.</li>
          <li>There are 2-3 ships, at least, and a few boats.</li>
          <li>
            The railroad even connects the Helldrivers. After witnessing the
            nuclear explosion, and in the wake of the war and Patrick's death,
            the Helldrivers saw the wisdom of growing with the Zone instead of
            trying to rule it. They even run a popular bar in Gladhand Market.If
            ark expansion zone is Gladhand Market.
          </li>
          <li>
            Now that they've started working together with more permanence, the
            allied groups are all thriving with trade.
          </li>
          <li>
            Besides a bar in Gladhand Market, the Helldriver headquarters/zone
            could have become a hard rockin' casino and betting hall, providing
            entertainment and betting opportunities in fighting, racing,
            drinking, playing dice, arm wrestling, etc. The Helldrivers are now
            led by a stern but practical drunk named Febreeze (my template for
            his portrait if one was ever needed would be Robert DeNiro).
          </li>
          <li>
            Chromicus and Ghostbird both live at the Silver Egg, working
            together to fulfill its promise?
          </li>
          <li>
            Communication between the people and the Saurians has improved, with
            a shared language of sound and sign that the two groups have
            developed, and are still developing, together.
          </li>
          <li>
            The Saurians patrol the waves and are a valued part of our defense.
            We, in turn, protect the Saurians. While welcome at any of the
            settlements, the Saurians want to be near the seas so they don't
            usually venture to Sunshine Plaza.{" "}
            <li>
              Some Saurians can, however, be found often at the Ark's Beachhead
              Settlement.
            </li>
            <li>
              Pure water is transported weekly from the Saurian encampment and
              stored in the beachhead settlement. Some stays there in storage
              while some is shipped via railroad to other settlements. Between
              the Pure Plant and the Saurians, none of the allied groups is
              going thirsty.
            </li>
            <li>
              No one is starving, either. Food is available at all settlements.
              The trains are extremely helpful in transporting goods between
              zones. Bitterbeast steaks are a welcome surprise to most of the
              Zone, even better than pig, and at least one of the settlements
              has been growing vegetables, something previously rarely seen, if
              at all, at Sunshine Plaza. The settlement at the beach brings in
              fish, kelp, and other marine life.
            </li>
            <li>
              Cassandra has recovered the energy she expended in battle, mostly
              because we won. If we had lost, she would have died. Her life
              force was tied to the outcome by helping us. No way of recharging
              if the Zone went the wrong way. She risked it all in an expression
              of faith in us, in hope. She was able to recover because the
              balance in the Zone had tilted towards life.
            </li>
            <li>
              Technology is being shared. All settlements' Technology raises by
              10-15.
            </li>
            <li>
              Heliographs are constructed at all settlements. Chroniclers are
              charged with the heliographs and have already developed a robust
              code for communication. Ghostbird is the Head Chronicler. The
              heliographs are also a way that people{" "}
              <li>
                alert Ghostbird they're bringing artifiacts and items for him to
                use his clairvoyance on.
              </li>
              <li>
                Ghostbird runs a museum of artifacts and other items, the Allied
                Archives & Museum at the Silver Egg.
              </li>
              <li>
                The Homestead has a smaller bitterbeast operation. It's enough
                to keep them from eating mutant or human meat, mostly. Hetu Jr.
                spends much of his time at the Homestead where he and the
                Homestead family train bitterbeast riders.
              </li>
              <li>
                With the Chairman dead, the Dimond Center's remaining citizens
                feel the lifting of his yoke. They suffer for a while, but it is
                hard to ignore the success to their northwest and they reach out
                to join the market. Though the trip is longer for them without
                railroad tracks, they find their lot has improved some. Will
                they also enjoy the taste of bitterbeast?
              </li>
              <li>
                Boss Hulagu and Boss Fatima have managed to work together well.
                They have a good advisement committee, too.
              </li>
              <li>
                Fatima birthed her and Gladhand's child, a girl named Glib.
              </li>
              <li>
                Kohen the Dog Handler has become parent to Glib and partner to
                Fatima. Kohen was a soldier and leader during the war, serving
                under Chromicus and taking over his troops when the party
                traveled to the Nova Cult.
              </li>
              <li>
                Boss Hulagu is widely popular. Any doubters or detractors have
                become converts after the war and as a new level of comfort has
                settled in.
              </li>
              <li>
                Despite the irradiated NE corner of the map, the Zone has never
                been more hopeful.
              </li>
              <li>
                Chromicus administers the Regen that Hulagu has been carrying,
                becoming clean of his seven points of rot.
              </li>
              <li>
                Smokeraven continues to go on expeditions into the Zone looking
                for artifacts and to connect with new people and groups. He
                brings artifacts to Ghostbird and is interested in settting up
                an institution of higher learning.
              </li>
              <li>
                Other projects that are completed: Electric Lights, Gladhand
                Market, Museum, Railroads, Ships, School, list projects here
              </li>
            </li>
          </li>
        </ul>
      </p>
    ),

    image: {
      imagePath: kohen,
      altText: `Kohen is one of the Exkurtzers who ran with Chromicus and crew from the Elysian Fields. He shared Chromicus' need to protect Rel and commiserated after her death. He fought under Chromicus in the most recent war and is a trusted ally and friend. He was the deputy left behind to command Chromicus' troops while Il Gang took their fateful trip to the Nova Cult.`,
      className: "imageLeft",
      imageCaption: "Kohen the Dog Handler",
    },
  },
  {
    title: "Elta Cult",
    text: (
      <p>
        During the Very Good Year, after the Video of Eden was watched, the IL
        Gang takes the Window Pane battle bus far the the southeast of the Zone.
        They travel to the farflung homeland of Ghost Bird to pay a visit to the
        ELTA CULT (aka the Fire Cult).
        <br />
        <br />
        As the bus approaches the rusty graveyard of the skybirds, the sounds of
        a small jet engine, shouting, and laughter are heard. Ghost Bird recalls
        the rich, powerful smell of the jet fuel and climbs atop the bus. Window
        Pane approaches cultists firing the small test engiene for practice
        flame endurance tests. Ghost Bird fires ritual arcs of flame from his
        behemoth flamethrower in greeting to his brothers. The cultists shout,
        "Ghost Bird! It's Ghost Bird". One runs ahead as the others dance and
        slap the bus in joy, screaming "on top of the world", a ritual phrase
        they learned from a film from the Old Age. Ghost Bird shouts "I'm on top
        of the world" and keeps goregous arcs of blistering fire blasting out.
        <br />
        <br />
        The IL Gang offloads their gifts to the Elta Cult. They brought a barrel
        of Jet Fuel from Command Center Charlie, food and water, and in an
        audience with ELTA, they also gift the behemoth flame thrower. ELTA and
        Ghost Bird's friend Bautista ask questions about the "outside world".
        The ELTA Cult knows very little about the Zone. They did know the RUST
        CASTLE was destroyed, but the Plaza, it's allies, and other wonders such
        as the Allied Archives & Museum at the Silver Egg is news to these
        backwater cultists.
        <br />
        <br />
        During a party that evening, the heavy metal cassette tapes are put
        aside long enough for Ghost Bird and Bayazid to play the Emergency
        Broadcast cassette. The tape is a complex series of long and short tones
        at different pitch levels. The tones are followed by a robotic voice
        reciting numbers. It's a cypher that will require the IL Gang to have
        use of the cassette recorder for weeks.
        <br />
        <br />
        Ghost Bird speaks with Elta. Bautista aids Ghost Bird's plea. The best
        deal that can be made is that one of the IL Gang besides Ghost Bird
        becomes a member of the loyalty program and gets their wings. Boss
        Hulagu has mutations that allow him to offset damage and he's loaded
        with mutation points. Boss Hulagu stands in the Big Engine Test tunnel
        as heavy metal music is played. He has to stand for three rounds of 12d6
        fiery blast damage. Each round he can activate mutation as usual. And at
        the end, there is a 50% chance he will be on-fire and need to roll MOVE
        successfully to put out the flames. Hulagu takes a total of 9 points
        damage and uses his mutations to survive. In the second round of the
        fiery test, Hulagu's mutation misfires and he gets another cosmetic
        mutation. Since he already has a praying mantis face, it is told that he
        is simply more charred, scarred, and horrific to look at. In the final
        round though, his mutation misfires again as a take damage or if there
        was no target for the mutation a round of disorientation. And so Hulagu
        is clutching the walls of the test tunnel as the Elta Cultists rush in
        with fireproof blankets and pull out the honorary new member of the Elta
        Cult.
        <br />
        <br />
        Goodbyes are made and the IL Gang returns to the Sunshine Plaza with the
        final artifacts needed to solve the mystery of EDEN.
      </p>
    ),
    image: {
      imagePath: eltaLandscape,
      altText: `The Elta Cult (aka Fire Cult) worships the ecstatic cleansing power of jet fueled flames from test engienes.`,
      className: "imageLeft",
      imageCaption: "ELTA CULT",
    },
    image2: {
      imagePath: eltaLeader,
      altText: `ELTA is the honorific name of whomever leads the cult. ELTA is always a member of the loyalty program and always has his wings (i.e. has stood for three rounds in the flames of the Big Test Engiene and lived)`,
      className: "imageRight",
      imageCaption: "ELTA",
    },
  },
  {
    title: "EDEN FOUND",
    text: (
      <p>
        Sometime during the Very Good Year, Bayazid puts together the VIDEO
        CAMERA and VHS TAPE that was found at the Elta Cult. The resulting
        footage unravels the mystery of Eden greatly. The hand text was as
        follows:
        <br />
        <br />
        <i>
          After hooking up the generator to power the Video Camera, you insert
          the VHS tape found at the Nova Cult silo. <br />
          The video is mere staticy black and white noise, and then a picture
          forms.
          <br />
          You watch in wonder as a clip plays. In the video it shows humans in
          heavy protection suits leaving a bunker. Some of them are carrying
          children. They are walking out of the bunker through a large metal
          door in the rocky side a mountain or hill;
          <br />
          "They look like scientists", Katie Kat has been watching from shadows
          in corner of the room. "It makes me miss Fortran."
          <br />
          The scientists are followed by thousands of children in addition to
          the small ones some scientists are carrying. Fatima says
          authoritatively, "none of the children seem to be more than three or
          four years old." Some of the children have obvious mutations. On the
          partly opened gate a flaking text says “Eden”, and below that,
          “Command Center Delta”.
          <br />
          One of the suited figures walks close to the camera and passes by,
          waving at the camera or perhaps the person behind the camera. The man
          is carrying a female mutant child on each arm. The man says, "Hello{" "}
          <b>Dr. Retzius</b>, capturing this for history eh?" A female voice
          answers, "Yes <b>Dr. Sebedius</b>, that's right."
          <br />
          You recognize the man's voice as The Elder of the Sunshine Plaza. He
          is about 20 years younger but you can see in his eyes now that it is
          him. And the children he is carrying; you demand the tape be rewound
          and played again . . . it is . . . one child he is carrying is clearly
          scrap oracle <b>Cassandra</b> and the other is{" "}
          <b>Double Bird the First</b>.<br />
          Afterward, as humans in protective gear guide mutants past the camera,
          the video has only the sound of someone holding the camera breathing.
          Finally, after all the humans and mutants have filed past, the video
          camera shakes as if the camera is being lowered to the side of the
          person holding it. A female human voice, presumably Dr. Retzius, says,
          "damn fools. No matter; we will broadcast the coordinates from the
          cassette tape. Someone, someday will understand. They will stop him
          and reclaim Eden. My God protect our souls." Then there is a clicking
          sound and the video goes dark.
          <br />
        </i>
        <br />
        At the end of the Very Good Year, after weeks of effort, Ghost Bird
        cracks the code of the cassette tape. The IL Gang has the coordinates of
        EDEN!
        <br />
        <br />
        The travelling to EDEN looks like: Boss Hulagu on a motorcycle,
        Chromicus riding bitterbeast Chi Tzu, and everyone else on the bus
        Window Pane (Smoke Raven, Bayazid, Kohen and his mountain hound Nails,
        Ghost Bird, Cad, and Grit).
        <br />
        <br />
        The door to EDEN is large but hard to find due to vegetation and the
        hills and rocks around the base of the mountain. Hulagu scans the card
        and it turns out the door will only open just wide enough to allow a
        large person through before closing. The failue protocol for the
        malfunctioning door puts some pressure on the group, but with some
        planning, decent MOVE rolls, and a splash of luck, everyone including
        pets and beasts makes it into the military bunker know as Command Center
        Delta or . . . EDEN.
        <br />
        <br /> Standing in a lange metal vehicle tunnel next to a guard shack
        with Smoke Raven's flashlight piercing the darkness, our group will be
        exploring EDEN next session and unravelling the remaining mysteries of
        The People and The Zone.
      </p>
    ),
    image: {
      imagePath: doorToEden,
      altText: `The door to EDEN is an overgrown metal door, three-feet thick, reminiscent of Command Center Charlie.`,
      className: "imageLeft",
      imageCaption: "DOOR TO EDEN",
    },
    image2: {
      imagePath: video,
      altText: `The video tape shows humans exiting a military bunker with thousands of mutant children.`,
      className: "imageRight",
      imageCaption: "THE VIDEO",
    },
  },
  {
    title: "Wrapup",
    text: (
      <p>
        Next session will start with every PC getting 5xp.
        <br />
        <br />
        As we near the campaign's end, it is clear that the population of the
        Sunshine Plaza skyrockets over 200 and will continue to grow as children
        are born and the safety of the Zone is at an all-time high.
        <br />
        <br />
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        .
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects, especially to Doc Gladhand
        </a>
        .
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageRight",
      imageCaption: "",
    },
  },
];

const MYZAnchortownSession63 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">Session 63: EDEN FOUND</h1>
                <p className="lead leadExtra">
                  The Zone prospers, Glib is born, and EDEN is found.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession63;
