export let encounters = {
    'no encounter': {
      'freq':0,'flavor':'Time passes without any significant encounter.',
      'terrain': [{'name': 'plains', 'freq': 18}, {'name': 'forest', 'freq': 18}, {'name': 'dark forest', 'freq': 18}, {'name': 'hills', 'freq': 18}, {'name': 'mountains', 'freq': 19}, {'name': 'lake', 'freq': 20},{'name': 'marshlands', 'freq': 18},{'name': 'quagmire', 'freq': 18},{'name': 'ruins', 'freq': 18}]
    },
    'bloodmist': {
      'freq': 0, 'flavor': 'It all happens almost imperceptibly. At first, all sounds die down, and the only thing that can be heard is your own small talk. Then you get the feeling that the hair on the backs of your necks rises up, as if the air is filled with some strange energy. Finally, you see it, the crimson mist, slowly flowing above the ground towards you. The Blood Mist.',
      'terrain': [{'name': 'plains', 'freq': 1}, {'name': 'forest', 'freq': 1}, {
        'name': 'dark forest',
        'freq': 2
      }, {'name': 'hills', 'freq': 1}, {'name': 'mountains', 'freq': 11}, {'name': 'lake', 'freq': 2
      }, {'name': 'marshlands', 'freq': 1}, {'name': 'quagmire', 'freq': 1}, {'name': 'ruins', 'freq': 1}]
    },
    'duel in the woods': {
      'freq': 0,
      'flavor': 'You hear the sounds of battle and a violent bellowing mixed with battle songs and cries. Further along the road, an orc is dueling with a tattooed elf. They lunge at each other and parry without hitting each other. Occasionally they stop in order to hurl insults at one another.',
      'terrain': [{'name': 'plains', 'freq': 1}, {'name': 'forest', 'freq': 1}, {'name': 'hills', 'freq': 1}]
    },
    'the orcish fugitive': {
      'freq': 0, 'flavor': 'At first you hear a guttural song, “Msshamassh, gukkull, gukkull,” and the sound of a drum beating out the rhythm. Then, you spot a group of orcs walking in a chaotic procession. They are singing a hymn. In the middle of the group, two of the orcs carry a huge wooden stick, where a trussed-up orc hangs dangling and helpless.',
      'terrain': [{'name': 'plains', 'freq': 1}, {'name': 'forest', 'freq': 1}, {
        'name': 'dark forest',
        'freq': 1
      }, {'name': 'hills', 'freq': 1}]
    },
    'death from above': {
      'freq': 0, 'flavor': 'You hear a chilling scream in the air, and the next\n' +
        'moment, the sound of heavy wing beats. Then you\n' +
        'see it. A huge, demon bird is descending from the\n' +
        'clouds. It turns and wheels in the air and dives,\n' +
        'targeting your party.',
      'terrain': [{'name': 'plains', 'freq': 1}, {'name': 'hills', 'freq': 1}, {
        'name': 'mountains',
        'freq': 1
      }, {'name': 'lake', 'freq': 2}, {'name': 'quagmire', 'freq': 1}, {'name': 'ruins', 'freq': 1}]
    },
    "the harpies' feast": {
      'freq': 0, 'flavor': 'At first you hear the screams: “Giiiive meee!,”\n' +
        '“Nooo, giiiive meee!,” “Nooo, I waaant to eeeat\n' +
        'the liiitle ones!” Then you spot three horrible creatures\n' +
        'with eagle-like wings and twisted bodies,\n' +
        'swooping down upon you. Harpies!',
      'terrain': [{'name': 'plains', 'freq': 1}, {'name': 'hills', 'freq': 1}, {'name': 'mountains', 'freq': 1}, {'name': 'lake', 'freq': 2}, {'name': 'marshlands', 'freq': 1}, {'name': 'quagmire', 'freq': 1}, {'name': 'ruins', 'freq': 1}]
    },
    'the horse': {
      'freq': 0, 'flavor': 'You hear the neighing of a horse, and suddenly it\n' +
        'stands before you. A proud stallion, with saddle, bags and a saddle blanket. Its owner is nowhere to be seen.\n' +
        'The lonely horse twists it head, clearly frustrated.',
      'terrain': [{'name': 'plains', 'freq': 1}, {'name': 'forest', 'freq': 1}, {'name': 'ruins', 'freq': 1}]
    },
    'the massacre lure': {
      'freq': 0, 'flavor': 'A terrible scene unfolds in front of you. A dozen\n' +
        'humans are crawling around with their innards\n' +
        'spilled out, some still alive, soaked in blood. A cart\n' +
        'with merchandise is overturned. A few people are\n' +
        'moaning, others screaming. A woman in chainmail\n' +
        'is trying to lift a sword. When a comparatively\n' +
        'healthy young man sees you, he screams hysterically.\n\n' +
        '“Robbers! They took our horses. Do you have\n' +
        'water? Is anyone a healer? No, brother, don’t fall\n' +
        'asleep!”',
      'terrain': [{'name': 'plains', 'freq': 1}, {'name': 'forest', 'freq': 1}, {
        'name': 'hills',
        'freq': 1
      }, {'name': 'mountains', 'freq': 1}, {'name': 'quagmire', 'freq': 1}]
    },
    'the great insulter': {
      'freq': 0, 'flavor': 'A loud and agitated voice cuts through the landscape.\n' +
        'Someone is shouting out mockery and insults.\n' +
        'When you get closer you see a rickety wooden\n' +
        'structure rise several feet above the ground. At\n' +
        'the top a small orc with an upstage look. When\n' +
        'he sees you, he unbuttons his pants and urinates in\n' +
        'your direction, spewing insults all the while.',
      'terrain': [{'name': 'plains', 'freq': 1}, {'name': 'forest', 'freq': 1}, {
        'name': 'quagmire',
        'freq': 1
      }, {'name': 'ruins', 'freq': 1}]
    },
    'the ruins of old': {
      'freq': 0, 'flavor': 'You see before you the broken remains of what was\n' +
        'once a building, a civilized outpost in the wilds.\n' +
        'Now nature has reclaimed the place and everything\n' +
        'that remains are but fallen remnants and\n' +
        'memories lost in the mist of history.',
      'terrain': [{'name': 'plains', 'freq': 1}, {'name': 'forest', 'freq': 1}, {
        'name': 'dark forest',
        'freq': 2
      }, {'name': 'hills', 'freq': 1}, {'name': 'mountains', 'freq': 1}, {
        'name': 'marshlands',
        'freq': 2
      }, {'name': 'quagmire', 'freq': 1}, {'name': 'ruins', 'freq': 3}]
    },
    'the fox': {
      'freq': 0, 'flavor': 'A strange song echoes over the land. A growly\n' +
        'male voice sings of purple flowers and deep tombs\n' +
        'where heaven and earth meet. You have never\n' +
        'heard a song such as this before. As you pass a turn\n' +
        'in the road, you suddenly find yourselves eye to eye\n' +
        'with a small fox. It stares at you with large eyes.\n' +
        'The song has ended.',
      'terrain': [{'name': 'plains', 'freq': 1}, {'name': 'forest', 'freq': 1}, {
        'name': 'dark forest',
        'freq': 1
      }, {'name': 'quagmire', 'freq': 1}]
    },
    'the forgotten prince': {
      'freq': 0, 'flavor': 'A large pile of heavy rocks, stacked one atop the\n' +
        'other in a monument that you recognize. Someone\n' +
        'has obviously spent a lot of effort building\n' +
        'the cairn, and someone or something is buried\n' +
        'beneath it.',
      'terrain': [{'name': 'plains', 'freq': 1}, {'name': 'forest', 'freq': 1}, {
        'name': 'dark forest',
        'freq': 1
      }, {'name': 'hills', 'freq': 1}, {'name': 'mountains', 'freq': 1}, {
        'name': 'marshlands',
        'freq': 1
      }, {'name': 'quagmire', 'freq': 1}, {'name': 'ruins', 'freq': 1}]
    },
    'the hungry robbers': {
      'freq': 0, 'flavor': 'A group of unwashed humans, wearing worn\n' +
        'clothes and all sorts of simple weapons, steps out\n' +
        'on the road. They are dirty and look like they\n' +
        'haven’t eaten properly for a number of days. A\n' +
        'large man with a black beard steps forward,\n' +
        'spits, and puts his hand on his sword. “Wot manner\n' +
        'o’ dirt are ya? Wot are ya doin’ ’ere? Dontcha\n' +
        'know who I am?”',
      'terrain': [{'name': 'plains', 'freq': 1}, {'name': 'forest', 'freq': 1}, {
        'name': 'dark forest',
        'freq': 1
      }, {'name': 'hills', 'freq': 1}, {'name': 'mountains', 'freq': 1}, {
        'name': 'marshlands',
        'freq': 2
      }, {'name': 'quagmire', 'freq': 1}, {'name': 'ruins', 'freq': 1}]
    },
    'the plague brother': {
      'freq': 0, 'flavor': 'You find the decayed remains of an unfortunate\n' +
        'wanderer in front of you. The ravens and wild\n' +
        'animals have already done their part and the\n' +
        'body is just about hacked to pieces.',
      'terrain': [{'name': 'plains', 'freq': 1}, {'name': 'forest', 'freq': 1}, {
        'name': 'dark forest',
        'freq': 1
      }, {'name': 'hills', 'freq': 1}, {'name': 'mountains', 'freq': 1}, {
        'name': 'marshlands',
        'freq': 1
      }, {'name': 'quagmire', 'freq': 1}, {'name': 'ruins', 'freq': 1}]
    },
    'flagellants': {
      'freq': 0, 'flavor': 'It’s hard to tell if it is the sound of whips and\n' +
        'screams or if it is the banging of drums and chanting\n' +
        'of holy words you first notice. A large procession\n' +
        'approaches. Dressed in black and wrapped\n' +
        'in blackberry thorns, they whip and beat themselves. Several of them have cut off their tongues\n' +
        'or ears, some have picked out their own eyes. At\n' +
        'the front of the line is a group of women dressed in\n' +
        'red, they carry a big plate with burning incense.\n' +
        'One of them holds a pole with a strange iron symbol.\n' +
        'In the middle of the crowd, there is a wooden\n' +
        'construction on wheels. From ropes, naked people\n' +
        'hang with hooks attached under the skin. Chained\n' +
        'slaves push the structure through the mud.',
      'terrain': [{'name': 'plains', 'freq': 1}, {'name': 'hills', 'freq': 1}, {'name': 'quagmire', 'freq': 1}]
    },
    'beggars': {
    'freq': 0, 'flavor': 'You see them from afar, a crowd of hungry and\n' +
        'weary wanderers dressed in rags. The beggars surround you with outstretched hands, saying\n' +
        'nothing.',
      'terrain': [{'name': 'plains', 'freq': 1}]},
    'the dwarf balloon pilot': {
      'freq': 0, 'flavor': 'At the distance, you spot a green cocoon up in a\n' +
        'tree. When you get closer you see that it is an air\n' +
        'balloon stuck at the top of the tree.',
      'terrain': [{'name': 'plains', 'freq': 1}, {'name': 'forest', 'freq': 1}, {'name': 'marshlands', 'freq': 1}, {'name': 'quagmire', 'freq': 1}]
    },
    'wedding': {
    'freq': 0, 'flavor': 'In the distance you hear song and dance; human\n' +
        'voices and deep voices of orcs. They sing songs of\n' +
        'joy. As you get closer you see a wooden stage decorated\n' +
        'with flowers in the center of a glade. Tables\n' +
        'with food and drinks. Musicians with violins and\n' +
        'accordions. And at the edge of the glade stands an\n' +
        'orc woman and a human man dressed in white.',
      'terrain': [{'name': 'forest', 'freq': 1}]},
    'the demon baker': {
      'freq': 0, 'flavor': 'When the wind turns you feel the smell of freshly\n' +
        'baked bread. As you approach you see a small stone\n' +
        'house nestled in vegetation. There are no trails, pastures\n' +
        'or fields. Heavy smoke rises from the chimney\n' +
        'and light glows in the small square windows.',
      'terrain': [{'name': 'forest', 'freq': 1}, {'name': 'dark forest', 'freq': 1}, {'name': 'marshlands', 'freq': 1}]
    },
    'the animal sanctum': { 'freq': 0, 'flavor': 'When the darkness spreads and your fire is burning\n' +
        'you see the first couple of eyes in the trees. At\n' +
        'first there is just a few, but more and more eyes appear, more than you can count. You are surrounded.\n' +
        'Owls and other woodland creatures\n' +
        'move between the trees, mice running over your\n' +
        'backpacks. Worms crawl at your feet and birds sit\n' +
        'on your axes and swords. A deer stands a few feet\n' +
        'away, next to it a fox. The animals approach you\n' +
        'slowly.',
      'terrain': [{'name': 'dark forest', 'freq': 1}]},
    'the burial procession': { 'freq': 0, 'flavor': 'Something very large is approaching, giving off a\n' +
        'sound you have never heard before. Dull, drawnout\n' +
        'horn signals are shaking the very air. Creaking\n' +
        'and snapping sounds create a rhythm around\n' +
        'howls cutting the air in plaintive cascades. After\n' +
        'a while five walking trees appear, carrying a sixth\n' +
        'between them. They take one step, sound off, take a few more steps, halt and then sound off again in\n' +
        'a slow procession.',
      'terrain': [{'name': 'dark forest', 'freq': 1}]},
    'the vengeful spirit': {
      'freq': 0, 'flavor': 'Quiet! Can you hear it? A scream? A voice? You\n' +
        'look at each other, but the landscape is deserted.\n' +
        'Then suddenly, you feel an ice-cold wind blowing\n' +
        'through your souls. A gray, shimmering shape\n' +
        'forms in the middle of your party, with a once\n' +
        'human face twisted in an unnatural scream. You have heard the stories around the campfires\n' +
        'before. A lost soul, a spirit unable to come to rest.',
      'terrain': [{'name': 'dark forest', 'freq': 1}, {'name': 'hills', 'freq': 1}, {
        'name': 'mountains',
        'freq': 1
      }, {'name': 'marshlands', 'freq': 1}, {'name': 'quagmire', 'freq': 1}, {'name': 'ruins', 'freq': 2}]
    },
    'the glassblower': {'freq': 0, 'flavor': 'In the distance, you hear a beeping sound every\n' +
        'time the wind take hold of the trees. It sounds like\n' +
        'a huge flute. A glass structure rises above the landscape;\n' +
        'corridors, openings, spirals and tunnels all\n' +
        'made of glass. In the middle of the building stands\n' +
        'a dwarf, illuminated by flames from a fireplace.\n' +
        'The dwarf lifts the tip of a long metal rod from the\n' +
        'fire, it is covered in a pulp of glowing glass. She puts\n' +
        'the other end to her lips and with a heavy breath the\n' +
        'pulp transforms in to a long winding tube of glass.',
      'terrain': [{'name': 'hills', 'freq': 1}, {'name': 'mountains', 'freq': 1}]},
    'the puppy': { 'freq': 0, 'flavor': 'You hear a slight squeak, and then something resembling\n' +
        'a bark. Beneath a bush a tail is sticking\n' +
        'out. It’s a puppy dog watching you with large eyes.',
      'terrain': [{'name': 'mountains', 'freq': 1}, {'name': 'quagmire', 'freq': 1}]},
    'the cursed orge': {
      'freq': 0, 'flavor': '“Toil ‘n’ trouble, trouble ‘n’ toil.” A deep, guttural\n' +
        'voice echoes across the land. The next moment,\n' +
        'you see a large and muscular ogre dragging\n' +
        'a sack. Something is kicking inside the sack,\n' +
        'causing the ogre to bash the sack repeatedly. The\n' +
        'movements end immediately. “Shut up, you, I\n' +
        'said!”',
      'terrain': [{'name': 'forest', 'freq': 1}, {'name': 'dark forest', 'freq': 1}, {'name': 'hills', 'freq': 1}, {'name': 'mountains', 'freq': 1}, {'name': 'ruins', 'freq': 1}]
    },
    'candlemakers': { 'freq': 0, 'flavor': 'Outside a cabin, two women, one old and one\n' +
        'younger, stand next to a cauldron with burning\n' +
        'logs underneath. Next to it, blue candles hang\n' +
        'from a stand. The women look worried, staring\n' +
        'into the forest.',
      'terrain': [{'name': 'dark forest', 'freq': 1}]},
    'forest fire': { 'freq': 0, 'flavor': 'The smell of smoke hits you as a deer rushes by.\n' +
        'Over the trees you see black smoke. The wind\n' +
        'turns, and the fire is coming your way.',
      'terrain': [{'name': 'forest', 'freq': 1}]},
    'black sand': { 'freq': 0, 'flavor': 'The forest opens up and you look upon a giant\n' +
        'glade surrounded by trees. The ground is covered\n' +
        'by black sand. You see no plants. You see\n' +
        'some rocks that could be the remains of a house.\n' +
        'Someone is sitting in the middle of the glade,\n' +
        'a young girl with her eyes fixed on the ground.\n' +
        'It reeks of sulfur. The sand rattles as the wind\n' +
        'blows through the glade.',
      'terrain': [{'name': 'dark forest', 'freq': 1}]},
    'the empty house': { 'freq': 0, 'flavor': 'At a distance, smoke from a chimney can be seen.\n' +
        'A small cottage nestled between tall trees. On a\n' +
        'stand of sticks laundry hangs to dry. Next to the\n' +
        'building there is a small fenced enclosure, but no\n' +
        'animals can be seen. A small field is surrounded\n' +
        'by rocks picked from its soil. Green stems stand in\n' +
        'straight lines. You hear a ripple from a stream.',
      'terrain': [{'name': 'forest', 'freq': 1}]},
    'the rustle of bones': {
      'freq': 0, 'flavor': 'They appear without warning. A group of once\n' +
        'proud human warriors. Now they are nothing\n' +
        'but crumbling skeletons, which for some reason\n' +
        'have been given unholy life. They are totally silent,\n' +
        'but when they start to move you hear the dry sound of centuries-old bones, creaking against each other.\n' +
        'The grinning skulls of the skeletons open their\n' +
        'jaws when they attack, emitting a silent scream.',
      'terrain': [{'name': 'dark forest', 'freq': 1}, {'name': 'marshlands', 'freq': 1}, {'name': 'quagmire', 'freq': 1}, {'name': 'ruins', 'freq': 1}]
    },
    'teramalda': {
      'freq': 0, 'flavor': 'The smell of burning meat reaches your noses,\n' +
        'and at the same time you hear heavy footsteps.\n' +
        'Something is making its way through the greenery.\n' +
        'The creature is making a terrible racket,\n' +
        'squeaking and clattering. The very ground is\n' +
        'shaking. As it emerges from the greenery in front\n' +
        'of you, stands a massive knight, completely covered\n' +
        'by ancient, rusty black armor. The breastplate\n' +
        'has a sign hammered into it. The creature\n' +
        'carries a sword, brown hued with dried blood.',
      'terrain': [{'name': 'plains', 'freq': 1}, {'name': 'dark forest', 'freq': 1}, {'name': 'marshlands', 'freq': 1}, {'name': 'quagmire', 'freq': 1}, {'name': 'ruins', 'freq': 1}]
    },
    'the stone singers': {
    'freq': 0, 'flavor': 'A strange, gigantic and weather-beaten structure\n' +
        'rises from the landscape. It glistens vaguely, as if\n' +
        'made of metal mixed with stone. The construction\n' +
        'is not a building and doesn’t serve any purpose you\n' +
        'can imagine. Next to it is a group of dwarves, busy\n' +
        'with measuring tools and maps. You are spotted by\n' +
        'an armored guard with an axe who points at you.',
      'terrain': [{'name': 'hills', 'freq': 1}, {'name': 'mountains', 'freq': 1}]},
    'rats': { 'freq': 0, 'flavor': 'A chittering sound makes you look down the hill\n' +
        'and see thirty rats moving on top of something\n' +
        'wrapped in white pieces of cloth. Only when the\n' +
        'little bundle starts to scream, you realize that the\n' +
        'rats have found a living baby.',
      'terrain': [{'name': 'hills', 'freq': 1}, {'name': 'mountains', 'freq': 1}]},
    "the minotaur's lament": { 'freq': 0, 'flavor': 'A harrowing roar echoes throughout the land. It is\n' +
        'a howl filled with enormous anger – and sorrow.\n' +
        'After the next bend in the road, you are met by\n' +
        'a terrible scene: four soldiers lie slain in a violent\n' +
        'battle and towering above the corpses is a bloody\n' +
        'and sobbing minotaur. The beast is leaning over\n' +
        'another minotaur, killed by about a dozen arrows.\n' +
        'The minotaur roars its sorrow towards the\n' +
        'sky. Then it turns towards you.',
      'terrain': [{'name': 'hills', 'freq': 1}, {'name': 'mountains', 'freq': 1}]},
    'the restless dead': {
      'freq': 0, 'flavor': 'A company of jesters are traveling along the road,\n' +
        'some on foot and others riding in carts. Many of\n' +
        'them are gaudily painted and dressed in comical\n' +
        'garb, although this place in the wilderness is not\n' +
        'exactly the ideal location for a performance. The\n' +
        'men and women escorting the troupe on horseback\n' +
        'greet you in a tense manner. None of them are\n' +
        'wearing make-up or body paint.',
      'terrain': [{'name': 'plains', 'freq': 1}, {'name': 'forest', 'freq': 1}, {'name': 'hills', 'freq': 1}, {'name': 'quagmire', 'freq': 1}, {'name': 'ruins', 'freq': 1}]
    },
    'the sleepy troll': { 'freq': 0, 'flavor': 'A terrible roar shatters the silence of the land.\n' +
        'Then you notice the stench. In front of you, the\n' +
        'rocks of the mountain appear to have come alive.\n' +
        'A huge, gray, mottled form emerges from the side\n' +
        'of the mountain, staring at you with cold, yellow eyes. Heavy rocky arms are raised in the air, and\n' +
        'you hear another roar. “Who arrre you, little\n' +
        'creeeeps disturbing Karrrg?”',
      'terrain': [{'name': 'mountains', 'freq': 1}]},
    'the furless': { 'freq': 0, 'flavor': 'A group of creatures lurks among the rocks. Four\n' +
        'people approach, all wrapped in cloth and armed.\n' +
        'The leader growls like a wolfman, but his face is\n' +
        'furless.',
      'terrain': [{'name': 'mountains', 'freq': 1}]},
    'road block': { 'freq': 0, 'flavor': 'The narrow path winds forward along the side of\n' +
        'the mountain. When you pass a turn, you see a pass\n' +
        'with steep cliffs high above both sides of the path.\n' +
        'Someone has turned a wagon on its side blocking\n' +
        'the path ahead. The wagon is filled with barrels\n' +
        'and sandbags. There is no visible movement and\n' +
        'no sound. There is no way around the roadblock.',
      'terrain': [{'name': 'mountains', 'freq': 1}]},
    'the infected': {
      'freq': 0, 'flavor': 'You see a small boat floating around without oars.\n' +
        'In it is a pile of blankets. The blankets are moving,\n' +
        'there is something underneath.',
      'terrain': [{'name': 'lake', 'freq': 2}, {'name': 'marshlands', 'freq': 1}]
    },
    'the stowaway': { 'freq': 0, 'flavor': 'A shiny white sail can be seen, and then another.\n' +
        'In front of you, a bulky wooden ship appears on the\n' +
        'lake. You spot a half dozen people at work on the\n' +
        'deck. One of them waves at you with his hat.',
      'terrain': [{'name': 'lake', 'freq': 4}]},
    'death from below': {
      'freq': 0, 'flavor': 'Suddenly, it seems like the water around your vessel\n' +
        'has started to boil. Something is moving below, something\n' +
        'big. The next moment, the ship tilts over due to\n' +
        'a hit from below, and you nearly fall into the water.\n' +
        'After that, everything happens fast. At first one,\n' +
        'then two, and then three giant tentacles rise up from\n' +
        'both sides. They grip the vessel, preparing to take it\n' +
        'beneath the water’s surface. The monster beneath\n' +
        'obviously doesn’t want you to travel in peace.',
      'terrain': [{'name': 'lake', 'freq': 2}, {'name': 'marshlands', 'freq': 1}]
    },
    'the trader of souls': {
      'freq': 0, 'flavor': 'A small sailboat slowly slides through the water.\n' +
        'Ten oil lamps illuminate the boat, a lyre is playing,\n' +
        'and in the top of the mast a colorful pennant flies.\n' +
        'There are three people in the boat.',
      'terrain': [{'name': 'lake', 'freq': 2}, {'name': 'marshlands', 'freq': 1}]
    },
    'the lizard hunters': { 'freq': 0, 'flavor': 'A strange clucking sound can be heard. It is echoed\n' +
        'by more clucking sounds from another direction.\n' +
        'And from a third direction. In the mist, you spot\n' +
        'a number of odd creatures moving towards you.\n' +
        'Grey-green scaly skin, sharp teeth and yellow eyes.\n' +
        '“Piiigs?” someone hisses. “Laaarge piiigiiies,”\n' +
        'from another hissing voice. “Biiig, uglyyy piiigs,\n' +
        'can youuu speeaak?”',
      'terrain': [{'name': 'marshlands', 'freq': 2}, {'name': 'quagmire', 'freq': 1}]},
    'buried alive': {'freq': 0, 'flavor': 'You hear screams in the distance, tormented cries\n' +
        'of agony. When you get closer you see an open area\n' +
        'between some abandoned stone houses. Seven elves\n' +
        'armed with swords and bows stand in front of a\n' +
        'deep pit. They all wear blue feathers in their hair.\n' +
        'In the pit there is an elven family, a woman, a\n' +
        'man and two children. They call for help but they\n' +
        'don’t try to climb out of the pit. Two of the elves\n' +
        'standing at the pit are using shovels to bury the\n' +
        'family alive.',
      'terrain': [{'name': 'ruins', 'freq': 1}]
    }
  }
  let terrainList = ['plains', 'forest', 'dark forest', 'lake', 'mountains', 'hills',
      'ruins', 'marshlands', 'quagmire']

  export {encounters as default, terrainList}