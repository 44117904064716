import React from "react";
import "./App.css";
// import { Worker, Viewer } from '@react-pdf-viewer/core';
import SmokeRavenNavbar from "./js/components/Navigation";

// Simple React Lightbox
// https://www.npmjs.com/package/simple-react-lightbox
function App() {
  return (
    <div className="App">
      {/*<Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js"></Worker>*/}
      <SmokeRavenNavbar />
    </div>
  );
}

export default App;
