import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import logo from "../../../../src/assets/forbidden-lands/forbidden-lands-logo.webp";
import sectionDivider from "../../../../src/assets/forbidden-lands/session01/bitter-reach-page-banner-session-01.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";
import "./forbidden-lands-styles.scss";

import legend from "../../../../src/assets/forbidden-lands/session01/bata-tells-the-legend-of-baraggor.webp";
import library from "../../../../src/assets/forbidden-lands/session01/library-of-gomsten.webp";
import baraggor from "../../../../src/assets/forbidden-lands/npcs/baraggor-portrait.webp";
import volle from "../../../../src/assets/forbidden-lands/npcs/marshall-volle.webp";
import berry from "../../../../src/assets/forbidden-lands/npcs/berry-wight.webp";
import protection from "../../../../src/assets/forbidden-lands/session01/adequate-protection-01-anorak.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "1",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Carter One",
  backgroundColor: "rgba(0, 0, 0, 1) ",
  color: "white",
  marginBottom: "15px",
};

const FBLSession01 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  const section1Data = [
    {
      title: "Freedom Day",
      anchor: "freedom-day",
      sectionTitleCssName: "sectionTitleFBL",
      text: (
        <div className="fblBodyText">
          Since the red mist in Ravenland has begun to fade, the king of
          Alderland is forming new plans; plans that include shutting down the
          <span style={{ color: "red" }}> prison island of Gomsten</span>. The
          inmates are excitedly going about their tasks to tear town the prison,
          stow goods the guards have deemed worthy of transport off the island,
          and all the work that goes into shutting down an age-old institution.
          <br />
          <br />
          <span style={{ color: "burlywood" }}>Blisster</span> is working at the
          barn on his own to pack up farming tools and seeds. Among the cattle
          feed in the barn he retrieves a book about sleight of hand tricks. He
          thinks about how he stole the book from the island library without
          regret. His pride after all is that he survives because he can steal
          anything.
          <br />
          <br />
          <ImageComponent
            image={library}
            caption="Prison Library"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="The library of Gomsten Island prison. It has been run by the venerable inmate known as Bata (Batachikhan) of Alderland for longer than any in the prison can remember. "
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <span style={{ color: "burlywood" }}>Lobo</span> and{" "}
          <span style={{ color: "burlywood" }}>Rog-Golar</span> are at the
          prison library helping pack books and scrolls. The library is
         a large tent gazebo with open sides facing the jungle all
          around. Lobo and Rog follow the direction of Gomsten's most venerable
          prisoner, an{" "}
          <span style={{ color: "burlywood" }}>Alderlander named Bata</span>{" "}
          (more properly Batachikhan). Rog-Golar is looking out at the jungle
          trees dreaming of freedom. He has hated the way prisoners are treated
          on Gomsten. From the edge of the jungle Rog-Golar sees four figures
          approaching. Walking with a telltale shuffle is the leader of the
          gang, a charismatic but deplorable{" "}
          <span style={{ color: "burlywood" }}>
            drug-dealer known as Wolftrap
          </span>
          . Wolftrap has a long, heavy chain wrapped over his shoulders and
          smiles smugly as he catches Rog-Golar's eye. Wolftrap's friends are
          also spoiling for a fight:{" "}
          <span style={{ color: "burlywood" }}>Sulien</span> (a dark-skinned
          Alderlander), <span style={{ color: "burlywood" }}>Borlang</span> (a
          pale human from Ravenland), and{" "}
          <span style={{ color: "burlywood" }}>Vitra</span> (a tense, jittery
          half-elf Frailer from Alderland). Wolftrap calls out as his gang
          approaches the library:
          <br />
          <br />
          Wolftrap:{" "}
          <span style={{ color: "lightgreen", fontStyle: "italic" }}>
            "I've got a little deal worked out to make sure your supplies come
            to me when you're lost and miss getting out of here. The Bitter
            Reach is a tough place; I'll need all the gear and coin I can muster
            eh?"
          </span>
          <br />
          <br />
          As violence breaks out in the library, we make SCOUT rolls to
          determine when and if Blisster hears the ruckus from the nearby barn.
          Once Blisster realizes what is going on, he stashes his book on
          sleight of hand in the belted prison tunic and races through the
          jungle toward the sounds of violence.
          <br />
          <br />
          Wolftrap and his minions focus their beatdown on Bata, hoping that by
          breaking the elder librarian they can negotiate the surrender of the
          others. The combat is all fisticuffs and kicks except for the chain
          wielded by Wolftrap.
          <br />
          <br />
          This combat gives us a chance to really look at some of the flavor
          moves such as GRAPPLE, DISARM, and so forth. The natural claws of the
          wolfkin give Lobo an advantage that he uses when he strikes a breaking
          blow to Wolftrap. With the EXECUTIONER talent, Lobo goes for the worst
          of two critical injuries, heedless of the consequences of perhaps
          using excessive self-defense the day before all the prisoners are to
          be set free. The worst of the two injuries slashes Wolftrap's throat.
          Guards pile into the library screaming "get on the ground" as Wolftrap
          lies glassy-eyed in a widening pool of blood.
          <br />
          <br />
          Lobo is taken away by the guards and Rog-Golar and Blisster are left
          to tend Bata's wounds and continue packing the library.
          <br />
          <br />
          Bata bemoans the limited cargo space the authorities has given him for
          books. <br />
          <br />
          <ImageComponent
            image={legend}
            caption="Legend of Baraggor"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="<h4>LEGEND OF BARAGGOR</h4><i>General Baraggor is one of the greatest soldiers of Alderland. 
            She is the foremost demonslayer Alderland has ever seen, and it is said that she has saved the 
            life of the king no less than three times. Once she threw herself between the king and a dragon and slew it with a broken spear!<br/><br/>
            Her soldiers idolize her and fear her in equal amounts, and it is said that the king fears that soldiers’ love for their general could be a threat to him. That is why she has been sent to the Bitter Reach, far from Alderland.</i>"
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>{" "}
          Bata:{" "}
          <span style={{ color: "lightgreen", fontStyle: "italic" }}>
            "How can I choose among all this knowledge? You know I want to open
            a bookstore in the Bitter Reach? It is cold and brutal and I think
            knowledge can help that land. It is difficult to believe I will be
            free; that we will all be free tomorrow. You do know who is coming
            for us yes?"
          </span>
          <br />
          <br />
          Bata then tells the LEGEND OF BARAGGOR to his small audience (see
          picture, click for popup with full legend).
          <hr className="hrForbiddenLands" />
          The following morning, all of the inmates are made to bring cargo to
          the beach then kneel in the sand in a long line.
          <br />
          <br />
          There is much anticipation as General Baraggor and her ships arrive in
          the bay of Gomsten Island. Two large ships appear from the icy morning
          fog blanketing the ocean. They drift toward the shore like hulking
          wraiths floating slowly toward a downed victim. One of the ships drops
          anchor and the other, slightly larger, comes further into the bay
          before dropping anchor. The black warship towers above the kneeling
          prisoners on the shore, even from a such a distance. Black sails flap
          in the wind and the calls of sailors climbing in the rigging echo over
          the ice-cold sea. The bow is adorned with an obscenely large battering
          ram and on the forecastle as well as two enormous bolt throwers. On
          the quarterdeck at the stern of the ship there is a figure dressed in
          purple and red, obviously in charge. Soldiers clad in the colors of
          Alderland gather at the icy railing to watch the guards and prisoners
          on the shore as well as the surrounding ocean. Half a dozen ships are
          dropped into the water and row ashore.
          <br />
          <br />
          Standing tall on one of the longboat's is a woman in purple and red.
          Bata whispers, "this is Captain Baraggor. She pilots that dread black
          ship there, Wyrm's Fist."
          <br />
          <br />
          The warden of Gomsten meets Captain Baraggor at the edge of the surf.
          Baraggor is a soldier through and through. She’s always dressed in a
          manner befitting her station, armed and ready for battle. Still, she
          appreciates worldly beauty and has a penchant for fine clothes and
          flamboyant embellishments. Her face is grim, and she has a large scar
          from forehead to her jaw, a permanent reminder of an injury that
          almost claimed her life. She walks with a slight limp.
          <br />
          <br />
          <ImageComponent
            image={baraggor}
            caption="Baraggor"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody=""
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          Baraggor steps forward to address all who are assembled.
          <br />
          <br />
          Baraggor:{" "}
          <span style={{ color: "lightgreen", fontStyle: "italic" }}>
            "By decree of the king of Alderland, the prison on Gomsten Island is
            to be closed. You wretches will be taken to the Silver Coast in the
            Bitter Reach. Our destination is Northfall.
            <br />
            <br />
            You will be prisoners aboard Wyrm's Fist (she gestures toward the
            ship); nothing more than dangerous cargo. We will tolerate no
            fighting, blackmarketing, or backtalk during the journey. You are to
            ride quiet, hold onto your guts, and pray for your deliverance.
            <br />
            <br />
            It should take us four or five days to get to Northfall. Once we
            reach the coast, you will be remanded to the local authorities led
            by Marshall Volle of Northfall. You will be given some basic
            supplies according to your profession and some small amount of
            money. You’ll be unskilled workers under the eye of Marshall Volle
            in the town of Northfall. You’ll survive; for a time, but it is a
            hellish wasteland filled with ice and death.
            <br />
            <br />
            Your years on Gomsten will not have prepared you for such a
            dangerous land. Keep your wits about you, your ears open, and your
            reflexes sharp; if you do, you might live long enough to outrun your
            criminal legacy and start a new life.
            <br />
            <br />
            You need to thank Wyrm for Alderland’s generosity in shipping you to
            a place where you have a chance. In the Bitter Reach there are no
            royal or religious chains. But, the price of that freedom is steep;
            I do not envy you your journey among the heathens of the North. It
            is my duty to execute the will of Alderland, and the King has
            spoken; you will be set free to live your lives as you see fit. May
            Wyrm have mercy on your soul and dispatch you quickly."
          </span>
          <hr className="hrForbiddenLands" />
          Baraggor has Lobo brought forward to kneel before her in the sand. She
          speaks loudly, "This one called Lobo has been found a murderer. He
          killed the inmate Wolftrap just one day before freedom arrived. Will
          anyone here speak for him before justice is delivered?"
          <br />
          <br />
          Rog-Golar steps forward. Guards move to block the hulking orc, but
          Baraggor motions him forward. Rog-Golar speaks of Wolftrap's intended
          violence toward Bata, the most respected prisoner on the island. He
          activates his pride in defending others to get the successes needed to
          get amnesty for Lobo. The wolfkin will be part of the party sailing to
          the Silver Coast after all.
          <br />
          <br />
          <a href="#pageTop">Back to Top</a>
        </div>
      ),
    },
    {
      title: "Sailing Away",
      anchor: "freedom-day",
      sectionTitleCssName: "sectionTitleFBL",
      text: (
        <div className="fblBodyText">
          The elation of leaving the prison island of Gomsten behind is tempered
          by the fact that the players are still prisoners aboard Wyrm's Fist.
          They find themselves in wooden cells belowdecks, dressed in rags. They
          all hold to the dream of freedom, gear, and silver promised by
          Alderland once Wyrm's Fist reaches the town of Northfall on The Silver
          Coast of the Bitter Reach.
          <br />
          <br />
          Wyrm's Fist is aptly named; the ship punches into icy waves that crash
          violently against the hull like angry water elementals. The one time
          per day the prisonerl are given time on deck for "fresh air" nearly
          results in hypothermia from the chilling sprays and cold winds. The
          players do gather a couple of interesting pieces of information during
          the voyage.
          <br />
          <br />
          1) Only a few rare souls can navigate the oceans due to unnatural
          weather, confusing fogs, and seemingly shifting shorelines and
          unpredictable weather.
          <br />
          <br />
          2) Rumors claim the Blood Mist of Ravenland is fading. This could be
          related to why Gomsten has been closed. Perhaps Alderland is preparing
          to face off against their kin from Ravenland, or perhaps they will be
          concentrating on the impact on trade and travel as the land becomes
          safer to travel.
          <br />
          <br />
          <a href="#pageTop">Back to Top</a>
        </div>
      ),
    },
    {
      title: "Arrival in Northfall",
      anchor: "freedom-day",
      sectionTitleCssName: "sectionTitleFBL",
      text: (
        <div className="fblBodyText">
          <ImageComponent
            image={protection}
            caption="Adequate Protection"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Without adequate protection, freezing to death is a certainty in the Bitter Reach."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          After days, Wyrm's Fist is wending its way through the narrow Raven
          Straits of the Bitter Reach. The town of Northfall appears on the
          horizon, poised on a cliff like a precarious waddlerbird egg on the
          edge of a black, stony nest.
          <br />
          <br />
          Baraggor boards the longboat to shore, riding with the newly free
          prisoners.
          <br />
          <br />
          Baraggor:{" "}
          <span style={{ color: "lightgreen", fontStyle: "italic" }}>
            “Watch your step, tenderfeets! What you see is Northfall, a
            backwater populated by scum and bandits. But it’s still the beating
            heart of the Bitter Reach, this is where you find warmth and friends
            – because outside Northfall everything is worse than you can
            imagine.”
          </span>
          <br />
          <br />
          Baraggor leans against the gunwale of the longboat, her breath puffing
          out in small clouds of frost.{" "}
          <span style={{ color: "lightgreen", fontStyle: "italic" }}>
            “What will you do now? Hunt Pikebeast? Gather silver eggs from the
            waddlerbirds? Mine the elves’ riches from the tundra? Walk the
            mighty glaciers? One thing is sure … your noblest parts will freeze
            like they’ve never frozen before!”
          </span>
          <br />
          <br /> Baraggor's boisterous laugh leads as the prisoners follow her,
          stepping off the boat onto the dark, rocky beach of Northfall. Ahead
          is a mishmash of dwellings made from skins, snow, ice and stone. The
          main exception is a stronghold occupied by Ingmarg, the merchant
          prince on the hill above. The legendary peddler has built his home out
          of stone and timber to house his treasures, his henchmen and the trade
          barons that currently have his trust. Compared to the cramped island
          and confinement left behind, Northfall teems with life. Then a tall
          man wrapped in furs with a broadsword at his side shouts, "that's good
          enough right there; line 'em up."
          <br />
          <br />
          The ex-prisoners are marched up the Stone Staircase to the upper level
          of Northfall. The narrow stone steps are black as midnight and as
          trecherous as a starving rogue. Ropes are fixed along the stairway to
          assist, but it's clear than on a bad night with the right mix of drink
          or distraction that one could die trying to navigate the staircase.
          <br />
          <br />
          <ImageComponent
            image={volle}
            caption="Marshall Volle"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody=""
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          The party is marched through the strees of Northfall toward Marshall
          Volle's jail. The locals barely throw the newcomers a glance; they
          have seen others like them arrive, and they have seen them leave
          Northfall hunting for treasure, never to return. Some of the villagers
          may know of these newcomers are from Gomsten prison, but if they care
          it is hard to see it in their faces. One bent old woman with vertical
          lines tattooed on her chin is standing next to a pikebeast carcass
          peeling away the meat of the beast. She glances away as the freed
          prisoners walk past, she waves her hand in a gesture that seems to
          indicate they are worth no more bother than the skin of the pikebeast
          she tosses aside.
          <br />
          <br />
          Marshall Volle:{" "}
          <span style={{ color: "lightgreen", fontStyle: "italic" }}>
            "Welcome to the Bitter Reach. Until this moment, you have been known
            to myself and the town council as the Gomsten Nine. Now, you are
            just nine more souls in the frozen north. I don't care where you
            came from; what you did; what you did not do. From this day forward
            it is only who you are when inside the walls of Northfall that I
            care about. I will give you a brief walkaround Northfall. When we
            get back here you will receive your items as put forth by the
            officials of Alderland. Let's walkabout cheechakos."
          </span>
          <br />
          <br />
          <ImageComponent
            image={berry}
            caption="Berry Wight"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Berry is the rare halfling in the Bitter Reach. He keeps warm with copious amounts of alcohol. Berry trades tells, both tall and small, for a drink at Cuss & Dangs."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>{" "}
          The players got a brief tour of Northfall and their silver and gear
          are returned. One sour-smelling guard, his face gleaming with some
          kind of translucent gel, seems to be on friendly terms with Wolftrap's
          old friends Vitra, Sulien, and Borland; talking to the trio outside
          the jail and sharing a brief, harsh laugh as Vitra glares at Lobo,
          Blisster, and Rog-Golar.
          <br />
          <br />
          The party is invited to camp in the old winter elf ruins. They are
          given a two-week voucher for food at Cuss & Dang's Inn and some
          torches so they may start a fire each night in the ruins. In the
          Bitter Reach, even within the boundaries of Northfall one can go to
          sleep never to wake without a proper fire.
          <br />
          <br />
          The players begin working during various QUARTER DAY shifts to earn
          money. They understand they any adventure outside the walls of
          Northfall require winter clothing, ammunition, food, and supplies. The
          work includes gathering waddlerbird eggs, butchering pikebeats for
          boss Khurkan, and working as deputies for the town guard. Some
          interesting events include:
          <br />
          <br />
          <ul>
            <li>
              Lobo chops his fingers at nearly the same rate as the pikebeast
              carcasses. Khurkhan is amused and busts the wolfkin's chops about
              his terrible butchering skills.
            </li>
            <li>
              Blisster notices that the old Winter Elf lighthouse glows with
              multiple colors in the late hours of the dark night. He talks to a
              villager at the top of the Staircase one night who warns no one
              ever enters the lighthouse ruins.
            </li>
            <li>
              Rog-golar solves a dispute between sons of a dead whaler over a
              boat by urging them to figure out an arrangement before he divedes
              the boat into two equal pieces with his axe. Orc justice for the
              win!
            </li>
            <li>
              Rog-Golar meets a halfling drunk named Berry Wight that begs for a
              drink, explains the need for warm clothes as well as strong drink
              and invites Rog and his friends to meet him at Cuss & Dangs
              anytime.
            </li>
          </ul>
          <a href="#pageTop">Back to Top</a>
          <br/><br/>
          <small>Attendees: Blisster, Lobo, Rog-Golar</small><br/>
          <small>Ending Datetime: 6am, Winterwane 35, 1165</small>
          <h5>XP</h5>
          <ul>
            <li>Session 1.0 (Blisster, Lobo, Rog-Golar)</li>
            <li>Legend: 1.0 (Baraggor by Batu)</li>
            <li>Lore: 0.0</li>
            <li>Hex: 0.0</li>
            <li>Pride: 0.0</li>
            <li>Treasure: 0.0</li>
            <li>Monster: 0.0</li>
            <li>Dark Secret: 0.0</li>
          </ul>
        </div>
      ),
    },
  ];

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/forbidden-lands/fbl-page-background-blizzard.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.backgroundWidth = "100vw";
    document.body.backgroundHeight = "100vh";
    document.body.style.backgroundSize = "cover";
  }, []);

  return (
    <Container>
      {/* <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin" 
                    style={{position: 'float', float: 'right',width: '120px', height: '240px'}} marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=smokeraven-20&language=en_US&marketplace=amazon&region=US&placement=9189143701&asins=9189143701&linkId=d1384e0e6b9ef083fee35d7272a71f88&show_border=true&link_opens_in_new_window=true"></iframe> */}
      <Row>
        <Col>
          <div
            style={{
              backgroundColor: "transparent",
              fontFamily: "IM Fell Great Primer",
            }}
          >
            <div style={pageHeader}>
              <main role="main" className="inner cover text-center">
                <a href="#freedom-day" name="pageTop">
                  <img
                    src={logo}
                    style={{ maxWidth: "200px" }}
                    alt="Forbidden Lands Campaign"
                  />
                </a>
              </main>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title className="cardTitle-forbidden-lands  ">
                Prisoners on the Alderlander island prison of Gomsten are given
                amnesty and passage to the Bitter Reach.
                <br /><br/>
                <small>
                  ⇐ Session 00:{" "}
                  <a href="./forbidden-lands-session-00">
                    Prisoners in Gomsten
                  </a>{" "}
                  ☠{" "}
                  Session 02:{" "}
                  <a href="./forbidden-lands-session-02">
                    The Elven Lighthouse
                  </a>{" "}
                  ⇒{" "}
                </small>
              </Card.Title>
              <div>
                <ContentContainer data={section1Data} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default FBLSession01;
