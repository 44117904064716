import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import logo from "../../../../src/assets/kids-on-bikes/logo-kids-on-bikes.webp";
import sectionDivider from "../../../../src/assets/kids-on-bikes/kids-on-bikes-page-banner.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";
import "./kids-on-bikes-styles.scss";

import jack from "../../../../src/assets/kids-on-bikes/session02/bully-jack-melvin.webp";
import bixby from "../../../../src/assets/kids-on-bikes/session02/bully-bixby-shetfield.webp";
import cover from "../../../../src/assets/kids-on-bikes/session02/session-02-cover.webp";
import carla from "../../../../src/assets/kids-on-bikes/session02/carla-fielder.webp";
import roly from "../../../../src/assets/kids-on-bikes/session02/roly-higgins.webp";
import steve from "../../../../src/assets/kids-on-bikes/session02/hippie-steve.webp";
import foster from "../../../../src/assets/kids-on-bikes/session02/hippie-foster.webp";
import moonflower from "../../../../src/assets/kids-on-bikes/session02/hippie-moonflower.webp";
import clarissa from "../../../../src/assets/kids-on-bikes/session02/hippie-clarissa.webp";
import seth from "../../../../src/assets/kids-on-bikes/session02/hippie-seth.webp";
import declan from "../../../../src/assets/kids-on-bikes/session02/declan-collier.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "1",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Carter One",
  backgroundColor: "rgba(0, 0, 0, 1) ",
  color: "white",
  marginBottom: "15px",
};

const KOBTurnaroundRockSession02 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  const section1Data = [
    {
      title: "WEIRDER THINGS",
      sectionTitleCssName: "sectionTitleKOB",
      text: (
        <div className="kidsOnBikesBodyText">
          <ImageComponent
            image={cover}
            caption="Session 02"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Ajax, Ariadne, and Sheldon search for a missing boy scout, hear
            the story of the wendigo, and come face to face with a ghost."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          Welcome back to Turnaround Rock, a sleepy little town on the Oregon
          coast, about 80 miles north of the California border. Here generations
          of families work the sea, a hard life made more difficult by The Rock,
          a large rocky island which keeps larger boats and ships from coming
          into the harbor. It is still Monday, April 10, 1989, just before
          midnight, and we find Sheldon, Ariadne, and Ajax in a small cave on
          the beach that is part of the old Qua-to-mah village site. But they
          are not alone. In front of them, at the cave mouth, is the floating
          spirit of Christopher Liu, their classmate. He wears a ghostly version
          of the Boy Scout uniform he wore in life. And nearby, yet again comes
          the otherworldly howling of some beast.
          <br />
          <br />
          We begin the session by checking in with each of the kids about what
          they're feeling in the moment.
          <br />
          <br />
          <ImageComponent
            image={roly}
            caption="Roly Higgin"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="Roly Higgin is an artist down the boardwalk searching for Christopher."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          Ajax has always felt like there was something more out there and that
          he would be visited by something, whether it be aliens, a ghost, or
          something else. Having always followed the occult and the weird, he
          feels vindicated and terrified.
          <br />
          <br />
          Sheldon realizes he has been wrong about the truth of the Qua-to-mah
          site, and now knows that there might be something to the rumors about
          this place. In his confrontation of this new knowledge, he is also
          terrified.
          <br />
          <br />
          Ariadne is fully embraced by her terror and wants nothing more than to
          flee this place.
          <hr className="hrKidsOnBikes" />
          We return to Sheldon's question from last session: “Christopher, what
          is that sound?”
          <br />
          <br />
          The spirit form of Christopher tries to speak, then seems panicked in
          the realization that it cannot do so. The chilling sound, something
          like a howl mixed with the whistling of the wind, is closer now, and
          just beyond Christopher's floating form, the kids can make out a
          beastly shape. Christopher's spirit turns toward it and, before they
          can make out exactly what it looks like, there is a flash of green
          light and then Christopher and the beast are gone.
          <br />
          <br />
          Ajax uses his Intuitive skill to try to figure out what happened.
          Analyzing the moment, he recalls a brief image of cliffs with the
          sound of revelry from a beach down below. His intuition tells him that
          the green light came from Christopher, that Christopher saved them
          from the beast by translocating his ghostly form and the creature to
          those cliffs.
          <br />
          <br />
          The kids decide that there is nothing more to do here. Any thoughts
          Ariadne had about camping are now left behind in the aftermath of
          their terror. As they leave the cave, they immediately notice that the
          electric light of the lighthouse on The Rock has gone dark. This
          meagre protection from the pulling eddies that threaten vessels on the
          ocean-side of The Rock is temporarily no more.
          <br />
          <br />
          <ImageComponent
            image={carla}
            caption="Carla Fielder"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Carla Fielder is an artist down the boardwalk searching for Christopher. She lost her grand-daughter Lisa Fielder and is distraught by what is happening in her town."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          As they leave the site and begin to bike through the woods that will
          lead them back toward the marina and downtown, they see some lights in
          the woods. They run into local sculptor, Roly Higgin, part of a group
          still out searching for Christopher. Roly cautions the kids to get
          home. While Ajax talks to Roly about the search, the sculptor notices
          through the trees for the first time that the lighthouse has gone out.
          He worries because there are boats out searching the water and
          shoreline for “any sign of Christopher's…” The adult stops himself
          before he says the word “body.”
          <br />
          <br />
          He also wonders aloud if the lighthouse going out happened at the same
          time that he felt an electrical crackling in the air, a subtle feeling
          that he had written-off but which had been accompanied by a slight
          darkening of the woods around him, a darkening he now recognizes was
          the new lack of the rotating light from The Rock.
          <br />
          <br />
          Carla Aimes Fielder, another local artist, an older woman, comes out
          of the trees calling Roly's name. She looks excessively distraught.
          <br />
          <br />
          Ariadne secretly passes Christopher's Boy Scout sash to Sheldon, with
          a whisper about whether they should tell the adults anything about
          what they'd just seen in the cave. Sheldon asks, “What's out there? We
          can handle it. We're tough kids.” Carla expresses her worry that they
          won't find Christopher, and as she speaks, she mentions that thing
          that has made his disappearance so distressful and personal to her. A
          few months ago, her own grand-daughter, Lisa Fielder, had gone
          missing. Perhaps she ran away. Perhaps she was abducted. Perhaps she
          got into an accident. There was no way to know.
          <br />
          <br />
          Sheldon decides to show the sash to the adults. He tells them that
          they found it at the Qua-to-mah-site in a cave by the beach. Carla
          takes the sash. Sheldon asks that the adults let them know what they
          find out and says that the kids will come by the boardwalk, where the
          art galleries and studios are, in the morning to talk with them.
          <br />
          <br />
          Ariadne, a little bit upset because both adults had said “you boys” a
          couple of times when talking to the kids, obviously speaking to the
          boys more than the girl, swerves on her bike towards Carla to try to
          grab the sash and keep it, but she fails. Roly, thinking that Ariadne
          has lost control of her bike and might hit the old woman, steps in
          front of Carla and gently redirects Ariadne's bike. As she rides off,
          Ariadne yells back, “I'm a girl!”
          <br />
          <br />
          When the kids get home, they've almost forgotten the earlier phone
          calls (from last session) made by the security supervisor of the fish
          processing plant.
          <br />
          <br />
          Ajax knows his mom won't be very upset because she is tired from
          working at the Geoduck Inn, but that tiredness is exactly what makes
          her focus on the incident. She doesn't like that one of her coworkers
          got the message and had to relay it to her. She says that it's hard
          enough being a woman in her industry in this town of fishermen, and
          she doesn't need to give the men anything else they can use to make
          her the butt of a joke. Ajax's attempt to diffuse the situation fails,
          and his mother gets angrier and sends him to bed without any of the
          chocolate cake she'd made that day.
          <br />
          <br />
          Earlier in the day, Sheldon's mom had seemed fine on the phone with
          the security supervisor, but once he's home, he fails his charm roll,
          and the single mother gives him a very rare lecture.
          <br />
          <br />
          Ariadne arrives home just as her father is about to listen to the one
          message that is beeping on their answering machine. She successfully
          charms him, saying that the call is from her. She distracts him by
          talking about the missing Christopher, then deletes the message
          without her parents hearing it.
        </div>
      ),
    },
    {
      title: "TUESDAY, APRIL 11, 1989",
      sectionTitleCssName: "sectionTitleKOB",
      text: (
        <div className="kidsOnBikesBodyText">
          <ImageComponent
            image={jack}
            caption="Bully: Jack Melvin"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Jack Melvin the bully. Leader of a gang of two meatheads, himself included."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          Before school, the kids ride down to the boardwalk at Fielder Beach.
          They talk to Joseph Mankiller and his adult son, Joey, two artists on
          the boardwalk. Sheldon asks what they've seen and the Mankillers make
          the connection that they're the kids who gave Roly and Carla the Boy
          Scout sash. Joseph tells them that Roly and Carla are at the Sheriff's
          Office talking with Sheriff Roberts and Deputy Waters. Perhaps the
          sheriff and deputy will want to talk to the kids?
          <br />
          <br />
          Other artists start showing up for the morning along the boardwalk –
          Arvid Root, Rita Huerta, Miranda Mistral – as well as Maria
          Donalds-Majester on her way to join her morning staff at Donalds'
          Seafood Restaurant.
          <br />
          <br />
          The Mankillers and the kids learn that the Dora Lee, a small fishing
          vessel, went down last night. The crew was part of the search for
          Christopher, and they were drawn into The Rock by its ocean-side
          eddies when the light of the lighthouse went out. It's the first loss
          of a boat to The Rock in a long while. All hands were lost, a tragedy.
          As the group looks out toward The Rock, they see two small boats
          heading out on the safe Eastern side of the otherwise ominous
          landmass.
          <br />
          <br />
          “They're going out to try to fix the light,” Rita Huerta says.
          <br />
          <br />
          As they enter school, the kids set some intentions. Ariadne wants
          Sheldon to help her once they're in Computer Lab at the end of the
          day. She wants to try to figure out how to get into a chat room or
          search the nascent internet for some of the history of the lighthouse.
          <br />
          <br />
          <ImageComponent
            image={bixby}
            caption="Bully: Bixby Shetfield"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Bixby runs with Jack Melvin, bullying kids and throwing his weight around."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          Ajax wants to speak to some of the Boy Scouts, who are back in
          classes, to get more insight into what happened with Christopher and
          the scouts.
          <br />
          <br />
          Sheldon is up for both avenues of gathering information, and the kids
          agree to sit together at lunch.
          <br />
          <br />
          At lunch, they are ready to start talking to Boy Scouts, when Rodney
          Green and Billy Porder approach them. Rodney nervously asks Ariadne if
          the two scouts can join them.
          <br />
          <br />
          Rodney prompts Billy to share what he has to say. Billy says that he
          is less confused than he was when they talked to him last. For
          instance, he remembers now that Christopher wasn't with the scouts
          when they went in search of the noise. He was in his tent when they
          left camp.
          <br />
          <br />
          “Along the way,” Billy says, “we ran into something. A black-tailed
          deer ran into our midst, chased by something else.”
          <br />
          <br />
          “It was terrifying,” Rodney adds.
          <br />
          <br />
          The scouts talk about the pandemonium that ensued as the deer and some
          beast passed through their group. Most of the scouts were hurt, some
          very badly, as they were right in the path of the chase.
          <br />
          <br />
          “That's when I saw Christopher,” Rodney said, “He was floating in the
          air and glowing yellow.”
          <br />
          <br />
          “Rodney yelled out Christopher's name and I looked around and realized
          that Christopher wasn't with us,” Billy added.
          <br />
          <br />
          “It turns out,” says Rodney, “that only I could see Christopher. I
          thought I was crazy.”
          <br />
          <br />
          Billy gulps and continues, “I didn't see him, but that's when the real
          weirdness started happening. The beast returned for us, and then,
          suddenly, we were someplace else.”
          <br />
          <br />
          “Christopher,” Rodney adds, “had been glowing with a yellow light, but
          he gave off a flash of green, and then we were hallway up a cliffside,
          looking down at a beach.”
          <br />
          <br />
          Billy says, “I could smell weed, and there were naked people dancing
          around a bonfire below us. I don't know how we got there. But a moment
          later, we were back at our campsite.”
          <br />
          <br />
          “Christopher flashed green again,” Rodney exclaimed, wide-eyed. “We
          went from being on that cliff to being at our camp. Kids were moaning
          because they were hurt. And Christopher was there with us, too, still
          floating in the air. No one else saw him. And then he flashed again,
          this time with a blue light, and we all felt better. But I was really
          confused after the blue light, and I didn't remember any of that until
          later.”
          <br />
          <br />
          Billy says, “Yeah, we were all super confused. I remembered getting
          lost and feeling like I was in the wrong place, but I couldn't
          remember details about where we'd been until much later, basically
          when I woke up in the middle of the night last night.”
          <br />
          <br />
          The kids question Billy and Rodney about why Christopher wasn't with
          them when they left camp. Rodney tells them that Christopher had one
          of his seizures before all these events and had gone to his tent to
          rest. That's why he wasn't present when the other scouts emboldened
          each other to leave the campsite and head towards the Qua-to-mah
          village.
          <br />
          <br />
          (When a player asked the GM if the kids knew about Christopher's
          seizure, the GM said that's up to them. It's not necessarily a secret,
          so the players could decide for themselves whether the seizures were
          new information.)
          <br />
          <br />
          When asked to describe the creature, Billy says that it was a big,
          thin, muscular creature, with a jaw filled with sharp teeth, antlers
          on its head, and that it walked upright like a man. Billy also adds
          that he had a feeling, and he doesn't know why, that Christopher died
          on or near that cliffside.
          <br />
          <br />
          Ariadne asks if he means that Christopher was alive with them when
          Rodney saw him floating in the woods, but Billy says “No, I mean that
          I think he somehow died before Rodney saw him, but that he didn't die
          in camp. He died at that other spot. I think it was Gawker Beach.”
          <br />
          <br />
          Ajax remembers the image he'd seen in the cave of a cliff with a beach
          below it, the sound of revelers. That must have been Wallowa (or
          Gawker) Beach. He thinks that's where they need to go after school.
          <br />
          <br />
          During Computer Lab, Sheldon has trouble getting information on The
          Rock. The World Wide Web hasn't been invented yet, so it's much harder
          to find what you need. Ariadne biffs an attempt to charm Mr. Jackson,
          their Computer Lab teacher, and he gets more annoyed than he should.
          He breaks down and reveals too much, that his cousin was found dead
          this morning near Wallowa Beach. He'd been searching near there in
          case Christopher somehow wandered that far in the night, and he'd been
          rewarded for his efforts by being ravaged by some animal. Mr. Jackson
          leaves the room, and soon an aide from the office arrives and says
          she'll be supervising class for the rest of the period.
          <br />
          <br />
          Ajax types in the description of the beast into one of his chat rooms,
          but all he gets back are conspiracy theories that have nothing to do
          with his question.
          <br />
          <br />
          After school, the kids ride toward Gawker Beach. They realize they are
          being followed by Jack Melvin, the bully that gave Ajax his black eye
          when Ajax defended Sheldon during spring break. Worse yet, Jack is
          accompanied by his enormous buddy, Bixby Shetfeld. Jack yells out to
          the kids, calling them assholes and saying he's going to get Jack.
          <br />
          <br />
          Sheldon ride away with a sudden burst of speed and cowers behind a
          tree. Ajax is hampered by the familiar event of a bike chain coming
          lose from the gears of his bike. Ariadne look back and then pauses in
          indecision about whether to join Sheldon or go back to help Jack. That
          moment is all it takes for Bixby to skid to a stop, his back bike
          wheel jostling her front wheel so that she must step off her bike as
          it falls to the ground. Bixby grabs her by the shoulder. Ariadne
          boldly attempt to punch the mammoth Bixby in the nose, but he simply
          holds her tight in a vice grip and calls her a bitch.
          <br />
          <br />
          Jack mocks Ajax, but Ajax pulls out his buck knife and threatens the
          bully. Jack lowers himself into a charging position, determined to
          bring Ajax down, but Ajax uses all seven of his adversity points to
          convert his roll to a success, and Jack looks into Ajax's eyes and
          sees the determination. “Not today, huh, Ajax,” he says.
          <br />
          <br />
          “Not ever!” is the response.
          <br />
          <br />
          “I don't know about ever, but not today.” Jack calls out to Bixby to
          let Ariadne go. The two bullies get back on their bikes and retreat.
          <br />
          <br />
          Looking to the sky, the kids note that a storm is approaching, but
          they decide there is still time to complete their trek to Wallowa
          Beach and the cliffs above it.
        </div>
      ),
    },
    {
      title: "GAWKER BEACH",
      sectionTitleCssName: "sectionTitleKOB",
      text: (
        <div className="kidsOnBikesBodyText">
          <ImageComponent
            image={steve}
            caption="Hippie: Steve"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Steve is one of the hippies from Wallowa (aka Gawker) beach."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          As they approach Wallowa (Gawker) Beach, the kids debate taking the
          road up to the cliffs or down to the beach. They decide to bike down
          to the beach, and along the way they see three hippies walking on the
          road in front of them. They meet Steve, Moonflower, and Foster.
          Moonflower looks like she's about sixteen or seventeen. Underneath her
          smile, there is a sadness. Steve is drug-addled but always smiling,
          while Foster, while welcoming and friendly at first, grows more
          reticent in response to Steve's encouragement that the kids join them
          on the beach where there will be a fire later and dancing. “This is
          not the day, Steve,” Foster says, but Steve persists, saying that Seth
          always likes to meet newcomers and will be happy to see them.
          <br />
          <br />
          Ajax asks the hard questions, such as “So, you guys take your clothes
          off when you dance around at night?” Steve and Foster both grin and
          acknowledge that they do sometimes take their clothes off when they
          dance around. Moonflower says that she doesn't. When Ariadne tries to
          befriend her and asks about the creepy men there, Moonflower confides
          that a woman named Clarissa makes sure nothing bad happens to her.
          “Seth and Clarissa run the place.” Moonflower also says that the kids
          shouldn't go down to the beach. “How old are you anyway,” she asks
          Ariadne. Ariadne lies and tells her that she is seventeen, but
          Moonflower laughs and says she must be about twelve. This upsets the
          13-year-old Ariadne, who tries to impress Moonflower by showing her
          the expensive make-up kit from her bag. Moonflower calls her a
          “coastal noser,” but is still friendly with her.
          <br />
          <br />
          <ImageComponent
            image={foster}
            caption="Hippie: Foster"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="Foster is one of the hippies from Wallowa (aka Gawker) beach."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          The kids ask if the hippies have seen anything unusual, maybe green
          lights or something. Foster assures them that they haven't, but Ajax
          uses his Intuitive skill again and gets the feeling that Foster is
          hiding the truth. Sheldon comments that “we figured you guys would
          know about ghosts and stuff,” and again is met with promises that
          meeting Seth is what they need to do. Foster repeats that this might
          not be the night for it.
          <br />
          <br />
          On the beach and beachfront there is an actual commune with bungalows,
          beach shacks, and tents. It is clear someone owns this land and allows
          this community to be here.
          <br />
          <br />
          Seth and Clarissa hold court over a group of hippies. Foster, who
          doesn't look happy, goes over to talk to them. The kids notice him
          motion towards a particular bungalow, outside of which two guys are
          sitting, semi-alert hippie guards it would seem. Despite Foster's
          demeanor, Seth waves everyone over. Steve introduces the group to Seth
          and Clarissa. They are invited to stay for dinner, which will be
          shared around the fire. Seth is amiable but filled with strange ideas.
          He talks about transformation and harnessing the energy of Turnaround
          Rock (turn, turn, turn, to every season) to achieve the next stage of
          human evolution. Ajax finds the ideas fascinating.
          <br />
          <br />
          <ImageComponent
            image={moonflower}
            caption="Hippie: Moonflower"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Moonflower is one of the hippies from Wallowa (aka Gawker) beach. She was trying to escape the cult but was brought back by Foster and Steve."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          During the impromptu guru-sermon, the kids notice that Moonflower
          sneaks past the men in front of the guarded bungalow with more food.
          She gives the guards a baggie with something in it, which they
          immediately divide and eat, to aid her efforts. Someone or something
          must be inside that bungalow.
          <br />
          <br />
          After the sermon, there is dancing in front of a fire and a more
          partylike atmosphere prevails even as turbulent clouds roll in and the
          night sky herald the quickly approaching storm. Now in the dark of
          night and with the commune focused on their revelry, the group makes
          their attempt at getting into the mystery bungalow. Ariadne distracts
          the men out front by showing them her copy of The Fellowship of the
          Ring. The men are clearly on hallucinogenic drugs, one of them looking
          at his hands in the air, the other enthralled by the book.
          <ImageComponent
            image={clarissa}
            caption="Hippie: Clarissa"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="Clarissa leads the hippie cult beside Seth in Wallowa (aka Gawker) beach."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <br />
          <br />
          Sheldon and Ajax sneak around to the side of the building. Sheldon's
          Unassuming skill helps him. He also decides to use his Brains instead
          of his Brawn to get in. It had been described that he could try to
          loosen the stuck window, but with a successful (and slightly higher
          difficulty) Brains roll, the player describes knowing this particular
          lock and getting it open. Sheldon scrambles in through the window, and
          Ajax follows him inside. They find another hippie-looking gentleman
          tied to a chair and gagged.
          <br />
          <br />
          When Sheldon ungags the man, he introduces himself as ATF Agent Declan
          Collier. He urges them to untie him, but Ajax insists that he explain
          himself fully beforehand.
          <br />
          <br />
          “Seth and Clarissa deal drugs on a high level into Portland and other
          cities, which is what brought me here undercover. While here, I found
          out some strange things about them. They think this place is the
          epicenter of the next stage in human evolution and consciousness. They
          think they can somehow harness that energy. It all sounds like hokum
          to me, but two nights ago the craziest thing happened. There was a
          flash of green light in the air, and a boy scout fell from the sky
          onto the beach. He was in a sleeping bag and shaking with a seizure.
          I'm pretty sure that he's now in a coma. They figured that he is tied
          into the energies of Turnaround Rock and that he can be the catalyst
          they need to achieve their evolution.”
          <ImageComponent
            image={seth}
            caption="Hippie: Seth"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Seth leads the hippie cult in Wallowa (aka Gawker) beach."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <br />
          <br />
          The kids are in shock that Christopher is alive and in danger. They'd
          come in search of his dead body, but now there was some hope. They
          learn that Agent Collier was being confronted about being an agent
          right when the body appeared. Foster had found his gun in his shack.
          Collier tells the kids that he's smart enough not to have brought his
          badge with him, but that next time he'll bring a gun that's not
          standard issue for law enforcement.
          <br />
          <br />
          He further informs them that, with tonight's storm, the commune/cult
          plans to act. They have the boy's comatose body in a metal box.
          They'll use the lightning of the storm to channel all the energy into
          themselves. “That'll kill that kid,” Collier says.
          <br />
          <br />
          Meanwhile, Moonflower approaches Ariadne and the hippie guards outside
          the bungalow. She asks the guards if she can talk to Ariadne. The one
          who was interested in the book, asks if he can hold onto it while they
          talk. He leafs through it while Moonflower pulls Ariadne aside.
          Moonflower asks what Ariadne is doing near this bungalow and then
          confides that “they won't let me leave.” Earlier, when the kids
          encountered them on the road, Steve and Foster were bringing
          Moonflower back from trying to escape. She says they've got an ATF
          agent tied up in the bungalow who had been her one hope to have help
          in getting free. With his capture, she'd attempted to escape and find
          Sheriff Roberts, and especially Deputy Waters. As she tells her story,
          Ariadne sees hippies bringing a metal box out of a bungalow as
          lightning starts to split the dark night sky. They drag the metal box
          towards a circle of stones on the beach and attach a metal red to the
          top of it. Ariadne knows that something sinister is going on.
          <br />
          <br />
          “My friends are inside that bungalow,” she whispers, “Distract those
          idiots out front.” While Moonflower goes back to talk to the guards,
          Ariadne walks away and then sneaks back around to climb in the window.
          <br />
          <br />
          With Ariadne's arrival and her breathless description of what's going
          on, Ajax and Sheldon finally trust Declan enough to untie him from the
          chair. The kids debate going into town to find Sheriff Roberts and
          Deputy Waters, but as they do so, they see lightning through the
          window, and hear thunder almost immediately after. Time is of the
          essence.
          <ImageComponent
            image={declan}
            caption="Declan Collier"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Declan Collier is an undercover ATF agent captured by the hippie cult on Gawker Beach."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <br />
          <br />
          As they're deciding their next move, they hear Foster outside
          reprimanding the guards. Then the door opens, and Foster enters.
          Seeing the kids and the freed Declan Collier, Foster reaches for his
          waistline where he has Declan's gun.
          <br />
          <br />
          Sheldon tries to dart between Foster's legs and trip him up, but he
          rolls a 1 and instead falls at his feet. Sheldon's stumble causes
          Foster to dodge to the side, and Ajax's own poor roll combined with
          Foster's movement means that his tackle fails and instead he falls on
          top of Sheldon. Fortunately, this allows Ariadne to line up a solid
          kick to the family jewels that stuns Foster long enough for Agent
          Collier to get his gun back.
          <br />
          <br />
          The two guards come through the door, as Declan points his gun at
          them. Taking in all the details, Declan shouts, “Put down the Lord of
          the Rings!” END OF SESSION
          <br />
          <br />
          Kids on Bikes was GMed and written by{" "}
          <a
            href="https://www.roblecrone.com/"
            rel="noreferrer"
            target="_blank"
          >
            CobraRex
          </a>
        </div>
      ),
    },
  ];

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/kids-on-bikes/kids-on-bikes-page-background.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <Container>
      {/* <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin"
                    style={{position: 'float', float: 'right',width: '120px', height: '240px'}} marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=smokeraven-20&language=en_US&marketplace=amazon&region=US&placement=9189143701&asins=9189143701&linkId=d1384e0e6b9ef083fee35d7272a71f88&show_border=true&link_opens_in_new_window=true"></iframe> */}
      <Row>
        <Col>
          <div
            style={{
              backgroundColor: "transparent",
              fontFamily: "Carter One",
            }}
          >
            <main role="main" className="inner cover text-center">
              <img
                src={logo}
                style={{ maxWidth: "200px" }}
                alt="Kids on Bikes Turnaround Rock Campaign"
              />
            </main>
            <div style={pageHeader}>
              <h1 className="cover-heading-kids-on-bikes">
                Session 02: Turnaround Rock
              </h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title className="cardTitle-kids-on-bikes">
                Things get weird in the sleepy town of Turnaround Rock: flashing
                lights, beach hippies, and a prisoner are some of the
                discoveries as our kids deal with Trouble at Gawker Beach.
              </Card.Title>
              <div>
                <ContentContainer data={section1Data} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default KOBTurnaroundRockSession02;
