import React from 'react';
import {
    encounters as encounters_ravenland,
    terrainList as terrain_ravenland
} from '../../../src/json/encounter_data_gamemasters_guide';
import {
    encounters as encounters_bitterreach,
    terrainList as terrain_bitterreach
} from '../../../src/json/encounter_data_bitter_reach';
import background1
    from "../../../src/assets/random_encounters/autumn_stream_by_ninjatic.jpg";
import background2
    from "../../../src/assets/random_encounters/a_morning_pilgrimage_by_ninjatic.jpg"
import background3
    from "../../../src/assets/random_encounters/a_visit_from_an_old_friend_by_ninjatic.jpg"
import background4
    from "../../../src/assets/random_encounters/cavern_by_ninjatic.jpg"
import background5
    from "../../../src/assets/random_encounters/deep_swamp_by_ninjatic.jpg"
import background6
    from "../../../src/assets/random_encounters/hidden_falls_by_ninjatic.jpg"
import background7
    from "../../../src/assets/random_encounters/holy_glade_by_ninjatic.jpg"
import background8
    from "../../../src/assets/random_encounters/misty_mountains_by_ninjatic.jpg"
import background9 from "../../../src/assets/random_encounters/ninjatic-01.jpg"
import background10 from "../../../src/assets/random_encounters/ninjatic-02.jpg"
import background11 from "../../../src/assets/random_encounters/ninjatic-03.jpg"
import background12 from "../../../src/assets/random_encounters/ninjatic-04.jpg"
import background13 from "../../../src/assets/random_encounters/ninjatic-05.jpg"
import background14
    from "../../../src/assets/random_encounters/ninjatic-clear-sky.jpg"
import background15
    from "../../../src/assets/random_encounters/ninjatic-dark-road-02.jpg"
import background16
    from "../../../src/assets/random_encounters/ninjatic-dark-road.jpg"
import background17
    from "../../../src/assets/random_encounters/ninjatic-ice-and-stone.jpg"
import background18
    from "../../../src/assets/random_encounters/ninjatic-snowy-path.jpg"
import background19
    from "../../../src/assets/random_encounters/ninjatic-snowy-tree.jpg"
import background20
    from "../../../src/assets/random_encounters/ninjatic-thru-the-trees.jpg"
import background21
    from "../../../src/assets/random_encounters/old_ruins_by_ninjatic_d67rzxi-fullview.jpg"
import background22
    from "../../../src/assets/random_encounters/ruined_ship_by_ninjatic.jpg"
import background23
    from "../../../src/assets/random_encounters/this_is_not_good__by_ninjatic.jpg"
import background24
    from "../../../src/assets/random_encounters/to_high_region_by_ninjatic_d6fqf9y-fullview.jpg"
import background25
    from "../../../src/assets/random_encounters/unexpected_quest_by_ninjatic.jpg"
import background26
    from "../../../src/assets/random_encounters/until_then_by_ninjatic.jpg"
import background27
    from "../../../src/assets/random_encounters/white_drifters_by_ninjatic.jpg"
import background28
    from "../../../src/assets/random_encounters/long_journey_ahead_by_ninjatic.jpg"
import background29
    from "../../../src/assets/random_encounters/frozen_mountains_by_ninjatic.jpg"
import background30
    from "../../../src/assets/random_encounters/waterfalls_by_ninjatic.jpg"
import background31 from "../../../src/assets/random_encounters/lost_by_ninjatic.jpg"
import background32
    from "../../../src/assets/random_encounters/swamp_fairies_by_ninjatic.jpg"
import background33
    from "../../../src/assets/random_encounters/prophecy_by_ninjatic.jpg"
import background34
    from "../../../src/assets/random_encounters/the_road_by_ninjatic.jpg"
import background35
    from "../../../src/assets/random_encounters/the_shore_by_ninjatic.jpg"
import background36
    from "../../../src/assets/random_encounters/shore_city_by_ninjatic.jpg"
import background37
    from "../../../src/assets/random_encounters/thousand_suns_by_ninjatic.jpg"
import background38
    from "../../../src/assets/random_encounters/the_ruins_by_ninjatic.jpg"
import background39
    from "../../../src/assets/random_encounters/holy_ruins_by_ninjatic.jpg"
import {hexData} from "../../../src/js/hexData";
import {hexDataBitterReach} from "../../../src/js/hexDataBitterReach";
import {titleCase} from "title-case";
import arrow1 from "../../../src/assets/arrow-1.png";
import arrow2 from "../../../src/assets/arrow-3.png";
import arrow3 from "../../../src/assets/arrow-5.png";
import arrow4 from "../../../src/assets/arrow-6.png";
import arrow5 from "../../../src/assets/arrow-7.png";
import arrow6 from "../../../src/assets/arrow-11.png";

let backgroundImages = [{background1}, {background2}, {background3}, {background4},
    {background5}, {background6}, {background7}, {background8}, {background9},
    {background10}, {background11}, {background12}, {background13}, {background14},
    {background15}, {background16}, {background17}, {background18}, {background19},
    {background20}, {background21}, {background22}, {background23}, {background24},
    {background25}, {background26}, {background27}, {background28}, {background29},
    {background30}, {background31}, {background32}, {background33}, {background34},
    {background35}, {background36}, {background37}, {background38}, {background39}]

//todo: move hexletters and numbers into hexdata files
const hexLetters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P',
    'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH',
    'AI', 'AJ', 'AK', 'AL', 'AM', 'AN', 'AO'];

function getRandomIndexFromList(inputList) {
    return Math.floor(Math.random() * inputList.length);
}

function getTerrainNew(name, terrain) {
    let z = terrain.filter(x => x.name === name)
    return (z[0]) ? z[0].freq : 0;
}

class HexTraveller extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            encountersData: null,
            selectedTerrain: '',
            terrain: '',
            encounterName: null,
            encounter: null,
            backgroundImage: null,
            ignoreNoEncounterResults: false,
            onlyBeneathTheIceResults: false,
            selectedCampaign: 'ravenland',
            hexLabels: null,
            input: 'p25',
            selectedHex: null,
            hexData: null,
            hitMapEdge: false,
            x: 0,
            y: 0,
            hexNumbers: [...Array(52).keys()]
        };
    }

    buildHexLabels = () => {
        let hexNumbers = this.state.hexNumbers;
        hexNumbers.shift();
        let temp = [];
        for (const x of hexLetters) {
            for (const y of hexNumbers) {
                temp.push(x + y);
            }
        }
        this.setState({hexLabels: temp});
    }
    randomEncounterMonster = (encounterListNew) => {
        let r = Math.floor(Math.random() * encounterListNew.length);
        return (encounterListNew[r]) ? encounterListNew[r].name : null;
    }
    buildTerrainList = () => {
        //NOTE: in future we may combine all terrain lists into master of unique terrains
        if (this.state.selectedCampaign === 'bitter reach') {
            return terrain_bitterreach;
        }
        return terrain_ravenland;
    }
    createSelectItems = () => {
        let items = [];
        let terrainList = this.buildTerrainList();
        items.push(<option key={-1} value={-1}></option>);
        terrainList.forEach(function (item, index) {
            items.push(<option key={index}
                               value={item}>{item.toUpperCase()}</option>);
        });
        return items;
    }

    componentDidMount() {
        this.buildHexLabels();

        this.setState({backgroundImage: background1})
        this.setState({encountersData: this.getCampaignEncountersData()})
        this.setState({hexData: this.getCampaignHexData()})
    }

    handleChangeNoEncounter = (e) => {
        this.setState({ignoreNoEncounterResults: e.target.checked});
    }
    handleBeneathTheIce = (e) => {
        this.setState({onlyBeneathTheIceResults: e.target.checked});
    }
    getCampaignEncountersData = (name) => {
        switch (name) {
            case 'bitter reach':
                return encounters_bitterreach
            default:
                return encounters_ravenland
        }
    }
    getCampaignHexData = (name) => {
        switch (name) {
            case 'bitter reach':
                return hexDataBitterReach
            default:
                return hexData
        }
    }
    getCampaignHexNumbers = (name) => {
        switch (name) {
            case 'bitter reach':
                return [...Array(52).keys()]
            default:
                return [...Array(52).keys()]
        }
    }
    handleChangeCampaign = (e) => {
        let selectedCampaign = e.target.value;
        this.setState({selectedCampaign: e.target.value}, this.createSelectItems);
        this.setState({encountersData: this.getCampaignEncountersData(selectedCampaign)})
        this.setState({hexData: this.getCampaignHexData(selectedCampaign)})
        this.setState({hexNumbers: this.getCampaignHexNumbers(selectedCampaign)})
        this.setState({onlyBeneathTheIceResults: false})

    }
    /*
  Create list of objects that have encounter names. There are as many rows with same encounter name
  as their designated frequency.
  NOTE: convert to binary tree search - https://stackoverflow.com/questions/16927299/coding-random-encounter
  [
    {name: "bloodmist"}
    {name: "bloodmist"}
    {name: "death from above"}
    {name: "death from above"}
    {name: "the harpies' feast"}
    {name: "the harpies' feast"}
  ]
   */
    buildEncounterList = (terrain, ignoreNoEncounters) => {
        if (!terrain) {
            return
        }
        let encounters = this.state.encountersData;
        let encounterList = []
        for (let name in encounters) {
            if (ignoreNoEncounters && name === 'no encounter') {
                continue;
            }
            let frequency = getTerrainNew(terrain, encounters[name].terrain)
            for (let i = 0; i < frequency; i++) {
                encounterList.push({'name': name})
            }
        }
        return encounterList;
    }

    changeBackground = (e) => {
        e.target.style.background = 'red';
    }
    mouseLeave = (e) => {
        e.target.style.background = 'green';
    }

    getEncounter = (terrain) => {
        if (terrain === '--' || (terrain === 'high mountains' && !this.state.onlyBeneathTheIceResults) || terrain === 'ocean') {
            this.setState({encounter: null})
            this.setState({selectedHex: {'name': terrain}})
            return
        }
        let el = this.buildEncounterList(terrain, this.state.ignoreNoEncounterResults)
        if (!el) {
            return
        }
        let randomEncounterName = getRandomIndexFromList(el)
        randomEncounterName = el[randomEncounterName].name
        let encounter = this.state.encountersData[randomEncounterName]
        return {
            'name': randomEncounterName,
            'selectedTerrain': terrain,
            'flavor': encounter.flavor
        }
    }

    getLetters = (input) => {
        return input.match(/[a-z]+|[^a-z]+/gi);
    }

    handleChange = (e) => {
        this.setState({input: e.target.value.toUpperCase()});
    }
    handleClick = (hex) => {

        let newImageObj = backgroundImages[getRandomIndexFromList(backgroundImages)];
        let newImage = Object.values(newImageObj)[0];
        this.setState({backgroundImage: newImage})
        // use state variable if this is a button click, but if called from other
        // function, used the value passed in as a hex label
        let input = this.state.input;
        console.log('input 1: ' + input)

        if (!hex.target) {
            input = hex;
        }
        console.log('input 2: '+ input)
        if (input) {

            const selectedHex = this.state.hexData.filter(x => x['name'] === input.toUpperCase())

            let terrain = selectedHex[0] ? selectedHex[0].terrain : '';
            if (this.state.selectedCampaign === 'bitter reach' && this.state.onlyBeneathTheIceResults) {
                terrain = "beneath the ice"
            }
            let encounter = this.getEncounter(terrain);
            this.setState({selectedHex: selectedHex[0], encounter: encounter})
        }
    }
    handleArrowClick = (arrowNumber) => {
        let currentHex = this.state.input;
        this.setState({hitMapEdge: false})
        let hexNameParts = this.getLetters(currentHex)
        let letters = hexNameParts[0];
        let number = parseInt(hexNameParts[1]);
        let numAdj = 0;
        let letterAdj = 0;
        switch (arrowNumber) {
            case 1:
                letterAdj = 0;
                numAdj = -2;
                break;
            case 2:
                letterAdj = 1;
                numAdj = -1;
                break;
            case 3:
                letterAdj = 1;
                numAdj = 1;
                break;
            case 4:
                letterAdj = 0;
                numAdj = 2;
                break;
            case 5:
                letterAdj = -1;
                numAdj = 1;
                break;
            case 6:
                letterAdj = -1;
                numAdj = -1;
                break;
            default:
                break;
        }
        let letterNumber = hexLetters.indexOf(letters.toUpperCase());
        letterNumber += letterAdj;
        letters = hexLetters[letterNumber]
        if (letters == null || letters === 'undefined' || letters === -1) {
            this.setState({hitMapEdge: true});
            return;
        }

        number += numAdj;
        let maxNumber = Math.max(...this.state.hexNumbers);
        if (number > maxNumber || number < 1) {
            this.setState({hitMapEdge: true});
            return;
        }
        let newHex = letters + number.toString();

        this.setState({input: newHex})
        this.handleClick(newHex)
    }

    render() {

        const renderSpecial = () => {
            let selectedHex = this.state.selectedHex;
            if (!selectedHex) {
                return
            }
            let special = this.state.selectedHex.special;
            if (special && special.length > 0) {
                return (
                    <span>Special: {titleCase(special[0])}<br/></span>)
            }
        }
        const renderAdventureSite = () => {
            let selectedHex = this.state.selectedHex;
            if (!selectedHex) {
                return
            }
            let adventure = this.state.selectedHex.adventure;
            if (adventure && adventure.length > 0) {
                return (
                    <span>Adventure Site: {titleCase(adventure)}<br/></span>)
            }
        }
        // const renderMapEdgeWarning = () => {
        //     let hitMapEdge = this.state.hitMapEdge;
        //     if (hitMapEdge) {
        //         return (<span>MAP EDGE - NOWHERE TO GO</span>)
        //     }
        // }
        const renderHexRegion = () => {
            let selectedHex = this.state.selectedHex;
            if (!selectedHex) {
                return
            }
            let region = this.state.selectedHex.region;
            if (region) {
                return (
                    <span>Region: {titleCase(region)}<br/></span>)
            }
        }
        const renderPrimaryKin = () => {
            let selectedHex = this.state.selectedHex;
            if (!selectedHex) {
                return
            }
            let kin = this.state.selectedHex.kin;
            if (kin) {
                return (
                    <span>Primary Kin: {titleCase(kin)}<br/></span>)
            }
        }
        const renderTerrain = () => {
            let selectedHex = this.state.selectedHex;
            if (!selectedHex) {
                return
            }
            let terrain = titleCase(selectedHex.terrain);
            let includes = selectedHex.includes;

            if (terrain && includes) {
                let includesDisplay = ''
                if (includes.length > 0) {
                    includesDisplay = '[Includes: ';
                    includesDisplay += includes.join(', ')
                    includesDisplay += ']'
                }

                return (
                    <span>Terrain: {terrain} {includesDisplay}<br/></span>)
            } else if (terrain) {
                return (
                    <span>Terrain: {terrain}<br/></span>)
            }
        }
        let showBeneathTheIce = this.state.selectedCampaign === 'bitter reach';
        let showEncounter = this.state.encounter;
        let campaignLink = (this.state.selectedCampaign === 'bitter reach')
            ? 'https://frialigan.se/en/store/?product_id=5362320277653' :
            'https://frialigan.se/en/store/?product_id=2164836859952';
        let backgroundStyle = {
            backgroundImage: `url(${this.state.backgroundImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
            backgroundSize: 'cover',
            width: '100vw',
            height: '2000px'
        }

        const renderHexData = () => {
            let selectedHex = this.state.selectedHex;

            if (selectedHex) {
                if (selectedHex.terrain === '--') {
                    return <span>Invalid hex.</span>
                }
                if (selectedHex.terrain === 'high mountains' && !this.state.onlyBeneathTheIceResults) {
                    return <span>Cannot travel through High Mountains.</span>
                }
                if (this.state.hitMapEdge) {
                    this.setState({hitMapEdge: false});
                    return <span>Map Edge. Nowhere to travel.</span>
                }

                let includes = selectedHex.includes;
                if (includes) {
                    includes = includes.join(', ')
                }

                return <div style={{
                    fontSize: 18,
                    backgroundColor: '#000000',
                    color: '#ffffff',
                    padding: 20,
                    marginTop: 20,
                    maxWidth: 650,
                    margin: '0 auto',
                    textAlign: 'left'
                }}>

                    <div><h4>Hex Data</h4>
                        Hex Label: {selectedHex.name} <br/>
                        {renderTerrain()}
                        {renderHexRegion()}
                        {renderPrimaryKin()}
                        {renderSpecial()}
                        {renderAdventureSite()}

                        {showEncounter &&
                        <div>
                            <br/>
                            Party encounters <span
                            style={{textTransform: 'capitalize', fontWeight: 600}}>
          {this.state.encounter.name}
          </span> in the <span
                            style={{fontWeight: 600}}>{this.state.encounter.selectedTerrain.toUpperCase()}</span>.
                            <div style={{marginTop: 20}}>[flavor
                                text]: <i>{this.state.encounter.flavor}</i>
                            </div>

                            <div style={{marginTop: 20}}><small>&nbsp;&nbsp;--
                                Encounter details:
                                <a rel="noopener noreferrer" target='_blank' href={campaignLink}>
                                    Forbidden Lands Gamemaster's Guide</a></small>
                            </div>
                            <div style={{
                                fontSize: 14,
                                fontWeight: 400,
                                padding: 10,
                                textAlign: 'left'
                            }}>
                                It's not fancy (yet), but this generator does pick
                                random encounters based on terrain with the exact
                                weight shown in the Forbidden Lands
                                Gamemasters guide.<br/>This means The Stowaway will
                                only appear if LAKE terrain is selected,
                                the Ruins of Old appears in RUINS terrain 3 out of 18
                                possibilities (i.e. approx. 16.7% chance)
                                as shown in the Random Encounters table.<br/><br/>The
                                Bitter Reach and other material will be
                                added soon, including SmokeRaven encounters flavored
                                to fit Forbidden Lands like a spiked glove!
                            </div>
                        </div>
                        }
                    </div>


                </div>
            } else {
                return <div>No hex data</div>
            }
        }
        return (

            <div style={backgroundStyle}>

                <div style={{
                    backgroundColor: '#ffffff',
                    padding: 20,
                    opacity: 0.7,
                    width: '50vw',
                    margin: '0 auto'
                }}>
                    <center><h2>Forbidden Lands Random Encounters</h2>
                        <small>art by <a rel="noopener noreferrer" target='_blank'
                                         href='https://www.deviantart.com/ninjatic/gallery'>Ninjatic</a></small>
                        <br/>
                        <div>

                            <div style={{display: 'inline-block'}}>
                                <input onChange={this.handleChangeNoEncounter}
                                       type='checkbox' value='force encounter'
                                       checked={this.state.ignoreNoEncounterResults}/> ignore
                                "no encounter" results
                                <div
                                    style={{display: (showBeneathTheIce ? 'inline-block' : 'none')}}>
                                    &nbsp; -- &nbsp;&nbsp;<input
                                    onChange={this.handleBeneathTheIce}
                                    type='checkbox' value=''
                                    checked={this.state.onlyBeneathTheIceResults}/> make
                                    encounter check Beneath the Ice


                                </div>
                            </div>
                        </div>
                        <div>
                            <input
                                checked={this.state.selectedCampaign === 'ravenland'}
                                onChange={this.handleChangeCampaign} type='radio'
                                value='ravenland'/> Ravenland -&nbsp;
                            <input
                                checked={this.state.selectedCampaign === 'bitter reach'}
                                onChange={this.handleChangeCampaign} type='radio'
                                value='bitter reach'/> Bitter Reach
                        </div>
                        <div style={{margin: '0 auto'}}>
                            <table>
                                <tr>
                                    <td>
                                        <img alt="arrow" src={arrow6}
                                             onClick={() => this.handleArrowClick(6)}
                                             style={{
                                                 maxWidth: 30,
                                                 pointerEvents: 'all'
                                             }}
                                        />
                                    </td>
                                    <td>
                                        <img alt="arrow" src={arrow1}
                                             onClick={() => this.handleArrowClick(1)}
                                             style={{maxWidth: 30}}/>
                                    </td>
                                    <td>
                                        <img alt="arrow" src={arrow2}
                                             onClick={() => this.handleArrowClick(2)}
                                             style={{maxWidth: 30}}/>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <img alt="arrow" src={arrow5}
                                             onClick={() => this.handleArrowClick(5)}
                                             style={{maxWidth: 30}}/>
                                    </td>
                                    <td>
                                        <img alt="arrow" src={arrow4}
                                             onClick={() => this.handleArrowClick(4)}
                                             style={{maxWidth: 30}}/>
                                    </td>
                                    <td>
                                        <img alt="arrow" src={arrow3}
                                             onClick={() => this.handleArrowClick(3)}
                                             style={{maxWidth: 30}}/>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </center>

                    <div style={{margin: 20}}><b>Go ahead, pick one; we dare you
                        ---></b>

                        <input
                            placeholder='enter hex label'
                            onChange={this.handleChange} value={this.state.input}/>
                        <button onClick={this.handleClick}>Get Hex Data</button>
                    </div>
                    {renderHexData()}
                </div>


            </div>
        );
    }
}

export default HexTraveller;