import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import burgerjim from "../../../../assets/myz/burgerjim.jpg";
import girl from "../../../../assets/myz/apocalypse-child.jpg";
import note from "../../../../assets/myz/note-from-berke-about-cassandra.jpg";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Shootout at Burger Jim's",
    text: (
      <p>
        03-27-0001: This session begin in medias res (in the middle of the
        action) with the IL Gang battling zone ghouls at a FAST FOOD JOINT which
        we decided was Burger Jim's (an old Anchorage joint that is still in
        business decades later).
        <br />
        <br />
        We had to struggle with tactical map and lighting for this session and
        the battle took the entire session. On the plus side it was fairly epic.
        The IL gang generally owned the zone ghouls. Some highlights of the
        battle were:
        <ul>
          <li>
            RIFLEMAN leader of ghouls killed Scooby with scrap rifle shot.
          </li>
          <li>
            Chromicus picked up a slingshot and shot a zone ghoul for FIVE point
            damage; that's a sharp rock.
          </li>
          <li>
            Double-Bird used telepathy to fry the rifleman's brains and break
            him.
          </li>
          <li>
            Rowbert interrogated broken rifleman to learn location of artifact
            and information about prisoner in one of the office rooms.
          </li>
          <li>
            Hulagu had mutation misfire and got FROG LEGS mutation (our first
            second mutation!) and proceeded to hop around with a KATANA chopping
            ghouls down.
          </li>
          <li>
            Mysterious apocalypse girl shot Jebe with shotgun when he opened
            door to storeroom; and then she disappeared by going invisible.
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: burgerjim,
      altText: `Burger Jim's is FAST FOOD JOINT in a rot free oasis sector right next to the Ark.`,
      className: "imageRight",
      imageCaption: "Burger Jim's",
    },
  },
  {
    title: "Loot, Artifact, and Apocalypse Girl",
    text: (
      <p>
        The IL Gang kept the rifleman alive for now after interrogating him.
        They found a DRESS in one of the offices and added +5 DEV culture to the
        Ark.
        <br />
        <br />
        Jebe opened another office door. He got a glimpse of a little girl who
        seemed to appear out of nowhere. She said "NO", blasted Jebe with a
        shotgun, and then turned invisible. Chromicus and others tried to reason
        with her but realized she was gone. She did, however, drop the shotgun
        which Chromicus picked up hoping it would be even better than the
        slingshot of doom he used earlier.
        <br />
        <br />
        The party gathered up over a dozen bicycle chains, a dozen slingshots,
        and a scrap rifle as well as 10 units of scrap. All in all a decent
        haul.
        <br />
        <br />A crow flew into the scene and landed on Hetu's arm to everyone's
        surprise. "What", he asked laconically, "you guys don't have a bird that
        can deliver messages?"
        <br />
        In a leather tube on the crows leg was a letter from Berke pointing the
        IL Gang toward the former Ark mutant Cassandra who is now the famed
        scrap oracle of Sector A05. She is said to have beyond normal mental
        powers and a masterful grasp on toxins and poisons from the zone.
      </p>
    ),
    image: {
      imagePath: girl,
      altText: `Mysterious apocalypse girl found in storage room with shotgun at Burger Jim's`,
      className: "imageLeft",
      imageCaption: `Apocalypse Girl`,
    },
  },
  {
    title: "Wrapup",
    text: (
      <p>
        XP for the session was THREE for each mutant awarded at session end.
        <br />
        <br />
        Projects were advanced on auto-pilot with random rolls for which project
        got points:
        <ul>
          <li>MUSEUM: +3</li>
          <li>TEMPLE +1 (COMPLETE - rebuilt from Helldrivers attack)</li>
          <li>DEFENSES: +3</li>
        </ul>
        Next session starts 2pm, 03-27-0001 in game. The party is planning on
        camping at Burger Jim's and building some stuff from scrap. They know,
        however, that the threat level of the sector is 8, so it may be a bumpy
        night.
        <br />
        <br />
        Check out Session Body Count at:{" "}
        <a href="./mutant-year-zero-anchortown-population-notable-events">
          SESSION BODY COUNT AND POPULATION
        </a>
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a href="./mutant-year-zero-anchortown-graveyard">
          Anchortown Graveyard to pay your respects, especially for Scooby the
          hound.
        </a>
        .
      </p>
    ),
    image: {
      imagePath: note,
      altText: `A note from Boss Berke delivered by Crow to Hetu`,
      className: "imageLeft",
      imageCaption: "Note from Berke",
    },
  },
];

const MYZAnchortownSession14 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 14: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  The IL Gang takes out zone ghouls at Burger Jim's, finds more
                  loot, encounters mysterious apocalypse girl, and receives a
                  message from Boss Berke. hunt.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                {/*<Card.Title style={cardTitle}>Shootout at Burger Jim's, 03-27-0001 - 03-27-0001</Card.Title>*/}
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession14;
