import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import avenger from "../../../../assets/myz/class-iv-avenger-portrait.jpg";
import sparky from "../../../../assets/myz/sparky-the-small-vicious-dog.webp";
import level2DoorScan from "../../../../assets/myz/level-2-door-scan.jpg";
import medicalBay from "../../../../assets/myz/level-2-medical-bay.webp";
import projectGenesis from "../../../../assets/myz/project-genesis.mp3";
import warningLevel2 from "../../../../assets/myz/system-failure-2.mp3";
import corpse from "../../../../assets/myz/jail-cell-corpse.jpg";
import elsDownload from "../../../../assets/myz/code-download.gif";
import dutyRoster from "../../../../assets/myz/security-center-duty-roster.jpg";
import dorothy from "../../../../assets/myz/dorothy-portrait.jpg";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Level 2 Command Center Exploration",
    text: (
      <p>
        We pick back up with the IL Gang and Oilfield clones in the Command
        Center of Level 2 in Command Center Charlie. Bayazid's extraction of
        information from the computer systems seems to have attracted automated
        guard bots. The party fights four drone soliders and two guard robots
        led by a Class IV Avenger. Sparky the Small Vicious Dog bits the dust
        after valiantly, bravely, and stupidly bites through a hydraulic wire in
        the elbow of a guard robot who replies to the affront by shooting the
        little pooch in the head with a laser pistol.
        <br />
        <br />
        Vanja the clone moves the paralyzed Chromicus to the far corner of the
        command center as the robots rage around trying to kill our heroes.
        Smoke Raven sustains a critical DAMAGED SHIN and gets another cosmetic
        change due to mutation misfire. His legs become taloned bird legs which
        goes with his fangs, beak, and feathered hair. Damage is dealt but the
        robots fall in the end. Ghost Bird uses clairvoyance on the defunct
        Class IV Avenger (details in image popup) and learns details about the
        model and insight into how the robots are connected to the Command
        Center network and the party cannot stay in one area for more than an
        hour without a patrol roll to see if they are discovered.
        <br />
        <br />
        The party ponders the message from the scanner pad next to the elevator
        (see graphic) before continuing to investigate.
        <br />
        <br />
      </p>
    ),
    image: {
      imagePath: avenger,
      altText: `A prototype that was never cleared for large-scale production. Similar to the Class III, but with even stronger composite armor and more advanced targeting programs. Unfortunately, the Class IV Avenger suffered from instability problems that were never solved before the end of the Enclave War.`,
      className: "imageLeft",
      imageCaption: "Class IV Avenger",
    },

    image2: {
      imagePath: sparky,
      altText: `Sparky. He was small. He was vicious. His name: Sparky the Small Vicious Dog.`,
      className: "imageRight",
      imageCaption: "Sparky the Small Vicious Dog",
    },
  },
  {
    title: "Medical Bay",
    text: (
      <p>
        The next area explored in the Medical Bay. It's an area where everything
        is dimly lit and in sleep mode. There is a receptionist desk with pencil
        holder, touchpad, lamp, and an apple-shaped button. Glandhand pushes the
        red apple button which activates low lighting, machines wake up and
        muzak plays. There is a large butterfly on top of monitor on the
        reception desk. It starts to fly with psychedelic wings that mesmerize.
        This as a lone psionic butterfly trapped in the military complex. It is
        quickly killed and smoke Raven expresses wonderment and interest in the
        creature. As a Stalker he collects lore from the Zone and hungers for
        knowledge and solving mysteries.
        <br />
        <br />
        Bayazid sits at the computer system and starts making JURY-RIG rolls. As
        he hacks like a swordfish, Gladhand examines a metal box labelled
        "DECONTAMINATOR" with a transparent lid that has a number pad on the
        side. Inside the box is a thumbdrive. Gladhand inputs a random number on
        the device and a warning pipes through the complex intercoms and a
        battle erupts in the corridor outside the Medical Bay.
        <br />
        <br />
        During the battle Chromicus is shot for 5 points damage by a Digimax
        Hoverdrone. Even paralyzed, his SHAKE IT OFF allows him to survive the
        damage. Frustrated, the Hoverdrone takes out it venom of Theodore, the
        bartending robot from the RUST CASTLE and blows his fuses to bits.
        Theodore has served his last drink and his battlesaw will rattle no
        longer. Also, one of the three IL Gang minions dies in the battle.
        <br />
        <br />
        The Medical Bay has a Cryo chair that heals damage and a Body Bed that
        can heal critical injuries. Vanja is healed and Chromicus' SHATTERED
        SPINE is fixed and he is back up on his feet high-fiving everyone!
        <br />
        <br />
        Bayazid has figured out the medical bay computer and finds a holotape
        with information about PROJECT GENESIS (see audio file linked here;
        SPOILER ALERT for metaplot elements). Unable to open the decontaminator,
        the party continues exploring.
      </p>
    ),
    image: {
      imagePath: medicalBay,
      altText: `Medical Bay in Command Center Charlie.`,
      className: "imageLeft",
      imageCaption: "Medical Bay",
    },
    image2: {
      imagePath: level2DoorScan,
      altText: `Level 2 Elevantor Door scan shows his message.`,
      className: "imageRight",
      imageCaption: "Level 2 Door Scan",
    },
    audio2: {
      audioPath: projectGenesis,
      altText: `History of project genesis.`,
      className: "imageLeft",
      audioCaption: "Project Genesis Recording (SPOILER ALERT)",
    },
  },
  {
    title: "Jail Cells and Security Center",
    text: (
      <p>
        The next area found is an area with a few jail cells. A small patrol of
        four Guard Robots is dispatched and then the IL Gang investigates a body
        sitting slumped against a wall in one of the cells. The corpse is
        wearing gloves and a GAS MASK with papers scattered everywhere (see
        story of the corpse in graphic popup). He was an unstable scientist
        researching the "Red Death", even from his cell, and even as he was left
        abanoned to die alone. In one of the other cells is a LIFE VEST
        artifact.
        <br />
        <br />
        Across the hall from the Jail Cells is the Security Center. Bayazid
        hacks into the system and finds a duty roster (see graphic) regarding
        the thumbdrive and why is was taken to the Medical Bay decontaminator,
        and there is a note with the passcode to the decontaminator. Bayazid
        finds information about downloading code for the ELS (Enhanced Level
        Security) protocol which might be their ticket to unlocking the elevator
        doors to the third level (since going back up is not an option during
        the hightened RAD: robot assisted detection security protocol). So, they
        go back and get the thumbdrive then return to the security center.
        <br />
        <br />
        Before Bayazid tries to hack into the ELS security download, Smoke Raven
        suggests everyone else take cover with guns drawn in case robots are
        alerted. The clones and IL gang members will hide to ambush from the
        jail cell area across the hall.
        <br />
        <br />
        Bayazid starts hacking and sure enough, robots are alerted. The robots
        are taken out without a problem and the ELS download is put on the
        thumbdrive.
        <br />
        <br />
        The party explores level 2 a bit and highlights are:
      </p>
    ),
    image: {
      imagePath: corpse,
      altText: `Among the papers are notes showing that the prisoner was a doctor trying to find a cure for sickness called "Red Death". His mind was affected and he had to be locked up, but books were provided to allow him to search for cure as others slowly evacuated over time. His last set of notes make it clear he knew he was alone in CCC, but he wore the gas mask and never gave up hope despite having no chance at all.`,
      className: "imageRight",
      imageCaption: "Corpse in Jail Cell",
    },
    image2: {
      imagePath: elsDownload,
      altText: `Enhanced Level Security download is required for accessing elevator movement to the third floor.`,
      className: "imageLeft",
      imageCaption: "ELS Download",
    },
  },
  {
    title: "We're Not In Kansas Anymore",
    text: (
      <p>
        Now the only piece of the puzzle is what will interface with the slot
        next to the elevator. The RAD Protol (Robot Assisted Detection) has been
        triggered by the ELS (Enhanced Level Security).
        <br />
        <br />
        The party finds the Robot Charging Center and the Robot Storage Area.
        Robot storage has torsos, heads, and undercarriages in heretic
        containers yoked to metal rails for movement aronud the center.
        Everything that controls the robot storage room is in a big competer
        work station in the corner of the room. So, once again Bayazid gets to
        work hacking.
        <br />
        <br /> Bayazid accesses a program that will let the party build a robot
        and control it with a primary, secondary, and tertiary voice-activated
        protocol. This protocol means the robot will obey orders it is given
        from any of the three voices with the precendence of obediance favoring
        primary, secondary, and tertiary in that order. They are not certain
        what happens if orders overlap or contract, or what the robots default
        programming is when not performing a specific action. But they decide to
        build and risk triggering more robot patrols. After a series of rolls to
        determine if they get to choose the components or if they are random,
        they get a robot!
        <ul>
          <li>
            The name the robot Dorothy in honor of the fallen Theodore
            (syllables of his name reversed as inspiration).
          </li>
          <li>
            Voice Activation Owners: Bayazid(primary), Gladhand (secondary),
            Smoke Raven (tertiary).
          </li>
          <li>HEAD: Pashin Ama 9</li>
          <li>CHASSIS: OZKUN TN75</li>
          <li>UNDERCARRIAGE: Orion V Bolo</li>
        </ul>
        Dorothy is charged up at the Robot Charging Station. Then she is loaded
        with the ELS security protocol and can interface with the elevator. As
        the elevator plunges downward, we hear another security warning about
        the generator array that sounds like pressure is mounting. Cut to
        credits and we transition to talking XP and wrapping up.
      </p>
    ),
    image: {
      imagePath: dutyRoster,
      altText: `On the computer system in the Security Center is a duty roster with a clue to the thumbdrive in the Medical Bay.`,
      className: "imageRight",
      imageCaption: "Duty Roster",
    },
    image2: {
      imagePath: dorothy,
      altText: `Build in the Robot Storage Area of CCC by the IL Gang. Named Dorothy is honor of Theodore.`,
      className: "imageLeft",
      imageCaption: "Dorothy",
    },
    audio2: {
      audioPath: warningLevel2,
      altText: `System Warning 2.`,
      className: "imageRight",
      audioCaption: "System Warning 2",
    },
  },
  {
    title: "Wrapup",
    text: (
      <p>
        <h3>Bookkeeping</h3>
        <ul>
          <li>Bitterbeasts: food harvested.</li>
          <li>
            Pure Plant Patrol: zone creatures attack and kill 2 humans on the
            patrol.
          </li>
          <li>
            Ark Reputation: 2 mutants arrive seeking citizenship in the Sunshine
            Plaza.
          </li>
        </ul>
        NOTE: Altair is housed at the Rust Castle along with Summer of Love bus.
        Chi Tzu is on the Steelcrest anchored off the island of Command Center
        Charlie.
        <br />
        <br />
        Next session starts in-game 06-23-0001, approximately dawn inside
        Command Center Charlie. The party is just getting off the elevator on
        floor 3.
        <br />
        <br />
        Base XP is 3 per character. Then, we discussed cool stuff that happened.
        Based on discussion, GM awards a bonus xp to one of the characters for
        each player. Some highlights discussed were:
        <ul>
          <li>
            Gladhand recognized symbol of medical bay door because he saw it in
            the healing book he uses frequently. Had idea to honor Theodore by
            switching syllables to name new robot Dorothy.
          </li>
          <li>
            Chromicus heavy hitting on digimax hoverdrone. Got out of body bed
            and high-fived everyone like a jazzy Lazurus.
          </li>
          <li>Bayazid jury-rigging the hell out of everything again.</li>
          <li>
            Hulagu still frog-leaping and wielding katana like he's not frail
            from low stats. Choice of COWARD as new talent nicely storylined.
          </li>
          <li>
            Smoke Raven did great fighting the Avenger, great use of mutation
            points, and had plan to do pincer move and take cover in Security
            Center.
          </li>
          <li>
            Ghost Bird uses clairvoyance on the defunct Avenger bot and in the
            Medical Bay.
          </li>
        </ul>
        Bonus xp went to Hulagu, Ghost Bird, and Chromicus.
        <h6>Critical Injury Roundup</h6>
        <ul>
          <li>
            Chromicus: GROIN HIT: -1 FIGHT, MOVE, FORCE; heal time 7 days (half
            time not yet done); started on 22nd.
          </li>
          <li>
            Ghost Bird: SPRAINED ANKLE: -1 MOVE, SNEAK; heal time 3 days
            (includes half time successful); started on 22nd.
          </li>
          <li>
            Smoke Raven: DAMAGED SHIN, -1 MOVE and SNEAK, HEALING TIME 7 days
            (half time not yet done).
          </li>
        </ul>
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        .
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
        <br />
        This session we lost Theodore the bartending robot from the Rust Castle,
        an IL Gang minion, and Sparky the Small Vicious Dog.
      </p>
    ),
  },
];

const MYZAnchortownSession49 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 49: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  The IL Gang and Oilfield clones battle robots and hack
                  computers as they try to solve getting the elevator to level 3
                  of Command Center Charlie.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession49;
