import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import bladesLogo from "../../../../src/assets/blades-in-the-dark/logo-blades-in-the-dark.png";
import sectionDivider from "../../../../src/assets/blades-in-the-dark/banner-bitd.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import { useEffect } from "react";

import cutter from "../../../../src/assets/blades-in-the-dark/tile-cutter.webp";
import hound from "../../../../src/assets/blades-in-the-dark/tile-hound.webp";
import leech from "../../../../src/assets/blades-in-the-dark/tile-leech.webp";
import lurk from "../../../../src/assets/blades-in-the-dark/tile-lurk.webp";
import slide from "../../../../src/assets/blades-in-the-dark/tile-slide.webp";
import spider from "../../../../src/assets/blades-in-the-dark/tile-spider.webp";
import whisper from "../../../../src/assets/blades-in-the-dark/tile-whisper.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "CUTTER",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <i>A dangerous and intimidating fighter.</i>
        <br />
        <br />
        In the underworld of the Dusk, there are scoundrels who take what they
        want at the bloody edge of a blade or with a threatening presence that
        can stop a man cold. They are the enforcers, the debt collectors, the
        killers, the leg-breakers, and the thugs, commonly known as Cutters.
        <br />
        <br />
      </div>
    ),
    image: {
      imagePath: cutter,
      altText: `Cutter: a dangerous and intimidating fighter.`,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "HOUND",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <i>A deadly sharpshooter and tracker.</i>
        <br />
        <br />
        There’s always a need for scoundrels who can find what others want to
        keep hidden. Whether that’s a deadbeat gambler trying to skip out on
        their debts, a treacherous informant or witness who’s going to squeal to
        the Bluecoats, or the trail to a treasure squirreled away in a secret
        vault—it’s the Hounds that ferret them out.
        <br />
        <br />
      </div>
    ),
    image: {
      imagePath: hound,
      altText: `Hound: a deadly sharpshooter and tracker.`,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "LEECH",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <i>A saboteur and technician.</i>
        <br />
        <br />
        The world is built on industry, and the underworld is no different.
        Leeches are the scoundrels who distill the drugs, refine the arcane
        essences, build the bombs, bandage the wounds, and forge the tools of
        the criminal trades—and knowing how to make things also means knowing
        how to break them.
        <br />
        <br />
      </div>
    ),
    image: {
      imagePath: leech,
      altText: `Leech: a saboteur and technician.`,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "LURK",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <i>A stealthy infiltrator and burglar.</i>
        <br />
        <br />
        There is no longer any sunlight—the world is plunged into eternal night.
        There are scoundrels who live in the darkness, who prowl the underworld
        unseen, trespassing where they will. They are the burglars, the spies,
        the infiltrators, the cut-throats—commonly called Lurks.
        <br />
        <br />
      </div>
    ),
    image: {
      imagePath: lurk,
      altText: `Lurk: a stealthy infiltrator and burglar.`,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "SLIDE",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <i>A subtle manipulator and spy.</i>
        <br />
        <br />
        It’s said that the primal substance of the world is not mere matter or
        strange plasms, it’s lies. Everything we do, everything we care about,
        everything we value—all lies we tell each other and ourselves. The world
        is a fabrication, a convenient fiction, a story—and the storytellers are
        Slides.
        <br />
        <br />
      </div>
    ),
    image: {
      imagePath: slide,
      altText: `Slide: a subtle manipulator and spy.`,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "SPIDER",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <i>A devious mastermind.</i>
        <br />
        <br />
        The underworld may appear on the surface to be a chaotic array of
        warring factions and bloody deeds, but it has a beautiful order to those
        who are able to see it. This pattern is the web of connections, favors,
        vendettas, and secrets that bind the criminal world together. The
        scoundrels who pluck the strands of this web are known as Spiders.
        <br />
        <br />
      </div>
    ),
    image: {
      imagePath: spider,
      altText: `Spider: a devious mastermind.`,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "WHISPER",
    text: (
      <div class="morkBorgFont morkBorgTitleText">
        <i>An arcane adept and channeler.</i>
        <br />
        <br />
        Duskwall is a haunted place—plagued by rogue spirits consumed by
        vengeance, by cunning demons manipulating humans for their own
        inscrutable purposes, and by even stranger horrors lurking in the space
        just beyond sight and reason. To go into this shadowy world without
        knowledge of the arcane and the occult is to walk unarmed into the lair
        of the enemy. The Whispers are the sentinels who watch the greater
        darkness—staring into the void so others don’t have to.
        <br />
        <br />
      </div>
    ),
    image: {
      imagePath: whisper,
      altText: `Whisper: an arcane adept and channeler.`,
      className: "imageLeft",
      imageCaption: "",
    },
  },
];

const BITDClassesCore = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/blades-in-the-dark/mood-doskvol_street-scene-01.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={bladesLogo}
                  style={{ maxWidth: "200px" }}
                  alt="Blades in the Dark campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Blades in the Dark: Core Classes
                </h1>
                <p className="lead leadExtra">
                  <div
                    class="bitdFont morkBorgText"
                    style={{ textAlign: "center" }}
                  >
                    💀{" "}
                    <a
                      style={{ color: "yellow" }}
                      href="./blades-in-the-dark-overview"
                    >
                      What is Blades?
                    </a>{" "}
                    💀
                  </div>
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img
                src={sectionDivider}
                style={{ maxHeight: "169px" }}
                alt="section divider"
              />
              <Card.Body>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BITDClassesCore;
