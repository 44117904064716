import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import noMutant from "../../../../assets/myz/session-48-no-mans-land.jpg";
import hellsBells from "../../../../assets/myz/hells-bells-plant-monster.jpg";
import hellsBells2 from "../../../../assets/myz/session-48-hells-bells.jpg";
import commandCenter from "../../../../assets/myz/session-48-command-center.jpg";
import alluringBells from "../../../../assets/myz/alluring-bells.mp3";
import noatunHistory from "../../../../assets/myz/enclave-noatun-history.mp3";
import warningLevel1 from "../../../../assets/myz/system-failure-1.mp3";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "No Mutants Land",
    text: (
      <p>
        The Green Man leads the party to Command Center Charlie. As the party
        approaches they see four bunkers on a hill with a sattelite dish and a
        radio attenae. Surrounding the hill is a deep, wide trench. Around the
        edges of the trench is barbed wire. There are two small bridges
        obviously meant to funnel foot traffic into a very narrow passage that
        would maximize the ability of defenses to do damage.
        <br />
        <br />
        Beto and the other clones become excited when they see the Noatun logo
        under the dust and rust on the backside of the sattelite dish. They
        begin to rush forward despite the Green Man's warnings. Beto commands
        Vanja to follow but Chromicus makes her stay with the party. As the
        clones cross the foot bridge, four huge 50 caliber, automatic, mouted
        machines guns open fire from the four bunkers. They rise up as shielded
        cylindrical enclosures that have 360 degree rotation to cover the entire
        approach to an unassuming metal door in the middle of the hill,
        presumably the entrance to CCC.
        <br />
        <br />
        The clones flee the No Mutants Land alive but injured. The Green Man
        says "I must talk to Charlie". He goes into a prostration and melds with
        the ground for a little over half an hour. When he finally stands, he
        explains that he is not like the humans or the mutants. He will walk to
        the center of the hill. The guns will track him but they will not shoot
        as their programming cannot understand his unique plant-like physiology.
        Once the guns are trained on him the party can approach. "Either fast or
        sneaky" the Green Man recommends.
        <br />
        <br />
        The party decides to make for the entrance all at once to give the four
        guns many targets. Chromicus flys, Ghost Bird hoverboards (holding Spark
        the Small Vicious dog), Gladhand uses SPRINTER mutation, and Boss Hulagu
        uses FROG LEGS to jump. Theodore the robot, the three surviving IL Gang
        members, and the clones (Beto, Darryl, Harry, and Vanja), follow trying
        to sneak and move without attracting too much fire from the guns.
        <br />
        <br />
        As the group makes for the entrance, Chromicus swoops down to bodyguard
        Ghost Bird (who failed to use Sparky as cover successfully!); Chromicus
        crashes into the ground and takes a little extra damage getting to the
        door; he suffers a GROIN HIT. Chromicus also threw handfuls of rocks
        which aggravated the gun sensors and give the fast moving front line of
        the party a bonus on their rolls. Once everyone is at the door,
        Chromicus uses the card given to them by Nikkil of the Rust Castle.
      </p>
    ),
    image: {
      imagePath: noMutant,
      altText: `Deadly 50 caliber machine guns guard the entrance to Command Center Charlie`,
      className: "imageLeft",
      imageCaption: "No Mutants Land",
    },
  },
  {
    title: "Hells Bells",
    text: (
      <p>
        <br />
        <br />
        <br />
        <br />
        As the party enters the bunker, they hear a mysterious, beautiful song.
        It penetrates into the mind and makes the listener slave to a compulsion
        to seek out the source of the sound. As Chromicus and others succumb to
        the sound, a heat laser pops up out of a murder hole to fire on the
        characters. The ones who have become slave to the musical, eerie song
        echoing through the bunker pay no mind to the damage or the shouts of
        their companions. They are entranced and will even fight and kill
        friends and loved ones to reach the sound.
        <br />
        <br />
        Gladhand gets Bayazid, Theodore, and Harry to help move a metal
        footlocker to block the laser when it appears again to shoot at the
        backs of the mutants walking down the hall toward the sound. As the
        mutants find a metal cover in the floor with stairs into a generator
        room, the laser destroys itself when it fires and bounces off the
        footlocker.
        <br />
        <br />
        Outside, Smoke Raven has a wild intuition as he is the only one to
        faintly hear the sounds from the entrance to the bunker. He warns
        everyone outside to stuff fabric in their ears. This gives a bonus on
        the check to resist the sounds as they also enter the tunnels. Chromicus
        and others wander into the generator room like zombies and see a
        nightmarish, but pretty chaos of plant stalks and foliage covering the
        generator and filling the room. The plants are a deep green color, and
        measure over 6 feet tall. They have stems ending in three pretty
        flowerheads of blue and purple, shaped like a bell. The flowerhead is
        covered by teeth and small tentacle mouths. Chromicus, Ghost Bird, Beto
        and the others all walk up and willingly put their heads into the mouths
        of the deadly flowers known as Hells Bells. Ghost Bird suffers a
        SPRAINED ANKLE when broken. Note: the Hells Bells are a great, deadly
        creature found in the{" "}
        <a
          href="https://www.drivethrurpg.com/product/305844/Zone-Bestiary"
          rel="noreferrer"
          target="_blank"
        >
          Zone Bestiary
        </a>{" "}
        on DriveThruRPG.)
        <br />
        <br />
        Meanwhile, Smoke Raven and an IL Gang member are entranced and heading
        to another bunker room that also has a Hells Bells creature. Bayazid
        commands Theodore to try and hold back SmokeRaven, and the two
        non-entranced IL Gang members go heavy swinging spiked bats to save the
        other IL Gang member. Bayazid races toward the generator room.
        <br />
        <br />
        In the generator room, Chromicus breaks with a DAMAGED SPINE; he is
        paralyzed, again! (He was also paralyzed with a broken spine way back in{" "}
        <a
          href="https://www.smokeraven.com/mutant-year-zero-session-16-anchortown"
          rel="noreferrer"
          target="_blank"
        >
          Session 16
        </a>
        , but this time there is no Jebe to cure critical injury.)
        <br />
        <br />
        Gladhand jams his shield into a crevice to block a second heat laser
        popping up from a murder hole, and then he starts sawing away at the
        hellish plant with a CHAIN KNIFE and almost takes it out
        single-handedly. Bayazid and others arrive. and the danger is
        eliminated. Once both Hells Bells are killed, the song ends.
      </p>
    ),

    image: {
      imagePath: hellsBells,
      altText: `The alluring song of Hells Bells enslaves human and mutant alike.`,
      className: "imageRight",
      imageCaption: "My Generator!",
    },
    image2: {
      imagePath: hellsBells2,
      altText: `The alluring song of Hells Bells enslaves human and mutant alike.`,
      className: "imageLeft",
      imageCaption: "Hells Bells",
    },
    audio: {
      audioPath: alluringBells,
      altText: `The alluring song of Hells Bells enslaves human and mutant alike.`,
      className: "imageLeft",
      audioCaption: "Alluring sounds of the Hells Bells",
    },
  },
  {
    title: "Command Center and Noatun Enclave History",
    text: (
      <p>
        <br />
        <br />
        <br />
        <br />
        The lights flicker and an automated warning announcement is broadcast
        throughtout the level. Bayazid jury-rigs the generator and is able to
        restore power to the lights, unlock the metal gate to the stairs down to
        level 2, but the doors into the bunker from the outside cannot be opened
        as CCC enters a minor lockdown do to failing generator as described in
        warning announcement.
        <br />
        <br />
        The party explores level 2 a bit and highlights are:
        <ul>
          <li>
            Firing Range: Smoke Raven blows away two Digimax Hoverdrones before
            they can do a damn thing, and another chain knife is found under the
            shelf of firing range counter.
          </li>
          <li>Armory: alas, nothing more than 4 scrap.</li>
          <li>
            Command Center: large command center with video screens and dimly
            lit workstations along semi-circular counters. Bayazid finds how to
            play video about the history of Noatun Enclave (see audio file
            above; SPOILER WARNING).
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: commandCenter,
      altText: `Command Center in Charlie.`,
      className: "imageLeft",
      imageCaption: "Command Center",
    },
    audio: {
      audioPath: warningLevel1,
      altText: `The alluring song of Hells Bells enslaves human and mutant alike.`,
      className: "imageLeft",
      audioCaption: "CCC Warning Level 1",
    },
    audio2: {
      audioPath: noatunHistory,
      altText: `The alluring song of Hells Bells enslaves human and mutant alike.`,
      className: "imageLeft",
      audioCaption: "Enclave: Noatun History Recording",
    },
  },
  {
    title: "Wrapup",
    text: (
      <p>
        <h3>Bookkeeping</h3>
        <ul>
          <li>Bitterbeasts: food harvested.</li>
          <li>
            Pure Plant Patrol: zone creatures attack and kill 2 humans on the
            patrol.
          </li>
          <li>
            Ark Reputation: 2 mutants arrive seeking citizenship in the Sunshine
            Plaza.
          </li>
        </ul>
        NOTE: Altair is housed at the Rust Castle along with Summer of Love bus.
        Chi Tzu is on the Steelcrest anchored off the island of Command Center
        Charlie.
        <br />
        <br />
        Next session starts in-game 06-22-0001, approximately noon inside
        Command Center Charlie. The party is on the 2nd floor inside the Command
        Center room with robots marching to attack.
        <br />
        <br />
        Base XP is 3 per character. Then, we discussed cool stuff that happened.
        Based on discussion, GM awards a bonus xp to one of the characters for
        each player. Some highlights discussed were:
        <ul>
          <li>
            Gladhand chainsawing the hell out of the Hells Bells and mounting
            one-man rescue.
          </li>
          <li>
            Chromicus bodyguarding Ghost Bird while in flight; throwing rocks to
            decalibrate guns.
          </li>
          <li>
            Bayazid jury-rigging the hell out of everything; playing the Noatun
            history tape; and commanding Theodore to hold back the entranced
            Smoke Raven.
          </li>
          <li>
            Smoke Raven heard the bells from afar and suggested stuffing fabric
            in ears to defray the alluring sounds.
          </li>
        </ul>
        Bonus xp went to Chromicus, Smoke Raven, and Bayazid.
        <h6>Critical Injury Roundup</h6>
        <ul>
          <li>
            Chromicus: GROIN HIT: -1 FIGHT, MOVE, FORCE; heal time 7 days (half
            time not yet done); started on 22nd.
          </li>
          <li>
            Chromicus: DAMAGED SPINE: paralyzed; permanent if not healed in 8
            days, includes half time successful); started on 22nd.
          </li>
          <li>
            Ghost Bird: SPRAINED ANKLE: -1 MOVE, SNEAK; heal time 3 days
            (includes half time successful); started on 22nd.
          </li>
        </ul>
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        .
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
];

const MYZAnchortownSession48 = () => {
  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 48: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  The IL Gang and Oilfield clones enter Command Center Charlie
                  to face unknown dangers.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession48;
