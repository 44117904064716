import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import mirror from "../../../../assets/myz/artifact-aranmula-kannadi-card.jpg";
import cereal from "../../../../assets/myz/cereal-card.jpg";
import mimirSign from "../../../../assets/myz/remains-metal-sign-mimir.jpg";
import hologram from "../../../../assets/myz/remains-hologram-1.gif";
import yorrick from "../../../../assets/myz/yorrick.jpg";

const cardTitle = {
  paddingTop: 10,
  paddingLeft: 10,
  paddingBottom: 10,
  backgroundColor: "rgba(221, 117, 28, .7) ",
};
const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Bookkeeping",
    text: (
      <p>
        Party is ready to travel starting morning of 03-18-0001. They are have
        15 Kurtzers in town and the awe-inspiring, four-armed, four-legged,
        two-headed mutant Janis (See{" "}
        <a
          href="./mutant-year-zero-session-08-anchortown"
          rel="noreferrer"
          target="_blank"
        >
          Session 08
        </a>
        ).
        <br />
        <br />
        GM reminded party they are all hovering around 5 rot points each, but
        our stalwart party decides to hit new zones on their way home to The
        Sunshine Plaza; and this despite the introduction of{" "}
        <a
          href="./mutant-year-zero-rot-critical-injuries"
          rel="noreferrer"
          target="_blank"
        >
          Rot Critical Injury
        </a>{" "}
        rules (adopted from Reddit author{" "}
        <a
          href="https://www.reddit.com/user/jeremysbrain"
          rel="noreferrer"
          target="_blank"
        >
          u/jeremysbrain)
        </a>
        .
        <br />
        <br />
        XP Spend saw party members picking LIGHT EATER to offset the constant
        struggle to find grub. We also got two mutants picking up COMBAT VETERAN
      </p>
    ),
    image: {
      imagePath: yorrick,
      altText: ``,
      className: "imageLeft",
    },
  },
  {
    title: "Sector D10 - Factory 1 [03-18-0001]",
    text: (
      <p>
        The mutants enter this sector and before too long they hear an explosion
        and see some smoke on the horizon. Moments later the air feels heavy,
        then ionized. The mutants all dive for cover having now experienced the
        ravages of sudden zone weather patterns. They huddle for a few minutes
        as an ELECTRICAL STORM pounds the tattered earth. No one is hurt.
        <br />
        <br />
        Emerging from their cover they continue until they find a factory. Smoke
        Raven's ARCHAEOLOGIST talent gives him the impression that the explosion
        not only came from this factory, but that the electrical storm may have
        somehow been precipitated by that explosion. The party enters the
        factory to search around.
        <br />
        <br />
        They find some scrap and Jebe finds a shiny object. Looking into it, he
        sees his face cleary for the first time in his life. He is rocked by the
        experience and suffers a 2D6 empathy attack from this newly discovered
        artifact, the ARANMULA KANNADI. Then, suddenly, the air is sucked from
        the factory as further mysterious effects of the factory explosion
        manifest. Everyone runs to get out. Rowbert stumbles lost, and after a
        couple of failed rolls, barely gets outside alive. This time he left his
        helmet on too tight we suspect.
      </p>
    ),
    image: {
      imagePath: mirror,
      altText: `The aranmula kannadi mirror is a beautiful handmade mirror in a well-preserved wooden case.`,
      className: "imageLeft",
    },
  },
  {
    title: "Sector E09 - Car Park 3 [03-18-0001]",
    text: (
      <p>
        Two of the next three zones on the return journey are derelict car
        parks. This helps explain how the Sunshine Plaza came to have a
        perimeter of ruined vehicles. While our resident gearhead Skut may have
        been disappointed that none of the cars looked repairable, the party
        found several interesting items.
        <br />
        <br />
        In Sector E09 car park, they found a consumable artifact, namely CEREAL.
        Check out the popup for stats on this delicious item; it's Clark Stanley
        dontcha know?
      </p>
    ),
    image: {
      imagePath: cereal,
      altText: `A colorful box filled with bits of grub. The musty, sweet crunchy bits inside are very refreshing to 
        tired Zone wanderers. The Old Ones’ really knew how to get the day started.`,
      className: "imageRight",
    },
  },
  {
    title: "Sector E08 - Car Park 1 [03-18-0001]",
    text: (
      <p>
        In Sector E08 we have even more rusted vehicles overgrown with twisted
        zone vegetation. In the front seat of one the cars is some colorful
        fabric. Double Bird takes a closer look. As he grabs the dress, he hits
        a button on a piece of square metal under the fabric. Suddenly a
        holographic projection arises from the metal square.
        <br />
        <br />
        The mutants gather around, the Kurtzers especially in awe and scratching
        their antler head pieces. After carefully study, the party realizes it
        is a map of sorts. They can determine that a red light in the display
        correlates to the military base they found in{" "}
        <a
          href="./mutant-year-zero-session-02-anchortown"
          rel="noreferrer"
          target="_blank"
        >
          Session 02
        </a>
        . Using that as a reference, another nearby red-dot leads them to
        believe there is another military base in Sector Q12.
      </p>
    ),
    image: {
      imagePath: hologram,
      altText: `Metal square projects light as a sort of map revealing another Military Base in Sector Q12`,
      className: "imageLeft",
    },
  },
  {
    title: "Sector E07 - Ash Desert 4 [03-18-0001]",
    text: (
      <p>
        The last sector right to the east of the Ark is simply Ash Desert.
        Nothing is seen or encountered. Nothing but strong winds blowing grit,
        sand, and toxic particulates back and forth in a ceaseless wasteland
        filled with nothing at all; not hope, not even danger.
      </p>
    ),
    image: {
      imagePath: mimirSign,
      altText: `A metal sign with the word Mimir. It looks like the design on the piece of paper from the diary found
        at the Pure Plant.`,
      className: "imageLeft",
    },
  },
];
const section2Data = [
  {
    title: "Scene: Chromicus",
    text: (
      <p>
        03-18-0001: the party arrives at the Ark an hour or two after nightfall.
        A few mutants that could not sleep see our party arrive with extra
        mutants including Janis. Word begins to spread and many more mutants
        come running, rubbing sleep from their eyes, to see the newcomers. Krao
        and Skag are in attendance at this impromptu, late night gathering.
        <br />
        <br />
        Rowbert tells the story of the madman Kurtz and how they travelled to
        his amusement park to get medicine for The People and how they detonated
        a bomb that destroyed the sector, and finally that they brought new
        mutants to the Ark. Rowbert's performance nets him three grub and
        applause. Then, Chromicus steps up. Here is summary of his scene (see
        the writeup for <a href="./mutant-year-zero-scenes">MYZ Scenes</a> as
        inspired by the other Free League game Vaesen):
        <br />
        <br />
        After Rowbert shared the tale of Kurtz and the Amusement Park with the
        gathered People, Chromicus steps forward with Janis by his side. The two
        giant men were intimidating each on their own, but as a pair they are
        especially menacing.
        <br />
        <br />
        “In his prime,” Chromicus says, threateningly, “Kurtz would have led his
        people here to slaughter you all. Your defenses are poor. You don’t even
        have a Watchtower. Kurtz would have mowed you down. He’s gone, but other
        threats are out there. Janis and I, and the Exkurtzers, we just want a
        good place to survive. We could scrap with you but we’d rather work with
        you and build up the Defenses to protect the People.”
        <br />
        <br />
        Chromicus presents the polaroid camera artifact and the crates of
        medicine he has been carrying. He shows the generator but holds onto it
        until it is needed. Then he looks Boss Skag up and down. “You look like
        you are more concerned with your own petty power than building up
        defenses for the good of everyone. Prove me wrong and help build the
        defenses.”
        <br />
        <br />
        He tries to INTIMIDATE Boss Skag, but it is Intimidate against
        Intimidate since both men are Enforcers. Skag wins the Intimidate roll.
        The men stare each other down, then Skag bends down, picks up a rock and
        wings it across the distance and upside Chromicus' head. Chromicus
        doesn't move except to shake his head and put his hand to his temple.
        Skag and his crew leave with begrudging respect for how Chromicus
        stepped up.
        <br />
        <br />
        After Chromicus' scene, the mutants wash off rot from the zone. They
        experience their first serious wake-up call about permanent rot when
        Jebe gets another point; Rowbert and Chromicus get a permament point;
        and, Double Bird and Smoke Raven get hit with two points each.
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
    },
  },
  {
    title: "Scene: Hulagu",
    text: (
      <p>
        03-19-0001: Boss Berke comes up to Hulagu the next morning. "Good job on
        the medicine. Hey look, I know you're the one whose been racketeering my
        boys to shake em down for grub. I'll consider it no harm done if you can
        help me with a little problem I've been having."
        <br />
        <br />
        "You see, there's this upstart wanna-be boss named Torque; a real
        scrawny, hot-headed weasel that fixes dog fights. If you could see your
        way to roughing him up a little without him knowing I called the hit, it
        would help me out."
        <br />
        <br />
        Hulagu agrees and takes some of his boys over to push on Torque. They
        see a dog that lost fight bleeding and abandoned on the floor as Torque
        and his friends celebrate with the winning pooch. Rolls are made and
        while Torque wasn't roughed up too bad, the message that he's being
        watched is clear and Hulagu's favor to Berke is still valid.
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageRight",
    },
  },
  {
    title: "Scene: Jebe",
    text: (
      <p>
        03-19-0001: Grandma Yoya comes around and is glad to see Jebe is alive
        and well. "But you look sad boy. I know you miss Subu. I heard Vik the
        Fixer has been setting up dog fights and may have a new dog for you, or
        you can take one."
        <br />
        <br />
        Jebe takes Skut with him to try and sneak into Vik's area of the Ark and
        grab one of the dogs. The rolls fail, but as luck would have it, on
        their way back to their dens, Jebe and Skut see the wounded dog from
        Hulagu's scene.
        <br />
        <br />
        Jebe makes rolls to calm down the wounded pooch and gains a new best
        friend and fighting companion named <strong>Scooby</strong>.
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageRight",
    },
  },
  {
    title: "Scene: Smoke Raven",
    text: (
      <p>
        03-19-0001: Smoke Raven gifts the hologram projector to Grit. They visit
        the Temple of Endurance together, and after praying, they discuss their
        plans for how to arrange their den and how to work on a better future
        for The People. This was a nice scene that didn't culminate with any
        rolls, but fleshed out what Smoke Raven's all about with his companion
        Grit and his dedication to the temple project the group finished a
        couple of sessions ago.
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageRight",
    },
  },
  {
    title: "Other Ark Events",
    text: (
      <p>
        <ul>
          <li>
            Boss Krao thanks the party for their bravery. While they first
            brought the infection on the Ark by going into the Zone, and have
            now cured it; she appreciates the sudden influx of new artifacts
            entrusted to the Order of the New Dawn.
          </li>
          <li>
            The party learns that Skag sent Calihan the Promiscuous Stalker to
            track the Helldrivers back to their headquarters. Apparently Elson
            the dog mutant and Hulagu's NPC to protect, little Kublai, tagged
            along. There was a fight near the Helldrivers sector and only
            Calihan made it back. He has no idea what has happened to Elson and
            Kublai, but he does know the location of the Helldrivers within
            three sectors accuracy.
          </li>
          <li>
            Party turns in artifacts: DRESS, POLAROID CAMERA, ARANMULA KANNADI.
          </li>
          <li>
            Work on projects is done, but there are two critical failures (i.e.
            pushed rolls with a fail among results).
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
    },
  },
];
const section3Data = [
  {
    title: "",
    text: (
      <p>
        We ended session having taken care of grub/water for healing and
        maintenance, rot points, and assigning XP points. Everyone got 4 except
        Chromicus got 5. We tend to run out of time for the XP discussion but we
        made time for it again and realized it's just a really important part of
        the game. It helps underscore the rewards of roleplay versus rollplay
        and it deserves to have the time set aside for it.
        <br />
        <br />
        Next session starts morning of 03-20-0001 in the Ark.
        <br /> We will add mutation points, allow for XP spend, and then we will
        do projects and scenes before finding out if party is going back into
        the zone or what.
        <br />
        The body count roll is still 2D6 because the medicine JUST arrived.
        <br />
        <br />
        Note that since we had two fails on pushed project rolls this session;
        instead of simply saying a mutant died, we are using a tiny little
        project mishap chart with the following items.
        <strong>Project Mishaps</strong>
        <br />
        <ul>
          <li>ACCIDENT: Random mutant dies from work accident.</li>
          <li>
            POLITICAL SNUB: project pisses off a boss (-1 on all skill rolls
            next time this project is worked on).
          </li>
          <li>
            POOR WORK: some work has to be redone. D6 points on Project lost.
          </li>
          <li>
            SCOPE CREEP: project is harder than it looks. Increase work points
            required by D6.
          </li>
        </ul>
        <br />
        <br />
        <ol>
          <li>
            Boss Berke feels POLITICAL SNUB regarding STABLES. All rolls for
            project Stables -1 next time it is worked on.
          </li>
          <li></li>
        </ol>
        <br />
        Check out Session Body Count at:{" "}
        <a href="./mutant-year-zero-anchortown-population-notable-events">
          SESSION BODY COUNT AND POPULATION
        </a>
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a href="./mutant-year-zero-anchortown-graveyard">
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
    },
  },
];

const MYZAnchortownSession09 = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 09: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  Our mutants find several artifacts without a fight whilst
                  leading 15 newly rescued cult members back to The Sunshine
                  Plaza to take care of business.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                <Card.Title style={cardTitle}>
                  Journey Home, 03-18-0001
                </Card.Title>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
                <Card.Title style={cardTitle}>
                  Home Again, 03-18-0001 to 03-19-0001
                </Card.Title>
                <Card.Text>
                  <ContentContainer data={section2Data} />
                </Card.Text>
                <Card.Title style={cardTitle}>Wrapup</Card.Title>
                <Card.Text>
                  <ContentContainer data={section3Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession09;
