import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import delta from "../../../../assets/myz/delta-kcg-966.png";
import lemmy from "../../../../assets/myz/lemmy-token.png";
import patrick from "../../../../assets/myz/ncp-patrick.png";
import adalia from "../../../../assets/myz/inhabitant-adalia.jpg";
import berke from "../../../../assets/myz/boss-berke.png";
import nikiil from "../../../../assets/myz/nikkil-and-merchants.jpg";
import elwith from "../../../../assets/myz/elwith-and-friends.jpg";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Bookkeeping",
    text: (
      <p>
        05-30-0001:
        <br />
        <br />
        Rolls for this session were already completed between sessions and
        applied. The only outstanding item we had to resolve was an Ark Battle.
        More on that below.
        <br />
        <br />
        Session started in-game on 5-30-0001 at 6am. Everyone suffered a point
        of rot damage and then the IL Gang left the radio station behind, put
        the comatose Double-Bird on the Summer of Love battle bus, and headed
        east toward the Rust Castle.
        <br />
        <br />
      </p>
    ),
    image: {
      imagePath: delta,
      altText: `Delta KCG 966 is a basic service/guard bot from Noatun Estates.`,
      className: "imageLeft",
      imageCaption: "Delta KCG 966",
    },
  },
  {
    title: "Rust Castle or Bust",
    text: (
      <p>
        The IL Gang continues eastward toward the Rust Castle. Along the way
        they hit a few sectors including a crater. Some highlights of sector
        travel were:
        <ul>
          <li>
            Battling zone dogs while getting bus out of a crater. Smoke Raven
            pushed Find the Path and took three points of AGI damage. Then
            during the battle with hounds, Smoke Raven has mutation misfire and
            suffers a permanent point of trauma (he chooses WITS since his
            EMPATHY is already down to two). And then he gains a new mutation
            which was BEASTMASTER.
          </li>
          <li>
            Party kills all eight zone dogs with Smoke Raven trying out his new
            mutation to tame the last dog. The last dog is cleaved by Doc
            Gladhand though who had been used as a chew toy throughout the
            combat. The IL Gang loads up the dog corpses to see if they can
            trade at the castle because, as was so eloquently stated, "our
            butcher is in a coma." (i.e. Double Bird cannot harvest the grub
            from the corpses due to seriously deep naptime).
          </li>
          <li>
            Zone Gnats attack at night as IL Gang sets up camp. Smoke Raven
            controls them with beastmaster and then lights them up with
            flamethrower as the sun sets for fireworks Zone-style!
          </li>
          <li>
            The party finds a bridge crossing zone river after going one sector
            out of their way. They discover Lemmy, Delta KCG-966, as well as two
            guards from Noatun Estates. Lemmy seems genuinely pleased and the IL
            Gang offers the travellers a ride to the Rust Castle since they
            share the same destination. Lemmy gives them a lecture about the
            Rust Castle.
          </li>
        </ul>
        <br />
        <br />
      </p>
    ),
    image: {
      imagePath: lemmy,
      altText: `Lemmy is a sergeant of the guard at Noatun Estates. He is a dapper Zone Human with a friendly disposition`,
      className: "imageLeft",
      imageCaption: "Lemmy of Noatun Estates",
    },
  },
  {
    title: "Parking Lot Party",
    text: (
      <p>
        Upon arrival at the RUST CASTLE, the IL Gang sees that the castle is a
        huge back-end of a shattered ferry boat beached in shallow waters. In
        the "parking lot" area they see other vehicles and a Karzar Battlewalker
        on patrol. In the waters of the bay, they see the Mechie sloop ship
        Steelcrest patrolling waters as jet ski, canoes, and motorboats arrive
        to set up for market day.
        <br />
        <br />
        Whopper Cad of the Rust Castle arrives in a unicart with two automatons
        to greet the party. As explained by Lemmy, Cad is one of four humans
        that help the Mechies and their robot battalion manage the Rust Castle.
        Cad, Roggi, Elli, and Tir are all big, strong Zone Humans that stand
        seven feet tall and are clearly superior in physical presence to any
        other zone ghoul or zone human yet seen.
        <br />
        <br />
        "Welcome to the Rust Castle. Mounted vehicle weapons have to be unloaded
        and all personal weapons turned over. You won't have to worry about your
        other belongings or vehicles while you're here. We have guards,
        automatons, and of course the Battlewalker over there." Cad fields some
        basic questions but defers the party as his job is to intake their
        arrival, sweep for weapons, and then they will have an interview with
        Nikkil as to their suitability as guests of the Rust Castle. "You can
        see the Steel crest out there in the bay. That's Elwith's baby and she's
        the ship captain. They're helping with the marketplace. You're here at a
        good time. Now that we're in the good part of the year, trade is picking
        up."
        <br />
        <br />
        As the party waits for an escort to their interview with Nikkil, Boss
        Hulagu feels a hand on his ass. Whirling around he sees Helldriver
        Harley. "Boss Hulagu, good to feel ya." She gives him her trademark
        psychotic grin and laugh as he notices Boss Patrick of the Helldrivers
        leaning against the back bumper of Summer of Love. Patrick waves his
        fingers like he is buttering up a wayward child.
        <br />
        <br />
        "Well well, look whose all growed up and made it to the Rust Castle"
        says Patrick with laconic insincerity. He throws a hand-rolled cigarette
        to the ground next to the bus and crushes it under foot.
        <br />
        "Ain't it somethin? In this here place you have to behave no matter who
        you see and how you might feel about them. That's the price for stable
        trade I guess." Chromicus is seething and Boss Hulagu has to hold back
        the violence welling up in his heart. "Y'all have a good time here. Oh
        and, uh, guess what? While y'all are here, my gang is back at the Plaza
        raping your ark. Enjoy the marketplace."
        <br />
        <br />
        We cutback to the Sunshine Plaza to resolve Ark Battle which was rolled
        between sessions as a result on the Ark Reputation table. The Plaza has
        a base Battle Rating of 4 but operates as a 6 thanks to two battlebuses
        on premises. The Helldriver force has D6+2 battle rating. IL Gang rolls
        a one and so with luck, the Ark Battle is 6 versus 3 battle dice in
        favor of the Sunshine Plaza. Several pushed rolls later the Ark suffers
        no damage, runs off the Helldrivers, and the Plaza has{" "}
        <b>four Helldriver mutants as prisoners</b>.
        <br />
        <br />
        Since the party already met Lemmy from Noatun and the Helldrivers, there
        was just one more of the three random Rust Castle encounter slots open.
        They rolled and it came up Pax Aeternal; a new faction the IL Gang soon
        learns is run by none other than Boss Berke of the Sunshine Plaza civil
        war fame. Three of the six members of the IL Gang party hate and want to
        kill Berke. "Not here" is the uniform consensus, such is the power of
        the Rust Castle as neutral zone.
      </p>
    ),
    image: {
      imagePath: patrick,
      altText: `Patrick of the Helldrivers. A maniac who keeps his disorder under wraps; until he snaps and starts screaming fire`,
      className: "imageLeft",
      imageCaption: "Boss Patrick, Helldrivers",
    },
    image2: {
      imagePath: berke,
      altText: `Boss Berke, formerly of the Sunshine Plaza, left (or fled like a coward) during the Civil War and has recently put together a new gang called Pax Aeternal.`,
      className: "imageRight",
      imageCaption: "Boss Berke of Pax Aeternal",
    },
  },
  {
    title: "Interview with Nikkil and Marketplace",
    text: (
      <p>
        The less-than-friendly whopper named Roggi takes Doc Gladhand to
        interview on a jet ski as Smoke Raven and Boss Hulagu follow two
        automatons up through the maze of ferry wreckage. They see that the
        on-foot entry to the Rust Castle is very difficult and fraught with
        traps. It seems perhaps they are given this tour to demonstrate how
        dangerous an unaccompaned land approach would be.
        <br />
        <br />
        The three characters are sat down in the bridge with four automatons in
        attendance until Nikkil arrives. Nikkil, like all Mechies, reminds the
        IL Gang of the elder. Their skin is wrinkled and old and the Elder is
        the only person they have seen like that, with the exceptional
        difference that the Mechies are only four feet tall. Nikkil is calm and
        asks just four questions of the IL Gang.
        <ol>
          <li>Why have you come to the Rust Castle?</li>
          <li>Where is your Ark?</li>
          <li>
            Did you detonate a nuclear bomb in the Zone to destroy a sector?
          </li>
          <li>What question would you ask of me if you were in my chair?</li>
        </ol>
        The IL Gang is forcoming about their Ark location but thrown by the
        nuclear bomb question. Clearly the bomb used to destroy the Kurtzers way
        back in{" "}
        <a
          href="https://www.smokeraven.com/mutant-year-zero-session-08-anchortown"
          rel="noreferrer"
          target="_blank"
        >
          Session 8
        </a>{" "}
        is known by factions thoughout the zone. Glandhand and Boss Hulagu
        double-team the answer and it comes down to "would you push the button
        if it was your people in danger?"
        <br />
        <br />
        Nikkil summons Elli to give the party a tour on their way back to their
        vehicles. Elli is friendly and flirtacious and explains that means they
        will be allowed in the Rust Castle. She tells them showers are 1/2
        bullet (or 1 grub), medical bed is 1 bullet per day, marketplace access
        is 1 bullet per day, access to the lounge is 1 bullet per day, and a
        stateroom (which includes a shower and access to the lounge) is 2 buttes
        per day. As she explains the menu of services she leads them through the
        lounge where she introduces their player piano robot Vivaldi and their
        mechtender Theodore who pours drinks.
        <br />
        <br />
        The party decides to spend 4 bullets and have Adalia look after him in
        the final four days of his coma before he recovers from that critical
        injury. They all shower off rot for 2.5 bullets. And finally, they pay 8
        bullets to have dual person access to marketplace each day with Gladhand
        in attendance each day and the others rotating in so everyone gets to
        see the marketplace.
        <br />
        <br />
        Hulagu has a moment with Harley on the docks outside the harbor area.
        She seems despondent which is the opposite of the bright, psychotic
        Harley they have seen to date. She is pouting because she cannot go into
        the marketplace with her bat which she is cradling in her lap. On
        inspection it is some kind of mythic artifact and she refuses to let go
        of it. While she bitches about how Patrick is flirting with that skank
        whopper Roggi, Hulagu guesses that Patrick has likely made a substantial
        payment for the Mechies to allow Harley this close to the Castle with a
        weapon in hand. He likely didn't like her insisting on keeping the
        weapon, how much it cost him, and she didn't like the way he lectured
        her on keeping her cool (not to mention the way he talks to that whore
        Roggi). Hulagu spritzes some cologne and puts on his sunglasses to
        manipulate Harley into putting in a good word about them with Patrick
        and he lears that the ex-Skag gang members from the civil did join the
        Helldrivers and the Helldrivers have allied with the remaining cannibal
        women of the Diamond Center.
        <br />
        <br />
        In the marketplace, over the course of four days, they meet:
        <ul>
          <li>
            Gregoria and her son Ibor of Cage Island in the Dead Blue Sea. They
            are not friendly in particular. The rumor is that Cage Island
            traffics in slaves. And while slaves are not allowed at the Rust
            Castle, Gregoria has handcuffs among her inventory of items for
            sale.
          </li>
          <li>
            Evgenia of the Fardrifters has some stuff for sale include
            Fardrifter fetish necklaces, fishing nets, and foot fins. The
            Fardrifters are a faction in the Dead Blue Sea with singsong accent
            and darker skin. She is very happy and friendly.
          </li>
          <li>
            The smug, friendly, and confident pedant named Xerxes is running a
            sales area with six other companions from the Oilfields of the
            Ancients. They are a faction in the deep blue sea, but not a lot is
            known about them. Xerxes looks almost identical to the other
            Oilfielders; perhaps some kind of pre-war cloning experiement? The
            only one of the Oilfielders who seems different is named Vanja. She
            has a scar on her right cheek; self-inflicted damage during an
            existential crisis in which she was desperate to distinguish herself
            from the nearly identical brothers and sisters of her faction.
          </li>
        </ul>
        <br />
        <br />
      </p>
    ),
    image: {
      imagePath: nikiil,
      altText: `Nikkil of the Rust Castle and merchants; Xerxes, Evgenia, and Gregoria`,
      className: "imageLeft",
      imageCaption: "Nikkil and merchants",
    },
    image2: {
      imagePath: elwith,
      altText: `Elwith of the Rust Castle, Tir, mechtender Theodore, and player robot Vivaldi.`,
      className: "imageRight",
      imageCaption: "Elwith & Friends",
    },
  },

  {
    title: "Wrapup",
    text: (
      <p>
        <b>
          Special Note: the Mechies are based on real human beings that suffered
          from the disease progeria. In particular, I want to say Adalia of the
          Rust Castle is based on real-life
          <a
            href="https://nypost.com/2022/01/13/adalia-rose-williams-dead-at-15-she-is-no-longer-in-pain/"
            rel="noreferrer"
          >
            &nbsp; Adalia Rose who passed away in January of year 2022
          </a>
          .
        </b>
        <br />
        <br />
        Next session will start on in-game morning of 06-05-2021 with party at
        the Rust Castle with Double Bird coming out of his coma. We already did
        four days worth of food and water for party. XP award was 3.is
        <br />
        <br />
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        .
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: adalia,
      altText: `Adalia is the resident Chronicler, healer, and teach of the Mechies at the Rust Castle. She is based on real-life human who suffered from progeria and passed away in January 2022. RIP Adalia!`,
      className: "imageLeft",
      imageCaption: "Adalia Rose",
    },
  },
];

const MYZAnchortownSession36 = () => {
  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 36: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  The IL gang rides out a flood in an abandoned Radio Station.
                  Double-Bird doubles down on Stable Genius pills with startling
                  results!
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                {/*<Card.Title style={cardTitle}>Shootout at Burger Jim's, 03-27-0001 - 03-27-0001</Card.Title>*/}
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession36;
