import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import myzTitle from "../../../../assets/myz/myz-title-white.png";
import sectionDivider from "../../../../assets/myz/myz-section-divider.jpg";
import "../../../../index.css";
import { ContentContainer } from "../../SessionSection";
import { useEffect } from "react";

import roll66 from "../../../../assets/myz/death-of-double-bird-66.png";
import doubleBird from "../../../../assets/myz/double-bird-portrait.jpg";
import graves from "../../../../assets/myz/graves.jpg";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "Bookkeeping",
    text: (
      <p>
        06-08-0001, 6am:
        <br />
        <br />
        Points are spend and mutation points allocated. We did double rolls for
        bitterbeast hatching/training, ark reputation, and pure plant patrols.
        Party was headed toward Ark so we put off project rolls, but since we
        only got there at end of session we will start next session with THREE
        TIMES project rolls.
        <br />
        <br />
        The patrols included an argument between mutants and humans which ties
        in with some elements of next session, but also another roll where
        things went like clockwork.
        <br />
        <br />
        Two ark reputation rolls resulted in four more mutants arriving at the
        Plaza to become new citizens.
        <br />
        <br />
        NOTE: Altair and Chi Tzu are housed at the Rust Castle along with Summer
        of Love bus.
        <br />
        <br />
        Critical Injury Roundup
        <ul>
          <li>
            GLADHAND has a DAMAGED KIDNEY. Ends morning of 06-13-0001. Point of
            damage for roll to Force, Move or Fight.
          </li>

          <li>
            SMOKE RAVEN has a GROIN HIT. End morning of 06-10-0001. Point of
            damage for roll to Force, Move or Fight.
          </li>
        </ul>
      </p>
    ),
    image: {
      imagePath: doubleBird,
      altText: `Double Bird the Second. Twin brother to Double Bird the first, slave, taker of chances, bodyguard, and hero among The People`,
      className: "imageLeft",
      imageCaption: "RIP Double Bird",
    },
  },
  {
    title: "The Death of Double Bird",
    text: (
      <p>
        The party talks with Berke and learn the location of the Oilfield of the
        Ancients is Sector H17 in the Dead Blue Sea. They have also found the
        scuba gear for the RUST CASTLE. The party decides to take a dislodged,
        live torpedo with them by hauling it onto a hoverboard artifact and
        having someone push it along as they travel toward the Ark.
        <br />
        <br />
        <ul>
          <li>
            DOUBLE BIRD gets INTERNAL BLEEDING from rot, but thanks to Rot Eater
            he continues to live.
          </li>
          <li>
            Elwith is walking again and wants to go to the Sunshine Plaza to
            take advantage of the surgery they have to help with her recent
            paralysis. She worries about leaving the Steelcrest behind, but Elli
            worries about letting Elwith travel and not being by her side.
            Finally, it is decided that Elli, Chromicus, Smoke Raven, Ford the
            Scrap Pirate, and captive Berke will stay at the submarine. And
            Hulagu, Bayazid, Double Bird (guiding the hovering torpedo),
            Gladhand, and Elwith will travel back to the Ark.
          </li>
        </ul>
        The party travels and encounters two hot zones. In the second hot zone,
        Double Bird's rot suit fails him and this time he makes the worst
        possible roll on the ROT CRITICAL table. It is time to say goodbye.
        <br />
        <br />
        Double-Bird has been travelling for many sessions knowing he will die
        soon, and carrying, even caressing the grenade artifact. He has been
        haunted by the delirious glory of knowing his day are numbered in a way
        more real than others feel their own mortality. His willingness to take
        bullets for Hulagu, dive into rot water beneath the submarine's reactor
        . . . he bravery and foolishness sparked by his looming death.
        <br />
        <br />
        As he walks into the hot sector, he can feel his body about to collapse
        and halt. He stops and tells the others. Double Bird stands tall. He
        raises his arms. "It's a good day to die. I welcome you, this moment! I
        want to give each of you a gift and divide my gear. Boss Hulagu, Rot
        suit to Hulagu, take my rot suit, wear it, and remember me; and remember
        the rot! The rot killed me and it also made me more alive than I've ever
        been. Gladhand, I want you to take my spiked bat and deliver it to the
        Temple of Endurance at the Sunshine Plaza; it has always belonged there.
        I want other slaves to see this spiked bat and know my story. Take my
        bat and keep my story."
        <br />
        <br />
        Then Double-Bird strips naked and walks away from his gear in a pile
        near his friends, his family. He pulls the pin on the grenade. He blows
        a kiss. Time stands still.
        <br />
        <br />
        The explosion shatters his rot-wracked body into pieces so small they
        drift into the air. Double Bird the Second, twin brother to Double Bird
        the First is part of the Zone now, and with the help of the chroniclers
        he will join Jebe, The Elder and others in the book of legends.
        <br />
        <br />
      </p>
    ),
    image2: {
      imagePath: graves,
      altText: `Double Bird the Second; once the long lost twin of Double Bird the First, was found and become part of the Sunshine Plaza. Finally succumbed to rot, but he did it like a champion.`,
      className: "imageLeft",
      imageCaption: "Double Bird",
    },
    image: {
      imagePath: roll66,
      altText: `Double Bird, hanging on with 9 permanent rot for many sessions, finally succumbs on the Rot Critical roll; but in true Double Bird fashion, he goes out with the most epic roll!`,
      className: "imageRight",
      imageCaption: "Rot Critical Roll",
    },
  },
  {
    title: "Match Bearing and Shoot",
    text: (
      <p>
        The party goes back to the submarine after Double Bird's demise to
        update the group and chance the plan.
        <br />
        STAYING AT SHIP: Elli, Chromicus, Bayazid.
        <br />
        GOING BACK TO ARK: Hulagu, Smoke Raven, Berke/Ford (guard), Gladhand
        guiding the hoverboard with torpedo, and Elwith.
        <br />
        <br />
        With heavy hearts our heros begin their trek anew. Entering one of the
        last unexplored sectors blocking their path home, they feel an odd tug
        at their bodies; or more accurately, at their weapons and armor. This
        sector has a magnetic pull. The party comprehended rather quickly that a
        force was pulling anything metal towards it. Smokeraven called out a
        warning about the torpedo, just as Doc Gladhand, who had a hand on it to
        guide it on the hoverboard, felt the torpedo being pulled toward a
        building in the distance. Gladhand put a foot upon the hoverboard to
        hold the board down, then pushed on the torpedo to roll it off the
        hoverboard, hoping that the futuristic plastic polymers of the board
        meant it didn't have enough metal components to send it spiraling after
        the torpedo.
        <br />
        <br />
        Luckily, the torpedo rolled easily off the hoverboard (with a little
        help from the dice gods and the distant magnet), and the composition and
        power of the board kept it in place.
        <br />
        <br />
        As Hulagu and others succeeded in their force rolls to avoid being drawn
        into the blast radius from the inevitable explosion that would occur
        when the torpedo struck the building, Elwith failed and tumbled forward
        one distance range. Feeling his shield start to draw him in her
        direction, Doc Gladhand first let it pull him toward Elwith before
        throwing it into the air and moving to grab her. He used his Sprinter
        mutation, along with a force roll, to drag her back to their starting
        point, taking a point of damage from his extant GROIN HIT critical
        injury.
        <br />
        <br />
        This use of his mutation triggered a mutation misfire which gave him the
        Spore mutation. The torpedo continued towards the building, and everyone
        made their force rolls again to avoid being dragged forward. Smokeraven
        used a move instead of force and caught ahold of the pole from an old
        powerline, anchoring himself. Berke again used his weight and girth to
        plant himself in place.
        <br />
        <br />
        Gladhand held onto Elwith, using his Sprinter mutation again to fight
        the pull, this time triggering a cosmetic misfire. As the muscles of his
        legs fought to move them away from the magnet in the building, those
        muscles, and the legs themselves, grew larger and lengthened.
        <br />
        <br />
        The torpedo hit the building, destroying it without anyone being caught
        in the blast radius. In the aftermath, Doc Gladhand said, “I feel
        funny,” taking a moment to realize he’d been altered in two major ways,
        having acquired the new Spore mutation as well as incredibly long legs.
        He was now as tall as or slightly taller than even Chromicus, his added
        height being all leg.
        <br />
        <br />
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
  {
    title: "Wrapup",
    text: (
      <p>
        Using our modified XP rules, Gladhand won the nomination for extra xp
        thanks to his use of SPRINTER to save Elwith (for a total of 4xp).
        Everyone else got 3 XP.
        <br />
        <br />
        Next session will start on in-game in the early morning hours of
        06-10-2021 with Hulagu, Smoke Raven, Berke, Ford (Berke's guard),
        Gladhand, and Elwith at the ark.
        <br />
        <br />
        STAYING AT SHIP: Elli, Chromicus, Bayazid. NEXT SESSION WILL START WITH:
        <ul>
          <li>Mutation points and XP spend</li>
          <li>
            Scene cut back to the Steelcrest. How did Elli, Chromicus, and
            Bayazid do in the day the party spent travelling to the Plaza?
          </li>
          <li>Who has the hoverboard? Or where is it?</li>
          <li>
            What is done with Berke? And what does he know about Oilfield of the
            Ancients?
          </li>
          <li>Can Boss Krao or anyone help decipher the DEAD BLUE SEA book?</li>
          <li>What is the scuba gear kept?</li>

          <li>
            TRIPLE rolls for projects, bitterbeasts, ark reputation, and pure
            plant patrols.
          </li>
          <li>
            What goes on before party meets Capt Ossian back at the Saurian lair
            by morning of the 15th?
          </li>
        </ul>
        Check out Session Body Count at:{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-population-notable-events"
        >
          SESSION BODY COUNT AND POPULATION
        </a>
        .
        <br />
        <br />
        As always, feel free to stop by the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="./mutant-year-zero-anchortown-graveyard"
        >
          Anchortown Graveyard to pay your respects
        </a>
        .
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
      imageCaption: "",
    },
  },
];

const MYZAnchortownSession41 = () => {
  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../assets/myz/bkgnd.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <div
              style={{
                backgroundColor: "transparent",
                fontFamily: "Orbitron",
              }}
            >
              <main role="main" className="inner cover text-center">
                <img
                  src={myzTitle}
                  style={{ maxWidth: "400px" }}
                  alt="Mutant Year Zero campaign"
                />
              </main>
              <div style={pageHeader}>
                <h1 className="cover-heading">
                  Session 41: Chronicles of the Sunshine Plaza
                </h1>
                <p className="lead leadExtra">
                  The Death of Double Bird the Second
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <img src={sectionDivider} alt="section divider" />
              <Card.Body>
                {/*<Card.Title style={cardTitle}>Shootout at Burger Jim's, 03-27-0001 - 03-27-0001</Card.Title>*/}
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZAnchortownSession41;
