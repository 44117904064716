import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import logo from "../../../../src/assets/forbidden-lands/forbidden-lands-logo.webp";
import sectionDivider from "../../../../src/assets/forbidden-lands/session08/bitter-reach-page-banner-session-08.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";
import "./forbidden-lands-styles.scss";

import cover from "../../../../src/assets/forbidden-lands/session08/fbl-session-08-cover.webp";
import door from "../../../../src/assets/forbidden-lands/session08/dragon-door-open.webp";
import key from "../../../../src/assets/forbidden-lands/session08/silver-dragon-key.webp";
import sinkhole from "../../../../src/assets/forbidden-lands/session08/sinkhole.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "1",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Carter One",
  backgroundColor: "rgba(0, 0, 0, 1) ",
  color: "white",
  marginBottom: "15px",
};

const FBLSession08 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  const section1Data = [
    {
      title: "Dragon Doors",
      anchor: "freedom-day",
      sectionTitleCssName: "sectionTitleFBL",
      text: (
        <div className="fblBodyText">
          <ImageComponent
            image={cover}
            caption="The Crimson Raven"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="A dead raven covered in crimson dust beside an ancient cenote filling with translucent fog."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <ImageComponent
            image={door}
            caption="Dragon Doors"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Imposing doors decorated with the symbol of Wyrm block access to the rest of the ruin."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          The{" "}
          <span style={{ color: "burlywood" }}>
            party camps in the entry corridor
          </span>{" "}
          to the Ruenlocke ruins. Turold is broken, bound, and out for the
          count. Everyone is healed and Lobo's severed leg is tied off with a
          tourniquet. The party is able to rest and recover for a QUARTER DAY.
          Note: we had Rog-Golar switch out Defender for Path of Fate since it
          made more sense for his character. Also, Stilak gives the haughty elf
          Neha arrows out of respect for a fellow archer.
          <br />
          <br />
          Rested and ready, the party decides to explore the ruins. They find
          two different sets of doors, both locked via an ancient mechanism.
          Aileen points out that the{" "}
          <span style={{ color: "burlywood" }}>
            symbol of the god Wyrm decorates each door
          </span>
          . Blisster sees nothing resembling a traditional lock that he might
          manipulate. Without another path forward, the group investigates a
          large room with a sinkhole in it.
          <br />
          <br />
          <a href="#pageTop">Back to Top</a>
        </div>
      ),
    },
    {
      title: "The Crimson Raven",
      anchor: "freedom-day",
      sectionTitleCssName: "sectionTitleFBL",
      text: (
        <div className="fblBodyText">
          <ImageComponent
            image={sinkhole}
            caption="The Sinkhole Room"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="A large chamber dominated by a sinkhole with blue-white, glowing vapor spilling from holes near the top."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <h2>The Sinkhole Room</h2>
          <i>
            This circular cavern is dominated by a{" "}
            <span style={{ color: "burlywood" }}>
              sinkhole with blue-white, glowing vapor spilling from holes near
              the top
            </span>
            . The vapor drifts down into the sinkhole. You would have to enter
            the chamber to see further into the sinkhole. <br />
            <br />
            The floor of the natural chamber is coated in a{" "}
            <span style={{ color: "burlywood" }}>fine crimson dust</span> that
            appears to be undisturbed for centuries except for the{" "}
            <span style={{ color: "burlywood" }}>corpse of a raven</span> on the
            ground. The corpse is reddish, as if it writhed around in the dust
            before stopping. The body of the raven is intact with feathers and
            milky-white eyes glaring up at you.
          </i>
          <br />
          <br />
          To our merry band of ruin robbers the room is a mystery. It's clearly
          dangerous and they approach the puzzle with caution. Using his shaman
          powers, Ibax casts a spell and see through the eyes of the dead raven.
          He sees some of the history of the ruins, an ancient race of dwarves
          (that Aileen later suspects may have been a vision of forgethralls, an
          ancient dwarf kin group that served the winter elves). The vision also
          shows a shattered alter to Wyrm at the bottom of the sinkhole and how
          over the years, the centuries, the sinkhole has claimed life after
          life. Then, as the spell ends, Ibax collapses to the group with
          AMNESIA (magic mishap).
          <br />
          <br />
          When{" "}
          <span style={{ color: "burlywood" }}>
            Ibak comes out of the spell with AMNESIA
          </span>
          , he sees a wolfkin, a goblin, and a giant orc leaning iin close and
          freaks out. Who are these foul monstrousities? Ibax begins to flee
          toward the sinkhole! With a quick MOVE, Rog-Golar grapples the
          horrified Ibax.{" "}
          <span style={{ color: "lightgreen" }}>
            "I stopped you from falling into the hole"
          </span>
          , Rog-Golar shouts,{" "}
          <span style={{ color: "lightgreen" }}>
            "that proves I am your friend!"
          </span>{" "}
          With a successfull MANIPULATION, the gang manages to calm Ibax down.
          And while he does not remember them, logic dictates that they must be
          his friends.
          <br />
          <br />
          <h2>Getting the Bird</h2>
          Ibax's vision informs the party that falling into the sinkhole is more
          or less deadly depending upon whether one is affected by the
          hallucinagenic poison of the crimson dust covering the ground and the
          dead raven. Also, they know{" "}
          <span style={{ color: "burlywood" }}>
            they will need a feather from the raven to get out of the sinkhole
          </span>
          . The first order of business is to get a feather from the raven
          covered in crimson poison without disturbing the chamber or breathing
          in the dust.
          <br />
          <br />
          Tiemiesz gets some rope from the dragon bone bridge. Rog-Golar ties
          the rope around his battle axe and tosses the axe into the room to try
          and drag the corpse closer to the edge of the chamber. It proves
          harder than it seems and the effort fails. Then,{" "}
          <span style={{ color: "burlywood" }}>
            Stilak nabs the rope and ties it to an arrow and shoots the arrow
            into the raven
          </span>
          . The arrow sticks and the party is able to drag the raven to the edge
          of the chamber. Blisster pours melted ice onto the raven corpse to
          inactivate the crimson dust and plucks a feather. Now, it's a matter
          of getting to the bottom of the sinkhole.
          <br />
          <br />
          <h2>Into the Sinkhole</h2>
          Blisster volunteers to go into the sinkhole.{" "}
          <span style={{ color: "burlywood" }}>
            Rog-Golar, with Aileen's help, tosses Blisster into the sinkhole
          </span>
          . An excellent roll puts Blisster sailing into the sinkhole like a
          basketball swish, no net. Blisster falls slowly to the bottom of the
          sinkhole. As he falls he takes note:
          <ImageComponent
            image={key}
            caption="Dragon Key"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="An ancient Dragon Key at the bottom of the sinkhole in Ruenlocke is crucial to opening the Dragon Doors to access the rest of the ruin."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>
          <br />
          <br />
          <i>
            As you fall like a feather into the sinkhole, you see that the
            vapors gather into a semi-transparent cloud at the bottom. Also at
            the bottom of the sinkhole you can see a shattered altar and other
            smaller items hard to see through the vapor cloud, as well as
            humanoid skeletons. The altar had some kind of design on the surface
            before it shattered into pieces. Perhaps the altar and debris once
            stood in this chamber before everything collapsed into this
            sinkhole?
          </i>
          <br />
          <br />
          In the debris of shattered altar stone, shards of wood, and skeletal
          remains, Blisster finds some treasure and a Dragon Key. Flapping his
          arms, Blisster rises out of the sinkhole, buoyed by ancient magic. The
          party hauls in snow to scatter on the ground of the chamber so
          Blisster can safely land and walk out. Dragon Key in hand, the party
          is poised to go deeper into Ruenlocke . . . TO BE CONTINUED.
          <br />
          <br />
          <a href="#pageTop">Back to Top</a>
          <br />
          <br />
          <small>
            Attendees: Blisster, Tulok, Ibax, Lobo, Rog-Golar, Stilak.
          </small>
          <br />
          <small>Ending Datetime: 9pm, Winterwane 46, 1165</small>
          <h5>XP</h5>
          <ul>
            <li>Session 1.0</li>
            <li>Legend: 0.0</li>
            <li>Lore: 0.0</li>
            <li>Hex: 0.0</li>
            <li>Pride: 0.0</li>
            <li>Treasure: 1.0</li>
            <li>Monster: 1.0</li>
            <li>Dark Secret: 0.0</li>
          </ul>
        </div>
      ),
    },
  ];

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/forbidden-lands/fbl-page-background-blizzard.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.backgroundWidth = "100vw";
    document.body.backgroundHeight = "100vh";
    document.body.style.backgroundSize = "cover";
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <div
            style={{
              backgroundColor: "transparent",
              fontFamily: "IM Fell Great Primer",
            }}
          >
            <div style={pageHeader}>
              <main role="main" className="inner cover text-center">
                <a href="#freedom-day" name="pageTop">
                  <img
                    src={logo}
                    style={{ maxWidth: "200px" }}
                    alt="Forbidden Lands Campaign"
                  />
                </a>
              </main>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title
                id="cardTitle-forbidden-lands"
                className="cardTitle-forbidden-lands  "
              >
                Dragon doors, a mysterious sinkhole filled with lumious vapor,
                ground covered in crimson dust, and a dead raven.
                <br />
                <br />
                <small>
                  ⇐ Session 08:{" "}
                  <a href="./forbidden-lands-session-07">Throwing Bones</a> ☠{" "}
                  Session 09:{" "}
                  <a href="./forbidden-lands-session-09">
                    Æther Serpents of Ruenlocke
                  </a>{" "}
                  ⇒{" "}
                </small>
              </Card.Title>
              <div>
                <ContentContainer data={section1Data} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default FBLSession08;
