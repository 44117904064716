import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import mbLogo from "../../../../src/assets/mork-borg/mork-borg-logo.webp";
import sectionDivider from "../../../../src/assets/mork-borg/mork-borg-section-divider.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";

import begin from "../../../../src/assets/mork-borg/session01/and-so-it-begins.webp";
import weather from "../../../../src/assets/mork-borg/weather-chat-size.gif";
import loot from "../../../../src/assets/mork-borg/session01/initial-loot.webp";
import comet from "../../../../src/assets/mork-borg/session01/comet.webp";
import galgenbeck from "../../../../src/assets/mork-borg/session01/galgenbeck-with-locations.webp";
import master from "../../../../src/assets/mork-borg/session01/the-master-and-his-hounds-portrait.webp";
import baby from "../../../../src/assets/mork-borg/session01/bassinet-baby.webp";
import emperor from "../../../../src/assets/mork-borg/session01/emperor-of-teeth.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const MBMalumMortisSession01 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/mork-borg/bkgnd-01.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  const section1Data = [
    {
      title: "Hapless Scvm Gather on Western Shore of Tveland",
      text: (
        <div class="morkBorgFont morkBorgTitleText">
          <ImageComponent
            image={begin}
            caption="And So It Begins"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Malum Mortis campaign begins."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>

          <ImageComponent
            image={weather}
            caption="Soup Thick Mist"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="Weather: Soup Thick Mist."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>

          <p>
            We started Malum Mortis campaign with two players and two PCs each.
            <br />
            We rolled up the first two characters manually, then used the Create
            Scvm function in the excellent{" "}
            <a
              href="https://foundryvtt.com/packages/morkborg"
              rel="noreferrer"
            >
              Mork Borg
            </a>{" "}
            system module for &nbsp;
            <a href="https://foundryvtt.com/" rel="noreferrer">
              Foundry
            </a>{" "}
            by the magnificent &nbsp;
            <a
              href="https://mcglintlock.itch.io/"
              rel="noreferrer"
            >
              mcglintlock
            </a>
            .
            <br />
            <br />
            Malum Mortis campaign runs on a d20 dice roll on Calendar of
            Nechrubel. There is a metaplot to be discovered that will give the
            party incentive to try & save the Dying Lands before it is consumed
            by eternal darkness.
            <br />
            Our party consists of:
            <ul>
              <li>
                <span style={{ color: "#00FFFF" }}>PLAYTOPUSS</span>: an
                egocentric{" "}
                <span style={{ color: "#ADFF2F" }}>FORLORN PHILOSOPHER</span>{" "}
                that sweats like a pig and is a cannibal. He has hypersentive
                mental tissues that are inflamed by ambient stupidity and bright
                lights.
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>HILDEBRAND</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>HERETICAL PRIEST</span>,
                Hildebrand worries constantly, washes his hands often, and is
                obsessively clean and neat. He was baptized by fire!
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>KLAPTRAP</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>SLAPPING BASTARD</span> prone
                to substance abuse with a broken jaw. He collects scabs.
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>HERMAN</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>GUTTERBORN SCVM</span>. He is
                worried and naive with hands caked in sores. He is a bit a
                pyromaniac.
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>Innocent</span>: donkey
              </li>
            </ul>
            Some interesting bits about the party:
            <ul>
              <li>
                Rolled{" "}
                <span style={{ color: "#FF7F50" }}>
                  party background resulted in a curse
                </span>{" "}
                wherein Hildebrand heals half as fast as normal when resting and
                Platopuss heals twice as fast. This unlikely friendship between
                OCB clean freak and sweaty cannibal could be due to their
                intellectual respect for each other as priest and philosopher,
                or perhaps just because of the curse that binds them.
              </li>
              <li>
                Klaptrap's background included that he was the{" "}
                <span style={{ color: "#FF7F50" }}>
                  dodge cannonball champion from Kergus
                </span>
                . So, we decided that the party is all from Kergus. Platopuss
                ate (and killed?) a noble female and enraged some of the wrong
                people. Klaptrap's dodge cannonball career fizzeled out; perhaps
                because he started losing and/or because he was friends with
                Platopuss and guilty by association. In either case, we hand the
                party start on the western shore of Tveland just over a day's
                journey from Galgenbeck.
              </li>
              <li>
                Platopuss is a{" "}
                <span style={{ color: "#FF7F50" }}>harbinger of misery</span>{" "}
                which means{" "}
                <span style={{ color: "#FFFF00" }}>
                  TWO MISERY ROLLS PER DAY
                </span>
                !!!
              </li>
            </ul>
          </p>
        </div>
      ),
    },

    {
      title: "Follow the Shattered Brick Road",
      text: (
        <div class="morkBorgFont morkBorgTitleText">
          <ImageComponent
            image={comet}
            caption="Comet Over Galgenbeck"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Comet Over Galgenbeck."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>

          <ImageComponent
            image={loot}
            caption="First Loot!!"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="Loot Found on Battlefield."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>

          <p>
            The journey to Galgenbeck begins with a weather roll resulting in{" "}
            <span style={{ color: "#FF7F50" }}>soup thick mist</span>.
            <br />
            As the hapless scvm (aka PCs) travel through the dense grey mist,
            they enter a pertrified forest and find the{" "}
            <span style={{ color: "#FF7F50" }}>remants of a battle</span>{" "}
            between priests of the Two-Headed Basilisk church and what appear to
            be common peasants. They find a scalp with hair attached, bloody
            knuckle dusters, and a very small horseshoe. NOTE: we wired up
            Foundry macros for roll tables so they pull in a little graphic from
            source book just to give that Mork Borg visual spice (see image).
            <br />
            <br />
            Several hours later winds pick up and dissipate the fog. The party
            notices a comet in the sky. It hangs like a ripped out eye and looks
            toward Galgenbeck. As the wind escalates further, they realize that
            the dreaded{" "}
            <span style={{ color: "#FF7F50" }}>BLACK SALT WIND</span> is coming,
            and at hurricane force! Herman ties his donkey Innocent to a tree
            and everyone makes AGI rolls to find shelter in the hollow of a tree
            or anywhere. A success grants a +2 on roll to beat TOUGHNESS DR 16
            for the hurricane gale of the black salt. A couple of PCs take a
            point of damage and thank their armor that the crystalline shards
            did not do more. However,{" "}
            <span style={{ color: "#FFFF00" }}>
              poor old Innocent was shredded by the storm
            </span>
            . &nbsp; He is a bloody pile of horse meat once the storm passes.
            It's always the innocent who die first isn't it?
            <br />
            <br />
            Just outside Galgenbeck the party meets{" "}
            <span style={{ color: "#FF7F50" }}>
              three pilgrims from Allians
            </span>{" "}
            on their way to the city. The lead pilgrim is a pleasant fellow
            named Frogun. They join the party to camp that night and swap
            stories. One of the pilgrims recognizes Klaptrap as dodge cannonball
            champion. The portent of the comet in the sky is discussed. Also,
            they explain that{" "}
            <span style={{ color: "#FF7F50" }}>
              Josifa Migol, the Arch-Priestess of Galgenbeck, is expected to
              give a rare open sermon at the Cathedral
            </span>
            , presumably to discuss the Comet.
            <br />
            <br />
            The next morning the party makes Calendar of Nechrubel rolls. Yes,
            rolls plural because Platopuss is a Harbinger of Doom which means
            two misery rolls per day while the scvm lives! And today is the day:
            a misery happens.
            <br />
            <span style={{ color: "#FFFF00", fontSize: "30px" }}>
              <center>Psalm 6, Verse 1.</center>
              You shall know the last day is come. The sun shall set and never
              rise.
            </span>
            <br />
            The party lends some lamp oil to Frogun who carries an oil lamp
            because now the Dying Lands is truly cast into darkness and a lamp,
            torch, or other light source must be used at all times as the sun
            shall never rise again and all is darkness!
          </p>
        </div>
      ),
    },
    {
      title: "Galgenbeck: The Greatest City There Ever Was",
      text: (
        <div class="morkBorgFont morkBorgTitleText">
          <ImageComponent
            image={galgenbeck}
            caption="Galgenbeck: The Great City"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Galgenbeck: The Great City"
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>

          <ImageComponent
            image={master}
            caption="The Master and His Hounds"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="The Master and His Hounds."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>

          <p>
            The gates of Galgenbeck are chaos. It is midday but the world is
            dark. The guards are scrambling to ensure they remain stocked with
            light sources and the pilgrims, traders, and other teeming masses of
            &nbsp;
            <span style={{ color: "#FF7F50" }}>
              pestilent humanity clamor to enter the city, scared and bewildered
            </span>
            . The party is granted entry into the Neu Kirk gate where they
            marvel at the squalor and filth of the lower districts of the great
            city.
            <br />
            <br />
            In Neu Kirk,{" "}
            <span style={{ color: "#FF7F50" }}>
              Platopuss tries to take a severed foot from the gutter
            </span>{" "}
            and has a tense moment with two butchers of Neu Kirk, Vresi and
            Charlie. They chase Platopuss away but he vows to see them again.
            Platopuss decides to ignore a man covered in fungal growths
            stumbling into the bowels of a dark alley; a mystery better left
            unsolved. Meanwhile, Frogun has given an overview of Galgenbeck's
            districts to the party. Frogun and his companions are going to the
            Altmarkt district to be close to the Cathedral's Shadow. It seems
            Josilfa's sermon may be postponed as Galgenbeckian officials
            struggle to adapt to the new darkness of misery Pslam 6, Verse 1.
            <br />
            Neu Kirk is dangerous as a place to stay. And while Riverside is
            safer and filled with informants, it is also filled with thieves.
            The party decides to follow Frogun and trade in the markets of
            Altmarkt.
            <br />
            <br />
            The{" "}
            <span style={{ color: "#FF7F50" }}>
              borders of Altmarkt are blocked with security barricades
            </span>{" "}
            and heavily manned by Josilfa Migol's guards. With the comet in the
            sky and the sudden darkness cloaking the Dying Lands, they have
            beefed up security to make proximity to the great Cathedral of the
            Two-Headed Basilisks more difficult. Frogun and his two companions
            as pilgrims are suspect because of their Kergusian origins. They are
            take aside into a building. They wave goodbye to the party and walk
            away. Hildegrand bribes the guard at their security station and
            gains access to the market.
            <br />
            <br />
            The PCs then sell their small treasure and undesired starting items.
            They buy oil, oil lamps, and other necessities including armor. Once
            done, they walk back toward Neu Kirk searching for work or a way to
            make money.
            <br />
            <br />
            In Neu Kirk they see an old man with milky, blank eyes sitting in a
            decrepit, makeshift wooden "throne". He has two large hounds at his
            feet and two ravens perched on the back of the throne. His lost
            stare into nothing is broken as the party walks past and he calls
            out to Platopuss. Platopuss talks with the old man who goes by
            nothing more than{" "}
            <span style={{ color: "#FF7F50" }}>
              The Master and His Faithful Hounds
            </span>
            . He has a compelling voice and presence and says it can be no
            coincidence that they have arrived on the day of the misery that has
            darkened the world. Hildebrand uses his List of Sins to suss out
            evil. While several cowardly alley scum glow with an evil aura, as
            well as the party's own Platopuss the Harbinger of Misery, it
            appears The Master has an aura that pulses from grey to white to
            black and so on.
            <br />
            <br />
            The Master pours a pouch of teeth out onto a wooden board. "
            <span style={{ color: "#FF7F50" }}>
              There is one known as Emperor of Teeth
            </span>
            . He lives is a hidden cabin. He has a tooth that matches this one
            and it is mine. I want it back." He shows the party a fat yellow
            molar. He tosses it to the ground. The tooth quivers then begins to
            roll along the ground, slowly picking up speed. The Master barks a
            command in an unknown tonge to one of his ravens. The raven flys
            quickly to snatch up the tooth and return it to The Master. "Place
            this tooth on the ground. It will roll to find it's mate. Follow it
            and you will come to the Emperor of Teeth. Once you get back, you
            all need to learn what it is you want to believe."
            <br />
            The Master has promised payment of 200 silver and introduction to
            the "power behind my throne" that can help with the curse betwixt
            Hildebrand and Platopuss.
          </p>
        </div>
      ),
    },
    {
      title: "Emporer of Teeth",
      text: (
        <div class="morkBorgFont morkBorgTitleText">
          <ImageComponent
            image={baby}
            caption="Baby Corpse"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Swaddled baby corpse."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>

          <ImageComponent
            image={emperor}
            caption="The Emperor of Teeth"
            cssClass="imageRight"
            handleClick={handleClick}
            modalBody="EMPEROR OF TEETH"
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>

          <p>
            The party follows the tooth a days journey from Galgenbeck. The
            tooth guides them to a dilapitated cabin with a smoking chimney and
            boarded over windows. Klaptrap exercises the non-subtly of a
            slapping bastard and kicks the door in. Inside the cabin the party
            ignores a chest and other doors to follow the tooth into a side
            room. The side room has a{" "}
            <span style={{ color: "#FF7F50" }}>
              bassinet with swaddled corpse of an infant
            </span>{" "}
            in it. Before he can put the tooth back on the ground, Klaptrap sees
            the dead baby and is flooded with a dread that causes a mental
            breakdown. "Do I have to do everything around here?!?" He screams
            and attacks Herman and his own companions.
            <br />
            <br />
            In the ensuing battle, Hildebrand had to attack him to get him to
            stop. Unfortunately,{" "}
            <span style={{ color: "#FF7F50" }}>
              Hildebrand knocked Klaptrap's arm clean off
            </span>{" "}
            thanks to a critical result ( we are using the{" "}
            <a
              href="https://www.drivethrurpg.com/product/341379/Deck-of-Terribly-Broken-Bodies-A-ThirdParty-Mork-Borg-Card-Deck?term=broken+bodies"
              rel="noreferrer"
            >
              Deck of Terribly Broken Bodies
            </a>{" "}
            for some critical injury variety). Once revieved via medicine kit,
            Klaptrap is no longer under the baby's spell, but he covers the
            decisscated body for safe measure. And now, the tooth shows that
            it's companion is upstairs. Platopuss picks up Klaptrap's severed
            arm; you never know when Platopuss will need a tasty snack eh?
            <br />
            When the party goes upstairs they see entwined skeletons on a bed,
            forever locked together in an obscene act. And then, a portly but
            quick man jumps up from under the bed, the rolling tooth in his
            hands. "I found it!! The other tooth. Oh, what are you doing here. I
            AM THE EMPORER OF TEETH!!!"
            <br />
            <br />
            <span style={{ color: "#FF7F50" }}>
              Battle ensues with the Emporer of Teeth
            </span>{" "}
            with these highlights:
            <ul>
              <li>Mind-numbing amount of critical fumbles!</li>
              <li>
                Several blows against the Emporer knock a tooth from the
                embedded chompers he has smashed into his bald skull like an
                enamel crown. Each time a tooth is knocked off he loses a round
                of action chasing down the precious tooth.
              </li>
              <li>
                When the Emperor is injured to half HP, he casts a spell and
                awakens the The Lovers. The entiwned skeletons attack with bony
                hands and drain ability scores making the overall battle that
                much more difficult.
              </li>
              <li>
                Omens are used up like a canteen of water in a desert. And once
                dry, there is nothing to be done when{" "}
                <span style={{ color: "#FFFF00" }}>Klaptrap is killed</span>.
              </li>
              <li>
                Platopuss, after several rounds fighting unarmed against the
                Emporer's armored defense, pull out Klaptrap's severed arm and
                starts swinging it as a FEMUR weapon. Klaptrap doesn't mind
                because, well, he's dead at that point.
              </li>
              <li>
                Hildebrand has an ARCANE CATASTROPHE wherein he falls into
                esoteric dimension of Cube-Violet and has to claw his way back
                to the reality of the Dying Lands. We give him a chance not to
                lose fingers per the event description due to his background of
                baptized in fire. He comes back with all his digits!!
              </li>
            </ul>
            We ended the session with GETTING BETTER rolls, dividing up
            Klaptrap's stuff, and rolling up a new character; a dissolute member
            of the WRETCHED ROYALTY named SPUG. SPUG is from Kergus as it
            happens. Is he related or involved with the minor noble consumed by
            Platopuss that causes their expeditious retreat from Kergus?
            <br />
            <br />
            This episode of Malum Mortis brought to you in part by:
            <br />
            Game:{" "}
            <a href="https://morkborg.com/content/" rel="noreferrer">
              Mork Borg
            </a>
            <br />
            Module:{" "}
            <a
              href="https://anadversary.itch.io/the-emperor-of-teeth"
              rel="noreferrer"
            >
              The Emperor of Teeth
            </a>
            <br />
            Artwork:{" "}
            <a
              href="https://goatmansgoblet.itch.io/"
              rel="noreferrer"
            >
              The Goatman's Goblet
            </a>
            <br />
            Module:{" "}
            <a
              href="https://www.drivethrurpg.com/product/376385/Galgenbeck-Sacrifice--A-Mork-Borg-Adventure"
              rel="noreferrer"
            >
              Galgenbeck Sacrifice
            </a>
            <br />
            Rules:{" "}
            <a href="https://www.drivethrurpg.com/product/341379/Deck-of-Terribly-Broken-Bodies-A-ThirdParty-Mork-Borg-Card-Deck">
              Terribly Broken Bodies
            </a>
            <br />
            Class:{" "}
            <a href="https://anadversary.itch.io/slapping-bastard">
              Slapping Bastard
            </a>
            <br />
          </p>
        </div>
      ),
    },
  ];

  return (
    <Container>
      {/* <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin" 
                    style={{position: 'float', float: 'right',width: '120px', height: '240px'}} marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=smokeraven-20&language=en_US&marketplace=amazon&region=US&placement=9189143701&asins=9189143701&linkId=d1384e0e6b9ef083fee35d7272a71f88&show_border=true&link_opens_in_new_window=true"></iframe> */}
      <Row>
        <Col>
          <div
            style={{ backgroundColor: "transparent", fontFamily: "Orbitron" }}
          >
            <main role="main" className="inner cover text-center">
              <img
                src={mbLogo}
                style={{ maxWidth: "200px" }}
                alt="Mutant Year Zero campaign"
              />
            </main>
            <div style={pageHeader}>
              <h1 className="cover-heading">Session 01: Malum Mortis</h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title className="cardTitle-mork-borg">
                WELCOME TO MALUM MORTIS, A MORK BORG CAMPAIGN
              </Card.Title>
              <Card.Text>
                <ContentContainer data={section1Data} />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MBMalumMortisSession01;
