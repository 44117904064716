import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import mbLogo from "../../../../src/assets/mork-borg/mork-borg-logo.webp";
import sectionDivider from "../../../../src/assets/mork-borg/mork-borg-section-divider.webp";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import ModalComponent from "../modal/modal.component";
import ImageComponent from "../image/image.component";
import { useEffect } from "react";
import sludger from "../../../../src/assets/mork-borg/session06/sludger.webp";
import relics from "../../../../src/assets/mork-borg/session06/relics-map.webp";

const pageHeader = {
  color: "white",
  backgroundColor: "black",
  opacity: "0.7",
  marginTop: "20px",
  padding: "15px",
};
const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const MBMalumMortisSession06 = () => {
  const [showModal, setShowModal] = React.useState("");
  const [modalData, setModalData] = React.useState({});
  const handleClick = (img, body) => {
    setModalData({
      body: body,
      image: img,
    });
    handleOpenModal();
  };
  const handleOpenModal = () => {
    setShowModal("show");
  };
  const handleCloseModal = () => {
    setShowModal("", "");
  };

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../../src/assets/mork-borg/bkgnd-01.webp")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  const section1Data = [
    {
      title:
        "In which our scum fight a SLUDGER, battle a BYTOR, are nursed by horrors of Chapel Olandun, and find the STAFF OF AWFUL LIGHT.",
      text: (
        <div class="morkBorgFont morkBorgTitleText">
          <ImageComponent
            image={sludger}
            caption="Sludger"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="Piss n' Shit n' Mud comin' atcha!"
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>

          <p>
            Our party consists of:
            <ul>
              <li>
                <span style={{ color: "#00FFFF" }}>SIR FAT HEAD</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>MUSHROOM EATER</span>, a
                wacked out prophet, scrambled like eggs.
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>VESPER</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>PALE ONE</span>, a quiet and
                violently unpredictable alien with a half-bitten off tongue.
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>HILDEBRAND</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>HERETICAL PRIEST</span>,
                worries constantly, washes his hands often, and is obsessively
                clean and neat. He was baptized by fire!
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>HERMAN</span>:{" "}
                <span style={{ color: "#ADFF2F" }}>GUTTERBORN SCVM</span>. He is
                worried and naive with hands caked in sores. He is a bit a
                pyromaniac.
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>
                  SPUG (absent this session)
                </span>
                : <span style={{ color: "#ADFF2F" }}>WRETCHED ROYALTY</span>{" "}
                afflicted with kleptomania and afraid of pain. Pain is such a
                bother!
              </li>
              <li>
                <span style={{ color: "#00FFFF" }}>
                  KLATUUBARADA (absent this session)
                </span>
                : <span style={{ color: "#ADFF2F" }}>FUGITIVE KNIGHT</span>, an
                argumentative, pyromaniacal.
              </li>
              <li style={{ fontSize: "0.8em" }}>
                <span style={{ color: "#00FFFF" }}>Donkey Hoatee</span>: donkey
              </li>
              <li style={{ fontSize: "0.8em" }}>
                <span style={{ color: "#00FFFF" }}>
                  Barbarister the Incredible Horse
                </span>
                : talking horse minion (master?) to Spug
              </li>
              <li style={{ fontSize: "0.8em" }}>
                <span style={{ color: "#00FFFF" }}>Jaque Manaque</span>: wannabe
                knight squire-lad trying to earn his keep
              </li>
            </ul>
            <div
              style={{ border: "3px solid red", padding: "5px", margin: "5px" }}
            >
              Misery Countdown:
              <ul>
                <li>
                  Psalm 6, Verse 1: You shall know the last day is come. The sun
                  shall set and never rise.
                  <ul>
                    <li>
                      EFFECT: sun never shines. World is dark always. No more
                      daytime.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            This session saw SPUG and KLATUUBARADA sitting out.
            <br />
            <br />
            Our wayward scum are deep in the burning bowels of Chapel Olandun in
            the forgotten town of Tunstal. In their quest to find the STAFF OF
            AWFUL LIGHT they are{" "}
            <span style={{ color: "#FF7F50" }}>fighting a SLUDGER</span>.
            <br />
            <br />
            Hildebrand does some good damage on the Sludger with{" "}
            <span style={{ color: "lightgreen" }}>Roskoe's Baleful Glare</span>.
            The fight is ended decisively when Sir Fat Head rolls a critical hit
            with a bomb to toss the explosive into the dripping maw of the
            Sludger. The damage is so much the Sludger blasts into smithereens
            and the{" "}
            <span style={{ color: "#C19A6B" }}>
              PCs get a victory shower of shit and mud as their reward
            </span>
            !
            <br />
            <br />
            At the bottom of the sludge trough is the key to the door. Walking
            past the moaning torsos of long dead, tormented zombie bodies, the
            door is unlocked. As the mechanism clicks, a horrific scream echoes
            throughout the hellish basement of the Chapel. It would seem the
            dreaded NURSES that rose from the dead during the long-ago
            inquisition have risen.
            <br />
            <br />A random roll puts a{" "}
            <span style={{ color: "#FF7F50" }}>
              ROT NURSE in the room with party, and she targets HERMAN
            </span>
            . As the nurse floats near Herman, he suffers a psychic attack, a
            marginwidth prison of terror. He sees visions of himself burning
            alive; his own pyromaniacal nature caught up to him! The Nurse
            drains abilities instead of doing damage, and any ability that
            reaches -3 means death!
            <br />
            <br />
            The party scouts the final room as Herman battles to make the
            PRESENSE check needed to get the Nurse out of his mind. The room is
            mostly barren except for a glowing green moss clinging to
            everything. Sir Fat Head scoops up some moss as he wanders toward a
            jagged hole in the floor of the far corner of what appears to be the
            final room of the Chapel.{" "}
            <span style={{ color: "lightgreen" }}>
              The moss heals HP; a rare boon in the Dying Lands
            </span>
            ! As HERMAN strikes at the Nurse and discovers she cannot be hurt by
            physical attacks. A huge centipede-like creature emerges from the
            hole in the floor:{" "}
            <span style={{ color: "#FF7F50" }}>
              the dreaded BYTOR with an acidic bite
            </span>{" "}
            as X.D. Meadows warned back in Galgenbeck.
            <br />
            <br />
            The ensuing final battle of the NURSE THE ROT adventure includes
            these highlights:
            <ul>
              <li>Herman makes save and nurse moves on to attack VESPER.</li>
              <li>
                BYTOR suffers damage from the ever-powerful glare attack from
                HILDEBRAND.
              </li>
              <li>
                VESPER suffers intense terror from the nurse. He feels like he
                is stuck in an alternate reality, a psychedelic madness that
                threatens to consume him.
              </li>
              <li>
                SIR FAT HEAD tries to ram a paralysis mushroom into the
                chitinous maw of the BYTOR. He fumbles and the acidic pedipalps
                of the monstrousity nearly take his arm off.
              </li>
              <li>
                <span style={{ color: "#FFFF00" }}>
                  VESPER succumbs to the horrors of the ROT NURSE and dies!
                </span>
              </li>
              <li>
                Wayward squire Jacque Manaque is left fighting the BYTOR and the
                ROT NURSE as the rest of the party is searching for treasure in
                the BYTOR lair hole. He keeps surviving with lucky PRE rolls and
                plate mail rebuting the Bytor attacks.
              </li>
              <li>
                The party finds a BIRD CAGE; a strange artifact that will slowly
                kill anything placed within it overnight, and then the corpse of
                said thing will arise as furious undead!
              </li>
              <li>
                SIR FAT HEAD is the final target of the Nurse. He fears sinking
                into nothingness and dispersing into the void.
              </li>
              <li>
                The STAFF OF AWFUL LIGHT is found in the Bytor hole. The PCs
                flee the chapel as NURSE pursues all the way to the edge of the
                church ruins above ground.
              </li>
            </ul>
            The party travels back to Galgenbeck and present the STAFF OF AWFUL
            LIGHT to Tergol the "Child Killer". The staff and the BOOKS OF
            UNFORGIVING LIGHT combine to reveal everything known about the
            RELICS OF ANUK SCHLEGER!
          </p>
        </div>
      ),
    },
    {
      title: "The Relics Revealed",
      text: (
        <div class="morkBorgFont morkBorgTitleText">
          <ImageComponent
            image={relics}
            caption="Relics of Anuk Schleger"
            cssClass="imageLeft"
            handleClick={handleClick}
            modalBody="The Relics of Anuk Schleger are scattred throughout the Dyning Lands."
          ></ImageComponent>
          <ModalComponent
            show={showModal}
            onHide={handleCloseModal}
            modalData={modalData}
          ></ModalComponent>

          <p>
            And so, bathed in the light of the STAFF OF AWFUL LIGHT, our svum
            see the revelation of the RELICS OF ANUK SCHLEGER. Now, the game is
            afoot . . . and a heart, and a jow, and . . . so on.
            <br />
            <br />
            Tune in next time!
            <br />
            <br />
            VESPER died, so please visit the{" "}
            <a
              rel="noreferrer"
              target="_blank"
              href="./mork-borg-malum-mortis-graveyard"
            >
              Mork Borg Malum Mortis Campaign Graveyard
            </a>
            .
            <br />
            <br />
            This episode of Malum Mortis brought to you in part by:
            <br />
            Game:{" "}
            <a
              href="https://morkborg.com/content/"
              rel="noreferrer"
              target="_blank"
            >
              Mork Borg
            </a>
            <br />
            Module:{" "}
            <a
              href="https://www.drivethrurpg.com/product/380973/MORK-BORG-CULT-HERETIC"
              rel="noreferrer"
              target="_blank"
            >
              Nurse the Rot [HERETIC]
            </a>
            <br />
          </p>
        </div>
      ),
    },
  ];

  return (
    <Container>
      {/* <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin" 
                    style={{position: 'float', float: 'right',width: '120px', height: '240px'}} marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=smokeraven-20&language=en_US&marketplace=amazon&region=US&placement=9189143701&asins=9189143701&linkId=d1384e0e6b9ef083fee35d7272a71f88&show_border=true&link_opens_in_new_window=true"></iframe> */}
      <Row>
        <Col>
          <div
            style={{ backgroundColor: "transparent", fontFamily: "Orbitron" }}
          >
            <main role="main" className="inner cover text-center">
              <img
                src={mbLogo}
                style={{ maxWidth: "200px" }}
                alt="Malum Mortis Mork Borg campaign"
              />
            </main>
            <div style={pageHeader}>
              <h1 className="cover-heading">Session 06: Malum Mortis</h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={cardStyle}>
            <img src={sectionDivider} alt="section divider" />
            <Card.Body>
              <Card.Title className="cardTitle-mork-borg">
                THE STAFF OF AWFUL LIGHT
              </Card.Title>
              <Card.Text>
                <ContentContainer data={section1Data} />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MBMalumMortisSession06;
