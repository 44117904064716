import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import "../../../index.css";
import { ContentContainer } from "../SessionSection";
import { useEffect } from "react";

const cardStyle = {
  textAlign: "left",
  fontFamily: "Special Elite",
  backgroundColor: "rgba(0, 0, 0, .7) ",
  color: "white",
  marginBottom: "15px",
};

const section1Data = [
  {
    title: "",
    text: (
      <p>
        Anchortown is a Mutant Year Zero campaign set in area that was once
        Anchorage, Alaska.
        <br />
        <br />
        Grid map originally created with awesome{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="https://stefouch.github.io/myz-zonemap/#/"
        >
          Find My Path tool
        </a>
        .
        <br />
        Map now maintained with hand-drawn graphics from{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.roblecrone.com/"
        >
          Rob Lecrone
        </a>
        .
      </p>
    ),
    image: {
      imagePath: null,
      altText: ``,
      className: "imageLeft",
    },
  },
];

const MYZMapGridExploration = () => {

  useEffect(() => {
    document.body.style.backgroundImage = `url(${require("../../../assets/myz/zone-explored-session-44.jpg")})`;
    document.body.style.backgroundColor = "#000000";
    document.body.style.back = "fixed";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  return (
    <div
      style={{
        "margin-top": "200px",
        margin: "auto",
        width: "550px",
        height: "50px",
      }}
    >
      <Container style={{ "margin-top": "300px" }}>
        <Row>
          <Col>
            <Card style={cardStyle}>
              <Card.Body>
                <Card.Text>
                  <ContentContainer data={section1Data} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MYZMapGridExploration;
